import '../../_helpers/sort-by.js'
export const listBrowsers = [
    {value:'CHROME',        label:'Chrome',             deviceDependency: ['DESKTOP', 'TABLET', 'MOBILE']},
    {value:'SAFARI',        label:'Safari',             deviceDependency: ['DESKTOP', 'TABLET', 'MOBILE']},
    {value:'FIREFOX',       label:'Firefox',            deviceDependency: ['DESKTOP', 'TABLET', 'MOBILE']},
    {value:'IE',            label:'Internet Explorer',  deviceDependency: ['DESKTOP', 'TABLET', 'MOBILE']},
    {value:'OPERA',         label:'Opera',              deviceDependency: ['DESKTOP', 'TABLET', 'MOBILE']},
    {value:'EDGE',          label:'Microsoft Edge',     deviceDependency: ['DESKTOP', 'TABLET', 'MOBILE']},
    {value:'SAMSUNG',       label:'Samsung',            deviceDependency: ['DESKTOP', 'TABLET', 'MOBILE']},
    {value:'UC_BROWSER',    label:'UC Browser',         deviceDependency: ['TABLET', 'MOBILE']},
].sortBy('label')