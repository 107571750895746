import '../../_helpers/sort-by.js'
export const listIABCategory = [
    {value:'IAB1_1', label:'Books & Literature (IAB1_1)'},
    {value:'IAB1_2', label:'Celebrity Fan/Gossip (IAB1_2)'},
    {value:'IAB1_3', label:'Fine Art (IAB1_3)'},
    {value:'IAB1_4', label:'Humor (IAB1_4)'},
    {value:'IAB1_5', label:'Movies (IAB1_5)'},
    {value:'IAB1_6', label:'Music (IAB1_6)'},
    {value:'IAB1_7', label:'Television (IAB1_7)'},
    {value:'IAB2_1', label:'Auto Parts (IAB2_1)'},
    {value:'IAB2_2', label:'Auto Repair (IAB2_2)'},
    {value:'IAB2_3', label:'Buying/Selling Cars (IAB2_3)'},
    {value:'IAB2_4', label:'Car Culture (IAB2_4)'},
    {value:'IAB2_5', label:'Certified Pre-Owned (IAB2_5)'},
    {value:'IAB2_6', label:'Convertible (IAB2_6)'},
    {value:'IAB2_7', label:'Coupe (IAB2_7)'},
    {value:'IAB2_8', label:'Crossover (IAB2_8)'},
    {value:'IAB2_9', label:'Diesel (IAB2_9)'},
    {value:'IAB2_10', label:'Electric Vehicle (IAB2_10)'},
    {value:'IAB2_11', label:'Hatchback (IAB2_11)'},
    {value:'IAB2_12', label:'Hybrid (IAB2_12)'},
    {value:'IAB2_13', label:'Luxury (IAB2_13)'},
    {value:'IAB2_14', label:'MiniVan (IAB2_14)'},
    {value:'IAB2_15', label:'Mororcycles (IAB2_15)'},
    {value:'IAB2_16', label:'Off-Road Vehicles (IAB2_16)'},
    {value:'IAB2_17', label:'Performance Vehicles (IAB2_17)'},
    {value:'IAB2_18', label:'Pickup (IAB2_18)'},
    {value:'IAB2_19', label:'Road-Side Assistance (IAB2_19)'},
    {value:'IAB2_20', label:'Sedan (IAB2_20)'},
    {value:'IAB2_21', label:'Trucks & Accessories (IAB2_21)'},
    {value:'IAB2_22', label:'Vintage Cars (IAB2_22)'},
    {value:'IAB2_23', label:'Wagon (IAB2_23)'},
    {value:'IAB3_1', label:'Advertising (IAB3_1)'},
    {value:'IAB3_2', label:'Agriculture (IAB3_2)'},
    {value:'IAB3_3', label:'Biotech/Biomedical (IAB3_3)'},
    {value:'IAB3_4', label:'Business Software (IAB3_4)'},
    {value:'IAB3_5', label:'Construction (IAB3_5)'},
    {value:'IAB3_6', label:'Forestry (IAB3_6)'},
    {value:'IAB3_7', label:'Government (IAB3_7)'},
    {value:'IAB3_8', label:'Green Solutions (IAB3_8)'},
    {value:'IAB3_9', label:'Human Resources (IAB3_9)'},
    {value:'IAB3_10', label:'Logistics (IAB3_10)'},
    {value:'IAB3_11', label:'Marketing (IAB3_11)'},
    {value:'IAB3_12', label:'Metals (IAB3_12)'},
    {value:'IAB4_1', label:'Career Planning (IAB4_1)'},
    {value:'IAB4_2', label:'College (IAB4_2)'},
    {value:'IAB4_3', label:'Financial Aid (IAB4_3)'},
    {value:'IAB4_4', label:'Job Fairs (IAB4_4)'},
    {value:'IAB4_5', label:'Job Search (IAB4_5)'},
    {value:'IAB4_6', label:'Resume Writing/Advice (IAB4_6)'},
    {value:'IAB4_7', label:'Nursing (IAB4_7)'},
    {value:'IAB4_8', label:'Scholarships (IAB4_8)'},
    {value:'IAB4_9', label:'Telecommuting (IAB4_9)'},
    {value:'IAB4_10', label:'U.S. Military (IAB4_10)'},
    {value:'IAB4_11', label:'Career Advice (IAB4_11)'},
    {value:'IAB5_1', label:'7-12 Education (IAB5_1)'},
    {value:'IAB5_2', label:'Adult Education (IAB5_2)'},
    {value:'IAB5_3', label:'Art History (IAB5_3)'},
    {value:'IAB5_4', label:'Colledge Administration (IAB5_4)'},
    {value:'IAB5_5', label:'College Life (IAB5_5)'},
    {value:'IAB5_6', label:'Distance Learning (IAB5_6)'},
    {value:'IAB5_7', label:'English as a 2nd Language (IAB5_7)'},
    {value:'IAB5_8', label:'Language Learning (IAB5_8)'},
    {value:'IAB5_9', label:'Graduate School (IAB5_9)'},
    {value:'IAB5_10', label:'Homeschooling (IAB5_10)'},
    {value:'IAB5_11', label:'Homework/Study Tips (IAB5_11)'},
    {value:'IAB5_12', label:'K-6 Educators (IAB5_12)'},
    {value:'IAB5_13', label:'Private School (IAB5_13)'},
    {value:'IAB5_14', label:'Special Education (IAB5_14)'},
    {value:'IAB5_15', label:'Studying Business (IAB5_15)'},
    {value:'IAB6_1', label:'Adoption (IAB6_1)'},
    {value:'IAB6_2', label:'Babies & Toddlers (IAB6_2)'},
    {value:'IAB6_3', label:'Daycare/Pre School (IAB6_3)'},
    {value:'IAB6_4', label:'Family Internet (IAB6_4)'},
    {value:'IAB6_5', label:'Parenting - K-6 Kids (IAB6_5)'},
    {value:'IAB6_6', label:'Parenting teens (IAB6_6)'},
    {value:'IAB6_7', label:'Pregnancy (IAB6_7)'},
    {value:'IAB6_8', label:'Special Needs Kids (IAB6_8)'},
    {value:'IAB6_9', label:'Eldercare (IAB6_9)'},
    {value:'IAB7_1', label:'Exercise (IAB7_1)'},
    {value:'IAB7_2', label:'A.D.D (IAB7_2).'},
    {value:'IAB7_3', label:'AIDS/HIV (IAB7_3)'},
    {value:'IAB7_4', label:'Allergies (IAB7_4)'},
    {value:'IAB7_5', label:'Alternative Medicine (IAB7_5)'},
    {value:'IAB7_6', label:'Arthritis (IAB7_6)'},
    {value:'IAB7_7', label:'Asthma (IAB7_7)'},
    {value:'IAB7_8', label:'Autism/PDD (IAB7_8)'},
    {value:'IAB7_9', label:'Bipolar Disorder (IAB7_9)'},
    {value:'IAB7_10', label:'Brain Tumor (IAB7_10)'},
    {value:'IAB7_11', label:'Cancer (IAB7_11)'},
    {value:'IAB7_12', label:'Cholesterol (IAB7_12)'},
    {value:'IAB7_13', label:'Chronic Fatigue Syndrome (IAB7_13)'},
    {value:'IAB7_14', label:'Chronic Pain (IAB7_14)'},
    {value:'IAB7_15', label:'Cold & Flu (IAB7_15)'},
    {value:'IAB7_16', label:'Deafness (IAB7_16)'},
    {value:'IAB7_17', label:'Dental Care (IAB7_17)'},
    {value:'IAB7_18', label:'Depression (IAB7_18)'},
    {value:'IAB7_19', label:'Dermatology (IAB7_19)'},
    {value:'IAB7_20', label:'Diabetes (IAB7_20)'},
    {value:'IAB7_21', label:'Epilepsy (IAB7_21)'},
    {value:'IAB7_22', label:'GERD/Acid Reflux (IAB7_22)'},
    {value:'IAB7_23', label:'Headaches/Migraines (IAB7_23)'},
    {value:'IAB7_24', label:'Heart Disease (IAB7_24)'},
    {value:'IAB7_25', label:'Herbs for Health (IAB7_25)'},
    {value:'IAB7_26', label:'Holistic Healing (IAB7_26)'},
    {value:'IAB7_27', label:'IBS/Crohn’s Disease (IAB7_27)'},
    {value:'IAB7_28', label:'Incest/Abuse Support (IAB7_28)'},
    {value:'IAB7_29', label:'Incontinence (IAB7_29)'},
    {value:'IAB7_30', label:'Infertility (IAB7_30)'},
    {value:'IAB7_31', label:'Men’s Health (IAB7_31)'},
    {value:'IAB7_32', label:'Nutrition (IAB7_32)'},
    {value:'IAB7_33', label:'Orthopedics (IAB7_33)'},
    {value:'IAB7_34', label:'Panic/Anxiety Disorders (IAB7_34)'},
    {value:'IAB7_35', label:'Pediatrics (IAB7_35)'},
    {value:'IAB7_36', label:'Physical Therapy (IAB7_36)'},
    {value:'IAB7_37', label:'Psychology/Psychiatry (IAB7_37)'},
    {value:'IAB7_38', label:'Senior Health (IAB7_38)'},
    {value:'IAB7_39', label:'Sexuality (IAB7_39)'},
    {value:'IAB7_40', label:'Sleep Disorders (IAB7_40)'},
    {value:'IAB7_41', label:'Smoking Cessation (IAB7_41)'},
    {value:'IAB7_42', label:'Substance Abuse (IAB7_42)'},
    {value:'IAB7_43', label:'Thyroid Disease (IAB7_43)'},
    {value:'IAB7_44', label:'Weight Loss (IAB7_44)'},
    {value:'IAB7_45', label:'Women’s Health (IAB7_45)'},
    {value:'IAB8_1', label:'American Cuisine (IAB8_1)'},
    {value:'IAB8_2', label:'Barbecues & Grilling (IAB8_2)'},
    {value:'IAB8_3', label:'Cajun/Creole (IAB8_3)'},
    {value:'IAB8_4', label:'Chinese Cuisine (IAB8_4)'},
    {value:'IAB8_5', label:'Cocktails/Beer (IAB8_5)'},
    {value:'IAB8_6', label:'Coffee/Tea (IAB8_6)'},
    {value:'IAB8_7', label:'Cuisine-Specific (IAB8_7)'},
    {value:'IAB8_8', label:'Desserts & Baking (IAB8_8)'},
    {value:'IAB8_9', label:'Dining Out (IAB8_9)'},
    {value:'IAB8_10', label:'Food Allergies (IAB8_10)'},
    {value:'IAB8_11', label:'French Cuisine (IAB8_11)'},
    {value:'IAB8_12', label:'Health/Lowfat Cooking (IAB8_12)'},
    {value:'IAB8_13', label:'Italian Cuisine (IAB8_13)'},
    {value:'IAB8_14', label:'Japanese Cuisine (IAB8_14)'},
    {value:'IAB8_15', label:'Mexican Cuisine (IAB8_15)'},
    {value:'IAB8_16', label:'Vegan (IAB8_16)'},
    {value:'IAB8_17', label:'Vegetarian (IAB8_17)'},
    {value:'IAB8_18', label:'Wine (IAB8_18)'},
    {value:'IAB9_1', label:'Art/Technology (IAB9_1)'},
    {value:'IAB9_2', label:'Arts & Crafts (IAB9_2)'},
    {value:'IAB9_3', label:'Beadwork (IAB9_3)'},
    {value:'IAB9_4', label:'Birdwatching (IAB9_4)'},
    {value:'IAB9_5', label:'Board Games/Puzzles (IAB9_5)'},
    {value:'IAB9_6', label:'Candle & Soap Making (IAB9_6)'},
    {value:'IAB9_7', label:'Card Games (IAB9_7)'},
    {value:'IAB9_8', label:'Chess (IAB9_8)'},
    {value:'IAB9_9', label:'Cigars (IAB9_9)'},
    {value:'IAB9_10', label:'Collecting (IAB9_10)'},
    {value:'IAB9_11', label:'Comic Books (IAB9_11)'},
    {value:'IAB9_12', label:'Drawing/Sketching (IAB9_12)'},
    {value:'IAB9_13', label:'Freelance Writing (IAB9_13)'},
    {value:'IAB9_14', label:'Genealogy (IAB9_14)'},
    {value:'IAB9_15', label:'Getting Published (IAB9_15)'},
    {value:'IAB9_16', label:'Guitar (IAB9_16)'},
    {value:'IAB9_17', label:'Home Recording (IAB9_17)'},
    {value:'IAB9_18', label:'Investors & Patents (IAB9_18)'},
    {value:'IAB9_19', label:'Jewelry Making (IAB9_19)'},
    {value:'IAB9_20', label:'Magic & Illusion (IAB9_20)'},
    {value:'IAB9_21', label:'Needlework (IAB9_21)'},
    {value:'IAB9_22', label:'Painting (IAB9_22)'},
    {value:'IAB9_23', label:'Photography (IAB9_23)'},
    {value:'IAB9_24', label:'Radio (IAB9_24)'},
    {value:'IAB9_25', label:'Roleplaying Games (IAB9_25)'},
    {value:'IAB9_26', label:'Sci-Fi & Fantasy (IAB9_26)'},
    {value:'IAB9_27', label:'Scrapbooking (IAB9_27)'},
    {value:'IAB9_28', label:'Screenwriting (IAB9_28)'},
    {value:'IAB9_29', label:'Stamps & Coins (IAB9_29)'},
    {value:'IAB9_30', label:'Video & Computer Games (IAB9_30)'},
    {value:'IAB9_31', label:'Woodworking (IAB9_31)'},
    {value:'IAB10_1', label:'Appliances (IAB10_1)'},
    {value:'IAB10_2', label:'Entertaining (IAB10_2)'},
    {value:'IAB10_3', label:'Environmental Safety (IAB10_3)'},
    {value:'IAB10_4', label:'Gardening (IAB10_4)'},
    {value:'IAB10_5', label:'Home Repair (IAB10_5)'},
    {value:'IAB10_6', label:'Home Theater (IAB10_6)'},
    {value:'IAB10_7', label:'Interior Decorating (IAB10_7)'},
    {value:'IAB10_8', label:'Landscaping (IAB10_8)'},
    {value:'IAB10_9', label:'Remodeling & Construction (IAB10_9)'},
    {value:'IAB11_1', label:'Immigration (IAB11_1)'},
    {value:'IAB11_2', label:'Legal Issues (IAB11_2)'},
    {value:'IAB11_3', label:'U.S. Government Resources (IAB11_3)'},
    {value:'IAB11_4', label:'Politics (IAB11_4)'},
    {value:'IAB11_5', label:'Commentary (IAB11_5)'},
    {value:'IAB12_1', label:'International News (IAB12_1)'},
    {value:'IAB12_2', label:'National News (IAB12_2)'},
    {value:'IAB12_3', label:'Local News (IAB12_3)'},
    {value:'IAB13_1', label:'Beginning Investing (IAB13_1)'},
    {value:'IAB13_2', label:'Credit/Debt & Loans (IAB13_2)'},
    {value:'IAB13_3', label:'Financial News (IAB13_3)'},
    {value:'IAB13_4', label:'Financial Planning (IAB13_4)'},
    {value:'IAB13_5', label:'Hedge Fund (IAB13_5)'},
    {value:'IAB13_6', label:'Insurance (IAB13_6)'},
    {value:'IAB13_7', label:'Investing (IAB13_7)'},
    {value:'IAB13_8', label:'Mutual Funds (IAB13_8)'},
    {value:'IAB13_9', label:'Options (IAB13_9)'},
    {value:'IAB13_10', label:'Retirement Planning (IAB13_10)'},
    {value:'IAB13_11', label:'Stocks (IAB13_11)'},
    {value:'IAB13_12', label:'Tax Planning (IAB13_12)'},
    {value:'IAB14_1', label:'Dating (IAB14_1)'},
    {value:'IAB14_2', label:'Divorce Support (IAB14_2)'},
    {value:'IAB14_3', label:'Gay Life (IAB14_3)'},
    {value:'IAB14_4', label:'Marriage (IAB14_4)'},
    {value:'IAB14_5', label:'Senior Living (IAB14_5)'},
    {value:'IAB14_6', label:'Teens (IAB14_6)'},
    {value:'IAB14_7', label:'Weddings (IAB14_7)'},
    {value:'IAB14_8', label:'Ethnic Specific (IAB14_8)'},
    {value:'IAB15_1', label:'Astrology (IAB15_1)'},
    {value:'IAB15_2', label:'Biology (IAB15_2)'},
    {value:'IAB15_3', label:'Chemistry (IAB15_3)'},
    {value:'IAB15_4', label:'Geology (IAB15_4)'},
    {value:'IAB15_5', label:'Paranormal Phenomena (IAB15_5)'},
    {value:'IAB15_6', label:'Physics (IAB15_6)'},
    {value:'IAB15_7', label:'Space/Astronomy (IAB15_7)'},
    {value:'IAB15_8', label:'Geography (IAB15_8)'},
    {value:'IAB15_9', label:'Botany (IAB15_9)'},
    {value:'IAB15_10', label:'Weather (IAB15_10)'},
    {value:'IAB16_1', label:'Aquariums (IAB16_1)'},
    {value:'IAB16_2', label:'Birds (IAB16_2)'},
    {value:'IAB16_3', label:'Cats (IAB16_3)'},
    {value:'IAB16_4', label:'Dogs (IAB16_4)'},
    {value:'IAB16_5', label:'Large Animals (IAB16_5)'},
    {value:'IAB16_6', label:'Reptiles (IAB16_6)'},
    {value:'IAB16_7', label:'Veterinary Medicine (IAB16_7)'},
    {value:'IAB17_1', label:'Auto Racing (IAB17_1)'},
    {value:'IAB17_2', label:'Baseball (IAB17_2)'},
    {value:'IAB17_3', label:'Bicycling (IAB17_3)'},
    {value:'IAB17_4', label:'Bodybuilding (IAB17_4)'},
    {value:'IAB17_5', label:'Boxing (IAB17_5)'},
    {value:'IAB17_6', label:'Canoeing/Kayaking (IAB17_6)'},
    {value:'IAB17_7', label:'Cheerleading (IAB17_7)'},
    {value:'IAB17_8', label:'Climbing (IAB17_8)'},
    {value:'IAB17_9', label:'Cricket (IAB17_9)'},
    {value:'IAB17_10', label:'Figure Skating (IAB17_10)'},
    {value:'IAB17_11', label:'Fly Fishing (IAB17_11)'},
    {value:'IAB17_12', label:'Football (IAB17_12)'},
    {value:'IAB17_13', label:'Freshwater Fishing (IAB17_13)'},
    {value:'IAB17_14', label:'Game & Fish (IAB17_14)'},
    {value:'IAB17_15', label:'Golf (IAB17_15)'},
    {value:'IAB17_16', label:'Horse Racing (IAB17_16)'},
    {value:'IAB17_17', label:'Horses (IAB17_17)'},
    {value:'IAB17_18', label:'Hunting/Shooting (IAB17_18)'},
    {value:'IAB17_19', label:'Inline Skating (IAB17_19)'},
    {value:'IAB17_20', label:'Martial Arts (IAB17_20)'},
    {value:'IAB17_21', label:'Mountain Biking (IAB17_21)'},
    {value:'IAB17_22', label:'NASCAR Racing (IAB17_22)'},
    {value:'IAB17_23', label:'Olympics (IAB17_23)'},
    {value:'IAB17_24', label:'Paintball (IAB17_24)'},
    {value:'IAB17_25', label:'Power & Motorcycles (IAB17_25)'},
    {value:'IAB17_26', label:'Pro Basketball (IAB17_26)'},
    {value:'IAB17_27', label:'Pro Ice Hockey (IAB17_27)'},
    {value:'IAB17_28', label:'Rodeo (IAB17_28)'},
    {value:'IAB17_29', label:'Rugby (IAB17_29)'},
    {value:'IAB17_30', label:'Running/Jogging (IAB17_30)'},
    {value:'IAB17_31', label:'Sailing (IAB17_31)'},
    {value:'IAB17_32', label:'Saltwater Fishing (IAB17_32)'},
    {value:'IAB17_33', label:'Scuba Diving (IAB17_33)'},
    {value:'IAB17_34', label:'Skateboarding (IAB17_34)'},
    {value:'IAB17_35', label:'Skiing (IAB17_35)'},
    {value:'IAB17_36', label:'Snowboarding (IAB17_36)'},
    {value:'IAB17_37', label:'Surfing/Bodyboarding (IAB17_37)'},
    {value:'IAB17_38', label:'Swimming (IAB17_38)'},
    {value:'IAB17_39', label:'Table Tennis/Ping-Pong (IAB17_39)'},
    {value:'IAB17_40', label:'Tennis (IAB17_40)'},
    {value:'IAB17_41', label:'Volleyball (IAB17_41)'},
    {value:'IAB17_42', label:'Walking (IAB17_42)'},
    {value:'IAB17_43', label:'Waterski/Wakeboard (IAB17_43)'},
    {value:'IAB17_44', label:'World Soccer (IAB17_44)'},
    {value:'IAB18_1', label:'Beauty (IAB18_1)'},
    {value:'IAB18_2', label:'Body Art (IAB18_2)'},
    {value:'IAB18_3', label:'Fashion (IAB18_3)'},
    {value:'IAB18_4', label:'Jewelry (IAB18_4)'},
    {value:'IAB18_5', label:'Clothing (IAB18_5)'},
    {value:'IAB18_6', label:'Accessories (IAB18_6)'},
    {value:'IAB19_1', label:'3-D Graphics (IAB19_1)'},
    {value:'IAB19_2', label:'Animation (IAB19_2)'},
    {value:'IAB19_3', label:'Antivirus Software (IAB19_3)'},
    {value:'IAB19_4', label:'C/C (IAB19_4)++'},
    {value:'IAB19_5', label:'Cameras & Camcorders (IAB19_5)'},
    {value:'IAB19_6', label:'Cell Phones (IAB19_6)'},
    {value:'IAB19_7', label:'Computer Certification (IAB19_7)'},
    {value:'IAB19_8', label:'Computer Networking (IAB19_8)'},
    {value:'IAB19_9', label:'Computer Peripherals (IAB19_9)'},
    {value:'IAB19_10', label:'Computer Reviews (IAB19_10)'},
    {value:'IAB19_11', label:'Data Centers (IAB19_11)'},
    {value:'IAB19_12', label:'Databases (IAB19_12)'},
    {value:'IAB19_13', label:'Desktop Publishing (IAB19_13)'},
    {value:'IAB19_14', label:'Desktop Video (IAB19_14)'},
    {value:'IAB19_15', label:'Email (IAB19_15)'},
    {value:'IAB19_16', label:'Graphics Software (IAB19_16)'},
    {value:'IAB19_17', label:'Home Video/DVD (IAB19_17)'},
    {value:'IAB19_18', label:'Internet Technology (IAB19_18)'},
    {value:'IAB19_19', label:'Java (IAB19_19)'},
    {value:'IAB19_20', label:'JavaScript (IAB19_20)'},
    {value:'IAB19_21', label:'Mac Support (IAB19_21)'},
    {value:'IAB19_22', label:'MP3/MIDI (IAB19_22)'},
    {value:'IAB19_23', label:'Net Conferencing (IAB19_23)'},
    {value:'IAB19_24', label:'Net for Beginners (IAB19_24)'},
    {value:'IAB19_25', label:'Network Security (IAB19_25)'},
    {value:'IAB19_26', label:'Palmtops/PDAs (IAB19_26)'},
    {value:'IAB19_27', label:'PC Support (IAB19_27)'},
    {value:'IAB19_28', label:'Portable (IAB19_28)'},
    {value:'IAB19_29', label:'Entertainment (IAB19_29)'},
    {value:'IAB19_30', label:'Shareware/Freeware (IAB19_30)'},
    {value:'IAB19_31', label:'Unix (IAB19_31)'},
    {value:'IAB19_32', label:'Visual Basic (IAB19_32)'},
    {value:'IAB19_33', label:'Web Clip Art (IAB19_33)'},
    {value:'IAB19_34', label:'Web Design/HTML (IAB19_34)'},
    {value:'IAB19_35', label:'Web Search (IAB19_35)'},
    {value:'IAB19_36', label:'Windows (IAB19_36)'},
    {value:'IAB20_1', label:'Adventure Travel (IAB20_1)'},
    {value:'IAB20_2', label:'Africa (IAB20_2)'},
    {value:'IAB20_3', label:'Air Travel (IAB20_3)'},
    {value:'IAB20_4', label:'Australia & New Zealand (IAB20_4)'},
    {value:'IAB20_5', label:'Bed & Breakfasts (IAB20_5)'},
    {value:'IAB20_6', label:'Budget Travel (IAB20_6)'},
    {value:'IAB20_7', label:'Business Travel (IAB20_7)'},
    {value:'IAB20_8', label:'By US Locale (IAB20_8)'},
    {value:'IAB20_9', label:'Camping (IAB20_9)'},
    {value:'IAB20_10', label:'Canada (IAB20_10)'},
    {value:'IAB20_11', label:'Caribbean (IAB20_11)'},
    {value:'IAB20_12', label:'Cruises (IAB20_12)'},
    {value:'IAB20_13', label:'Eastern Europe (IAB20_13)'},
    {value:'IAB20_14', label:'Europe (IAB20_14)'},
    {value:'IAB20_15', label:'France (IAB20_15)'},
    {value:'IAB20_16', label:'Greece (IAB20_16)'},
    {value:'IAB20_17', label:'Honeymoons/Getaways (IAB20_17)'},
    {value:'IAB20_18', label:'Hotels (IAB20_18)'},
    {value:'IAB20_19', label:'Italy (IAB20_19)'},
    {value:'IAB20_20', label:'Japan (IAB20_20)'},
    {value:'IAB20_21', label:'Mexico & Central America (IAB20_21)'},
    {value:'IAB20_22', label:'National Parks (IAB20_22)'},
    {value:'IAB20_23', label:'South America (IAB20_23)'},
    {value:'IAB20_24', label:'Spas (IAB20_24)'},
    {value:'IAB20_25', label:'Theme Parks (IAB20_25)'},
    {value:'IAB20_26', label:'Traveling with Kids (IAB20_26)'},
    {value:'IAB20_27', label:'United Kingdom (IAB20_27)'},
    {value:'IAB21_1', label:'Apartments (IAB21_1)'},
    {value:'IAB21_2', label:'Architects (IAB21_2)'},
    {value:'IAB21_3', label:'Buying/Selling Homes (IAB21_3)'},
    {value:'IAB22_1', label:'Contests & Freebies (IAB22_1)'},
    {value:'IAB22_2', label:'Couponing (IAB22_2)'},
    {value:'IAB22_3', label:'Comparison (IAB22_3)'},
    {value:'IAB22_4', label:'Engines (IAB22_4)'},
    {value:'IAB23_1', label:'Alternative Religions (IAB23_1)'},
    {value:'IAB23_2', label:'Atheism/Agnosticism (IAB23_2)'},
    {value:'IAB23_3', label:'Buddhism (IAB23_3)'},
    {value:'IAB23_4', label:'Catholicism (IAB23_4)'},
    {value:'IAB23_5', label:'Christianity (IAB23_5)'},
    {value:'IAB23_6', label:'Hinduism (IAB23_6)'},
    {value:'IAB23_7', label:'Islam (IAB23_7)'},
    {value:'IAB23_8', label:'Judaism (IAB23_8)'},
    {value:'IAB23_9', label:'Latter-Day Saints (IAB23_9)'},
    {value:'IAB23_10', label:'Pagan/Wiccan (IAB23_10)'},
    {value:'IAB24', label:'Uncategorized (IAB24)'},
    {value:'IAB25_1', label:'Unmoderated UGC (IAB25_1)'},
    {value:'IAB25_2', label:'Extreme Graphic/Explicit Violence (IAB25_2)'},
    {value:'IAB25_3', label:'Pornography (IAB25_3)'},
    {value:'IAB25_4', label:'Profane Content (IAB25_4)'},
    {value:'IAB25_5', label:'Hate Content (IAB25_5)'},
    {value:'IAB25_6', label:'Under Construction (IAB25_6)'},
    {value:'IAB25_7', label:'Incentivized (IAB25_7)'},
    {value:'IAB26_1', label:'Illegal Content (IAB26_1)'},
    {value:'IAB26_2', label:'Warez (IAB26_2)'},
    {value:'IAB26_3', label:'Spyware/Malware (IAB26_3)'},
    {value:'IAB26_4', label:'CopyrightInfringement (IAB26_4)'},
].sortBy('label')