import '../../_helpers/sort-by.js'
export const listLanguage = [
    {value:'ARABIC', label:'Arabic'},
    {value:'GERMAN', label:'German'},
    {value:'GREEK', label:'Greek'},
    {value:'ENGLISH', label:'English'},
    {value:'SPANISH', label:'Spanish'},
    {value:'FRENCH', label:'French'},
    {value:'INDONESIAN', label:'Indonesian'},
    {value:'ITALIAN', label:'Italian'},
    {value:'JAPANESE', label:'Japanese'},
    {value:'MALAY', label:'Malay'},
    {value:'DUTCH', label:'Dutch'},
    {value:'PORTUGUESE', label:'Portuguese'},
    {value:'ROMANIAN', label:'Romanian'},
    {value:'RUSSIAN', label:'Russian'},
    {value:'SWEDISH', label:'Swedish'},
    {value:'TURKISH', label:'Turkish'},
    {value:'VIETNAMESE', label:'Vietnamese'},
    {value:'SIMPLIFIED_CHINESE', label:'Simplified Chinese'},
    {value:'TRADITIONAL_CHINESE', label:'Traditional Chinese'},
    {value:'OTHER', label:'Other'},
].sortBy('label')