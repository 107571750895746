import { conversionConstants } from '../_constants';
import { conversionService } from '../_services';
import { alertActions, progressActions } from './';
import { history } from '../_helpers';

export const conversionActions = {
    getConversionByAccountId,
};



function getConversionByAccountId(accountId) {
    return dispatch => {
        dispatch(request());

        conversionService.getConversionByAccountId(accountId)
            .then(
                (conversions) => {
                    dispatch(success(conversions))
                },
                (error) => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: conversionConstants.GET_REQUEST } }
    function success(conversions) { return { type: conversionConstants.GET_SUCCESS, conversions } }
    function failure(error) { return { type: conversionConstants.GET_FAILURE, error } }
}
