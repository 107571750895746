export const pixelConstants = {
    GET_REQUEST: 'PIXEL_GET_REQUEST',
    GET_SUCCESS: 'PIXEL_GET_SUCCESS',
    GET_FAILURE: 'PIXEL_GET_FAILURE',

    UPDATE_REQUEST: 'PIXEL_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'PIXEL_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'PIXEL_UPDATE_FAILURE',
    
    CREATE_REQUEST: 'PIXEL_CREATE_REQUEST',
    CREATE_SUCCESS: 'PIXEL_CREATE_SUCCESS',
    CREATE_FAILURE: 'PIXEL_CREATE_FAILURE',
};
