import React, { useState, useEffect, Component, useDebugValue } from 'react'
import {BrowserRouter as Router, Link } from "react-router-dom";

//Redux
import { connect } from 'react-redux';
import { 
    userActions,
    campaignActions,
    ipcActions, 
    adgroupActions,
    statisticActions,
    duplicatesActions
} from '../_actions';

//React Select
import Select from 'react-select';

import { styleSelectBasic, styleSelectFit, styleSelectGoal, styleSelectMultiple } from '../_constants/settings'

//Components
import {LeftNavigation} from '../_components/LeftNavigation'
import {Footer} from '../_components/Footer';

//Tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

//Toggle
import Toggle from 'react-toggle'
import '../_stylesheets/css/react-toggle.css'

//Moment JS
import moment from 'moment';
import { statistics } from '../_reducers/statistics.reducer';
import { duplicates } from '../_reducers/duplicates.reducer';


const IPCPage = (props) => {

    const { user, users, campaigns, ipc, adgroups, statistics, duplicates} = props;

    const useStateWithLabel = (initialValue, label) => {
        const [value, setValue] = useState(initialValue);
        useDebugValue(`${label}: ${value}`);
        return [value, setValue];
    };


    const [selectedAccountId, setSelectedAccountId] = useStateWithLabel(null, "selectedAccountId");
    const [selectedCampaignId, setSelectedCampaignId] = useStateWithLabel(null, "selectedCampaignId");
    const [selectedAdgroupId, setSelectedAdgroupId] = useStateWithLabel(null, "selectedAdgroupId");
    
    
    const [data_source, setData_source] = useStateWithLabel("ZEMANTA", "data_source");
    const [pricing_status, setPricing_status] = useStateWithLabel(false, "pricing_status");
    const [chosen_metric, setChosen_metric] = useStateWithLabel("CLICKS", "chosen_metric");
    const [stats_chosen_metric, setStats_chosen_metric] = useStateWithLabel(0, "stats_chosen_metric");
    const [stats_impression, setStats_impression] = useStateWithLabel(0, "stats_impression");
    const [stats_current_ctr, setStats_current_ctr] = useStateWithLabel(0, "stats_current_ctr");
    const [max_threshold, setMax_threshold] = useStateWithLabel(0, "max_threshold");
    const [impression_per_chosen_metric, setImpression_per_chosen_metric] = useStateWithLabel(0, "impression_per_chosen_metric");
    const [ipc_media, setipc_media] = useStateWithLabel("impressions", "ipc_media");
    const [ipc_impression, setipc_impression] = useStateWithLabel(0, "ipc_impression");
    const [ipc_bid_type, setipc_bid_type] = useStateWithLabel("CPM", "ipc_bid_type");
    const [ipc_bid_value, setipc_bid_value] = useStateWithLabel("0.01", "ipc_bid_value");
    const [ipc_budget, setipc_budget] = useStateWithLabel(0, "ipc_budget");
    const [ipc_start_date, setipc_start_date] = useStateWithLabel("", "ipc_start_date");
    const [ipc_end_date, setipc_end_date] = useStateWithLabel("", "ipc_end_date");

    const [uniqueAccountIds, setUniqueAccountIds] = useStateWithLabel("", "uniqueAccountIds");


    // ON LOAD: 
    // Get List of client users under this account (including sub clients)
    // Get IPC
    useEffect(() => {
        if(user.role == "admin"){
            props.getByAccountId(user.accountId);   //For Client Admin
        }
        else{
            props.getAll();                         //For Platform admin
        }
    },[]);


    //Get list of unique accountIds
    useEffect(() => {
        let a = users && users.items && users.items.map((account, index) => (
            {
                label:`${account.accountId} ${account.companyName}`, 
                value:account.accountId
            }
        ))
        if(a !== undefined){
            setUniqueAccountIds([...new Map(a.map((a) => [a.value, a])).values()]);
        }
    },[users]);

    //ON new IPC data is Loaded
    useEffect(() => {
        if(ipc && ipc.items && ipc.items.length > 0){
            //If result is found then get the value from the result 
            setData_source(ipc.items[0].data_source)
            setPricing_status(ipc.items[0].pricing_status)
            setChosen_metric(ipc.items[0].chosen_metric)
            setMax_threshold(ipc.items[0].max_threshold)
            setImpression_per_chosen_metric(ipc.items[0].impression_per_chosen_metric)
            setipc_media(ipc.items[0].ipc_media)
            setipc_impression(ipc.items[0].ipc_impression)
            setipc_bid_type(ipc.items[0].ipc_bid_type)
            setipc_bid_value(ipc.items[0].ipc_bid_value == 0 ? 0.01 : ipc.items[0].ipc_bid_value) //Minimum based on Zemanta should be 0.01
            setipc_budget(ipc.items[0].ipc_budget)
        }
        else{
            //Else reset
            setData_source("ZEMANTA")
            setPricing_status(false)
            setChosen_metric("CLICKS")
            setStats_chosen_metric(0)
            setStats_impression(0)
            setStats_current_ctr(0)
            setMax_threshold(0)
            setImpression_per_chosen_metric(0)
            setipc_media("impressions")
            setipc_impression(0)
            setipc_bid_type("CPM")
            setipc_bid_value(0.01)
            setipc_budget(0)
            setipc_start_date("")
            setipc_end_date("")
        }
    },[ipc]);


    //ON selectedAccountId CHANGED
    useEffect(() => {
        props.getCampaigns(selectedAccountId);
        props.getAllAdgroups(selectedAccountId);
        setSelectedCampaignId(null)
        props.getDuplicatedCampaignsByAccountId(selectedAccountId)
    },[selectedAccountId]);
    

    //ON selectedCampaignId CHANGED
    useEffect(() => {
        setSelectedAdgroupId(null)
    },[selectedCampaignId]);

    //ON statistics CHANGED
    useEffect(() => {

        let stats_chosen_metric = chosen_metric == "CLICKS" ?
                                    statistics && 
                                    statistics.totals && 
                                    statistics.totals.clicks > 0 ?
                                    statistics.totals.clicks     
                                    // 500000  //Use for testing Uncomment this line and comment the first line above this
                                    : 0
                                : 0

        

        // Update Stats of Impressions
        setStats_impression(
            statistics && statistics.totals && statistics.totals.impressions > 0 ?
                statistics.totals.impressions
            : 0
        )

        // Update Stats of current CTR
        setStats_current_ctr(
            statistics && 
            statistics.totals &&
            statistics.totals.clicks &&
            statistics.totals.impressions &&
            (stats_chosen_metric > 0 && statistics.totals.impressions > 0) ? 
                stats_chosen_metric / statistics.totals.impressions
            : 0
        )


        // Update Stats of the Chosen Metric
        setStats_chosen_metric(stats_chosen_metric)

    },[statistics]);
    

    //ON ipc_bid_value or ipc_impression CHANGED
    useEffect(() => {
        //Set local var IPC Impression
        let ipc_impression = 
            stats_current_ctr > max_threshold ?
                Math.round(stats_impression * max_threshold * impression_per_chosen_metric)
            :
                Math.round(stats_chosen_metric * impression_per_chosen_metric)
        

        //Set State: IPC Bid Budget
        setipc_budget((ipc_bid_value * ipc_impression) / 1000)

        // Set State: IPC Impression
        setipc_impression(ipc_impression)
    },[stats_impression, max_threshold, impression_per_chosen_metric, ipc_bid_value, stats_chosen_metric]);


    //ON selectedAdgroupId CHANGED
    useEffect(() => {
        props.getTotalByAdgroup(selectedAccountId, selectedCampaignId, selectedAdgroupId);

        //Update the IPC Bid Type
        selectedAdgroupId != null && 
        adgroups && 
        adgroups.items && 
        adgroups.isDone == true && 
        adgroups.items.length > 0 ?
            setipc_bid_type(adgroups.items.find(item => item.id == selectedAdgroupId).biddingType)
        :
            setipc_bid_type("CPM")
    },[selectedAdgroupId]);

    //Checks if the campaign is IPC 
    const hasDuplicateCampaign = (id) => {
        const recordFound = duplicates && duplicates.items ? duplicates.items.filter(item => item.originId == id) : []
        if(recordFound.length > 0){
            return true
        }
        else{
            return false
        }
    }

    //Get the IPC Details of an adgroup
    const getIPC = (adgroupId) => {
        setSelectedAdgroupId(adgroupId)
        if(selectedAccountId != null){
            props.getIPCByAdgroupId(selectedAccountId, selectedCampaignId, adgroupId)
        }
    }

    //ON any dropdown CHANGE
    const handleDropdownChange = (name, dropdown) => {
        const value = Array.isArray(dropdown)
            ? dropdown.map((item) => {
                return item.value
            })
            : dropdown.value
        switch(name){
            case "chosen_metric": setChosen_metric(value)
                break;
            case "data_source": setData_source(value)
                break;
        }
        
    }

    const handleSaveChanges = (e) => {
        console.log("handleSaveChanges")
        props.addIPC({
            accountId                       : selectedAccountId,
            campaignId                      : selectedCampaignId,
            adgroupId                       : selectedAdgroupId,
            data_source                     : data_source,
            pricing_status                  : pricing_status,
            chosen_metric                   : chosen_metric,
            max_threshold                   : max_threshold,
            impression_per_chosen_metric    : impression_per_chosen_metric,
            ipc_media                       : ipc_media,
            ipc_impression                  : ipc_impression,
            ipc_bid_type                    : ipc_bid_type,
            ipc_bid_value                   : ipc_bid_value,
            ipc_budget                      : ipc_budget,
            // ipc_start_date                  : ipc_start_date,
            // ipc_end_date                    : ipc_end_date
        })
    }


    try{
        return (
            <div className='admin'>

                {/* SUB MENU */}
                <LeftNavigation role={user.role}/>

                <div className='canvas'>
                    <div className="admin custom-panel table ipc">
                        <div className="header">
                            IPC Pricing
                            <div className="subtitle">
                                {/*  */}
                            </div>
                            <div className="controls">
                                <div className="section">
                                    <label>Account</label>
                                    {
                                        <Select
                                        className="select-primary"
                                        onChange={(e) => {setSelectedAccountId(e.value);}}
                                        placeholder={"Please select"}
                                        options={uniqueAccountIds}
                                        styles={styleSelectFit} />
                                    }
                                </div>
                                <div className="section">
                                    <label>Campaign</label>
                                    {
                                        <Select
                                        className="select-primary"
                                        defaultValue={{
                                            label:"",
                                            value:""
                                        }}
                                        value={
                                            selectedCampaignId == null ?
                                                {
                                                    value :"",
                                                    label :"Please Select"
                                                }
                                            :
                                                {
                                                    value : campaigns.items.filter(item => item.id == selectedCampaignId)[0].id,
                                                    label : campaigns.items.filter(item => item.id == selectedCampaignId)[0].name
                                                }
                                        }
                                        onChange={(e) => setSelectedCampaignId(e.value)}
                                        // Do not include push and IPC campaigns in the dropdown option:
                                        options={campaigns && campaigns.items && campaigns.items.filter(item => item.type != "PUSH_NOTIFICATION" && !duplicates?.items?.map(item => item.campaignId).includes(item.id)).map(c => ({
                                            label:c.name,
                                            value:c.id
                                        }))}
                                        styles={styleSelectFit} />
                                    }
                                </div>
                                <div className="section">
                                    <label>Adgroup</label>
                                    {
                                        <Select
                                        className="select-primary"
                                        defaultValue={{
                                            label:"",
                                            value:""
                                        }}
                                        value={
                                            selectedAdgroupId == null ?
                                                {
                                                    value :"",
                                                    label :"Please Select"
                                                }
                                            :
                                                {
                                                    value : adgroups && adgroups.items ? adgroups.items.filter(item => item.id == selectedAdgroupId)[0]?.id : "",
                                                    label : adgroups && adgroups.items ? adgroups.items.filter(item => item.id == selectedAdgroupId)[0]?.name : ""
                                                }
                                        }
                                        onChange={(e) => getIPC(e.value)}
                                        options={selectedCampaignId != null && adgroups && adgroups.items && adgroups.items.filter(item => item.campaignId == selectedCampaignId && item.biddingType == "CPM").map(c => ({
                                            label:c.name,
                                            value:c.id
                                        }))}
                                        styles={styleSelectFit} />
                                    }
                                </div>
                            </div>
                        </div>
                        {
                                campaigns && 
                                campaigns.items && 
                                campaigns.isDone == true && 
                                selectedCampaignId != null && 
                                selectedAdgroupId != null && 
                                // (ipc && ipc.loading == false) &&
                                adgroups &&
                                adgroups.items &&
                                adgroups.isDone == true ?
                                <table className="primary fixed-header ipc">
                                        <tbody>
                                            {
                                                selectedCampaignId && 
                                                selectedAdgroupId && 
                                                adgroups && 
                                                adgroups.items && 
                                                adgroups.items.filter(c => c.id == selectedAdgroupId).map(adgroup => 
                                                    <React.Fragment key={adgroup.id}>
                                                        <tr className='fields'>
                                                            <td>Data Source</td>
                                                            <td>
                                                                <div className="select-wrapper">
                                                                    <Select
                                                                        onChange={event => handleDropdownChange("data_source",event)}
                                                                        className={`select-primary pixe-page-dropdown mt-0 mb-0`}
                                                                        defaultValue={
                                                                            data_source == "ZEMANTA" ?
                                                                                {label:'Zemanta', value:'ZEMANTA'}
                                                                            :
                                                                                {label:'Realtime', value:'RELATIME', isDisabled:true}
                                                                        }
                                                                        options={[
                                                                            {label:'Realtime', value:'REALTIME', isDisabled:true},
                                                                            {label:'Zemanta', value:'ZEMANTA'}
                                                                        ]}
                                                                        styles={styleSelectGoal} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className='fields'>
                                                            <td>IPC Pricing Status</td>
                                                            <td>
                                                                <div className="select-wrapper">
                                                                    {<Toggle className={`${ipc?.loading == true ? "disabled" : ""}`} checked={pricing_status} onChange={(e) => setPricing_status(e.target.checked)}/> }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className='fields'>
                                                            <td>Chosen Metric</td>
                                                            <td>
                                                                <div className="select-wrapper">
                                                                    <Select
                                                                        onChange={event => handleDropdownChange("chosen_metric",event)}
                                                                        className={`select-primary pixe-page-dropdown mt-0 mb-0`}
                                                                        defaultValue={
                                                                            chosen_metric == "CLICKS" ?
                                                                                {label:'Clicks', value:'CLICKS'}
                                                                            :
                                                                                {label:'Conversions', value:'CONVERSIONS', isDisabled:true}
                                                                        }
                                                                        options={[
                                                                            {label:'Clicks', value:'CLICKS'},
                                                                            {label:'Conversions', value:'CONVERSIONS', isDisabled:true}
                                                                        ]}
                                                                        styles={styleSelectGoal} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className='fields'>
                                                            <td>Stats of the Chosen Metric</td>
                                                            <td>
                                                                <span>
                                                                    {stats_chosen_metric.toLocaleString()}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className='fields'>
                                                            <td>Stats of Impressions</td>
                                                            <td>
                                                                <span>
                                                                    {stats_impression.toLocaleString()}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className='fields'>
                                                            <td>Stats of current CTR</td>
                                                            <td>
                                                                <span>
                                                                    {
                                                                        //Multiply to 100 to display as percentage
                                                                        parseFloat(stats_current_ctr * 100).toLocaleString(undefined, {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        })
                                                                    }%
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className='fields'>
                                                            <td>Max Threshold of Chosen Metric (CTR if Clicks, CVR if Conversions) %</td>
                                                            <td><span><input type="number" className='w-50' name="max_threshold" value={max_threshold} onChange={(e) => setMax_threshold(e.target.value)}></input> %</span></td>
                                                        </tr>
                                                        <tr className='fields'>
                                                            <td>Impressions per Chosen Metric (for 1 chosen metric, it equals to x impressions).</td>
                                                            <td><span><input type="number" className='w-50' name="impression_per_chosen_metric" value={impression_per_chosen_metric} onChange={(e) => setImpression_per_chosen_metric(e.target.value)}></input></span></td>
                                                        </tr>
                                                        <tr className='fields'>
                                                            <td>IPC Media</td>
                                                            <td><span>{ipc_media}</span></td>
                                                        </tr>
                                                        <tr className='fields'>
                                                            <td>IPC Impressions</td>
                                                            <td>
                                                                <span>
                                                                    {ipc_impression.toLocaleString()}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className='fields'>
                                                            <td>IPC Bid Type</td>
                                                            <td><span>{ipc_bid_type}</span></td>
                                                        </tr>
                                                        <tr className='fields'>
                                                            <td>IPC Bid Value</td>
                                                            <td><span>$<input type="number" className='w-50' name="ipc_bid_value" value={ipc_bid_value} onChange={(e) => setipc_bid_value(e.target.value)}></input></span></td>
                                                        </tr>
                                                        <tr className='fields'>
                                                            <td>IPC Budget if CPM</td>
                                                            <td>
                                                                <span>
                                                                    $ {
                                                                        parseFloat(ipc_budget).toLocaleString(undefined, {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        })
                                                                    }
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className='fields'>
                                                            <td>IPC Start Date</td>
                                                            <td>
                                                                <span>
                                                                    {/* {
                                                                        moment(adgroups.items
                                                                        .filter(item => item.campaignId === selectedCampaignId && item.biddingType === "CPM")
                                                                        .reduce(function (max, obj) {
                                                                          return new Date(obj.startDate) < max ? new Date(obj.startDate) : max;
                                                                        }, new Date())).format('ddd MMM D, YYYY')
                                                                    } */}
                                                                    {
                                                                        moment(adgroup.startDate).format('ddd MMM D, YYYY')
                                                                    }
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className='fields'>
                                                            <td>IPC End Date</td>
                                                            <td>
                                                                <span>
                                                                    {/* {
                                                                        moment(adgroups.items
                                                                        .filter(item => item.campaignId === selectedCampaignId && item.biddingType === "CPM")
                                                                        .reduce(function (max, obj) {
                                                                          return new Date(obj.startDate) > max ? new Date(obj.startDate) : max;
                                                                        }, new Date())).format('ddd MMM D, YYYY')
                                                                    } */}
                                                                    {
                                                                        adgroup.endDate != null ?
                                                                            moment(adgroup.endDate).format('ddd MMM D, YYYY')
                                                                        :
                                                                            "No End Date"
                                                                    }
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            }
                                            <tr>
                                                <td>
                                                    {/* SUBMIT OR CANCEL */}
                                                    {/* <button className="btn btn-secondary mt-3 mb-3">Cancel</button> */}
                                                    <button onClick={(e) => handleSaveChanges()} className={`btn btn-primary  mt-3 mb-3`} type="submit"><i className="fa fa-check" aria-hidden="true"></i> Save</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                </table>
                            :
                            <>
                                {
                                    selectedCampaignId != null && selectedAdgroupId != null ?
                                    <>
                                        <i className="fa fa-spinner fa-spin fast-spin"></i> Loading...
                                    </>
                                    :
                                    <>
                                        
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>


                <Footer />
            </div>
        );
    }
    catch(e){
        // window.location.reload(false);
        console.log(e)
    }
}

const mapState = state => ({
    user                : state.users.current,
    users               : state.users,
    accountsSupernova   : state.accountsSupernova,
    campaigns           : state.campaigns,
    adgroups            : state.adgroups,
    ipc                 : state.ipc,
    statistics          : state.statistics,
    duplicates          : state.duplicates,
}) 

const actionCreators = {
    getAll                  : userActions.getAll,
    getCampaigns            : campaignActions.getCampaigns,
    getCampaigns            : campaignActions.getCampaigns,
    getIPCByCampaignId      : ipcActions.getIPCByCampaignId,    //Deprecated
    getIPCByAdgroupId       : ipcActions.getIPCByAdgroupId,
    addIPC                  : ipcActions.addIPC,
    getAllAdgroups          : adgroupActions.getAllAdgroups,
    getTotalByCampaign      : statisticActions.getTotalByCampaign,
    getTotalByAdgroup       : statisticActions.getTotalByAdgroup,
    getDuplicatedCampaignsByAccountId   : duplicatesActions.getDuplicatedCampaignsByAccountId,
}

const connectedIPCPage = connect(mapState, actionCreators)(IPCPage);
export { connectedIPCPage as IPCPage };