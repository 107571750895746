import { accountsSupernovaConstants } from '../_constants';
import { accountSupernovaService } from '../_services';
import { alertActions} from './';

export const accountSupernovaActions = {
    getASById,
    getASByMultipleId,
    updateASById,
    getASMinBidById,
};



function getASById(accountId) {
    return dispatch => {
        dispatch(request());

        accountSupernovaService.getASById(accountId)
            .then(
                accountsSupernova => dispatch(success(accountsSupernova)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: accountsSupernovaConstants.GET_REQUEST } }
    function success(accountsSupernova) { return { type: accountsSupernovaConstants.GET_SUCCESS, accountsSupernova } }
    function failure(error) { return { type: accountsSupernovaConstants.GET_FAILURE, error } }
}

function getASMinBidById(accountId) {
    return dispatch => {
        dispatch(request());

        accountSupernovaService.getASMinBidById(accountId)
            .then(
                accountsSupernova => dispatch(success(accountsSupernova)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: accountsSupernovaConstants.GET_REQUEST } }
    function success(accountsSupernova) { return { type: accountsSupernovaConstants.GET_SUCCESS, accountsSupernova } }
    function failure(error) { return { type: accountsSupernovaConstants.GET_FAILURE, error } }
}

function getASByMultipleId(accountList) {
    return dispatch => {
        dispatch(request());

        accountSupernovaService.getASByMultipleId(accountList)
            .then(
                accountsSupernova => dispatch(success(accountsSupernova)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: accountsSupernovaConstants.GET_MIN_BID_REQUEST } }
    function success(accountsSupernova) { return { type: accountsSupernovaConstants.GET_MIN_BID_SUCCESS, accountsSupernova } }
    function failure(error) { return { type: accountsSupernovaConstants.GET_MIN_BID_FAILURE, error } }
}

function updateASById(accountId, countryCode, settings, accountList) {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.loading("Saving changes..."));
        console.log("Updating Account Supernova: " + accountId + " " + countryCode);

        accountSupernovaService.updateASById(accountId, countryCode, settings)
            .then(
                () => {
                    accountSupernovaService.getASByMultipleId(accountList)
                    .then(
                        accountSupernovaService => {
                            console.log(accountSupernovaService)
                            dispatch(success(accountSupernovaService))
                            dispatch(alertActions.success('Changes Saved'));
                            console.log("DONE: Updating Account Supernova");
                        },
                        error => {
                            dispatch(failure(error.toString()))
                        }
                    );
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: accountsSupernovaConstants.UPDATE_REQUEST} }
    function success(result) { return { type: accountsSupernovaConstants.UPDATE_SUCCESS, result } }
    function failure(id, error) { return { type: accountsSupernovaConstants.UPDATE_FAILURE, id, error } }
}