import '../../_helpers/sort-by.js'
export const listPushAdsOS = [
    {
        "id": 208712,
        "value": "android",
        "label": "Android"
    },
    {
        "id": 208717,
        "value": "blackberry",
        "label": "Blackberry"
    },
    {
        "id": 208743,
        "value": "ios",
        "label": "iOS"
    },
    {
        "id": 208747,
        "value": "linux",
        "label": "Linux"
    },
    {
        "id": 208748,
        "value": "mac",
        "label": "Mac OS"
    },
    {
        "id": 208779,
        "value": "other",
        "label": "Other"
    },
    {
        "id": 208828,
        "value": "symbian",
        "label": "Symbian"
    },
    {
        "id": 208904,
        "value": "windows",
        "label": "Windows"
    },
    {
        "id": 208905,
        "value": "windows_phone",
        "label": "Windows Phone"
    },
    {
        "id": 465527,
        "value": "chrome_os",
        "label": "Chrome OS"
    },
    {
        "id": 465650,
        "value": "kai_os",
        "label": "KaiOS"
    },
    {
        "id": 513773,
        "value": "other_mobile",
        "label": "Other Mobile"
    }
].sortBy('label')