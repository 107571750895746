import React, { useState, useEffect, Component, useDebugValue } from 'react'
import { Link } from 'react-router-dom';

//Redux
import { connect } from 'react-redux';
import { userActions, aiActions, adgroupActions } from '../_actions';

//React Select
import { listConversionType } from '../_constants/data'
import { styleSelectPrompt } from '../_constants/settings'
import Select from 'react-select'
import { 
    listGeo,
} from '../_constants/data';


// Globe
import {GlobePrototype} from '../GlobePage';

const Footer = (props) => {
    const { user, ai, campaign_id, campaigns, adgroups} = props;

    const useStateWithLabel = (initialValue, label) => {
        const [value, setValue] = useState(initialValue);
        useDebugValue(`${label}: ${value}`);
        return [value, setValue];
    };

    const [showAIWindow, setShowAIWindow]               = useStateWithLabel(false, "showAIWindow");
    const [userMessage, setUserMessage]                 = useStateWithLabel("", "userMessage");
    const [currentStep, setCurrentStep]                 = useStateWithLabel("NEW_QUERY","currentStep")
    const [adgroupName, setAdgroupName]                 = useStateWithLabel("Adgroup 1","adgroupName")
    const [outputMsg, setOutputMsg]                     = useStateWithLabel("", "outputMsg")
    const [audienceDescription, setAudienceDescription] = useStateWithLabel("", "audienceDescription")
    const [selectedState, setSelectedState]             = useStateWithLabel([], "selectedState")
    // const [globeData, setGlobeData]                  = useStateWithLabel([], "globeData")
    // const [globeData, setGlobeData]                     = useStateWithLabel([["00601", 21000, 18.180555, -66.749961]], "globeData") //For testing ["zipcode", population, latitude, longitude]
    const [globeData, setGlobeData]                     = useStateWithLabel([], "globeData")
    const [totalReach, setTotalReach]                   = useStateWithLabel(0, "totalReach")
    const [listLongLat, setListLongLat]                 = useStateWithLabel([], "listLongLat")

    //Cesium
    const convertToGeoJson = (customData) => {
        return {
            "type": "FeatureCollection",
            "features": customData.map(item => ({
                "type": "Feature",
                "properties": {
                    "zipcode": item[0],
                    "population": item[1],
                    "height": item[1] // Assuming the second value in the array is the population
                },
                "geometry": {
                    "type": "Point",
                    "coordinates": [item[3], item[2]] // Assuming item[2] is latitude and item[3] is longitude
                }
            }))
        };
    };

    const printStringOneByOne = (str) => {
        if(str == undefined){
            return
        }
        let index = 0;
        const intervalId = setInterval(() => {
            if (index < str.length) {
                setOutputMsg(`${str.slice(0,index+1)}`);
                index++;
            } else {
                clearInterval(intervalId);
            }
        }, 0.2); 
    }

    // useEffect(() => {
    //     if(ai.response && ai.response != "" && !ai.response.includes("Unexpected token") && !ai.response.includes("We've created an audience for you. ERROR")){
    //         setCurrentStep(ai?.response_type)
    //         printStringOneByOne(ai?.response)
    //     }
    // }, [ai?.response]);


    // useEffect(() => {
    //     if(ai?.gatheredZip && ai.gatheredZip.length > 0){
    //         console.log("setting globe data")
    //         // console.log(listLongLat)
    //         setGlobeData(
    //             ai.gatheredZip.map(zip => {
    //                 const matchingEntry = listLongLat.find(entry => entry.zip.toString() === zip.toString());
    //                 if (matchingEntry) {
    //                     // return [entry.zip.toString(), Math.floor(Math.random() * (25000 - 20000 + 1)) + 20000, matchingEntry.lat, matchingEntry.lng];
    //                     return [zip.toString(), 50000, matchingEntry.lat, matchingEntry.lng];
    //                 } 
    //                 //   else {
    //                 //     return [0,0,0];
    //                 //   }
    //             }).filter(element => element !== undefined)
    //         )
    //     }
    // }, [ai?.gatheredZip]);
    
    // useEffect(() => {
    //     if(ai.loading){
    //         setCurrentStep("LOADING")
    //         printStringOneByOne("Processing...")
    //         if(ai.response_type == "AUDIENCE_DESCRIPTION"){
    //             setAudienceDescription(ai.response)
    //         }
    //     }
    // }, [ai.loading]);

    // useEffect(() => {
    //     if(ai?.totalReach){
    //         setTotalReach(ai?.totalReach)
    //     }
    // }, [ai?.totalReach]);
    
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch(`https://omni.supernova.inc/public/uploads/data/lnglat.json?v=1`);
    //             if (!response.ok) {
    //                 throw new Error('Network response was not ok');
    //             }
    //             const data = await response.json();
    //             console.log("Longitude and Latitude Loaded")
    //             setListLongLat(data);
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         }
    //     };

    //     fetchData();
    // }, []);

    const askAI = () => {
        props.askAI(
            userMessage, 
            ai.thread_id && ai.thread_id !== undefined && currentStep != "NEW_QUERY" ? ai.thread_id : null,
            user.accountId, 
            campaign_id
        )
    }

    const handleSelectState = (event) => {
        setSelectedState(event.target.value);
    }

    const createAdGroup = () => {
        let budgets                 = campaigns?.items.find(item => item.id == campaign_id)?.budgets || 0
        let total_available_budget  = budgets?.reduce((total, obj) => total + parseInt(obj.available), 0) || 0;
        let last_budget_date        = budgets?.sort((a, b) => new Date(b.endDate) - new Date(a.endDate))[0]?.endDate;

        setUserMessage("")
        setCurrentStep("CREATE_ADGROUP")
        printStringOneByOne(`We are now setting up your adgroup ${userMessage == "" ? ai.adgroup_name :userMessage}... <br/><br/>You may close the window while it is running in the background.`)
        props.createSupernovaAdgroup({
            thread_id   : ai.thread_id,
            name        : ai.adgroup_name,
            query_id    : ai.query_id,
            supernovaCampaignId : campaigns?.items.find(item => item.id == campaign_id)?.supernovaCampaignId,
        },
        user.accountId,
        campaign_id,
        ai.clusters,
        {
            total_available_budget:total_available_budget,
            last_budget_date:last_budget_date,
            budget_type: getBudgetType()
        }
        )
    }

    const closeWindow = () => {
        setShowAIWindow(false)
        setUserMessage("")
        setCurrentStep("NEW_QUERY")
        setAdgroupName("Adgroup 1")
        setOutputMsg("")
    }

    const onClickProceed = () => {
        switch(currentStep){
            case "AUDIENCE_DESCRIPTION"    : 
                setOutputMsg(`What is the scope of your Geo Targeting in the US?
                </br></br>
                You can say the entire USA, states, cities, DMA's, towns, neighborhoods, zip codes, and radius.
                </br></br>
                Examples:  
                <br/>I want to include US states on the East Coast.  
                <br/>I want to include Chelsea, Midtown, Bayside, and Jumbo in NYC.
                <br/>I want to include the surrounding zip codes within 10 mile radius of the following addresses.
                `)
                setCurrentStep("GEO_TARGETING")
                setUserMessage("")
                break;
            case "PROCESS_COMPLETE" : 
                createAdGroup();
                break;
        }
    }

    const submitTargetLocation = () => {
        props.askAIToProceedProcess(ai.thread_id, campaign_id, userMessage); 
        setUserMessage("")
    }

    const getBudgetType = () => {
        return adgroups?.items.some(adgroup => adgroup.state == "ACTIVE") ? "OPTIMIZED" : "EVEN_DISTRIBUTION";
    }

    const getMaxEndDate = (campaign_id) => {
        const budgets = campaigns?.items?.find(item => item.id == campaign_id)?.budgets || []
        const r = budgets?.reduce((latest, current) => {
            return new Date(current.endDate) > new Date(latest.endDate) ? current.endDate : latest.endDate;
        }) || "false"
        // console.log(r?.endDate)
        return r?.endDate
    }

    try{
        return (
            <footer>
                <div className="user-details-row1">
                    <b>Logged in As:</b> {`${user.firstName} ${user.lastName} ( ${user.role.charAt(0).toUpperCase() + user.role.replace("_", " ").substr(1).toLowerCase()} )`}
                </div>
                <div className="user-details-row2">
                    <b>Account:</b>  {`${user.companyName} ${user.accountId}`}
                </div>
                <p><a href="https://www.supernova.inc/policy.html" target='_blank'>Terms Of Service</a></p>
                <p>All rights reserved © Supernova.inc <span>Version 1.0 | Build Number 251</span></p>

                {/* AI INTEGRATION TO AUTOMATE CAMPAIGN CREATION */}
                {
                //     showAIWindow &&
                //     <>
                //         <div className='overlay-ai-campaign-creation'>
                //             <div className="window">
                //                 {/* {outputMsg.length} / {ai.response && ai?.response.length} */}
                //                 <button className='close-btn' onClick={(e) => closeWindow()}>Close</button>
                //                 <div className='ai-response'>
                //                     {
                //                         <div className="inline" dangerouslySetInnerHTML={{ __html: `${outputMsg} ${outputMsg == "" ? "" : `<div class="cursor blink"><div class="block"></div></div>`}` }}/>
                //                     }
                //                     {
                //                         //////////////////////////////////////////////////////
                //                         // PROCEED BUTTON WHEN:
                //                         // - Confirming the suggested Audience Description
                //                         // - Confirming the suggested AdGroup Name
                //                         //////////////////////////////////////////////////////
                //                             ai.response &&
                //                             ai.ask_to_proceed &&
                //                             ai.thread_id != null &&
                //                             currentStep !== "CREATE_ADGROUP" &&
                //                             currentStep !== "NEW_QUERY" &&
                //                             currentStep !== "GEO_TARGETING" &&
                //                             !ai.response.toString().includes("Please provide additional details") &&
                //                             !ai.response.toString().includes("No audience found") &&
                //                             !ai.response.toString().includes("Unexpected token") &&
                //                             (outputMsg.length >= (ai?.response?.length || 0) || outputMsg.includes("continue.")) && 
                //                             <button className='chat-gpt-prompt' disabled={ai?.loading === true} onClick={() => {onClickProceed()}}>
                //                                 Proceed <i className="fas fa-caret-right"></i>
                //                             </button>
                //                     }
                //                 </div>
                //                 {
                //                     <>
                //                         {
                //                             <textarea className={`user-message ${currentStep != "CREATE_ADGROUP" ? "" : "hidden"}`} value={userMessage} onChange={(e) => setUserMessage(e.target.value) } placeholder='Enter your message here...'>{userMessage}</textarea>
                //                         }
                //                         {
                //                             // Hide submit button on the following:
                //                             // - When processing
                //                             // - When being asked to confirm adgroup name
                //                             // - When adgroup is already being processed
                //                             (   !ai?.loading == true 
                //                                 && currentStep != "PROCESS_COMPLETE" && 
                //                                 currentStep != "CREATE_ADGROUP" && 
                //                                 currentStep != "LOADING" && userMessage != "") &&
                //                             <button onClick={() => {
                //                                 currentStep == "GEO_TARGETING" 
                //                                 ? submitTargetLocation()
                //                                 : askAI()
                //                             }
                //                             }>
                //                                 {outputMsg.includes("Something went wrong") ? "Try Again" : "Send"} <i className="fas fa-paper-plane"></i>
                //                             </button>
                //                         }
                //                     </>
                //                 }
                //             </div>
                //         </div>
                //         {/* <GlobePrototype globeData={[["Query1",[40.7506, -73.997, 9]]]}/> */}
                //         <div className="total-reach-wrapper">
                //             {
                //                 totalReach > 0 &&
                //                 <>
                //                     <div className="title">Estimated Reach:</div>
                //                     <div className="value">
                //                         {totalReach.toLocaleString()}
                //                     </div>
                //                 </>
                //             }
                //         </div>
                //         {/* <GlobePrototype globeData={[["Query1",globeData]]}/> */}
                //         <GlobePrototype globeData={globeData} />
                //     </>
                }
                {
                //     campaign_id !== undefined &&
                //     campaign_id !== null &&
                //     campaigns?.items?.length > 0 &&
                //     <button 
                //         className={`
                //             ${campaign_id !== null ? "shown" : ""} 
                //             ${campaigns?.items?.find(item => item.id == campaign_id)?.budgets && new Date() < new Date(getMaxEndDate(campaign_id)) ? "" : "disabled"} chat-gpt-button`
                //         } 
                //         onClick={() => setShowAIWindow(true)}>
                //             <i className="fas fa-robot"></i>
                //     </button>
                }
                {
                //     // config.environment != "dev2" &&
                //     <div className='resolution-indicator'>
                //         <p className="xs">xs</p>
                //         <p className="sm">sm</p>
                //         <p className="md">md</p>
                //         <p className="lg">lg</p>
                //         <p className="xl">xl</p>
                //         <p className="laptop">lt</p>
                //         <p className="laptopSmall">lts</p>
                //         <p className="widescreen">ws</p>
                //     </div>
                }
            </footer>
        )
    }
    catch(e){
        // window.location.reload(false);
    }
}

const mapState = state => ({
    user        : state.users.current,
    ai          : state.ai,
    campaigns   : state.campaigns,
    adgroups    : state.adgroups,
})

const actionCreators = {
    askAI: aiActions.askAI,
    askAIToProceedProcess: aiActions.askAIToProceedProcess,
    createSupernovaAdgroup: adgroupActions.createSupernovaAdgroup,
}

const connectedFooter = connect(mapState, actionCreators)(Footer);

export { connectedFooter as Footer };
