import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const userActions = {
    login,
    logout,
    authenticateJWT,
    getAll,
    getById,
    getByAccountId,
    delete: _delete,
    update,
    showUpdateForm,
    hideUpdateForm,
    updateMyInfo,
    approve,
    register,               // Public registration page
    addUser,                // Used by platform admin to add user
};

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                token => {
                    dispatch(success(token));
                    // history.push('/');
                    window.location.replace("/");
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(token) { return { type: userConstants.LOGIN_SUCCESS, token } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function authenticateJWT(token) {
    return dispatch => {
        dispatch(request({ token }));

        userService.authenticateJWT(token)
            .then(
                user => { 
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.GET_REQUEST, user } }
    function success(user) { return { type: userConstants.GET_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GET_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function register(user) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user)
            .then(
                user => { 
                    dispatch(success());
                    // history.push('/#/login');
                    // window.location.reload(false)
                    dispatch(alertActions.success('Registration successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function addUser(user, requester) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user)
            .then(
                (user) => { 
                    if(requester.role == "admin"){
                        
                        //If Client Admin get all users again based on accountID
                        console.log("adding as admin")
                        userService.getByAccountId(requester.accountId)
                        .then(
                            users => {
                                dispatch(success(users));
                                dispatch(alertActions.success('New user is successfully added'));
                            },
                            error => dispatch(failure(error.toString()))
                        );
                    }
                    else{
                        
                        console.log("adding as platform admin")
                        //If Platform Admin get all users
                        userService.getAll()
                        .then(
                            users => {
                                dispatch(success(users));
                                dispatch(alertActions.success('New user is successfully added'));
                            },
                            error => dispatch(failure(error.toString()))
                        );
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.ADD_REQUEST, user } }
    function success(users) { return { type: userConstants.ADD_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => {
                    dispatch(success(users));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

function getById(id) {
    return dispatch => {
        dispatch(request());
        userService.getById(id)
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.MYINFO_REQUEST } }
    function success(user) { return { type: userConstants.MYINFO_SUCCESS, user } }
    function failure(error) { return { type: userConstants.MYINFO_FAILURE, error } }
}

function getByAccountId(accountId) {
    return dispatch => {
        dispatch(request());

        userService.getByAccountId(accountId)
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETBYACCOUNTID_REQUEST } }
    function success(users) { return { type: userConstants.GETBYACCOUNTID_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETBYACCOUNTID_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(user) {
    return dispatch => {
        const username = user.username
        const id = user.id
        dispatch(request(user.id));
        dispatch(alertActions.loading("Saving changes..."));

        userService.delete(user)
            .then(
                user => {
                    dispatch(success(id))
                    dispatch(alertActions.success(`User ${username} is Removed`));
                },
                error => {
                    dispatch(failure(id, error.toString()))
                }
            );
    };

    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}

function showUpdateForm(id) {
    return dispatch => {
        dispatch(request(id));
    };
    function request(id) { return { type: userConstants.SHOW_UPDATE_FORM, id } }
}
function hideUpdateForm(id) {
    return dispatch => {
        dispatch(request(id));
    };
    function request(id) { return { type: userConstants.HIDE_UPDATE_FORM, id } }
}

function update(user) {
    const data = user;
    return dispatch => {
        dispatch(request(data));
        dispatch(alertActions.loading("Saving changes..."));

        userService.update(data)
            .then(
                user => {
                    console.log(data);
                    dispatch(success(data)),
                    dispatch(alertActions.success('Changes Saved!'));
                    // dispatch(userActions.hideUpdateForm(data.id));
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request(user) { return { type: userConstants.UPDATE_REQUEST, user } }
    function success(user) { return { type: userConstants.UPDATE_SUCCESS, user } }
    function failure(id, error) { return { type: userConstants.UPDATE_FAILURE, id, error } }
}

function updateMyInfo(user) {
    return dispatch => {
        dispatch(request(user));

        userService.updateMyInfo(user)
            .then(
                users => {
                    dispatch(success(user)),
                    dispatch(alertActions.success('Changes Saved!'));
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request(user) { return { type: userConstants.UPDATE_MYINFO_REQUEST, user } }
    function success(user) { return { type: userConstants.UPDATE_MYINFO_SUCCESS, user } }
    function failure(id, error) { return { type: userConstants.UPDATE_MYINFO_FAILURE, id, error } }
}

function approve(userId) {
    return dispatch => {
        dispatch(request(userId));

        userService.approve(userId)
            .then(
                users => {
                    dispatch(success(userId)),
                    dispatch(alertActions.success(`Successfully approved`));
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request(userId) { return { type: userConstants.APPROVE_REQUEST, userId } }
    function success(userId) { return { type: userConstants.APPROVE_SUCCESS, userId } }
    function failure(id, error) { return { type: userConstants.APPROVE_FAILURE, id, error } }
}

