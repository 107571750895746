import { marketResearchConstants } from '../_constants';
import { marketResearchService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const marketResarchActions = {
    getMarketResearch,
    createMarketStudy,
    createClient,
    createBrand,
    updateMarketStudy,
    removeClient,
    removeBrand,
    removeStudy,
};

function getMarketResearch(accountId) {
    return dispatch => {
        dispatch(request());

        marketResearchService.getMarketResearch(accountId)
            .then(
                marketresearch => dispatch(success(marketresearch)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: marketResearchConstants.GET_REQUEST } }
    function success(marketresearch) { return { type: marketResearchConstants.GET_SUCCESS, marketresearch } }
    function failure(error) { return { type: marketResearchConstants.GET_FAILURE, error } }
}

function createMarketStudy(data) {
    return dispatch => {
        dispatch(request());

        marketResearchService.createMarketStudy(data)
            .then(
                marketresearch => {
                    marketResearchService.getMarketResearch(data.accountId)
                    .then(
                        marketresearch => {
                            dispatch(success(marketresearch))
                            dispatch(alertActions.success('Research Study Created!'));
                        },
                        error => {
                            dispatch(failure(error.toString()))
                        }
                    );
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: marketResearchConstants.CREATE_REQUEST } }
    function success(marketresearch) { return { type: marketResearchConstants.CREATE_SUCCESS, marketresearch } }
    function failure(error) { return { type: marketResearchConstants.CREATE_FAILURE, error } }
}

function createClient(data) {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.loading("Saving..."));
        marketResearchService.createClient(data)
            .then(
                marketresearch => {
                    marketResearchService.getMarketResearch(data.accountId)
                    .then(
                        marketresearch => {
                            dispatch(success(marketresearch))
                            dispatch(alertActions.success('Client Created!'));
                        },
                        error => {
                            dispatch(failure(error.toString()))
                        }
                    );
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: marketResearchConstants.CREATE_CLIENT_REQUEST } }
    function success(marketresearch) { return { type: marketResearchConstants.CREATE_CLIENT_SUCCESS, marketresearch } }
    function failure(error) { return { type: marketResearchConstants.CREATE_CLIENT_FAILURE, error } }
}
function createBrand(data) {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.loading("Saving..."));
        marketResearchService.createBrand(data)
            .then(
                marketresearch => {
                    marketResearchService.getMarketResearch(data.accountId)
                    .then(
                        marketresearch => {
                            dispatch(success(marketresearch))
                            dispatch(alertActions.success('Brand Created!'));
                        },
                        error => {
                            dispatch(failure(error.toString()))
                        }
                    );
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: marketResearchConstants.CREATE_BRAND_REQUEST } }
    function success(marketresearch) { return { type: marketResearchConstants.CREATE_BRAND_SUCCESS, marketresearch } }
    function failure(error) { return { type: marketResearchConstants.CREATE_BRAND_FAILURE, error } }
}

function updateMarketStudy(data, id) {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.loading("Saving Changes..."));
        marketResearchService.updateMarketStudy(data, id)
            .then(
                marketresearch => {
                    marketResearchService.getMarketResearch(data.accountId)
                    .then(
                        marketresearch => {
                            dispatch(success(marketresearch))
                            dispatch(alertActions.success('Research Is Updated!'));
                        },
                        error => {
                            dispatch(failure(error.toString()))
                        }
                    );
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: marketResearchConstants.UPDATE_REQUEST } }
    function success(marketresearch) { return { type: marketResearchConstants.UPDATE_SUCCESS, marketresearch } }
    function failure(error) { return { type: marketResearchConstants.UPDATE_FAILURE, error } }
}

function removeClient(data) {
    return dispatch => {
        dispatch(request());

        marketResearchService.removeClient(data)
            .then(
                marketresearch => {
                    marketResearchService.getMarketResearch(data.accountId)
                    .then(
                        marketresearch => {
                            dispatch(success(marketresearch))
                            dispatch(alertActions.success('Successfully removed!'));
                        },
                        error => {
                            dispatch(failure(error.toString()))
                        }
                    );
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: marketResearchConstants.UPDATE_REQUEST } }
    function success(marketresearch) { return { type: marketResearchConstants.UPDATE_SUCCESS, marketresearch } }
    function failure(error) { return { type: marketResearchConstants.UPDATE_FAILURE, error } }
}

function removeBrand(data) {
    return dispatch => {
        dispatch(request());

        marketResearchService.removeBrand(data)
            .then(
                marketresearch => {
                    marketResearchService.getMarketResearch(data.accountId)
                    .then(
                        marketresearch => {
                            dispatch(success(marketresearch))
                            dispatch(alertActions.success('Successfully removed!'));
                        },
                        error => {
                            dispatch(failure(error.toString()))
                        }
                    );
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: marketResearchConstants.UPDATE_REQUEST } }
    function success(marketresearch) { return { type: marketResearchConstants.UPDATE_SUCCESS, marketresearch } }
    function failure(error) { return { type: marketResearchConstants.UPDATE_FAILURE, error } }
}

function removeStudy(data) {
    return dispatch => {
        dispatch(request());

        marketResearchService.removeStudy(data)
            .then(
                marketresearch => {
                    marketResearchService.getMarketResearch(data.accountId)
                    .then(
                        marketresearch => {
                            dispatch(success(marketresearch))
                            dispatch(alertActions.success('Successfully removed!'));
                        },
                        error => {
                            dispatch(failure(error.toString()))
                        }
                    );
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: marketResearchConstants.UPDATE_REQUEST } }
    function success(marketresearch) { return { type: marketResearchConstants.UPDATE_SUCCESS, marketresearch } }
    function failure(error) { return { type: marketResearchConstants.UPDATE_FAILURE, error } }
}