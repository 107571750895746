export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',
    
    ADD_REQUEST: 'USERS_ADD_REQUEST',
    ADD_SUCCESS: 'USERS_ADD_SUCCESS',
    ADD_FAILURE: 'USERS_ADD_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',
    SHOW_UPDATE_FORM : 'USERS_SHOW_UPDATE_FORM',
    HIDE_UPDATE_FORM : 'USERS_HIDE_UPDATE_FORM',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',
    
    GETBYACCOUNTID_REQUEST: 'USERS_GETBYACCOUNTID_REQUEST',
    GETBYACCOUNTID_SUCCESS: 'USERS_GETBYACCOUNTID_SUCCESS',
    GETBYACCOUNTID_FAILURE: 'USERS_GETBYACCOUNTID_FAILURE',

    GET_REQUEST: 'USERS_GET_REQUEST',
    GET_SUCCESS: 'USERS_GET_SUCCESS',
    GET_FAILURE: 'USERS_GET_FAILURE',
    
    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',
    
    UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',
    
    APPROVE_REQUEST: 'USERS_APPROVE_REQUEST',
    APPROVE_SUCCESS: 'USERS_APPROVE_SUCCESS',
    APPROVE_FAILURE: 'USERS_APPROVE_FAILURE',

    UPDATE_MYINFO_REQUEST: 'USERS_UPDATE_MYINFO_REQUEST',
    UPDATE_MYINFO_SUCCESS: 'USERS_UPDATE_MYINFO_SUCCESS',
    UPDATE_MYINFO_FAILURE: 'USERS_UPDATE_MYINFO_FAILURE',

    MYINFO_REQUEST: 'USERS_MYINFO_REQUEST',
    MYINFO_SUCCESS: 'USERS_MYINFO_SUCCESS',
    MYINFO_FAILURE: 'USERS_MYINFO_FAILURE',
};
