import { duplicatesConstants } from '../_constants';
import { 
    duplicatesService,
 } from '../_services';
import { alertActions, progressActions } from './';
import { history } from '../_helpers';

export const duplicatesActions = {
    getDuplicatedCampaignsByAccountId,
    addDuplicateReference
};

function getDuplicatedCampaignsByAccountId(accountId) {
    return dispatch => {
        dispatch(request());

        duplicatesService.getDuplicatedCampaignsByAccountId(accountId)
            .then(
                duplicates => {
                    dispatch(success(duplicates))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: duplicatesConstants.GET_REQUEST } }
    function success(duplicates) { return { type: duplicatesConstants.GET_SUCCESS, duplicates } }
    function failure(error) { return { type: duplicatesConstants.GET_FAILURE, error } }
}

function addDuplicateReference(accountId, campaignId, originId) {
    return dispatch => {
        dispatch(request());
        // dispatch(alertActions.loading("Savings..."));
        duplicatesService.addIPC(data)
            .then(
                duplicates => {
                    dispatch(success(duplicates))
                    // dispatch(alertActions.success('IPC Setting is Saved.'));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: duplicatesConstants.CREATE_REQUEST } }
    function success(duplicates) { return { type: duplicatesConstants.CREATE_SUCCESS, duplicates } }
    function failure(error) { return { type: duplicatesConstants.CREATE_FAILURE, error } }
}
