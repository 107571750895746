import config from 'config';
import { creativeConstants } from '../_constants';
import { creativeService } from '../_services';
import { alertActions, progressActions } from './';
import { history } from '../_helpers';

//For uploading files
import { authHeader } from '../_helpers';
import axios from 'axios';


export const creativeActions = {
    getCreativesInAccount,
    getCreativesInAdgroup,
    updateCreative,                 // Update Creative
    addCreative,                    // Add to local DB
    select,             
    uploadFile,                     // Upload temporary for preview

    
    //Video
    createNewVideoAsset,            // 1. Create new video aset
    
    clearFileUploads,

    //Push
    addCreativePush,
    addMoreCreativePush,
    editCreativePush,
};



function getCreativesInAdgroup(adgroupId) {
    return dispatch => {
        dispatch(request());

        creativeService.getCreativesInAdgroup(adgroupId)
            .then(
                creatives => dispatch(success(creatives)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: creativeConstants.GET_REQUEST } }
    function success(creatives) { return { type: creativeConstants.GET_SUCCESS, creatives } }
    function failure(error) { return { type: creativeConstants.GET_FAILURE, error } }
}

function getCreativesInAccount(accountId) {
    return dispatch => {
        dispatch(request());

        creativeService.getCreativesInAccount(accountId)
            .then(
                creatives => dispatch(success(creatives)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: creativeConstants.GET_REQUEST } }
    function success(creatives) { return { type: creativeConstants.GET_SUCCESS, creatives } }
    function failure(error) { return { type: creativeConstants.GET_FAILURE, error } }
}



function updateCreative(creative) {
    return dispatch => {
        dispatch(request(creative));
        dispatch(alertActions.loading("Saving..."));
        creativeService.updateCreative(creative)
            .then(
                creative => {
                    dispatch(success(creative))
                    dispatch(alertActions.success('Changes Saved'));
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request(creative) { return { type: creativeConstants.UPDATE_REQUEST, creative } }
    function success(creative) { return { type: creativeConstants.UPDATE_SUCCESS, creative } }
    function failure(error) { return { type: creativeConstants.UPDATE_FAILURE, error } }
}

function addCreative(creative) {
    return dispatch => {
        dispatch(request(creative));
        dispatch(alertActions.loading("Saving..."));
        console.log("Saving creative");
        console.log(creative)
        creativeService.addCreative(creative)
            .then(
                creatives => {
                    dispatch(success(creatives))
                    dispatch(alertActions.success('Creatives Added'));
                    console.log("DONE: Saving creative");
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request(creative) { return { type: creativeConstants.CREATE_REQUEST, creative } }
    function success(creatives) { return { type: creativeConstants.CREATE_SUCCESS, creatives } }
    function failure(error) { return { type: creativeConstants.CREATE_FAILURE, error } }
}

function addCreativePush(creative, accountId, selectedAdgroup) {
    return dispatch => {
        dispatch(request(creative));
        dispatch(alertActions.loading("Saving..."));
        console.log("Saving creative");
        console.log(creative)
        creativeService.addCreativePush(creative, accountId, selectedAdgroup)
            .then(
                creatives => {
                    dispatch(success(creatives))
                    dispatch(alertActions.success('Creatives Added'));
                    console.log("DONE: Saving creative");
                    location.reload();
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request(creative) { return { type: creativeConstants.CREATE_PUSH_REQUEST, creative } }
    function success(creatives) { return { type: creativeConstants.CREATE_PUSH_SUCCESS, creatives } }
    function failure(error) { return { type: creativeConstants.CREATE_PUSH_FAILURE, error } }
}

function addMoreCreativePush(creative, accountId, selectedAdgroup) {
    return dispatch => {
        dispatch(request(creative));
        dispatch(alertActions.loading("Saving..."));
        console.log("Saving creative");
        console.log(creative)
        creativeService.addMoreCreativePush(creative, accountId, selectedAdgroup)
            .then(
                creatives => {
                    dispatch(success(creatives))
                    dispatch(alertActions.success('Creatives Added'));
                    console.log("DONE: Saving creative");
                    location.reload();
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request(creative) { return { type: creativeConstants.CREATE_PUSH_REQUEST, creative } }
    function success(creatives) { return { type: creativeConstants.CREATE_PUSH_SUCCESS, creatives } }
    function failure(error) { return { type: creativeConstants.CREATE_PUSH_FAILURE, error } }
}

function editCreativePush(creative) {
    return dispatch => {
        dispatch(request(creative));
        dispatch(alertActions.loading("Saving..."));
        console.log("Saving creative");
        console.log(creative)
        creativeService.editCreativePush(creative)
            .then(
                creatives => {
                    console.log(creative)
                    dispatch(success(creative))
                    dispatch(alertActions.success('Saved!'));
                    console.log("DONE: Saving Changes");
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request(creative) { return { type: creativeConstants.EDIT_PUSH_REQUEST, creative } }
    function success(creative) { return { type: creativeConstants.EDIT_PUSH_SUCCESS, creative } }
    function failure(error) { return { type: creativeConstants.EDIT_PUSH_FAILURE, error } }
}

function select(creativeID) {
    return dispatch => {
        dispatch(request(creativeID));
    };

    function request(creativeID) { return { type: creativeConstants.SELECT_ADGROUP, creativeID } }
}

function uploadFile(file, index, field){
    return dispatch => {
        dispatch(request());
        const data = new FormData()
        data.append('file', file)
        return axios.request({
            method: "post", 
            url: `${config.apiURL}/creatives/preview/${field}`, 
            headers: authHeader(),
            data: data, 
            onUploadProgress: (p) => {
                // console.log(p.loaded / p.total); 
                // progress.timestamp = Date.now(); //Triggers componentDidUpdate
                dispatch(updateProgress({
                    value: (p.loaded / p.total) * 100, 
                    field: field+"UploadProgress",
                    index: index,
                }))
                // fileprogress: p.loaded / p.total
                
            }
        }).then (result => {
            let image = {
                url:result.data.file,
                field:result.data.field,
                index:index
            }
            dispatch(success(image))
        })
    };

    function request() { return { type: creativeConstants.UPLOAD_REQUEST} }
    function success(image) { return { type: creativeConstants.UPLOAD_SUCCESS, image } }
    function updateProgress(progress) { return { type: creativeConstants.UPLOAD_PROGRESS, progress } }
    function failure(id, error) { return { type: creativeConstants.UPLOAD_FAILURE, id, error } }
}

function createNewVideoAsset(file, index, data, field){
    return dispatch => {
        dispatch(request());

        //1. Create new video asset
        creativeService.createNewVideoAsset(data)
        .then(
            response => {

                //2. Send for upload
                const formData = new FormData()
                formData.append('file', file)
                formData.append('urlUpload', response.upload.url)
                formData.append('saveFileAs', data.name)
                return axios.request({
                    method: "post", 
                    url: `${config.apiURL}/creatives/videoUpload/${field}/${data.name}`, 
                    headers: authHeader(),
                    data: formData, 
                    onUploadProgress: (p) => {
                        dispatch(updateProgress({
                            value: (p.loaded / p.total) * 100, 
                            field: field+"UploadProgress",
                            index: index,
                        }))
                    }
                }).then (result => {

                    let isVideoDone                 = false;
                    let attempt                     = 0;
                    let isWaitingForResponse        = false;
                    const maxAttempt                = 20;
                    const intervalTimeForChecking   = 2000; //2 Seconds

                    //3. Consistently check the status of video processing every 2 seconds until its ready (Max of 20 attempts)
                    const myInterval = setInterval(() => {
                        attempt++;
                        if(isVideoDone == true || attempt >= maxAttempt){
                            clearInterval(myInterval);
                        }
                        else{
                            getStatusOfVideoUpload();
                        }
                    }, intervalTimeForChecking);

                    //Function that gets called every 2 seconds for checking status
                    function getStatusOfVideoUpload(){

                        
                        // 3.1 Make sure we call API while it still waiting for response.
                        // Wait for it to finish first before attempting to do another call.
                        if(!isWaitingForResponse){
                            
                            console.log("Checking status of video upload... (Attempt No: " + attempt +")");
                            isWaitingForResponse = true;

                            creativeService.getStatusOfVideoUpload(response.id)
                            .then(
                                result => {
                                    
                                    isWaitingForResponse = false; // When there's already an API response, set {isWaitingForResponse} to false so we can do another attempt if needed.
                            
                                    //If Result is "READY_FOR_USE" then set {isVideoDone} to true to stop {setInterval}
                                    if(result.status == "READY_FOR_USE"){
                                        console.log("Success: Video is Finish ans ready for use! ")
                                        isVideoDone = true
                                        let video = {
                                            videoAssetId:result.id,
                                            field:field,
                                            index:index
                                        }
                                        console.log(video)
                                        dispatch(success(video))
                                    }
                                }
                            ),
                            error => {
                                dispatch(failure(error.toString()))
                            }
                        }
                    }
                })
            },
            error => {
                dispatch(failure(error.toString()))
            }
        );

        
    };


    function request() { return { type: creativeConstants.UPLOAD_REQUEST} }
    function success(video) { return { type: creativeConstants.UPLOAD_VIDEO_SUCCESS, video } }
    function updateProgress(progress) { return { type: creativeConstants.UPLOAD_PROGRESS, progress } }
    function failure(id, error) { return { type: creativeConstants.UPLOAD_FAILURE, id, error } }
}


function clearFileUploads(){
    return dispatch => {
        dispatch(request());
    }   
    function request() { return { type: creativeConstants.CLEAR_UPLOADS } }
}

