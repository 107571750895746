import config from 'config';
import { authHeader } from '../_helpers';

export const creativesPendingService = {
    getCreativesPending,
    addCreativesPending,
    submitCreative,             // Submit to zemanta
    checkUploadStatus,          // Check submission status
    connectCreativesToAdgroup   // Connect Creatives to AdGroup

};

function getCreativesPending() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/creatives_pending/`, requestOptions).then(handleResponse);
}
function addCreativesPending(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };
    return fetch(`${config.apiURL}/creatives_pending/`, requestOptions).then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function submitCreative(data, adGroupId) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };
    return fetch(`${config.apiURL}/creatives/${adGroupId}`, requestOptions).then(handleResponse);
}

function checkUploadStatus(batchId, accountId, adGroupId, zemantaAdgroupId){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiURL}/creatives/batch/${batchId}/${accountId}/${adGroupId}/${zemantaAdgroupId}`, requestOptions).then(handleResponse);
}

function connectCreativesToAdgroup(adGroupId, creativeId) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
    };
    return fetch(`${config.apiURL}/creatives/ads/${adGroupId}/${creativeId}`, requestOptions).then(handleResponse);
}