//This function distincts an array by {groupByField}. When it finds duplicate it will return the first occurance
Array.prototype.distinctBy = function(groupByField) {
  const uniqueValues = new Set();
  const distinctArray = [];

  this.forEach(item => {
    const value = item[groupByField];
    if (value !== undefined && !uniqueValues.has(value)) {
      uniqueValues.add(value);
      distinctArray.push(item);
    }
  });

  return distinctArray;

}

