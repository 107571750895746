import { ipcConstants } from '../_constants';
import { ipcService } from '../_services';
import { alertActions, progressActions } from './';
import { history } from '../_helpers';

export const ipcActions = {
    getIPCByCampaignId,
    getIPCByAdgroupId,
    addIPC
};



function getIPCByCampaignId(accountId, campaignId) {
    return dispatch => {
        dispatch(request());

        ipcService.getIPCByCampaignId(accountId, campaignId)
            .then(
                ipc => {
                    dispatch(success(ipc))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: ipcConstants.GET_REQUEST } }
    function success(ipc) { return { type: ipcConstants.GET_SUCCESS, ipc } }
    function failure(error) { return { type: ipcConstants.GET_FAILURE, error } }
}

function getIPCByAdgroupId(accountId, campaignId, adgroupId) {
    return dispatch => {
        dispatch(request());

        ipcService.getIPCByAdgroupId(accountId, campaignId, adgroupId)
            .then(
                ipc => dispatch(success(ipc)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: ipcConstants.GET_REQUEST } }
    function success(ipc) { return { type: ipcConstants.GET_SUCCESS, ipc } }
    function failure(error) { return { type: ipcConstants.GET_FAILURE, error } }
}

function addIPC(data) {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.loading("Savings..."));
        ipcService.addIPC(data)
            .then(
                ipc => {
                    dispatch(success(ipc))
                    dispatch(alertActions.success('IPC Setting is Saved.'));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: ipcConstants.CREATE_REQUEST } }
    function success(ipc) { return { type: ipcConstants.CREATE_SUCCESS, ipc } }
    function failure(error) { return { type: ipcConstants.CREATE_FAILURE, error } }
}


