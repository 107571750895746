import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../_actions';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        this.props.logout();

        this.state = {
            username: '',
            password: '',
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { username, password } = this.state;
        if (username && password) {
            this.props.login(username, password);
        }
    }

    render() {
        const { loggingIn } = this.props;
        const { username, password, submitted } = this.state;
        document.title = "Supernova"
        return (    
            <>
            <div className="container">
                <div className="login">
                        {/* <h1>Login</h1> */}
                        <img className="logo" width="400" src="/public/images/logo-text.png" alt=""/>
                        <form name="form" onSubmit={this.handleSubmit}>
                            <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                                {/* <label htmlFor="username">Username</label> */}
                                <input type="text" className={`form-control ${username != "" ? "has-val" : ""}`} name="username" value={username} onChange={this.handleChange} />
                                <label data-placeholder="Username"></label>
                                {submitted && !username &&
                                    <div className="help-block">Username is required</div>
                                }
                            </div>
                            <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                {/* <label htmlFor="password">Password</label> */}
                                <input type="password" className={`form-control ${password != "" ? "has-val" : ""}`} name="password" value={password} onChange={this.handleChange} />
                                <label data-placeholder="Password"></label>
                                {submitted && !password &&
                                    <div className="help-block">Password is required</div>
                                }
                            </div>
                            <div className="form-group border-0">
                                <button className={`${loggingIn ? "disabled" : ""}`} disabled={loggingIn}>
                                {loggingIn ? 
                                    <><i className="fa fa-cog fa-spin mr-2"></i><i>Signing-in...</i></>
                                    : 'Login'
                                }
                                </button>
                            </div>
                        </form>
                    <div className="lower-section d-none">
                        <ul>
                            <li><i className="fa fa-circle"></i> Forgot <Link to="/#" className="btn btn-link">Username / Password?</Link></li>
                            <li><i className="fa fa-circle"></i> Don’t have an account? <Link to="/register" className="btn btn-link">Sign up</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };