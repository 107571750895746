import React from 'react'
import { connect } from 'react-redux';
import { userActions } from '../../_actions';

class UpdateProfileForm extends React.Component {
    constructor(props) {
        super(props);

        const { user } = this.props;
        this.state = {
            user: {
                id: user.id,
                firstName: JSON.parse(localStorage.user).firstName,
                lastName: JSON.parse(localStorage.user).lastName,
                companyName: JSON.parse(localStorage.user).companyName,
                companyWebsite: JSON.parse(localStorage.user).companyWebsite,
                position: JSON.parse(localStorage.user).position
            },
            submitted: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        //this.props.getUsers();
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        const { user } = this.state;
        this.props.hideModal();
        this.props.updateMyInfo(user)
    }

    render() {
        const { hideModal } = this.props;
        const { user, submitted } = this.state;
        return (
            <>
                    <form name="form" onSubmit={this.handleSubmit}>
                        <section>
                            <div className={'form-group' + (submitted && !user.firstName ? ' has-error' : '')}>
                                <label htmlFor="firstName">First Name</label>
                                <div className="content">
                                    <input type="text" className="form-control" name="firstName" value={user.firstName} onChange={this.handleChange} />
                                    {submitted && !user.firstName &&
                                        <div className="help-block">Required</div>
                                    }
                                </div>
                            </div>
                            <div className={'form-group' + (submitted && !user.lastName ? ' has-error' : '')}>
                                <label htmlFor="lastName">Last Name</label>
                                <div className="content">
                                    <input type="text" className="form-control" name="lastName" value={user.lastName} onChange={this.handleChange} />
                                    {submitted && !user.lastName &&
                                        <div className="help-block">Required</div>
                                    }
                                </div>
                            </div>
                            <div className={'form-group' + (submitted && !user.password ? ' has-error' : '')}>
                                <label htmlFor="password">Password</label>
                                <div className="content">
                                    <input type="password" className="form-control" name="password" value={user.password} onChange={this.handleChange} />
                                    {submitted && !user.password &&
                                        <div className="help-block">Required</div>
                                    }
                                </div>
                            </div>
                            <div className={'form-group' + (submitted && !user.companyName ? ' has-error' : '')}>
                                <label htmlFor="companyName">Company Name</label>
                                <div className="content">
                                    <input type="text" className="form-control" name="companyName" value={user.companyName} onChange={this.handleChange} />
                                    {submitted && !user.companyName &&
                                        <div className="help-block">Required</div>
                                    }
                                </div>
                            </div>
                            <div className={'form-group' + (submitted && !user.companyWebsite ? ' has-error' : '')}>
                                <label htmlFor="companyWebsite">Company Website</label>
                                <div className="content">
                                    <input type="text" className="form-control" name="companyWebsite" value={user.companyWebsite} onChange={this.handleChange} />
                                    {submitted && !user.companyWebsite &&
                                        <div className="help-block">Required</div>
                                    }
                                </div>
                            </div>
                            <div className={'form-group' + (submitted && !user.position ? ' has-error' : '')}>
                                <label htmlFor="position">Your positition in this company</label>
                                <div className="content">
                                    <input type="text" className="form-control" name="position" value={user.position} onChange={this.handleChange} />
                                    {submitted && !user.position &&
                                        <div className="help-block">Required</div>
                                    }
                                </div>
                            </div>
                        </section>
                        <div className="footer">
                            <button className="btn btn-primary" type="submit" onClick={this.handleSubmit}><i className="fa fa-check" aria-hidden="true"></i> Save</button>
                            <button className="btn btn-secondary" type="button" onClick={hideModal}>Cancel</button>
                        </div>
                    </form>
            </>
        );
    }
}

function mapState(state) {
    const { user } = state.authentication
    return { user };
}

const actionCreators = {
    getUsers: userActions.getAll,
    updateMyInfo: userActions.updateMyInfo
}

const connectedUpdateProfileForm = connect(mapState, actionCreators)(UpdateProfileForm);
export { connectedUpdateProfileForm as UpdateProfileForm };