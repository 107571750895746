import React, { useState, useEffect, Component, useDebugValue } from 'react'
import {BrowserRouter as Router, Link } from "react-router-dom";

//Redux
import { connect } from 'react-redux';
import { 
    userActions,
    adgroupActions,
    creativeActions,
    statisticActions,
    campaignActions,
    adgroupCreativeActions
} from '../_actions';

//React Select
import { listCreativeType } from '../_constants/data'
import { styleSelectBasic, styleSelectMultiple } from '../_constants/settings'
import Select from 'react-select'

//Components
import {Footer} from '../_components/Footer';
import {LeftNavigation} from '../_components/LeftNavigation'

//Helpers
import '../_helpers/sort-by.js'

//Moments JS
import Moment from 'react-moment';
import moment from 'moment';

//Toggle
import Toggle from 'react-toggle'
import '../_stylesheets/css/react-toggle.css'

//List Data
import { 
    listIABCategory, 
    listLanguage, 

} from '../_constants/data'
import { campaigns } from '../_reducers/campaigns.reducer';


const CreativeList = (props) => {
    const useStateWithLabel = (initialValue, label) => {
        const [value, setValue] = useState(initialValue);
        useDebugValue(`${label}: ${value}`);
        return [value, setValue];
    };

    const { user, users, campaigns, adgroups, statistics, creatives, adgroupCreatives } = props;

    //Used as the actual Date Range parameter
    const [startDate, setStartDate] = useStateWithLabel(moment().subtract(60, 'days'), "startDate");
    // const [startDate, setStartDate] = useStateWithLabel(moment("2021/01/01"), "startDate");
    const [endDate, setEndDate] = useStateWithLabel(moment(), "endDate");

    //Daily Budget input fields (Helps us determine which Bid is being edited in the dashboard table)
    const [editBudgetFieldId, setEditBudgetFieldId] = useStateWithLabel(null, "editBudgetFieldId");
    const [editBudgetFieldValue, setEditBudgetFieldValue] = useStateWithLabel(null, "editBudgetFieldValue");

    const [creativeSearch, setCreativeSearch] = useStateWithLabel("", "creativeSearch");
    const [selectedAdgroup, setSelectedAdgroup] = useStateWithLabel(props.match.params.adgroupId !== undefined ? props.match.params.adgroupId : null, "selectedAdgroup");


    //1. GET all adgroups
    useEffect(() => {
        props.getCreativesInAccount(user.accountId)
        props.getAdgroupCreatives(user.accountId)

        //Get list of Campaigns (So we can get the "Campaign Name" based on campaignID)
        props.getCampaigns(user.accountId);
    },[user]);

    //2. WHEN campaign is data is ready, GET its statistics
    useEffect(() => {
        if(creatives.isDone){
            props.getStatsPerCreative(user.accountId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
        }
    },[
        creatives.isDone,
    ]);


    const getCampaignNameByID = (campaignId) =>{
        return campaigns.items && campaigns.items.filter(campaign => campaign.id == campaignId)[0].name;
    }

    const getAdgroupByCreativeId = (creativeId) =>{
        let data = adgroupCreatives.items && adgroupCreatives.items.filter(item => item.creativeId == creativeId)
        return data.length > 0 ? data[0].adGroupId : "-";
    }

    let creativeFilter = []
    
    if(adgroupCreatives.items && statistics.creative && creatives.items){
        creativeFilter = selectedAdgroup == null 
        ? creatives.items
        : creatives.items.filter(creative => 
            (
                creative.title.toUpperCase().indexOf(creativeSearch.toUpperCase()) >= 0 || creative.id.indexOf(creativeSearch) >= 0) && 
                (
                    adgroupCreatives.items.filter(adgroupCreative => adgroupCreative.adGroupId == selectedAdgroup).map(item => {return item.creativeId}).includes(creative.id)
                )
                
            )
    }

    return (

        <div className="creative-library">
            <LeftNavigation />
            <div className="canvas">
                <div className={`custom-panel creativelib table`}>
                    <div className="header">
                        Campaigns <i className="fas fa-long-arrow-alt-right"></i> Ad Groups <i className="fas fa-long-arrow-alt-right"></i> Creatives
                    </div>
                    {
                        adgroups.loading ?
                        <div className="loading">
                            Initializing...
                        </div>
                        :
                        <table className="home primary fixed-header has-hover"> 
                            <thead>
                                <tr>
                                    <th className="creative-thumb"></th>
                                    <th className="id">Creative Id</th>
                                    <th className="creative-title">Creative Title</th>
                                    <th className="id">Adgroup ID</th>
                                    <th className="creative-size">Size</th>
                                    <th className="stats text-right">Impression</th>
                                    <th className="stats text-right">Clicks</th>
                                    <th className="stats text-right">Media Spend</th>   
                                    <th className="stats text-right">Data Spend</th>   
                                    <th className="stats text-right">Total Spend</th>   
                                    {/* <th className="stats text-right">Daily Budget</th>    */}
                                    {/* <th className="stats text-right">Yesterday</th>    */}
                                    <th className="stats text-right">ECPC</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    creativeFilter.map((creative, index) =>
                                    <tr className={`clickable ${creative.archived ? 'd-none' : ''}`} key={creative.id}>
                                        <td className="creative-thumb">
                                            {
                                                creative.imageUrl &&
                                                <img src={creative.imageUrl.replace(/\?w=.*.&h/, '?w=19&h=19&o')} width="19" height="19" alt=""/>
                                            }
                                            {
                                                creative.icon &&
                                                <img src={creative.icon.replace(/\?w=.*.&h/, '?w=19&h=19&o')} width="19" height="19" alt=""/>
                                            }
                                        </td>
                                        <td className="id"><span>{creative.id}</span></td>
                                        <td className="creative-title">
                                            <span className="blue-link plain">{creative.title}</span>
                                        </td>
                                        <td className="id"><span>{getAdgroupByCreativeId(creative.id)}</span></td>
                                        <td className="creative-size">
                                            <span>
                                                {creative.imageWidth}x{creative.imageHeight}
                                            </span>
                                        </td>
                                        {/* CREATIVE IMPRESSION */}
                                        <td className="stats text-right">
                                            <span>
                                                {
                                                    statistics.creative.filter(item => item.creativeId == creative.id).length == 0 ? "N/A" : statistics.creative.filter(item => item.creativeId == creative.id)[0].impressions.toLocaleString()
                                                }
                                            </span>
                                        </td>

                                        {/* CREATIVE CLICKS */}
                                        <td className="stats text-right">
                                            <span>
                                                {
                                                    statistics.creative.filter(item => item.creativeId == creative.id).length == 0 ? "N/A" : statistics.creative.filter(item => item.creativeId == creative.id)[0].clicks.toLocaleString()
                                                }
                                            </span>
                                        </td>

                                        {/* MEDIA SPEND */}
                                        <td className="stats text-right">
                                            <span>
                                                {
                                                    statistics.creative.filter(item => item.creativeId == creative.id).length == 0 
                                                    ? "N/A"
                                                    :
                                                        `$${parseFloat(
                                                            statistics.creative.filter(item => item.creativeId == creative.id)[0].mediaSpend).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                        })}`
                                                }
                                            </span>
                                        </td>
                                        
                                        {/* DATA SPEND */}
                                        <td className="stats text-right">
                                            <span>
                                                {
                                                    statistics.creative.filter(item => item.creativeId == creative.id).length == 0 
                                                    ? "N/A"
                                                    :
                                                        `$${parseFloat(
                                                            statistics.creative.filter(item => item.creativeId == creative.id)[0].dataspend_with_margin).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                        })}`
                                                }
                                            </span>
                                        </td>
                                        
                                        {/* TOTAL SPEND */}
                                        <td className="stats text-right">
                                            <span>
                                                {
                                                    statistics.creative.filter(item => item.creativeId == creative.id).length == 0 
                                                    ? "N/A"
                                                    :
                                                        `$${parseFloat(
                                                            statistics.creative.filter(item => item.creativeId == creative.id)[0].mediaSpend + statistics.creative.filter(item => item.creativeId == creative.id)[0].dataspend_with_margin
                                                            ).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                        })}`
                                                }
                                            </span>
                                        </td>

                                        {/* CREATIVE DAILY BUDGET */}
                                        {/* <td className="stats text-right">
                                            <span>
                                                {
                                                    statistics.creative.filter(item => item.creativeId == creative.id).length == 0 
                                                    ? "N/A"
                                                    :
                                                        `$${parseFloat(statistics.creative.filter(item => item.creativeId == creative.id)[0].dailyBudget).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                        })}`
                                                }
                                            </span>
                                        </td> */}

                                        {/* CREATIVE YESTERDAY SPEND */}
                                        {/* <td className="stats text-right">
                                            <span>
                                                {
                                                    statistics.creative.filter(item => item.creativeId == creative.id).length == 0 
                                                    ? "N/A"
                                                    :
                                                        `$${parseFloat(statistics.creative.filter(item => item.creativeId == creative.id)[0].yesterday_spend).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                        })}`
                                                }
                                            </span>
                                        </td> */}
                                        
                                        {/* CREATIVE CPC */}
                                        <td className="stats text-right">
                                            <span>
                                                {
                                                    statistics.creative.filter(item => item.creativeId == creative.id).length == 0 
                                                    ? "N/A"
                                                    : 
                                                        //Total Spend Divided by Clicks
                                                        `$${parseFloat(
                                                            (statistics.creative.filter(item => item.creativeId == creative.id)[0].mediaSpend + statistics.creative.filter(item => item.creativeId == creative.id)[0].dataspend_with_margin) / 
                                                            statistics.creative.filter(item => item.creativeId == creative.id)[0].clicks).toLocaleString(undefined, {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                        })}`
                                                } 
                                            </span>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        
            <Footer />
        </div>
    )
}

const mapState = state => ({
    user        : state.users.current,
    users       : state.users,
    campaigns   : state.campaigns,
    statistics  : state.statistics,
    adgroups    : state.adgroups,
    creatives   : state.creatives,
    adgroupCreatives    : state.adgroupCreatives,
})

const actionCreators = {
    getAllAdgroups          : adgroupActions.getAllAdgroups,
    getAdgroupCreatives     : adgroupCreativeActions.getAdgroupCreatives,
    getStatsPerCreative      : statisticActions.getStatsPerCreative,
    getCampaigns            : campaignActions.getCampaigns,
    getCreativesInAccount   : creativeActions.getCreativesInAccount,
}

const connectedCreativeList = connect(mapState, actionCreators)(CreativeList);

export { connectedCreativeList as CreativeList };