import React, { useState, useEffect, Component, useDebugValue } from 'react';
import {BrowserRouter as Router, Link } from "react-router-dom";

//Redux
import { connect } from 'react-redux';
import { 
    userActions,
    pixelActions,
    audienceActions,
} from '../_actions';

//React Select
import { listCreativeType } from '../_constants/data'
import { styleSelectBasic, styleSelectMultiple } from '../_constants/settings'
import Select from 'react-select'

//Components
import {TopNavigation} from '../_components/TopNavigation'
import {Submenu} from '../_components/Submenu'
import {Footer} from '../_components/Footer';
import {LeftNavigation} from '../_components/LeftNavigation'

//Moment
import Moment from 'react-moment';
import moment from 'moment';

//Helpers
import '../_helpers/sort-by.js'

const MarketResearchPage2 = (props) => {

    const useStateWithLabel = (initialValue, label) => {
        const [value, setValue] = useState(initialValue);
        useDebugValue(`${label}: ${value}`);
        return [value, setValue];
    };


    const { user, users, pixels, audiences } = props;
    const accountId = user.accountId

    const handleDropdownChange = (dropdown) => {
        const value = Array.isArray(dropdown)
            ? dropdown.map((item) => {
                return item.value
            })
            : dropdown === null ? "" : dropdown.value
        return (value)
    }


    useEffect(() => {
        props.getPixelsByAccountId(accountId)
    },[]);
    

    useEffect(() => {
        if(pixels.items){
            let pixelIds = pixels.items.map(pixel => {return pixel.id})
            props.getAudiencesByPixelId(pixelIds)
        }
    },[pixels.items]);

    const [currentStep, setCurrentStep] = useStateWithLabel("1", "setCurrentStep");
    
    //Client and Brand Name
    const [clientName, setClientName]   = useStateWithLabel("", "clientName");
    const [brandName, setBrandName]   = useStateWithLabel("", "brandName");

    //Step 1
    const [dimension1, setDimension1]   = useStateWithLabel("", "dimension1");
    const [dimension2, setDimension2]   = useStateWithLabel("", "dimension2");
    const [dimension3, setDimension3]   = useStateWithLabel("", "dimension3");
    const [segment1, setSegment1]       = useStateWithLabel("", "segment1");
    const [segment2, setSegment2]       = useStateWithLabel("", "segment2");
    const [segment3, setSegment3]       = useStateWithLabel("", "segment3");

    const [totalParticipantA, setTotalParticipantA]       = useStateWithLabel(60, "totalParticipantA");
    const [totalParticipantB, setTotalParticipantB]       = useStateWithLabel(60, "totalParticipantB");
    const [totalParticipantC, setTotalParticipantC]       = useStateWithLabel(60, "totalParticipantB");
    
    //Step 2
    const [geo, setGeo]                         = useStateWithLabel("", "geo");
    const [employment, setEmployment]           = useStateWithLabel("", "employment");
    const [maritalStatus, setMaritalStatus]     = useStateWithLabel("", "maritalStatus");
    const [parents, setParents]                 = useStateWithLabel("", "parents");
    const [occupation, setOccupation]           = useStateWithLabel("", "occupation");
    const [ethnicities, setEthnicities]         = useStateWithLabel("", "ethnicities");
    const [gender, setGender]                   = useStateWithLabel("", "gender");
    const [age, setAge]                         = useStateWithLabel("", "age");
    const [householdIncome, setHouseholdIncome] = useStateWithLabel("", "householdIncome");
    const [customScreener, setCustomScreener]   = useStateWithLabel("", "customScreener");
    
    //Step 3a
    const [audienceSegment1Prompt, setAudienceSegment1Prompt]   = useStateWithLabel("", "audienceSegment1Prompt");
    const [audienceSegment1Question1, setAudienceSegment1Question1]   = useStateWithLabel("", "audienceSegment1Question1");
    const [audienceSegment1Question2, setAudienceSegment1Question2]   = useStateWithLabel("", "audienceSegment1Question2");
    const [audienceSegment1Question3, setAudienceSegment1Question3]   = useStateWithLabel("", "audienceSegment1Question3");
    const [audienceSegment1Question4, setAudienceSegment1Question4]   = useStateWithLabel("", "audienceSegment1Question4");
    const [audienceSegment1Question5, setAudienceSegment1Question5]   = useStateWithLabel("", "audienceSegment1Question5");
    const [audienceSegment1Question6, setAudienceSegment1Question6]   = useStateWithLabel("", "audienceSegment1Question6");
    const [audienceSegment1Question7, setAudienceSegment1Question7]   = useStateWithLabel("", "audienceSegment1Question7");
    const [audienceSegment1Question8, setAudienceSegment1Question8]   = useStateWithLabel("", "audienceSegment1Question8");
    const [audienceSegment1Question9, setAudienceSegment1Question9]   = useStateWithLabel("", "audienceSegment1Question9");
    const [audienceSegment1Question10, setAudienceSegment1Question10]   = useStateWithLabel("", "audienceSegment1Question10");
    
    //Step 3b
    const [audienceSegment2Prompt, setAudienceSegment2Prompt]   = useStateWithLabel("", "audienceSegment2Prompt");
    const [audienceSegment2Question1, setAudienceSegment2Question1]   = useStateWithLabel("", "audienceSegment2Question1");
    const [audienceSegment2Question2, setAudienceSegment2Question2]   = useStateWithLabel("", "audienceSegment2Question2");
    const [audienceSegment2Question3, setAudienceSegment2Question3]   = useStateWithLabel("", "audienceSegment2Question3");
    const [audienceSegment2Question4, setAudienceSegment2Question4]   = useStateWithLabel("", "audienceSegment2Question4");
    const [audienceSegment2Question5, setAudienceSegment2Question5]   = useStateWithLabel("", "audienceSegment2Question5");
    const [audienceSegment2Question6, setAudienceSegment2Question6]   = useStateWithLabel("", "audienceSegment2Question6");
    const [audienceSegment2Question7, setAudienceSegment2Question7]   = useStateWithLabel("", "audienceSegment2Question7");
    const [audienceSegment2Question8, setAudienceSegment2Question8]   = useStateWithLabel("", "audienceSegment2Question8");
    const [audienceSegment2Question9, setAudienceSegment2Question9]   = useStateWithLabel("", "audienceSegment2Question9");
    const [audienceSegment2Question10, setAudienceSegment2Question10]   = useStateWithLabel("", "audienceSegment2Question10");
    
    //Step 3c
    const [audienceSegment3Prompt, setAudienceSegment3Prompt]   = useStateWithLabel("", "audienceSegment3Prompt");
    const [audienceSegment3Question1, setAudienceSegment3Question1]   = useStateWithLabel("", "audienceSegment3Question1");
    const [audienceSegment3Question2, setAudienceSegment3Question2]   = useStateWithLabel("", "audienceSegment3Question2");
    const [audienceSegment3Question3, setAudienceSegment3Question3]   = useStateWithLabel("", "audienceSegment3Question3");
    const [audienceSegment3Question4, setAudienceSegment3Question4]   = useStateWithLabel("", "audienceSegment3Question4");
    const [audienceSegment3Question5, setAudienceSegment3Question5]   = useStateWithLabel("", "audienceSegment3Question5");
    const [audienceSegment3Question6, setAudienceSegment3Question6]   = useStateWithLabel("", "audienceSegment3Question6");
    const [audienceSegment3Question7, setAudienceSegment3Question7]   = useStateWithLabel("", "audienceSegment3Question7");
    const [audienceSegment3Question8, setAudienceSegment3Question8]   = useStateWithLabel("", "audienceSegment3Question8");
    const [audienceSegment3Question9, setAudienceSegment3Question9]   = useStateWithLabel("", "audienceSegment3Question9");
    const [audienceSegment3Question10, setAudienceSegment3Question10]   = useStateWithLabel("", "audienceSegment3Question10");
    
    //Step 3d
    const [audienceSegment4Prompt, setAudienceSegment4Prompt]   = useStateWithLabel("", "audienceSegment4Prompt");
    const [audienceSegment4Question1, setAudienceSegment4Question1]   = useStateWithLabel("", "audienceSegment4Question1");
    const [audienceSegment4Question2, setAudienceSegment4Question2]   = useStateWithLabel("", "audienceSegment4Question2");
    const [audienceSegment4Question3, setAudienceSegment4Question3]   = useStateWithLabel("", "audienceSegment4Question3");
    const [audienceSegment4Question4, setAudienceSegment4Question4]   = useStateWithLabel("", "audienceSegment4Question4");
    const [audienceSegment4Question5, setAudienceSegment4Question5]   = useStateWithLabel("", "audienceSegment4Question5");
    const [audienceSegment4Question6, setAudienceSegment4Question6]   = useStateWithLabel("", "audienceSegment4Question6");
    const [audienceSegment4Question7, setAudienceSegment4Question7]   = useStateWithLabel("", "audienceSegment4Question7");
    const [audienceSegment4Question8, setAudienceSegment4Question8]   = useStateWithLabel("", "audienceSegment4Question8");
    const [audienceSegment4Question9, setAudienceSegment4Question9]   = useStateWithLabel("", "audienceSegment4Question9");
    const [audienceSegment4Question10, setAudienceSegment4Question10]   = useStateWithLabel("", "audienceSegment4Question10");
    
    //Step 4
    const [hypotheses1, setHypotheses1]   = useStateWithLabel("", "hypotheses1");
    const [hypotheses2, setHypotheses2]   = useStateWithLabel("", "hypotheses2");
    const [hypotheses3, setHypotheses3]   = useStateWithLabel("", "hypotheses3");
    const [hypotheses4, setHypotheses4]   = useStateWithLabel("", "hypotheses4");
    const [hypotheses5, setHypotheses5]   = useStateWithLabel("", "hypotheses5");
    const [hypotheses6, setHypotheses6]   = useStateWithLabel("", "hypotheses6");
    const [hypotheses7, setHypotheses7]   = useStateWithLabel("", "hypotheses7");
    const [hypotheses8, setHypotheses8]   = useStateWithLabel("", "hypotheses8");
    const [hypotheses9, setHypotheses9]   = useStateWithLabel("", "hypotheses9");
    const [hypotheses10, setHypotheses10]   = useStateWithLabel("", "hypotheses10");
    const [hypotheses11, setHypotheses11]   = useStateWithLabel("", "hypotheses11");
    const [hypotheses12, setHypotheses12]   = useStateWithLabel("", "hypotheses12");
    const [hypotheses13, setHypotheses13]   = useStateWithLabel("", "hypotheses13");
    const [hypotheses14, setHypotheses14]   = useStateWithLabel("", "hypotheses14");
    const [hypotheses15, setHypotheses15]   = useStateWithLabel("", "hypotheses15");

    const [label4A, setLabel4A]   = useStateWithLabel("Hypotheses", "label4A");
    const [label4B, setLabel4B]   = useStateWithLabel("Value Proposition", "label4B");
    
    //Step 5
    const [proposition1, setProposition1]   = useStateWithLabel("", "proposition1");
    const [proposition2, setProposition2]   = useStateWithLabel("", "proposition2");
    const [proposition3, setProposition3]   = useStateWithLabel("", "proposition3");
    const [proposition4, setProposition4]   = useStateWithLabel("", "proposition4");
    const [proposition5, setProposition5]   = useStateWithLabel("", "proposition5");
    const [proposition6, setProposition6]   = useStateWithLabel("", "proposition6");
    const [proposition7, setProposition7]   = useStateWithLabel("", "proposition7");
    const [proposition8, setProposition8]   = useStateWithLabel("", "proposition8");
    const [proposition9, setProposition9]   = useStateWithLabel("", "proposition9");
    const [proposition10, setProposition10]   = useStateWithLabel("", "proposition10");
    const [proposition11, setProposition11]   = useStateWithLabel("", "proposition11");
    const [proposition12, setProposition12]   = useStateWithLabel("", "proposition12");
    const [proposition13, setProposition13]   = useStateWithLabel("", "proposition13");
    const [proposition14, setProposition14]   = useStateWithLabel("", "proposition14");
    const [proposition15, setProposition15]   = useStateWithLabel("", "proposition15");
    

    const gotoNextStep = () => {
        switch(currentStep){
            case "1" : setCurrentStep("2");     break;
            case "2" : setCurrentStep("3a");    break;
            case "3a" : setCurrentStep("3b");   break;
            case "3b" : setCurrentStep("3c");   break;
            case "3c" : setCurrentStep("3d");   break;
            case "3d" : setCurrentStep("4a");    break;
            case "4a" : setCurrentStep("4b");     break;
        }
    }

    const gotoPrevStep = () => {
        switch(currentStep){
            case "2" : setCurrentStep("1");     break;
            case "3a" : setCurrentStep("2");    break;
            case "3b" : setCurrentStep("3a");   break;
            case "3c" : setCurrentStep("3b");   break;
            case "3d" : setCurrentStep("3c");   break;
            case "4a" : setCurrentStep("3d");    break;
            case "4b" : setCurrentStep("4a");     break;
        }
    }
    
    return (
        <div className="market-research">


            <LeftNavigation />
            <div className="canvas">
                {/* PIXELS */}
                <div className={`custom-panel creativelib table`}>
                    <div className="header">
                        Market Research
                        <div className="subtitle">Gather information about target markets and customers.</div>
                    </div>
                    <button className="btn-add-market-study"><i className="fa fa-plus" aria-hidden="true"></i> Market Study</button>
                    <div className="outline">
                        <ul>
                            <li className={currentStep == "1" ? "active" : ""} onClick={() => setCurrentStep("1")}>
                                <div className={`step`}>1</div>
                                <div className="text">
                                    <div className="title">Segment Definitions (up to 3 as default)</div>
                                    <div className="sub-title">What type of audience do you want to evaluate?</div>
                                </div>
                            </li>
                            <li className={currentStep == "2" ? "active" : ""} onClick={() => setCurrentStep("2")}>
                                <div className={`step`}>2</div>
                                <div className="text">
                                    <div className="title">Screeners</div>
                                    <div className="sub-title">What are the factors to use as filters to isolate the audience segments above?</div>
                                </div>
                            </li>
                            <li onClick={() => setCurrentStep("3a")}>
                                <div className={`step`}>3</div>
                                <div className="text">
                                    <div className="title">Feature Sets</div>
                                </div>
                            </li>
                            <ul>
                                <li className={currentStep == "3a" ? "active" : ""} onClick={() => setCurrentStep("3a")}>
                                    <div className={`step`}>A</div>
                                    <div className="text">
                                        <div className="title">Set 1</div>
                                    </div>
                                </li>
                                <li className={currentStep == "3b" ? "active" : ""} onClick={() => setCurrentStep("3b")}>
                                    <div className={`step`}>B</div>
                                    <div className="text">
                                        <div className="title">Set 2</div>
                                    </div>
                                </li>
                                <li className={currentStep == "3c" ? "active" : ""} onClick={() => setCurrentStep("3c")}>
                                    <div className={`step`}>C</div>
                                    <div className="text">
                                        <div className="title">Set 3</div>
                                    </div>
                                </li>
                                <li className={currentStep == "3d" ? "active" : ""} onClick={() => setCurrentStep("3d")}>
                                    <div className={`step`}>D</div>
                                    <div className="text">
                                        <div className="title">Set 4</div>
                                    </div>
                                </li>
                            </ul>
                            <li onClick={() => setCurrentStep("4a")}>
                                <div className={`step`}>4</div>
                                <div className="text">
                                    <div className="title">Emotional Linguistic Analysis</div>
                                </div>
                            </li>
                            <ul>
                                <li className={currentStep == "4a" ? "active" : ""} onClick={() => setCurrentStep("4a")}>
                                    <div className={`step`}>A</div>
                                    <div className="text">
                                        <div className="title">{label4A}</div>
                                    </div>
                                </li>
                                <li className={currentStep == "4b" ? "active" : ""} onClick={() => setCurrentStep("4b")}>
                                    <div className={`step`}>B</div>
                                    <div className="text">
                                        <div className="title">{label4B}</div>
                                    </div>
                                </li>
                            </ul>
                        </ul>
                    </div>
                    <div className="form-section">
                        <table className="client-name">
                            <tbody>
                                <tr>
                                    <td>Client</td>
                                    <td><input type='text' value={clientName} onChange={(e) => setClientName(e.target.value)}/></td>
                                </tr>
                                <tr>
                                    <td>Brand Name</td>
                                    <td><input type='text' value={brandName} onChange={(e) => setBrandName(e.target.value)}/></td>
                                </tr>
                            </tbody>
                        </table>
                        {
                            currentStep == "4a" && 
                            <div className="dropdown-group">
                                <div className="label">What type of language do you want to test?</div>
                                <div className="select-wrapper">
                                    <Select
                                            onChange={(event) => {setLabel4A(handleDropdownChange(event))}}
                                            className="select-primary"
                                            placeholder={label4A}
                                            value={label4A}
                                            options={[
                                                {label:"Hypotheses",            value:"Hypotheses"},
                                                {label:"Value Propositions",    value:"Value Proposition"},
                                                {label:"Naming",                value:"Naming"},
                                                {label:"Creative Concepts",     value:"Creative Concept"},
                                                {label:"Master Brand Language", value:"Master Brand Language"},
                                            ]}
                                            isClearable={false}
                                            styles={styleSelectBasic} />
                                </div>
                            </div>
                        }
                        {
                            currentStep == "4b" && 
                            <div className="dropdown-group">
                                <div className="label">What type of language do you want to test?</div>
                                <div className="select-wrapper">
                                    <Select
                                            onChange={(event) => {setLabel4B(handleDropdownChange(event))}}
                                            className="select-primary"
                                            placeholder={label4B}
                                            value={label4B}
                                            options={[
                                                {label:"Hypotheses",            value:"Hypotheses"},
                                                {label:"Value Propositions",    value:"Value Proposition"},
                                                {label:"Naming",                value:"Naming"},
                                                {label:"Creative Concepts",     value:"Creative Concept"},
                                                {label:"Master Brand Language", value:"Master Brand Language"},
                                            ]}
                                            isClearable={false}
                                            styles={styleSelectBasic} />
                                </div>
                            </div>
                        }
                        {
                            currentStep == 1 ?
                                <table className='screeners'>
                                    <thead>
                                        <tr>
                                            <th>{parseInt(totalParticipantA) + parseInt(totalParticipantB) + parseInt(totalParticipantC)} Participants</th>
                                            <th></th>
                                            <th></th>
                                            <th>Dimension 1</th>
                                            <th>Dimension 2</th>
                                            <th>Dimension 3</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><input type='text' value={dimension1} onChange={(e) => setDimension1(e.target.value)}/></td>
                                            <td><input type='text' value={dimension2} onChange={(e) => setDimension2(e.target.value)}/></td>
                                            <td><input type='text' value={dimension3} onChange={(e) => setDimension2(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td><input type='text' value={totalParticipantA} onChange={(e) => setTotalParticipantA(e.target.value)}/></td>
                                            <td>Segment 1</td>
                                            <td><input type='text' value={segment1} onChange={(e) => setSegment1(e.target.value)}/></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td><input type='text' value={totalParticipantB} onChange={(e) => setTotalParticipantB(e.target.value)}/></td>
                                            <td>Segment 2</td>
                                            <td><input type='text' value={segment2} onChange={(e) => setSegment2(e.target.value)}/></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td><input type='text' value={totalParticipantC} onChange={(e) => setTotalParticipantC(e.target.value)}/></td>
                                            <td>Segment 3</td>
                                            <td><input type='text' value={segment3} onChange={(e) => setSegment3(e.target.value)}/></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>

                                    </tbody>
                                </table>
                            : currentStep == 2 ?
                                <div className="option-group">
                                    <div className="dropdown-group">
                                        <div className="label"><strong>Employment</strong></div>
                                        <div className="select-wrapper">
                                            <Select
                                                onChange={(event) => {setEmployment(handleDropdownChange(event))}}
                                                className="select-primary"
                                                placeholder={employment}
                                                value={employment}
                                                options={[
                                                    {label:"Employed",      value:"employed"},
                                                    {label:"Unemployed",    value:"unemployed"},
                                                    {label:"Self-employed", value:"self-employed"},
                                                ]}
                                                isClearable={false}
                                                styles={styleSelectBasic} />
                                        </div>
                                    </div>
                                    <div className="dropdown-group">
                                        <div className="label"><strong>Age</strong></div>
                                        <div className="select-wrapper">
                                            <Select
                                                onChange={(event) => {setAge(handleDropdownChange(event))}}
                                                className="select-primary"
                                                placeholder={age}
                                                value={age}
                                                options={[
                                                    {label:"Age 19 - 24",  value:"19-24"},
                                                    {label:"Age: 25 - 30", value:"25-30"},
                                                    {label:"Age: 31 - 36", value:"31-36"},
                                                    {label:"Age: 37 - 42", value:"37-42"},
                                                    {label:"Age: 43 - 48", value:"43-48"},
                                                ]}
                                                isClearable={false}
                                                styles={styleSelectBasic} />
                                        </div>
                                    </div>
                                    <div className="dropdown-group">
                                        <div className="label"><strong>Parental Status</strong></div>
                                        <div className="select-wrapper">
                                            <Select
                                                onChange={(event) => {setParents(handleDropdownChange(event))}}
                                                className="select-primary"
                                                placeholder={parents}
                                                value={parents}
                                                options={[
                                                    {label:"Parent with child living at home.",         value:"parental_status-1"},
                                                    {label:"Parent with child not living at home.",     value:"parental_status-2"},
                                                    {label:"Single Parents",                            value:"parental_status-3"},
                                                ]}
                                                isClearable={false}
                                                styles={styleSelectBasic} />
                                        </div>
                                    </div>
                                    <div className="dropdown-group">
                                        <div className="label"><strong>Marital Status</strong></div>
                                        <div className="select-wrapper">
                                            <Select
                                                onChange={(event) => {setMaritalStatus(handleDropdownChange(event))}}
                                                className="select-primary"
                                                placeholder={maritalStatus}
                                                value={maritalStatus}
                                                options={[
                                                    {label:"Married",   value:"married"},
                                                    {label:"Single",    value:"single"},
                                                ]}
                                                isClearable={false}
                                                styles={styleSelectBasic} />
                                        </div>
                                    </div>
                                    <div className="dropdown-group">
                                        <div className="label"><strong>Gender</strong></div>
                                        <div className="select-wrapper">
                                            <Select
                                                onChange={(event) => {setGender(handleDropdownChange(event))}}
                                                className="select-primary"
                                                placeholder={gender}
                                                value={gender}
                                                options={[
                                                    {label:"Male",   value:"Male"},
                                                    {label:"Female",    value:"Female"},
                                                ]}
                                                isClearable={false}
                                                styles={styleSelectBasic} />
                                        </div>
                                    </div>
                                    <div className="dropdown-group">
                                        <div className="label"><strong>Ethnicities</strong></div>
                                        <div className="select-wrapper">
                                            <Select
                                                onChange={(event) => {setEthnicities(handleDropdownChange(event))}}
                                                className="select-primary"
                                                placeholder={ethnicities}
                                                value={ethnicities}
                                                options={[
                                                    {label:"Caucasian",         value:"caucasian"},
                                                    {label:"African American",  value:"african-american"},
                                                    {label:"Hispanic",          value:"hispanic"},
                                                    {label:"Pacific Islander",  value:"pacific-islander"},
                                                    {label:"Asian",             value:"asian"},
                                                    {label:"Native American",   value:"native-american"},
                                                    {label:"Black",             value:"black"},
                                                    {label:"Other",             value:"other"},
                                                ]}
                                                isClearable={false}
                                                styles={styleSelectBasic} />
                                        </div>
                                    </div>
                                    <div className="dropdown-group">
                                        <div className="label"><strong>Household Income</strong></div>
                                        <div className="select-wrapper">
                                            <Select
                                                onChange={(event) => {setHouseholdIncome(handleDropdownChange(event))}}
                                                className="select-primary"
                                                placeholder={householdIncome}
                                                value={householdIncome}
                                                options={[
                                                    {label:"HHI 25th",  value:"HHI-25th"},
                                                    {label:"HHI 50th",  value:"HHI-50th"},
                                                    {label:"HHI 75th",  value:"HHI-75th"},
                                                    {label:"HHI 100th", value:"HHI-100th"},
                                                ]}
                                                isClearable={false}
                                                styles={styleSelectBasic} />
                                        </div>
                                    </div>
                                    <div className="dropdown-group">
                                        <div className="label"><strong>Ocucpation Category</strong></div>
                                        <div className="select-wrapper">
                                            <Select
                                                onChange={(event) => {setOccupation(handleDropdownChange(event))}}
                                                className="select-primary"
                                                placeholder={occupation}
                                                value={occupation}
                                                options={[
                                                    {label:"Accountant",  value:"Accountant"},
                                                    {label:"Architect",  value:"Architect"},
                                                    {label:"Designer",  value:"Designer"},
                                                    {label:"Doctor", value:"Doctor"},
                                                    {label:"Engineer", value:"Engineer"},
                                                    {label:"Factory worker", value:"Factory worker"},
                                                ]}
                                                isClearable={false}
                                                styles={styleSelectBasic} />
                                        </div>
                                    </div>
                                    <div className="dropdown-group">
                                        <div className="label"><strong>Geo Country</strong></div>
                                        <div className="select-wrapper">
                                            <Select
                                                onChange={(event) => {setGeo(handleDropdownChange(event))}}
                                                className="select-primary"
                                                placeholder={geo}
                                                value={geo}
                                                options={[
                                                    {label:'Afghanistan',  value:'Afghanistan'},
                                                    {label:'Albania',  value:'Albania'},
                                                    {label:'Algeria',  value:'Algeria'},
                                                    {label:'Andorra',  value:'Andorra'},
                                                    {label:'Angola',  value:'Angola'},
                                                    {label:'Antigua & Deps',  value:'Antigua & Deps'},
                                                    {label:'Argentina',  value:'Argentina'},
                                                    {label:'Armenia',  value:'Armenia'},
                                                    {label:'Australia',  value:'Australia'},
                                                    {label:'Austria',  value:'Austria'},
                                                    {label:'Azerbaijan',  value:'Azerbaijan'},
                                                    {label:'Bahamas',  value:'Bahamas'},
                                                    {label:'Bahrain',  value:'Bahrain'},
                                                    {label:'Bangladesh',  value:'Bangladesh'},
                                                    {label:'Barbados',  value:'Barbados'},
                                                    {label:'Belarus',  value:'Belarus'},
                                                    {label:'Belgium',  value:'Belgium'},
                                                    {label:'Belize',  value:'Belize'},
                                                    {label:'Benin',  value:'Benin'},
                                                    {label:'Bhutan',  value:'Bhutan'},
                                                    {label:'Bolivia',  value:'Bolivia'},
                                                    {label:'Bosnia Herzegovina',  value:'Bosnia Herzegovina'},
                                                    {label:'Botswana',  value:'Botswana'},
                                                    {label:'Brazil',  value:'Brazil'},
                                                    {label:'Brunei',  value:'Brunei'},
                                                    {label:'Bulgaria',  value:'Bulgaria'},
                                                    {label:'Burkina',  value:'Burkina'},
                                                    {label:'Burundi',  value:'Burundi'},
                                                    {label:'Cambodia',  value:'Cambodia'},
                                                    {label:'Cameroon',  value:'Cameroon'},
                                                    {label:'Canada',  value:'Canada'},
                                                    {label:'Cape Verde',  value:'Cape Verde'},
                                                    {label:'Central African Rep',  value:'Central African Rep'},
                                                    {label:'Chad',  value:'Chad'},
                                                    {label:'Chile',  value:'Chile'},
                                                    {label:'China',  value:'China'},
                                                    {label:'Colombia',  value:'Colombia'},
                                                    {label:'Comoros',  value:'Comoros'},
                                                    {label:'Congo',  value:'Congo'},
                                                    {label:'Congo {Democratic Rep}',  value:'Congo {Democratic Rep}'},
                                                    {label:'Costa Rica',  value:'Costa Rica'},
                                                    {label:'Croatia',  value:'Croatia'},
                                                    {label:'Cuba',  value:'Cuba'},
                                                    {label:'Cyprus',  value:'Cyprus'},
                                                    {label:'Czech Republic',  value:'Czech Republic'},
                                                    {label:'Denmark',  value:'Denmark'},
                                                    {label:'Djibouti',  value:'Djibouti'},
                                                    {label:'Dominica',  value:'Dominica'},
                                                    {label:'Dominican Republic',  value:'Dominican Republic'},
                                                    {label:'East Timor',  value:'East Timor'},
                                                    {label:'Ecuador',  value:'Ecuador'},
                                                    {label:'Egypt',  value:'Egypt'},
                                                    {label:'El Salvador',  value:'El Salvador'},
                                                    {label:'Equatorial Guinea',  value:'Equatorial Guinea'},
                                                    {label:'Eritrea',  value:'Eritrea'},
                                                    {label:'Estonia',  value:'Estonia'},
                                                    {label:'Eswatini',  value:'Eswatini'},
                                                    {label:'Ethiopia',  value:'Ethiopia'},
                                                    {label:'Fiji',  value:'Fiji'},
                                                    {label:'Finland',  value:'Finland'},
                                                    {label:'France',  value:'France'},
                                                    {label:'Gabon',  value:'Gabon'},
                                                    {label:'Gambia',  value:'Gambia'},
                                                    {label:'Georgia',  value:'Georgia'},
                                                    {label:'Germany',  value:'Germany'},
                                                    {label:'Ghana',  value:'Ghana'},
                                                    {label:'Greece',  value:'Greece'},
                                                    {label:'Grenada',  value:'Grenada'},
                                                    {label:'Guatemala',  value:'Guatemala'},
                                                    {label:'Guinea',  value:'Guinea'},
                                                    {label:'Guinea-Bissau',  value:'Guinea-Bissau'},
                                                    {label:'Guyana',  value:'Guyana'},
                                                    {label:'Haiti',  value:'Haiti'},
                                                    {label:'Honduras',  value:'Honduras'},
                                                    {label:'Hungary',  value:'Hungary'},
                                                    {label:'Iceland',  value:'Iceland'},
                                                    {label:'India',  value:'India'},
                                                    {label:'Indonesia',  value:'Indonesia'},
                                                    {label:'Iran',  value:'Iran'},
                                                    {label:'Iraq',  value:'Iraq'},
                                                    {label:'Ireland {Republic}',  value:'Ireland {Republic}'},
                                                    {label:'Israel',  value:'Israel'},
                                                    {label:'Italy',  value:'Italy'},
                                                    {label:'Ivory Coast',  value:'Ivory Coast'},
                                                    {label:'Jamaica',  value:'Jamaica'},
                                                    {label:'Japan',  value:'Japan'},
                                                    {label:'Jordan',  value:'Jordan'},
                                                    {label:'Kazakhstan',  value:'Kazakhstan'},
                                                    {label:'Kenya',  value:'Kenya'},
                                                    {label:'Kiribati',  value:'Kiribati'},
                                                    {label:'Korea North',  value:'Korea North'},
                                                    {label:'Korea South',  value:'Korea South'},
                                                    {label:'Kosovo',  value:'Kosovo'},
                                                    {label:'Kuwait',  value:'Kuwait'},
                                                    {label:'Kyrgyzstan',  value:'Kyrgyzstan'},
                                                    {label:'Laos',  value:'Laos'},
                                                    {label:'Latvia',  value:'Latvia'},
                                                    {label:'Lebanon',  value:'Lebanon'},
                                                    {label:'Lesotho',  value:'Lesotho'},
                                                    {label:'Liberia',  value:'Liberia'},
                                                    {label:'Libya',  value:'Libya'},
                                                    {label:'Liechtenstein',  value:'Liechtenstein'},
                                                    {label:'Lithuania',  value:'Lithuania'},
                                                    {label:'Luxembourg',  value:'Luxembourg'},
                                                    {label:'Macedonia',  value:'Macedonia'},
                                                    {label:'Madagascar',  value:'Madagascar'},
                                                    {label:'Malawi',  value:'Malawi'},
                                                    {label:'Malaysia',  value:'Malaysia'},
                                                    {label:'Maldives',  value:'Maldives'},
                                                    {label:'Mali',  value:'Mali'},
                                                    {label:'Malta',  value:'Malta'},
                                                    {label:'Marshall Islands',  value:'Marshall Islands'},
                                                    {label:'Mauritania',  value:'Mauritania'},
                                                    {label:'Mauritius',  value:'Mauritius'},
                                                    {label:'Mexico',  value:'Mexico'},
                                                    {label:'Micronesia',  value:'Micronesia'},
                                                    {label:'Moldova',  value:'Moldova'},
                                                    {label:'Monaco',  value:'Monaco'},
                                                    {label:'Mongolia',  value:'Mongolia'},
                                                    {label:'Montenegro',  value:'Montenegro'},
                                                    {label:'Morocco',  value:'Morocco'},
                                                    {label:'Mozambique',  value:'Mozambique'},
                                                    {label:'Myanmar',  value:'Myanmar'},
                                                    {label:'Namibia',  value:'Namibia'},
                                                    {label:'Nauru',  value:'Nauru'},
                                                    {label:'Nepal',  value:'Nepal'},
                                                    {label:'Netherlands',  value:'Netherlands'},
                                                    {label:'New Zealand',  value:'New Zealand'},
                                                    {label:'Nicaragua',  value:'Nicaragua'},
                                                    {label:'Niger',  value:'Niger'},
                                                    {label:'Nigeria',  value:'Nigeria'},
                                                    {label:'Norway',  value:'Norway'},
                                                    {label:'Oman',  value:'Oman'},
                                                    {label:'Pakistan',  value:'Pakistan'},
                                                    {label:'Palau',  value:'Palau'},
                                                    {label:'Palestine',  value:'Palestine'},
                                                    {label:'Panama',  value:'Panama'},
                                                    {label:'Papua New Guinea',  value:'Papua New Guinea'},
                                                    {label:'Paraguay',  value:'Paraguay'},
                                                    {label:'Peru',  value:'Peru'},
                                                    {label:'Philippines',  value:'Philippines'},
                                                    {label:'Poland',  value:'Poland'},
                                                    {label:'Portugal',  value:'Portugal'},
                                                    {label:'Qatar',  value:'Qatar'},
                                                    {label:'Romania',  value:'Romania'},
                                                    {label:'Russian Federation',  value:'Russian Federation'},
                                                    {label:'Rwanda',  value:'Rwanda'},
                                                    {label:'St Kitts & Nevis',  value:'St Kitts & Nevis'},
                                                    {label:'St Lucia',  value:'St Lucia'},
                                                    {label:'Saint Vincent & the Grenadines',  value:'Saint Vincent & the Grenadines'},
                                                    {label:'Samoa',  value:'Samoa'},
                                                    {label:'San Marino',  value:'San Marino'},
                                                    {label:'Sao Tome & Principe',  value:'Sao Tome & Principe'},
                                                    {label:'Saudi Arabia',  value:'Saudi Arabia'},
                                                    {label:'Senegal',  value:'Senegal'},
                                                    {label:'Serbia',  value:'Serbia'},
                                                    {label:'Seychelles',  value:'Seychelles'},
                                                    {label:'Sierra Leone',  value:'Sierra Leone'},
                                                    {label:'Singapore',  value:'Singapore'},
                                                    {label:'Slovakia',  value:'Slovakia'},
                                                    {label:'Slovenia',  value:'Slovenia'},
                                                    {label:'Solomon Islands',  value:'Solomon Islands'},
                                                    {label:'Somalia',  value:'Somalia'},
                                                    {label:'South Africa',  value:'South Africa'},
                                                    {label:'South Sudan',  value:'South Sudan'},
                                                    {label:'Spain',  value:'Spain'},
                                                    {label:'Sri Lanka',  value:'Sri Lanka'},
                                                    {label:'Sudan',  value:'Sudan'},
                                                    {label:'Suriname',  value:'Suriname'},
                                                    {label:'Sweden',  value:'Sweden'},
                                                    {label:'Switzerland',  value:'Switzerland'},
                                                    {label:'Syria',  value:'Syria'},
                                                    {label:'Taiwan',  value:'Taiwan'},
                                                    {label:'Tajikistan',  value:'Tajikistan'},
                                                    {label:'Tanzania',  value:'Tanzania'},
                                                    {label:'Thailand',  value:'Thailand'},
                                                    {label:'Togo',  value:'Togo'},
                                                    {label:'Tonga',  value:'Tonga'},
                                                    {label:'Trinidad & Tobago',  value:'Trinidad & Tobago'},
                                                    {label:'Tunisia',  value:'Tunisia'},
                                                    {label:'Turkey',  value:'Turkey'},
                                                    {label:'Turkmenistan',  value:'Turkmenistan'},
                                                    {label:'Tuvalu',  value:'Tuvalu'},
                                                    {label:'Uganda',  value:'Uganda'},
                                                    {label:'Ukraine',  value:'Ukraine'},
                                                    {label:'United Arab Emirates',  value:'United Arab Emirates'},
                                                    {label:'United Kingdom',  value:'United Kingdom'},
                                                    {label:'United States',  value:'United States'},
                                                    {label:'Uruguay',  value:'Uruguay'},
                                                    {label:'Uzbekistan',  value:'Uzbekistan'},
                                                    {label:'Vanuatu',  value:'Vanuatu'},
                                                    {label:'Vatican City',  value:'Vatican City'},
                                                    {label:'Venezuela',  value:'Venezuela'},
                                                    {label:'Vietnam',  value:'Vietnam'},
                                                    {label:'Yemen',  value:'Yemen'},
                                                    {label:'Zambia',  value:'Zambia'},
                                                    {label:'Zimbabwe',  value:'Zimbabwe'},
                                                ]}
                                                isClearable={false}
                                                styles={styleSelectBasic} />
                                        </div>
                                    </div>
                                </div>
                                // <table>
                                //     <tbody>
                                //         {/* <tr>
                                //             <td>Marital Status</td>
                                //             <td><input type='text' value={maritalStatus} onChange={(e) => setMaritalStatus(e.target.value)}/></td>
                                //         </tr>
                                //         <tr>
                                //             <td>Parents</td>
                                //             <td><input type='text' value={parents} onChange={(e) => setParents(e.target.value)}/></td>
                                //         </tr>
                                //         <tr>
                                //             <td>Custom</td>
                                //             <td><input type='text' value={customScreener} onChange={(e) => setCustomScreener(e.target.value)}/></td>
                                //         </tr> */}
                                //     </tbody>
                                // </table>
                            : currentStep == "3a" ?
                                <table className="question">
                                    <tbody>
                                        <tr>
                                            <td>Prompt</td>
                                            <td><input type='text' value={audienceSegment1Prompt} onChange={(e) => setAudienceSegment1Prompt(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 1</td>
                                            <td><input type='text' value={audienceSegment1Question1} onChange={(e) => setAudienceSegment1Question1(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 2</td>
                                            <td><input type='text' value={audienceSegment1Question2} onChange={(e) => setAudienceSegment1Question2(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 3</td>
                                            <td><input type='text' value={audienceSegment1Question3} onChange={(e) => setAudienceSegment1Question3(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 4</td>
                                            <td><input type='text' value={audienceSegment1Question4} onChange={(e) => setAudienceSegment1Question4(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 5</td>
                                            <td><input type='text' value={audienceSegment1Question5} onChange={(e) => setAudienceSegment1Question5(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 6</td>
                                            <td><input type='text' value={audienceSegment1Question6} onChange={(e) => setAudienceSegment1Question6(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 7</td>
                                            <td><input type='text' value={audienceSegment1Question7} onChange={(e) => setAudienceSegment1Question7(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 8</td>
                                            <td><input type='text' value={audienceSegment1Question8} onChange={(e) => setAudienceSegment1Question8(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 9</td>
                                            <td><input type='text' value={audienceSegment1Question9} onChange={(e) => setAudienceSegment1Question9(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 10</td>
                                            <td><input type='text' value={audienceSegment1Question10} onChange={(e) => setAudienceSegment1Question10(e.target.value)}/></td>
                                        </tr>
                                    </tbody>
                                </table>
                            : currentStep == "3b" ?
                                <table className="question">
                                    <tbody>
                                        <tr>
                                            <td>Prompt</td>
                                            <td><input type='text' value={audienceSegment2Prompt} onChange={(e) => setAudienceSegment2Prompt(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 1</td>
                                            <td><input type='text' value={audienceSegment2Question1} onChange={(e) => setAudienceSegment2Question1(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 2</td>
                                            <td><input type='text' value={audienceSegment2Question2} onChange={(e) => setAudienceSegment2Question2(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 3</td>
                                            <td><input type='text' value={audienceSegment2Question3} onChange={(e) => setAudienceSegment2Question3(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 4</td>
                                            <td><input type='text' value={audienceSegment2Question4} onChange={(e) => setAudienceSegment2Question4(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 5</td>
                                            <td><input type='text' value={audienceSegment2Question5} onChange={(e) => setAudienceSegment2Question5(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 6</td>
                                            <td><input type='text' value={audienceSegment2Question6} onChange={(e) => setAudienceSegment2Question6(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 7</td>
                                            <td><input type='text' value={audienceSegment2Question7} onChange={(e) => setAudienceSegment2Question7(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 8</td>
                                            <td><input type='text' value={audienceSegment2Question8} onChange={(e) => setAudienceSegment2Question8(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 9</td>
                                            <td><input type='text' value={audienceSegment2Question9} onChange={(e) => setAudienceSegment2Question9(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 10</td>
                                            <td><input type='text' value={audienceSegment2Question10} onChange={(e) => setAudienceSegment2Question10(e.target.value)}/></td>
                                        </tr>
                                    </tbody>
                                </table>
                            : currentStep == "3c" ?
                                <table className="question">
                                    <tbody>
                                        <tr>
                                            <td>Prompt</td>
                                            <td><input type='text' value={audienceSegment3Prompt} onChange={(e) => setAudienceSegment3Prompt(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 1</td>
                                            <td><input type='text' value={audienceSegment3Question1} onChange={(e) => setAudienceSegment3Question1(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 2</td>
                                            <td><input type='text' value={audienceSegment3Question2} onChange={(e) => setAudienceSegment3Question2(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 3</td>
                                            <td><input type='text' value={audienceSegment3Question3} onChange={(e) => setAudienceSegment3Question3(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 4</td>
                                            <td><input type='text' value={audienceSegment3Question4} onChange={(e) => setAudienceSegment3Question4(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 5</td>
                                            <td><input type='text' value={audienceSegment3Question5} onChange={(e) => setAudienceSegment3Question5(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 6</td>
                                            <td><input type='text' value={audienceSegment3Question6} onChange={(e) => setAudienceSegment3Question6(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 7</td>
                                            <td><input type='text' value={audienceSegment3Question7} onChange={(e) => setAudienceSegment3Question7(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 8</td>
                                            <td><input type='text' value={audienceSegment3Question8} onChange={(e) => setAudienceSegment3Question8(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 9</td>
                                            <td><input type='text' value={audienceSegment3Question9} onChange={(e) => setAudienceSegment3Question9(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 10</td>
                                            <td><input type='text' value={audienceSegment3Question10} onChange={(e) => setAudienceSegment3Question10(e.target.value)}/></td>
                                        </tr>
                                    </tbody>
                                </table>
                            : currentStep == "3d" ?
                                <table className="question">
                                    <tbody>
                                        <tr>
                                            <td>Prompt</td>
                                            <td><input type='text' value={audienceSegment4Prompt} onChange={(e) => setAudienceSegment4Prompt(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 1</td>
                                            <td><input type='text' value={audienceSegment4Question1} onChange={(e) => setAudienceSegment4Question1(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 2</td>
                                            <td><input type='text' value={audienceSegment4Question2} onChange={(e) => setAudienceSegment4Question2(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 3</td>
                                            <td><input type='text' value={audienceSegment4Question3} onChange={(e) => setAudienceSegment4Question3(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 4</td>
                                            <td><input type='text' value={audienceSegment4Question4} onChange={(e) => setAudienceSegment4Question4(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 5</td>
                                            <td><input type='text' value={audienceSegment4Question5} onChange={(e) => setAudienceSegment4Question5(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 6</td>
                                            <td><input type='text' value={audienceSegment4Question6} onChange={(e) => setAudienceSegment4Question6(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 7</td>
                                            <td><input type='text' value={audienceSegment4Question7} onChange={(e) => setAudienceSegment4Question7(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 8</td>
                                            <td><input type='text' value={audienceSegment4Question8} onChange={(e) => setAudienceSegment4Question8(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 9</td>
                                            <td><input type='text' value={audienceSegment4Question9} onChange={(e) => setAudienceSegment4Question9(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>Feature statement 10</td>
                                            <td><input type='text' value={audienceSegment4Question10} onChange={(e) => setAudienceSegment4Question10(e.target.value)}/></td>
                                        </tr>
                                    </tbody>
                                </table>
                            : currentStep == "4a" ?
                                <table className="question">
                                    <tbody>
                                        <tr>
                                            <td>{label4A} 1</td>
                                            <td><input type='text' value={hypotheses1} onChange={(e) => setHypotheses1(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4A} 2</td>
                                            <td><input type='text' value={hypotheses2} onChange={(e) => setHypotheses2(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4A} 3</td>
                                            <td><input type='text' value={hypotheses3} onChange={(e) => setHypotheses3(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4A} 4</td>
                                            <td><input type='text' value={hypotheses4} onChange={(e) => setHypotheses4(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4A} 5</td>
                                            <td><input type='text' value={hypotheses5} onChange={(e) => setHypotheses5(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4A} 6</td>
                                            <td><input type='text' value={hypotheses6} onChange={(e) => setHypotheses6(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4A} 7</td>
                                            <td><input type='text' value={hypotheses7} onChange={(e) => setHypotheses7(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4A} 8</td>
                                            <td><input type='text' value={hypotheses8} onChange={(e) => setHypotheses8(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4A} 9</td>
                                            <td><input type='text' value={hypotheses9} onChange={(e) => setHypotheses9(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4A} 10</td>
                                            <td><input type='text' value={hypotheses10} onChange={(e) => setHypotheses10(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4A} 11</td>
                                            <td><input type='text' value={hypotheses11} onChange={(e) => setHypotheses11(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4A} 12</td>
                                            <td><input type='text' value={hypotheses12} onChange={(e) => setHypotheses12(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4A} 13</td>
                                            <td><input type='text' value={hypotheses13} onChange={(e) => setHypotheses13(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4A} 14</td>
                                            <td><input type='text' value={hypotheses14} onChange={(e) => setHypotheses14(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4A} 15</td>
                                            <td><input type='text' value={hypotheses15} onChange={(e) => setHypotheses15(e.target.value)}/></td>
                                        </tr>
                                    </tbody>
                                </table>
                            : currentStep == "4b" ?
                                <table className="question">
                                    <tbody>
                                        <tr>
                                            <td>{label4B} 1</td>
                                            <td><input type='text' value={proposition1} onChange={(e) => setProposition1(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4B} 2</td>
                                            <td><input type='text' value={proposition2} onChange={(e) => setProposition2(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4B} 3</td>
                                            <td><input type='text' value={proposition3} onChange={(e) => setProposition3(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4B} 4</td>
                                            <td><input type='text' value={proposition4} onChange={(e) => setProposition4(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4B} 5</td>
                                            <td><input type='text' value={proposition5} onChange={(e) => setProposition5(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4B} 6</td>
                                            <td><input type='text' value={proposition6} onChange={(e) => setProposition6(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4B} 7</td>
                                            <td><input type='text' value={proposition7} onChange={(e) => setProposition7(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4B} 8</td>
                                            <td><input type='text' value={proposition8} onChange={(e) => setProposition8(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4B} 9</td>
                                            <td><input type='text' value={proposition9} onChange={(e) => setProposition9(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4B} 10</td>
                                            <td><input type='text' value={proposition10} onChange={(e) => setProposition10(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4B} 11</td>
                                            <td><input type='text' value={proposition11} onChange={(e) => setProposition11(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4B} 12</td>
                                            <td><input type='text' value={proposition12} onChange={(e) => setProposition12(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4B} 13</td>
                                            <td><input type='text' value={proposition13} onChange={(e) => setProposition13(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4B} 14</td>
                                            <td><input type='text' value={proposition14} onChange={(e) => setProposition14(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td>{label4B} 15</td>
                                            <td><input type='text' value={proposition15} onChange={(e) => setProposition15(e.target.value)}/></td>
                                        </tr>
                                    </tbody>
                                </table>
                            : <></>
                        }
                        <div className="controls">
                            {
                                currentStep == "5" ?
                                <>
                                    <button type="button" onClick={() => gotoPrevStep()} className={`${currentStep == "1" ? "inactive" : ""} btn btn-primary btn-sm btn-actions`}><i className="fa fa-caret-left" aria-hidden="true"></i> Back</button>
                                    <button type="button" className={`${currentStep == "1" ? "inactive" : ""} btn btn-primary btn-sm btn-actions`}><i className="fa fa-check" aria-hidden="true"></i> Submit</button>
                                </>
                                :
                                <>
                                    <button type="button" onClick={() => gotoPrevStep()} className={`${currentStep == "1" ? "inactive" : ""} btn btn-primary btn-sm btn-actions`}><i className="fa fa-caret-left" aria-hidden="true"></i> Back</button>
                                    <button type="button" onClick={() => gotoNextStep()} className={`${currentStep == "5" ? "inactive" : ""} btn btn-primary btn-sm btn-actions`}>Next <i className="fa fa-caret-right" aria-hidden="true"></i></button>
                                </>
                            }
                        </div>
                    </div>
                </div>
                
            </div>

            <Footer />
        </div>
    )
}

const mapState = state => ({
    user:state.authentication,
    users:state.users,
    pixels:state.pixels,
    audiences:state.audiences,
})

const actionCreators = {
    getPixelsByAccountId: pixelActions.getPixelsByAccountId,
    updateAudience: audienceActions.updateAudience,
    createAudience: audienceActions.createAudience,
    updatePixel: pixelActions.updatePixel,
    createPixel: pixelActions.createPixel,
    getAudiencesByPixelId: audienceActions.getAudiencesByPixelId,
}

const connectedMarketResearchPage2 = connect(mapState, actionCreators)(MarketResearchPage2);

export { connectedMarketResearchPage2 as MarketResearchPage2 };