import config from 'config';
import { authHeader } from '../_helpers';

export const userService = {
    login,
    authenticateJWT,
    logout,
    register,
    getAll,
    getById,
    update: _update,
    updateMyInfo,
    approve,
    delete: _delete,
    getByAccountId,
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(`${config.apiURL}/users/authenticateUsername`, requestOptions)
        .then(handleResponse)
        .then(result => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('token', result.token);
            localStorage.setItem('r', result.r);
            return result;
        });
}

function authenticateJWT(token) {
    const requestOptions = {
        method: 'POST',
        headers: { 'x-access-token': token },
    };

    return fetch(`${config.apiURL}/users/authenticateToken`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            //localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
    localStorage.removeItem('user');
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/users`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/users/${id}`, requestOptions).then(handleResponse);
}

function getByAccountId(accountId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/users/a/${accountId}`, requestOptions).then(handleResponse);
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${config.apiURL}/users/register`, requestOptions).then(handleResponse);
}

function _update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${config.apiURL}/users/${user.id}`, requestOptions).then(handleResponse);;
}

function updateMyInfo(user) {
    const submitted = user;
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${config.apiURL}/users/${user.id}`, requestOptions)
    .then(handleResponse)
    .then(user => {
        user = JSON.parse(localStorage.user)
        user.companyName = submitted.companyName
        user.firstName = submitted.firstName
        user.lastName = submitted.lastName
        user.position = submitted.position
        user.companyWebsite = submitted.companyWebsite
        localStorage.setItem('user', JSON.stringify(user));
        return user;
    });
}


function approve(userId) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    return fetch(`${config.apiURL}/users/approve/${userId}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(user) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/users/${user.id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}