import { measurementConstants } from '../_constants';

export function measurement(state = {}, action) {
  switch (action.type) {
    //=====================================
    // GET SERVICES
    //=====================================
    case measurementConstants.GET_SERVICES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case measurementConstants.GET_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.services,
        loading: false
      };
    case measurementConstants.GET_SERVICES_FAILURE:
      return { 
        ...state,
        error: action.error,
        loading: false
      };
    //=====================================
    // GET CONVERSION DEFINITION
    //=====================================
    case measurementConstants.GET_CONVERSION_DEFINITION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case measurementConstants.GET_CONVERSION_DEFINITION_SUCCESS:
      return {
        ...state,
        conversion_definitions: action.conversion_definition,
        loading: false
      };
    case measurementConstants.GET_CONVERSION_DEFINITION_FAILURE:
      return { 
        ...state,
        error: action.error,
        loading: false
      };
    //=====================================
    // GET AUDIENCES
    //=====================================
    case measurementConstants.GET_AUDIENCES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case measurementConstants.GET_AUDIENCES_SUCCESS:
      return {
        ...state,
        audiences: action.audiences,
        loading: false
      };
    case measurementConstants.GET_AUDIENCES_FAILURE:
      return { 
        ...state,
        error: action.error,
        loading: false
      };
    //=====================================
    // UPDATE CONVERSION DEFINITION
    //=====================================
    case measurementConstants.UPDATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case measurementConstants.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        conversion_definitions: action.conversionDefinition
      };
    case measurementConstants.UPDATE_FAILURE:
      return { 
        ...state,
        loading: false,
        error: action.error
      };
    //=====================================
    // CREATE CONVERSION DEFINITION
    //=====================================
    case measurementConstants.CREATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case measurementConstants.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        conversion_definitions: action.conversionDefinition
      };
    case measurementConstants.CREATE_FAILURE:
      return { 
        ...state,
        loading: false,
        error: action.error
      };
      //=====================================
      // DEFAULT
      //=====================================
    default:
      return state
  }
}