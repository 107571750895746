import { adsConstants } from '../_constants';

export function ads(state = {}, action) {
  switch (action.type) {
    //=====================================
    // GET
    //=====================================
    case adsConstants.GET_REQUEST:
      return {
        isDone: false
      };
    case adsConstants.GET_SUCCESS:
      return {
        items: action.ads,
        isDone: true
      };
    case adsConstants.GET_FAILURE:
      return { 
        error: action.error,
        isDone: true
      };
    //=====================================
    // UPDATE
    //=====================================
    case adsConstants.UPDATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case adsConstants.UPDATE_SUCCESS:
      return {
        ...state,
        isDone: true,
        loading: false,
        items: action.ads,
      };
    case adsConstants.UPDATE_FAILURE:
      return {
        ...state,
        isDone: true,
        loading: false,
        error:action.error
      };
      //=====================================
      // DEFAULT
      //=====================================
    default:
      return state
  }
}