import { sideDrawerConstants } from '../_constants';
const defaultState = {
  isOpen:false,
  form:null,
  purpose:null
}
export function sideDrawer(state = defaultState, action) {
  switch (action.type) {
    case sideDrawerConstants.OPEN:
      return {
        ...state,
        isOpen:true,
        form:action.form,
        purpose:action.purpose
      };
    case sideDrawerConstants.CLOSE:
      return {
        ...state,
        isOpen:false,
        form:action.form,
        purpose:action.purpose
      };
    default:
      return state
  }
}