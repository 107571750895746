import React, { useDebugValue, useEffect, useState, KeyboardEventHandler } from 'react';
//Tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
//Daterange Picker
// import { DateRangePicker } from 'react-dates'
// import 'react-dates/initialize';
// import 'react-dates/lib/css/_datepicker.css';
//New Date range picker
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
//React Draggable
import Draggable from "react-draggable";
//Redux
import { connect } from 'react-redux';
import Select from 'react-select';
import {
    marketResarchActions,
    bluekaiActions
} from '../_actions';
import { Footer } from '../_components/Footer';
import { LeftNavigation } from '../_components/LeftNavigation';


//React Select
import { styleSelectBasic } from '../_constants/settings';
import '../_stylesheets/css/react-toggle.css';

//React Select (Creatable)
import CreatableSelect from 'react-select/creatable';
import { ActionMeta, OnChangeValue } from 'react-select';

//Tree Select
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';

//React bootstrap
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'



const MarketResearchPage = (props) => {

    const useStateWithLabel = (initialValue, label) => {
        const [value, setValue] = useState(initialValue);
        useDebugValue(`${label}: ${value}`);
        return [value, setValue];
    };

    
    const [selectedClient, setSelectedClient]                       = useStateWithLabel(null, "selectedClient");
    const [selectedBrand, setSelectedBrand]                         = useStateWithLabel(null, "selectedBrand");
    const [selectedMarketStudy, setSelectedMarketStudy]             = useStateWithLabel(null, "selectedMarketStudy");
    const [isBrandNameNeedToChange, setIsBrandNameNeedToChange]     = useStateWithLabel(false, "isBrandNameNeedToChange");
    const [isNewMarketStudy, setIsNewMarketStudy]                   = useStateWithLabel(false, "isNewMarketStudy");
    const [isExampleShown, setIsExampleShown]                       = useStateWithLabel(false, "isExampleShown");
    const [isResultShown, setIsResultShown]                         = useStateWithLabel(false, "isResultShown");
    const [isFormShown, setIsFormShown]                             = useStateWithLabel(false, "isFormShown");
    const [isCreativeAnalysisShown, setIsCreativeAnalysisShown]     = useStateWithLabel(false, "isCreativeAnalysisShown");
    const [hasChanges, setHasChanges]                               = useStateWithLabel(false, "hasChanges");
    const [activeReport, setActiveReport]                           = useStateWithLabel("1a", "activeReport");
    const [activeCreativeAnalysis, setActiveCreativeAnalysis]       = useStateWithLabel("slide2", "activeCreativeAnalysis");
    const [isCreateClientFormVisible, setIsCreateClientFormVisible] = useStateWithLabel(false, "isCreateClientFormVisible");
    const [isCreateBrandFormVisible, setIsCreateBrandFormVisible]   = useStateWithLabel(false, "isCreateBrandFormVisible");
    const [newClient, setNewClient]                                 = useStateWithLabel("", "newClient");
    const [newBrand, setNewBrand]                                   = useStateWithLabel("", "newBrand");

    //Tabs
    const [tabKey, setTabKey] = useStateWithLabel("BRAND_STUDY", "tabKey");

    //Bluekai
    const [optionBluekai, setOptionBluekai] = useStateWithLabel([], "optionBluekai");
    const [whiteListedBluekai, setWhiteListedBluekai] = useStateWithLabel([], "whiteListedBluekai");
    const [blackListedBluekai, setBlackListedBluekai] = useStateWithLabel([], "blackListedBluekai");
    
    //For Additional Screener
    const [additionalScreenerOption, setAdditionalScreenerOption]   = useStateWithLabel([], "additionalScreenerOption");

    const {bluekai, user, marketResearch} = props;

    const getSelectedStudy = (prop, selectedStudyTitle) => {
        let item = marketResearch.items && marketResearch.items.filter(item => item.brand == selectedBrand && item.client == selectedClient && item.title == selectedStudyTitle)[0]
        if(item == undefined){
            return ""
        }
        else{
            return item[prop]
        }
    }

    //Handles adding new item in the Select options separated by comma
    const handleCreateableChange = (
      ) => {
        console.group('Value Changed');
        // console.log(newValue);
        // console.log(`action: ${actionMeta.action}`);
        // console.groupEnd();
    };
    const createOption = (label) => ({
        label,
        value: label,
    });
    const isNewCustomScreenerValid = () => {
        console.log(additionalScreenerOption.filter(item => item.question == "" || item.options.length == 0).length)
        return additionalScreenerOption.filter(item => item.question == "" || item.options.length == 0).length == 0
    }


    const [currentStep, setCurrentStep] = useStateWithLabel("1", "setCurrentStep");
    
    //Client and Brand Name
    const [id, setId]                   = useStateWithLabel("", "id");
    const [title, setTitle]             = useStateWithLabel("", "title");
    const [clientName, setClientName]   = useStateWithLabel("", "clientName");
    const [brandName, setBrandName]     = useStateWithLabel("", "brandName");

    //Step 1
    const [dimension1, setDimension1]   = useStateWithLabel("", "dimension1");
    const [dimension2, setDimension2]   = useStateWithLabel("", "dimension2");
    const [dimension3, setDimension3]   = useStateWithLabel("", "dimension3");
    const [segment1, setSegment1]       = useStateWithLabel("", "segment1");
    const [segment2, setSegment2]       = useStateWithLabel("", "segment2");
    const [segment3, setSegment3]       = useStateWithLabel("", "segment3");

    const [totalParticipantA, setTotalParticipantA]       = useStateWithLabel(60, "totalParticipantA");
    const [totalParticipantB, setTotalParticipantB]       = useStateWithLabel(60, "totalParticipantB");
    const [totalParticipantC, setTotalParticipantC]       = useStateWithLabel(60, "totalParticipantB");
    
    //Step 2
    const [geo, setGeo]                         = useStateWithLabel("", "geo");
    const [employment, setEmployment]           = useStateWithLabel("", "employment");
    const [maritalStatus, setMaritalStatus]     = useStateWithLabel("", "maritalStatus");
    const [parents, setParents]                 = useStateWithLabel("", "parents");
    const [occupation, setOccupation]           = useStateWithLabel("", "occupation");
    const [ethnicities, setEthnicities]         = useStateWithLabel("", "ethnicities");
    const [gender, setGender]                   = useStateWithLabel("", "gender");
    const [age, setAge]                         = useStateWithLabel("", "age");
    const [householdIncome, setHouseholdIncome] = useStateWithLabel("", "householdIncome");
    const [customScreener, setCustomScreener]   = useStateWithLabel([], "customScreener");
    
    //Step 3a
    const [audienceSegment1MarketingFunnel, setAudienceSegment1MarketingFunnel]         = useStateWithLabel("", "audienceSegment1MarketingFunnel");
    const [audienceSegment1Prompt, setAudienceSegment1Prompt]         = useStateWithLabel("", "audienceSegment1Prompt");
    const [audienceSegment1Question1, setAudienceSegment1Question1]   = useStateWithLabel("", "audienceSegment1Question1");
    const [audienceSegment1Question2, setAudienceSegment1Question2]   = useStateWithLabel("", "audienceSegment1Question2");
    const [audienceSegment1Question3, setAudienceSegment1Question3]   = useStateWithLabel("", "audienceSegment1Question3");
    const [audienceSegment1Question4, setAudienceSegment1Question4]   = useStateWithLabel("", "audienceSegment1Question4");
    const [audienceSegment1Question5, setAudienceSegment1Question5]   = useStateWithLabel("", "audienceSegment1Question5");
    const [audienceSegment1Question6, setAudienceSegment1Question6]   = useStateWithLabel("", "audienceSegment1Question6");
    const [audienceSegment1Question7, setAudienceSegment1Question7]   = useStateWithLabel("", "audienceSegment1Question7");
    const [audienceSegment1Question8, setAudienceSegment1Question8]   = useStateWithLabel("", "audienceSegment1Question8");
    const [audienceSegment1Question9, setAudienceSegment1Question9]   = useStateWithLabel("", "audienceSegment1Question9");
    const [audienceSegment1Question10, setAudienceSegment1Question10]   = useStateWithLabel("", "audienceSegment1Question10");
    
    //Step 3b
    const [audienceSegment2MarketingFunnel, setAudienceSegment2MarketingFunnel]         = useStateWithLabel("", "audienceSegment2MarketingFunnel");
    const [audienceSegment2Prompt, setAudienceSegment2Prompt]   = useStateWithLabel("", "audienceSegment2Prompt");
    const [audienceSegment2Question1, setAudienceSegment2Question1]   = useStateWithLabel("", "audienceSegment2Question1");
    const [audienceSegment2Question2, setAudienceSegment2Question2]   = useStateWithLabel("", "audienceSegment2Question2");
    const [audienceSegment2Question3, setAudienceSegment2Question3]   = useStateWithLabel("", "audienceSegment2Question3");
    const [audienceSegment2Question4, setAudienceSegment2Question4]   = useStateWithLabel("", "audienceSegment2Question4");
    const [audienceSegment2Question5, setAudienceSegment2Question5]   = useStateWithLabel("", "audienceSegment2Question5");
    const [audienceSegment2Question6, setAudienceSegment2Question6]   = useStateWithLabel("", "audienceSegment2Question6");
    const [audienceSegment2Question7, setAudienceSegment2Question7]   = useStateWithLabel("", "audienceSegment2Question7");
    const [audienceSegment2Question8, setAudienceSegment2Question8]   = useStateWithLabel("", "audienceSegment2Question8");
    const [audienceSegment2Question9, setAudienceSegment2Question9]   = useStateWithLabel("", "audienceSegment2Question9");
    const [audienceSegment2Question10, setAudienceSegment2Question10]   = useStateWithLabel("", "audienceSegment2Question10");
    
    //Step 3c
    const [audienceSegment3MarketingFunnel, setAudienceSegment3MarketingFunnel]         = useStateWithLabel("", "audienceSegment3MarketingFunnel");
    const [audienceSegment3Prompt, setAudienceSegment3Prompt]   = useStateWithLabel("", "audienceSegment3Prompt");
    const [audienceSegment3Question1, setAudienceSegment3Question1]   = useStateWithLabel("", "audienceSegment3Question1");
    const [audienceSegment3Question2, setAudienceSegment3Question2]   = useStateWithLabel("", "audienceSegment3Question2");
    const [audienceSegment3Question3, setAudienceSegment3Question3]   = useStateWithLabel("", "audienceSegment3Question3");
    const [audienceSegment3Question4, setAudienceSegment3Question4]   = useStateWithLabel("", "audienceSegment3Question4");
    const [audienceSegment3Question5, setAudienceSegment3Question5]   = useStateWithLabel("", "audienceSegment3Question5");
    const [audienceSegment3Question6, setAudienceSegment3Question6]   = useStateWithLabel("", "audienceSegment3Question6");
    const [audienceSegment3Question7, setAudienceSegment3Question7]   = useStateWithLabel("", "audienceSegment3Question7");
    const [audienceSegment3Question8, setAudienceSegment3Question8]   = useStateWithLabel("", "audienceSegment3Question8");
    const [audienceSegment3Question9, setAudienceSegment3Question9]   = useStateWithLabel("", "audienceSegment3Question9");
    const [audienceSegment3Question10, setAudienceSegment3Question10]   = useStateWithLabel("", "audienceSegment3Question10");
    
    //Step 3d
    const [audienceSegment4MarketingFunnel, setAudienceSegment4MarketingFunnel]         = useStateWithLabel("", "audienceSegment4MarketingFunnel");
    const [audienceSegment4Prompt, setAudienceSegment4Prompt]   = useStateWithLabel("", "audienceSegment4Prompt");
    const [audienceSegment4Question1, setAudienceSegment4Question1]   = useStateWithLabel("", "audienceSegment4Question1");
    const [audienceSegment4Question2, setAudienceSegment4Question2]   = useStateWithLabel("", "audienceSegment4Question2");
    const [audienceSegment4Question3, setAudienceSegment4Question3]   = useStateWithLabel("", "audienceSegment4Question3");
    const [audienceSegment4Question4, setAudienceSegment4Question4]   = useStateWithLabel("", "audienceSegment4Question4");
    const [audienceSegment4Question5, setAudienceSegment4Question5]   = useStateWithLabel("", "audienceSegment4Question5");
    const [audienceSegment4Question6, setAudienceSegment4Question6]   = useStateWithLabel("", "audienceSegment4Question6");
    const [audienceSegment4Question7, setAudienceSegment4Question7]   = useStateWithLabel("", "audienceSegment4Question7");
    const [audienceSegment4Question8, setAudienceSegment4Question8]   = useStateWithLabel("", "audienceSegment4Question8");
    const [audienceSegment4Question9, setAudienceSegment4Question9]   = useStateWithLabel("", "audienceSegment4Question9");
    const [audienceSegment4Question10, setAudienceSegment4Question10]   = useStateWithLabel("", "audienceSegment4Question10");
    
    //Step 4a
    const [label4A, setLabel4A]   = useStateWithLabel("Hypotheses", "label4A");
    const [label4B, setLabel4B]   = useStateWithLabel("Value Proposition", "label4B");
    const [label4C, setLabel4C]   = useStateWithLabel("Naming", "label4C");
    const [label4D, setLabel4D]   = useStateWithLabel("Creative Concept", "label4D");
    const [label4E, setLabel4E]   = useStateWithLabel("Master Brand Language", "label4E");
    

    //Badge
    const [badge3A, setBadge3A]   = useStateWithLabel("", "badge3A");
    const [badge3B, setBadge3B]   = useStateWithLabel("", "badge3B");
    const [badge3C, setBadge3C]   = useStateWithLabel("", "badge3C");
    const [badge3D, setBadge3D]   = useStateWithLabel("", "badge3D");
    
    const [badge4A, setBadge4A]   = useStateWithLabel("", "badge4A");
    const [badge4B, setBadge4B]   = useStateWithLabel("", "badge4B");
    const [badge4C, setBadge4C]   = useStateWithLabel("", "badge4C");
    const [badge4D, setBadge4D]   = useStateWithLabel("", "badge4D");
    const [badge4E, setBadge4E]   = useStateWithLabel("", "badge4E");

    const [hypothesisTitle1, setHypothesisTitle1]   = useStateWithLabel("", "hypothesisTitle1");
    const [hypothesisTitle2, setHypothesisTitle2]   = useStateWithLabel("", "hypothesisTitle2");
    const [hypothesisTitle3, setHypothesisTitle3]   = useStateWithLabel("", "hypothesisTitle3");
    const [hypothesisTitle4, setHypothesisTitle4]   = useStateWithLabel("", "hypothesisTitle4");
    const [hypothesisTitle5, setHypothesisTitle5]   = useStateWithLabel("", "hypothesisTitle5");
    
    const [hypotheses1, setHypotheses1]   = useStateWithLabel("", "hypotheses1");
    const [hypotheses2, setHypotheses2]   = useStateWithLabel("", "hypotheses2");
    const [hypotheses3, setHypotheses3]   = useStateWithLabel("", "hypotheses3");
    const [hypotheses4, setHypotheses4]   = useStateWithLabel("", "hypotheses4");
    const [hypotheses5, setHypotheses5]   = useStateWithLabel("", "hypotheses5");
    const [hypotheses6, setHypotheses6]   = useStateWithLabel("", "hypotheses6");
    const [hypotheses7, setHypotheses7]   = useStateWithLabel("", "hypotheses7");
    const [hypotheses8, setHypotheses8]   = useStateWithLabel("", "hypotheses8");
    const [hypotheses9, setHypotheses9]   = useStateWithLabel("", "hypotheses9");
    const [hypotheses10, setHypotheses10]   = useStateWithLabel("", "hypotheses10");
    const [hypotheses11, setHypotheses11]   = useStateWithLabel("", "hypotheses11");
    const [hypotheses12, setHypotheses12]   = useStateWithLabel("", "hypotheses12");
    const [hypotheses13, setHypotheses13]   = useStateWithLabel("", "hypotheses13");
    const [hypotheses14, setHypotheses14]   = useStateWithLabel("", "hypotheses14");
    const [hypotheses15, setHypotheses15]   = useStateWithLabel("", "hypotheses15");
    
    
    //Step 4b
    const [proposition1, setProposition1]   = useStateWithLabel("", "proposition1");
    const [proposition2, setProposition2]   = useStateWithLabel("", "proposition2");
    const [proposition3, setProposition3]   = useStateWithLabel("", "proposition3");
    const [proposition4, setProposition4]   = useStateWithLabel("", "proposition4");
    const [proposition5, setProposition5]   = useStateWithLabel("", "proposition5");
    const [proposition6, setProposition6]   = useStateWithLabel("", "proposition6");
    const [proposition7, setProposition7]   = useStateWithLabel("", "proposition7");
    const [proposition8, setProposition8]   = useStateWithLabel("", "proposition8");
    const [proposition9, setProposition9]   = useStateWithLabel("", "proposition9");
    const [proposition10, setProposition10]   = useStateWithLabel("", "proposition10");
    const [proposition11, setProposition11]   = useStateWithLabel("", "proposition11");
    const [proposition12, setProposition12]   = useStateWithLabel("", "proposition12");
    const [proposition13, setProposition13]   = useStateWithLabel("", "proposition13");
    const [proposition14, setProposition14]   = useStateWithLabel("", "proposition14");
    const [proposition15, setProposition15]   = useStateWithLabel("", "proposition15");
    
    //Step 4c
    const [emotionalC1, setEmotionalC1]   = useStateWithLabel("", "emotionalC1");
    const [emotionalC2, setEmotionalC2]   = useStateWithLabel("", "emotionalC2");
    const [emotionalC3, setEmotionalC3]   = useStateWithLabel("", "emotionalC3");
    const [emotionalC4, setEmotionalC4]   = useStateWithLabel("", "emotionalC4");
    const [emotionalC5, setEmotionalC5]   = useStateWithLabel("", "emotionalC5");
    const [emotionalC6, setEmotionalC6]   = useStateWithLabel("", "emotionalC6");
    const [emotionalC7, setEmotionalC7]   = useStateWithLabel("", "emotionalC7");
    const [emotionalC8, setEmotionalC8]   = useStateWithLabel("", "emotionalC8");
    const [emotionalC9, setEmotionalC9]   = useStateWithLabel("", "emotionalC9");
    const [emotionalC10, setEmotionalC10]   = useStateWithLabel("", "emotionalC10");
    const [emotionalC11, setEmotionalC11]   = useStateWithLabel("", "emotionalC11");
    const [emotionalC12, setEmotionalC12]   = useStateWithLabel("", "emotionalC12");
    const [emotionalC13, setEmotionalC13]   = useStateWithLabel("", "emotionalC13");
    const [emotionalC14, setEmotionalC14]   = useStateWithLabel("", "emotionalC14");
    const [emotionalC15, setEmotionalC15]   = useStateWithLabel("", "emotionalC15");
    
    //Step 4d
    const [emotionalD1, setEmotionalD1]   = useStateWithLabel("", "emotionalD1");
    const [emotionalD2, setEmotionalD2]   = useStateWithLabel("", "emotionalD2");
    const [emotionalD3, setEmotionalD3]   = useStateWithLabel("", "emotionalD3");
    const [emotionalD4, setEmotionalD4]   = useStateWithLabel("", "emotionalD4");
    const [emotionalD5, setEmotionalD5]   = useStateWithLabel("", "emotionalD5");
    const [emotionalD6, setEmotionalD6]   = useStateWithLabel("", "emotionalD6");
    const [emotionalD7, setEmotionalD7]   = useStateWithLabel("", "emotionalD7");
    const [emotionalD8, setEmotionalD8]   = useStateWithLabel("", "emotionalD8");
    const [emotionalD9, setEmotionalD9]   = useStateWithLabel("", "emotionalD9");
    const [emotionalD10, setEmotionalD10]   = useStateWithLabel("", "emotionalD10");
    const [emotionalD11, setEmotionalD11]   = useStateWithLabel("", "emotionalD11");
    const [emotionalD12, setEmotionalD12]   = useStateWithLabel("", "emotionalD12");
    const [emotionalD13, setEmotionalD13]   = useStateWithLabel("", "emotionalD13");
    const [emotionalD14, setEmotionalD14]   = useStateWithLabel("", "emotionalD14");
    const [emotionalD15, setEmotionalD15]   = useStateWithLabel("", "emotionalD15");
    
    //Step 4e
    const [emotionalE1, setEmotionalE1]   = useStateWithLabel("", "emotionalE1");
    const [emotionalE2, setEmotionalE2]   = useStateWithLabel("", "emotionalE2");
    const [emotionalE3, setEmotionalE3]   = useStateWithLabel("", "emotionalE3");
    const [emotionalE4, setEmotionalE4]   = useStateWithLabel("", "emotionalE4");
    const [emotionalE5, setEmotionalE5]   = useStateWithLabel("", "emotionalE5");
    const [emotionalE6, setEmotionalE6]   = useStateWithLabel("", "emotionalE6");
    const [emotionalE7, setEmotionalE7]   = useStateWithLabel("", "emotionalE7");
    const [emotionalE8, setEmotionalE8]   = useStateWithLabel("", "emotionalE8");
    const [emotionalE9, setEmotionalE9]   = useStateWithLabel("", "emotionalE9");
    const [emotionalE10, setEmotionalE10]   = useStateWithLabel("", "emotionalE10");
    const [emotionalE11, setEmotionalE11]   = useStateWithLabel("", "emotionalE11");
    const [emotionalE12, setEmotionalE12]   = useStateWithLabel("", "emotionalE12");
    const [emotionalE13, setEmotionalE13]   = useStateWithLabel("", "emotionalE13");
    const [emotionalE14, setEmotionalE14]   = useStateWithLabel("", "emotionalE14");
    const [emotionalE15, setEmotionalE15]   = useStateWithLabel("", "emotionalE15");
    

    const goBackToBrandStudy = () => {
        clearMarketStudyForm();
        setSelectedMarketStudy(null); 
        setIsNewMarketStudy(false);
        setHasChanges(false);
        setIsFormShown(false);
    }
    const gotoNextStep = () => {
        switch(currentStep){
            case "1" : setCurrentStep("2");     break;
            case "2" : setCurrentStep("3a");    break;
            case "3a" : setCurrentStep("3b");   break;
            case "3b" : setCurrentStep("3c");   break;
            case "3c" : setCurrentStep("3d");   break;
            case "3d" : setCurrentStep("4a");    break;
            case "4a" : setCurrentStep("4b");     break;
        }
    }

    const gotoPrevStep = () => {
        switch(currentStep){
            case "2" : setCurrentStep("1");     break;
            case "3a" : setCurrentStep("2");    break;
            case "3b" : setCurrentStep("3a");   break;
            case "3c" : setCurrentStep("3b");   break;
            case "3d" : setCurrentStep("3c");   break;
            case "4a" : setCurrentStep("3d");    break;
            case "4b" : setCurrentStep("4a");     break;
        }
    }

    const getTotalBrands = (clientName) => {
        return marketResearch.items.filter(item  => item.client == clientName && item.brand !== undefined).filter((value, index, self) => index === self.findIndex((t) => (t.brand === value.brand))).length
    }
    
    const getTotalStudies = (brandName) => {
        return marketResearch.items.filter(item  => item.brand == brandName && item.title !== undefined).length
    }
    
    const showBrandPanel = (client) => {
        setIsNewMarketStudy(false);
        setSelectedClient(client)
        setSelectedBrand(null)
        setSelectedMarketStudy(null)
        setClientName("");
        setBrandName("");
    }

    const showResearchPanel = (brand) => {
        setSelectedMarketStudy(null)
        setIsNewMarketStudy(false)
        setSelectedBrand(brand)
    }

    const showStudyForm = (marketStudy) => {
        setSelectedMarketStudy(marketStudy)
        
        //Basic
        setTitle(getSelectedStudy("title", marketStudy) == undefined ? ""  : getSelectedStudy("title", marketStudy));
        setClientName(getSelectedStudy("client", marketStudy) == undefined ? ""  : getSelectedStudy("client", marketStudy))
        setBrandName(getSelectedStudy("brand", marketStudy) == undefined ? ""  : getSelectedStudy("brand", marketStudy))
        
        //Segment Definition
        setDimension1(getSelectedStudy("dimension1", marketStudy) == undefined ? ""  : getSelectedStudy("dimension1", marketStudy))
        setDimension2(getSelectedStudy("dimension2", marketStudy) == undefined ? ""  : getSelectedStudy("dimension2", marketStudy))
        setDimension3(getSelectedStudy("dimension3", marketStudy) == undefined ? ""  : getSelectedStudy("dimension3", marketStudy))
        
        setSegment1(getSelectedStudy("segment1", marketStudy) == undefined ? ""  : getSelectedStudy("segment1", marketStudy))
        setSegment2(getSelectedStudy("segment2", marketStudy) == undefined ? ""  : getSelectedStudy("segment2", marketStudy))
        setSegment3(getSelectedStudy("segment3", marketStudy) == undefined ? ""  : getSelectedStudy("segment3", marketStudy))
        
        setTotalParticipantA(getSelectedStudy("totalParticipantA", marketStudy) == undefined ? ""  : getSelectedStudy("totalParticipantA", marketStudy))
        setTotalParticipantB(getSelectedStudy("totalParticipantB", marketStudy) == undefined ? ""  : getSelectedStudy("totalParticipantB", marketStudy))
        setTotalParticipantC(getSelectedStudy("totalParticipantC", marketStudy) == undefined ? ""  : getSelectedStudy("totalParticipantC", marketStudy))
        

        //Screeners
        setEmployment(getSelectedStudy("employment", marketStudy) == undefined ? ""  : getSelectedStudy("employment", marketStudy))
        setMaritalStatus(getSelectedStudy("maritalStatus", marketStudy) == undefined ? ""  : getSelectedStudy("maritalStatus", marketStudy))
        setParents(getSelectedStudy("parents", marketStudy) == undefined ? ""  : getSelectedStudy("parents", marketStudy))
        setOccupation(getSelectedStudy("occupation", marketStudy) == undefined ? ""  : getSelectedStudy("occupation", marketStudy))
        setEthnicities(getSelectedStudy("ethnicities", marketStudy) == undefined ? ""  : getSelectedStudy("ethnicities", marketStudy))
        setGender(getSelectedStudy("gender", marketStudy) == undefined ? ""  : getSelectedStudy("gender", marketStudy))
        setAge(getSelectedStudy("age", marketStudy) == undefined ? ""  : getSelectedStudy("age", marketStudy))
        setHouseholdIncome(getSelectedStudy("householdIncome", marketStudy) == undefined ? ""  : getSelectedStudy("householdIncome", marketStudy))
        setGeo(getSelectedStudy("geo", marketStudy) == undefined ? ""  : getSelectedStudy("geo", marketStudy))
        setCustomScreener(getSelectedStudy("customScreener", marketStudy) == undefined ? []  : getSelectedStudy("customScreener", marketStudy))
        
        //Feature Sets 1
        setAudienceSegment1MarketingFunnel(getSelectedStudy("audienceSegment1MarketingFunnel", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment1MarketingFunnel", marketStudy))
        setAudienceSegment1Prompt(getSelectedStudy("audienceSegment1Prompt", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment1Prompt", marketStudy))
        setAudienceSegment1Question1(getSelectedStudy("audienceSegment1Question1", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment1Question1", marketStudy))
        setAudienceSegment1Question2(getSelectedStudy("audienceSegment1Question2", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment1Question2", marketStudy))
        setAudienceSegment1Question3(getSelectedStudy("audienceSegment1Question3", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment1Question3", marketStudy))
        setAudienceSegment1Question4(getSelectedStudy("audienceSegment1Question4", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment1Question4", marketStudy))
        setAudienceSegment1Question5(getSelectedStudy("audienceSegment1Question5", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment1Question5", marketStudy))
        setAudienceSegment1Question6(getSelectedStudy("audienceSegment1Question6", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment1Question6", marketStudy))
        setAudienceSegment1Question7(getSelectedStudy("audienceSegment1Question7", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment1Question7", marketStudy))
        setAudienceSegment1Question8(getSelectedStudy("audienceSegment1Question8", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment1Question8", marketStudy))
        setAudienceSegment1Question9(getSelectedStudy("audienceSegment1Question9", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment1Question9", marketStudy))
        setAudienceSegment1Question10(getSelectedStudy("audienceSegment1Question10", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment1Question10", marketStudy))
        
        //Feature Sets 2
        setAudienceSegment2MarketingFunnel(getSelectedStudy("audienceSegment2MarketingFunnel", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment2MarketingFunnel", marketStudy))
        setAudienceSegment2Prompt(getSelectedStudy("audienceSegment2Prompt", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment2Prompt", marketStudy))
        setAudienceSegment2Question1(getSelectedStudy("audienceSegment2Question1", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment2Question1", marketStudy))
        setAudienceSegment2Question2(getSelectedStudy("audienceSegment2Question2", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment2Question2", marketStudy))
        setAudienceSegment2Question3(getSelectedStudy("audienceSegment2Question3", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment2Question3", marketStudy))
        setAudienceSegment2Question4(getSelectedStudy("audienceSegment2Question4", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment2Question4", marketStudy))
        setAudienceSegment2Question5(getSelectedStudy("audienceSegment2Question5", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment2Question5", marketStudy))
        setAudienceSegment2Question6(getSelectedStudy("audienceSegment2Question6", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment2Question6", marketStudy))
        setAudienceSegment2Question7(getSelectedStudy("audienceSegment2Question7", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment2Question7", marketStudy))
        setAudienceSegment2Question8(getSelectedStudy("audienceSegment2Question8", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment2Question8", marketStudy))
        setAudienceSegment2Question9(getSelectedStudy("audienceSegment2Question9", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment2Question9", marketStudy))
        setAudienceSegment2Question10(getSelectedStudy("audienceSegment2Question10", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment2Question10", marketStudy))
        
        //Feature Sets 3
        setAudienceSegment3MarketingFunnel(getSelectedStudy("audienceSegment3MarketingFunnel", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment3MarketingFunnel", marketStudy))
        setAudienceSegment3Prompt(getSelectedStudy("audienceSegment3Prompt", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment3Prompt", marketStudy))
        setAudienceSegment3Question1(getSelectedStudy("audienceSegment3Question1", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment3Question1", marketStudy))
        setAudienceSegment3Question2(getSelectedStudy("audienceSegment3Question2", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment3Question2", marketStudy))
        setAudienceSegment3Question3(getSelectedStudy("audienceSegment3Question3", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment3Question3", marketStudy))
        setAudienceSegment3Question4(getSelectedStudy("audienceSegment3Question4", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment3Question4", marketStudy))
        setAudienceSegment3Question5(getSelectedStudy("audienceSegment3Question5", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment3Question5", marketStudy))
        setAudienceSegment3Question6(getSelectedStudy("audienceSegment3Question6", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment3Question6", marketStudy))
        setAudienceSegment3Question7(getSelectedStudy("audienceSegment3Question7", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment3Question7", marketStudy))
        setAudienceSegment3Question8(getSelectedStudy("audienceSegment3Question8", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment3Question8", marketStudy))
        setAudienceSegment3Question9(getSelectedStudy("audienceSegment3Question9", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment3Question9", marketStudy))
        setAudienceSegment3Question10(getSelectedStudy("audienceSegment3Question10", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment3Question10", marketStudy))
        
        //Feature Sets 4
        setAudienceSegment4MarketingFunnel(getSelectedStudy("audienceSegment4MarketingFunnel", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment4MarketingFunnel", marketStudy))
        setAudienceSegment4Prompt(getSelectedStudy("audienceSegment4Prompt", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment4Prompt", marketStudy))
        setAudienceSegment4Question1(getSelectedStudy("audienceSegment4Question1", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment4Question1", marketStudy))
        setAudienceSegment4Question2(getSelectedStudy("audienceSegment4Question2", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment4Question2", marketStudy))
        setAudienceSegment4Question3(getSelectedStudy("audienceSegment4Question3", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment4Question3", marketStudy))
        setAudienceSegment4Question4(getSelectedStudy("audienceSegment4Question4", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment4Question4", marketStudy))
        setAudienceSegment4Question5(getSelectedStudy("audienceSegment4Question5", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment4Question5", marketStudy))
        setAudienceSegment4Question6(getSelectedStudy("audienceSegment4Question6", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment4Question6", marketStudy))
        setAudienceSegment4Question7(getSelectedStudy("audienceSegment4Question7", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment4Question7", marketStudy))
        setAudienceSegment4Question8(getSelectedStudy("audienceSegment4Question8", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment4Question8", marketStudy))
        setAudienceSegment4Question9(getSelectedStudy("audienceSegment4Question9", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment4Question9", marketStudy))
        setAudienceSegment4Question10(getSelectedStudy("audienceSegment4Question10", marketStudy) == undefined ? ""  : getSelectedStudy("audienceSegment4Question10", marketStudy))

        //Step 4
        setLabel4A(getSelectedStudy("label4A", marketStudy) == undefined ? "Hypotheses"  : getSelectedStudy("label4A", marketStudy))
        setLabel4B(getSelectedStudy("label4B", marketStudy) == undefined ? "Value Proposition"  : getSelectedStudy("label4B", marketStudy))
        setLabel4C(getSelectedStudy("label4C", marketStudy) == undefined ? "Naming"  : getSelectedStudy("label4C", marketStudy))
        setLabel4D(getSelectedStudy("label4D", marketStudy) == undefined ? "Creative Concept"  : getSelectedStudy("label4D", marketStudy))
        setLabel4E(getSelectedStudy("label4E", marketStudy) == undefined ? "Master Brand Language"  : getSelectedStudy("label4E", marketStudy))
        
        //Step 4a
        setHypotheses1(getSelectedStudy("hypotheses1", marketStudy) == undefined ? ""  : getSelectedStudy("hypotheses1", marketStudy))
        setHypotheses2(getSelectedStudy("hypotheses2", marketStudy) == undefined ? ""  : getSelectedStudy("hypotheses2", marketStudy))
        setHypotheses3(getSelectedStudy("hypotheses3", marketStudy) == undefined ? ""  : getSelectedStudy("hypotheses3", marketStudy))
        setHypotheses4(getSelectedStudy("hypotheses4", marketStudy) == undefined ? ""  : getSelectedStudy("hypotheses4", marketStudy))
        setHypotheses5(getSelectedStudy("hypotheses5", marketStudy) == undefined ? ""  : getSelectedStudy("hypotheses5", marketStudy))
        setHypotheses6(getSelectedStudy("hypotheses6", marketStudy) == undefined ? ""  : getSelectedStudy("hypotheses6", marketStudy))
        setHypotheses7(getSelectedStudy("hypotheses7", marketStudy) == undefined ? ""  : getSelectedStudy("hypotheses7", marketStudy))
        setHypotheses8(getSelectedStudy("hypotheses8", marketStudy) == undefined ? ""  : getSelectedStudy("hypotheses8", marketStudy))
        setHypotheses9(getSelectedStudy("hypotheses9", marketStudy) == undefined ? ""  : getSelectedStudy("hypotheses9", marketStudy))
        setHypotheses10(getSelectedStudy("hypotheses10", marketStudy) == undefined ? ""  : getSelectedStudy("hypotheses10", marketStudy))
        setHypotheses11(getSelectedStudy("hypotheses11", marketStudy) == undefined ? ""  : getSelectedStudy("hypotheses11", marketStudy))
        setHypotheses12(getSelectedStudy("hypotheses12", marketStudy) == undefined ? ""  : getSelectedStudy("hypotheses12", marketStudy))
        setHypotheses13(getSelectedStudy("hypotheses13", marketStudy) == undefined ? ""  : getSelectedStudy("hypotheses13", marketStudy))
        setHypotheses14(getSelectedStudy("hypotheses14", marketStudy) == undefined ? ""  : getSelectedStudy("hypotheses14", marketStudy))
        setHypotheses15(getSelectedStudy("hypotheses15", marketStudy) == undefined ? ""  : getSelectedStudy("hypotheses15", marketStudy))
        
        //Step 4b
        setProposition1(getSelectedStudy("proposition1", marketStudy) == undefined ? ""  : getSelectedStudy("proposition1", marketStudy))
        setProposition2(getSelectedStudy("proposition2", marketStudy) == undefined ? ""  : getSelectedStudy("proposition2", marketStudy))
        setProposition3(getSelectedStudy("proposition3", marketStudy) == undefined ? ""  : getSelectedStudy("proposition3", marketStudy))
        setProposition4(getSelectedStudy("proposition4", marketStudy) == undefined ? ""  : getSelectedStudy("proposition4", marketStudy))
        setProposition5(getSelectedStudy("proposition5", marketStudy) == undefined ? ""  : getSelectedStudy("proposition5", marketStudy))
        setProposition6(getSelectedStudy("proposition6", marketStudy) == undefined ? ""  : getSelectedStudy("proposition6", marketStudy))
        setProposition7(getSelectedStudy("proposition7", marketStudy) == undefined ? ""  : getSelectedStudy("proposition7", marketStudy))
        setProposition8(getSelectedStudy("proposition8", marketStudy) == undefined ? ""  : getSelectedStudy("proposition8", marketStudy))
        setProposition9(getSelectedStudy("proposition9", marketStudy) == undefined ? ""  : getSelectedStudy("proposition9", marketStudy))
        setProposition10(getSelectedStudy("proposition10", marketStudy) == undefined ? ""  : getSelectedStudy("proposition10", marketStudy))
        setProposition11(getSelectedStudy("proposition11", marketStudy) == undefined ? ""  : getSelectedStudy("proposition11", marketStudy))
        setProposition12(getSelectedStudy("proposition12", marketStudy) == undefined ? ""  : getSelectedStudy("proposition12", marketStudy))
        setProposition13(getSelectedStudy("proposition13", marketStudy) == undefined ? ""  : getSelectedStudy("proposition13", marketStudy))
        setProposition14(getSelectedStudy("proposition14", marketStudy) == undefined ? ""  : getSelectedStudy("proposition14", marketStudy))
        setProposition15(getSelectedStudy("proposition15", marketStudy) == undefined ? ""  : getSelectedStudy("proposition15", marketStudy))
        
        //Step 4c
        setEmotionalC1(getSelectedStudy("emotionalC1", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalC1", marketStudy))
        setEmotionalC2(getSelectedStudy("emotionalC2", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalC2", marketStudy))
        setEmotionalC3(getSelectedStudy("emotionalC3", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalC3", marketStudy))
        setEmotionalC4(getSelectedStudy("emotionalC4", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalC4", marketStudy))
        setEmotionalC5(getSelectedStudy("emotionalC5", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalC5", marketStudy))
        setEmotionalC6(getSelectedStudy("emotionalC6", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalC6", marketStudy))
        setEmotionalC7(getSelectedStudy("emotionalC7", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalC7", marketStudy))
        setEmotionalC8(getSelectedStudy("emotionalC8", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalC8", marketStudy))
        setEmotionalC9(getSelectedStudy("emotionalC9", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalC9", marketStudy))
        setEmotionalC10(getSelectedStudy("emotionalC10", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalC10", marketStudy))
        setEmotionalC11(getSelectedStudy("emotionalC11", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalC11", marketStudy))
        setEmotionalC12(getSelectedStudy("emotionalC12", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalC12", marketStudy))
        setEmotionalC13(getSelectedStudy("emotionalC13", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalC13", marketStudy))
        setEmotionalC14(getSelectedStudy("emotionalC14", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalC14", marketStudy))
        setEmotionalC15(getSelectedStudy("emotionalC15", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalC15", marketStudy))
        
        //Step 4d
        setEmotionalD1(getSelectedStudy("emotionalD1", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalD1", marketStudy))
        setEmotionalD2(getSelectedStudy("emotionalD2", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalD2", marketStudy))
        setEmotionalD3(getSelectedStudy("emotionalD3", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalD3", marketStudy))
        setEmotionalD4(getSelectedStudy("emotionalD4", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalD4", marketStudy))
        setEmotionalD5(getSelectedStudy("emotionalD5", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalD5", marketStudy))
        setEmotionalD6(getSelectedStudy("emotionalD6", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalD6", marketStudy))
        setEmotionalD7(getSelectedStudy("emotionalD7", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalD7", marketStudy))
        setEmotionalD8(getSelectedStudy("emotionalD8", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalD8", marketStudy))
        setEmotionalD9(getSelectedStudy("emotionalD9", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalD9", marketStudy))
        setEmotionalD10(getSelectedStudy("emotionalD10", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalD10", marketStudy))
        setEmotionalD11(getSelectedStudy("emotionalD11", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalD11", marketStudy))
        setEmotionalD12(getSelectedStudy("emotionalD12", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalD12", marketStudy))
        setEmotionalD13(getSelectedStudy("emotionalD13", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalD13", marketStudy))
        setEmotionalD14(getSelectedStudy("emotionalD14", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalD14", marketStudy))
        setEmotionalD15(getSelectedStudy("emotionalD15", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalD15", marketStudy))
        
        //Step 4e
        setEmotionalE1(getSelectedStudy("emotionalE1", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalE1", marketStudy))
        setEmotionalE2(getSelectedStudy("emotionalE2", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalE2", marketStudy))
        setEmotionalE3(getSelectedStudy("emotionalE3", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalE3", marketStudy))
        setEmotionalE4(getSelectedStudy("emotionalE4", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalE4", marketStudy))
        setEmotionalE5(getSelectedStudy("emotionalE5", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalE5", marketStudy))
        setEmotionalE6(getSelectedStudy("emotionalE6", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalE6", marketStudy))
        setEmotionalE7(getSelectedStudy("emotionalE7", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalE7", marketStudy))
        setEmotionalE8(getSelectedStudy("emotionalE8", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalE8", marketStudy))
        setEmotionalE9(getSelectedStudy("emotionalE9", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalE9", marketStudy))
        setEmotionalE10(getSelectedStudy("emotionalE10", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalE10", marketStudy))
        setEmotionalE11(getSelectedStudy("emotionalE11", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalE11", marketStudy))
        setEmotionalE12(getSelectedStudy("emotionalE12", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalE12", marketStudy))
        setEmotionalE13(getSelectedStudy("emotionalE13", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalE13", marketStudy))
        setEmotionalE14(getSelectedStudy("emotionalE14", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalE14", marketStudy))
        setEmotionalE15(getSelectedStudy("emotionalE15", marketStudy) == undefined ? ""  : getSelectedStudy("emotionalE15", marketStudy))
    }

    const clearMarketStudyForm = () => {
        setSelectedMarketStudy(false);
        setIsNewMarketStudy(true);

        //Basic
        setTitle("")
        setClientName("")
        setBrandName("")


        //Segment Definitions
        setDimension1("")
        setDimension2("")
        setDimension3("")

        setSegment1("")
        setSegment2("")
        setSegment3("")
        
        setTotalParticipantA(60)
        setTotalParticipantB(60)
        setTotalParticipantC(60)
        

        //Screeners
        setEmployment("")
        setMaritalStatus("")
        setParents("")
        setOccupation("")
        setEthnicities("")
        setGender("")
        setAge("")
        setHouseholdIncome("")
        setGeo("")
        setCustomScreener([])
        setAdditionalScreenerOption([])

        //Feature Set 1
        setAudienceSegment1MarketingFunnel("")
        setAudienceSegment1Prompt("")
        setAudienceSegment1Question1("")
        setAudienceSegment1Question2("")
        setAudienceSegment1Question3("")
        setAudienceSegment1Question4("")
        setAudienceSegment1Question5("")
        setAudienceSegment1Question6("")
        setAudienceSegment1Question7("")
        setAudienceSegment1Question8("")
        setAudienceSegment1Question9("")
        setAudienceSegment1Question10("")
        
        //Feature Set 2
        setAudienceSegment2MarketingFunnel("")
        setAudienceSegment2Prompt("")
        setAudienceSegment2Question1("")
        setAudienceSegment2Question2("")
        setAudienceSegment2Question3("")
        setAudienceSegment2Question4("")
        setAudienceSegment2Question5("")
        setAudienceSegment2Question6("")
        setAudienceSegment2Question7("")
        setAudienceSegment2Question8("")
        setAudienceSegment2Question9("")
        setAudienceSegment2Question10("")
        
        //Feature Set 3
        setAudienceSegment3MarketingFunnel("")
        setAudienceSegment3Prompt("")
        setAudienceSegment3Question1("")
        setAudienceSegment3Question2("")
        setAudienceSegment3Question3("")
        setAudienceSegment3Question4("")
        setAudienceSegment3Question5("")
        setAudienceSegment3Question6("")
        setAudienceSegment3Question7("")
        setAudienceSegment3Question8("")
        setAudienceSegment3Question9("")
        setAudienceSegment3Question10("")
        
        //Feature Set 4
        setAudienceSegment4MarketingFunnel("")
        setAudienceSegment4Prompt("")
        setAudienceSegment4Question1("")
        setAudienceSegment4Question2("")
        setAudienceSegment4Question3("")
        setAudienceSegment4Question4("")
        setAudienceSegment4Question5("")
        setAudienceSegment4Question6("")
        setAudienceSegment4Question7("")
        setAudienceSegment4Question8("")
        setAudienceSegment4Question9("")
        setAudienceSegment4Question10("")

        //Step 4
        setLabel4A("Hypotheses")
        setLabel4B("Value Proposition")
        setLabel4C("Naming")
        setLabel4D("Creative Concept")
        setLabel4E("Master Brand Language")

        setHypotheses1("")
        setHypotheses2("")
        setHypotheses3("")
        setHypotheses4("")
        setHypotheses5("")
        setHypotheses6("")
        setHypotheses7("")
        setHypotheses8("")
        setHypotheses9("")
        setHypotheses10("")
        setHypotheses11("")
        setHypotheses12("")
        setHypotheses13("")
        setHypotheses14("")
        setHypotheses15("")

        setProposition1("")
        setProposition2("")
        setProposition3("")
        setProposition4("")
        setProposition5("")
        setProposition6("")
        setProposition7("")
        setProposition8("")
        setProposition9("")
        setProposition10("")
        setProposition11("")
        setProposition12("")
        setProposition13("")
        setProposition14("")
        setProposition15("")
        
        setEmotionalC1("")
        setEmotionalC2("")
        setEmotionalC3("")
        setEmotionalC4("")
        setEmotionalC5("")
        setEmotionalC6("")
        setEmotionalC7("")
        setEmotionalC8("")
        setEmotionalC9("")
        setEmotionalC10("")
        setEmotionalC11("")
        setEmotionalC12("")
        setEmotionalC13("")
        setEmotionalC14("")
        setEmotionalC15("")
        
        setEmotionalD1("")
        setEmotionalD2("")
        setEmotionalD3("")
        setEmotionalD4("")
        setEmotionalD5("")
        setEmotionalD6("")
        setEmotionalD7("")
        setEmotionalD8("")
        setEmotionalD9("")
        setEmotionalD10("")
        setEmotionalD11("")
        setEmotionalD12("")
        setEmotionalD13("")
        setEmotionalD14("")
        setEmotionalD15("")
        
        setEmotionalE1("")
        setEmotionalE2("")
        setEmotionalE3("")
        setEmotionalE4("")
        setEmotionalE5("")
        setEmotionalE6("")
        setEmotionalE7("")
        setEmotionalE8("")
        setEmotionalE9("")
        setEmotionalE10("")
        setEmotionalE11("")
        setEmotionalE12("")
        setEmotionalE13("")
        setEmotionalE14("")
        setEmotionalE15("")

    }

    //Create Client
    const createClient = () => {
        props.createClient({
            accountId       : user.accountId,
            client          : newClient
        });
        setIsCreateClientFormVisible(false);
        setNewClient("");
    }

    //Create Brand
    const createBrand = () => {
        props.createBrand({
            accountId       : user.accountId,
            client          : selectedClient,
            brand           : newBrand
        });
        setIsCreateBrandFormVisible(false);
        setNewBrand("");
    }

    //Create Market Study
    const create = () => {
        props.createMarketStudy({
            accountId       : user.accountId,

            //Basic
            title           : title,
            client          : clientName,
            brand           : brandName,

            //Segment Definitions
            dimension1:dimension1,
            dimension2:dimension2,
            dimension3:dimension3,

            segment1:segment1,
            segment2:segment2,
            segment3:segment3,
            
            totalParticipantA:totalParticipantA,
            totalParticipantB:totalParticipantB,
            totalParticipantC:totalParticipantC,

            //Screener
            geo             : geo,
            employment      : employment,
            maritalStatus   : maritalStatus,
            parents         : parents,
            occupation      : occupation,
            ethnicities     : ethnicities,
            gender          : gender,
            age             : age,
            householdIncome : householdIncome,

            //Feature Set 1
            audienceSegment1MarketingFunnel : audienceSegment1MarketingFunnel,
            audienceSegment1Prompt : audienceSegment1Prompt,
            audienceSegment1Question1 : audienceSegment1Question1,
            audienceSegment1Question2 : audienceSegment1Question2,
            audienceSegment1Question3 : audienceSegment1Question3,
            audienceSegment1Question4 : audienceSegment1Question4,
            audienceSegment1Question5 : audienceSegment1Question5,
            audienceSegment1Question6 : audienceSegment1Question6,
            audienceSegment1Question7 : audienceSegment1Question7,
            audienceSegment1Question8 : audienceSegment1Question8,
            audienceSegment1Question9 : audienceSegment1Question9,
            audienceSegment1Question10 : audienceSegment1Question10,
            
            //Feature Set 2
            audienceSegment2MarketingFunnel : audienceSegment2MarketingFunnel,
            audienceSegment2Prompt : audienceSegment2Prompt,
            audienceSegment2Question1 : audienceSegment2Question1,
            audienceSegment2Question2 : audienceSegment2Question2,
            audienceSegment2Question3 : audienceSegment2Question3,
            audienceSegment2Question4 : audienceSegment2Question4,
            audienceSegment2Question5 : audienceSegment2Question5,
            audienceSegment2Question6 : audienceSegment2Question6,
            audienceSegment2Question7 : audienceSegment2Question7,
            audienceSegment2Question8 : audienceSegment2Question8,
            audienceSegment2Question9 : audienceSegment2Question9,
            audienceSegment2Question10 : audienceSegment2Question10,
            
            //Feature Set 3
            audienceSegment3MarketingFunnel : audienceSegment3MarketingFunnel,
            audienceSegment3Prompt : audienceSegment3Prompt,
            audienceSegment3Question1 : audienceSegment3Question1,
            audienceSegment3Question2 : audienceSegment3Question2,
            audienceSegment3Question3 : audienceSegment3Question3,
            audienceSegment3Question4 : audienceSegment3Question4,
            audienceSegment3Question5 : audienceSegment3Question5,
            audienceSegment3Question6 : audienceSegment3Question6,
            audienceSegment3Question7 : audienceSegment3Question7,
            audienceSegment3Question8 : audienceSegment3Question8,
            audienceSegment3Question9 : audienceSegment3Question9,
            audienceSegment3Question10 : audienceSegment3Question10,
            
            //Feature Set 4
            audienceSegment4MarketingFunnel : audienceSegment4MarketingFunnel,
            audienceSegment4Prompt : audienceSegment4Prompt,
            audienceSegment4Question1 : audienceSegment4Question1,
            audienceSegment4Question2 : audienceSegment4Question2,
            audienceSegment4Question3 : audienceSegment4Question3,
            audienceSegment4Question4 : audienceSegment4Question4,
            audienceSegment4Question5 : audienceSegment4Question5,
            audienceSegment4Question6 : audienceSegment4Question6,
            audienceSegment4Question7 : audienceSegment4Question7,
            audienceSegment4Question8 : audienceSegment4Question8,
            audienceSegment4Question9 : audienceSegment4Question9,
            audienceSegment4Question10 : audienceSegment4Question10,

            //Step 4
            label4A:label4A,
            label4B:label4B,
            label4C:label4C,
            label4D:label4D,
            label4E:label4E,

            hypotheses1:hypotheses1,
            hypotheses2:hypotheses2,
            hypotheses3:hypotheses3,
            hypotheses4:hypotheses4,
            hypotheses5:hypotheses5,
            hypotheses6:hypotheses6,
            hypotheses7:hypotheses7,
            hypotheses8:hypotheses8,
            hypotheses9:hypotheses9,
            hypotheses10:hypotheses10,
            hypotheses11:hypotheses11,
            hypotheses12:hypotheses12,
            hypotheses13:hypotheses13,
            hypotheses14:hypotheses14,
            hypotheses15:hypotheses15,

            proposition1:proposition1,
            proposition2:proposition2,
            proposition3:proposition3,
            proposition4:proposition4,
            proposition5:proposition5,
            proposition6:proposition6,
            proposition7:proposition7,
            proposition8:proposition8,
            proposition9:proposition9,
            proposition10:proposition10,
            proposition11:proposition11,
            proposition12:proposition12,
            proposition13:proposition13,
            proposition14:proposition14,
            proposition15:proposition15,
            
            emotionalC1:emotionalC1,
            emotionalC2:emotionalC2,
            emotionalC3:emotionalC3,
            emotionalC4:emotionalC4,
            emotionalC5:emotionalC5,
            emotionalC6:emotionalC6,
            emotionalC7:emotionalC7,
            emotionalC8:emotionalC8,
            emotionalC9:emotionalC9,
            emotionalC10:emotionalC10,
            emotionalC11:emotionalC11,
            emotionalC12:emotionalC12,
            emotionalC13:emotionalC13,
            emotionalC14:emotionalC14,
            emotionalC15:emotionalC15,
            
            emotionalD1:emotionalD1,
            emotionalD2:emotionalD2,
            emotionalD3:emotionalD3,
            emotionalD4:emotionalD4,
            emotionalD5:emotionalD5,
            emotionalD6:emotionalD6,
            emotionalD7:emotionalD7,
            emotionalD8:emotionalD8,
            emotionalD9:emotionalD9,
            emotionalD10:emotionalD10,
            emotionalD11:emotionalD11,
            emotionalD12:emotionalD12,
            emotionalD13:emotionalD13,
            emotionalD14:emotionalD14,
            emotionalD15:emotionalD15,
            
            emotionalE1:emotionalE1,
            emotionalE2:emotionalE2,
            emotionalE3:emotionalE3,
            emotionalE4:emotionalE4,
            emotionalE5:emotionalE5,
            emotionalE6:emotionalE6,
            emotionalE7:emotionalE7,
            emotionalE8:emotionalE8,
            emotionalE9:emotionalE9,
            emotionalE10:emotionalE10,
            emotionalE11:emotionalE11,
            emotionalE12:emotionalE12,
            emotionalE13:emotionalE13,
            emotionalE14:emotionalE14,
            emotionalE15:emotionalE15,



        })
        setIsNewMarketStudy(true);
    }
    
    const saveChanges = () => {

        /*********************************************************************************************************
         * Before submitting to API:
         **********************************************************************************************************/

         /*********************************************************************************************************
         * 1. Set the value of customScreener (Make sure it gets the edited and uneditem items before submitting)
         * 
         * If customeScreener is not included in the changes then get the original customScreener from the props
         * Else get the customScreener from useState and those that are not edited from the props (Merge them together)
         **********************************************************************************************************/
        let selectedMarketResearch = marketResearch.items && marketResearch.items.filter(marketResearch => marketResearch.title == title && marketResearch.client == clientName && marketResearch.brand == brandName)[0];
        let newCustomScreener = []
        selectedMarketResearch.customScreeners.map((item, index) => {
            newCustomScreener[index] = customScreener[index] == undefined ? item : customScreener[index];
        })
        if(customScreener.length == 0){
            newCustomScreener = selectedMarketResearch.customScreeners
        }

        /*********************************************************************************************************
         * 2. Add any additional Screeners
         * Also: Remove the "inputValue" property since this is only needed for handling Changes in <CreatableSelect>
         **********************************************************************************************************/
        additionalScreenerOption.map(item => {
            item.options = item.options.map(option => {
                return option.value
            })
             delete item.inputValue;
         })
        newCustomScreener = newCustomScreener.concat(additionalScreenerOption);

        props.updateMarketStudy({
            accountId       : user.accountId,

            //Basic
            title           : title,
            client          : clientName,
            brand           : brandName,

            //Segment Definitions
            dimension1:dimension1,
            dimension2:dimension2,
            dimension3:dimension3,

            segment1:segment1,
            segment2:segment2,
            segment3:segment3,
            
            totalParticipantA:totalParticipantA,
            totalParticipantB:totalParticipantB,
            totalParticipantC:totalParticipantC,


            //Screener
            geo             : geo,
            employment      : employment,
            maritalStatus   : maritalStatus,
            parents         : parents,
            occupation      : occupation,
            ethnicities     : ethnicities,
            gender          : gender,
            age             : age,
            householdIncome : householdIncome,
            customScreeners : newCustomScreener,

            //Feature Set 1
            audienceSegment1MarketingFunnel : audienceSegment1MarketingFunnel,
            audienceSegment1Prompt : audienceSegment1Prompt,
            audienceSegment1Question1 : audienceSegment1Question1,
            audienceSegment1Question2 : audienceSegment1Question2,
            audienceSegment1Question3 : audienceSegment1Question3,
            audienceSegment1Question4 : audienceSegment1Question4,
            audienceSegment1Question5 : audienceSegment1Question5,
            audienceSegment1Question6 : audienceSegment1Question6,
            audienceSegment1Question7 : audienceSegment1Question7,
            audienceSegment1Question8 : audienceSegment1Question8,
            audienceSegment1Question9 : audienceSegment1Question9,
            audienceSegment1Question10 : audienceSegment1Question10,
            
            //Feature Set 2
            audienceSegment2MarketingFunnel : audienceSegment2MarketingFunnel,
            audienceSegment2Prompt : audienceSegment2Prompt,
            audienceSegment2Question1 : audienceSegment2Question1,
            audienceSegment2Question2 : audienceSegment2Question2,
            audienceSegment2Question3 : audienceSegment2Question3,
            audienceSegment2Question4 : audienceSegment2Question4,
            audienceSegment2Question5 : audienceSegment2Question5,
            audienceSegment2Question6 : audienceSegment2Question6,
            audienceSegment2Question7 : audienceSegment2Question7,
            audienceSegment2Question8 : audienceSegment2Question8,
            audienceSegment2Question9 : audienceSegment2Question9,
            audienceSegment2Question10 : audienceSegment2Question10,
            
            //Feature Set 3
            audienceSegment3MarketingFunnel : audienceSegment3MarketingFunnel,
            audienceSegment3Prompt : audienceSegment3Prompt,
            audienceSegment3Question1 : audienceSegment3Question1,
            audienceSegment3Question2 : audienceSegment3Question2,
            audienceSegment3Question3 : audienceSegment3Question3,
            audienceSegment3Question4 : audienceSegment3Question4,
            audienceSegment3Question5 : audienceSegment3Question5,
            audienceSegment3Question6 : audienceSegment3Question6,
            audienceSegment3Question7 : audienceSegment3Question7,
            audienceSegment3Question8 : audienceSegment3Question8,
            audienceSegment3Question9 : audienceSegment3Question9,
            audienceSegment3Question10 : audienceSegment3Question10,
            
            //Feature Set 4
            audienceSegment4MarketingFunnel : audienceSegment4MarketingFunnel,
            audienceSegment4Prompt : audienceSegment4Prompt,
            audienceSegment4Question1 : audienceSegment4Question1,
            audienceSegment4Question2 : audienceSegment4Question2,
            audienceSegment4Question3 : audienceSegment4Question3,
            audienceSegment4Question4 : audienceSegment4Question4,
            audienceSegment4Question5 : audienceSegment4Question5,
            audienceSegment4Question6 : audienceSegment4Question6,
            audienceSegment4Question7 : audienceSegment4Question7,
            audienceSegment4Question8 : audienceSegment4Question8,
            audienceSegment4Question9 : audienceSegment4Question9,
            audienceSegment4Question10 : audienceSegment4Question10,

            //Step 4
            label4A:label4A,
            label4B:label4B,
            label4C:label4C,
            label4D:label4D,
            label4E:label4E,
            
            hypotheses1:hypotheses1,
            hypotheses2:hypotheses2,
            hypotheses3:hypotheses3,
            hypotheses4:hypotheses4,
            hypotheses5:hypotheses5,
            hypotheses6:hypotheses6,
            hypotheses7:hypotheses7,
            hypotheses8:hypotheses8,
            hypotheses9:hypotheses9,
            hypotheses10:hypotheses10,
            hypotheses11:hypotheses11,
            hypotheses12:hypotheses12,
            hypotheses13:hypotheses13,
            hypotheses14:hypotheses14,
            hypotheses15:hypotheses15,

            proposition1:proposition1,
            proposition2:proposition2,
            proposition3:proposition3,
            proposition4:proposition4,
            proposition5:proposition5,
            proposition6:proposition6,
            proposition7:proposition7,
            proposition8:proposition8,
            proposition9:proposition9,
            proposition10:proposition10,
            proposition11:proposition11,
            proposition12:proposition12,
            proposition13:proposition13,
            proposition14:proposition14,
            proposition15:proposition15,
        }, id )
        setHasChanges(false);
        setAdditionalScreenerOption([]);
    }

    const removeClient = (clientName) => {
        let isExecuted = confirm(`Are you sure you want to remove ${clientName} ?`);
        if(isExecuted){
            props.removeClient({
                client      : clientName,
                accountId   : user.accountId
            })
        }
    }
    
    const removeBrand = (clientName, brandName) => {
        let isExecuted = confirm(`Are you sure you want to remove ${brandName} ?`);
        if(isExecuted){
            props.removeBrand({
                client      : clientName,
                brand       : brandName,
                accountId   : user.accountId
            })
        }
    }
    
    const removeStudy = (clientName, brandName, studyTitle) => {
        let isExecuted = confirm(`Are you sure you want to remove ${studyTitle} ?`);
        if(isExecuted){
            setSelectedMarketStudy(null);
            props.removeStudy({
                client      : clientName,
                brand       : brandName,
                title       : studyTitle,
                accountId   : user.accountId
            })
        }
    }

    const options = {
        employment:[
            {label:"",              value:""},
            {label:"Employed",      value:"employed"},
            {label:"Unemployed",    value:"unemployed"},
            {label:"Self-employed", value:"self-employed"},
            {label:"Unknown/ Not Applicable", value:"not-applicable"},
        ],
        age:[
            {label:"",              value:""},
            {label:"Age: 19 - 24",  value:"19-24"},
            {label:"Age: 25 - 30",  value:"25-30"},
            {label:"Age: 31 - 36",  value:"31-36"},
            {label:"Age: 37 - 42",  value:"37-42"},
            {label:"Age: 43 - 48",  value:"43-48"},
            {label:"Age: 49 - 60",  value:"49-60"},
            {label:"Unknown/ Not Applicable", value:"not-applicable"},
        ],
        parental_status:[
            {label:"",                                          value:""},
            {label:"Parent with child living at home.",         value:"parental_status-1"},
            {label:"Parent with child not living at home.",     value:"parental_status-2"},
            {label:"Single Parents",                            value:"parental_status-3"},
            {label:"Unknown/ Not Applicable", value:"not-applicable"},
        ],
        marital_status:[
            {label:"",          value:""},
            {label:"Married",   value:"married"},
            {label:"Single",    value:"single"},
            {label:"Unknown/ Not Applicable", value:"not-applicable"},
        ],
        gender:[
            {label:"",          value:""},
            {label:"Male",      value:"Male"},
            {label:"Female",    value:"Female"},
            {label:"Unknown/ Not Applicable", value:"not-applicable"},
        ],
        ethnicities:[
            {label:"",                  value:""},
            {label:"Caucasian",         value:"caucasian"},
            {label:"African American",  value:"african-american"},
            {label:"Hispanic",          value:"hispanic"},
            {label:"Pacific Islander",  value:"pacific-islander"},
            {label:"Asian",             value:"asian"},
            {label:"Native American",   value:"native-american"},
            {label:"Black",             value:"black"},
            {label:"Other",             value:"other"},
            {label:"Unknown/ Not Applicable", value:"not-applicable"},
        ],
        household_income:[
            {label:"",          value:""},
            {label:"HHI 25th",  value:"HHI-25th"},
            {label:"HHI 50th",  value:"HHI-50th"},
            {label:"HHI 75th",  value:"HHI-75th"},
            {label:"HHI 100th", value:"HHI-100th"},
            {label:"Unknown/ Not Applicable", value:"not-applicable"},
        ],
        occupation_category:[
            {label:"",                  value:""},
            {label:"Accountant",        value:"Accountant"},
            {label:"Architect",         value:"Architect"},
            {label:"Designer",          value:"Designer"},
            {label:"Doctor",            value:"Doctor"},
            {label:"Engineer",          value:"Engineer"},
            {label:"Factory worker",    value:"Factory worker"},
            {label:"Unknown/ Not Applicable", value:"not-applicable"},
        ],
        geo_country:[
            {label:"",              value:""},
            {label:'Afghanistan',  value:'Afghanistan'},
            {label:'Albania',  value:'Albania'},
            {label:'Algeria',  value:'Algeria'},
            {label:'Andorra',  value:'Andorra'},
            {label:'Angola',  value:'Angola'},
            {label:'Antigua & Deps',  value:'Antigua & Deps'},
            {label:'Argentina',  value:'Argentina'},
            {label:'Armenia',  value:'Armenia'},
            {label:'Australia',  value:'Australia'},
            {label:'Austria',  value:'Austria'},
            {label:'Azerbaijan',  value:'Azerbaijan'},
            {label:'Bahamas',  value:'Bahamas'},
            {label:'Bahrain',  value:'Bahrain'},
            {label:'Bangladesh',  value:'Bangladesh'},
            {label:'Barbados',  value:'Barbados'},
            {label:'Belarus',  value:'Belarus'},
            {label:'Belgium',  value:'Belgium'},
            {label:'Belize',  value:'Belize'},
            {label:'Benin',  value:'Benin'},
            {label:'Bhutan',  value:'Bhutan'},
            {label:'Bolivia',  value:'Bolivia'},
            {label:'Bosnia Herzegovina',  value:'Bosnia Herzegovina'},
            {label:'Botswana',  value:'Botswana'},
            {label:'Brazil',  value:'Brazil'},
            {label:'Brunei',  value:'Brunei'},
            {label:'Bulgaria',  value:'Bulgaria'},
            {label:'Burkina',  value:'Burkina'},
            {label:'Burundi',  value:'Burundi'},
            {label:'Cambodia',  value:'Cambodia'},
            {label:'Cameroon',  value:'Cameroon'},
            {label:'Canada',  value:'Canada'},
            {label:'Cape Verde',  value:'Cape Verde'},
            {label:'Central African Rep',  value:'Central African Rep'},
            {label:'Chad',  value:'Chad'},
            {label:'Chile',  value:'Chile'},
            {label:'China',  value:'China'},
            {label:'Colombia',  value:'Colombia'},
            {label:'Comoros',  value:'Comoros'},
            {label:'Congo',  value:'Congo'},
            {label:'Congo {Democratic Rep}',  value:'Congo {Democratic Rep}'},
            {label:'Costa Rica',  value:'Costa Rica'},
            {label:'Croatia',  value:'Croatia'},
            {label:'Cuba',  value:'Cuba'},
            {label:'Cyprus',  value:'Cyprus'},
            {label:'Czech Republic',  value:'Czech Republic'},
            {label:'Denmark',  value:'Denmark'},
            {label:'Djibouti',  value:'Djibouti'},
            {label:'Dominica',  value:'Dominica'},
            {label:'Dominican Republic',  value:'Dominican Republic'},
            {label:'East Timor',  value:'East Timor'},
            {label:'Ecuador',  value:'Ecuador'},
            {label:'Egypt',  value:'Egypt'},
            {label:'El Salvador',  value:'El Salvador'},
            {label:'Equatorial Guinea',  value:'Equatorial Guinea'},
            {label:'Eritrea',  value:'Eritrea'},
            {label:'Estonia',  value:'Estonia'},
            {label:'Eswatini',  value:'Eswatini'},
            {label:'Ethiopia',  value:'Ethiopia'},
            {label:'Fiji',  value:'Fiji'},
            {label:'Finland',  value:'Finland'},
            {label:'France',  value:'France'},
            {label:'Gabon',  value:'Gabon'},
            {label:'Gambia',  value:'Gambia'},
            {label:'Georgia',  value:'Georgia'},
            {label:'Germany',  value:'Germany'},
            {label:'Ghana',  value:'Ghana'},
            {label:'Greece',  value:'Greece'},
            {label:'Grenada',  value:'Grenada'},
            {label:'Guatemala',  value:'Guatemala'},
            {label:'Guinea',  value:'Guinea'},
            {label:'Guinea-Bissau',  value:'Guinea-Bissau'},
            {label:'Guyana',  value:'Guyana'},
            {label:'Haiti',  value:'Haiti'},
            {label:'Honduras',  value:'Honduras'},
            {label:'Hungary',  value:'Hungary'},
            {label:'Iceland',  value:'Iceland'},
            {label:'India',  value:'India'},
            {label:'Indonesia',  value:'Indonesia'},
            {label:'Iran',  value:'Iran'},
            {label:'Iraq',  value:'Iraq'},
            {label:'Ireland {Republic}',  value:'Ireland {Republic}'},
            {label:'Israel',  value:'Israel'},
            {label:'Italy',  value:'Italy'},
            {label:'Ivory Coast',  value:'Ivory Coast'},
            {label:'Jamaica',  value:'Jamaica'},
            {label:'Japan',  value:'Japan'},
            {label:'Jordan',  value:'Jordan'},
            {label:'Kazakhstan',  value:'Kazakhstan'},
            {label:'Kenya',  value:'Kenya'},
            {label:'Kiribati',  value:'Kiribati'},
            {label:'Korea North',  value:'Korea North'},
            {label:'Korea South',  value:'Korea South'},
            {label:'Kosovo',  value:'Kosovo'},
            {label:'Kuwait',  value:'Kuwait'},
            {label:'Kyrgyzstan',  value:'Kyrgyzstan'},
            {label:'Laos',  value:'Laos'},
            {label:'Latvia',  value:'Latvia'},
            {label:'Lebanon',  value:'Lebanon'},
            {label:'Lesotho',  value:'Lesotho'},
            {label:'Liberia',  value:'Liberia'},
            {label:'Libya',  value:'Libya'},
            {label:'Liechtenstein',  value:'Liechtenstein'},
            {label:'Lithuania',  value:'Lithuania'},
            {label:'Luxembourg',  value:'Luxembourg'},
            {label:'Macedonia',  value:'Macedonia'},
            {label:'Madagascar',  value:'Madagascar'},
            {label:'Malawi',  value:'Malawi'},
            {label:'Malaysia',  value:'Malaysia'},
            {label:'Maldives',  value:'Maldives'},
            {label:'Mali',  value:'Mali'},
            {label:'Malta',  value:'Malta'},
            {label:'Marshall Islands',  value:'Marshall Islands'},
            {label:'Mauritania',  value:'Mauritania'},
            {label:'Mauritius',  value:'Mauritius'},
            {label:'Mexico',  value:'Mexico'},
            {label:'Micronesia',  value:'Micronesia'},
            {label:'Moldova',  value:'Moldova'},
            {label:'Monaco',  value:'Monaco'},
            {label:'Mongolia',  value:'Mongolia'},
            {label:'Montenegro',  value:'Montenegro'},
            {label:'Morocco',  value:'Morocco'},
            {label:'Mozambique',  value:'Mozambique'},
            {label:'Myanmar',  value:'Myanmar'},
            {label:'Namibia',  value:'Namibia'},
            {label:'Nauru',  value:'Nauru'},
            {label:'Nepal',  value:'Nepal'},
            {label:'Netherlands',  value:'Netherlands'},
            {label:'New Zealand',  value:'New Zealand'},
            {label:'Nicaragua',  value:'Nicaragua'},
            {label:'Niger',  value:'Niger'},
            {label:'Nigeria',  value:'Nigeria'},
            {label:'Norway',  value:'Norway'},
            {label:'Oman',  value:'Oman'},
            {label:'Pakistan',  value:'Pakistan'},
            {label:'Palau',  value:'Palau'},
            {label:'Palestine',  value:'Palestine'},
            {label:'Panama',  value:'Panama'},
            {label:'Papua New Guinea',  value:'Papua New Guinea'},
            {label:'Paraguay',  value:'Paraguay'},
            {label:'Peru',  value:'Peru'},
            {label:'Philippines',  value:'Philippines'},
            {label:'Poland',  value:'Poland'},
            {label:'Portugal',  value:'Portugal'},
            {label:'Qatar',  value:'Qatar'},
            {label:'Romania',  value:'Romania'},
            {label:'Russian Federation',  value:'Russian Federation'},
            {label:'Rwanda',  value:'Rwanda'},
            {label:'St Kitts & Nevis',  value:'St Kitts & Nevis'},
            {label:'St Lucia',  value:'St Lucia'},
            {label:'Saint Vincent & the Grenadines',  value:'Saint Vincent & the Grenadines'},
            {label:'Samoa',  value:'Samoa'},
            {label:'San Marino',  value:'San Marino'},
            {label:'Sao Tome & Principe',  value:'Sao Tome & Principe'},
            {label:'Saudi Arabia',  value:'Saudi Arabia'},
            {label:'Senegal',  value:'Senegal'},
            {label:'Serbia',  value:'Serbia'},
            {label:'Seychelles',  value:'Seychelles'},
            {label:'Sierra Leone',  value:'Sierra Leone'},
            {label:'Singapore',  value:'Singapore'},
            {label:'Slovakia',  value:'Slovakia'},
            {label:'Slovenia',  value:'Slovenia'},
            {label:'Solomon Islands',  value:'Solomon Islands'},
            {label:'Somalia',  value:'Somalia'},
            {label:'South Africa',  value:'South Africa'},
            {label:'South Sudan',  value:'South Sudan'},
            {label:'Spain',  value:'Spain'},
            {label:'Sri Lanka',  value:'Sri Lanka'},
            {label:'Sudan',  value:'Sudan'},
            {label:'Suriname',  value:'Suriname'},
            {label:'Sweden',  value:'Sweden'},
            {label:'Switzerland',  value:'Switzerland'},
            {label:'Syria',  value:'Syria'},
            {label:'Taiwan',  value:'Taiwan'},
            {label:'Tajikistan',  value:'Tajikistan'},
            {label:'Tanzania',  value:'Tanzania'},
            {label:'Thailand',  value:'Thailand'},
            {label:'Togo',  value:'Togo'},
            {label:'Tonga',  value:'Tonga'},
            {label:'Trinidad & Tobago',  value:'Trinidad & Tobago'},
            {label:'Tunisia',  value:'Tunisia'},
            {label:'Turkey',  value:'Turkey'},
            {label:'Turkmenistan',  value:'Turkmenistan'},
            {label:'Tuvalu',  value:'Tuvalu'},
            {label:'Uganda',  value:'Uganda'},
            {label:'Ukraine',  value:'Ukraine'},
            {label:'United Arab Emirates',  value:'United Arab Emirates'},
            {label:'United Kingdom',  value:'United Kingdom'},
            {label:'United States',  value:'United States'},
            {label:'Uruguay',  value:'Uruguay'},
            {label:'Uzbekistan',  value:'Uzbekistan'},
            {label:'Vanuatu',  value:'Vanuatu'},
            {label:'Vatican City',  value:'Vatican City'},
            {label:'Venezuela',  value:'Venezuela'},
            {label:'Vietnam',  value:'Vietnam'},
            {label:'Yemen',  value:'Yemen'},
            {label:'Zambia',  value:'Zambia'},
            {label:'Zimbabwe',  value:'Zimbabwe'},
            {label:"Unknown/ Not Applicable", value:"not-applicable"},
        ],
        


    }


    useEffect(() => {
        props.getMarketResearch(user.accountId);
        props.getBluekaiList()
    },[]);

    /************************************************************************************
     * Populate the dropdown of Audience Targeting When bluekai list done loading
     ************************************************************************************/
     useEffect(() => {
        if(bluekai && bluekai.length > 0){
            setOptionBluekai(refit_keys_excluded(bluekai));
        }
    },[
        bluekai,
    ]);

    useEffect(() => {
        if(selectedMarketStudy != null){
            setId(getSelectedStudy("_id", selectedMarketStudy));
        }
    },[selectedMarketStudy]);

    const handleDropdownChange = (dropdown) => {
        const value = Array.isArray(dropdown)
            ? dropdown.map((item) => {
                return item.value
            })
            : dropdown === null ? "" : dropdown.value
        return (value)
    }

    const updateCustomScreener = (index, question, answer, options) => {
        let newArr = [...customScreener]; 
        newArr[index] = 
        {
            answer:answer,
            question:question,
            options:options
        }
        setCustomScreener(newArr);
    }

    /***************************
     * BLUE KAI OPTION LIST
     **************************/

    // Get Audience Targeting (BLUEKAI)
    const flattenArr = (a, prefix = '') => {  
        return a.reduce(function (flattened, {name, categoryId, childNodes}) {
            return flattened
            .concat([{name, categoryId}])
            .concat(childNodes ? flattenArr(childNodes, name) : []);
        }, []);
    }
    const mapShortToLong = new Map([
        ["categoryId",  "value"],
        ["name",        "label"],
        ["childNodes",  "children"],
    ]);
    const getNestedPath = (arr,name) => {
        for(let item of arr){
            if(item.name===name) return ` > ${name}`;
            if(item.childNodes) {
                const child = getNestedPath(item.childNodes, name);
                if(child) return ` > ${item.name}${child}`
            }
        }
    };
    const refit_keys_included = (o) => {
        if (o === null || typeof o !== "object") {
            return o;
        }
        if (Array.isArray(o)) {
            return o.map(refit_keys_included);
        }
        const build = {};
        for (const key in o) {
            const destKey = mapShortToLong.get(key) || key;
            let value = o[key];
            if (typeof value === "object") {
                value = refit_keys_included(value);
            }
            build[destKey] = value;
            if(destKey == "value" && whiteListedBluekai.includes(value.toString())){
                    build["checked"] = true;
            }
            if(destKey == "label"){
                let path  = getNestedPath(bluekai, o[key])
                let split = path.split(" > ")
                let x = split.slice(0, split.length - 1).join(" > ")
                let breadcrumb = x.length > 0 ? " ( " + x.substring(2) + " )" : ""
                build["tagLabel"] = o[key] + breadcrumb
            }
        }
        return build;
    }
    const refit_keys_excluded = (o) => {
        if (o === null || typeof o !== "object") {
            return o;
        }
        if (Array.isArray(o)) {
            return o.map(refit_keys_excluded);
        }
        const build = {};
        for (const key in o) {
            const destKey = mapShortToLong.get(key) || key;
            let value = o[key];
            if (typeof value === "object") {
                value = refit_keys_excluded(value);
            }
            build[destKey] = value;
            if(destKey == "value" && blackListedBluekai.includes(value.toString())){
                    build["checked"] = true;
            }
            if(destKey == "label"){
                let path  = getNestedPath(bluekai, o[key])
                let split = path.split(" > ")
                let x = split.slice(0, split.length - 1).join(" > ")
                let breadcrumb = x.length > 0 ? " ( " + x.substring(2) + " )" : ""
                build["tagLabel"] = o[key] + breadcrumb
            }
        }
        return build;
    }

    const handleTreeSelectOnChangeInclude = (currentNode, selectedNodes) => {
        let a = adgroup;
        if(a.targeting.audience !== undefined){
            //Find the first index in the array where it has "OR"
            let target_index = a.targeting.audience["AND"].findIndex(x => x.OR !== undefined)
            target_index = target_index >= 0 ? target_index : 0
            
            // Create an OR inside AND if it doesn't exist
            if(a.targeting.audience.AND.length == 0){
                a.targeting.audience.AND.push({OR:[]}) 
            }

            //Push the new item included
            a.targeting.audience["AND"][target_index]["OR"].push({category:`bluekai:${currentNode.value}`});

            //Remove empty OR if there's any
            if(a.targeting.audience.OR && a.targeting.audience.OR.length == 0){
                delete a.targeting.audience.OR
            }
    
            //Deterimine if its
            const action = selectedNodes.findIndex(x => x.value == currentNode.value) >= 0 ? "add" : "remove"
    
            //Update the state
            //Push the new item included
            if(action == "add"){
                // console.log(a)
                // console.log(a.targeting.audience.AND[target_index].OR)
                setAdgroup(a)
            }
            updateTargetedAudience();
        }
    }
    const handleTreeSelectOnChangeExclude = (currentNode, selectedNodes) => {

        let a = adgroup;

        //Find the first index in the array where it has "OR"
        if(a.targeting.audience !== undefined){
            //Deterimine if its
            const action = selectedNodes.findIndex(x => x.value == currentNode.value) >= 0 ? "add" : "remove"
    
            if(action == "add"){

                // Create a NOT inside AND if it doesn't exist
                if(a.targeting.audience.AND.findIndex(x => x.NOT !== undefined) == -1){
                    a.targeting.audience.AND.push({NOT:[]}) 
                }

                //Set the target_index where we placed the NOT inside AND
                let target_index = a.targeting.audience["AND"].findIndex(x => x.NOT !== undefined)
                target_index = target_index >= 0 ? target_index : 0
                
                // Create an OR inside AND.NOT if it doesn't exist
                if(a.targeting.audience.AND[target_index].NOT.findIndex(x => x.OR !== undefined) == -1){
                    a.targeting.audience.AND[target_index].NOT.push({OR:[]}) 
                }

                //Now we can push the new item included
                a.targeting.audience.AND[target_index].NOT[0].OR.push({category:`bluekai:${currentNode.value}`})

                //Remove empty OR if there's any
                if(a.targeting.audience.OR && a.targeting.audience.OR.length == 0){
                    delete a.targeting.audience.OR
                }

                // Update the state
                // console.log(a)
                // console.log(a.targeting.audience.AND[target_index].NOT[0].OR)
                setAdgroup(a)
                updateTargetedAudience();
            }
            if(action == "remove"){
                // console.log(a.targeting.audience.AND[target_index].NOT[0].OR.findIndex(x => x.category == `bluekai:${currentNode.value}`))
                // a.targeting.audience.AND[target_index].NOT[0].OR.push({category:`bluekai:${currentNode.value}`})
            }
        }

 
    }
    const handleTreeSelectOnAction = (node, action) => {
        console.log('onAction::', action, node)
    }
    const handleTreeSelectOnNodecurrentNode = () => {
        console.log('onNodeToggle::', currentNode)
    }

    try{
        return (
            <div className="home-page">
    
                {
                    user.hasIF && 
                    <>
                            {/* SUB MENU */}
                            <LeftNavigation />
                            
                            <div className="dashboard">
    
                                {/* DETAIL COLUMN */}
                                <div className={`details-column market-research ${isResultShown ? "result" : ""} ${isCreativeAnalysisShown ? "creative-analysis" : ""}`}>

                                    <div className="tabs-wrapper">
                                        <Tabs id="controlled-tab-example" activeKey={tabKey} onSelect={(k) => setTabKey(k)} className="mb-3">

                                            {/* BRAND STUDY TAB */}
                                            <Tab eventKey="BRAND_STUDY" title="Brand Study">

                                                    {/* ADD NEW BRAND */}
                                                    {
                                                        selectedClient != null && selectedBrand != null &&
                                                        <div className="controls">
                                                            <OverlayTrigger placement="top" content="" overlay={<Tooltip>New Brand Study</Tooltip>}>
                                                                <button className="add-entity creatives" onClick={() => {
                                                                    clearMarketStudyForm();
                                                                    setSelectedMarketStudy(marketResearch.title); 
                                                                    showStudyForm(marketResearch.title); 
                                                                    setIsNewMarketStudy(true);
                                                                    setHasChanges(false);
                                                                    setIsFormShown(true);
                                                                }}><i className="fa fa-plus" aria-hidden="true"></i></button>
                                                            </OverlayTrigger>
                                                        </div>
                                                    }
                                                    {
                                                        
                                                        selectedClient == null ?
                                                            <div className="empty-text">Please choose a Client</div>
                                                        : selectedBrand == null ?
                                                            <div className="empty-text">Please choose a Brand</div>
                                                        : !isFormShown && marketResearch.items && marketResearch.items.filter(item => item.brand == selectedBrand && item.title !== undefined).length == 0 ?
                                                            <div className="empty-text">No Results Found</div>
                                                        : !isFormShown &&
                                                            <div className="fixed-table-wrapper"> 
                                                                <table className="primary brand-study">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Brand Study</th>
                                                                            <th>Ordered Date</th>
                                                                            <th>Completed Date</th>
                                                                            <th></th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            marketResearch.items && marketResearch.items.filter(item => item.brand == selectedBrand && item.title !== undefined).map(marketResearch => 
                                                                                <tr key={marketResearch._id}>
                                                                                    <td>{marketResearch.title}</td>
                                                                                    <td>-</td>
                                                                                    <td>-</td>
                                                                                    <td>
                                                                                        <OverlayTrigger placement="left" content="" overlay={<Tooltip>Edit</Tooltip>}>
                                                                                            <i className="fa fa-pencil" aria-hidden="true" onClick={() => {
                                                                                                clearMarketStudyForm();
                                                                                                setSelectedMarketStudy(marketResearch.title); 
                                                                                                showStudyForm(marketResearch.title); 
                                                                                                setIsNewMarketStudy(false);
                                                                                                setHasChanges(false);
                                                                                                setIsFormShown(true);
                                                                                            }}></i>
                                                                                        </OverlayTrigger>
                                                                                    </td>
                                                                                    <td>
                                                                                        <OverlayTrigger placement="left" content="" overlay={<Tooltip>Remove</Tooltip>}>
                                                                                                <i className="fa fa-trash" aria-hidden="true" onClick={(e) => {e.stopPropagation(); removeStudy(marketResearch.client, marketResearch.brand, marketResearch.title)}}></i>
                                                                                        </OverlayTrigger>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                    }
                                                    {
                                                        isFormShown && (selectedMarketStudy != null || isNewMarketStudy) &&
                                                                <div className="inner-page-wrapper">
                                                                    <div className="outline">
                                                                        <OverlayTrigger placement="top" content="" overlay={<Tooltip>Back</Tooltip>}>
                                                                            <button className='creative-analysis-back' onClick={() => goBackToBrandStudy()}>
                                                                                <i class="fa fa-arrow-left" aria-hidden="true"></i> <span>Back</span>
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                        <ul>
                                                                            <li className={currentStep == "1" ? "active" : ""} onClick={() => { setCurrentStep("1"); setIsExampleShown(false); }}>
                                                                                <div className={`step`}>1</div>
                                                                                <div className="text">
                                                                                    <div className="label">Segment Definitions</div>
                                                                                    <div className="sub-title">What type of audience do you want to evaluate?</div>
                                                                                </div>
                                                                            </li>
                                                                            <li className={currentStep == "2" ? "active" : ""} onClick={() => setCurrentStep("2")}>
                                                                                <div className={`step`}>2</div>
                                                                                <div className="text">
                                                                                    <div className="label">Screeners</div>
                                                                                    <div className="sub-title">What are the factors to use as filters to isolate the audience segments above?</div>
                                                                                </div>
                                                                            </li>
                                                                            <li onClick={() => setCurrentStep("3a")}>
                                                                                <div className={`step`}>3</div>
                                                                                <div className="text">
                                                                                    <div className="label">Feature Sets</div>
                                                                                </div>
                                                                            </li>
                                                                            <ul>
                                                                                <li className={currentStep == "3a" ? "active" : ""} onClick={() => setCurrentStep("3a")}>
                                                                                    <div className={`step`}>A</div>
                                                                                    <div className="text">
                                                                                        <div className="label">Set 1</div>
                                                                                    </div>
                                                                                </li>
                                                                                <li className={currentStep == "3b" ? "active" : ""} onClick={() => setCurrentStep("3b")}>
                                                                                    <div className={`step`}>B</div>
                                                                                    <div className="text">
                                                                                        <div className="label">Set 2</div>
                                                                                    </div>
                                                                                </li>
                                                                                <li className={currentStep == "3c" ? "active" : ""} onClick={() => setCurrentStep("3c")}>
                                                                                    <div className={`step`}>C</div>
                                                                                    <div className="text">
                                                                                        <div className="label">Set 3</div>
                                                                                    </div>
                                                                                </li>
                                                                                <li className={currentStep == "3d" ? "active" : ""} onClick={() => setCurrentStep("3d")}>
                                                                                    <div className={`step`}>D</div>
                                                                                    <div className="text">
                                                                                        <div className="label">Set 4</div>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                            <li onClick={() => setCurrentStep("4a")}>
                                                                                <div className={`step`}>4</div>
                                                                                <div className="text">
                                                                                    <div className="label">Emotional Linguistic Analysis</div>
                                                                                </div>
                                                                            </li>
                                                                            <ul>
                                                                                <li className={currentStep == "4a" ? "active" : ""} onClick={() => setCurrentStep("4a")}>
                                                                                    <div className={`step`}>A</div>
                                                                                    <div className="text">
                                                                                        <div className="label">{label4A}</div>
                                                                                    </div>
                                                                                </li>
                                                                                <li className={currentStep == "4b" ? "active" : ""} onClick={() => setCurrentStep("4b")}>
                                                                                    <div className={`step`}>B</div>
                                                                                    <div className="text">
                                                                                        <div className="label">{label4B}</div>
                                                                                    </div>
                                                                                </li>
                                                                                <li className={currentStep == "4c" ? "active" : ""} onClick={() => setCurrentStep("4c")}>
                                                                                    <div className={`step`}>C</div>
                                                                                    <div className="text">
                                                                                        <div className="label">{label4C}</div>
                                                                                    </div>
                                                                                </li>
                                                                                <li className={currentStep == "4d" ? "active" : ""} onClick={() => setCurrentStep("4d")}>
                                                                                    <div className={`step`}>D</div>
                                                                                    <div className="text">
                                                                                        <div className="label">{label4D}</div>
                                                                                    </div>
                                                                                </li>
                                                                                <li className={currentStep == "4e" ? "active" : ""} onClick={() => setCurrentStep("4e")}>
                                                                                    <div className={`step`}>E</div>
                                                                                    <div className="text">
                                                                                        <div className="label">{label4E}</div>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="form-section">
                                                                        <table className="client-name">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td colSpan="2">
                                                                                        <button type="button" onClick={() => { setIsExampleShown(!isExampleShown); }} className={`btn btn-primary btn-sm btn-example ${currentStep == "1" ? "d-none" : ""} ${isResultShown ? "" : "active"}`}> Show Example</button>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Title</td>
                                                                                    <td>
                                                                                        <input type='text' value={isNewMarketStudy ? title : title == "" ? getSelectedStudy("title", selectedMarketStudy) : title} onChange={(e) => { setHasChanges(true); setTitle(e.target.value) }} />
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Client</td>
                                                                                    <td>
                                                                                        <Select
                                                                                            onChange={(event) => { setHasChanges(true); setHasChanges(true); setClientName(handleDropdownChange(event)); setIsBrandNameNeedToChange(true); setBrandName("") }}
                                                                                            className="select-primary"
                                                                                            placeholder={
                                                                                                isNewMarketStudy ? clientName : clientName == "" ? selectedClient : clientName
                                                                                            }
                                                                                            value={
                                                                                                isNewMarketStudy ? clientName : clientName == "" ? selectedClient : clientName
                                                                                            }
                                                                                            options={
                                                                                                marketResearch.items && marketResearch.items.filter((value, index, self) => index === self.findIndex((t) => (t.client === value.client))).map(marketResearch => {
                                                                                                    return ({
                                                                                                        "label": marketResearch.client,
                                                                                                        "value": marketResearch.client
                                                                                                    })
                                                                                                }
                                                                                                )
                                                                                            }
                                                                                            isClearable={false}
                                                                                            styles={styleSelectBasic} />
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Brand Name</td>
                                                                                    <td>
                                                                                        <Select
                                                                                            onChange={(event) => { setHasChanges(true); setHasChanges(true); setBrandName(handleDropdownChange(event)); setIsBrandNameNeedToChange(false); }}
                                                                                            className="select-primary"
                                                                                            placeholder={
                                                                                                isNewMarketStudy ? brandName : isBrandNameNeedToChange ? "" : brandName == "" ? selectedBrand : brandName
                                                                                            }
                                                                                            value={
                                                                                                isNewMarketStudy ? brandName : isBrandNameNeedToChange ? "" : brandName == "" ? selectedBrand : brandName
                                                                                            }
                                                                                            options={
                                                                                                clientName == "" ?
                                                                                                    marketResearch.items && marketResearch.items.filter(item => item.client == selectedClient).filter((value, index, self) => index === self.findIndex((t) => (t.brand === value.brand))).map(marketResearch => {
                                                                                                        return ({
                                                                                                            "label": marketResearch.brand,
                                                                                                            "value": marketResearch.brand
                                                                                                        })
                                                                                                    }
                                                                                                    )
                                                                                                    :
                                                                                                    marketResearch.items && marketResearch.items.filter(item => item.client == clientName).filter((value, index, self) => index === self.findIndex((t) => (t.brand === value.brand))).map(marketResearch => {
                                                                                                        return ({
                                                                                                            "label": marketResearch.brand,
                                                                                                            "value": marketResearch.brand
                                                                                                        })
                                                                                                    }
                                                                                                    )

                                                                                            }
                                                                                            isClearable={false}
                                                                                            styles={styleSelectBasic} />
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        {
                                                                            currentStep == 1 ?
                                                                                <table className='screeners'>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th colSpan={3}>
                                                                                                {
                                                                                                    (totalParticipantA == "" ? 0 : parseInt(totalParticipantA)) +
                                                                                                    (totalParticipantB == "" ? 0 : parseInt(totalParticipantB)) +
                                                                                                    (totalParticipantC == "" ? 0 : parseInt(totalParticipantC))
                                                                                                }
                                                                                                &nbsp; Participants
                                                                                            </th>
                                                                                            <th>Segment 1</th>
                                                                                            <th>Segment 2</th>
                                                                                            <th>Segment 3</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                            <td><input type='text' value={dimension1} onChange={(e) => { setHasChanges(true); setDimension1(e.target.value) }} /></td>
                                                                                            <td><input type='text' value={dimension2} onChange={(e) => { setHasChanges(true); setDimension2(e.target.value) }} /></td>
                                                                                            <td><input type='text' value={dimension3} onChange={(e) => { setHasChanges(true); setDimension3(e.target.value) }} /></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><input type='text' value={totalParticipantA} onChange={(e) => { setHasChanges(true); setTotalParticipantA(e.target.value) }} /></td>
                                                                                            <td>Dimension 1</td>
                                                                                            <td><input type='text' value={segment1} onChange={(e) => { setHasChanges(true); setSegment1(e.target.value) }} /></td>
                                                                                            {/* <td>
                                                                                                        <DropdownTreeSelect 
                                                                                                            data={optionBluekai} 
                                                                                                            onChange={handleTreeSelectOnChangeInclude} 
                                                                                                            onAction={handleTreeSelectOnAction} 
                                                                                                            onNodeToggle={handleTreeSelectOnNodecurrentNode} />
                                                                                                    </td> */}
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><input type='text' value={totalParticipantB} onChange={(e) => { setHasChanges(true); setTotalParticipantB(e.target.value) }} /></td>
                                                                                            <td>Dimension 2</td>
                                                                                            <td><input type='text' value={segment2} onChange={(e) => { setHasChanges(true); setSegment2(e.target.value) }} /></td>
                                                                                            {/* <td>
                                                                                                        <DropdownTreeSelect 
                                                                                                            data={optionBluekai} 
                                                                                                            onChange={handleTreeSelectOnChangeInclude} 
                                                                                                            onAction={handleTreeSelectOnAction} 
                                                                                                            onNodeToggle={handleTreeSelectOnNodecurrentNode} />
                                                                                                    </td> */}
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><input type='text' value={totalParticipantC} onChange={(e) => { setHasChanges(true); setTotalParticipantC(e.target.value) }} /></td>
                                                                                            <td>Dimension 3</td>
                                                                                            <td><input type='text' value={segment3} onChange={(e) => { setHasChanges(true); setSegment3(e.target.value) }} /></td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                        </tr>

                                                                                    </tbody>
                                                                                </table>
                                                                                : currentStep == 2 ?
                                                                                    <div className="option-group">
                                                                                        <table>
                                                                                            <tbody>

                                                                                                <tr>
                                                                                                    <td>Employment</td>
                                                                                                    <td>
                                                                                                        <div className="select-wrapper">
                                                                                                            <Select
                                                                                                                onChange={(event) => { setHasChanges(true); setEmployment(handleDropdownChange(event)) }}
                                                                                                                className="select-primary multi"
                                                                                                                // placeholder={
                                                                                                                //     options.employment.filter(item => item.value == employment)[0].label
                                                                                                                // }
                                                                                                                // value={
                                                                                                                //     options.employment.filter(item => item.value == employment)[0].label
                                                                                                                // }
                                                                                                                // defaultValue={[
                                                                                                                //     {label:"Employed",      value:"employed"},
                                                                                                                //     {label:"Unemployed",    value:"unemployed"},
                                                                                                                // ]}
                                                                                                                defaultValue={[
                                                                                                                    Array.isArray(employment) ?
                                                                                                                        employment.map((selected_item) => {
                                                                                                                            return (
                                                                                                                                options.employment.filter(item => item.value == selected_item).length > 0 ? {
                                                                                                                                    label: options.employment.filter(item => item.value == selected_item)[0].label,
                                                                                                                                    value: selected_item
                                                                                                                                }
                                                                                                                                    : {

                                                                                                                                    }
                                                                                                                            )
                                                                                                                        })
                                                                                                                        : {
                                                                                                                            label: options.employment.filter(item => item.value == getSelectedStudy("employment", selectedMarketStudy)).map(item => { return (item.label) }),
                                                                                                                            value: getSelectedStudy("employment", selectedMarketStudy)
                                                                                                                        }


                                                                                                                ]}
                                                                                                                value={
                                                                                                                    Array.isArray(employment)
                                                                                                                    && employment.map((selected_item) => {
                                                                                                                        return (
                                                                                                                            {
                                                                                                                                label: options.employment.filter(item => item.value == selected_item)[0].label,
                                                                                                                                value: selected_item
                                                                                                                            }
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                                isMulti
                                                                                                                options={options.employment}
                                                                                                                multi={true}
                                                                                                                isClearable={false}
                                                                                                                styles={styleSelectBasic} />
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>Age</td>
                                                                                                    <td>
                                                                                                        <div className="select-wrapper">
                                                                                                            <Select
                                                                                                                onChange={(event) => { setHasChanges(true); setAge(handleDropdownChange(event)) }}
                                                                                                                className="select-primary multi"
                                                                                                                defaultValue={[
                                                                                                                    Array.isArray(age) ?
                                                                                                                        age.map((selected_item) => {
                                                                                                                            return (
                                                                                                                                options.age.filter(item => item.value == selected_item).length > 0 ? {
                                                                                                                                    label: options.age.filter(item => item.value == selected_item)[0].label,
                                                                                                                                    value: selected_item
                                                                                                                                }
                                                                                                                                    : {

                                                                                                                                    }
                                                                                                                            )
                                                                                                                        })
                                                                                                                        : {
                                                                                                                            label: options.age.filter(item => item.value == getSelectedStudy("age", selectedMarketStudy)).map(item => { return (item.label) }),
                                                                                                                            value: getSelectedStudy("age", selectedMarketStudy)
                                                                                                                        }


                                                                                                                ]}
                                                                                                                value={
                                                                                                                    Array.isArray(age)
                                                                                                                    && age.map((selected_item) => {
                                                                                                                        return (
                                                                                                                            {
                                                                                                                                label: options.age.filter(item => item.value == selected_item)[0].label,
                                                                                                                                value: selected_item
                                                                                                                            }
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                                isMulti
                                                                                                                options={options.age}
                                                                                                                isClearable={false}
                                                                                                                styles={styleSelectBasic} />
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>Parental Status</td>
                                                                                                    <td>
                                                                                                        <div className="select-wrapper">
                                                                                                            <Select
                                                                                                                onChange={(event) => { setHasChanges(true); setParents(handleDropdownChange(event)) }}
                                                                                                                className="select-primary multi"
                                                                                                                defaultValue={[
                                                                                                                    Array.isArray(parents) ?
                                                                                                                        parents.map((selected_item) => {
                                                                                                                            return (
                                                                                                                                options.parental_status.filter(item => item.value == selected_item).length > 0 ? {
                                                                                                                                    label: options.parental_status.filter(item => item.value == selected_item)[0].label,
                                                                                                                                    value: selected_item
                                                                                                                                }
                                                                                                                                    : {

                                                                                                                                    }
                                                                                                                            )
                                                                                                                        })
                                                                                                                        : {
                                                                                                                            label: options.parental_status.filter(item => item.value == getSelectedStudy("parental_status", selectedMarketStudy)).map(item => { return (item.label) }),
                                                                                                                            value: getSelectedStudy("parental_status", selectedMarketStudy)
                                                                                                                        }


                                                                                                                ]}
                                                                                                                value={
                                                                                                                    Array.isArray(parents)
                                                                                                                    && parents.map((selected_item) => {
                                                                                                                        return (
                                                                                                                            {
                                                                                                                                label: options.parental_status.filter(item => item.value == selected_item)[0].label,
                                                                                                                                value: selected_item
                                                                                                                            }
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                                isMulti
                                                                                                                options={options.parental_status}
                                                                                                                isClearable={false}
                                                                                                                styles={styleSelectBasic} />
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>Marital Status</td>
                                                                                                    <td>
                                                                                                        <div className="select-wrapper">
                                                                                                            <Select
                                                                                                                onChange={(event) => { setHasChanges(true); setMaritalStatus(handleDropdownChange(event)) }}
                                                                                                                className="select-primary multi"
                                                                                                                defaultValue={[
                                                                                                                    Array.isArray(maritalStatus) ?
                                                                                                                        maritalStatus.map((selected_item) => {
                                                                                                                            return (
                                                                                                                                options.marital_status.filter(item => item.value == selected_item).length > 0 ? {
                                                                                                                                    label: options.marital_status.filter(item => item.value == selected_item)[0].label,
                                                                                                                                    value: selected_item
                                                                                                                                }
                                                                                                                                    : {

                                                                                                                                    }
                                                                                                                            )
                                                                                                                        })
                                                                                                                        : {
                                                                                                                            label: options.marital_status.filter(item => item.value == getSelectedStudy("parental_status", selectedMarketStudy)).map(item => { return (item.label) }),
                                                                                                                            value: getSelectedStudy("parental_status", selectedMarketStudy)
                                                                                                                        }


                                                                                                                ]}
                                                                                                                value={
                                                                                                                    Array.isArray(maritalStatus)
                                                                                                                    && maritalStatus.map((selected_item) => {
                                                                                                                        return (
                                                                                                                            {
                                                                                                                                label: options.marital_status.filter(item => item.value == selected_item)[0].label,
                                                                                                                                value: selected_item
                                                                                                                            }
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                                isMulti
                                                                                                                options={options.marital_status}
                                                                                                                isClearable={false}
                                                                                                                styles={styleSelectBasic} />
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>Gender</td>
                                                                                                    <td>
                                                                                                        <div className="select-wrapper">
                                                                                                            <Select
                                                                                                                onChange={(event) => { setHasChanges(true); setGender(handleDropdownChange(event)) }}
                                                                                                                className="select-primary multi"
                                                                                                                defaultValue={[
                                                                                                                    Array.isArray(gender) ?
                                                                                                                        gender.map((selected_item) => {
                                                                                                                            return (
                                                                                                                                options.gender.filter(item => item.value == selected_item).length > 0 ? {
                                                                                                                                    label: options.gender.filter(item => item.value == selected_item)[0].label,
                                                                                                                                    value: selected_item
                                                                                                                                }
                                                                                                                                    : {

                                                                                                                                    }
                                                                                                                            )
                                                                                                                        })
                                                                                                                        : {
                                                                                                                            label: options.gender.filter(item => item.value == getSelectedStudy("parental_status", selectedMarketStudy)).map(item => { return (item.label) }),
                                                                                                                            value: getSelectedStudy("parental_status", selectedMarketStudy)
                                                                                                                        }


                                                                                                                ]}
                                                                                                                value={
                                                                                                                    Array.isArray(gender)
                                                                                                                    && gender.map((selected_item) => {
                                                                                                                        return (
                                                                                                                            {
                                                                                                                                label: options.gender.filter(item => item.value == selected_item)[0].label,
                                                                                                                                value: selected_item
                                                                                                                            }
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                                isMulti
                                                                                                                options={options.gender}
                                                                                                                isClearable={false}
                                                                                                                styles={styleSelectBasic} />
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>Ethnicities</td>
                                                                                                    <td>
                                                                                                        <div className="select-wrapper">
                                                                                                            <Select
                                                                                                                onChange={(event) => { setHasChanges(true); setEthnicities(handleDropdownChange(event)) }}
                                                                                                                className="select-primary multi"
                                                                                                                defaultValue={[
                                                                                                                    Array.isArray(ethnicities) ?
                                                                                                                        ethnicities.map((selected_item) => {
                                                                                                                            return (
                                                                                                                                options.ethnicities.filter(item => item.value == selected_item).length > 0 ? {
                                                                                                                                    label: options.ethnicities.filter(item => item.value == selected_item)[0].label,
                                                                                                                                    value: selected_item
                                                                                                                                }
                                                                                                                                    : {

                                                                                                                                    }
                                                                                                                            )
                                                                                                                        })
                                                                                                                        : {
                                                                                                                            label: options.ethnicities.filter(item => item.value == getSelectedStudy("parental_status", selectedMarketStudy)).map(item => { return (item.label) }),
                                                                                                                            value: getSelectedStudy("parental_status", selectedMarketStudy)
                                                                                                                        }


                                                                                                                ]}
                                                                                                                value={
                                                                                                                    Array.isArray(ethnicities)
                                                                                                                    && ethnicities.map((selected_item) => {
                                                                                                                        return (
                                                                                                                            {
                                                                                                                                label: options.ethnicities.filter(item => item.value == selected_item)[0].label,
                                                                                                                                value: selected_item
                                                                                                                            }
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                                isMulti
                                                                                                                options={options.ethnicities}
                                                                                                                isClearable={false}
                                                                                                                styles={styleSelectBasic} />
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>Household Income</td>
                                                                                                    <td>
                                                                                                        <div className="select-wrapper">
                                                                                                            <Select
                                                                                                                onChange={(event) => { setHasChanges(true); setHouseholdIncome(handleDropdownChange(event)) }}
                                                                                                                className="select-primary multi"
                                                                                                                defaultValue={[
                                                                                                                    Array.isArray(householdIncome) ?
                                                                                                                        householdIncome.map((selected_item) => {
                                                                                                                            return (
                                                                                                                                options.household_income.filter(item => item.value == selected_item).length > 0 ? {
                                                                                                                                    label: options.household_income.filter(item => item.value == selected_item)[0].label,
                                                                                                                                    value: selected_item
                                                                                                                                }
                                                                                                                                    : {

                                                                                                                                    }
                                                                                                                            )
                                                                                                                        })
                                                                                                                        : {
                                                                                                                            label: options.household_income.filter(item => item.value == getSelectedStudy("parental_status", selectedMarketStudy)).map(item => { return (item.label) }),
                                                                                                                            value: getSelectedStudy("parental_status", selectedMarketStudy)
                                                                                                                        }


                                                                                                                ]}
                                                                                                                value={
                                                                                                                    Array.isArray(householdIncome)
                                                                                                                    && householdIncome.map((selected_item) => {
                                                                                                                        return (
                                                                                                                            {
                                                                                                                                label: options.household_income.filter(item => item.value == selected_item)[0].label,
                                                                                                                                value: selected_item
                                                                                                                            }
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                                isMulti
                                                                                                                options={options.household_income}
                                                                                                                isClearable={false}
                                                                                                                styles={styleSelectBasic} />
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>Occupation Category</td>
                                                                                                    <td>
                                                                                                        <div className="select-wrapper">
                                                                                                            <Select
                                                                                                                onChange={(event) => { setHasChanges(true); setOccupation(handleDropdownChange(event)) }}
                                                                                                                className="select-primary multi"
                                                                                                                defaultValue={[
                                                                                                                    Array.isArray(occupation) ?
                                                                                                                        occupation.map((selected_item) => {
                                                                                                                            return (
                                                                                                                                options.occupation_category.filter(item => item.value == selected_item).length > 0 ? {
                                                                                                                                    label: options.occupation_category.filter(item => item.value == selected_item)[0].label,
                                                                                                                                    value: selected_item
                                                                                                                                }
                                                                                                                                    : {

                                                                                                                                    }
                                                                                                                            )
                                                                                                                        })
                                                                                                                        : {
                                                                                                                            label: options.occupation_category.filter(item => item.value == getSelectedStudy("parental_status", selectedMarketStudy)).map(item => { return (item.label) }),
                                                                                                                            value: getSelectedStudy("parental_status", selectedMarketStudy)
                                                                                                                        }


                                                                                                                ]}
                                                                                                                value={
                                                                                                                    Array.isArray(occupation)
                                                                                                                    && occupation.map((selected_item) => {
                                                                                                                        return (
                                                                                                                            {
                                                                                                                                label: options.occupation_category.filter(item => item.value == selected_item)[0].label,
                                                                                                                                value: selected_item
                                                                                                                            }
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                                isMulti
                                                                                                                menuPlacement="top"
                                                                                                                options={options.occupation_category}
                                                                                                                isClearable={false}
                                                                                                                styles={styleSelectBasic} />
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>Geo Country</td>
                                                                                                    <td>
                                                                                                        <div className="select-wrapper">
                                                                                                            <Select
                                                                                                                onChange={(event) => { setHasChanges(true); setGeo(handleDropdownChange(event)) }}
                                                                                                                className="select-primary multi"
                                                                                                                defaultValue={[
                                                                                                                    Array.isArray(geo) ?
                                                                                                                        geo.map((selected_item) => {
                                                                                                                            return (
                                                                                                                                options.geo_country.filter(item => item.value == selected_item).length > 0 ? {
                                                                                                                                    label: options.geo_country.filter(item => item.value == selected_item)[0].label,
                                                                                                                                    value: selected_item
                                                                                                                                }
                                                                                                                                    : {

                                                                                                                                    }
                                                                                                                            )
                                                                                                                        })
                                                                                                                        : {
                                                                                                                            label: options.geo_country.filter(item => item.value == getSelectedStudy("parental_status", selectedMarketStudy)).map(item => { return (item.label) }),
                                                                                                                            value: getSelectedStudy("parental_status", selectedMarketStudy)
                                                                                                                        }


                                                                                                                ]}
                                                                                                                value={
                                                                                                                    Array.isArray(geo)
                                                                                                                    && geo.map((selected_item) => {
                                                                                                                        return (
                                                                                                                            {
                                                                                                                                label: options.geo_country.filter(item => item.value == selected_item)[0].label,
                                                                                                                                value: selected_item
                                                                                                                            }
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                                isMulti
                                                                                                                menuPlacement="top"
                                                                                                                options={options.geo_country}
                                                                                                                isClearable={false}
                                                                                                                styles={styleSelectBasic} />
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                {
                                                                                                    marketResearch.items && marketResearch.items.filter((value, index, self) => index === self.findIndex((t) => (t.title == title && t.client == clientName && t.brand == brandName))).map(marketResearch =>
                                                                                                        marketResearch.customScreeners && marketResearch.customScreeners.map((customScreener, index) =>
                                                                                                            <tr key={index}>
                                                                                                                <td>{customScreener.question}</td>
                                                                                                                <td>
                                                                                                                    <div className="select-wrapper">
                                                                                                                        <Select
                                                                                                                            onChange={(event) => { setHasChanges(true); updateCustomScreener(index, customScreener.question, handleDropdownChange(event), customScreener.options) }}
                                                                                                                            className="select-primary multi"
                                                                                                                            defaultValue={customScreener.answer.map(item => {
                                                                                                                                return (
                                                                                                                                    { "value": item, "label": item }
                                                                                                                                )
                                                                                                                            })}
                                                                                                                            // value={
                                                                                                                            // }
                                                                                                                            isMulti
                                                                                                                            menuPlacement="top"
                                                                                                                            options={
                                                                                                                                customScreener.options.map(item => {
                                                                                                                                    return (
                                                                                                                                        { "value": item, "label": item }
                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }
                                                                                                                            isClearable={false}
                                                                                                                            styles={styleSelectBasic} />
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        )
                                                                                                    )
                                                                                                }
                                                                                                <tr>
                                                                                                    <td>{additionalScreenerOption.length > 0 && "Additional Questions"}</td>
                                                                                                    <td>
                                                                                                        <div className="additional-wrapper">
                                                                                                            {
                                                                                                                additionalScreenerOption.map((item, index) =>
                                                                                                                    <section key={index}>
                                                                                                                        <div className='additional-cancel' onClick={(e) => {
                                                                                                                            let newArr = [...additionalScreenerOption];
                                                                                                                            newArr.splice(index, 1)
                                                                                                                            setAdditionalScreenerOption(newArr)
                                                                                                                        }}><i className="fa fa-times" aria-hidden="true"></i></div>
                                                                                                                        <div key={index} className=''>
                                                                                                                            <label>Question:</label>
                                                                                                                            <input className='additional-input' type="text" value={item.question || ""} onChange={(e) => {
                                                                                                                                let newArr = [...additionalScreenerOption];
                                                                                                                                newArr[index].question = e.target.value
                                                                                                                                setAdditionalScreenerOption(newArr)
                                                                                                                            }} />
                                                                                                                            <label>Options <span>(Type something and press enter)</span>:</label>
                                                                                                                            <CreatableSelect
                                                                                                                                components={{ DropdownIndicator: null }}
                                                                                                                                inputValue={additionalScreenerOption[index].inputValue}
                                                                                                                                className="creatable-field"
                                                                                                                                isClearable
                                                                                                                                isMulti
                                                                                                                                menuIsOpen={false}
                                                                                                                                onInputChange={(inputValue) => {
                                                                                                                                    let newArr = [...additionalScreenerOption];
                                                                                                                                    newArr[index].inputValue = inputValue
                                                                                                                                    setAdditionalScreenerOption(newArr)
                                                                                                                                }}
                                                                                                                                onChange={(value) => {
                                                                                                                                    let newArr = [...additionalScreenerOption];
                                                                                                                                    newArr[index].options = value
                                                                                                                                    setAdditionalScreenerOption(newArr)
                                                                                                                                }}
                                                                                                                                onKeyDown={(event) => {
                                                                                                                                    switch (event.key) {
                                                                                                                                        case 'Enter':
                                                                                                                                        case 'Tab':
                                                                                                                                            let newArr = [...additionalScreenerOption];
                                                                                                                                            newArr[index].options = [...newArr[index].options, createOption(newArr[index].inputValue)]
                                                                                                                                            newArr[index].inputValue = ""
                                                                                                                                            setAdditionalScreenerOption(newArr)
                                                                                                                                            event.preventDefault();
                                                                                                                                    }
                                                                                                                                }}
                                                                                                                                placeholder=""
                                                                                                                                value={additionalScreenerOption[index].options}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </section>
                                                                                                                )
                                                                                                            }
                                                                                                            <button onClick={() => {
                                                                                                                setHasChanges(true); setAdditionalScreenerOption(prevArray => [...prevArray, {
                                                                                                                    question: "",
                                                                                                                    options: [],
                                                                                                                    answer: [],
                                                                                                                    inputValue: "",
                                                                                                                }])
                                                                                                            }} className="btn btn-primary btn-sm btn-actions"><i className="fa fa-plus-circle" aria-hidden="true"></i> Add More Question</button>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                    : currentStep == "3a" ?
                                                                                        <>
                                                                                            <div className="header-title">Set 1</div>
                                                                                            <div className="option-group mt-0">
                                                                                                <table className="question">
                                                                                                    <tr>
                                                                                                        <td>Marketing Funnel</td>
                                                                                                        <td>
                                                                                                            <Select
                                                                                                                onChange={(event) => { setHasChanges(true); setAudienceSegment1MarketingFunnel(handleDropdownChange(event)) }}
                                                                                                                className="select-primary mb-4"
                                                                                                                placeholder={audienceSegment1MarketingFunnel}
                                                                                                                value={"audienceSegment1MarketingFunnel"}
                                                                                                                options={[
                                                                                                                    { label: "Awareness", value: "Awareness" },
                                                                                                                    { label: "Consideration", value: "Consideration" },
                                                                                                                    { label: "Purchase", value: "Purchase" },
                                                                                                                    { label: "Loyalty", value: "Loyalty" },
                                                                                                                    { label: "Advocacy", value: "Advocacy" },
                                                                                                                ]}
                                                                                                                isClearable={false}
                                                                                                                styles={styleSelectBasic} />
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td><b>Prompt</b></td>
                                                                                                        <td><input type='text' value={audienceSegment1Prompt} onChange={(e) => { setHasChanges(true); setAudienceSegment1Prompt(e.target.value) }} /></td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Feature statement 1</td>
                                                                                                        <td><input type='text' value={audienceSegment1Question1} onChange={(e) => { setHasChanges(true); setAudienceSegment1Question1(e.target.value) }} /></td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Feature statement 2</td>
                                                                                                        <td><input type='text' value={audienceSegment1Question2} onChange={(e) => { setHasChanges(true); setAudienceSegment1Question2(e.target.value) }} /></td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Feature statement 3</td>
                                                                                                        <td><input type='text' value={audienceSegment1Question3} onChange={(e) => { setHasChanges(true); setAudienceSegment1Question3(e.target.value) }} /></td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Feature statement 4</td>
                                                                                                        <td><input type='text' value={audienceSegment1Question4} onChange={(e) => { setHasChanges(true); setAudienceSegment1Question4(e.target.value) }} /></td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Feature statement 5</td>
                                                                                                        <td><input type='text' value={audienceSegment1Question5} onChange={(e) => { setHasChanges(true); setAudienceSegment1Question5(e.target.value) }} /></td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Feature statement 6</td>
                                                                                                        <td><input type='text' value={audienceSegment1Question6} onChange={(e) => { setHasChanges(true); setAudienceSegment1Question6(e.target.value) }} /></td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Feature statement 7</td>
                                                                                                        <td><input type='text' value={audienceSegment1Question7} onChange={(e) => { setHasChanges(true); setAudienceSegment1Question7(e.target.value) }} /></td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Feature statement 8</td>
                                                                                                        <td><input type='text' value={audienceSegment1Question8} onChange={(e) => { setHasChanges(true); setAudienceSegment1Question8(e.target.value) }} /></td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Feature statement 9</td>
                                                                                                        <td><input type='text' value={audienceSegment1Question9} onChange={(e) => { setHasChanges(true); setAudienceSegment1Question9(e.target.value) }} /></td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className='text-left' colSpan="2"><hr></hr>Switching Statement:</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Feature statement 10</td>
                                                                                                        <td><input type='text' value={audienceSegment1Question10} onChange={(e) => { setHasChanges(true); setAudienceSegment1Question10(e.target.value) }} /></td>
                                                                                                    </tr>
                                                                                                </table>
                                                                                            </div>
                                                                                        </>
                                                                                        : currentStep == "3b" ?
                                                                                            <>
                                                                                                <div className="header-title">Set 2</div>
                                                                                                <div className="option-group mt-0">
                                                                                                    <table className="question">
                                                                                                        <tr>
                                                                                                            <td>Marketing Funnel</td>
                                                                                                            <td>
                                                                                                                <Select
                                                                                                                    onChange={(event) => { setHasChanges(true); setAudienceSegment2MarketingFunnel(handleDropdownChange(event)) }}
                                                                                                                    className="select-primary mb-4"
                                                                                                                    placeholder={audienceSegment2MarketingFunnel}
                                                                                                                    value={audienceSegment2MarketingFunnel}
                                                                                                                    options={[
                                                                                                                        { label: "Awareness", value: "Awareness" },
                                                                                                                        { label: "Consideration", value: "Consideration" },
                                                                                                                        { label: "Purchase", value: "Purchase" },
                                                                                                                        { label: "Loyalty", value: "Loyalty" },
                                                                                                                        { label: "Advocacy", value: "Advocacy" },
                                                                                                                    ]}
                                                                                                                    isClearable={false}
                                                                                                                    styles={styleSelectBasic} />
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td><b>Prompt</b></td>
                                                                                                            <td><input type='text' value={audienceSegment2Prompt} onChange={(e) => { setHasChanges(true); setAudienceSegment2Prompt(e.target.value) }} /></td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td>Feature statement 1</td>
                                                                                                            <td><input type='text' value={audienceSegment2Question1} onChange={(e) => { setHasChanges(true); setAudienceSegment2Question1(e.target.value) }} /></td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td>Feature statement 2</td>
                                                                                                            <td><input type='text' value={audienceSegment2Question2} onChange={(e) => { setHasChanges(true); setAudienceSegment2Question2(e.target.value) }} /></td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td>Feature statement 3</td>
                                                                                                            <td><input type='text' value={audienceSegment2Question3} onChange={(e) => { setHasChanges(true); setAudienceSegment2Question3(e.target.value) }} /></td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td>Feature statement 4</td>
                                                                                                            <td><input type='text' value={audienceSegment2Question4} onChange={(e) => { setHasChanges(true); setAudienceSegment2Question4(e.target.value) }} /></td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td>Feature statement 5</td>
                                                                                                            <td><input type='text' value={audienceSegment2Question5} onChange={(e) => { setHasChanges(true); setAudienceSegment2Question5(e.target.value) }} /></td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td>Feature statement 6</td>
                                                                                                            <td><input type='text' value={audienceSegment2Question6} onChange={(e) => { setHasChanges(true); setAudienceSegment2Question6(e.target.value) }} /></td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td>Feature statement 7</td>
                                                                                                            <td><input type='text' value={audienceSegment2Question7} onChange={(e) => { setHasChanges(true); setAudienceSegment2Question7(e.target.value) }} /></td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td>Feature statement 8</td>
                                                                                                            <td><input type='text' value={audienceSegment2Question8} onChange={(e) => { setHasChanges(true); setAudienceSegment2Question8(e.target.value) }} /></td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td>Feature statement 9</td>
                                                                                                            <td><input type='text' value={audienceSegment2Question9} onChange={(e) => { setHasChanges(true); setAudienceSegment2Question9(e.target.value) }} /></td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td className='text-left' colSpan="2"><hr></hr>Switching Statement:</td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td>Feature statement 10</td>
                                                                                                            <td><input type='text' value={audienceSegment2Question10} onChange={(e) => { setHasChanges(true); setAudienceSegment2Question10(e.target.value) }} /></td>
                                                                                                        </tr>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </>
                                                                                            : currentStep == "3c" ?
                                                                                                <>
                                                                                                    <div className="header-title">Set 3</div>
                                                                                                    <div className="option-group mt-0">
                                                                                                        <table className="question">
                                                                                                            <tr>
                                                                                                                <td>Marketing Funnel</td>
                                                                                                                <td>
                                                                                                                    <Select
                                                                                                                        onChange={(event) => { setHasChanges(true); setAudienceSegment3MarketingFunnel(handleDropdownChange(event)) }}
                                                                                                                        className="select-primary mb-4"
                                                                                                                        placeholder={audienceSegment3MarketingFunnel}
                                                                                                                        value={audienceSegment3MarketingFunnel}
                                                                                                                        options={[
                                                                                                                            { label: "Awareness", value: "Awareness" },
                                                                                                                            { label: "Consideration", value: "Consideration" },
                                                                                                                            { label: "Purchase", value: "Purchase" },
                                                                                                                            { label: "Loyalty", value: "Loyalty" },
                                                                                                                            { label: "Advocacy", value: "Advocacy" },
                                                                                                                        ]}
                                                                                                                        isClearable={false}
                                                                                                                        styles={styleSelectBasic} />
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td><b>Prompt</b></td>
                                                                                                                <td><input type='text' value={audienceSegment3Prompt} onChange={(e) => { setHasChanges(true); setAudienceSegment3Prompt(e.target.value) }} /></td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>Feature statement 1</td>
                                                                                                                <td><input type='text' value={audienceSegment3Question1} onChange={(e) => { setHasChanges(true); setAudienceSegment3Question1(e.target.value) }} /></td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>Feature statement 2</td>
                                                                                                                <td><input type='text' value={audienceSegment3Question2} onChange={(e) => { setHasChanges(true); setAudienceSegment3Question2(e.target.value) }} /></td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>Feature statement 3</td>
                                                                                                                <td><input type='text' value={audienceSegment3Question3} onChange={(e) => { setHasChanges(true); setAudienceSegment3Question3(e.target.value) }} /></td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>Feature statement 4</td>
                                                                                                                <td><input type='text' value={audienceSegment3Question4} onChange={(e) => { setHasChanges(true); setAudienceSegment3Question4(e.target.value) }} /></td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>Feature statement 5</td>
                                                                                                                <td><input type='text' value={audienceSegment3Question5} onChange={(e) => { setHasChanges(true); setAudienceSegment3Question5(e.target.value) }} /></td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>Feature statement 6</td>
                                                                                                                <td><input type='text' value={audienceSegment3Question6} onChange={(e) => { setHasChanges(true); setAudienceSegment3Question6(e.target.value) }} /></td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>Feature statement 7</td>
                                                                                                                <td><input type='text' value={audienceSegment3Question7} onChange={(e) => { setHasChanges(true); setAudienceSegment3Question7(e.target.value) }} /></td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>Feature statement 8</td>
                                                                                                                <td><input type='text' value={audienceSegment3Question8} onChange={(e) => { setHasChanges(true); setAudienceSegment3Question8(e.target.value) }} /></td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>Feature statement 9</td>
                                                                                                                <td><input type='text' value={audienceSegment3Question9} onChange={(e) => { setHasChanges(true); setAudienceSegment3Question9(e.target.value) }} /></td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td className='text-left' colSpan="2"><hr></hr>Switching Statement:</td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>Feature statement 10</td>
                                                                                                                <td><input type='text' value={audienceSegment3Question10} onChange={(e) => { setHasChanges(true); setAudienceSegment3Question10(e.target.value) }} /></td>
                                                                                                            </tr>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                </>
                                                                                                : currentStep == "3d" ?
                                                                                                    <>
                                                                                                        <div className="header-title">Set 4</div>
                                                                                                        <div className="option-group mt-0">
                                                                                                            <table className="question">
                                                                                                                <tr>
                                                                                                                    <td>Marketing Funnel</td>
                                                                                                                    <td>
                                                                                                                        <Select
                                                                                                                            onChange={(event) => { setHasChanges(true); setAudienceSegment4MarketingFunnel(handleDropdownChange(event)) }}
                                                                                                                            className="select-primary mb-4"
                                                                                                                            placeholder={audienceSegment4MarketingFunnel}
                                                                                                                            value={audienceSegment4MarketingFunnel}
                                                                                                                            options={[
                                                                                                                                { label: "Awareness", value: "Awareness" },
                                                                                                                                { label: "Consideration", value: "Consideration" },
                                                                                                                                { label: "Purchase", value: "Purchase" },
                                                                                                                                { label: "Loyalty", value: "Loyalty" },
                                                                                                                                { label: "Advocacy", value: "Advocacy" },
                                                                                                                            ]}
                                                                                                                            isClearable={false}
                                                                                                                            styles={styleSelectBasic} />
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td><b>Prompt</b></td>
                                                                                                                    <td><input type='text' value={audienceSegment4Prompt} onChange={(e) => { setHasChanges(true); setAudienceSegment4Prompt(e.target.value) }} /></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Feature statement 1</td>
                                                                                                                    <td><input type='text' value={audienceSegment4Question1} onChange={(e) => { setHasChanges(true); setAudienceSegment4Question1(e.target.value) }} /></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Feature statement 2</td>
                                                                                                                    <td><input type='text' value={audienceSegment4Question2} onChange={(e) => { setHasChanges(true); setAudienceSegment4Question2(e.target.value) }} /></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Feature statement 3</td>
                                                                                                                    <td><input type='text' value={audienceSegment4Question3} onChange={(e) => { setHasChanges(true); setAudienceSegment4Question3(e.target.value) }} /></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Feature statement 4</td>
                                                                                                                    <td><input type='text' value={audienceSegment4Question4} onChange={(e) => { setHasChanges(true); setAudienceSegment4Question4(e.target.value) }} /></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Feature statement 5</td>
                                                                                                                    <td><input type='text' value={audienceSegment4Question5} onChange={(e) => { setHasChanges(true); setAudienceSegment4Question5(e.target.value) }} /></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Feature statement 6</td>
                                                                                                                    <td><input type='text' value={audienceSegment4Question6} onChange={(e) => { setHasChanges(true); setAudienceSegment4Question6(e.target.value) }} /></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Feature statement 7</td>
                                                                                                                    <td><input type='text' value={audienceSegment4Question7} onChange={(e) => { setHasChanges(true); setAudienceSegment4Question7(e.target.value) }} /></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Feature statement 8</td>
                                                                                                                    <td><input type='text' value={audienceSegment4Question8} onChange={(e) => { setHasChanges(true); setAudienceSegment4Question8(e.target.value) }} /></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Feature statement 9</td>
                                                                                                                    <td><input type='text' value={audienceSegment4Question9} onChange={(e) => { setHasChanges(true); setAudienceSegment4Question9(e.target.value) }} /></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td className='text-left' colSpan="2"><hr></hr>Switching Statement:</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Feature statement 10</td>
                                                                                                                    <td><input type='text' value={audienceSegment4Question10} onChange={(e) => { setHasChanges(true); setAudienceSegment4Question10(e.target.value) }} /></td>
                                                                                                                </tr>
                                                                                                            </table>
                                                                                                        </div>
                                                                                                    </>
                                                                                                    : currentStep == "4a" ?
                                                                                                        <>
                                                                                                            <div className="header-title">{label4A}</div>
                                                                                                            <div className="option-group mt-0">
                                                                                                                <table className="hypothesis">
                                                                                                                    <tr>
                                                                                                                        <td>Marketing Funnel</td>
                                                                                                                        <td>
                                                                                                                            <Select
                                                                                                                                onChange={(event) => { setHasChanges(true); setBadge4A(handleDropdownChange(event)) }}
                                                                                                                                className="select-primary mb-4"
                                                                                                                                placeholder={badge4A}
                                                                                                                                value={badge4A}
                                                                                                                                options={[
                                                                                                                                    { label: "Awareness", value: "Awareness" },
                                                                                                                                    { label: "Consideration", value: "Consideration" },
                                                                                                                                    { label: "Purchase", value: "Purchase" },
                                                                                                                                    { label: "Loyalty", value: "Loyalty" },
                                                                                                                                    { label: "Advocacy", value: "Advocacy" },
                                                                                                                                ]}
                                                                                                                                isClearable={false}
                                                                                                                                styles={styleSelectBasic} />
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <td>What type of language do you want to test?</td>
                                                                                                                        <td>
                                                                                                                            <div className="select-wrapper">
                                                                                                                                <Select
                                                                                                                                    onChange={(event) => { setHasChanges(true); setLabel4A(handleDropdownChange(event)) }}
                                                                                                                                    className="select-primary"
                                                                                                                                    placeholder={label4A}
                                                                                                                                    value={label4A}
                                                                                                                                    options={[
                                                                                                                                        { label: "Hypotheses", value: "Hypotheses" },
                                                                                                                                        { label: "Value Propositions", value: "Value Proposition" },
                                                                                                                                        { label: "Naming", value: "Naming" },
                                                                                                                                        { label: "Creative Concept", value: "Creative Concept" },
                                                                                                                                        { label: "Master Brand Language", value: "Master Brand Language" },
                                                                                                                                    ]}
                                                                                                                                    isClearable={false}
                                                                                                                                    styles={styleSelectBasic} />
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <td>Title</td>
                                                                                                                        <td><input type='text' value={hypothesisTitle1} onChange={(e) => { setHasChanges(true); setHypothesisTitle1(e.target.value) }} /></td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <td>{label4A} 1</td>
                                                                                                                        <td><input type='text' value={hypotheses1} onChange={(e) => { setHasChanges(true); setHypotheses1(e.target.value) }} /></td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <td>{label4A} 2</td>
                                                                                                                        <td><input type='text' value={hypotheses2} onChange={(e) => { setHasChanges(true); setHypotheses2(e.target.value) }} /></td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <td>{label4A} 3</td>
                                                                                                                        <td><input type='text' value={hypotheses3} onChange={(e) => { setHasChanges(true); setHypotheses3(e.target.value) }} /></td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <td>{label4A} 4</td>
                                                                                                                        <td><input type='text' value={hypotheses4} onChange={(e) => { setHasChanges(true); setHypotheses4(e.target.value) }} /></td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <td>{label4A} 5</td>
                                                                                                                        <td><input type='text' value={hypotheses5} onChange={(e) => { setHasChanges(true); setHypotheses5(e.target.value) }} /></td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <td>{label4A} 6</td>
                                                                                                                        <td><input type='text' value={hypotheses6} onChange={(e) => { setHasChanges(true); setHypotheses6(e.target.value) }} /></td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <td>{label4A} 7</td>
                                                                                                                        <td><input type='text' value={hypotheses7} onChange={(e) => { setHasChanges(true); setHypotheses7(e.target.value) }} /></td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <td>{label4A} 8</td>
                                                                                                                        <td><input type='text' value={hypotheses8} onChange={(e) => { setHasChanges(true); setHypotheses8(e.target.value) }} /></td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <td>{label4A} 9</td>
                                                                                                                        <td><input type='text' value={hypotheses9} onChange={(e) => { setHasChanges(true); setHypotheses9(e.target.value) }} /></td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <td>{label4A} 10</td>
                                                                                                                        <td><input type='text' value={hypotheses10} onChange={(e) => { setHasChanges(true); setHypotheses10(e.target.value) }} /></td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <td>{label4A} 11</td>
                                                                                                                        <td><input type='text' value={hypotheses11} onChange={(e) => { setHasChanges(true); setHypotheses11(e.target.value) }} /></td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <td>{label4A} 12</td>
                                                                                                                        <td><input type='text' value={hypotheses12} onChange={(e) => { setHasChanges(true); setHypotheses12(e.target.value) }} /></td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <td>{label4A} 13</td>
                                                                                                                        <td><input type='text' value={hypotheses13} onChange={(e) => { setHasChanges(true); setHypotheses13(e.target.value) }} /></td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <td>{label4A} 14</td>
                                                                                                                        <td><input type='text' value={hypotheses14} onChange={(e) => { setHasChanges(true); setHypotheses14(e.target.value) }} /></td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <td>{label4A} 15</td>
                                                                                                                        <td><input type='text' value={hypotheses15} onChange={(e) => { setHasChanges(true); setHypotheses15(e.target.value) }} /></td>
                                                                                                                    </tr>
                                                                                                                </table>
                                                                                                            </div>
                                                                                                        </>
                                                                                                        : currentStep == "4b" ?
                                                                                                            <>
                                                                                                                <div className="header-title">{label4B}</div>
                                                                                                                <div className="option-group mt-0">
                                                                                                                    <table className="hypothesis">
                                                                                                                        <tr>
                                                                                                                            <td>Marketing Funnel</td>
                                                                                                                            <td>
                                                                                                                                <Select
                                                                                                                                    onChange={(event) => { setHasChanges(true); setBadge4B(handleDropdownChange(event)) }}
                                                                                                                                    className="select-primary mb-4"
                                                                                                                                    placeholder={badge4B}
                                                                                                                                    value={badge4B}
                                                                                                                                    options={[
                                                                                                                                        { label: "Awareness", value: "Awareness" },
                                                                                                                                        { label: "Consideration", value: "Consideration" },
                                                                                                                                        { label: "Purchase", value: "Purchase" },
                                                                                                                                        { label: "Loyalty", value: "Loyalty" },
                                                                                                                                        { label: "Advocacy", value: "Advocacy" },
                                                                                                                                    ]}
                                                                                                                                    isClearable={false}
                                                                                                                                    styles={styleSelectBasic} />
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>What type of language do you want to test?</td>
                                                                                                                            <td>
                                                                                                                                <div className="select-wrapper">
                                                                                                                                    <Select
                                                                                                                                        onChange={(event) => { setHasChanges(true); setLabel4B(handleDropdownChange(event)) }}
                                                                                                                                        className="select-primary"
                                                                                                                                        placeholder={label4B}
                                                                                                                                        value={label4B}
                                                                                                                                        options={[
                                                                                                                                            { label: "Hypotheses", value: "Hypotheses" },
                                                                                                                                            { label: "Value Propositions", value: "Value Proposition" },
                                                                                                                                            { label: "Naming", value: "Naming" },
                                                                                                                                            { label: "Creative Concept", value: "Creative Concept" },
                                                                                                                                            { label: "Master Brand Language", value: "Master Brand Language" },
                                                                                                                                        ]}
                                                                                                                                        isClearable={false}
                                                                                                                                        styles={styleSelectBasic} />
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>Title</td>
                                                                                                                            <td><input type='text' value={hypothesisTitle2} onChange={(e) => { setHasChanges(true); setHypothesisTitle2(e.target.value) }} /></td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>{label4B} 1</td>
                                                                                                                            <td><input type='text' value={proposition1} onChange={(e) => { setHasChanges(true); setProposition1(e.target.value) }} /></td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>{label4B} 2</td>
                                                                                                                            <td><input type='text' value={proposition2} onChange={(e) => { setHasChanges(true); setProposition2(e.target.value) }} /></td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>{label4B} 3</td>
                                                                                                                            <td><input type='text' value={proposition3} onChange={(e) => { setHasChanges(true); setProposition3(e.target.value) }} /></td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>{label4B} 4</td>
                                                                                                                            <td><input type='text' value={proposition4} onChange={(e) => { setHasChanges(true); setProposition4(e.target.value) }} /></td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>{label4B} 5</td>
                                                                                                                            <td><input type='text' value={proposition5} onChange={(e) => { setHasChanges(true); setProposition5(e.target.value) }} /></td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>{label4B} 6</td>
                                                                                                                            <td><input type='text' value={proposition6} onChange={(e) => { setHasChanges(true); setProposition6(e.target.value) }} /></td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>{label4B} 7</td>
                                                                                                                            <td><input type='text' value={proposition7} onChange={(e) => { setHasChanges(true); setProposition7(e.target.value) }} /></td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>{label4B} 8</td>
                                                                                                                            <td><input type='text' value={proposition8} onChange={(e) => { setHasChanges(true); setProposition8(e.target.value) }} /></td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>{label4B} 9</td>
                                                                                                                            <td><input type='text' value={proposition9} onChange={(e) => { setHasChanges(true); setProposition9(e.target.value) }} /></td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>{label4B} 10</td>
                                                                                                                            <td><input type='text' value={proposition10} onChange={(e) => { setHasChanges(true); setProposition10(e.target.value) }} /></td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>{label4B} 11</td>
                                                                                                                            <td><input type='text' value={proposition11} onChange={(e) => { setHasChanges(true); setProposition11(e.target.value) }} /></td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>{label4B} 12</td>
                                                                                                                            <td><input type='text' value={proposition12} onChange={(e) => { setHasChanges(true); setProposition12(e.target.value) }} /></td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>{label4B} 13</td>
                                                                                                                            <td><input type='text' value={proposition13} onChange={(e) => { setHasChanges(true); setProposition13(e.target.value) }} /></td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>{label4B} 14</td>
                                                                                                                            <td><input type='text' value={proposition14} onChange={(e) => { setHasChanges(true); setProposition14(e.target.value) }} /></td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>{label4B} 15</td>
                                                                                                                            <td><input type='text' value={proposition15} onChange={(e) => { setHasChanges(true); setProposition15(e.target.value) }} /></td>
                                                                                                                        </tr>
                                                                                                                    </table>
                                                                                                                </div>
                                                                                                            </>
                                                                                                            : currentStep == "4c" ?
                                                                                                                <>
                                                                                                                    <div className="header-title">{label4C}</div>
                                                                                                                    <div className="option-group mt-0">
                                                                                                                        <table className="hypothesis">
                                                                                                                            <tr>
                                                                                                                                <td>Marketing Funnel</td>
                                                                                                                                <td>
                                                                                                                                    <Select
                                                                                                                                        onChange={(event) => { setHasChanges(true); setBadge4C(handleDropdownChange(event)) }}
                                                                                                                                        className="select-primary mb-4"
                                                                                                                                        placeholder={badge4C}
                                                                                                                                        value={badge4C}
                                                                                                                                        options={[
                                                                                                                                            { label: "Awareness", value: "Awareness" },
                                                                                                                                            { label: "Consideration", value: "Consideration" },
                                                                                                                                            { label: "Purchase", value: "Purchase" },
                                                                                                                                            { label: "Loyalty", value: "Loyalty" },
                                                                                                                                            { label: "Advocacy", value: "Advocacy" },
                                                                                                                                        ]}
                                                                                                                                        isClearable={false}
                                                                                                                                        styles={styleSelectBasic} />
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <td>What type of language do you want to test?</td>
                                                                                                                                <td>
                                                                                                                                    <div className="select-wrapper">
                                                                                                                                        <Select
                                                                                                                                            onChange={(event) => { setHasChanges(true); setLabel4C(handleDropdownChange(event)) }}
                                                                                                                                            className="select-primary"
                                                                                                                                            placeholder={label4C}
                                                                                                                                            value={label4C}
                                                                                                                                            options={[
                                                                                                                                                { label: "Hypotheses", value: "Hypotheses" },
                                                                                                                                                { label: "Value Propositions", value: "Value Proposition" },
                                                                                                                                                { label: "Naming", value: "Naming" },
                                                                                                                                                { label: "Creative Concept", value: "Creative Concept" },
                                                                                                                                                { label: "Master Brand Language", value: "Master Brand Language" },
                                                                                                                                            ]}
                                                                                                                                            isClearable={false}
                                                                                                                                            styles={styleSelectBasic} />
                                                                                                                                    </div>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <td>Title</td>
                                                                                                                                <td><input type='text' value={hypothesisTitle3} onChange={(e) => { setHasChanges(true); setHypothesisTitle3(e.target.value) }} /></td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <td>{label4C} 1</td>
                                                                                                                                <td><input type='text' value={emotionalC1} onChange={(e) => { setHasChanges(true); setEmotionalC1(e.target.value) }} /></td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <td>{label4C} 2</td>
                                                                                                                                <td><input type='text' value={emotionalC2} onChange={(e) => { setHasChanges(true); setEmotionalC2(e.target.value) }} /></td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <td>{label4C} 3</td>
                                                                                                                                <td><input type='text' value={emotionalC3} onChange={(e) => { setHasChanges(true); setEmotionalC3(e.target.value) }} /></td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <td>{label4C} 4</td>
                                                                                                                                <td><input type='text' value={emotionalC4} onChange={(e) => { setHasChanges(true); setEmotionalC4(e.target.value) }} /></td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <td>{label4C} 5</td>
                                                                                                                                <td><input type='text' value={emotionalC5} onChange={(e) => { setHasChanges(true); setEmotionalC5(e.target.value) }} /></td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <td>{label4C} 6</td>
                                                                                                                                <td><input type='text' value={emotionalC6} onChange={(e) => { setHasChanges(true); setEmotionalC6(e.target.value) }} /></td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <td>{label4C} 7</td>
                                                                                                                                <td><input type='text' value={emotionalC7} onChange={(e) => { setHasChanges(true); setEmotionalC7(e.target.value) }} /></td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <td>{label4C} 8</td>
                                                                                                                                <td><input type='text' value={emotionalC8} onChange={(e) => { setHasChanges(true); setEmotionalC8(e.target.value) }} /></td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <td>{label4C} 9</td>
                                                                                                                                <td><input type='text' value={emotionalC9} onChange={(e) => { setHasChanges(true); setEmotionalC9(e.target.value) }} /></td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <td>{label4C} 10</td>
                                                                                                                                <td><input type='text' value={emotionalC10} onChange={(e) => { setHasChanges(true); setEmotionalC10(e.target.value) }} /></td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <td>{label4C} 11</td>
                                                                                                                                <td><input type='text' value={emotionalC11} onChange={(e) => { setHasChanges(true); setEmotionalC11(e.target.value) }} /></td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <td>{label4C} 12</td>
                                                                                                                                <td><input type='text' value={emotionalC12} onChange={(e) => { setHasChanges(true); setEmotionalC12(e.target.value) }} /></td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <td>{label4C} 13</td>
                                                                                                                                <td><input type='text' value={emotionalC13} onChange={(e) => { setHasChanges(true); setEmotionalC13(e.target.value) }} /></td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <td>{label4C} 14</td>
                                                                                                                                <td><input type='text' value={emotionalC14} onChange={(e) => { setHasChanges(true); setEmotionalC14(e.target.value) }} /></td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <td>{label4C} 15</td>
                                                                                                                                <td><input type='text' value={emotionalC15} onChange={(e) => { setHasChanges(true); setEmotionalC15(e.target.value) }} /></td>
                                                                                                                            </tr>
                                                                                                                        </table>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                                : currentStep == "4d" ?
                                                                                                                    <>
                                                                                                                        <div className="header-title">{label4D}</div>
                                                                                                                        <div className="option-group mt-0">
                                                                                                                            <table className="hypothesis">
                                                                                                                                <tr>
                                                                                                                                    <td>Marketing Funnel</td>
                                                                                                                                    <td>
                                                                                                                                        <Select
                                                                                                                                            onChange={(event) => { setHasChanges(true); setBadge4D(handleDropdownChange(event)) }}
                                                                                                                                            className="select-primary mb-4"
                                                                                                                                            placeholder={badge4D}
                                                                                                                                            value={badge4D}
                                                                                                                                            options={[
                                                                                                                                                { label: "Awareness", value: "Awareness" },
                                                                                                                                                { label: "Consideration", value: "Consideration" },
                                                                                                                                                { label: "Purchase", value: "Purchase" },
                                                                                                                                                { label: "Loyalty", value: "Loyalty" },
                                                                                                                                                { label: "Advocacy", value: "Advocacy" },
                                                                                                                                            ]}
                                                                                                                                            isClearable={false}
                                                                                                                                            styles={styleSelectBasic} />
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>What type of language do you want to test?</td>
                                                                                                                                    <td>
                                                                                                                                        <div className="select-wrapper">
                                                                                                                                            <Select
                                                                                                                                                onChange={(event) => { setHasChanges(true); setLabel4D(handleDropdownChange(event)) }}
                                                                                                                                                className="select-primary"
                                                                                                                                                placeholder={label4D}
                                                                                                                                                value={label4D}
                                                                                                                                                options={[
                                                                                                                                                    { label: "Hypotheses", value: "Hypotheses" },
                                                                                                                                                    { label: "Value Propositions", value: "Value Proposition" },
                                                                                                                                                    { label: "Naming", value: "Naming" },
                                                                                                                                                    { label: "Creative Concept", value: "Creative Concept" },
                                                                                                                                                    { label: "Master Brand Language", value: "Master Brand Language" },
                                                                                                                                                ]}
                                                                                                                                                isClearable={false}
                                                                                                                                                styles={styleSelectBasic} />
                                                                                                                                        </div>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>Title</td>
                                                                                                                                    <td><input type='text' value={hypothesisTitle4} onChange={(e) => { setHasChanges(true); setHypothesisTitle4(e.target.value) }} /></td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>{label4D} 1</td>
                                                                                                                                    <td><input type='text' value={emotionalD1} onChange={(e) => { setHasChanges(true); setEmotionalD1(e.target.value) }} /></td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>{label4D} 2</td>
                                                                                                                                    <td><input type='text' value={emotionalD2} onChange={(e) => { setHasChanges(true); setEmotionalD2(e.target.value) }} /></td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>{label4D} 3</td>
                                                                                                                                    <td><input type='text' value={emotionalD3} onChange={(e) => { setHasChanges(true); setEmotionalD3(e.target.value) }} /></td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>{label4D} 4</td>
                                                                                                                                    <td><input type='text' value={emotionalD4} onChange={(e) => { setHasChanges(true); setEmotionalD4(e.target.value) }} /></td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>{label4D} 5</td>
                                                                                                                                    <td><input type='text' value={emotionalD5} onChange={(e) => { setHasChanges(true); setEmotionalD5(e.target.value) }} /></td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>{label4D} 6</td>
                                                                                                                                    <td><input type='text' value={emotionalD6} onChange={(e) => { setHasChanges(true); setEmotionalD6(e.target.value) }} /></td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>{label4D} 7</td>
                                                                                                                                    <td><input type='text' value={emotionalD7} onChange={(e) => { setHasChanges(true); setEmotionalD7(e.target.value) }} /></td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>{label4D} 8</td>
                                                                                                                                    <td><input type='text' value={emotionalD8} onChange={(e) => { setHasChanges(true); setEmotionalD8(e.target.value) }} /></td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>{label4D} 9</td>
                                                                                                                                    <td><input type='text' value={emotionalD9} onChange={(e) => { setHasChanges(true); setEmotionalD9(e.target.value) }} /></td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>{label4D} 10</td>
                                                                                                                                    <td><input type='text' value={emotionalD10} onChange={(e) => { setHasChanges(true); setEmotionalD10(e.target.value) }} /></td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>{label4D} 11</td>
                                                                                                                                    <td><input type='text' value={emotionalD11} onChange={(e) => { setHasChanges(true); setEmotionalD11(e.target.value) }} /></td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>{label4D} 12</td>
                                                                                                                                    <td><input type='text' value={emotionalD12} onChange={(e) => { setHasChanges(true); setEmotionalD12(e.target.value) }} /></td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>{label4D} 13</td>
                                                                                                                                    <td><input type='text' value={emotionalD13} onChange={(e) => { setHasChanges(true); setEmotionalD13(e.target.value) }} /></td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>{label4D} 14</td>
                                                                                                                                    <td><input type='text' value={emotionalD14} onChange={(e) => { setHasChanges(true); setEmotionalD14(e.target.value) }} /></td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>{label4D} 15</td>
                                                                                                                                    <td><input type='text' value={emotionalD15} onChange={(e) => { setHasChanges(true); setEmotionalD15(e.target.value) }} /></td>
                                                                                                                                </tr>
                                                                                                                            </table>
                                                                                                                        </div>
                                                                                                                    </>
                                                                                                                    : currentStep == "4e" ?
                                                                                                                        <>
                                                                                                                            <div className="header-title">{label4E}</div>
                                                                                                                            <div className="option-group mt-0">
                                                                                                                                <table className="hypothesis">
                                                                                                                                    <tr>
                                                                                                                                        <td>Marketing Funnel</td>
                                                                                                                                        <td>
                                                                                                                                            <Select
                                                                                                                                                onChange={(event) => { setHasChanges(true); setBadge4E(handleDropdownChange(event)) }}
                                                                                                                                                className="select-primary mb-4"
                                                                                                                                                placeholder={badge4E}
                                                                                                                                                value={badge4E}
                                                                                                                                                options={[
                                                                                                                                                    { label: "Awareness", value: "Awareness" },
                                                                                                                                                    { label: "Consideration", value: "Consideration" },
                                                                                                                                                    { label: "Purchase", value: "Purchase" },
                                                                                                                                                    { label: "Loyalty", value: "Loyalty" },
                                                                                                                                                    { label: "Advocacy", value: "Advocacy" },
                                                                                                                                                ]}
                                                                                                                                                isClearable={false}
                                                                                                                                                styles={styleSelectBasic} />
                                                                                                                                        </td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                        <td>What type of language do you want to test?</td>
                                                                                                                                        <td>
                                                                                                                                            <div className="select-wrapper">
                                                                                                                                                <Select
                                                                                                                                                    onChange={(event) => { setHasChanges(true); setLabel4E(handleDropdownChange(event)) }}
                                                                                                                                                    className="select-primary"
                                                                                                                                                    placeholder={label4E}
                                                                                                                                                    value={label4E}
                                                                                                                                                    options={[
                                                                                                                                                        { label: "Hypotheses", value: "Hypotheses" },
                                                                                                                                                        { label: "Value Propositions", value: "Value Proposition" },
                                                                                                                                                        { label: "Naming", value: "Naming" },
                                                                                                                                                        { label: "Creative Concept", value: "Creative Concept" },
                                                                                                                                                        { label: "Master Brand Language", value: "Master Brand Language" },
                                                                                                                                                    ]}
                                                                                                                                                    isClearable={false}
                                                                                                                                                    styles={styleSelectBasic} />
                                                                                                                                            </div>
                                                                                                                                        </td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                        <td>Title</td>
                                                                                                                                        <td><input type='text' value={hypothesisTitle5} onChange={(e) => { setHasChanges(true); setHypothesisTitle5(e.target.value) }} /></td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                        <td>{label4E} 1</td>
                                                                                                                                        <td><input type='text' value={emotionalE1} onChange={(e) => { setHasChanges(true); setEmotionalE1(e.target.value) }} /></td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                        <td>{label4E} 2</td>
                                                                                                                                        <td><input type='text' value={emotionalE2} onChange={(e) => { setHasChanges(true); setEmotionalE2(e.target.value) }} /></td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                        <td>{label4E} 3</td>
                                                                                                                                        <td><input type='text' value={emotionalE3} onChange={(e) => { setHasChanges(true); setEmotionalE3(e.target.value) }} /></td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                        <td>{label4E} 4</td>
                                                                                                                                        <td><input type='text' value={emotionalE4} onChange={(e) => { setHasChanges(true); setEmotionalE4(e.target.value) }} /></td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                        <td>{label4E} 5</td>
                                                                                                                                        <td><input type='text' value={emotionalE5} onChange={(e) => { setHasChanges(true); setEmotionalE5(e.target.value) }} /></td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                        <td>{label4E} 6</td>
                                                                                                                                        <td><input type='text' value={emotionalE6} onChange={(e) => { setHasChanges(true); setEmotionalE6(e.target.value) }} /></td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                        <td>{label4E} 7</td>
                                                                                                                                        <td><input type='text' value={emotionalE7} onChange={(e) => { setHasChanges(true); setEmotionalE7(e.target.value) }} /></td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                        <td>{label4E} 8</td>
                                                                                                                                        <td><input type='text' value={emotionalE8} onChange={(e) => { setHasChanges(true); setEmotionalE8(e.target.value) }} /></td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                        <td>{label4E} 9</td>
                                                                                                                                        <td><input type='text' value={emotionalE9} onChange={(e) => { setHasChanges(true); setEmotionalE9(e.target.value) }} /></td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                        <td>{label4E} 10</td>
                                                                                                                                        <td><input type='text' value={emotionalE10} onChange={(e) => { setHasChanges(true); setEmotionalE10(e.target.value) }} /></td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                        <td>{label4E} 11</td>
                                                                                                                                        <td><input type='text' value={emotionalE11} onChange={(e) => { setHasChanges(true); setEmotionalE11(e.target.value) }} /></td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                        <td>{label4E} 12</td>
                                                                                                                                        <td><input type='text' value={emotionalE12} onChange={(e) => { setHasChanges(true); setEmotionalE12(e.target.value) }} /></td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                        <td>{label4E} 13</td>
                                                                                                                                        <td><input type='text' value={emotionalE13} onChange={(e) => { setHasChanges(true); setEmotionalE13(e.target.value) }} /></td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                        <td>{label4E} 14</td>
                                                                                                                                        <td><input type='text' value={emotionalE14} onChange={(e) => { setHasChanges(true); setEmotionalE14(e.target.value) }} /></td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                        <td>{label4E} 15</td>
                                                                                                                                        <td><input type='text' value={emotionalE15} onChange={(e) => { setHasChanges(true); setEmotionalE15(e.target.value) }} /></td>
                                                                                                                                    </tr>
                                                                                                                                </table>
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                        : <></>
                                                                        }
                                                                        <div className="controls">
                                                                            {
                                                                                currentStep == "5" ?
                                                                                    <>
                                                                                        <button type="button" onClick={() => gotoPrevStep()} className={`${currentStep == "1" ? "inactive" : ""} btn btn-primary btn-sm btn-actions`}><i className="fa fa-caret-left" aria-hidden="true"></i> Prev</button>
                                                                                        <button type="button" className={`${currentStep == "1" ? "inactive" : ""} btn btn-primary btn-sm btn-actions`}><i className="fa fa-check" aria-hidden="true"></i> Submit</button>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <button type="button" onClick={() => gotoPrevStep()} className={`${currentStep == "1" ? "inactive" : ""} btn btn-primary btn-sm btn-actions`}><i className="fa fa-caret-left" aria-hidden="true"></i> Prev</button>
                                                                                        <button type="button" onClick={() => gotoNextStep()} className={`${currentStep == "5" ? "inactive" : ""} btn btn-primary btn-sm btn-actions`}>Next <i className="fa fa-caret-right" aria-hidden="true"></i></button>
                                                                                        {
                                                                                            isNewMarketStudy ?
                                                                                                <button type="button" onClick={() => create()} className={`${currentStep == "5" ? "inactive" : ""} btn btn-primary btn-sm btn-actions`}><i className="fa fa-check" aria-hidden="true"></i> Create</button>
                                                                                                :
                                                                                                <button type="button" onClick={() => saveChanges()} className={`${currentStep == "5" || !hasChanges || !isNewCustomScreenerValid() ? "inactive" : ""} btn btn-primary btn-sm btn-actions ${(label4A != "Creative Concept" && label4B != "Creative Concept" && label4C != "Creative Concept" && label4D != "Creative Concept" && label4E != "Creative Concept") ? "inactive disabled" : ""}`}><i className="fa fa-check" aria-hidden="true"></i> Save Changes {isNewCustomScreenerValid()}</button>

                                                                                        }
                                                                                    </>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    
                                                            </div>
                                                    }
                                            </Tab>

                                            {/* CREATIVE ANALYSIS TAB */}
                                            <Tab eventKey="CREATIVE_ANALYSIS" title="Creative Analysis">
                                                
                                                {
                                                    selectedClient == null ?
                                                    <div className="empty-text">Please choose a Client</div>
                                                    : selectedBrand == null ?
                                                    <div className="empty-text">Please choose a Brand</div>
                                                    : marketResearch.items && marketResearch.items.filter(item => item.brand == selectedBrand && item.title !== undefined).length == 0 ?
                                                    <div className="empty-text">No Results Found</div>
                                                    : isFormShown && (selectedMarketStudy != null) ?
                                                        <div className="creative-analysis-result">
                                                            <div className="report-navigation">
                                                                <OverlayTrigger placement="top" content="" overlay={<Tooltip>Back</Tooltip>}>
                                                                    <button className='creative-analysis-back' onClick={() => {
                                                                            clearMarketStudyForm();
                                                                            setSelectedMarketStudy(null); 
                                                                            showStudyForm(null); 
                                                                            setIsNewMarketStudy(false);
                                                                            setHasChanges(false);
                                                                            setIsFormShown(false);
                                                                        }}>
                                                                        <i class="fa fa-arrow-left" aria-hidden="true"></i> <span>Back</span>
                                                                    </button>
                                                                </OverlayTrigger>
                                                                {/* OULINE LINKS FOR CREATIVE ANALYSIS GOES HERE*/}
                                                                <div className={`link mb-4 ${activeCreativeAnalysis == "slide2" ? "active":""}`} onClick={() => setActiveCreativeAnalysis("slide2")}>Demographics</div>
                                                                <div className="section-title">Implicit association test/Implicit Reaction Time</div>
                                                                <div className={`link mb-4 ${activeCreativeAnalysis == "slide4" ? "active":""}`} onClick={() => setActiveCreativeAnalysis("slide4")}>Definition</div>
                                                                <div className="section-title">Ashley Benson VS J-LO</div>
                                                                <div className={`link mb-4 ${activeCreativeAnalysis == "slide6" ? "active":""}`} onClick={() => setActiveCreativeAnalysis("slide6")}>Differential</div>
                                                                <div className={`link mb-4 ${activeCreativeAnalysis == "slide7" ? "active":""}`} onClick={() => setActiveCreativeAnalysis("slide7")}>All Participants</div>
                                                                <div className={`link mb-4 ${activeCreativeAnalysis == "slide8" ? "active":""}`} onClick={() => setActiveCreativeAnalysis("slide8")}>Age-segmented Participants</div>
                                                                <div className="section-title">Ashley Benson VS Jessica Simpson</div>
                                                                <div className={`link mb-4 ${activeCreativeAnalysis == "slide9" ? "active":""}`} onClick={() => setActiveCreativeAnalysis("slide9")}>Differential</div>
                                                                <div className={`link mb-4 ${activeCreativeAnalysis == "slide10" ? "active":""}`} onClick={() => setActiveCreativeAnalysis("slide10")}>All Participants</div>
                                                                <div className={`link mb-4 ${activeCreativeAnalysis == "slide11" ? "active":""}`} onClick={() => setActiveCreativeAnalysis("slide11")}>Age-segmented Participants</div>
                                                                <div className="section-title">Average</div>
                                                                <div className={`link mb-4 ${activeCreativeAnalysis == "slide12" ? "active":""}`} onClick={() => setActiveCreativeAnalysis("slide12")}>Part 1</div>
                                                                <div className={`link mb-4 ${activeCreativeAnalysis == "slide13" ? "active":""}`} onClick={() => setActiveCreativeAnalysis("slide13")}>Part 2</div>
                                                                <div className="section-title">Salience</div>
                                                                <div className={`link mb-4 ${activeCreativeAnalysis == "slide14" ? "active":""}`} onClick={() => setActiveCreativeAnalysis("slide14")}>Definition</div>
                                                                <div className={`link mb-4 ${activeCreativeAnalysis == "slide15" ? "active":""}`} onClick={() => setActiveCreativeAnalysis("slide15")}>Results by Face</div>
                                                                <div className={`link mb-4 ${activeCreativeAnalysis == "slide16" ? "active":""}`} onClick={() => setActiveCreativeAnalysis("slide16")}>Results by Product</div>
                                                                <div className={`link mb-4 ${activeCreativeAnalysis == "slide17" ? "active":""}`} onClick={() => setActiveCreativeAnalysis("slide17")}>Score</div>
                                                            </div>
                                                            <div className='report-preview creative-analysis'>
                                                                <img src={`/public/creative-analysis/4762/${activeCreativeAnalysis}.png`} width="1229" alt=""/>
                                                            </div>
                                                        </div>
                                                    :   <div className="fixed-table-wrapper">
                                                            <table className="primary brand-study">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Creative Study</th>
                                                                        <th>Ordered Date</th>
                                                                        <th>Completed Date</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        marketResearch.items && marketResearch.items.filter(item => item.brand == selectedBrand && item.title !== undefined).map(marketResearch => 
                                                                            <tr key={marketResearch._id}>
                                                                                <td>{marketResearch.title}</td>
                                                                                <td>-</td>
                                                                                <td>-</td>
                                                                                <td>
                                                                                    <OverlayTrigger placement="left" content="" overlay={<Tooltip>Edit</Tooltip>}>
                                                                                        <i className="fa fa-eye" aria-hidden="true" onClick={() => {
                                                                                            clearMarketStudyForm();
                                                                                            setSelectedMarketStudy(marketResearch.title); 
                                                                                            showStudyForm(marketResearch.title); 
                                                                                            setIsNewMarketStudy(false);
                                                                                            setHasChanges(false);
                                                                                            setIsFormShown(true);
                                                                                        }}></i>
                                                                                    </OverlayTrigger>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                }
                                            </Tab>
                                        </Tabs>
                                    </div>

                                    {
                                        // (selectedMarketStudy != null || isNewMarketStudy) &&
                                        // <>
                                        //     <div className="title mw-100">
                                        //         {
                                        //             isResultShown && `Result of ${selectedMarketStudy}`
                                        //         }
                                        //         {
                                        //             isCreativeAnalysisShown && `Creative Analysis of ${selectedMarketStudy}`
                                        //         }
                                        //         {
                                        //             isNewMarketStudy && "New Market Study" 
                                        //         }
                                        //         {
                                        //             (!isResultShown && !isCreativeAnalysisShown && !isNewMarketStudy) && `Edit ${selectedMarketStudy}`
                                        //         }
                                        //     </div>
                                        //     <div className="tabs">
                                        //         <button type="button" onClick={() => {setIsResultShown(false);  setIsFormShown(false); setIsCreativeAnalysisShown(true); }} className={`btn btn-primary btn-sm btn-example ${isCreativeAnalysisShown ? "active":""}`}> Creative Analysis</button>
                                        //         <button type="button" onClick={() => {setIsResultShown(true);   setIsFormShown(false); setIsCreativeAnalysisShown(false); }} className={`btn btn-primary btn-sm btn-example ${isResultShown ? "active":""}`}> Results</button>
                                        //         <button type="button" onClick={() => {setIsResultShown(false);  setIsFormShown(true); setIsCreativeAnalysisShown(false); }} className={`btn btn-primary btn-sm btn-example ${isFormShown ? "active":""}`}> Form</button>
                                        //     </div>
                                        // </>
                                    }
                                    {
                                        /************************
                                         * RESULTS
                                         ***********************/
                                        isResultShown &&
                                        <>
                                            <div className="report-navigation">
                                                <div className="section-title">OCEAN Personality Analysis</div>
                                                <div className={`link mb-4 ${activeReport == "1a" ? "active":""}`} onClick={() => setActiveReport("1a")}>Brand + Target Audience</div>
                                                <div className="section-title">Brand Experience Features</div>
                                                <div className={`link ${activeReport == "2a" ? "active":""}`} onClick={() => setActiveReport("2a")}>Feature Performance</div>
                                                <div className={`link ${activeReport == "2b" ? "active":""}`} onClick={() => setActiveReport("2b")}>Feature Combinations</div>
                                                <div className={`link mb-4 ${activeReport == "2c" ? "active":""}`} onClick={() => setActiveReport("2c")}>Breakdown</div>
                                                <div className="section-title">Affect</div>
                                                <div className={`link mb-4 ${activeReport == "3a" ? "active":""}`} onClick={() => setActiveReport("3a")}>Primary Emotions, Behavior + Trust</div>
                                                <div className="section-title">Authenticity + Tensions</div>
                                                <div className={`link mb-4 ${activeReport == "4a" ? "active":""}`} onClick={() => setActiveReport("4a")}>Secondary Emotions + Outcomes</div>
                                            </div>
                                            <div className='report-preview'>
                                                <img src={`/public/reports/report_${id}_${activeReport}.gif`} alt=""/>
                                            </div>
                                        </>
                                    }
                                    
                                </div>
    
    
                                {/* ENTITY COLUMNS */}
                                <div className={`entity-column market-research ${(isResultShown || isCreativeAnalysisShown) ? "collapsed" : ""}`}>
                                    <div className="title-wrapper">
                                        <div className="title">Clients</div>
                                    </div>
                                    <div className="controls">
                                        <OverlayTrigger placement="top" content="" overlay={<Tooltip>Create Client</Tooltip>}>
                                            <button onClick={() => {
                                                setIsCreateClientFormVisible(true);
                                                setIsCreateBrandFormVisible(false);
                                                setNewBrand("");
                                            }} className="add-entity"><i className="fa fa-plus" aria-hidden="true"></i></button>
                                        </OverlayTrigger>
                                    </div>
                                    <ul>
                                    {
                                        isCreateClientFormVisible &&
                                        <li>
                                            <div className="menu new-form">
                                                <input type="text" placeholder='Enter Client Name' value={newClient} onChange={(e) => setNewClient(e.target.value)}/>
                                                <div className="btn-cancel" onClick={() => {setIsCreateClientFormVisible(false); setNewClient("");}}><i className="fa fa-times"></i></div>
                                                <div className={`btn-submit ${newClient == "" ? "disabled" : ""}`} onClick={() => createClient()}><i className="fa fa-check"></i></div>
                                            </div>
                                        </li>
                                    }
                                    {
                                        //Group by Client
                                        marketResearch.items && marketResearch.items.filter((value, index, self) => index === self.findIndex((t) => (t.client === value.client))).map(marketResearch => 
                                            <li key={marketResearch.client} className={`${marketResearch.client == selectedClient ? 'selected' : ''}`} onClick={() => {
                                                    if(marketResearch.client == selectedClient){
                                                        //Do nothing if its the selected item
                                                    }
                                                    else if(hasChanges){
                                                        if(confirm("You have unsaved changes. \nAre you sure you want to discard these changes?") == true){
                                                            clearMarketStudyForm();
                                                            showBrandPanel(marketResearch.client)
                                                            setHasChanges(false);
                                                        }
                                                    } 
                                                    else{
                                                        clearMarketStudyForm();
                                                        showBrandPanel(marketResearch.client)
                                                        setHasChanges(false);
                                                    }
                                                }}>
                                                <OverlayTrigger placement="right" content="" overlay={<Tooltip>Remove</Tooltip>}>
                                                    <div className="delete-wrapper" onClick={() => removeClient(marketResearch.client)}>
                                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                                    </div>
                                                </OverlayTrigger>
                                                <div className="menu">
                                                    {marketResearch.client}
                                                    <span className={`badge clickable ${getTotalBrands(marketResearch.client) > 0 ? "clickable" : "empty"}`}>
                                                        {
                                                            getTotalBrands(marketResearch.client)
                                                        }
                                                    </span>
                                                </div>
                                            </li>
                                        )
                                    }
                                    </ul>
                                </div>
                                <div className={`entity-column market-research ${(isResultShown || isCreativeAnalysisShown) ? "collapsed" : ""}`}>
                                    <div className="title-wrapper">
                                        <div className="title">Brands</div>
                                    </div>
                                    <div className={`controls ${selectedClient == null ? "disabled" : ""}`}>
                                        <OverlayTrigger placement="top" content="" overlay={<Tooltip>Create Brand</Tooltip>}>
                                            <button onClick={() => {
                                                setIsCreateBrandFormVisible(true);
                                                setIsCreateClientFormVisible(false);
                                                setNewClient("");
                                            }} className="add-entity"><i className="fa fa-plus" aria-hidden="true"></i></button>
                                        </OverlayTrigger>
                                    </div>
                                    <ul>
                                    {
                                        isCreateBrandFormVisible &&
                                        <li>
                                            <div className="menu new-form">
                                                <input type="text" placeholder='Enter Brand Name' value={newBrand} onChange={(e) => setNewBrand(e.target.value)}/>
                                                <div className="btn-cancel" onClick={() => {setIsCreateBrandFormVisible(false); setNewBrand("");}}><i className="fa fa-times"></i></div>
                                                <div className={`btn-submit ${newBrand == "" ? "disabled" : ""}`} onClick={() => createBrand()}><i className="fa fa-check"></i></div>
                                            </div>
                                        </li>
                                    }
                                    {
                                        //Filter by Selected Client AND Group By Brand
                                        marketResearch.items && marketResearch.items.filter(item => item.client == selectedClient && item.brand !== undefined).filter((value, index, self) => index === self.findIndex((t) => (t.brand === value.brand))).map(marketResearch => 
                                            <li key={marketResearch.brand} className={`${marketResearch.brand == selectedBrand ? 'selected' : ''}`} onClick={() => {
                                                    if(marketResearch.brand == selectedBrand){
                                                        //Do nothing if its the selected item
                                                    }
                                                    else if(hasChanges){
                                                        if(confirm("You have unsaved changes. \nAre you sure you want to discard these changes?") == true){
                                                            clearMarketStudyForm();
                                                            showResearchPanel(marketResearch.brand)
                                                            setHasChanges(false);
                                                        }
                                                    } 
                                                    else{
                                                        clearMarketStudyForm();
                                                        showResearchPanel(marketResearch.brand)
                                                        setHasChanges(false);
                                                    }
    
                                                }}>
                                                <OverlayTrigger placement="right" content="" overlay={<Tooltip>Remove</Tooltip>}>
                                                    <div className="delete-wrapper" onClick={() => removeBrand(marketResearch.client, marketResearch.brand)}>
                                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                                    </div>
                                                </OverlayTrigger>
                                                <div className="menu">
                                                    {marketResearch.brand}
                                                    <span className={`badge clickable ${getTotalStudies(marketResearch.brand) > 0 ? "clickable" : "empty"}`}>
                                                        {
                                                            getTotalStudies(marketResearch.brand)
                                                        }
                                                    </span>
                                                </div>
                                            </li>
                                        )
                                    }
                                    </ul>
                                </div>
                                {/* 
                                    <div className={`entity-column market-research market-studies`}>
                                        <div className={`resize-button ${(!isResultShown && !isCreativeAnalysisShown) ? "d-none":""}`} onClick={() => {setIsResultShown(false); setIsCreativeAnalysisShown(false); setIsFormShown(true); }}><i className="fa fa-angle-right" aria-hidden="true"></i></div>
                                        <div className="title-wrapper">
                                            <div className="title">Research Studies</div>
                                        </div>
                                        <div className={`controls ${selectedBrand == null ? "disabled" : ""}`}>
                                            <OverlayTrigger placement="top" content="" overlay={<Tooltip>Create</Tooltip>}>
                                                <button className={`add-entity`} onClick={() => {
                                                    
                                                    if(hasChanges){
                                                        if(confirm("You have unsaved changes. \nAre you sure you want to discard these changes?") == true){
                                                            clearMarketStudyForm();
                                                            setHasChanges(false);
                                                            setIsResultShown(false);
                                                        }
                                                    } 
                                                    else{
                                                        clearMarketStudyForm();
                                                        setHasChanges(false);
                                                        setIsResultShown(false);
                                                    }
                                                }}><i className="fa fa-plus" aria-hidden="true"></i></button>
                                            </OverlayTrigger>
                                        </div>
                                        <ul>
                                        {
                                            //Filter by Selected Brand 
                                            marketResearch.items && marketResearch.items.filter(item => item.brand == selectedBrand && item.title !== undefined).map(marketResearch => 
                                                <li key={marketResearch._id} className={`${marketResearch.title == selectedMarketStudy ? 'selected' : ''}`} onClick={() => {
                                                        if(marketResearch.title == selectedMarketStudy){
                                                            //Do nothing if its the selected item
                                                        }
                                                        else if(hasChanges){
                                                            if(confirm("You have unsaved changes. \nAre you sure you want to discard these changes?") == true){
                                                                clearMarketStudyForm();
                                                                setSelectedMarketStudy(marketResearch.title); 
                                                                showStudyForm(marketResearch.title); 
                                                                setIsNewMarketStudy(false);
                                                                setHasChanges(false);
                                                            }
                                                        } 
                                                        else{
                                                            clearMarketStudyForm();
                                                            setSelectedMarketStudy(marketResearch.title); 
                                                            showStudyForm(marketResearch.title); 
                                                            setIsNewMarketStudy(false);
                                                            setHasChanges(false);
                                                        }
                                                    } }>
                                                    <OverlayTrigger placement="right" content="" overlay={<Tooltip>Remove</Tooltip>}>
                                                        <div className="delete-wrapper" onClick={(e) => {e.stopPropagation(); removeStudy(marketResearch.client, marketResearch.brand, marketResearch.title)}}>
                                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                                        </div>
                                                    </OverlayTrigger>
                                                    <div className="menu">
                                                        {marketResearch.title}
                                                    </div>
                                                </li>
                                            )
                                        }
                                        </ul>
                                    </div>
                                */}

                                {/* DETAILS COLUMN */}
                                <div className="details-column">

                                </div>
                            </div>
                            <Footer />
                    </>
                }
                </div>
        );
    }
    catch(e){
        console.log(e)
        // window.location.reload(false);
    }
    
}


const mapState = state => ({
    user                : state.users.current,
    marketResearch      : state.marketResearch,
    bluekai             : state.bluekai.items,
})

const actionCreators = {
    getMarketResearch   : marketResarchActions.getMarketResearch,
    createMarketStudy   : marketResarchActions.createMarketStudy,
    createClient        : marketResarchActions.createClient,
    createBrand         : marketResarchActions.createBrand,
    updateMarketStudy   : marketResarchActions.updateMarketStudy,
    removeClient        : marketResarchActions.removeClient,
    removeBrand         : marketResarchActions.removeBrand,
    removeStudy         : marketResarchActions.removeStudy,
    getBluekaiList      : bluekaiActions.getBluekaiList,
}

const connectedMarketResearchPage = connect(mapState, actionCreators)(MarketResearchPage);

export { connectedMarketResearchPage as MarketResearchPage };
