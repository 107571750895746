import { bluekaiConstants } from '../_constants';

export function bluekai(state = {}, action) {
  switch (action.type) {
    //=====================================
    // GET
    //=====================================
    case bluekaiConstants.GET_REQUEST:
      return {
        loading: true
      };
    case bluekaiConstants.GET_SUCCESS:
      return {
        items: action.bluekai
      };
    case bluekaiConstants.GET_FAILURE:
      return { 
        error: action.error
      };
      //=====================================
      // DEFAULT
      //=====================================
    default:
      return state
  }
}