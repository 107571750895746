import '../../_helpers/sort-by.js'
export const listOperatingSystems = [
    {value:'ANDROID', label:'Android'},
    {value:'IOS', label:'iOS'},
    {value:'WINPHONE', label:'Windows Phone'},
    {value:'WINRT', label:'WinRT'},
    {value:'WINDOWS', label:'Windows'},
    {value:'MACOSX', label:'macOS'},
    {value:'LINUX', label:'Linux'},
    {value:'CHROMEOS', label:'ChromeOS'},
].sortBy('label')