import React, { useState, useEffect, Component, useDebugValue } from 'react'
import { connect } from 'react-redux';
import { 
    overlayActions, 
    creativeActions, 
    adgroupCreativeActions,
    creativesPendingActions,
} from '../../_actions';


//Helpers
import '../../_helpers/calculate-ratio.js'

//Moment
import moment from 'moment';


const CreativeFormPush  = (props) => {
    
    const { creatives, selectedAdgroup, campaignType, selectedCreative, adgroups, overlay, user } = props

    const useStateWithLabel = (initialValue, label) => {
        const [value, setValue] = useState(initialValue);
        useDebugValue(`${label}: ${value}`);
        return [value, setValue];
    };

    // console.log(adgroups && adgroups.items && adgroups.items.filter(item => item.id == selectedAdgroup))
    // console.log([creatives.items.filter(item => item.id == selectedCreative).reduce((acc, cur) => cur)])
    const adgroup = adgroups && adgroups.items && adgroups.items.filter(item => item.id == selectedAdgroup)[0]

    const [submitted, setSubmitted]                         = useStateWithLabel(false, "submitted")
    const [key, setKey]                                     = useStateWithLabel('info', "key")
    const [isErrorImageIcon, setIsErrorImageIcon]           = useStateWithLabel([false], "isErrorImageIcon")
    const [isErrorImageLandscape, setIsErrorImageLandscape] = useStateWithLabel([false], "isErrorImageLandscape")
    const [selected, setSelected]                           = useStateWithLabel(0, "selected")
    const [formData, setFormData]                           = useStateWithLabel(
        overlay.purpose == "edit"
        ? 
        {
            creatives: [creatives.items.filter(item => item.id == selectedCreative).reduce((acc, cur) => cur)]
        }
        : 
        {
        name                : adgroup.name,
        target_url          : adgroup.target_url,
        rate_model          : adgroup.rate_model,
        direction           : adgroup.direction,
        frequency           : adgroup.frequency,
        capping             : adgroup.capping,
        status              : adgroup.status,
        started_at          : adgroup.started_at,
        expired_at          : adgroup.expired_at,
        rates               : adgroup.rates,
        daily_amount        : adgroup.daily_amount,
        total_amount        : adgroup.total_amount,
        targeting           : adgroup.targeting,
        timezone            : adgroup.timezone,
        creatives               : [{
                status          : 1,
                title           : "Push Ad Creative #1",
                description     : "",
                icon            : null,
                image           : null,
                frequency       : adgroup.creatives ? adgroup.creatives[0].frequency        : "",
                capping         : adgroup.creatives ? adgroup.creatives[0].capping          : "",
                click_frequency : adgroup.creatives ? adgroup.creatives[0].click_frequency  : "",
                click_capping   : adgroup.creatives ? adgroup.creatives[0].click_capping    : "",
        }]
    }, "adgroup");
    

    const isFormValid = () => {
        let isValidForm = formData.creatives.filter(item => 
            item.title == "" || item.description == "" || item.icon == null
        ).length > 0 ? false : true

        return isValidForm
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if(overlay.purpose == "edit"){
            const data = formData.creatives[0]
            // console.log("edit")
            //Edit Creative
            // console.log(`edit creative: ${data.id}`)
            props.editCreativePush(data)
        }
        else{
            // IF its not in propeller yet THEN create as new propeller campaign
            // ELSE Add it to the existing propeller campaign
            if(adgroups.items.filter(item => item.id == selectedAdgroup).reduce((acc, cur) => cur).isAvailableRemotely == false){

                //Make sure we dont submit {status:9}
                if(formData.status == 9){
                    formData.status = 1
                }
                props.addCreativePush(formData, user.accountId, selectedAdgroup)
            }
            else{
                //Add more creatives on existing propeller campaign
                formData.creatives.map((creative, index) => {
                    delete creative.frequency
                    delete creative.capping
                    delete creative.click_frequency
                    delete creative.click_capping
                    delete creative.status
                })
                // console.log("add new creative")
                props.addMoreCreativePush(formData.creatives, user.accountId, selectedAdgroup)
            }
        }

        props.closeOverlay()
    }
    
    const handleSelectCreative = (index) => {
        setSelected(index)
    }

    const handleRemoveCreative = (index, event) => {
        event.stopPropagation();
        event.preventDefault();
        const newCreatives  = formData.creatives;
        var array = [...newCreatives]; 
        var index = index
        array.splice(index, 1);
        setSelected(0)
        setFormData({
            ...formData,
            ["creatives"]: array
        })
    }

    const handleAddNewCreative = (event) => {
        const newCreatives = formData.creatives;
        setFormData({
            ...formData,
            creatives:[
                ...formData.creatives,
                {
                    status          : 1,
                    title           : `Push Ad Creative #${formData.creatives.length + 1}`,
                    description     : "",
                    icon            : null,
                    image           : null,
                    frequency       : adgroup.creatives ? adgroup.creatives[0].frequency        : "",
                    capping         : adgroup.creatives ? adgroup.creatives[0].capping          : "",
                    click_frequency : adgroup.creatives ? adgroup.creatives[0].click_frequency  : "",
                    click_capping   : adgroup.creatives ? adgroup.creatives[0].click_capping    : "",
                }
            ]
        })
        setSelected(formData.creatives.length)
    }

    const handleCloseOverlay = () => {
        props.closeOverlay()
    }

    const getRemainingChar = (allowed, actual) => {
        return allowed - actual;
    }

    /**************************************************************
     * On Change Creatives
     **************************************************************/
     const handleChangeInputCreative = (event) => {
        let { name, value } = event.target;

        //Trim (max chars)
        if(name == "title"){
            value = value.substring(0, 30);
        }
        if(name == "description"){
            value = value.substring(0, 40);
        }

        let new_value = formData.creatives[selected];
        new_value[name] = value
        let arr = formData.creatives
        arr[selected] = new_value
        setFormData({
            ...formData,
            ["creatives"]: arr
        })
    }

    // const setIsErrorImageIconArr = (value) => {
    //     let new_value = isErrorImageIcon[selected];
    //     new_value[name] = value
    //     let arr = formData.creatives
    //     arr[selected] = new_value
    //     setIsErrorImageIcon({
    //         ...isErrorImageIcon,
    //         [selected]: false
    //     })
    // }

    /**************************************************************
     * On File Change
     **************************************************************/
     const handleFileUpload = (event) => {
        if (event.target.files && event.target.files[0]) {
            
            let file = event.target.files[0]
            let field = event.target.name

            //Clear previous error messge
            switch(field){
                case  "icon"    :   setIsErrorImageIcon({
                                        ...isErrorImageIcon,
                                        [selected]: false
                                    });
                                    break;
                case  "image"   :   setIsErrorImageLandscape({
                                        ...isErrorImageLandscape,
                                        [selected]: false
                                    });
                                    break;
            }


            //1. Reads as DataURL (for checking max width and height)
            const fileReader = new FileReader()
            fileReader.onload = () => {
                const img = new Image()

                img.onload = () => {
                    if((field == "icon" && (img.width != 192 || img.height != 192))){             // icon image must be 192x192
                        setIsErrorImageIcon({
                            ...isErrorImageIcon,
                            [selected]: true
                        });
                    }
                    else if((field == "image" && (img.width != 360 || img.height != 240))){     // landscape image must be 360x240
                        setIsErrorImageLandscape({
                            ...isErrorImageLandscape,
                            [selected]: true
                        });
                    }
                    else{
                        //2. Reads as BinaryString (for used in Payload)
                        const reader = new FileReader();
                        reader.onload = function(readerEvent){
                            // console.log(file.type)
                            
                            let binaryString = readerEvent.target.result
                            let new_value = formData.creatives[selected];
                            new_value[field] = "data:" + file.type + ";base64," + btoa(binaryString)
                            let arr = formData.creatives;
                            arr[selected] = new_value
                            setFormData({
                                ...formData,
                                ["creatives"]: arr
                            })
                        }
                        reader.readAsBinaryString(file)
                    }

                }

                img.src = fileReader.result
            }
            fileReader.readAsDataURL(file)

       }
    }

    try{

        return(
                <div className={`window creative-form push-ads ${overlay.purpose == "create" ? "wide" : "dual-width"}`}> 
                    <form name="form" onSubmit={handleSubmit}>
                        <section className="title main">
                            {formData.creatives[selected].title == "" ? <>&nbsp;</> : formData.creatives[selected].title}
                        </section>
                        <section className={`body ${overlay.purpose == "create" ? "tri-width" : "dual-width"}`}>
                            {
                                // overlay.purpose == "create" && 
                                overlay.purpose == "create" && 
                                <table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            {
                                                overlay.purpose == "create" &&
                                                <>
                                                    <th></th>
                                                    <th></th>
                                                </>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            formData.creatives.map((item, index) => 
                                                <tr key={index} className={`clickable ${index == selected ? "selected" : ""}`} onClick={() => handleSelectCreative(index)}>
                                                    <td className="preview">
                                                        {
                                                            formData.creatives[index].icon == null ? <i className="fa fa-file-image-o" aria-hidden="true"></i> : <img src={formData.creatives[index].icon} alt="" width="25" height="25"/>
                                                        }
                                                    </td>
                                                    {
                                                        overlay.purpose == "create" &&
                                                        <td className="controls">
                                                            <button className={`btn btn-primary btn-table ${formData.creatives.length == 1 ? "disabled" : ""}`} onClick={(e) => handleRemoveCreative(index, e)}><i className="fa fa-times" aria-hidden="true"></i> <span>Remove</span></button>
                                                        </td>

                                                    }
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                    {
                                        overlay.purpose == "create" &&
                                        <tfoot>
                                            <tr>
                                                <td colSpan="3">
                                                    <button type="button" className="btn btn-primary btn-xs btn-more" onClick={handleAddNewCreative}>
                                                        <i className="fa fa-plus-circle"></i> Add More
                                                    </button>
                                                </td>
                                            </tr>
                                        </tfoot>

                                    }
                                </table>
                            }
                            <div className="form-panel push-ads">
                                <div className="fields">
                                    <div className="title">{overlay.purpose == "edit" ? "Edit" : "Add New"} Creative</div>

                                    {/* TITLE */}
                                    <div className={'form-group'}>
                                        <label htmlFor="dailyBudget">Title*</label>
                                        <div className="content">
                                            <input type="text" name="title" value={formData.creatives.length > 0 ? formData.creatives[selected].title : ""} onChange={(e) => handleChangeInputCreative(e)} /> 
                                            <div className={`remaining ${overlay.purpose == "edit" && "d-none"}`}>
                                                {
                                                    formData.creatives.length > 0 ? `${getRemainingChar(30, formData.creatives[selected].title.length)}` : 0
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    {/* DESCRIPTION */}
                                    <div className={'form-group'}>
                                        <label htmlFor="dailyBudget">Description*</label>
                                        <div className="content">
                                            <input type="text" name="description" value={formData.creatives.length > 0 ? formData.creatives[selected].description : ""} onChange={(e) => handleChangeInputCreative(e)} /> 
                                            <div className={`remaining ${overlay.purpose == "edit" && "d-none"}`}>
                                                {
                                                    formData.creatives.length > 0 ? `${getRemainingChar(40, formData.creatives[selected].description.length)}` : 0
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    {/* ICON */}
                                    <div className={`form-group ${isErrorImageIcon[selected] && "mb-3"}`}>
                                        <label htmlFor="dailyBudget">
                                            Icon <small>(192x192)</small>
                                        </label>
                                        <div className={`content ${isErrorImageIcon[selected] && "d-inline"}`}>
                                            <input 
                                                className={`d-none`} 
                                                type="file" 
                                                accept="image/png, image/gif, image/jpeg, image/jpg"
                                                name="icon"
                                                multiple={false}
                                                value=""
                                                onChange={handleFileUpload }
                                                id="field_upload_icon"
                                                />
                                                <label className={`btn btn-primary btn-xs ${overlay.purpose == "edit" ? "disabled" : ""}`} htmlFor="field_upload_icon">
                                                    <i className="fa fa-cloud-upload"></i> 
                                                    {
                                                        (formData.creatives[selected].icon != null && !isErrorImageIcon[selected]) ? <> Change file</> :<> Choose file</>
                                                    }
                                                </label>
                                                {
                                                    isErrorImageIcon[selected] &&
                                                    <div className="error-field mt-0">Icon must be 192x192</div>
                                                }
                                                {
                                                    (formData.creatives[selected].icon != null && !isErrorImageIcon[selected] && overlay.purpose != "edit") &&
                                                    <div className="progress-wrapper "><i className="fa fa-check-circle" aria-hidden="true"></i></div>
                                                }
                                        </div>
                                    </div>
                                    
                                    {/* IMAGE */}
                                    <div className={`form-group ${isErrorImageLandscape[selected] && "mb-3"}`}>
                                        <label htmlFor="dailyBudget">Image <small>(360x240)</small></label>
                                        <div className={`content ${isErrorImageLandscape[selected] && "d-inline"}`}>
                                            <input 
                                                className={`d-none`} 
                                                type="file" 
                                                accept="image/png, image/gif, image/jpeg, image/jpg"
                                                name="image"
                                                multiple={false}
                                                value=""
                                                onChange={handleFileUpload }
                                                id="field_upload_landscapeimg"
                                            />
                                                <label className={`btn btn-primary btn-xs ${overlay.purpose == "edit" ? "disabled" : ""}`} htmlFor="field_upload_landscapeimg">
                                                    <i className="fa fa-cloud-upload"></i> 
                                                    {
                                                        (formData.creatives[selected].image != null && !isErrorImageLandscape[selected]) ? <> Change file</> :<> Choose file</>
                                                    }
                                                </label>
                                                {
                                                    isErrorImageLandscape[selected] && 
                                                    <div className="error-field mt-0">Image must be 360x240</div>
                                                }
                                                {
                                                    (formData.creatives[selected].image != null && !isErrorImageLandscape[selected] && overlay.purpose != "edit") &&
                                                    <div className="progress-wrapper "><i className="fa fa-check-circle" aria-hidden="true"></i></div>
                                                }
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="preview">
                                    <div className="title">
                                        Preview ad
                                    </div>
                                    <div className="canvas">
                                        {
                                            (formData.creatives[selected].icon != null || formData.creatives[selected].image != null) &&
                                            <div className="phone-preview">
                                                <div className='speaker'></div>
                                                <div className='push-list'>
                                                    <div className='push'>
                                                        <div className='header'>
                                                            <div className="browser">
                                                                <i className="fa fa-chrome" aria-hidden="true"></i>
                                                                <div className="AndroidPreview__push-header-browser-title">Chrome</div>
                                                            </div>
                                                            <div className="site">supernova.inc</div>
                                                            <div className="time">
                                                                <span>Now</span>
                                                            </div>
                                                        </div>
                                                        <div className='push-container'>
                                                            <div className="main-content">
                                                                <div className="push-title">{formData.creatives.length > 0 ? formData.creatives[selected].title : ""}</div>
                                                                <div className="push-description">{formData.creatives.length > 0 ? formData.creatives[selected].description : ""}</div>
                                                            </div>
                                                            <div className="icon">
                                                                <img width={34} height={34} src={formData.creatives[selected].icon} />
                                                            </div>
                                                            <div className="landscape-image" data-i={formData.creatives[selected].image} style={{
                                                                backgroundImage:`url(${formData.creatives[selected].image})`
                                                            }}></div>
                                                        </div>
                                                        <div className='push-buttons'>
                                                            <span>More</span>
                                                            <span>Settings</span>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        }
                                    </div>
                                    
                                </div>
                            </div>
                        </section>
                        <section className="footer">
                            <button className="btn btn-primary" disabled={isFormValid() == false} type="submit" onClick={handleSubmit}><i className="fa fa-check" aria-hidden="true"></i> 
                                {
                                    submitted ? "Uploading" :
                                    overlay.purpose == "create" ? 'Upload' : 'Save'
                                }
                            </button>
                            <button className="btn btn-secondary" onClick={handleCloseOverlay}>Cancel</button>
                        </section>
                    </form>
                </div>
        )
    }
    catch(e){
        // console.log(e)
        // window.location.reload(false);
    }
        
}

const mapState = state => ({
    user:state.users.current,
    overlay:state.overlay,
    creatives: state.creatives,
})

const actionCreators = {
    closeOverlay            : overlayActions.close,
    addCreativePush         : creativeActions.addCreativePush,
    addMoreCreativePush     : creativeActions.addMoreCreativePush,
    editCreativePush        : creativeActions.editCreativePush,
}


const connectedCreativeFormPush = connect(mapState, actionCreators)(CreativeFormPush);
export { connectedCreativeFormPush as CreativeFormPush };