import config from 'config';
import { authHeader } from '../_helpers';

export const adgroupService = {
    getAdgroups,
    getAllAdgroups,
    getAdgroupNames,
    createAdgroup,
    getAdgroupDetail,
    updateAdgroup,
    createAdgroupPush,
    updateAdgroupPush,
    togglePushCampaign,
    createSupernovaAdgroup,
    createAdgroupSupernovaExtensions,
    getLockList,
    updateSupernovaAdgroup
};

function getLockList(campaignId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/adgroups/lock/${campaignId}`, requestOptions).then(handleResponse);
}
function getAdgroups(supernovaCampaignId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/adgroups/?supernovaCampaignId=${supernovaCampaignId}`, requestOptions).then(handleResponse);
}

function getAllAdgroups(accountId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/adgroups/?accountId=${accountId}`, requestOptions).then(handleResponse);
}

function getAdgroupNames() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/adgroups/`, requestOptions).then(handleResponse);
}

function createAdgroup(data, accountId) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/adgroups/${accountId}`, requestOptions).then(handleResponse);
}

function createAdgroupSupernovaExtensions(data, queryId) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/adgroups/supernova/extended/${queryId}`, requestOptions).then(handleResponse);
}

function createSupernovaAdgroup(data, accountId, campaignId) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/adgroups/supernova/${accountId}/${campaignId}`, requestOptions).then(handleResponse);
}

function createAdgroupPush(data, accountId, campaignId) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/adgroups/push/${accountId}/${campaignId}`, requestOptions).then(handleResponse);
}

function getAdgroupDetail(adgroupId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/adgroups/${adgroupId}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function updateAdgroup(data, accountId) {
    if(data.autopilot!= undefined){
        data.autopilot = Boolean(data.autopilot)
    }
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/adgroups/${accountId}/${data.supernovaAdgroupId}`, requestOptions).then(handleResponse);
}

function updateSupernovaAdgroup(data, accountId) {
    if(data.autopilot!= undefined){
        data.autopilot = Boolean(data.autopilot)
    }
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/adgroups/supernova/${accountId}/${data.id}`, requestOptions).then(handleResponse);
}

function updateAdgroupPush(data, accountId) {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/adgroups/push/${accountId}/${data.id}`, requestOptions).then(handleResponse);
}

function togglePushCampaign(id, action) {
    const requestOptions = {
        method: 'PUT',
    };
    return fetch(`${config.apiURL}/adgroups/push/status/${action}/${id}`, requestOptions).then(handleResponse);
}