import React, { useState, useEffect, Component, useDebugValue } from 'react'
import {BrowserRouter as Router, Link, useHistory } from "react-router-dom";

//Redux
import { connect } from 'react-redux';
import { 
    userActions,
    adgroupActions,
    statisticActions,
    campaignActions
} from '../_actions';

//React Select
import { listCreativeType } from '../_constants/data'
import { styleSelectBasic, styleSelectMultiple } from '../_constants/settings'
import Select from 'react-select'

//Components
import {Footer} from '../_components/Footer';
import {LeftNavigation} from '../_components/LeftNavigation'

//Helpers
import '../_helpers/sort-by.js'

//Moments JS
import Moment from 'react-moment';
import moment from 'moment';

//Toggle
import Toggle from 'react-toggle'
import '../_stylesheets/css/react-toggle.css'

//List Data
import { 
    listIABCategory, 
    listLanguage, 

} from '../_constants/data'
import { campaigns } from '../_reducers/campaigns.reducer';


const AdgroupList = (props) => {
    const useStateWithLabel = (initialValue, label) => {
        const [value, setValue] = useState(initialValue);
        useDebugValue(`${label}: ${value}`);
        return [value, setValue];
    };

    const { user, users, campaigns, adgroups, statistics } = props;

    //Used as the actual Date Range parameter
    const [startDate, setStartDate] = useStateWithLabel(moment().subtract(60, 'days'), "startDate");
    // const [startDate, setStartDate] = useStateWithLabel(moment("2021/01/01"), "startDate");
    const [endDate, setEndDate] = useStateWithLabel(moment(), "endDate");

    //Daily Budget input fields (Helps us determine which Bid is being edited in the dashboard table)
    const [editBudgetFieldId, setEditBudgetFieldId] = useStateWithLabel(null, "editBudgetFieldId");
    const [editBudgetFieldValue, setEditBudgetFieldValue] = useStateWithLabel(null, "editBudgetFieldValue");


    //1. GET all adgroups
    useEffect(() => {
        let campaignId = props.match.params.campaignId;
        //If there is no campaignID specified in the URL paramater THEN get all adgroups
        if(campaignId){
            props.getAdgroups(campaignId);
        }
        else{
            //Else get adgroup based on given campaignID
            props.getAllAdgroups(user.accountId);
        }

        //Get list of Campaigns (So we can get the "Campaign Name" based on campaignID)
        props.getCampaigns(user.accountId);
    },[user]);

    //2. WHEN campaign is data is ready, GET its statistics
    useEffect(() => {
        if(adgroups.isDone){
            props.getStatsPerAdgroup(user.accountId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
        }
    },[
        adgroups.isDone,
    ]);

    //Navigate onClick
    const history = useHistory();
    const routeChange = (path) =>{ 
    history.push(path);
    }

    //Search input fields
    const [adgroupSearch, setAdGroupSearch] = useStateWithLabel("", "adgroupSearch");

    //Bid input fields (Helps us determine which Bid is being edited in the dashboard table)
    const [editBidFieldId, setEditBidFieldId] = useStateWithLabel(null, "editBidFieldId");
    const [editBidFieldValue, setEditBidFieldValue] = useStateWithLabel(null, "editBidFieldValue");

    const getCampaignNameByID = (campaignId) =>{
        let data = campaigns.items && campaigns.items.filter(campaign => campaign.id == campaignId);
        return (campaigns.items && data.length > 0) ? data[0].name : "-";

    }

    return (
        <div className="creative-library">

            <LeftNavigation />
            <div className="canvas">
                <div className={`custom-panel creativelib table`}>
                    <div className="header">
                        Campaigns <i className="fas fa-long-arrow-alt-right"></i> Ad Groups
                    </div>
                    {
                        adgroups.loading ?
                        <div className="loading">
                            Initializing...
                        </div>
                        :
                        <table className="home primary fixed-header has-hover"> 
                            <thead>
                                <tr>
                                    <th className="id">Adgroup ID</th>
                                    <th className="name">Adgroup Name</th>
                                    <th className="id">Campaign ID</th>
                                    <th className="name">Campaign Name</th>
                                    <th className="stats text-right">Impression</th>
                                    <th className="stats text-right">Clicks</th>
                                    <th className="stats text-right">Media Spend</th>   
                                    <th className="stats text-right">Data Spend</th>   
                                    <th className="stats text-right">Total Spend</th>   
                                    {/* <th className="stats text-right">Daily Budget</th>    */}
                                    <th className="stats text-right">Bid</th>   
                                    {/* <th className="stats text-right">Yesterday</th>    */}
                                    <th className="stats text-right">ECPC</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    statistics.adgroup && adgroups.items && adgroups.items.filter(adgroup => 
                                        adgroup.name.toUpperCase().indexOf(adgroupSearch.toUpperCase()) >= 0 ||
                                        adgroup.id.indexOf(adgroupSearch) >= 0
                                        ).map((adgroup, index) =>
                                        <tr className={`clickable ${adgroup.archived ? 'd-none' : ''}`} onClick={() => routeChange(`/creative/${adgroup.id}`)}  key={adgroup.id}>
                                            <td className="id"><span>{adgroup.id}</span></td>
                                            <td className="name"><span className="blue-link plain">{adgroup.name}</span></td>
                                            <td className="id"><span>{adgroup.campaignId}</span></td>
                                            <td className="name"><span className="blue-link plain">{getCampaignNameByID(adgroup.campaignId)}</span></td>
                                            
                                            {/* ADGROUP IMPRESSIONS */}
                                            <td className="stats text-right">
                                                <span>
                                                    {
                                                        statistics.adgroup.filter(item => item.adGroupId == adgroup.id).length == 0 
                                                        ? "-" 
                                                        : statistics.adgroup.filter(item => item.adGroupId == adgroup.id)[0].impressions.toLocaleString()
                                                    }
                                                </span>
                                            </td>

                                            {/* ADGROUP CLICKS */}
                                            <td className="stats text-right">
                                                <span>
                                                    {
                                                        statistics.adgroup.filter(item => item.adGroupId == adgroup.id).length == 0 
                                                        ? "-" 
                                                        : statistics.adgroup.filter(item => item.adGroupId == adgroup.id)[0].clicks.toLocaleString()
                                                    }
                                                </span>
                                            </td>

                                            {/* ADGROUP MEDIA SPEND */}
                                            <td className="stats text-right">
                                                <span>
                                                    {
                                                        statistics.adgroup.filter(item => item.adGroupId == adgroup.id).length == 0 
                                                        ? "-"
                                                        :
                                                            `$${parseFloat(statistics.adgroup.filter(item => item.adGroupId == adgroup.id)[0].mediaSpend).toLocaleString(undefined, {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })}`
                                                    }
                                                </span>
                                            </td>

                                            {/* ADGROUP DATA SPEND */}
                                            <td className="stats text-right">
                                                <span>
                                                    {
                                                        statistics.adgroup.filter(item => item.adGroupId == adgroup.id).length == 0 
                                                        ? "-"
                                                        :
                                                            `$${parseFloat(statistics.adgroup.filter(item => item.adGroupId == adgroup.id)[0].mediaSpend).toLocaleString(undefined, {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })}`
                                                    }
                                                </span>
                                            </td>

                                            {/* ADGROUP TOTAL SPEND */}
                                            <td className="stats text-right">
                                                <span>
                                                    {
                                                        statistics.adgroup.filter(item => item.adGroupId == adgroup.id).length == 0 
                                                        ? "-"
                                                        :
                                                            `$${parseFloat(statistics.adgroup.filter(item => item.adGroupId == adgroup.id)[0].dataspend_with_margin + statistics.adgroup.filter(item => item.adGroupId == adgroup.id)[0].mediaSpend).toLocaleString(undefined, {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })}`
                                                    }
                                                </span>
                                            </td>

                                            {/* ADGROUP DAILY BUDGET */}
                                            {/* <td className="stats text-right">
                                                <span>
                                                    {
                                                        editBudgetFieldId == adgroup.id 
                                                        ?
                                                            <>
                                                                <div className="editFieldButtons">
                                                                    <span className="currency">$</span> 
                                                                    <input type="text" className="form-control" name="bid" value={editBudgetFieldValue == null ? parseFloat(adgroup.dailyBudget).toLocaleString(undefined, {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2
                                                                    }) : editBudgetFieldValue} onChange={event => setEditBudgetFieldValue(event.target.value)}/>
                                                                    <button className="btn btn-primary" type="button" onClick={(event) => saveNewBudget(editBudgetFieldValue, adgroup.id)}><i className="fa fa-check" aria-hidden="true"></i> Save</button>
                                                                    <button className="btn btn-secondary" type="button" onClick={() => cancelEditField()}>Cancel</button>
                                                                </div>
                                                            </>
                                                        :
                                                            //IF autopilot of the parent campaign is disabled THEN show dailyBudget from {props.statistics.adgroup} 
                                                            //ELSE show dailyBudget from {props.adgroups}
                                                            campaigns.items && 
                                                            campaigns.items.filter(campaign => campaign.id == adgroup.campaignId).length > 0 &&
                                                            campaigns.items.filter(campaign => campaign.id == adgroup.campaignId)[0].autopilot == false 
                                                            ?
                                                                <span className="blue-link plain" onClick={() => {
                                                                    cancelEditField(); 
                                                                    setEditBudgetFieldId(adgroup.id);
                                                                    }}>
                                                                    $ {parseFloat(adgroup.dailyBudget).toLocaleString(undefined, {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2
                                                                    })} <i className="fa fa-pencil" aria-hidden="true"></i>
                                                                </span>
                                                            :
                                                                statistics.adgroup.filter(item => item.adGroupId == adgroup.id).length == 0 
                                                                ? 
                                                                    "-"
                                                                :
                                                                    `$${parseFloat(statistics.adgroup.filter(item => item.adGroupId == adgroup.id)[0].dailyBudget).toLocaleString(undefined, {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2
                                                                    })}`

                                                    }
                                                </span>
                                            </td> */}

                                            {/* ADGROUP BID */}
                                            <td className="stats text-right">
                                                    {
                                                        editBidFieldId == adgroup.id 
                                                        ?
                                                        <>
                                                            <div className="editFieldButtons">
                                                                <span className="currency">$</span> <input type="text" className="form-control" name="bid" value={editBidFieldValue == null ? parseFloat(adgroup.bid).toFixed(2) : editBidFieldValue} onChange={event => setEditBidFieldValue(event.target.value)}/>
                                                                <button className="btn btn-primary" type="button" onClick={(event) => saveNewBid(editBidFieldValue, adgroup.id)}><i className="fa fa-check" aria-hidden="true"></i> Save</button>
                                                                <button className="btn btn-secondary" type="button" onClick={() => cancelEditField()}>Cancel</button>
                                                            </div>
                                                        </>
                                                        :
                                                        <span className="blue-link plain" onClick={() => {
                                                            cancelEditField(); 
                                                            setEditBidFieldId(adgroup.id);
                                                            }}>
                                                            $ {parseFloat(adgroup.bid).toFixed(2)} <i className="fa fa-pencil" aria-hidden="true"></i>
                                                        </span>

                                                    }
                                            </td>

                                            {/* ADGROUP YESTERDAY SPEND */}
                                            {/* <td className="stats text-right">
                                                <span>
                                                    {
                                                        statistics.adgroup.filter(item => item.adGroupId == adgroup.id).length == 0 
                                                        ? "-"
                                                        :
                                                            `$${parseFloat(statistics.adgroup.filter(item => item.adGroupId == adgroup.id)[0].yesterday_spend).toLocaleString(undefined, {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })}`
                                                    }
                                                </span>
                                            </td> */}

                                            
                                            {/* ADGROUP CPC */}
                                            <td className="stats text-right">
                                                <span>
                                                    {
                                                        statistics.adgroup.filter(item => item.adGroupId == adgroup.id).length == 0 
                                                        ? "-"
                                                        : 
                                                            `$${parseFloat(
                                                                (statistics.adgroup.filter(item => item.adGroupId == adgroup.id)[0].dataspend_with_margin + statistics.adgroup.filter(item => item.adGroupId == adgroup.id)[0].mediaSpend) / 
                                                                statistics.adgroup.filter(item => item.adGroupId == adgroup.id)[0].clicks).toLocaleString(undefined, {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                            })}`
                                                    } 
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        
            <Footer />
        </div>
    )
}

const mapState = state => ({
    user        : state.users.current,
    users       : state.users,
    campaigns   : state.campaigns,
    statistics  : state.statistics,
    adgroups   : state.adgroups,
})

const actionCreators = {
    getAllAdgroups      : adgroupActions.getAllAdgroups,
    getAdgroups         : adgroupActions.getAdgroups,
    getStatsPerAdgroup  : statisticActions.getStatsPerAdgroup,
    getCampaigns        : campaignActions.getCampaigns,
}

const connectedAdgroupList = connect(mapState, actionCreators)(AdgroupList);

export { connectedAdgroupList as AdgroupList };