import React, {useState, useEffect, Component, useDebugValue } from 'react'
import {BrowserRouter as Router, Link } from "react-router-dom";

//Redux
import { connect } from 'react-redux';
import { 
    // sideDrawerActions 
} from '../_actions';

//Moments JS
import Moment from 'react-moment';
import moment from 'moment';


const Submenu = (props) => {
    const useStateWithLabel = (initialValue, label) => {
        const [value, setValue] = useState(initialValue);
        useDebugValue(`${label}: ${value}`);
        return [value, setValue];
    };

    useEffect(() => {
        
    },[]);

    const isOnCreativeLibrary = window.location.pathname == "/creative-library";
    const isOnDashboard = window.location.pathname == "/";

    return (
        <div className="submenu">
            <Link to="/" className={`bread-crumb ${isOnDashboard ? "active" : ""}`} ><span className="single">Dashboard</span></Link>
            <Link to="/creative-library" className={`bread-crumb ${isOnCreativeLibrary ? "active" : ""}`} ><span className="single">Creative Library</span></Link>
            <Link to="/publisher-management" className="bread-crumb"><span className="single">Publisher Management</span></Link>
            <Link to="/pixels" className="bread-crumb"><span className="single">Pixels and Audiences</span></Link>
        </div>
    )
}

const mapState = state => ({
})

const actionCreators = {
}

const connectedSubmenu = connect(mapState, actionCreators)(Submenu);

export { connectedSubmenu as Submenu };