import { creativesPendingConstants } from '../_constants';

export function creativesPending(state = {}, action) {
  switch (action.type) {
    //=====================================
    // SUBMIT
    //=====================================
    case creativesPendingConstants.SUBMIT_REQUEST:
      return { 
        ...state,
        submiting: true 
      };
    case creativesPendingConstants.SUBMIT_SUCCESS:
      return {
        ...state,
        progress: action.progress,
        submiting:false
      };
    case creativesPendingConstants.SUBMIT_FAILURE:
      return {
        ...state,
        submiting:false,
        error: action.error
      };
    //=====================================
    // GET
    //=====================================
    case creativesPendingConstants.GET_REQUEST:
      return {
        isDone: false,
        items:[],
      };
    case creativesPendingConstants.GET_SUCCESS:
      return {
        isDone: true,
        items: action.creativesPending
      };
    case creativesPendingConstants.GET_FAILURE:
      return { 
        isDone: true,
        items:[],
        error: action.error
      };
    //=====================================
    // ADD
    //=====================================
    case creativesPendingConstants.ADD_REQUEST:
      return {
        ...state,
        isDone: false,
      };
    case creativesPendingConstants.ADD_SUCCESS:
      return {
        ...state,
        isDone: true,
        items: state.items.concat(action.creatives),
      };
    case creativesPendingConstants.ADD_FAILURE:
      return { 
        ...state,
        isDone: true,
        error: action.error
      };
    //=====================================
    // CHECK UPLOAD STATUS
    //=====================================
    case creativesPendingConstants.CHECK_UPLOAD_STATUS_REQUEST:
      return {
        ...state,
        isCheckingUploadStatus: true,
        isReloadRequired:false,
      };
    case creativesPendingConstants.CHECK_UPLOAD_STATUS_SUCCESS:
      
      //Update status by batchId
      const i = state.items.findIndex(item => item.batchId == action.progress.id)
      if(action.progress.status == "DONE"){
        state.items[i].status = "approved" //APPROVED Is a temporary status by the reducer to include it the list but without Pending Status
      }
      if(action.progress.status == "FAILED"){
        state.items[i].status = "disapproved" //APPROVED Is a temporary status by the reducer to include it the list but without Pending Status
      }
      
      return {
        ...state,
        isCheckingUploadStatus: false,
        isReloadRequired: action.progress.status == "DONE" ? true : false,
        items: state.items,
      };
    case creativesPendingConstants.CHECK_UPLOAD_STATUS_FAILURE:
      return { 
        ...state,
        isCheckingUploadStatus: false,
        isReloadRequired:false,
        error: action.error
      };
      //=====================================
      // DEFAULT
      //=====================================
    default:
      return state
  }
}