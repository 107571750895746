import { adgroupCreativeConstants } from '../_constants';
import { adgroupCreativeService } from '../_services';

export const adgroupCreativeActions = {
    getAdgroupCreatives,
    addAdgroupCreatives
};



function getAdgroupCreatives(accountId) {
    return dispatch => {
        dispatch(request());

        adgroupCreativeService.getAdgroupCreatives(accountId)
            .then(
                adgroupCreatives => dispatch(success(adgroupCreatives)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: adgroupCreativeConstants.GET_REQUEST } }
    function success(adgroupCreatives) { return { type: adgroupCreativeConstants.GET_SUCCESS, adgroupCreatives } }
    function failure(error) { return { type: adgroupCreativeConstants.GET_FAILURE, error } }
}


function addAdgroupCreatives(adGroupId, creativeId, accountId){
    return dispatch => {
        dispatch(request());
        console.log(`${adGroupId}, ${creativeId}, ${accountId}`)
        adgroupCreativeService.addAdgroupCreatives(adGroupId, creativeId, accountId)
            .then(
                adgroupCreatives => dispatch(success(adgroupCreatives)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: adgroupCreativeConstants.ADD_REQUEST } }
    function success(adgroupCreatives) { return { type: adgroupCreativeConstants.ADD_SUCCESS, adgroupCreatives } }
    function failure(error) { return { type: adgroupCreativeConstants.ADD_FAILURE, error } }
}