import { measurementConstants } from '../_constants';
import { measurementService } from '../_services';
import { alertActions, progressActions } from './';

export const measurementActions = {
    getMeasurementServicesByAccountId,
    getConversionDefinitionByAccountId,
    getAudiencesByAccountId,
    updateConversionDefinition,
    createConversionDefinition
};



function getMeasurementServicesByAccountId(accountId) {
    return dispatch => {
        dispatch(request());
        measurementService.getMeasurementServicesByAccountId(accountId)
            .then(
                services => dispatch(success(services)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: measurementConstants.GET_SERVICES_REQUEST } }
    function success(services) { return { type: measurementConstants.GET_SERVICES_SUCCESS, services } }
    function failure(error) { return { type: measurementConstants.GET_SERVICES_FAILURE, error } }
}

function getConversionDefinitionByAccountId(accountId) {
    return dispatch => {
        dispatch(request());
        measurementService.getConversionDefinitionByAccountId(accountId)
            .then(
                conversion_definition => dispatch(success(conversion_definition)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: measurementConstants.GET_CONVERSION_DEFINITION_REQUEST } }
    function success(conversion_definition) { return { type: measurementConstants.GET_CONVERSION_DEFINITION_SUCCESS, conversion_definition } }
    function failure(error) { return { type: measurementConstants.GET_CONVERSION_DEFINITION_FAILURE, error } }
}

function getAudiencesByAccountId(accountId) {
    return dispatch => {
        dispatch(request());
        measurementService.getAudiencesByAccountId(accountId)
            .then(
                audiences => {
                    dispatch(success(audiences))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: measurementConstants.GET_AUDIENCES_REQUEST } }
    function success(audiences) { return { type: measurementConstants.GET_AUDIENCES_SUCCESS, audiences } }
    function failure(error) { return { type: measurementConstants.GET_AUDIENCES_FAILURE, error } }
}


function updateConversionDefinition(data, accountId) {
    const _accountId = accountId;
    return dispatch => {
        const totalProcess = 2; //Note: 2 because we need to first we need to update. Then second we need to retrieve again from local db for latest copy
        let completedProcess = 0
        dispatch(request());
        dispatch(alertActions.loading("Saving..."));

        completedProcess += 1;
        dispatch(progressActions.update(completedProcess, totalProcess));
        measurementService.updateConversionDefinition(data)
            .then(
                conversionDefinition => {
                    measurementService.getConversionDefinitionByAccountId(_accountId)
                    .then(
                        conversionDefinition => {
                            completedProcess = totalProcess;
                            dispatch(alertActions.success(`${data.name} is updated`));
                            dispatch(success(conversionDefinition))
                        },
                        error => dispatch(failure(error.toString()))
                    );
                },
                error => {
                    completedProcess = totalProcess;
                    dispatch(progressActions.update(completedProcess, totalProcess));
                    dispatch(alertActions.error(error));
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: measurementConstants.UPDATE_REQUEST } }
    function success(conversionDefinition) { return { type: measurementConstants.UPDATE_SUCCESS, conversionDefinition } }
    function failure(error) { return { type: measurementConstants.UPDATE_FAILURE, error } }
}

function createConversionDefinition(data, accountId) {
    const _accountId = accountId;
    return dispatch => {
        const totalProcess = 2; //Note: 2 because we need to first we need to create. Then second we need to retrieve again from local db for latest copy
        let completedProcess = 0
        dispatch(request());
        dispatch(alertActions.loading("Adding Conversion Definition..."));

        completedProcess += 1;
        dispatch(progressActions.update(completedProcess, totalProcess));

        measurementService.createConversionDefinition(data)
            .then(
                conversionDefinition => {
                measurementService.getConversionDefinitionByAccountId(_accountId)
                    .then(
                        conversionDefinition => {
                            dispatch(alertActions.success(`${data.name} is created`));
                            dispatch(success(conversionDefinition))
                        },
                        error => dispatch(failure(error.toString()))
                    );
                },
                error => {
                    completedProcess = totalProcess;
                    dispatch(progressActions.update(completedProcess, totalProcess));
                    dispatch(alertActions.error(error));
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: measurementConstants.CREATE_REQUEST } }
    function success(conversionDefinition) { return { type: measurementConstants.CREATE_SUCCESS, conversionDefinition } }
    function failure(error) { return { type: measurementConstants.CREATE_FAILURE, error } }
}