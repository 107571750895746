import { audienceConstants } from '../_constants';
import { audienceService } from '../_services';
import { alertActions, progressActions } from './';
import { history } from '../_helpers';

export const audienceActions = {
    getAudiencesByPixelId,
    updateAudience,
    createAudience,
};



function getAudiencesByPixelId(pixelIds) {
    return dispatch => {
        dispatch(request());
        audienceService.getAudiencesByPixelId(pixelIds)
            .then(
                audiences => dispatch(success(audiences)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: audienceConstants.GET_REQUEST } }
    function success(audiences) { return { type: audienceConstants.GET_SUCCESS, audiences } }
    function failure(error) { return { type: audienceConstants.GET_FAILURE, error } }
}

function createAudience(data, accountId) {
    return dispatch => {
        const totalProcess = 2; //Note: 2 because we need to first we need to create. Then second we need to retrieve again from local db for latest copy
        let completedProcess = 0
        dispatch(request());
        completedProcess += 1;
        dispatch(progressActions.update(completedProcess, totalProcess));

        audienceService.createAudience(data, accountId)
            .then(
                audiences => {
                    dispatch(alertActions.success(`${data.name} is created`));
                    dispatch(success(audiences))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: audienceConstants.CREATE_REQUEST } }
    function success(audiences) { return { type: audienceConstants.CREATE_SUCCESS, audiences } }
    function failure(error) { return { type: audienceConstants.CREATE_FAILURE, error } }
}



function updateAudience(data) {
    return dispatch => {
        const totalProcess = 2; //Note: 2 because we need to first we need to update. Then second we need to retrieve again from local db for latest copy
        let completedProcess = 0
        dispatch(request());

        completedProcess += 1;
        dispatch(progressActions.update(completedProcess, totalProcess));
        audienceService.updateAudience(data)
            .then(
                audiences => {
                    audienceService.getAudiencesByPixelId(data.pixelIds)
                    .then(
                        audiences => {
                            dispatch(alertActions.success(`${data.name} is updated`));
                            dispatch(success(audiences))
                        },
                        error => dispatch(failure(error.toString()))
                    );
                },
                error => {
                    completedProcess = totalProcess;
                    dispatch(progressActions.update(completedProcess, totalProcess));
                    dispatch(alertActions.error(error));
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: audienceConstants.UPDATE_REQUEST } }
    function success(audiences) { return { type: audienceConstants.UPDATE_SUCCESS, audiences } }
    function failure(error) { return { type: audienceConstants.UPDATE_FAILURE, error } }
}