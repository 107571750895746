import { pixelConstants } from '../_constants';
import { pixelService } from '../_services';
import { alertActions, progressActions } from './';
import { history } from '../_helpers';

export const pixelActions = {
    getPixelsByAccountId,
    updatePixel,
    createPixel
};



function getPixelsByAccountId(accountId) {
    return dispatch => {
        dispatch(request());

        pixelService.getPixelsByAccountId(accountId)
            .then(
                pixels => dispatch(success(pixels)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: pixelConstants.GET_REQUEST } }
    function success(pixels) { return { type: pixelConstants.GET_SUCCESS, pixels } }
    function failure(error) { return { type: pixelConstants.GET_FAILURE, error } }
}

function updatePixel(data) {
    return dispatch => {
        const totalProcess = 2; //Note: 2 because we need to first we need to update. Then second we need to retrieve again from local db for latest copy
        let completedProcess = 0
        dispatch(request());
        dispatch(alertActions.loading("Saving..."));

        completedProcess += 1;
        dispatch(progressActions.update(completedProcess, totalProcess));
        pixelService.updatePixel(data)
            .then(
                pixels => {
                    pixelService.getPixelsByAccountId(data.accountId)
                    .then(
                        pixels => {
                            completedProcess = totalProcess;
                            dispatch(alertActions.success(`${data.name} is updated`));
                            dispatch(success(pixels))
                        },
                        error => dispatch(failure(error.toString()))
                    );
                },
                error => {
                    completedProcess = totalProcess;
                    dispatch(progressActions.update(completedProcess, totalProcess));
                    dispatch(alertActions.error(error));
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: pixelConstants.UPDATE_REQUEST } }
    function success(pixels) { return { type: pixelConstants.UPDATE_SUCCESS, pixels } }
    function failure(error) { return { type: pixelConstants.UPDATE_FAILURE, error } }
}

function createPixel(data) {
    return dispatch => {
        const totalProcess = 2; //Note: 2 because we need to first we need to create. Then second we need to retrieve again from local db for latest copy
        let completedProcess = 0
        dispatch(request());
        dispatch(alertActions.loading("Adding Conversion Definition..."));

        completedProcess += 1;
        dispatch(progressActions.update(completedProcess, totalProcess));

        pixelService.createPixel(data)
            .then(pixels => {
                pixelService.getPixelsByAccountId(data.accountId)
                    .then(
                        pixels => {
                            dispatch(alertActions.success(`${data.name} is created`));
                            dispatch(success(pixels))
                        },
                        error => dispatch(failure(error.toString()))
                    );
                },
                error => {
                    completedProcess = totalProcess;
                    dispatch(progressActions.update(completedProcess, totalProcess));
                    dispatch(alertActions.error(error));
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: pixelConstants.CREATE_REQUEST } }
    function success(pixels) { return { type: pixelConstants.CREATE_SUCCESS, pixels } }
    function failure(error) { return { type: pixelConstants.CREATE_FAILURE, error } }
}