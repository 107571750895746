export const publisherConstants = {
    GET_ACCOUNT_REQUEST: 'PUBLISHER_GET_ACCOUNT_REQUEST',
    GET_ACCOUNT_SUCCESS: 'PUBLISHER_GET_ACCOUNT_SUCCESS',
    GET_ACCOUNT_FAILURE: 'PUBLISHER_GET_ACCOUNT_FAILURE',

    GET_CAMPAIGN_REQUEST: 'PUBLISHER_GET_CAMPAIGN_REQUEST',
    GET_CAMPAIGN_SUCCESS: 'PUBLISHER_GET_CAMPAIGN_SUCCESS',
    GET_CAMPAIGN_FAILURE: 'PUBLISHER_GET_CAMPAIGN_FAILURE',
    
    GET_ADGROUP_REQUEST: 'PUBLISHER_GET_ADGROUP_REQUEST',
    GET_ADGROUP_SUCCESS: 'PUBLISHER_GET_ADGROUP_SUCCESS',
    GET_ADGROUP_FAILURE: 'PUBLISHER_GET_ADGROUP_FAILURE',

    GET_TOTAL_PER_CAMPAIGN_REQUEST: 'PUBLISHER_GET_TOTAL_PER_CAMPAIGN_REQUEST',
    GET_TOTAL_PER_CAMPAIGN_SUCCESS: 'PUBLISHER_GET_TOTAL_PER_CAMPAIGN_SUCCESS',
    GET_TOTAL_PER_CAMPAIGN_FAILURE: 'PUBLISHER_GET_TOTAL_PER_CAMPAIGN_FAILURE',
    
    GET_TOTAL_PER_ADGROUP_REQUEST: 'PUBLISHER_GET_TOTAL_PER_ADGROUP_REQUEST',
    GET_TOTAL_PER_ADGROUP_SUCCESS: 'PUBLISHER_GET_TOTAL_PER_ADGROUP_SUCCESS',
    GET_TOTAL_PER_ADGROUP_FAILURE: 'PUBLISHER_GET_TOTAL_PER_ADGROUP_FAILURE',
    
    GET_TOTAL_PER_CREATIVE_REQUEST: 'PUBLISHER_GET_TOTAL_PER_CREATIVE_REQUEST',
    GET_TOTAL_PER_CREATIVE_SUCCESS: 'PUBLISHER_GET_TOTAL_PER_CREATIVE_SUCCESS',
    GET_TOTAL_PER_CREATIVE_FAILURE: 'PUBLISHER_GET_TOTAL_PER_CREATIVE_FAILURE',

};
