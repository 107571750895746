import { geolocationConstants } from '../_constants';

export function geolocations(state = {}, action) {
  switch (action.type) {
    //=====================================
    // GET INCLUDED PUSH COUNTRIES
    //=====================================
    case geolocationConstants.GET_INCLUDED_PUSH_COUNTRIES_REQUEST:
      return {
        ...state,
        is_included_countries_done: false
      };
    case geolocationConstants.GET_INCLUDED_PUSH_COUNTRIES_SUCCESS:
      return {
        ...state,
        included_countries: action.geolocations,
        is_included_countries_done: true
      };
    case geolocationConstants.GET_INCLUDED_PUSH_COUNTRIES_FAILURE:
      return { 
        ...state,
        error: action.error,
        is_included_countries_done: true
      };
    //=====================================
    // GET INCLUDED PUSH CITIES
    //=====================================
    case geolocationConstants.GET_INCLUDED_PUSH_CITIES_REQUEST:
      return {
        ...state,
        is_included_cities_done: false
      };
    case geolocationConstants.GET_INCLUDED_PUSH_CITIES_SUCCESS:
      return {
        ...state,
        included_cities: action.geolocations,
        is_included_cities_done: true,
      };
    case geolocationConstants.GET_INCLUDED_PUSH_CITIES_FAILURE:
      return { 
        ...state,
        error: action.error,
        is_included_cities_done: true
      };
    //=====================================
    // GET INCLUDED PUSH REGIONS
    //=====================================
    case geolocationConstants.GET_INCLUDED_PUSH_REGIONS_REQUEST:
      return {
        ...state,
        is_included_regions_done: false
      };
    case geolocationConstants.GET_INCLUDED_PUSH_REGIONS_SUCCESS:
      return {
        ...state,
        included_regions: action.geolocations,
        is_included_regions_done: true
      };
    case geolocationConstants.GET_INCLUDED_PUSH_REGIONS_FAILURE:
      return { 
        ...state,
        error: action.error,
        is_included_regions_done: true
      };
    //=====================================
    // GET INCLUDED
    //=====================================
    case geolocationConstants.GET_INCLUDED_CITIES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case geolocationConstants.GET_INCLUDED_CITIES_SUCCESS:
      return {
        ...state,
        included: action.geolocations
      };
    case geolocationConstants.GET_INCLUDED_CITIES_FAILURE:
      return { 
        ...state,
        error: action.error
      };
    //=====================================
    // GET EXCLUDED
    //=====================================
    case geolocationConstants.GET_EXCLUDED_CITIES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case geolocationConstants.GET_EXCLUDED_CITIES_SUCCESS:
      return {
        ...state,
        excluded: action.geolocations
      };
    case geolocationConstants.GET_EXCLUDED_CITIES_FAILURE:
      return { 
        ...state,
        error: action.error
      };
    //=====================================
    // FIND CITIES
    //=====================================
    case geolocationConstants.GET_CITIES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case geolocationConstants.GET_CITIES_SUCCESS:
      return {
        ...state,
        cities_found: action.geolocations
      };
    case geolocationConstants.GET_CITIES_FAILURE:
      return { 
        ...state,
        error: action.error
      };
    //=====================================
    // GET GOOGLE COORDINATES
    //=====================================
    case geolocationConstants.GET_GOOGLE_COORDINATES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case geolocationConstants.GET_GOOGLE_COORDINATES_SUCCESS:
      return {
        ...state,
        googlemap: action.googlemap
      };
    case geolocationConstants.GET_GOOGLE_COORDINATES_FAILURE:
      return { 
        ...state,
        error: action.error
      };
    //=====================================
    // DEFAULT
    //=====================================
    default:
      return state
  }
}