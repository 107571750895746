import { creativesPendingConstants, creativeConstants } from '../_constants';
import { creatives } from '../_reducers/creatives.reducer';
import { creativeService, creativesPendingService } from '../_services';
import { alertActions } from './';

export const creativesPendingActions = {
    getCreativesPending,
    addCreativesPending,
    submitCreative,         // Submit to Zemanta
    checkUploadStatus,      // Check submission status
};



function getCreativesPending() {
    return dispatch => {
        dispatch(request());

        creativesPendingService.getCreativesPending()
            .then(
                creativesPending => dispatch(success(creativesPending)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: creativesPendingConstants.GET_REQUEST } }
    function success(creativesPending) { return { type: creativesPendingConstants.GET_SUCCESS, creativesPending } }
    function failure(error) { return { type: creativesPendingConstants.GET_FAILURE, error } }
}


function addCreativesPending(creatives) {
    return dispatch => {
        dispatch(request(creatives));
        dispatch(alertActions.loading("Saving..."));
        console.log("Saving creative on local");
        let totalProcess            = creatives.length;
        let completedProcess        = 0

        console.log("xxxxxxxxxxxx")
        console.log(creatives)
        console.log("xxxxxxxxxxxx")
        creatives.map(data => {
            creativesPendingService.addCreativesPending(data)
                .then(
                    creatives => {
                        completedProcess += 1;
                        dispatch(success(creatives))
                        if(completedProcess >= totalProcess){
                            dispatch(alertActions.success('Creatives Added'));
                            console.log("DONE: Saving creative on local");
                        }
                    },
                    error => {
                        dispatch(failure(error.toString()))
                    }
                );
        })
    };

    function request(creative) { return { type: creativesPendingConstants.ADD_REQUEST, creative } }
    function success(creatives) { return { type: creativesPendingConstants.ADD_SUCCESS, creatives } }
    function failure(error) { return { type: creativesPendingConstants.ADD_FAILURE, error } }
}

function submitCreative(creative, adGroupId, accountId) {
    return dispatch => {
        dispatch(request(creative));
        dispatch(alertActions.loading("Saving..."));
        let totalProcess            = creative.length
        let completedProcess        = 0
        
        //Note: We only need videoUrl for our local database copy. It should NOT be included when submitting to Zemanta
        if(creative.type == "VIDEO"){
            let videoUrl                = creative.videoUrl
            delete creative.videoUrl
        }

        creativesPendingService.submitCreative(creative, adGroupId)
            .then(
                progress => {
                    progress.timestamp = Date.now(); //Triggers componentDidUpdate
                    dispatch(success_submit_batch(progress))
                    creative.map(data => {
                        data.adGroupId = adGroupId
                        data.batchId = progress.id
                        data.status = "pending"
                        data.accountId = accountId
                        if(creative.type == "VIDEO"){
                            data.videoUrl = videoUrl
                        }
                        creativesPendingService.addCreativesPending(data)
                            .then(
                                creatives => {
                                    completedProcess += 1;
                                    dispatch(success_saving_local(data))
                                    if(completedProcess >= totalProcess){
                                        dispatch(alertActions.success('Creatives Added'));
                                        console.log("DONE: Saving creative on local");
                                    }
                                },
                                error => {
                                    dispatch(failure(error.toString()))
                                }
                            );
                    })
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request(creative) { return { type: creativesPendingConstants.SUBMIT_REQUEST, creative } }
    function success_submit_batch(progress) { return { type: creativesPendingConstants.SUBMIT_SUCCESS, progress } }
    function success_saving_local(creatives) { return { type: creativesPendingConstants.ADD_SUCCESS, creatives } }
    function failure(error) { return { type: creativesPendingConstants.SUBMIT_FAILURE, error } }
}

function checkUploadStatus(batchId, accountId, adGroupId, zemantaAdgroupId) {
    return dispatch => {
        dispatch(request());
        creativesPendingService.checkUploadStatus(batchId, accountId, adGroupId, zemantaAdgroupId)
            .then(
                result => {
                    console.log(result.status)
                    if(result.status == "DONE"){
                        // Commented out because its causing duplicate. (If ever this is needed to be reused checkout getStatus() and connectCreativesToAdgroup() creatives.controller api)
                        // creativesPendingService.connectCreativesToAdgroup(adGroupId, result.creatives[0].id)
                        // location.reload();
                    }
                    // result.timestamp = Date.now(); //Triggers componentDidUpdate
                    dispatch(success(result))
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request(creative) { return { type: creativesPendingConstants.CHECK_UPLOAD_STATUS_REQUEST, creative } }
    function success(progress) { return { type: creativesPendingConstants.CHECK_UPLOAD_STATUS_SUCCESS, progress } }
    // function approved(progress) { return { type: creativeConstants.CHECK_UPLOAD_STATUS_SUCCESS, progress } }
    function failure(error) { return { type: creativesPendingConstants.CHECK_UPLOAD_STATUS_FAILURE, error } }
}