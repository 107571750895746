export const measurementConstants = {
    GET_SERVICES_REQUEST: 'MEASUREMENT_GET_SERVICES_REQUEST',
    GET_SERVICES_SUCCESS: 'MEASUREMENT_GET_SERVICES_SUCCESS',
    GET_SERVICES_FAILURE: 'MEASUREMENT_GET_SERVICES_FAILURE',

    GET_CONVERSION_DEFINITION_REQUEST: 'MEASUREMENT_GET_CONVERSION_DEFINITION_REQUEST',
    GET_CONVERSION_DEFINITION_SUCCESS: 'MEASUREMENT_GET_CONVERSION_DEFINITION_SUCCESS',
    GET_CONVERSION_DEFINITION_FAILURE: 'MEASUREMENT_GET_CONVERSION_DEFINITION_FAILURE',
    
    GET_AUDIENCES_REQUEST: 'MEASUREMENT_GET_AUDIENCES_REQUEST',
    GET_AUDIENCES_SUCCESS: 'MEASUREMENT_GET_AUDIENCES_SUCCESS',
    GET_AUDIENCES_FAILURE: 'MEASUREMENT_GET_AUDIENCES_FAILURE',

    UPDATE_REQUEST: 'CONVERSION_DEFINITION_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'CONVERSION_DEFINITION_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CONVERSION_DEFINITION_UPDATE_FAILURE',
    
    CREATE_REQUEST: 'CONVERSION_DEFINITION_CREATE_REQUEST',
    CREATE_SUCCESS: 'CONVERSION_DEFINITION_CREATE_SUCCESS',
    CREATE_FAILURE: 'CONVERSION_DEFINITION_CREATE_FAILURE',

};
