import { queryConstants } from '../_constants';
import { queryService } from '../_services';
import { alertActions, progressActions } from './';
import { history } from '../_helpers';

export const queryActions = {
    getQueriesByAdgroupID,
};



function getQueriesByAdgroupID(account_id, adgroup_id) {
    return dispatch => {
        dispatch(request());

        queryService.getQueriesByAdgroupID(account_id, adgroup_id)
            .then(
                query => dispatch(success(query)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: queryConstants.GET_REQUEST } }
    function success(query) { return { type: queryConstants.GET_SUCCESS, query } }
    function failure(error) { return { type: queryConstants.GET_FAILURE, error } }
}


