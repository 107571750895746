import config from 'config';
import { authHeader } from '../_helpers';

export const queryService = {
    getQueriesByAdgroupID,
};

function getQueriesByAdgroupID(account_id, adgroup_id) {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json'
        },
    };
    return fetch(`${config.apiURL}/query/${account_id}/${adgroup_id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}