import config from 'config';
import { authHeader } from '../_helpers';

export const ipcService = {
    getIPCByCampaignId,
    getIPCByAdgroupId,
    addIPC,
};

function getIPCByCampaignId(accountId, campaignId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/ipc/${accountId}/${campaignId}`, requestOptions).then(handleResponse);
}

function getIPCByAdgroupId(accountId, campaignId, adgroupId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/ipc/${accountId}/${campaignId}/${adgroupId}`, requestOptions).then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function addIPC(data) {
    data.autopilot = Boolean(data.autopilot)
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/ipc/${data.accountId}/${data.campaignId}/${data.adgroupId}`, requestOptions).then(handleResponse);
}