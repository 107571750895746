import React, { useState, useEffect, Component, useDebugValue } from 'react'
import {BrowserRouter as Router, Link } from "react-router-dom";

//Redux
import { connect } from 'react-redux';
import { 
    campaignActions,
    userActions,
    publisherGroupActions,
    accountActions,
    adgroupActions,
} from '../_actions';


//Components
import {TopNavigation} from '../_components/TopNavigation'
import {Submenu} from '../_components/Submenu'
import {Footer} from '../_components/Footer';
import {LeftNavigation} from '../_components/LeftNavigation'


//Tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


//Helpers
import '../_helpers/sort-by.js'

//CSV Download
import { CSVLink, CSVDownload } from "react-csv";

const PublisherManagement = (props) => {
    const { user, users, publishergroups, accounts, campaigns, adgroups} = props;
    const accountId = user.accountId

    const useStateWithLabel = (initialValue, label) => {
        const [value, setValue] = useState(initialValue);
        useDebugValue(`${label}: ${value}`);
        return [value, setValue];
    };

    const [isConnectionWindowVisible, setIsConnectionWindowVisible] = useStateWithLabel(false, "isConnectionWindowVisible");
    const [isPublisherGroupWindowVisible, setIsPublisherGroupWindowVisible] = useStateWithLabel(false, "isPublisherGroupWindowVisible");
    const [isEditingForm, setIsEditingForm] = useStateWithLabel(false, "setIsEditingForm");
    const [connections, setConnections] = useStateWithLabel([], "connections");
    const [listName, setListName] = useStateWithLabel([], "listName"); //Note: Publisher "Group" is also known as Publisher "List"
    const [selectedPublisherGroupId, setSelectedPublisherGroupId] = useStateWithLabel(null, "selectedPublisherGroupId");
    const [entries, setEntries] = useStateWithLabel(null, "entries");
    const [includeSubdomains, setIncludeSubdomains] = useStateWithLabel(true, "includeSubdomains");
    const [hasAttachment, setHasAttachment] = useStateWithLabel(false, "hasAttachment");
    const [error, setError] = useStateWithLabel(null, "error");
    const [csvFilename, setCSVFilename] = useStateWithLabel("", "csvFilename");

    
    
    useEffect(() => {
        console.log("xxxxxxxxxx")
        console.log(accountId)
        console.log("xxxxxxxxxx")
        props.getPublisherGroups(accountId)
        props.getAccountById(accountId)
        props.getCampaigns(accountId);
        props.getAllAdgroups(accountId)
    },[]);

    const deletePublisherGroup = (publisherGroupId, accountId, publisherName) => {
        confirm(`Are you sure you want to delete ${publisherName} ?`) && props.deletePublisherGroup(publisherGroupId, accountId, publisherName)
    }

    const showNewForm = () => {
        setCSVFilename("")
        setIncludeSubdomains(true);
        setHasAttachment(false);
        setIsPublisherGroupWindowVisible(true)
        setListName("")
        setIsEditingForm(false)
    }
    
    const showEditForm = (publisherGroupId) => {
        setHasAttachment(false);
        setCSVFilename("")
        setSelectedPublisherGroupId(publisherGroupId)
        setIsEditingForm(true)
        setListName(publishergroups.items.filter(publishergroup => publishergroup.id == publisherGroupId)[0].name)
        setIsPublisherGroupWindowVisible(true)
        
    }

    const handleCSV2Array = (event) => {
        var reader = new FileReader();
        reader.onloadend = () => {
            setHasAttachment(true);
            setError(null);
            let separator = reader.result.includes("\r") ? "\r\n" : "\n";
            let csvData = reader.result.replaceAll('"', '').split(separator);
            let arrData = [];
            const totalColumns = 3;
            for (let i in csvData) {
                // data[i] = data[i].split(",");
                try{
                    let row = csvData[i].split(",")
                    if(row.length == totalColumns && i > 0){
                        arrData.push({
                            publisher:row[0],
                            placement:row[1] == "" ? null : row[1],
                            source:row[2] == "" ? null : row[2]
                        })
                    }
                }catch(e){
                }
            }
            setEntries(arrData);
        }
        setCSVFilename(event.target.files[0].name)
        reader.readAsText(event.target.files[0]);
    }

    const updatePublisherGroup = (id, accountId, name) => {
        let data = {
            id: id, 
            accountId: accountId, 
            name: name,
            entries:entries
        }
        props.updatePublisherGroup(data)
        setIsPublisherGroupWindowVisible(false)
    }

    const createPublisherGroup = (accountId, name) => {
        if(!hasAttachment){
            setError("Please choose a file to upload")
            return;
        }
        let data = {
            accountId: accountId, 
            name: name,
            entries:entries,
            includeSubdomains:includeSubdomains
        }
        props.createPublisherGroup(data)
        setIsPublisherGroupWindowVisible(false)
    }

    const removeCSVAttachment = () => {
        setEntries(null);
        setHasAttachment(false)
    }

    const showConnections = (publisherGroupId) => {
        setIsConnectionWindowVisible(true)
        setSelectedPublisherGroupId(publisherGroupId)
        let connections = [];
        
        //Get all connected adgroup in whitelist 
        if(adgroups.items && adgroups.items.filter(adgroup => adgroup.targeting.publisherGroups.included.includes(parseInt(publisherGroupId))).length > 0){
            connections.push(adgroups.items.filter(adgroup => adgroup.targeting.publisherGroups.included.includes(parseInt(publisherGroupId))).map(item => {
                return {
                    usedOn:`Adgroup: ${item.name}`, 
                    usedAs:"Whitelist"
                }
            }))

        }
        
        
        //Get all connected adgroup in blacklist 
        if(adgroups.items && adgroups.items.filter(adgroup => adgroup.targeting.publisherGroups.excluded.includes(parseInt(publisherGroupId))).length > 0){
            connections.push(adgroups.items.filter(adgroup => adgroup.targeting.publisherGroups.excluded.includes(parseInt(publisherGroupId))).map(item => {
                return {
                    usedOn:`Adgroup: ${item.name}`, 
                    usedAs:"Blacklist"
                }
            }))

        }

        //Get all connected campaign in whitelist 
        if(campaigns.items && campaigns.items.filter(campaign => campaign.targeting.publisherGroups.included.includes(parseInt(publisherGroupId))).length > 0){
            connections.push(campaigns.items.filter(campaign => campaign.targeting.publisherGroups.included.includes(parseInt(publisherGroupId))).map(item => {
                return {
                    usedOn:`Adgroup: ${item.name}`, 
                    usedAs:"Whitelist"
                }
            }))

        }

        //Get all connected campaign in blacklist 
        if(campaigns.items && campaigns.items.filter(campaign => campaign.targeting.publisherGroups.excluded.includes(parseInt(publisherGroupId))).length > 0){
            connections.push(campaigns.items.filter(campaign => campaign.targeting.publisherGroups.excluded.includes(parseInt(publisherGroupId))).map(item => {
                return {
                    usedOn:`Adgroup: ${item.name}`, 
                    usedAs:"Blacklist"
                }
            }))
        }
        setConnections(connections)
    }

    let countPublisherGroupRows = publishergroups.items && publishergroups.items.length

    return (
        <div className="publisher-management">

            {
                /* CONNECTION WINDOW */
                isConnectionWindowVisible && 
                <div className="backdrop">
                    <div className="window">
                        <div className="title">
                            Publisher &amp; placement group use: 
                            <span className="highlight">
                            {
                                selectedPublisherGroupId !== null && publishergroups.items && publishergroups.items.filter(publishergroup => publishergroup.id == selectedPublisherGroupId).map(item => {
                                    return item.name
                                })
                            }
                            </span>
                        </div>
                        <div className="body">
                            <div className="sub-title">Here you can review all accounts, campaigns, ad groups and automation rules that use publisher group Test as a whitelist, blacklist or add to list.</div>
                            {
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Used on</th>
                                            <th>Used as</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            connections.length > 0 ?
                                                connections.map(item => item.map((item, index) => 
                                                    <tr key={index}>
                                                        <td>{item.usedOn}</td>
                                                        <td>{item.usedAs}</td>
                                                    </tr>
                                                ))
                                            :
                                            <tr>
                                                <td className="text-center" colspan="2">No data available</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            }
                        </div>
                        <div className="footer">
                            <button className="btn btn-xs btn-secondary" onClick={() => setIsConnectionWindowVisible(false)}>Close</button>
                        </div>
                    </div>
                </div>
            }

            {
                /* PUBLISHERGROUP FORM */
                isPublisherGroupWindowVisible && 
                <div className="backdrop">
                    <div className="window">
                        <div className="title">
                            {isEditingForm ? "Edit" : "Create"} Publishers &amp; Placement list
                        </div>
                        <div className="body">
                            <section>
                                <div className="form-group">
                                    <label>List name</label>
                                    <div className="content">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            value = {listName} 
                                            name="name" 
                                            onChange={event => setListName(event.target.value)}
                                            />
                                    </div>
                                </div>
                                <div className="form-group d-none">
                                    <label>Include subdomains</label>
                                    <div className="content">
                                        {
                                            publishergroups.items.some(publishergroup => publishergroup.id == selectedPublisherGroupId) ?
                                                publishergroups.items.filter(publishergroup => publishergroup.id == selectedPublisherGroupId).map(item => 
                                                    item.entries && item.entries.length >= 0 && item.entries.some(item => item.includeSubdomains == true)
                                                    ? <input type="checkbox" name="includeSubdomains" defaultChecked={true} onChange={e => setIncludeSubdomains(e.target.checked)}/>
                                                    : <input type="checkbox" name="includeSubdomains" defaultChecked={false} onChange={e => setIncludeSubdomains(e.target.checked)}/>
                                                )
                                            :
                                                <input type="checkbox" name="includeSubdomains" defaultChecked={true} onChange={e => setIncludeSubdomains(e.target.checked)}/>
                                        }
                                        <div className="info">
                                            <i className="fa fa-question-circle" aria-hidden="true"></i>
                                            The domains should be listed without prefixes http://, https:// or www.
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>CSV {hasAttachment}</label>
                                    <div className="content">
                                        {
                                            hasAttachment ?
                                                <div className="selected-csv">
                                                    <div className='file'>
                                                        <div className='remove' onClick={e => removeCSVAttachment()}><i className="fa fa-times"></i></div>
                                                        <div className='name'>{csvFilename}</div>
                                                    </div>
                                                </div>
                                            :
                                                <input 
                                                    type="file" 
                                                    name="imageUrl"
                                                    multiple={false}
                                                    value=""
                                                    accept=".csv"
                                                    onChange={event => handleCSV2Array(event)}/>
                                        }
                                        {
                                            error != null &&
                                            <div className='error-missing-csv'><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {error}</div>
                                        }
                                        <div className="info">
                                            <i className="fa fa-question-circle" aria-hidden="true"></i>
                                            <p>You can omit the file if you would only like to update the name of the publisher group.</p>
                                            <a href="/public/downloads/publisher_group_example.csv">Download CSV example file</a><br/>
                                            {/* <a href="/public/downloads/publisher_group_example.xlsx">Download Excel example file</a> */}
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="footer">
                            {
                                isEditingForm 
                                ? <button className="btn btn-xs btn-primary" type="submit" onClick={() => updatePublisherGroup(selectedPublisherGroupId, accountId, listName)}><i className="fa fa-check" aria-hidden="true"></i> Save</button>
                                : <button className="btn btn-xs btn-primary" type="submit" onClick={() => createPublisherGroup(accountId, listName)}><i className="fa fa-check" aria-hidden="true"></i> Create</button>
                                    
                            }
                            <button className="btn btn-xs btn-secondary" onClick={() => setIsPublisherGroupWindowVisible(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            }



            <LeftNavigation />
            <div className="canvas">
                <div className={`custom-panel creativelib table full-height`}>
                    <div className="header">
                        Custom Whitelist &amp; Blacklist
                        {
                            countPublisherGroupRows !== undefined && <div className="total">Found: {countPublisherGroupRows.toLocaleString()}</div>
                        }
                        <div className="subtitle">Publishers &amp; placements allow you to upload lists of publishers or placements and blacklist or whitelist them in account, campaign or ad group settings.</div>
                        <div className="controls">
                        <button type="button" className={`btn btn-primary btn-xs btn-pagination`} onClick={() => showNewForm()}>
                            <i className="fa fa-plus-circle" aria-hidden="true"></i> Add New List
                        </button>
                        </div>
                    </div>
                    <table className="home primary fixed-header has-hover"> 
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Subdomains Included</th>
                                    <th>Number of publishers/placements</th>
                                    <th>Scope</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    publishergroups.items && publishergroups.items.map((publishergroup, index) =>
                                    <tr key={index}>
                                        <td>{publishergroup.id}</td>
                                        <td>{publishergroup.name}</td>
                                        <td>{
                                                (publishergroup.entries && publishergroup.entries.length >= 0 && publishergroup.entries.some(item => item.includeSubdomains == true)) 
                                                ? "Yes"
                                                : "No"
                                            }
                                        </td>
                                        <td>{publishergroup.entries && publishergroup.entries.length > 0 ? publishergroup.entries.length : 0}</td>
                                        <td>{`Account (${accounts.items && accounts.items[0].name})`}</td>
                                        <td>
                                            {
                                                publishergroup.entries && 
                                                <OverlayTrigger placement="top" overlay={<Tooltip><b>Download CSV</b></Tooltip>}>
                                                    <CSVLink filename={`publisher-group ${publishergroup.name}.csv`} data={
                                                        [
                                                            [
                                                            "Publisher",
                                                            "Placement",
                                                            "Source",
                                                            ]
                                                            ].concat(publishergroup.entries.map((entry) => {
                                                                return [
                                                                    entry.publisher,
                                                                    entry.placement,
                                                                    entry.source,
                                                                ]
                                                        }))
                                                    } className="btn btn-primary btn-xs btn-actions btn-pagination mr-3"><i className="fa fa-file-excel-o" aria-hidden="true"></i></CSVLink>
                                                </OverlayTrigger>
                                            }
                                            <OverlayTrigger placement="top" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                <button type="button" className="btn btn-primary btn-xs btn-actions btn-pagination mr-3" onClick={() => showEditForm(publishergroup.id)}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                            </OverlayTrigger>
                                            <OverlayTrigger placement="top" overlay={<Tooltip><b>Delete</b></Tooltip>}>
                                                <button type="button" className="btn btn-primary btn-xs btn-actions btn-pagination mr-3" onClick={() => deletePublisherGroup(publishergroup.id, accountId, publishergroup.name)}><i className="fa fa-times" aria-hidden="true"></i></button>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                </div>

                {/* HIDDEN FOR NOW */}
                <div className={`d-none custom-panel creativelib table`}>
                    <div className="header">
                        System Publisher &amp; placements lists
                        <div className="subtitle">Publishers and placements that you blacklist in the dashboard are added to one of the system blacklists listed below.</div>
                    </div>
                    <table className="home primary fixed-header has-hover"> 
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Level</th>
                                    <th>Status</th>
                                    <th>Subdomains Included</th>
                                    <th>Number of publishers/placements</th>
                                    <th>Modified</th>
                                    <th>Created</th>
                                    <th>Scope</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                </div>
            </div>
            

            <Footer />
        </div>
    )
}

const mapState = state => ({
    user:state.users.current,
    users:state.users,
    accounts:state.accounts,
    adgroups:state.adgroups,
    campaigns:state.campaigns,
    publishergroups:state.publishergroups,
})

const actionCreators = {
    getCampaigns: campaignActions.getCampaigns,
    getAllAdgroups: adgroupActions.getAllAdgroups,
    getPublisherGroups: publisherGroupActions.getPublisherGroups,
    deletePublisherGroup: publisherGroupActions.deletePublisherGroup,
    updatePublisherGroup: publisherGroupActions.updatePublisherGroup,
    createPublisherGroup: publisherGroupActions.createPublisherGroup,
    getAccountById: accountActions.getAccountById,
}

const connectedPublisherManagement = connect(mapState, actionCreators)(PublisherManagement);

export { connectedPublisherManagement as PublisherManagement };