import config from 'config';
import React, { useDebugValue, useEffect, useRef, useState } from 'react';

//Daterange picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Tree Select
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';

//Rredux
import { connect } from 'react-redux';

//React Select
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import {
    accountSupernovaActions,
    adgroupActions,
    bluekaiActions,
    dmpActions,
    geolocationActions,
    measurementActions,
    sideDrawerActions,
    marketingFunnelActions,
    campaignActions,
    duplicatesActions,
    ipcActions,
    queryActions
} from '../../_actions';

//React bootstrap
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

//Constant Data
import { 
    listMarketingFunnels, 
    listBrowsers, 
    listConnectionTypes, 
    listDevices, 
    listEnvironments, 
    listInterest, 
    listOperatingSystems,
    listDeviceMake,
    listDeviceCarrier,
} from '../../_constants/data';

//Moment JS
import moment from 'moment';

//Select
import { styleSelectMultiple } from '../../_constants/settings';

//Helpers
import '../../_helpers/sort-by.js';

//Animated
import { Animated } from "react-animated-css";

//Tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


//Tag Input Field
import { TagsInput } from "react-tag-input-component";
import { SideDrawer } from '../SideDrawer';
import { marketingFunnel } from '../../_reducers/marketingFunnel.reducer';

//Toggle
import Toggle from 'react-toggle'
import '../../_stylesheets/css/react-toggle.css'

//Google Map

const AdgroupForm = (props) => {

    const { 
        isAutoPilot, 
        duplicates, 
        marketingFunnel, 
        bluekai, 
        dmp, 
        adgroups, 
        sideDrawer, 
        geolocations, 
        campaignId, 
        supernovaCampaignId,
        user, 
        accountsSupernova, 
        campaignType, 
        measurement, 
        campaigns, 
        allAdgroups, 
        ipc,
        query
    } = props

    const refs = useRef({});

    const useStateWithLabel = (initialValue, label) => {
        const [value, setValue] = useState(initialValue);
        useDebugValue(`${label}: ${value}`);
        return [value, setValue];
    };

    //Day Parting
    const hour_list = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]
    const day_list = ["monday","tuesday","wednesday","thursday","friday","saturday","sunday"]
    const [dayPartingAction, setDayPartingAction]   = useStateWithLabel(null, "dayPartingAction");  // Determines wether the selected hour should be Included or Excluded
    const [newDayParting, setNewDayParting]         = useStateWithLabel({
        "monday"    : [0,1,2,3,4,5,6,7,8,9,10,11,16,17,18,19,20,21,22,23], //Note by default we dont include 12am - 3pm
        "tuesday"   : [0,1,2,3,4,5,6,7,8,9,10,11,16,17,18,19,20,21,22,23],
        "wednesday" : [0,1,2,3,4,5,6,7,8,9,10,11,16,17,18,19,20,21,22,23],
        "thursday"  : [0,1,2,3,4,5,6,7,8,9,10,11,16,17,18,19,20,21,22,23],
        "friday"    : [0,1,2,3,4,5,6,7,8,9,10,11,16,17,18,19,20,21,22,23],
        "saturday"  : [0,1,2,3,4,5,6,7,8,9,10,11,16,17,18,19,20,21,22,23],
        "sunday"    : [0,1,2,3,4,5,6,7,8,9,10,11,16,17,18,19,20,21,22,23],
        "timezone"  : "America/New_York"
    }, "newDayParting");
    const [isMouseDown, setIsMouseDown] = useStateWithLabel(false, "isMouseDown");
    useEffect(() => {
        // Add event listeners to the document
        document.addEventListener('mousemove', handleDayParting);
        document.addEventListener('mouseup', handleMouseUp);
    
        // Clean up event listeners on component unmount
        return () => {
          document.removeEventListener('mousemove', handleDayParting);
          document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isMouseDown]);
    
 


    //Marketing Funnel (saved locally)
    const [marketingFunnelValue, setMarketingFunnelValue] = useStateWithLabel([], "marketingFunnelValue");

    //Multiple Adgroup's Ad Group Name Array
    const [multipleAdgroupObj, setMultipleAdgroupObj] = useStateWithLabel([], "multipleAdgroupObj");

    //Used for react bootstrap tabs
    const [locationTabKey, setLocationTabKey] = useStateWithLabel("included", "locationTabKey");

    //For delaying input search so it doesnt send searches while still typing
    const [inputDelay, setInputDelay] = useStateWithLabel(false, "inputDelay");
    // const [capturedInput, setCapturedInput] = useStateWithLabel("", "capturedInput");
    let capturedInput = ""

    //Wether to show first page 1 of multiple ad group creation or page 2
    const [multipleAdgroupFormPage, setMultipleAdgroupFormPage] = useStateWithLabel(1, "multipleAdgroupFormPage");
    
    //Holds the Dropdown list items (Supernova)
    const [optionDeviceMake, setOptionDeviceMake] = useStateWithLabel([], "optionDeviceMake");
    const [optionDeviceCarrier, setOptionDeviceCarrier] = useStateWithLabel([], "optionDeviceCarrier");
    
    
    //Holds the Dropdown list items (ZM)
    const [optionDevices, setOptionDevices] = useStateWithLabel([], "optionDevices");
    const [optionConnectionTypes, setOptionConnectionTypes] = useStateWithLabel([], "optionConnectionTypes");
    const [optionOperatingSystem, setOptionOperatingSystem] = useStateWithLabel([], "optionOperatingSystem");
    const [optionEnvironments, setOptionEnvironments] = useStateWithLabel([], "optionEnvironments");
    const [optionBrowsers, setOptionBrowsers] = useStateWithLabel([], "optionBrowsers");
    
    const [whiteListedBluekai, setWhiteListedBluekai] = useStateWithLabel([], "whiteListedBluekai");
    const [blackListedBluekai, setBlackListedBluekai] = useStateWithLabel([], "blackListedBluekai");
    
    const [whiteListedDmp, setWhiteListedDmp] = useStateWithLabel([], "whiteListedDmp");
    const [blackListedDmp, setBlackListedDmp] = useStateWithLabel([], "blackListedDmp");
    
    const [activeAdgroupNavButton, setActiveAdgroupNavButton] = useStateWithLabel(0, "activeAdgroupNavButton");
    const [isNameFieldVisible, setIsNameFieldVisible] = useStateWithLabel(false, "isNameFieldVisible");
    const [submitted, setSubmitted] = useStateWithLabel(false, "submitted");
    const [inputLocation, setInputLocation] = useStateWithLabel("", "inputLocation");
    const [adgroupRef, setAdgroupRef] = useStateWithLabel([], "adgroupRef");
    
    const [optionBluekaiIncluded, setOptionBluekaiIncluded] = useStateWithLabel([], "optionBluekaiIncluded");
    const [optionBluekaiExcluded, setOptionBluekaiExcluded] = useStateWithLabel([], "optionBluekaiExcluded");
    
    const [optionDmpIncluded, setOptionDmpIncluded] = useStateWithLabel([], "optionDmpIncluded");
    const [optionDmpExcluded, setOptionDmpExcluded] = useStateWithLabel([], "optionDmpExcluded");
    
    const [optionCustomAudiencesIncluded, setOptionCustomAudiencesIncluded] = useStateWithLabel([], "optionCustomAudiencesIncluded");
    const [optionCustomAudiencesExcluded, setOptionCustomAudiencesExcluded] = useStateWithLabel([], "optionCustomAudiencesExcluded");

    const [selectedLocation, setSelectedLocation] = useStateWithLabel(null, "selectedLocation");
    const [isDuplicateCampaignClicked, setIsDuplicateCampaignClicked] = useStateWithLabel(false, "isDuplicateCampaignClicked");

    const [audienceDescription, setAudienceDescription] = useStateWithLabel("", "audienceDescription");
    
    const [defaultBiddingType, setDefaultBiddingType] = useStateWithLabel((
        sideDrawer.purpose == "edit"
        ? adgroups.items && adgroups.items.filter(item => item.supernovaAdgroupId == adgroups.selected).reduce((acc, cur) => cur).biddingType
        : "CPM"
    ), "defaultBiddingType");


    const [ipc_status, setIpc_status] = useStateWithLabel(undefined, "ipc_status");

    //GOOGLE MAP
    const placeId = "ChIJ0zQtYiWsVHkRk8lRoB1RNPo";
    const [googleMapProps, setGoogleMapProps] = useStateWithLabel({
        center: {
          lat: 37.09024,
          lng: -95.712891
        },
        zoom: 1
    }, "googleMapProps");
    const initMap = (center, zoom) => {
        let featureLayer;
        let map = google.maps.Map;
        console.log(center, zoom)
        map = new google.maps.Map(document.getElementById('mapbox'), {
            // center: { lat: 20.773, lng: -156.01 },
            // zoom: 12,
            defaultCenter   : center,
            defaultZoom     : zoom,
            center          : center,
            zoom            : zoom,
            mapId           : '2f7ff894ed1cfa48',
        });

        
      
        


        ////////////////////

        let service = google.maps.places.PlacesService;
        let infowindow = google.maps.InfoWindow;
        let placeId = ""
        let viewport = ""
        
        if(geolocations.googlemap){
                const request = {
                    query: geolocations.googlemap.candidates[0].formatted_address,
                    fields: ["name", "geometry", "place_id"],
                };
                

                service = new google.maps.places.PlacesService(map);
                
                service.findPlaceFromQuery(
                    request,
                    (
                    results = google.maps.places.PlaceResult | null,
                    status = google.maps.places.PlacesServiceStatus
                    ) => {
                        if (status === google.maps.places.PlacesServiceStatus.OK && results) {

                            //Get placeId and ViewPort for boundaries and zoom to focus:
                            for (let i = 0; i < results.length; i++) {
                                viewport = results[i].geometry.viewport
                                placeId = results[i].place_id
                            }
                    

                            //Zoom properly
                            map.fitBounds(viewport)
            
            
                            //Highlight Boundaries:
                            featureLayer = map.getFeatureLayer("LOCALITY");
                            const featureStyleOptions = {
                                strokeColor: "#810FCB",
                                strokeOpacity: 1.0,
                                strokeWeight: 3.0,
                                fillColor: "#810FCB",
                                fillOpacity: 0.5,
                            };
                            featureLayer.style = (options) => {
                                if (options.feature.placeId == placeId) {
                                // Hana, HI
                                return featureStyleOptions;
                                }
                            }
                            
                        }
                    }
                );
        }
        

        //////////////////

    }

    const [adgroup, setAdgroup] = useStateWithLabel((
        sideDrawer.purpose == "edit"
        ? adgroups.items && adgroups.items.filter(item => item.supernovaAdgroupId == adgroups.selected).reduce((acc, cur) => cur)
        : {
            name:"",
            startDate: moment().format('YYYY-MM-DD'),
            endDate: null,
            bid:1,
            biddingType:"CPM",
            dailyBudget: isAutoPilot ? 1 : 0,
            campaignId: campaignId,
            supernovaCampaignId: supernovaCampaignId,
            supernova:{
                supply_targeting:[
                    "MAIN"
                ],
                targeting:{
                    devices_make:[
                        "APPLE",
                        "SAMSUNG",
                        "GOOGLE",
                        "MOTOROLA",
                        "SONY",
                        "LG",
                        "HTC",
                        "NOKIA",
                        "XIAOMI",
                        "ONEPLUS",
                        "HUAWEI",
                        "OPPO",
                        "VIVO",
                        "LENOVO",
                        "ASUS",
                    ],
                    devices_carrier:[
                        "AT&T",
                        "VERIZON",
                        "TMOBILE", 
                        "SPRINT",
                        "USCELLULAR", 
                        "XFINITY",
                        "SPECTRUM",
                        "COX", 
                        "CENTURYLINK",
                        "FRONTIER", 
                        "OPTIMUM",
                        "RCN",
                        "WINDSTREAM",
                        "MEDIACOM",
                        "HUGHESNET",
                        "VIASAT",
                        "GOOGLEFIBER", 
                    ]
                }
            },
            autopilot:{
                "state": "INACTIVE"
            },
            targeting: {
                channels: [],
                connectionTypes: [
                    "CELLULAR",
                    "WIFI"
                ],
                customAudiences: {
                    included: [],
                    excluded: []
                },
                environments: [
                    "APP",
                    "SITE",
                ],
                devices: [
                    "DESKTOP",
                    "MOBILE",
                    "TABLET",
                    "TV",
                ],
                language: {},
                os: [
                    {name: "ANDROID"},
                    {name: "CHROMEOS"},
                    {name: "IOS"},
                    {name: "LINUX"},
                    {name: "MACOSX"},
                    {name: "WINDOWS"},
                    {name: "WINPHONE"},
                    {name: "WINRT"},
                ],
                placements: [],
                geo: {
                    included: {},
                    excluded: {}
                },
                audience:{
                    AND: [],
                    OR: []
                },
                browsers: {
                    included: [
                        {family: "CHROME"},
                        {family: "FIREFOX"},
                        {family: "IE"},
                        {family: "EDGE"},
                        {family: "OPERA"},
                        {family: "SAFARI"},
                        {family: "SAMSUNG"},
                        {family: "UC_BROWSER"}
                    ],
                    excluded: []
                },
                interest: {
                    included: [],
                    excluded: []
                },
                publisherGroups: {
                    included: [],
                    excluded: []
                },
                retargetingAdGroups: {
                    included: [],
                    excluded: []
                },
            }
        }), "adgroup");


    //Vars
    const title = sideDrawer.purpose == "CREATE_SINGLE"  ? "Create an Adgroup" : sideDrawer.purpose == "CREATE_MULTIPLE" ? "Create Multiple Adgroups" : "Edit Adgroup"
    
    
    // let whiteListedBluekai      = []
    // let blackListedBluekai      = []

    let optionLocations         = []

    const data = {
        label: 'search me',
        value: 'searchme',
        children: [
            {
            label: 'search me too',
            value: 'searchmetoo',
            children: [
                {
                label: 'No one can get me',
                value: 'anonymous',
                },
            ],
            },
        ],
    }

    useEffect(() => {

        const { getBluekaiList, getDMP, getIncludedKeys, getExcludedKeys, getASMinBidById, getAudiencesByAccountId, getMarketingFunnel } = props
        

        //Load Google Maps
        initMap(googleMapProps.center, googleMapProps.zoom)

        //Note: Initial value is zero so that we can append comma on cities, dma, regions, etc..
        let includedKeys = "0";
        let excludedKeys = "0"; 

        //1. Get list of INCLUDED & EXCLUDED CITIES for Location targeting dropdowns
        if (adgroup && adgroup.targeting !== undefined && adgroup.targeting?.geo !== undefined && adgroup.targeting?.geo?.included?.cities !== undefined && adgroup.targeting?.geo?.included?.cities.length > 0) { includedKeys += "," + adgroup.targeting?.geo?.included?.cities.toString(); }
        if (adgroup && adgroup.targeting !== undefined && adgroup.targeting?.geo !== undefined && adgroup.targeting?.geo?.excluded?.cities !== undefined && adgroup.targeting?.geo?.excluded?.cities.length > 0) { excludedKeys += "," + adgroup.targeting?.geo?.excluded?.cities.toString(); }

        //2. Get list of INCLUDED & EXCLUDED DMA for Location targeting dropdowns
        if (adgroup && adgroup.targeting !== undefined && adgroup.targeting?.geo !== undefined && adgroup.targeting?.geo?.included?.dma !== undefined && adgroup.targeting?.geo?.included?.dma.length > 0) { includedKeys += "," + adgroup.targeting?.geo?.included?.dma.toString(); }
        if (adgroup && adgroup.targeting !== undefined && adgroup.targeting?.geo !== undefined && adgroup.targeting?.geo?.excluded?.dma !== undefined && adgroup.targeting?.geo?.excluded?.dma.length > 0) { excludedKeys += "," + adgroup.targeting?.geo?.excluded?.dma.toString(); }
        
        //3. Get list of INCLUDED & EXCLUDED REGIONS for Location targeting dropdowns
        if (adgroup && adgroup.targeting !== undefined && adgroup.targeting?.geo !== undefined && adgroup.targeting?.geo?.included?.regions !== undefined && adgroup.targeting?.geo?.included?.regions.length > 0) { includedKeys += "," + adgroup.targeting?.geo?.included?.regions.toString(); }
        if (adgroup && adgroup.targeting !== undefined && adgroup.targeting?.geo !== undefined && adgroup.targeting?.geo?.excluded?.regions !== undefined && adgroup.targeting?.geo?.excluded?.regions.length > 0) { excludedKeys += "," + adgroup.targeting?.geo?.excluded?.regions.toString(); }
        
        //4. Get list of INCLUDED & EXCLUDED COUNTRIES for Location targeting dropdowns
        if (adgroup && adgroup.targeting !== undefined && adgroup.targeting?.geo !== undefined && adgroup.targeting?.geo?.included?.countries !== undefined && adgroup.targeting?.geo?.included?.countries.length > 0) { includedKeys += "," + adgroup.targeting?.geo?.included?.countries.toString(); }
        if (adgroup && adgroup.targeting !== undefined && adgroup.targeting?.geo !== undefined && adgroup.targeting?.geo?.excluded?.countries !== undefined && adgroup.targeting?.geo?.excluded?.countries.length > 0) { excludedKeys += "," + adgroup.targeting?.geo?.excluded?.countries.toString(); }
        
        //5. Get list of INCLUDED & EXCLUDED POSTALCODES for Location targeting dropdowns
        if (adgroup && adgroup.targeting !== undefined && adgroup.targeting?.geo !== undefined && adgroup.targeting?.geo?.included?.postalCodes !== undefined && adgroup.targeting?.geo?.included?.postalCodes.length > 0) { includedKeys += "," + adgroup.targeting?.geo?.included?.postalCodes.toString(); }
        if (adgroup && adgroup.targeting !== undefined && adgroup.targeting?.geo !== undefined && adgroup.targeting?.geo?.excluded?.postalCodes !== undefined && adgroup.targeting?.geo?.excluded?.postalCodes.length > 0) { excludedKeys += "," + adgroup.targeting?.geo?.excluded?.postalCodes.toString(); }
        
        //6. CALL API to fetch all included and excluded location targeting
        getIncludedKeys(includedKeys)
        getExcludedKeys(excludedKeys)
        // getBluekaiList() // Deprecated
        getDMP()

        //7. GET MINIMUM BID
        getASMinBidById(user.accountId)

        //8. Get Conversion List for Retarging Section
        getAudiencesByAccountId(user.accountId)

        //9. Get Marketing Funnel
        console.log(sideDrawer.purpose)
        if(sideDrawer.purpose == "edit"){
            console.log("getting marketing funnel")
            getMarketingFunnel(adgroup.id)
        }

        //10. Get IPC
        props.getIPCByAdgroupId(user.accountId, adgroup.campaignId, adgroup.id)

        //11. Get duplicate props for reference of the duplicated campaigns
        props.getDuplicatedCampaignsByAccountId(user.accountId)


        //12. If it is SUPERNOVA type get the query
        if(isSupernovaAdgroup()){
            props.getQueriesByAdgroupID(user.accountId, adgroup.id)
        }

    },[]);

    useEffect(() => {
        if(query && query.audience_description){
            setAudienceDescription(query.audience_description)
        }
    },[query])

    useEffect(() => {
            if(geolocations.googlemap && geolocations.googlemap.candidates.length > 0){
                setGoogleMapProps({
                    center: {
                      lat: geolocations.googlemap.candidates[0].geometry.location.lat,
                      lng: geolocations.googlemap.candidates[0].geometry.location.lng
                    },
                    zoom: 7
                })

                // const place = autocomplete.getPlace();
                // let searchPlaceIdURL = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${geolocations.googlemap.candidates[0].geometry.location.lat},${geolocations.googlemap.candidates[0].geometry.location.lng}&radius=1500&keyword=${geolocations.googlemap.candidates[0].formatted_address}&types=locality&key=AIzaSyDfuGvx27JlyH4JCQxVMlpD9ZwMlqeT4WQ`
                // console.log("xxxxxxxxxxxxxxxxxxxxxxxx")
                // console.log(searchPlaceIdURL)
                // console.log("xxxxxxxxxxxxxxxxxxxxxxxx")
                // fetch(searchPlaceIdURL).then(response => {console.log(response)})


                // geocoder = new google.maps.Geocoder();


                // const request = {
                //     query: 'Washington',
                //     fields: ['place_id'],
                //   };
                
                // function findPlaceId() {
                //     placesService
                //         .findPlaceFromQuery(request, function (results, status) {
                //             if (status === google.maps.places.PlacesServiceStatus.OK) {
                //                 console.log(results[0]);
                //             }
                //         });
                // }
                
                // findPlaceId()
                

                //Update Map
                initMap(
                    {
                        lat: geolocations.googlemap.candidates[0].geometry.location.lat,
                        lng: geolocations.googlemap.candidates[0].geometry.location.lng
                    }, 
                    7
                )
            }
            
    },[geolocations.googlemap]);


    /************************************************************************************
     * Update state when IPC is updated
     ************************************************************************************/
    useEffect(() => {
        if(ipc && ipc.items && ipc.items.length > 0){
            setIpc_status(ipc.items[0].ipc_status)
        }
    },[ipc,]);

    /************************************************************************************
     * Populate the dropdown of Audience Targeting When bluekai list done loading
     ************************************************************************************/
    useEffect(() => {
        if(bluekai && bluekai.length > 0){
            setOptionBluekaiIncluded(refit_keys_included(bluekai));
            setOptionBluekaiExcluded(refit_keys_excluded(bluekai));
        }
    },[
        bluekai,
    ]);
    //DMP (new)
    useEffect(() => {
        if(dmp && dmp.length > 0){
            setOptionDmpIncluded(refit_keys_included_dmp(dmp));
            setOptionDmpExcluded(refit_keys_excluded_dmp(dmp));
        }
    },[
        dmp,
    ]);


    /************************************************************************************
     * Populate the dropdown of Re Targeting When measurement is done loading
     ************************************************************************************/
    useEffect(() => {
        if(measurement && 
            measurement.conversion_definitions && 
            measurement.conversion_definitions.length > 0 &&
            adgroup &&
            adgroup.targeting && 
            adgroup.targeting?.customAudiences &&
            adgroup.targeting?.customAudiences.included
            ){
            setOptionCustomAudiencesIncluded(
                measurement.audiences.filter(item => !adgroup.targeting?.customAudiences.included.includes(item) ).map(item => {
                    return {
                        label: item.name,
                        value: item.id
                    }
                })
            );
            setOptionCustomAudiencesExcluded(
                measurement.audiences.filter(item => !adgroup.targeting?.customAudiences.included.includes(item) ).map(item => {
                    return {
                        label: item.name,
                        value: item.id
                    }
                })
            );
        }
    },[
        measurement,
    ]);
    
    /************************************************************************************
     * Populate the dropdowns when adgroup list done loading / initializing
     ************************************************************************************/
    useEffect(() => {
        // if(adgroups && adgroups.isDone){
        //     console.log("changed devices")
        //     let chosenDevices = adgroup.targeting?.devices
        //     const filteredBrowsers = listBrowsers.filter(browser =>
        //         browsers.deviceDependency.some(dependency => chosenDevices.includes(dependency))    //browsers
        //     ).map(browser => ({
        //         label: browser.label,
        //         value: { family: browser.value }
        //     }));

        //     //Remove the selected options in the browser that are not available in the selected device
        //     let newSelectedBrowsers = adgroup.targeting?.browsers.included.filter(browser => filteredBrowsers.includes(browser))
        //     console.log("xxxxxxxxxxxxxxx")
        //     console.log(adgroup.targeting?.browsers.included)
        //     console.log("xxxxxxxxxxxxxxx")
        //     setAdgroup({
        //         ...adgroup,
        //         targeting: {
        //             ...adgroup.targeting,
        //             ["browsers"]: {
        //                 ...adgroup.targeting["browsers"],
        //                 // ["included"]: newSelectedBrowsers
        //             }
        //         }
        //     })

        //     //Limit the available options in the browser based on selected Device
        //     setOptionBrowsers(filteredBrowsers)
        // }
    },[
        adgroup?.targeting?.devices,
    ]);
    /************************************************************************************
     * Populate the dropdowns when adgroup list done loading / initializing
     ************************************************************************************/
    useEffect(() => {
        if(adgroups && adgroups.isDone){

            if (adgroup.targeting !== undefined) {

                /*********************************************
                 * 1. Get Targeted Devices
                 *********************************************/
                sideDrawer.purpose == "edit" ?
                    setOptionDevices(
                        listDevices.filter(device => !adgroup.targeting?.devices.includes(device.value)).map((item) => {
                            return {
                                label: item.label,
                                value: item.value
                            }
                    }).sortBy('label'))
                :
                    setOptionDevices(
                        listDevices.map((item) => {
                            return {
                                label: item.label,
                                value: item.value
                            }
                    }).sortBy('label'))
        

                /*********************************************
                 * 2. Get Targeted ConnectionTypes
                 *********************************************/
                sideDrawer.purpose == "edit" ?
                    setOptionConnectionTypes(
                        listConnectionTypes.filter(connectionType => !adgroup.targeting?.connectionTypes.includes(connectionType.value)).map((item) => {
                            return {
                                label: item.label,
                                value: item.value
                            }
                        }).sortBy('label')
                    )
                :
                    setOptionConnectionTypes(
                        listConnectionTypes.map((item) => {
                            return {
                                label: item.label,
                                value: item.value
                            }
                        }).sortBy('label')
                    )
        

                /*********************************************
                 * 3. Get Targeted Environments
                 *********************************************/
                 sideDrawer.purpose == "edit" ?
                    setOptionEnvironments(
                        listEnvironments.filter(environment => !adgroup.targeting?.environments.includes(environment.value)).map((item) => {
                            return {
                                label: item.label,
                                value: item.value
                            }
                        }).sortBy('label')
                    )
                :
                    setOptionEnvironments(
                        listEnvironments.map((item) => {
                            return {
                                label: item.label,
                                value: item.value
                            }
                        }).sortBy('label')
                    )
        

                /*********************************************
                 * 4. Get Targeted OS
                 *********************************************/
                 sideDrawer.purpose == "edit" ?
                    setOptionOperatingSystem(
                        listOperatingSystems.filter(os => !adgroup.targeting?.os.map(os => {return os.name}).includes(os.value)).map((item) => {
                            return {
                                label: item.label,
                                value: {name:item.value}
                            }
                        }).sortBy('label')
                        )
                :
                    setOptionOperatingSystem(
                        listOperatingSystems.map((item) => {
                            return {
                                label: item.label,
                                value: {name:item.value}
                            }
                        }).sortBy('label')
                    )


                /*********************************************
                 * 5. Get Browsers
                 *********************************************/
                 sideDrawer.purpose == "edit" ?
                    setOptionBrowsers(
                        listBrowsers.filter(browser =>
                                adgroup && 
                                !adgroup.targeting?.browsers.included.map(browser => {return browser.family}).includes(browser.value) &&
                                !adgroup.targeting?.browsers.excluded.map(browser => {return browser.family}).includes(browser.value)
                            ).map((item) => {
                            return {
                                label: item.label,
                                value: {family:item.value}
                            }
                        }).sortBy('label')
                    )
                :
                    setOptionBrowsers(
                        listBrowsers.map((item) => {
                            return {
                                label: item.label,
                                value: {family:item.value}
                            }
                        }).sortBy('label')
                    )

                /*********************************************
                 * 6. Set Target Audience
                 *********************************************/
                 updateTargetedAudience(true);

                 /*********************************************
                 * 7. Get Targeted Device Make (Supernova)
                 *********************************************/
                sideDrawer.purpose == "edit" ?
                    setOptionDeviceMake(
                        listDeviceMake.filter(deviceMake => !adgroup?.supernova?.targeting?.devices_make.includes(deviceMake.value)).map((item) => {
                            return {
                                label: item.label,
                                value: item.value
                            }
                    }).sortBy('label'))
                :
                    setOptionDeviceMake(
                        listDeviceMake.map((item) => {
                            return {
                                label: item.label,
                                value: item.value
                            }
                    }).sortBy('label'))

                 /*********************************************
                 * 8. Get Targeted Device Carrier (Supernova)
                 *********************************************/
                sideDrawer.purpose == "edit" ?
                    setOptionDeviceCarrier(
                        listDeviceCarrier.filter(deviceCarrier => !adgroup?.supernova?.targeting?.devices_carrier.includes(deviceCarrier.value)).map((item) => {
                            return {
                                label: item.label,
                                value: item.value
                            }
                    }).sortBy('label'))
                :
                    setOptionDeviceCarrier(
                        listDeviceCarrier.map((item) => {
                            return {
                                label: item.label,
                                value: item.value
                            }
                    }).sortBy('label'))

            }

            /******************************************************************************************
             * Assign adgroup.dayparting to newDayParting if theres any (For day parting table usage)
             *****************************************************************************************/
            if(sideDrawer.purpose == "edit" && adgroup?.dayparting){
                setNewDayParting(adgroup?.dayparting)
            }



        }
    },[
        adgroups.isDone,
    ]);



    /*
    |----------------------------------------------------------------------------------------------------------------------------------------
    | General handlers
    | - handleToggleEditName        - Toggles the Name field from input text to static text (for editing)
    | - handleChangeInputText       - Sets the adgroup details initiated by input textbox
    | - handleDropdownChange        - Sets the adgroup details initiated by dropdowns
    | - handleSubmit                - Handles the action (Create or Update) 
    | - handleFocusRef              - Scroll and focus on the desired section of the form
    |----------------------------------------------------------------------------------------------------------------------------------------
    */
    const handleToggleEditName = () => {
        setIsNameFieldVisible(!isNameFieldVisible)
    }
    const handleCloseDrawer = () => {
        props.closeDrawer()
    }
    const handleChangeInputDate = (value, name) => {
        value = moment(value).format('YYYY-MM-DD');
        setAdgroup({
            ...adgroup,
            [name]: value
        })
    }
    const handleChangeInputText = (event) => {
        let { name, value } = event.target;
        if(event.target.type == "number" || event.target.getAttribute('data-type') == "number"){
            // Allow only 2 decimal place
            let t = value;
            value = (t.indexOf(".") >= 0) ? parseFloat(t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : parseFloat(t);
        }
        if(name == "autopilot"){
            value = {"state": value}
        }
        setAdgroup({
            ...adgroup,
            [name]: value 
        })
    }
    const handleMultipleAdgroupNameChange = (event, index) => {
        let { name, value } = event.target;
        // setAdgroup({
        //     ...adgroup,
        //     [name]: value 
        // })
        let newArr = [...multipleAdgroupObj];
        console.log("old")
        console.log(newArr);
        newArr[index] = {name:value};
        console.log("new")
        console.log(newArr);
        setMultipleAdgroupObj(newArr);
    }
    const handleDropdownChange = (name, dropdown) => {
        const value = Array.isArray(dropdown)
            ? dropdown.map((item) => {
                return item.value
            })
            : dropdown.value
        setAdgroup({
            ...adgroup,
            [name]: value
        })
    }
    const handleFocusRef = (ref) => {
        setActiveAdgroupNavButton(ref)
        // adgroupRef[ref].scrollIntoView({ behavior: "smooth" }); 
        refs.current[ref]?.scrollIntoView({ behavior: "smooth" });
    }

    const getOriginId = (id) => {
        const recordFound = duplicates && duplicates.items && duplicates.items.filter(item => item.campaignId == id)
        let originId = null
        if(recordFound.length > 0){
            originId = recordFound[0].originId
        }
        return originId
    }

    const isDuplicateCampaign = () => {
        //If there is an origin then it must be a duplicate campaign
        return getOriginId(adgroup.campaignId) == null ? false : true
    }

    const hasDuplicateCampaign = () => {
        const recordFound = duplicates && duplicates.items && duplicates.items.filter(item => item.originId == adgroup.campaignId)
        console.log(recordFound)
        if(recordFound.length > 0 || isDuplicateCampaignClicked){
            return true
        }
        else{
            return false
        }
    }

    const handleSubmit = (e) => {
        console.log("handleSubmit START")
        e.preventDefault();
        let isValid = true;
        setSubmitted(true);

        let new_adgroup_for_submission = adgroup //Var for cleaning adgroup (this is the one that will be passed on to the actions)
        
        //Start Date is required
        if(!moment(adgroup.startDate).isValid()){
            isValid = false;
        }

        //End Date is required (NOTE: IF parent campaign is autopilot aka automatic then its ok not to enable endate)
        if(!moment(adgroup?.endDate).isValid() && isAutoPilot == false){
            console.log("2")
            isValid = false;
        }

        //Name is required (Temporary disabled while building multiple ad group )
        // if(adgroup.name === undefined || adgroup.name == ""){
        //     isValid = false;
        // }

        //Daily Budget must be greater than 0
        if(adgroup.dailyBudget == "" || adgroup.dailyBudget == null || adgroup.dailyBudget === undefined || adgroup.dailyBudget <= 0){
            isValid = false;
        }

        //Targeting devices is required
        if(adgroup.targeting?.devices.length == 0){
            isValid = false;
        }
        
        //Targeting Audience is required (DEPRECATED - TARGETING AUDIENCE IT NOT REQUIRED)
        // if(adgroup.targeting?.audience.AND.length == 0){
        //     isValid = false;
        // }

        //At least one geo target is required
        if(!isSupernovaAdgroup()){
            if(Object.keys(adgroup.targeting?.geo?.included).length == 0){
                isValid = false;
            }
        }

        //End date cannot be earlier than start date
        if(moment(adgroup?.endDate) < moment(adgroup.startDate)){
            // console.log("1")
            isValid = false;
        }
        
        //DISPLAY_BANNER
        if(campaignType == "DISPLAY" && adgroup.biddingType == "CPM" &&  getUserType() == "direct-client"    && adgroup.bid < accountsSupernova.minimum_bid_platform_display_cpm){isValid = false;}
        if(campaignType == "DISPLAY" && adgroup.biddingType == "CPM" &&  getUserType() == "sub-client"       && adgroup.bid < accountsSupernova.minimum_bid_client_display_cpm){isValid = false;}
        if(campaignType == "DISPLAY" && adgroup.biddingType == "CPC" &&  getUserType() == "direct-client"    && adgroup.bid < accountsSupernova.minimum_bid_platform_display_cpc){isValid = false;}
        if(campaignType == "DISPLAY" && adgroup.biddingType == "CPC" &&  getUserType() == "sub-client"       && adgroup.bid < accountsSupernova.minimum_bid_client_display_cpc){isValid = false;}
        
        //NATIVE
        if(campaignType == "NATIVE" && adgroup.biddingType == "CPM" &&  getUserType() == "direct-client"    && adgroup.bid < accountsSupernova.minimum_bid_platform_native_cpm){isValid = false;}
        if(campaignType == "NATIVE" && adgroup.biddingType == "CPM" &&  getUserType() == "sub-client"       && adgroup.bid < accountsSupernova.minimum_bid_client_native_cpm){isValid = false;}
        if(campaignType == "NATIVE" && adgroup.biddingType == "CPC" &&  getUserType() == "direct-client"    && adgroup.bid < accountsSupernova.minimum_bid_platform_native_cpc){isValid = false;}
        if(campaignType == "NATIVE" && adgroup.biddingType == "CPC" &&  getUserType() == "sub-client"       && adgroup.bid < accountsSupernova.minimum_bid_client_native_cpc){isValid = false;}
        
        //VIDEO
        if(campaignType == "VIDEO" && adgroup.biddingType == "CPM" &&  getUserType() == "direct-client"    && adgroup.bid < accountsSupernova.minimum_bid_platform_video_cpm){isValid = false;}
        if(campaignType == "VIDEO" && adgroup.biddingType == "CPM" &&  getUserType() == "sub-client"       && adgroup.bid < accountsSupernova.minimum_bid_client_video_cpm){isValid = false;}
        if(campaignType == "VIDEO" && adgroup.biddingType == "CPC" &&  getUserType() == "direct-client"    && adgroup.bid < accountsSupernova.minimum_bid_platform_video_cpc){isValid = false;}
        if(campaignType == "VIDEO" && adgroup.biddingType == "CPC" &&  getUserType() == "sub-client"       && adgroup.bid < accountsSupernova.minimum_bid_client_video_cpc){isValid = false;}
        
        //DISPLAY_AD_TAG (NOTE: DISPLAY_AD_TAG and DISPLAY_BANNER share the same minimum bid settings)
        if(campaignType == "DISPLAY_AD_TAG" && adgroup.biddingType == "CPM" &&  getUserType() == "direct-client"    && adgroup.bid < accountsSupernova.minimum_bid_platform_display_cpm){isValid = false;}
        if(campaignType == "DISPLAY_AD_TAG" && adgroup.biddingType == "CPM" &&  getUserType() == "sub-client"       && adgroup.bid < accountsSupernova.minimum_bid_client_display_cpm){isValid = false;}
        if(campaignType == "DISPLAY_AD_TAG" && adgroup.biddingType == "CPC" &&  getUserType() == "direct-client"    && adgroup.bid < accountsSupernova.minimum_bid_platform_display_cpc){isValid = false;}
        if(campaignType == "DISPLAY_AD_TAG" && adgroup.biddingType == "CPC" &&  getUserType() == "sub-client"       && adgroup.bid < accountsSupernova.minimum_bid_client_display_cpc){isValid = false;}




        
        //Bid is required if bid strategy is targeted bid
        if(isAutoPilot == false && (adgroup.bid == "" || adgroup.bid == 0 || adgroup.bid === undefined || isNaN(adgroup.bid))){
            isValid = false;
        }

        //Remove targeting audience if its not specified
        if(adgroup?.targeting?.audience?.AND?.length == 0 && adgroup?.targeting?.audience?.OR?.length == 0){
            delete new_adgroup_for_submission.targeting?.audience
        }
        if(adgroup?.targeting?.audience?.OR === undefined && adgroup?.targeting?.audience?.AND?.length == 0){
            delete new_adgroup_for_submission.targeting?.audience
        }
        if(adgroup?.targeting?.audience?.AND === undefined && adgroup?.targeting?.audience?.OR?.length == 0){
            delete new_adgroup_for_submission.targeting?.audience
        }

        //Remove fields that are not supposed to be modified when the parent campaign is in auto pilot
        if(isAutoPilot){
            delete new_adgroup_for_submission.targeting?.startDate
            delete new_adgroup_for_submission.targeting?.endDate
        }

        //Include supernovaCampaignId
        new_adgroup_for_submission.supernovaCampaignId = props.supernovaCampaignId

        //Include Day Parting
        new_adgroup_for_submission.dayparting = newDayParting
        
        //Form Validation
        if(isValid){
            if (adgroup.id == undefined) {
                //Creating new Adgroup
                if(sideDrawer.purpose == "CREATE_MULTIPLE"){
                    props.createMultipleAdgroups(new_adgroup_for_submission, user.accountId, multipleAdgroupObj)
                }
                else{
                    props.createAdgroup(new_adgroup_for_submission, user.accountId)
                }

                //Add marketing funnel to local db
                // props.createMarketingFunnel(marketingFunnelValue, adgroup.id)
                props.closeDrawer()

            }
            else {

                if(isSupernovaAdgroup()){
                    //Editing a Supernova Type adgroup
                    props.updateSupernovaAdgroup(new_adgroup_for_submission, user.accountId)
                }
                else{
                    //Editing an Adgroup
                    props.updateAdgroup(new_adgroup_for_submission, user.accountId)
                }
                //Edit marketing funnel in local db
                props.updateMarketingFunnel(marketingFunnelValue, new_adgroup_for_submission.id)
                props.closeDrawer()
            }
            setSubmitted(false);
        }
        console.log("handleSubmit END (IsValid:" + isValid + ")")
    }

    /*
    |----------------------------------------------------------------------------------------------------------------------------------------
    | Targeting Handlers (Audience Targeting)
    | - handleTargetingChange   - Sets the adgroup's targeting initiated by dropdowns
    | - handleSearchLocation    - Search for location
    |----------------------------------------------------------------------------------------------------------------------------------------
    */

    const handleTargetingChange = (dropdown, name) => {
        const value = Array.isArray(dropdown)
            ? dropdown.map((item) => {
                return item.value
            })
            : dropdown.value
        setAdgroup({
            ...adgroup,
            targeting: {
                ...adgroup.targeting,
                [name]: value
            }
        })
    }

    const handleDuplication = () => {
        // console.log(adgroup.campaignId)
        let newCampaign = {}
        let newAdgroup = {}
        setIsDuplicateCampaignClicked(true)
        if(campaigns && campaigns.isDone == true && campaigns.items){

            //New Campaign
            newCampaign = campaigns.items.find(item => item.supernovaCampaignId == adgroup.campaignId)
            newCampaign.name = `Duplicate of ${newCampaign.name}`
            delete newCampaign._id
            delete newCampaign._v
            delete newCampaign.id
            console.log(newCampaign)

            //New Adgroup
            newAdgroup = adgroup;

            
            //Set the Bid CPM of the duplicated adgroup to be the Bid  Value in the IPC Settings
            newAdgroup.bid = ipc.items[0].ipc_bid_value

            //Bid is required if bid strategy is targeted bid
            if(isAutoPilot == false && (adgroup.bid == "" || adgroup.bid == 0 || adgroup.bid === undefined || isNaN(adgroup.bid))){
                isValid = false;
            }

            //Remove targeting audience if its not specified
            if(adgroup?.targeting?.audience?.AND?.length == 0 && adgroup?.targeting?.audience?.OR?.length == 0){
                delete newAdgroup.targeting?.audience
            }
            if(adgroup?.targeting?.audience?.OR === undefined && adgroup?.targeting?.audience?.AND?.length == 0){
                delete newAdgroup.targeting?.audience
            }
            if(adgroup?.targeting?.audience?.AND === undefined && adgroup?.targeting?.audience?.OR?.length == 0){
                delete newAdgroup.targeting?.audience
            }
            props.createCampaign(newCampaign, newAdgroup)
        }
        else{
            return 
        }
    }

    const handleChangeZip = (val) => {
        let sub1 = "geo";
        let sub2 = "included";

        // let postalCodes = e.target.value.replace(/^,+|,+$/g, '').replace(" ", "").replace(/(\r\n|\n|\r)/gm, "").split(",").filter(item => item != "").map(item => `US:${item.replace(" ", "")}`)
        let postalCodes = val.filter(item => item != "").map(item => `US:${item.replace(" ", "")}`)
        
        setAdgroup({
            ...adgroup,
            targeting: {
                ...adgroup.targeting,
                [sub1]: {
                    ...adgroup.targeting[sub1],
                    [sub2]: {
                        ...adgroup.targeting[sub1][sub2],
                        ["postalCodes"] : postalCodes
                    }
                }
            }
        })
    }
    const handleSearchLocation = (sub1, sub2, dropdown) => {
        const { geolocations } = props
        geolocations[sub2] = []

        //1. COUNTRIES
        // console.log(dropdown)
        const value_countries = Array.isArray(dropdown)
                ? dropdown.filter(item => item.type=="COUNTRY").map((item) => {
                    geolocations[sub2].push({
                        type: item.type,
                        key: item.value,
                        name:item.label,
                    })
                    return item.value
                })
                : dropdown.value
        
        //2. REGIONS
        const value_regions = Array.isArray(dropdown)
                ? dropdown.filter(item => item.type=="REGION").map((item) => {
                    geolocations[sub2].push({
                        type: item.type,
                        key: item.value,
                        name:item.label,
                    })
                    return item.value
                })
                : dropdown.value
        
        //3. CITIES
        const value_cities = Array.isArray(dropdown)
                ? dropdown.filter(item => item.type=="CITY").map((item) => {
                    geolocations[sub2].push({
                        type: item.type,
                        key: item.value,
                        name:item.label,
                    })
                    return item.value
                })
                : dropdown.value
        
        //4. DMA
        const value_dma = Array.isArray(dropdown)
                ? dropdown.filter(item => item.type=="DMA").map((item) => {
                    geolocations[sub2].push({
                        type: item.type,
                        key: item.value,
                        name:item.label,
                    })
                    return item.value
                })
                : dropdown.value
        
        //5. ZIP (POSTAL CODE)
        const value_postalCodes = Array.isArray(dropdown)
                ? dropdown.filter(item => item.type=="ZIP").map((item) => {
                    geolocations[sub2].push({
                        type: item.type,
                        key: item.value,
                        name:item.label,
                    })
                    return item.value
                })
                : dropdown.value
        

        //Set in AdGroup
        setAdgroup({
            ...adgroup,
            targeting: {
                ...adgroup.targeting,
                [sub1]: {
                    ...adgroup.targeting[sub1],
                    [sub2]: {
                        ...adgroup.targeting[sub1][sub2],
                        ["countries"]   : value_countries,
                        ["regions"]     : value_regions,
                        ["cities"]      : value_cities,
                        ["dma"]         : value_dma,
                        ["postalCodes"] : value_postalCodes
                    }
                }
            }
        })

        //Get Google coordinates
        props.getGoogleCoordindates(encodeURI(dropdown[dropdown.length-1]?.label))

    }


    const toggleIPCAdgroup = (e) => {
        e.preventDefault();
        e.stopPropagation();


        
        
        if(e.target.checked){

            // let answer = window.confirm("Are you sure? By creating an ipc campaign supernova will duplicate this campaign")
            // if (answer) {
                console.log("IPC Initiated...")
                props.addIPC({
                    accountId   : user.accountId,
                    campaignId  : adgroup.campaignId,
                    adgroupId   : adgroup.id,
                    ipc_status  : e.target.checked
                })
                setIpc_status(true)
            // }
            // else{
            //     setIpc_status(false)
            // }
        }
        else{
            props.addIPC({
                accountId   : user.accountId,
                campaignId  : adgroup.campaignId,
                adgroupId   : adgroup.id,
                ipc_status  : e.target.checked
            })
            setIpc_status(false)
        }
    }


    const handleChangeTargetingHasIncExc = (name, includeExclude, dropdown) => {

        console.table("Drropdown: ")
        console.log(dropdown)
        let value = Array.isArray(dropdown)
            ? dropdown.map((item) => {
                return item.value
            })
            : dropdown.value

        // IF its from retargeting dropdown
        // THEN 
        //    set targeting?.customAudiences
        //    set targeting?.retargetingAdGroups (Note: adgroups in retargeting are saved in separate property)
        // ELSE
        //    set targeting[name]
        if(name == "customAudiences"){
            
            //Custom Audiences
            let value_audiences = Array.isArray(dropdown)
                ? dropdown.filter(item => item.type == "CUSTOM_AUDIENCE").map((item) => {
                    return item.value
                })
                : dropdown.value
            
            console.log(value_audiences)

            
            //Retargeting AdGroups
            let value_adgroups = Array.isArray(dropdown)
                ? dropdown.filter(item => item.type == "ADGROUP").map((item) => {
                    return parseInt(item.value)
                })
                : parseInt(dropdown.value)


            //Set State
            console.log("To Include:")
            console.log(value_adgroups)
            setAdgroup({
                ...adgroup,
                targeting: {
                    ...adgroup.targeting,
                    ["retargetingAdGroups"]: {
                        ...adgroup.targeting["retargetingAdGroups"],
                        [includeExclude]: value_adgroups
                    },
                    ["customAudiences"]: {
                        ...adgroup.targeting["customAudiences"],
                        [includeExclude]: value_audiences
                    }
                }
            })
        }
        else{
            setAdgroup({
                ...adgroup,
                targeting: {
                    ...adgroup.targeting,
                    [name]: {
                        ...adgroup.targeting[name],
                        [includeExclude]: value
                    }
                }
            })
        }
    }

    const handleChangeTargetingAudienceInc = (name, includeExclude, dropdown) => {
        const value = Array.isArray(dropdown)
            ? dropdown.map((item) => {
                return item.value
            })
            : dropdown.value
        setAdgroup({
            ...adgroup,
            targeting: {
                ...adgroup.targeting,
                audience: {
                    ...adgroup.targeting?.audience,
                    AND: {
                        ...adgroup.targeting?.audience["AND"],
                        ...{OR: [{category:value.pop()}]}
                    }
                }
            }
        })
    }

    const handleTreeSelectOnChangeInclude = (currentNode, selectedNodes) => {
        console.log("handleTreeSelectOnChangeInclude");
        let a = adgroup;
        if(a.targeting?.audience !== undefined){
            //Find the first index in the array where it has "OR"
            let target_index = a.targeting?.audience["AND"].findIndex(x => x.OR !== undefined)
            target_index = target_index >= 0 ? target_index : 0
            
            /////////////////////////////////////////////////////////////////////////
            // Commented out on Sep 13, 2023 to fix issue not adding audience (needs different structure)
            /////////////////////////////////////////////////////////////////////////

            // Create an OR inside AND if it doesn't exist
            if(a.targeting?.audience.AND.length == 0){
                a.targeting?.audience.AND.push({OR:[]}) 
            }

            //Push the new item included
            a.targeting?.audience["AND"][target_index]["OR"].push({category:`${currentNode.value}`});
            // a.targeting?.audience["AND"].push({category:`${currentNode.value}`}); //New Structure (not working)

            //Remove empty OR if there's any
            if(a.targeting?.audience.OR && a.targeting?.audience.OR.length == 0){
                delete a.targeting?.audience.OR
            }
    
            //Deterimine if its
            const action = selectedNodes.findIndex(x => x.value == currentNode.value) >= 0 ? "add" : "remove"
    
            //Update the state
            //Push the new item included
            if(action == "add"){
                // console.log(a)
                // console.log(a.targeting?.audience.AND[target_index].OR)
                setAdgroup(a)
                //Push new Adgroup name to multipleAdgroupFormPage[{name:xxxx}]
                let m = multipleAdgroupObj
                multipleAdgroupObj.push({name:`Adgroup ${multipleAdgroupObj.length + 1}`})
                setMultipleAdgroupObj(multipleAdgroupObj)
            }
            else{
                alert("remove")
            }


            //Update TargetAudience List
            updateTargetedAudience();
            
        }
    }
    const handleTreeSelectOnChangeExclude = (currentNode, selectedNodes) => {

        let a = adgroup;

        //Find the first index in the array where it has "OR"
        if(a.targeting?.audience !== undefined){
            //Deterimine if its
            const action = selectedNodes.findIndex(x => x.value == currentNode.value) >= 0 ? "add" : "remove"
    
            if(action == "add"){

                // Create a NOT inside AND if it doesn't exist
                if(a.targeting?.audience.AND.findIndex(x => x.NOT !== undefined) == -1){
                    a.targeting?.audience.AND.push({NOT:[]}) 
                }

                //Set the target_index where we placed the NOT inside AND
                let target_index = a.targeting?.audience["AND"].findIndex(x => x.NOT !== undefined)
                target_index = target_index >= 0 ? target_index : 0
                
                // Create an OR inside AND.NOT if it doesn't exist
                if(a.targeting?.audience.AND[target_index].NOT.findIndex(x => x.OR !== undefined) == -1){
                    a.targeting?.audience.AND[target_index].NOT.push({OR:[]}) 
                }

                //Now we can push the new item included
                a.targeting?.audience.AND[target_index].NOT[0].OR.push({category:`${currentNode.value}`})

                //Remove empty OR if there's any
                if(a.targeting?.audience.OR && a.targeting?.audience.OR.length == 0){
                    delete a.targeting?.audience.OR
                }

                // Update the state
                // console.log(a)
                // console.log(a.targeting?.audience.AND[target_index].NOT[0].OR)
                setAdgroup(a)
                updateTargetedAudience();
            }
            if(action == "remove"){
                // console.log(a.targeting?.audience.AND[target_index].NOT[0].OR.findIndex(x => x.category == `bluekai:${currentNode.value}`))
                // a.targeting?.audience.AND[target_index].NOT[0].OR.push({category:`bluekai:${currentNode.value}`})
            }
        }

 
    }
    const handleTreeSelectOnAction = (node, action) => {
        console.log('onAction::', action, node)
    }
    const handleTreeSelectOnNodecurrentNode = () => {
        console.log('onNodeToggle::', currentNode)
    }
    
    //Searches for whatever you enter in the {Location and language targeting}
    const loadLocationOptions = (inputLocation, callback) => {
        
        const   {getCities, geolocations }  = props
        const   types                       = "COUNTRY,REGION,CITY,DMA,ZIP";
        const   typesArr                    = types.split(",");
        let     typesFetched                = 0;
        


        // Note: These 2 setTimeouts are for delaying input search 
        //       so we dont send search query while the user is still typing
        //
        // The first setTimeout captures the inputed text in real time
        //
        // The second setTimeout compares if the inputed text has been changed whithin the last x second.
        // If it has been changed it means the user is still typing Else we can send it now to query on API
        setTimeout(() => {
            capturedInput = inputLocation
        }, 500);
        setTimeout(() => {
            if(inputLocation == capturedInput){
                if(inputLocation.length > 1){
                    const requestOptions = {
                        method: 'GET',
                    };
                    
                    optionLocations = []
                    //Note: The reason why we are querying by type not as a whole is so that we can limit the result per query.
                    typesArr.map(type => {
                        fetch(`${config.apiURL}/geolocations/?types=${type}&nameContains=${inputLocation}`, requestOptions)
                        .then(response => {
                                typesFetched ++;
                                response.text().then(text => {
                                    const data = text && JSON.parse(text)
                                    data.map(item => {
                                        optionLocations.push(
                                            {
                                                index: `${getGeoIndexByType(item.type)}.1 ${item.name}`,
                                                type: `${item.type}`,
                                                label: `${item.name}`,
                                                value: item.key
                                            }
                                        )
                                    })

                                    //When all data is ready (COUNTRY, REGIONS, CITIES, DMA and ZIP)
                                    if(typesFetched >= typesArr.length){
                                        typesArr.map(type => {
                                            if(optionLocations.some(item => item.type == type)){
                                                let totalType = optionLocations.filter(item => item.type == type).length
                                                optionLocations.push({
                                                    index: `${getGeoIndexByType(type)}.0`,
                                                    // label: `Found ${totalType} ${getPluralCountry(type, totalType)}:`,
                                                    label: `${getPluralCountry(type, totalType)}:`,
                                                    value: "",
                                                    isDisabled: true,
                                                    color:"red"
                                                })
                                            }
                                        })
                                        typesFetched = 0;
                                        // console.log(optionLocations)
                                        callback(optionLocations.sortBy("index"))
                                    }
                                });
                            }
                        )
                    })
                }
            }
        }, 1000);
    };

    const getGeoIndexByType = (type) => {
        switch(type){
            case "COUNTRY"  : return 1; break;
            case "REGION"   : return 2; break;
            case "CITY"     : return 3; break;
            case "DMA"      : return 4; break;
            case "ZIP"      : return 5; break;
        }
    }
    
    const getPluralCountry = (type, totalType) => {
        switch(type){
            case "COUNTRY"  : return totalType > 1 ? "Countries" : "Country"; break;
            case "REGION"   : return totalType > 1 ? "Regions" : "Region"; break;
            case "CITY"     : return totalType > 1 ? "Cities" : "City"; break;
            case "DMA"      : return totalType > 1 ? "DMA" : "DMA"; break;
            case "ZIP"      : return totalType > 1 ? "Zip Codes" : "Zip Code"; break;
        }
    }

    const getZipAsString = () => {
        if( adgroup && adgroup.targeting?.geo?.included?.postalCodes !== undefined && geolocations.included !== undefined ){
            adgroup.targeting?.geo?.included?.postalCodes.map(item => item.split(":")[1]).toString()
        }
        else{
            return ""
        }
    }

    /**********************************************************************
     * This function ensures that we are getting the geolocation name
     * 
     * IF dropdown label is already <span> THEN 
     *      label = <span>geolocations.included.find().props.dangerouslySetInnerHTML.___html</span>
     * ELSE
     *      label = <span>geolocations.included.find().name</span>
     * 
    /**********************************************************************/
    const displayGeoTargetingLabelItemAsSpan = (item, includedOrExcluded) => {
        let label = ""
        if(geolocations[includedOrExcluded] && geolocations[includedOrExcluded].find(geolocations => geolocations.key == item)?.name?.props){
            label = geolocations[includedOrExcluded] && geolocations[includedOrExcluded].find(geolocations => geolocations.key == item)?.name?.props?.dangerouslySetInnerHTML?.__html
        }
        else{
            label = geolocations[includedOrExcluded] && geolocations[includedOrExcluded].find(geolocations => geolocations.key == item)?.name
        }
        return <span className={`location-items ${selectedLocation == label ? "active" : ""}`} onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setSelectedLocation(label)
            props.getGoogleCoordindates(encodeURI(label))
            }
        } 
        dangerouslySetInnerHTML={{ __html: label }} />
    }

    const getIncludedGeoTargeting = () => {
        let includedLocations = [];

        //If geolocations.included is empty then exit function (Fix for )
        if(geolocations.included == ""){return;}


        //1. Geo Targeting Cities
        if( adgroup && adgroup.targeting?.geo?.included?.cities !== undefined && geolocations.included !== undefined ){
            includedLocations = includedLocations.concat(adgroup.targeting?.geo?.included?.cities.map(item => {
                return {
                    type: geolocations.included && geolocations.included.filter(geolocations => geolocations.key == item).map(geolocations => { return geolocations.type }).toString(),
                    label: displayGeoTargetingLabelItemAsSpan(item, "included"),
                    value: item
                }
            }))
        }
        //2. Get Targeting Countries
        if( adgroup && adgroup.targeting?.geo?.included?.countries !== undefined && geolocations.included !== undefined ){
            includedLocations = includedLocations.concat(adgroup.targeting?.geo?.included?.countries.map(item => {
                return {
                    type: geolocations.included && geolocations.included.filter(geolocations => geolocations.key == item).map(geolocations => { return geolocations.type }).toString(),
                    label: displayGeoTargetingLabelItemAsSpan(item, "included"),
                    value: item
                }
            }))
        }
        //3. Geo Targeting Regions
        if( adgroup && adgroup.targeting?.geo?.included?.regions !== undefined && geolocations.included !== undefined ){
            includedLocations = includedLocations.concat(adgroup.targeting?.geo?.included?.regions.map(item => {
                return {
                    type: geolocations.included && geolocations.included.filter(geolocations => geolocations.key == item).map(geolocations => { return geolocations.type }).toString(),
                    label: displayGeoTargetingLabelItemAsSpan(item, "included"),
                    value: item
                }
            }))
        }
        //4. Geo Targeting DMA
        if( adgroup && adgroup.targeting?.geo?.included?.dma !== undefined && geolocations.included !== undefined ){
            includedLocations = includedLocations.concat(adgroup.targeting?.geo?.included?.dma.map(item => {
                return {
                    type: geolocations.included.filter(geolocations => geolocations.key == item).map(geolocations => { return geolocations.type }).toString(),
                    label: displayGeoTargetingLabelItemAsSpan(item, "included"),
                    value: item
                }
            }))
        }
        //5. Geo Targeting Postal Codes (Deprecated) (Check item 4 "DMA" above make sure label is retrieved in the same way)
        // if( adgroup && adgroup.targeting?.geo?.included?.postalCodes !== undefined && geolocations.included !== undefined ){
        //     includedLocations = includedLocations.concat(adgroup.targeting?.geo?.included?.postalCodes.map(item => {
        //         return {
        //             type: geolocations.included.filter(geolocations => geolocations.key == item).map(geolocations => { return geolocations.type }).toString(),
        //             label: geolocations.included.filter(geolocations => geolocations.key == item).map(geolocations => { return geolocations.name }).toString(),
        //             value: item
        //         }
        //     }))
        // }
        return includedLocations;
    }

    const getExcludedGeoTargeting = () => {
        let excludedLocations = [];

        //1. Geo Targeting Cities
        if( adgroup && adgroup.targeting?.geo?.excluded?.cities !== undefined && geolocations.excluded !== undefined ){
            excludedLocations = excludedLocations.concat(adgroup.targeting?.geo?.excluded?.cities.map(item => {
                return {
                    type: geolocations.excluded && geolocations.excluded.filter(geolocations => geolocations.key == item).map(geolocations => { return geolocations.type }).toString(),
                    label: displayGeoTargetingLabelItemAsSpan(item, "excluded"),
                    value: item
                }
            }))
        }
        //2. Get Targeting Countries
        if( adgroup && adgroup.targeting?.geo?.excluded?.countries !== undefined && geolocations.excluded !== undefined ){
            excludedLocations = excludedLocations.concat(adgroup.targeting?.geo?.excluded?.countries.map(item => {
                return {
                    type: geolocations.excluded && geolocations.excluded.filter(geolocations => geolocations.key == item).map(geolocations => { return geolocations.type }).toString(),
                    label: displayGeoTargetingLabelItemAsSpan(item, "excluded"),
                    value: item
                }
            }))
        }
        //3. Geo Targeting Regions
        if( adgroup && adgroup.targeting?.geo?.excluded?.regions !== undefined && geolocations.excluded !== undefined ){
            excludedLocations = excludedLocations.concat(adgroup.targeting?.geo?.excluded?.regions.map(item => {
                return {
                    type: geolocations.excluded && geolocations.excluded.filter(geolocations => geolocations.key == item).map(geolocations => { return geolocations.type }).toString(),
                    label: displayGeoTargetingLabelItemAsSpan(item, "excluded"),
                    value: item
                }
            }))
        }
        //4. Geo Targeting DMA
        if( adgroup && adgroup.targeting?.geo?.excluded?.dma !== undefined && geolocations.excluded !== undefined ){
            excludedLocations = excludedLocations.concat(adgroup.targeting?.geo?.excluded?.dma.map(item => {
                return {
                    type: geolocations.excluded.filter(geolocations => geolocations.key == item).map(geolocations => { return geolocations.type }).toString(),
                    label: displayGeoTargetingLabelItemAsSpan(item, "excluded"),
                    value: item
                }
            }))
        }
        //5. Geo Targeting Postal Codes (Deprecated)
        // if( adgroup && adgroup.targeting?.geo?.excluded?.postalCodes !== undefined && geolocations.excluded !== undefined ){
        //     excludedLocations = excludedLocations.concat(adgroup.targeting?.geo?.excluded?.postalCodes.map(item => {
        //         return {
        //             type: geolocations.excluded.filter(geolocations => geolocations.key == item).map(geolocations => { return geolocations.type }).toString(),
        //             label: geolocations.excluded.filter(geolocations => geolocations.key == item).map(geolocations => { return geolocations.name }).toString(),
        //             value: item
        //         }
        //     }))
        // }
        return excludedLocations;
    }

    const getIncludedInterest = () => {
        return (
            adgroup && adgroup?.targeting?.interest?.included?.map(item => {
                return {
                    label: listInterest.filter(interest => interest.value == item).map(interest => { return interest.label }).toString(),
                    value: item
                }
            }).sortBy('label')
        )
    }

    const getExcludedInterest = () => {
        return (
            adgroup && adgroup?.targeting?.interest?.excluded?.map(item => {
                return {
                    label: listInterest.filter(interest => interest.value == item).map(interest => { return interest.label }).toString(),
                    value: item
                }
            }).sortBy('label')
        )
    }

    const getOptionInterest = () => {
        return(
            listInterest.filter(interest =>
                    adgroup && 
                    !adgroup?.targeting?.interest?.included?.includes(interest?.value) &&
                    !adgroup?.targeting?.interest?.excluded?.includes(interest?.value)
                ).map((item) => {
                return {
                    label: item.label,
                    value: item.value
                }
            }).sortBy('label')
        )
    }

    const getIncludedCustomAudiences = () => {
        let included_custom_audiences   = [] //Array that holds the mapped custom audience
        let included_adgroups           = [] //Array that holds the mapped adgroups

        // IF there is an "included custom audience" 
        // THEN map it so it can be shown in the dropdown list
        if( adgroup && 
            adgroup.targeting && 
            adgroup.targeting?.customAudiences && 
            adgroup.targeting?.customAudiences.included){

                // Custom Audiences
                // included_custom_audiences = adgroup.targeting?.customAudiences.included.map(item => {
                //     if(measurement.audiences.find(audience => audience.id == item)){
                //         return {
                //             label: measurement.audiences.find(audience => audience.id == item).name,
                //             value: item,
                //             type: "CUSTOM_AUDIENCE",
                //             sortIndex:3.1
                //         }
                //     }
                // }).sortBy('label')
                included_custom_audiences = measurement.audiences.filter(item => adgroup.targeting?.customAudiences.included.includes(parseInt(item.id))).map(item => {
                    return {
                        label: item.name,
                        value: item.id,
                        type: "CUSTOM_AUDIENCE",
                        sortIndex:3.1
                    }

                }).sortBy('label')
                

                // Adgroups
                included_adgroups = allAdgroups.filter(item => adgroup.targeting?.retargetingAdGroups.included.includes(parseInt(item.id))).map(item => {
                    console.log("INCLUDED:")
                    console.log(item)
                    return {
                        label: item.name,
                        value: item.id,
                        type: "ADGROUP",
                        sortIndex:2.1
                    }
                    
                }).sortBy('label')
                // console.log("List of Included adgroups")
                // console.log(included_adgroups)
        }

        // console.log(included_custom_audiences)

        return included_custom_audiences.concat(included_adgroups)
        
    }
    const getExcludedCustomAudiences = () => {
        let excluded_custom_audiences   = [] //Array that holds the mapped custom audience
        let excluded_adgroups           = [] //Array that holds the mapped adgroups

        // IF there is an "excluded custom audience" 
        // THEN map it so it can be shown in the dropdown list
        if( adgroup && 
            adgroup.targeting && 
            adgroup.targeting?.customAudiences && 
            adgroup.targeting?.customAudiences.excluded){

                // Custom Audiences
                // excluded_custom_audiences = adgroup.targeting?.customAudiences.excluded.map(item => {
                //     if(measurement.audiences.find(audience => audience.id == item)){
                //         return {
                //             label: measurement.audiences.find(audience => audience.id == item).name,
                //             value: item,
                //             type: "CUSTOM_AUDIENCE",
                //             sortIndex:3.1
                //         }
                //     }
                // }).sortBy('label')
                excluded_custom_audiences = measurement.audiences.filter(item => adgroup.targeting?.customAudiences.excluded.includes(parseInt(item.id))).map(item => {
                    return {
                        label: item.name,
                        value: item.id,
                        type: "CUSTOM_AUDIENCE",
                        sortIndex:3.1
                    }

                }).sortBy('label')
                

                // Adgroups
                excluded_adgroups = allAdgroups.filter(item => adgroup.targeting?.retargetingAdGroups.excluded.includes(parseInt(item.id))).map(item => {
                    console.log("EXCLUDED:")
                    console.log(item)
                    return {
                        label: item.name,
                        value: item.id,
                        type: "ADGROUP",
                        sortIndex:2.1
                    }
                    
                }).sortBy('label')
                // console.log("List of Excluded adgroups")
                // console.log(excluded_adgroups)
        }

        return excluded_custom_audiences.concat(excluded_adgroups)
    }

    const isOnlyForInternalUsers = (user) => {
        const internalUsers = ["bernard@arbigo.com", "sergey@arbigo.com", "johan@arbigo.com"]
        if( internalUsers.includes(user)){
            return true;
        }
        else{
            return false;
        }
    }

    const getOptionCustomAudiences = () => {
        if(sideDrawer.purpose == "edit"){
                return(
                    measurement.audiences.filter(audience =>
                        adgroup && 
                        adgroup.targeting &&
                        adgroup.targeting?.customAudiences && 
                        adgroup.targeting?.customAudiences.included && 
                        adgroup.targeting?.customAudiences.excluded &&
                        !adgroup.targeting?.customAudiences.excluded.includes(parseInt(audience.id)) &&
                        !adgroup.targeting?.customAudiences.included.includes(parseInt(audience.id)) &&
                        adgroup.targeting?.retargetingAdGroups.included &&
                        adgroup.targeting?.retargetingAdGroups.excluded &&
                        !adgroup.targeting?.retargetingAdGroups.excluded.includes(parseInt(audience.id)) &&
                        !adgroup.targeting?.retargetingAdGroups.included.includes(parseInt(audience.id))
                    ).map((item) => {
                        return {
                            label: item.name,
                            value: parseInt(item.id),
                            type: "CUSTOM_AUDIENCE",
                            sortIndex:3.1
                        }
                    }).sortBy('label')
                )
            }
            else{
                return(
                    measurement.audiences.map((item) => {
                        return {
                            label: item.name,
                            value: parseInt(item.id),
                            type: "CUSTOM_AUDIENCE",
                            sortIndex:3.1
                        }
                    }).sortBy('label')
                )
            }
    }

    const updateTargetedAudience = (setValuesIncludedAndExcluded = false) => {
        let valuesIncluded = []
        let valuesExcluded = []

        if(adgroup && !adgroup.targeting?.audience){
            setAdgroup({
                ...adgroup,
                targeting: {
                    ...adgroup.targeting,
                    ["audience"]: {
                        ["AND"]: [],
                    }
                }
            })
        }

        adgroup && adgroup.targeting?.audience && adgroup.targeting?.audience.AND.map(item => {
            //Included
            item.OR !== undefined &&
            item.OR.map(item2 => {
                let value = item2.category.split(":")[1];
                valuesIncluded.push(value)
            })
            
            //Excluded
            item.NOT !== undefined &&
            item.NOT.map(item2 => {
                
                item2.OR !== undefined &&
                item2.OR.map(item3 => {
                    let value = item3.category.split(":")[1];
                    valuesExcluded.push(value)
                })
            })
        })
        // console.log(setValuesIncludedAndExcluded)
        if(setValuesIncludedAndExcluded){
            setWhiteListedBluekai(valuesIncluded)
            setBlackListedBluekai(valuesExcluded)
        }

        
    }
    


    // Get Audience Targeting (BLUEKAI)
    const flattenArr = (a, prefix = '') => {  
        return a?.reduce(function (flattened, {name, id, childNodes}) {
            return flattened
            .concat([{name, id}])
            .concat(childNodes ? flattenArr(childNodes, name) : []);
        }, []);
    }

    //Used in BlueKai
    const mapShortToLong = new Map([
        ["categoryId",  "value"],
        ["name",        "label"],
        ["childNodes",  "children"],
    ]);
    //Used in Dmp
    const mapShortToLongDmp = new Map([
        ["id",          "value"],
        ["dmpType",     "dmpType"],
        ["name",        "label"],
        ["childNodes",  "children"],
    ]);
    const getNestedPath = (arr,name) => {
        for(let item of arr){
            if(item.name===name) return ` > ${name}`;
            if(item.childNodes) {
                const child = getNestedPath(item.childNodes, name);
                if(child) return ` > ${item.name}${child}`
            }
        }
    };
    /////////////////////////////////////////////////
    // Fit bluekai props into its option list
    /////////////////////////////////////////////////
    const refit_keys_included = (o) => {
        if (o === null || typeof o !== "object") {
            return o;
        }
        if (Array.isArray(o)) {
            return o.map(refit_keys_included);
        }
        const build = {};
        for (const key in o) {
            const destKey = mapShortToLong.get(key) || key;
            let value = o[key];
            if (typeof value === "object") {
                value = refit_keys_included(value);
            }
            build[destKey] = value;
            if(destKey == "value" && whiteListedBluekai.includes(value.toString())){
                    build["checked"] = true;
            }
            if(destKey == "label"){
                let path  = getNestedPath(bluekai, o[key])
                let split = path.split(" > ")
                let x = split.slice(0, split.length - 1).join(" > ")
                let breadcrumb = x.length > 0 ? " ( " + x.substring(2) + " )" : ""
                build["tagLabel"] = o[key] + breadcrumb
            }
        }
        return build;
    }
    const refit_keys_excluded = (o) => {
        if (o === null || typeof o !== "object") {
            return o;
        }
        if (Array.isArray(o)) {
            return o.map(refit_keys_excluded);
        }
        const build = {};
        for (const key in o) {
            const destKey = mapShortToLong.get(key) || key;
            let value = o[key];
            if (typeof value === "object") {
                value = refit_keys_excluded(value);
            }
            build[destKey] = value;
            if(destKey == "value" && blackListedBluekai.includes(value.toString())){
                    build["checked"] = true;
            }
            if(destKey == "label"){
                let path  = getNestedPath(bluekai, o[key])
                let split = path.split(" > ")
                let x = split.slice(0, split.length - 1).join(" > ")
                let breadcrumb = x.length > 0 ? " ( " + x.substring(2) + " )" : ""
                build["tagLabel"] = o[key] + breadcrumb
            }
        }
        return build;
    }
    /////////////////////////////////////////////////
    // Fit dmp props into its option list
    /////////////////////////////////////////////////
    const refit_keys_included_dmp = (o) => {
        if (o === null || typeof o !== "object") {
            return o;
        }
        if (Array.isArray(o)) {
            return o.map(refit_keys_included_dmp);
        }
        const build = {};
        for (const key in o) {
            const destKey = mapShortToLongDmp.get(key) || key;
            let value = o[key];
            if (typeof value === "object") {
                value = refit_keys_included_dmp(value);
            }
            if(destKey == "value"){
                build[destKey] = o["dmpType"] + ":" + value + ":navonly";
                // build[destKey] = "ob-eyeota:21230";
            }
            else{
                build[destKey] = value;
            }
            if(destKey == "value" && whiteListedDmp.includes(value.toString())){
                    build["checked"] = true;
            }
            if(destKey == "label"){
                let path  = getNestedPath(dmp, o[key])
                let split = path.split(" > ")
                let x = split.slice(0, split.length - 1).join(" > ")
                let breadcrumb = x.length > 0 ? " ( " + x.substring(2) + " )" : ""
                build["tagLabel"] = o[key] + breadcrumb
            }
        }
        return build;
    }
    const refit_keys_excluded_dmp = (o) => {
        if (o === null || typeof o !== "object") {
            return o;
        }
        if (Array.isArray(o)) {
            return o.map(refit_keys_excluded_dmp);
        }
        const build = {};
        for (const key in o) {
            const destKey = mapShortToLongDmp.get(key) || key;
            let value = o[key];
            if (typeof value === "object") {
                value = refit_keys_excluded_dmp(value);
            }
            if(destKey == "value"){
                build[destKey] = o["dmpType"] + ":" + value + ":navonly";
            }
            else{
                build[destKey] = value;
            }
            if(destKey == "value" && blackListedDmp.includes(value.toString())){
                    build["checked"] = true;
            }
            if(destKey == "label"){
                let path  = getNestedPath(dmp, o[key])
                let split = path.split(" > ")
                let x = split.slice(0, split.length - 1).join(" > ")
                let breadcrumb = x.length > 0 ? " ( " + x.substring(2) + " )" : ""
                build["tagLabel"] = o[key] + breadcrumb
            }
        }
        return build;
    }

    const AnyReactComponent = ({ text }) => <div>{text}</div>;


    const getUserType = () => {
        if(user.accountId){
            if(user.parentAccountId === undefined){                                             //IF there is no parentAccountId THEN it means the account is Supernova
                return "supernova";
            }
            else if(user.parentAccountId === "0000" || user.parentAccountId === 0){             //IF the parentAccountId is 0000 (Which is Supernova's Account ID) THEN it means it is a direct client of supernova
                return "direct-client";
            }
            else{                                                                               //ELSE THEN it means it's a sub-client of supernova
                return "sub-client"
            }
        }
        else{
            return null;
        }
    }

    const isSupernovaAdgroup = () => {
        return adgroup?.type == "SUPERNOVA"
    }


    ///////////////////////////////////////
    // DAY PARTING FUNCTIONS
    ///////////////////////////////////////
    const handleMouseUp = () => {
        setIsMouseDown(false);
    };
    const handleMouseDown = (event) => {
        if(event.target.className.includes("dayparting-cell")){
            const isActive  = event.target.getAttribute('data-value') == "active"
            setDayPartingAction(isActive ? "EXCLUDE" : "INCLUDE");
            setIsMouseDown(true);
            handleDayParting(event, true)
        }
    };
    const handleDayParting = (event, isFource = null) => {
        if((!isMouseDown && isFource == null) && event.target.className.includes("dayparting-cell")){
            return;
        }
        console.log("handleDayParting")
        const day       = event.target.getAttribute('data-day')
        const hour      = parseInt(event.target.getAttribute('data-hour'))

        setNewDayParting(prevNewDayParting => {
            const currentDayParting = prevNewDayParting[day] || [];
            
            if (dayPartingAction == "INCLUDE") {
                // Add the hour if it doesn't already exist
                if (!currentDayParting.includes(hour)) {
                    return {
                        ...prevNewDayParting,
                        [day]: [...currentDayParting, hour]
                    };
                }
            } else {
                // Remove the hour if it exists
                return {
                    ...prevNewDayParting,
                    [day]: currentDayParting.filter(item => item !== hour)
                };
            }
            
            // Return the state unchanged if no modifications were made
            return prevNewDayParting;
        });
    

        
    }
    
    try{
        return (
            <>
                {/* ADGROUP */}
                <div className="title">
                    {
                        title
                    }
                    {
                        sideDrawer.purpose == "CREATE_MULTIPLE" &&
                        <div className='sub-title'>
                            (We will create adgroup permutation based on selected audiences)
                        </div>
                    }
                    {
                        (sideDrawer.purpose == "CREATE_SINGLE" || sideDrawer.purpose == "edit") &&
                        <>
                            {
                                isNameFieldVisible || (sideDrawer.purpose == "CREATE_SINGLE" || sideDrawer.purpose == "CREATE_MULTIPLE")
                                ?   <>
                                <input 
                                    type="text" 
                                    autoFocus 
                                    className={`form-control`} 
                                    placeholder="Enter Ad Group Name" 
                                    name="name" 
                                    value={adgroup && adgroup.name != null ? adgroup.name : ""} 
                                    onBlur={handleToggleEditName} 
                                    onChange={(e) => handleChangeInputText(e)} />
                                </>
                                :   <div className="object-name" onClick={handleToggleEditName}>
                                        {adgroup ? adgroup.name : ""} <i className="fa fa-pencil" aria-hidden="true"></i>
                                    </div>

                            }
                        </>
                    }
                </div>
    
                {/* OUTLINE NAVIGATION */}
                <div className="outline-wrapper">
                    
                    {/* SCHEDULING */}
                    <button className={`border-top-0 blue-link d-block ${activeAdgroupNavButton == "FLIGHT_DATE" ? "active" : ""}`} onClick={() => handleFocusRef("FLIGHT_DATE")}>
                        1. Flight Date
                    </button>
                    
                    {/* DAY PARTING */}
                    <button className={`border-top-0 blue-link d-block ${activeAdgroupNavButton == "DAY_PARTING" ? "active" : ""}`} onClick={() => handleFocusRef("DAY_PARTING")}>
                        2. Day Parting
                    </button>
    
                    {/* MARKETING FUNNEL */}
                    <button className={`blue-link d-block ${activeAdgroupNavButton == "MARKETING_FUNNEL" ? "active" : ""}`} onClick={() => handleFocusRef("MARKETING_FUNNEL")}>
                        3. Marketing Funnel
                    </button>

                    {/* BIDDING AND BUDGETS */}
                    {
                        !isDuplicateCampaign() &&
                        <button className={`blue-link d-block ${activeAdgroupNavButton == "BID_SETTINGS" ? "active" : ""}`} onClick={() => handleFocusRef("BID_SETTINGS")}>
                            4. Bid Settings
                        </button>
                    }
                    
                    {/* DEVICE TARGETING */}
                    <button className={`border-bottom-0 pb-2 blue-link d-block ${activeAdgroupNavButton == "DEVICE_TARGETING" ? "active" : ""}`} onClick={() => handleFocusRef("DEVICE_TARGETING")}>
                        {isDuplicateCampaign() ? "4." : "5."} Device Targeting
                    </button>
    
                    {/* DEVICES */}
                    <button className={`child border-bottom-0 border-top-0 blue-link d-block ${activeAdgroupNavButton == "DEVICES" ? "active" : ""}`} onClick={() => handleFocusRef("DEVICES")}>
                        <span className={`mr-1 ${adgroup && adgroup.targeting?.devices.length == 0 ? "semi-visible" : ""}`}>
                            { adgroup && adgroup.targeting?.devices.filter(adgroup => !adgroup.deleted).length }
                        </span>
                        Devices
                    </button>
    
                    {/* CONNECTION TYPES */}
                    <button className={`child border-bottom-0 border-top-0 blue-link d-block ${activeAdgroupNavButton == "CONNECTION_TYPES" ? "active" : ""}`} onClick={() => handleFocusRef("CONNECTION_TYPES")}>
                        <span className={`mr-1 ${adgroup && adgroup.targeting?.connectionTypes.length == 0 ? "semi-visible" : ""}`}>
                            { adgroup && adgroup.targeting?.connectionTypes.filter(adgroup => !adgroup.deleted).length }
                        </span> 
                        Connection Types
                    </button>
                    
                    {/* OS */}
                    <button className={`child border-bottom-0 border-top-0 blue-link d-block ${activeAdgroupNavButton == "OS" ? "active" : ""}`} onClick={() => handleFocusRef("OS")}>
                        <span className={`mr-1 ${adgroup && adgroup.targeting?.os.length == 0 ? "semi-visible" : ""}`}>
                            { adgroup && adgroup.targeting?.os.filter(adgroup => !adgroup.deleted).length }
                        </span> 
                        OS
                    </button>
                    
                    {/* ENVIRONMENT */}
                    <button className={`child border-bottom-0 border-top-0 blue-link d-block ${activeAdgroupNavButton == "ENVIRONMENTS" ? "active" : ""}`} onClick={() => handleFocusRef("ENVIRONMENTS")}>
                        <span className={`mr-1 ${adgroup && adgroup.targeting?.environments.length == 0 ? "semi-visible" : ""}`}>
                            { adgroup && adgroup.targeting?.environments.filter(adgroup => !adgroup.deleted).length }
                        </span> 
                        Environments
                    </button>
    
                    {/* BROWSERS */}
                    <button className={`child border-bottom-0 border-top-0 blue-link d-block ${activeAdgroupNavButton == "BROWSERS" ? "active" : ""}`} onClick={() => handleFocusRef("BROWSERS")}>
                        {
                            adgroup && adgroup.targeting?.browsers.included !== undefined && adgroup.targeting?.browsers.excluded !== undefined &&
                            <span className={`mr-1 ${adgroup && adgroup.targeting?.browsers.included + adgroup.targeting?.browsers.excluded == 0 ? "semi-visible" : ""}`}>
                                {adgroup && adgroup.targeting?.browsers.included.length}
                            </span>
                        }
                        Browsers 
                    </button>

                    {/* DEVICES MAKE */}
                    <button className={`child border-bottom-0 border-top-0 blue-link d-block ${activeAdgroupNavButton == "DEVICE_MAKE" ? "active" : ""}`} onClick={() => handleFocusRef("DEVICE_MAKE")}>
                        {
                            <span className={`mr-1 ${adgroup && adgroup.supernova?.targeting?.devices_make == 0 ? "semi-visible" : ""}`}>
                                {adgroup && adgroup.supernova?.targeting?.devices_make.length || 0}
                            </span>

                        }
                        Device Make
                    </button>
                    
                    {/* DEVICES CARRIER */}
                    <button className={`child border-top-0 pb-3 blue-link d-block ${activeAdgroupNavButton == "DEVICE_CARRIER" ? "active" : ""}`} onClick={() => handleFocusRef("DEVICE_CARRIER")}>
                        {
                            <span className={`mr-1 ${adgroup && adgroup.supernova?.targeting?.devices_carrier == 0 ? "semi-visible" : ""}`}>
                                {adgroup && adgroup.supernova?.targeting?.devices_carrier.length || 0}
                            </span>

                        }
                        Device Carrier or ISP
                    </button>

                    {/* DEMOGRAPHICS */}
                    {
                        isSupernovaAdgroup() &&
                        <button className={`blue-link demographics d-block ${activeAdgroupNavButton == "DEMOGRAPHICS" ? "active" : ""}`} onClick={() => handleFocusRef("DEMOGRAPHICS")}>5. Demographics</button>
                    }
    
                    {/* AUDIENCE TARGETING */}
                    <button className={`blue-link audience d-block ${isSupernovaAdgroup() ? "disabled" : ""} ${activeAdgroupNavButton == "AUDIENCE_TARGETING" ? "active" : ""}`} onClick={() => handleFocusRef("AUDIENCE_TARGETING")}>
                    {
                        isDuplicateCampaign() ? "5." : 
                        isSupernovaAdgroup() ? "7" : "6."
                    } Audience Targeting 
                        {/* <span className={`ml-1`}>
                            {
                                (
                                    adgroup && 
                                    adgroup.targeting                           !== undefined &&
                                    adgroup.targeting?.audience                  !== undefined &&
                                    adgroup.targeting?.audience.AND              !== undefined &&
                                    adgroup.targeting?.audience.AND.length       > 0) ?
                                        adgroup.targeting?.audience.AND.filter(item => item.OR.length > 0).map(item => {return item.OR.length})
                                    : 0
                            }
                            &nbsp;|&nbsp; 
                            {
                                (
                                    adgroup && 
                                    adgroup.targeting                           !== undefined &&
                                    adgroup.targeting?.audience                  !== undefined &&
                                    adgroup.targeting?.audience.AND              !== undefined &&
                                    adgroup.targeting?.audience.AND.length       > 0) ?
                                        adgroup.targeting?.audience.AND.filter(item => item.NOT.length > 0).map(item => {return item.NOT.length})
                                    : 0
                            }
                        </span> */}
                    </button>

                    {/* RE-TARGETING */}
                    <button className={`blue-link d-block retargeting ${isSupernovaAdgroup() ? "disabled" : ""} ${activeAdgroupNavButton == "RETARGETING" ? "active" : ""}`} onClick={() => handleFocusRef("RETARGETING")}>
                        {
                            isDuplicateCampaign() ? "6." :
                            isSupernovaAdgroup() ? "8." : "7."
                        } Re Targeting 
                    </button>
                   
    
                    {/* INTEREST TARGETING */}
                    {/* <button className={`blue-link d-block ${activeAdgroupNavButton == 7 ? "active" : ""}`} onClick={() => handleFocusRef(7)}>
                        {isDuplicateCampaign() ? "6." : "7."} Interest Targeting 
                        {
                            <span className={`ml-1 ${adgroup && adgroup.targeting?.interest?.included?.length + adgroup.targeting?.interest?.excluded?.length == 0 ? "d-none" : ""}`}>
                            {
                                adgroup && 
                                adgroup.targeting?.interest?.included !== undefined && adgroup.targeting?.interest?.excluded !== undefined &&
                                adgroup.targeting?.interest?.included?.length} | {adgroup && adgroup.targeting?.interest?.excluded?.length
                            }
                            </span>
                        }
                    </button> */}
    
    
                    {/* LOCATION AND LANGUAGE */}
                    <button className={`blue-link d-block location ${isSupernovaAdgroup() ? "disabled" : ""} ${activeAdgroupNavButton == "LOCATION" ? "active" : ""}`} onClick={() => handleFocusRef("LOCATION")}>
                        {
                            isDuplicateCampaign() ? "8." :
                            isSupernovaAdgroup() ? "9." : "8."
                        } Location
                        {
                            <span className={`ml-1`}>
                            {
                                (
                                    adgroup && 
                                    adgroup.targeting?.geo                           !== undefined && 
                                    adgroup.targeting?.geo?.included                  !== undefined && 
                                    adgroup.targeting?.geo?.included?.countries        !== undefined && 
                                    adgroup.targeting?.geo?.included?.regions          !== undefined && 
                                    adgroup.targeting?.geo?.included?.dma              !== undefined && 
                                    adgroup.targeting?.geo?.included?.cities           !== undefined && 
                                    adgroup.targeting?.geo?.included?.postalCodes      !== undefined) ?
                                        adgroup.targeting?.geo?.included?.countries.length    +
                                        adgroup.targeting?.geo?.included?.regions.length      +
                                        adgroup.targeting?.geo?.included?.dma.length          +
                                        adgroup.targeting?.geo?.included?.cities.length       +
                                        adgroup.targeting?.geo?.included?.regions.length
                                    : 0
                            } 
                            &nbsp;|&nbsp; 
                            {
                                (
                                    adgroup && 
                                    adgroup.targeting?.geo                           !== undefined && 
                                    adgroup.targeting?.geo?.excluded                  !== undefined && 
                                    adgroup.targeting?.geo?.excluded?.countries        !== undefined && 
                                    adgroup.targeting?.geo?.excluded?.regions          !== undefined && 
                                    adgroup.targeting?.geo?.excluded?.dma              !== undefined && 
                                    adgroup.targeting?.geo?.excluded?.cities           !== undefined && 
                                    adgroup.targeting?.geo?.excluded?.postalCodes      !== undefined) ?
                                        adgroup.targeting?.geo?.excluded?.countries.length    +
                                        adgroup.targeting?.geo?.excluded?.regions.length      +
                                        adgroup.targeting?.geo?.excluded?.dma.length          +
                                        adgroup.targeting?.geo?.excluded?.cities.length       +
                                        adgroup.targeting?.geo?.excluded?.regions.length
                                    : 0
                            }
                            </span>
                        }
                    </button>

                    {/* IPC */}
                    {
                        !isDuplicateCampaign() &&
                        <button className={`blue-link d-block ${activeAdgroupNavButton == "IPC" ? "active" : ""}`} onClick={() => handleFocusRef("IPC")}>
                            {
                                isDuplicateCampaign() ? "9." :
                                isSupernovaAdgroup() ? "10." : "9."
                            } IPC 
                        </button>
                    }

                    {/* DEAL ID */}
                    {
                        !isDuplicateCampaign() && isOnlyForInternalUsers(user.username) &&
                        <button className={`blue-link d-block ${activeAdgroupNavButton == "DEAL_ID" ? "active" : ""}`} onClick={() => handleFocusRef("DEAL_ID")}>
                            {
                                isDuplicateCampaign() ? "10." :
                                isSupernovaAdgroup() ? "11." : "10."
                            } Deal ID
                        </button>
                    }
                    
                    {/* SUPPLY TARGETING */}
                    {/* DISABLE TEMPORARILY */}
                    {/* {
                        !isDuplicateCampaign() && isOnlyForInternalUsers(user.username) &&
                        <button className={`blue-link d-block ${activeAdgroupNavButton == 10 ? "active" : ""}`} onClick={() => handleFocusRef(10)}>
                            10. Supply Targeting
                        </button>
                    } */}
    
    
                    <div className="validation-info-wrapper">
                        {
                            /** Start date is required **/
                            submitted && 
                            !moment(adgroup.startDate).isValid() &&
                            <Animated animationIn="fadeInUp" isVisible={true}>
                                <div className="validation-panel">
                                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Start Date <br/>is required
                                </div>
                            </Animated>
                        }
                        {
                            /** End date is required **/
                            submitted && 
                            !moment(adgroup?.endDate).isValid() && isAutoPilot == false &&
                            <Animated animationIn="fadeInUp" isVisible={true}>
                                <div className="validation-panel">
                                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> End Date <br/>is required
                                </div>
                            </Animated>
                        }
                        {
                            /** Name is required (Temporary disabled while building the multiple adgroup creation) **/
                            // submitted && 
                            // (adgroup.name === undefined || adgroup.name == "") &&
                            // <Animated animationIn="fadeInUp" isVisible={true}>
                            //     <div className="validation-panel">
                            //         <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Name <br/>is required
                            //     </div>
                            // </Animated>
                        }
                        {
                            /** End date cannot be earlier than Start date **/
                            submitted && 
                            moment(adgroup?.endDate) < moment(adgroup.startDate) && isAutoPilot == false &&
                            <Animated animationIn="fadeInUp" isVisible={true}>
                                <div className="validation-panel">
                                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> End Date cannot be earlier than Start Date
                                </div>
                            </Animated>
                        }
                        {
                            /** Daily Budget must be greater than 0 **/
                            submitted && 
                            (adgroup.dailyBudget == "" || adgroup.dailyBudget == null || adgroup.dailyBudget === undefined || adgroup.dailyBudget <= 0) &&
                            <Animated animationIn="fadeInUp" isVisible={true}>
                                <div className="validation-panel">
                                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Budget is required
                                </div>
                            </Animated>
                        }
                        {
                            /** Targeting devices is required **/
                            submitted && 
                            (adgroup.targeting?.devices.length == 0) &&
                            <Animated animationIn="fadeInUp" isVisible={true}>
                                <div className="validation-panel">
                                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Target Device is required
                                </div>
                            </Animated>
                        }
                        {
                            /** Targeting location is required **/
                            submitted && !isSupernovaAdgroup() &&
                            (Object.keys(adgroup.targeting?.geo?.included).length == 0) &&
                            <Animated animationIn="fadeInUp" isVisible={true}>
                                <div className="validation-panel">
                                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Location is required
                                </div>
                            </Animated>
                        }
                        {
                            /** Bid is Below Minimum **/
                            submitted && 
                            (
                                (campaignType == "DISPLAY" && adgroup.biddingType == "CPM" &&  getUserType() == "direct-client"    && adgroup.bid < accountsSupernova.minimum_bid_platform_display_cpm) ||
                                (campaignType == "DISPLAY" && adgroup.biddingType == "CPM" &&  getUserType() == "sub-client"       && adgroup.bid < accountsSupernova.minimum_bid_client_display_cpm) ||
                                (campaignType == "DISPLAY" && adgroup.biddingType == "CPC" &&  getUserType() == "direct-client"    && adgroup.bid < accountsSupernova.minimum_bid_platform_display_cpc) ||
                                (campaignType == "DISPLAY" && adgroup.biddingType == "CPC" &&  getUserType() == "sub-client"       && adgroup.bid < accountsSupernova.minimum_bid_client_display_cpc) ||
                                (campaignType == "NATIVE" && adgroup.biddingType == "CPM" &&  getUserType() == "direct-client"    && adgroup.bid < accountsSupernova.minimum_bid_platform_native_cpm) ||
                                (campaignType == "NATIVE" && adgroup.biddingType == "CPM" &&  getUserType() == "sub-client"       && adgroup.bid < accountsSupernova.minimum_bid_client_native_cpm) ||
                                (campaignType == "NATIVE" && adgroup.biddingType == "CPC" &&  getUserType() == "direct-client"    && adgroup.bid < accountsSupernova.minimum_bid_platform_native_cpc) ||
                                (campaignType == "NATIVE" && adgroup.biddingType == "CPC" &&  getUserType() == "sub-client"       && adgroup.bid < accountsSupernova.minimum_bid_client_native_cpc) ||
                                (campaignType == "VIDEO" && adgroup.biddingType == "CPM" &&  getUserType() == "direct-client"    && adgroup.bid < accountsSupernova.minimum_bid_platform_video_cpm) ||
                                (campaignType == "VIDEO" && adgroup.biddingType == "CPM" &&  getUserType() == "sub-client"       && adgroup.bid < accountsSupernova.minimum_bid_client_video_cpm) || 
                                (campaignType == "VIDEO" && adgroup.biddingType == "CPC" &&  getUserType() == "direct-client"    && adgroup.bid < accountsSupernova.minimum_bid_platform_video_cpc) || 
                                (campaignType == "VIDEO" && adgroup.biddingType == "CPC" &&  getUserType() == "sub-client"       && adgroup.bid < accountsSupernova.minimum_bid_client_video_cpc) || 
                                (campaignType == "DISPLAY_AD_TAG" && adgroup.biddingType == "CPM" &&  getUserType() == "direct-client"    && adgroup.bid < accountsSupernova.minimum_bid_platform_display_cpm) ||
                                (campaignType == "DISPLAY_AD_TAG" && adgroup.biddingType == "CPM" &&  getUserType() == "sub-client"       && adgroup.bid < accountsSupernova.minimum_bid_client_display_cpm) || 
                                (campaignType == "DISPLAY_AD_TAG" && adgroup.biddingType == "CPC" &&  getUserType() == "direct-client"    && adgroup.bid < accountsSupernova.minimum_bid_platform_display_cpc) ||
                                (campaignType == "DISPLAY_AD_TAG" && adgroup.biddingType == "CPC" &&  getUserType() == "sub-client"       && adgroup.bid < accountsSupernova.minimum_bid_client_display_cpc)
                            ) &&
                            <Animated animationIn="fadeInUp" isVisible={true}>
                                <div className="validation-panel">
                                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Bid is below minimum
                                </div>
                            </Animated>
                        }
                        {
                            /** Bid is required if bid strategy is targeted bid **/
                            submitted && 
                            isAutoPilot == false && (adgroup.bid == "" || adgroup.bid == 0 || adgroup.bid === undefined || isNaN(adgroup.bid)) &&
                            <Animated animationIn="fadeInUp" isVisible={true}>
                                <div className="validation-panel">
                                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Bid is Required
                                </div>
                            </Animated>
                        }
                    </div>
                </div>
    
                <div className="form-wrapper">
                    <form name="form" onSubmit={(e) => handleSubmit(e)}>
    
                        <div className={`${multipleAdgroupFormPage != 1 ? "d-none" : ""}`}>
                            {/* SCHEDULING */}
                            {/* <section ref={(ref) => { adgroupRef[1] = ref }}> */}
                            <section ref={(el) => refs.current.FLIGHT_DATE = el}>
                                <div className="title">1. Ad Group Flight Date</div>
                                <div className={'form-group mt-5 mb-3'}>
                                    <label htmlFor="name">
                                        Start date
                                    </label>
                                    <div className="content">
                                        <DatePicker 
                                            // selected={new Date(adgroup && adgroup.startDate)} 
                                            selected={moment(adgroup.startDate).isValid() ? new Date(adgroup.startDate + " 00:00") : ""} 
                                            onChange={(date) => handleChangeInputDate(date, "startDate")} 
                                            minDate={new Date()}
                                            disabled={isDuplicateCampaign()}
                                        />
                                    </div>
                                </div>
                                <div className={'form-group mb-4'}>
                                    <label htmlFor="name">End date</label>
                                    <div className="content">
                                        {
                                            isAutoPilot == false ?
                                            <DatePicker 
                                                // selected={adgroup && adgroup?.endDate == null ? "" : new Date(adgroup && adgroup?.endDate)} 
                                                selected={moment(adgroup?.endDate).isValid() ? new Date(adgroup?.endDate + " 00:00") : ""} 
                                                onChange={(date) => handleChangeInputDate(date, "endDate")} 
                                                minDate={new Date(adgroup.startDate + " 00:00")}
                                                maxDate={
                                                    //IF parent campaign is automatic AND there is a budget 
                                                    //THEN the maxDate should be the last date found in all budget items
                                                    //ELSE the maxDate should be 1 year from today
                                                    campaigns && 
                                                    campaigns.items.find(item => item.id == adgroup.campaignId)?.autopilot == true &&
                                                    campaigns.items.find(item => item.id == adgroup.campaignId)?.budgets.length > 0 ?
                                                    new Date(campaigns.items.find(item => item.id == adgroup.campaignId).budgets.sortBy('endDate').reverse()[0].endDate + " 00:00")
                                                    : moment().add(1, 'years').calendar()
                                                }
                                                disabled={isDuplicateCampaign()}
                                                />
                                            : "Automatic"
                                        }
                                    </div>
                                </div>
                                <div className={'form-group mb-3'}>
                                    <label className="align-top" htmlFor="name">Delivery Type</label>
                                    <div className="radio" onChange={handleChangeInputText}>
                                        <label className="m-0">
                                            <input disabled={isDuplicateCampaign()} type="radio" value="STANDARD" name="deliveryType" defaultChecked={adgroup && adgroup.deliveryType == "STANDARD"} />
                                            <span className="radio-description delivery-type mb-3"><b>Standard</b> <i> Deliver ads throughout the day.</i></span>
                                        </label>
                                        <label>
                                            <input disabled={isDuplicateCampaign()} type="radio" value="ACCELERATED" name="deliveryType" defaultChecked={adgroup && adgroup.deliveryType == "ACCELERATED"} />
                                            <span className="radio-description delivery-type"><b>Accelerated</b> <i> Deliver ads as soon as possible.</i></span>
                                        </label>
                                    </div>
                                </div>
                            </section>

                            {/* DAY PARTING */}
                            <section ref={(el) => refs.current.DAY_PARTING = el}>
                                <div className="title">2. Day Parting</div>
                                <div className={'form-group mb-4'}>
                                        <table className="dayparting" border="1">
                                            <thead>
                                                <th></th>
                                                {
                                                    hour_list.map(hour => <th>{hour}</th>)
                                                }
                                            </thead>
                                            <tbody>
                                                {
                                                    day_list.map(day => 
                                                            <tr>
                                                                <td>{day}</td>
                                                                {
                                                                    // hour_list.map(hour => <td className="inactive" data-day={day} data-hour={hour} data-value="inactive" onMouseDown={handleDayParting}></td>)
                                                                    hour_list.map(hour => <td 
                                                                        onMouseDown={handleMouseDown}
                                                                        onMouseEnter={handleDayParting}
                                                                        className={`dayparting-cell ${newDayParting[day].includes(parseInt(hour)) ? "active" : "inactive"}`}
                                                                        data-day={day} 
                                                                        data-hour={hour} 
                                                                        data-value={newDayParting[day].includes(parseInt(hour)) ? "active" : "inactive"}></td>)
                                                                }
                                                            </tr>
                                                    )
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td colSpan={hour_list.length}>
                                                        Timezone is based on America/New_York
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                        
                                </div>
                            </section>

                            {/* MARKETING FUNNEL */}
                            <section ref={(el) => refs.current.MARKETING_FUNNEL = el}>
                                <div className="title">3. Marketing Funnel</div>
                                <div className={'form-group mb-4'}>
                                {
                                    sideDrawer.purpose != "edit" || marketingFunnel.isDone ?
                                    <Select
                                        isDisabled={isDuplicateCampaign()}
                                        defaultValue={(marketingFunnel.items && marketingFunnel.items.length > 0 && sideDrawer.purpose == "edit") ? listMarketingFunnels.find(item => item.value == marketingFunnel.items[0].marketing_funnel) : null}
                                        className="select-primary multi"
                                        onChange={(e) => {sideDrawer.purpose == "edit" ? setMarketingFunnelValue(e.value) : handleDropdownChange("marketingFunnel", e)}}
                                        isMulti={false}
                                        options={listMarketingFunnels}
                                        styles={styleSelectMultiple} />
                                    :
                                    "Loading..."
                                }
                                </div>
                            </section>
        
                            {/* BILLING AND BUDGETS */}
                            {
                                !isDuplicateCampaign() &&
                                <section ref={(el) => refs.current.BID_SETTINGS = el}>
                                    <div className="title">4. Bid Settings</div>
                                    <div className={'form-group mt-5 mb-5'}>
                                        <label className="align-top" htmlFor="name">Bidding type</label>
                                        <div className="radio" onChange={handleChangeInputText}>
                                            <label className="m-0">
                                                <input disabled={isDuplicateCampaign()} type="radio" value="CPC" name="biddingType" defaultChecked={adgroup && adgroup.biddingType == "CPC" ? true : false} />
                                                <span className="radio-description bidding-type"><b>CPC</b> <i>Cost Per Click.</i></span>
                                            </label>
                                            <label>
                                                <input disabled={isDuplicateCampaign()} type="radio" value="CPM" name="biddingType" defaultChecked={adgroup && adgroup.biddingType == "CPM" ? true : false} />
                                                <span className="radio-description bidding-type"><b>CPM</b> <i>Cost Per Thousand.</i></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className={'form-group mb-5'}>
                                        <label className="align-top" htmlFor="name">Supernova bidding strategy</label>
                                        <div className="radio" onChange={handleChangeInputText}>
                                            <label className="m-0">
                                                <input disabled={isDuplicateCampaign()} type="radio" value={"ACTIVE"} name="autopilot" defaultChecked={adgroup && ["ACTIVE_CPC_BUDGET", "ACTIVE"].includes(adgroup.autopilot?.state) ? true : false} />
                                                <span className="radio-description auto-pilot d-flex"><b>Dynamic Bid</b> <i>Supernova will bid dynamically and find the best prices.</i></span>
                                                {
                                                    adgroup && ["ACTIVE_CPC_BUDGET", "ACTIVE"].includes(adgroup.autopilot?.state) &&
                                                        <div className="bid-input-wrapper">
                                                            <span>Max bid {adgroup.biddingType}:</span>
                                                            <div className='position-relative'>
                                                                <span className="currency-lbl">$</span>
                                                                <input 
                                                                disabled={isDuplicateCampaign()}
                                                                className='pl-3'
                                                                type="number" 
                                                                name="bid" 
                                                                step="any"
                                                                min={0} 
                                                                // data-type="number"
                                                                defaultValue={adgroup && isNaN(adgroup.bid) ? "" : parseFloat(adgroup.bid).toFixed(2)} 
                                                                // value={adgroup && adgroup.bid && !isNaN(adgroup.bid) ? parseFloat(adgroup.bid).toFixed(2) : ""}
                                                                onChange={(e) => handleChangeInputText(e)} />
                                                            </div>
                                                        </div>
                                                }
                                            </label>
                                            <label>
                                                <input disabled={isDuplicateCampaign()} type="radio" value={"INACTIVE"} name="autopilot" defaultChecked={adgroup && ["INACTIVE"].includes(adgroup.autopilot?.state) ? true : false} />
                                                <span className="radio-description auto-pilot mb-3"><b>Fixed Bid</b> <i>Supernova will bid at a fixed value.</i></span>
                                                {
                                                    adgroup && ["INACTIVE"].includes(adgroup.autopilot?.state) &&
                                                        <div className="bid-input-wrapper">
                                                            <span>Bid {adgroup.biddingType}:</span>
                                                            <div className='position-relative'>
                                                                <span className="currency-lbl">$</span>
                                                                <input 
                                                                disabled={isDuplicateCampaign()}
                                                                className='pl-3'
                                                                type="number" 
                                                                name="bid" 
                                                                min={0} 
                                                                step="any"
                                                                // data-type="number"
                                                                defaultValue={adgroup && isNaN(adgroup.bid) ? "" : parseFloat(adgroup.bid).toFixed(2)} 
                                                                // value={adgroup && adgroup.bid && !isNaN(adgroup.bid) ? parseFloat(adgroup.bid).toFixed(2) : ""}
                                                                onChange={(e) => handleChangeInputText(e)} />
                                                            </div>
                                                    </div>
                                                }
                                            </label>
                                        </div>
                                    </div>
                                    <div className={'form-group mb-3 daily-budget'}>
                                        <label htmlFor="dailyBudget">Ad group's daily budget</label>
                                        <div className="content position-relative">
                                            {
                                                !isAutoPilot && 
                                                    <div className="daily-budget-input">
                                                        <span className={`currency-lbl ${isAutoPilot ? 'disabled' : ''}`}>$</span>
                                                        <input 
                                                            disabled={isDuplicateCampaign()}
                                                            type="number" 
                                                            className={`pl-3 form-control ${isAutoPilot ? 'disabled' : ''}`} 
                                                            name="dailyBudget" 
                                                            min={0} 
                                                            step="any"
                                                            defaultValue={adgroup && isNaN(adgroup.dailyBudget) ? "" : parseFloat(adgroup.dailyBudget).toFixed(2)} 
                                                            onChange={(e) => handleChangeInputText(e)} />
                                                    </div>
                                            }
                                            {
                                                isAutoPilot &&   
                                                    <>
                                                        <div className='info-badge'>
                                                            <div className='icon'>
                                                                <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                            </div>
                                                            <div className='text'>
                                                                Cannot be manually set because campaign's Frequency &amp; Pacing is set to Automatic.
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                    </div>
                                    <div className={'form-group mb-3'}>
                                        <label htmlFor="dailyBudget">
                                            Frequency capping &nbsp;
                                            <OverlayTrigger placement="top" content="" overlay={<Tooltip>This setting defines the maximum number of times your ads from this ad group will be shown to a unique user in one day.</Tooltip>}>
                                                <i className="fa fa-question-circle" aria-hidden="true"></i>
                                            </OverlayTrigger>
                                        </label>
                                        <div className="content">
                                            <input 
                                                disabled={isDuplicateCampaign() || isSupernovaAdgroup()}
                                                type="number" 
                                                className={`form-control`} 
                                                name="frequencyCapping" 
                                                min={0} 
                                                value={adgroup && adgroup.frequencyCapping && adgroup.frequencyCapping != null ? adgroup.frequencyCapping : ""} 
                                                onChange={(e) => handleChangeInputText(e)} />
                                        </div>
                                    </div>
                                    <div className={'form-group mb-5'}>
                                        <label htmlFor="dailyBudget">
                                            Daily click cap &nbsp;
                                            <OverlayTrigger placement="top" content="" overlay={<Tooltip>Limit number of clicks you want to reach daily within the ad group. Supernova will stop spending for the rest of the day once it hits the maximum number of clicks you set.</Tooltip>}>
                                                <i className="fa fa-question-circle" aria-hidden="true"></i>
                                            </OverlayTrigger>
                                        </label>
                                        <div className="content">
                                            <input 
                                                disabled={isDuplicateCampaign()}
                                                type="number" 
                                                className={`form-control`} 
                                                name="clickCappingDailyAdGroupMaxClicks" 
                                                min={0} 
                                                value={adgroup && adgroup.clickCappingDailyAdGroupMaxClicks && adgroup.clickCappingDailyAdGroupMaxClicks != null ? adgroup.clickCappingDailyAdGroupMaxClicks : ""} 
                                                onChange={(e) => handleChangeInputText(e)} />
                                        </div>
                                    </div>
                                </section>
                            }
        
                            {/* TARGETING */}
                            <section ref={(el) => refs.current.DEVICE_TARGETING = el}>
                                <div className="title">{isDuplicateCampaign() ? "4." : "5."} Device Targeting</div>
        
                                {/* DEVICES */}
                                <div ref={(el) => refs.current.DEVICES = el} className={'form-group mb-5'}>
                                    <label className="align-top" htmlFor="dailyBudget">Devices</label>
                                    <div className="content">
                                        <Select
                                            isDisabled={isDuplicateCampaign()}
                                            value={
                                                adgroup && adgroup.targeting?.devices.map(item => {
                                                    return {
                                                        label: <><i className="fa fa-check" aria-hidden="true"></i> {listDevices.filter(device => device.value == item).map(device => { return device.label }).toString()}</>,
                                                        value: item
                                                    }
                                                }).sortBy('label')
                                            }
                                            className={`select-primary multi ${submitted && adgroup && adgroup.targeting?.devices.length == 0 ? "has-error" : ""}`}
                                            onChange={(e) => handleTargetingChange(e, "devices")}
                                            isMulti
                                            options={optionDevices}
                                            styles={styleSelectMultiple} />
                                    </div>
                                </div>
                                
                                {/* CONNECTION TYPES */}
                                <div ref={(el) => refs.current.CONNECTION_TYPES = el} className={'form-group mb-5'}>
                                    <label className="align-top" htmlFor="dailyBudget">Connection Types</label>
                                    <div className="content">
                                        <Select
                                            isDisabled={isDuplicateCampaign()}
                                            value={
                                                adgroup && adgroup.targeting?.connectionTypes.map(item => {
                                                    return {
                                                        label: <><i className="fa fa-check" aria-hidden="true"></i> {listConnectionTypes.filter(connectionType => connectionType.value == item).map(connectionType => { return connectionType.label }).toString()}</>,
                                                        value: item
                                                    }
                                                }).sortBy('label')
                                            }
                                            className="select-primary multi"
                                            onChange={(e) => handleTargetingChange(e, "connectionTypes")}
                                            isMulti
                                            options={optionConnectionTypes}
                                            styles={styleSelectMultiple} />
                                    </div>
                                </div>
        
                                {/* OPERATING SYSTEMS */}
                                <div ref={(el) => refs.current.OS = el} className={'form-group mb-5'}>
                                    <label className="align-top" htmlFor="dailyBudget">Operating Systems</label>
                                    <div className="content">
                                        <Select
                                            isDisabled={isDuplicateCampaign()}
                                            value={
                                                adgroup && adgroup.targeting?.os.map(item => {
                                                    return {
                                                        label: <><i className="fa fa-check" aria-hidden="true"></i> {listOperatingSystems.filter(operatingSystem => operatingSystem.value == item.name).map(operatingSystem => { return operatingSystem.label }).toString()}</>,
                                                        value: item
                                                    }
                                                }).sortBy('label')
                                            }
                                            className="select-primary multi"
                                            onChange={(e) => handleTargetingChange(e, "os")}
                                            isMulti
                                            options={optionOperatingSystem}
                                            styles={styleSelectMultiple} />
                                    </div>
                                </div>
        
                                {/* ENVIRONMENTS */}
                                <div ref={(el) => refs.current.ENVIRONMENTS = el} className={'form-group mb-5'}>
                                    <label className="align-top" htmlFor="dailyBudget">Environments</label>
                                    <div className="content">
                                        <Select
                                            isDisabled={isDuplicateCampaign()}
                                            value={
                                                adgroup && adgroup.targeting?.environments.map(item => {
                                                    return {
                                                        label: <><i className="fa fa-check" aria-hidden="true"></i> {listEnvironments.filter(environment => environment.value == item).map(environment => { return environment.label }).toString()}</>,
                                                        value: item
                                                    }
                                                }).sortBy('label')
                                            }
                                            className="select-primary multi"
                                            onChange={(e) => handleTargetingChange(e, "environments")}
                                            isMulti
                                            options={optionEnvironments}
                                            styles={styleSelectMultiple} />
                                    </div>
                                </div>
        
                                {/* BROWSER */}
                                <div ref={(el) => refs.current.BROWSERS = el} className={'form-group mb-5'}>
                                    <label className="align-top" htmlFor="dailyBudget">Browsers</label>
                                    <div className="content">
                                        <Select
                                                isDisabled={isDuplicateCampaign()}
                                                value={
                                                    adgroup && adgroup.targeting?.browsers.included.map(item => {
                                                        return {
                                                            label: <><i className="fa fa-check" aria-hidden="true"></i> {listBrowsers.filter(browser => browser.value == item.family).map(browser => { return browser.label }).toString()}</>,
                                                            value: item
                                                        }
                                                    }).sortBy('label')
                                                }
                                                className="select-primary multi"
                                                onChange={(e) => handleChangeTargetingHasIncExc("browsers", "included", e)}
                                                isMulti
                                                options={optionBrowsers}
                                                styles={styleSelectMultiple} />
                                        {/* <div className="half-list-sub left">
                                            <div className="title">Included</div>
                                            <Select
                                                value={includedBrowsers}
                                                className="select-primary multi"
                                                onChange={(e) => handleChangeTargetingHasIncExc("browsers", "included", e)}
                                                isMulti
                                                options={optionBrowsers}
                                                styles={styleSelectMultiple} />
                                        </div>
                                        <div className="half-list-sub right">
                                            <div className="title">Excluded</div>
                                            <Select
                                                value={excludedBrowsers}
                                                className="select-primary multi"
                                                onChange={(e) => handleChangeTargetingHasIncExc("browsers", "excluded", e)}
                                                isMulti
                                                options={optionBrowsers}
                                                styles={styleSelectMultiple} />
                                        </div> */}
                                    </div>
                                </div>

                                {/* DEVICES MAKE (SUPERNOVA) */}
                                <div ref={(el) => refs.current.DEVICE_MAKE = el} className={'form-group mb-5'}>
                                    <label className="align-top" htmlFor="dailyBudget">Device Make</label>
                                    <div className="content">
                                        <Select
                                            isDisabled={isDuplicateCampaign()}
                                            value={
                                                adgroup && adgroup?.supernova?.targeting?.devices_make.map(item => {
                                                    return {
                                                        label: <><i className="fa fa-check" aria-hidden="true"></i> {listDeviceMake.filter(deviceMake => deviceMake.value == item).map(deviceMake => { return deviceMake.label }).toString()}</>,
                                                        value: item
                                                    }
                                                }).sortBy('label')
                                            }
                                            className={`select-primary multi`}
                                            onChange={(e) => handleTargetingChange(e, "devices_make")}
                                            isMulti
                                            options={optionDeviceMake}
                                            styles={styleSelectMultiple} />
                                    </div>
                                </div>

                                {/* DEVICES CARRIER (SUPERNOVA) */}
                                <div ref={(el) => refs.current.DEVICE_CARRIER = el} className={'form-group mb-5'}>
                                    <label className="align-top" htmlFor="dailyBudget">Device Carrier or ISP</label>
                                    <div className="content">
                                        <Select
                                            isDisabled={isDuplicateCampaign()}
                                            value={
                                                adgroup && adgroup?.supernova?.targeting?.devices_carrier.map(item => {
                                                    return {
                                                        label: <><i className="fa fa-check" aria-hidden="true"></i> {listDeviceCarrier.filter(deviceCarrier => deviceCarrier.value == item).map(deviceCarrier => { return deviceCarrier.label }).toString()}</>,
                                                        value: item
                                                    }
                                                }).sortBy('label')
                                            }
                                            className={`select-primary multi`}
                                            onChange={(e) => handleTargetingChange(e, "devices_carrier")}
                                            isMulti
                                            options={optionDeviceCarrier}
                                            styles={styleSelectMultiple} />
                                    </div>
                                </div>
                            </section>

                            {/* DEMOGRAPHICS */}
                            {
                                isSupernovaAdgroup() &&
                                <section ref={(el) => refs.current.DEMOGRAPHICS = el}>
                                    <div className="title">5. DEMOGRAPHICS</div>
                                    <div className={'form-group mb-4'}>
                                    {
                                        <div>
                                            {audienceDescription}
                                        </div>
                                    }
                                    </div>
                                </section>
                            }
        
                            {/* AUDIENCE */}
                            <section className={`audience ${isSupernovaAdgroup() ? "disabled" : ""}`} ref={(el) => refs.current.AUDIENCE_TARGETING = el}>
                                <div className={'form-group'}>
                                    <div className="title">{
                                        isDuplicateCampaign() ? "5." : 
                                        isSupernovaAdgroup() ? "7" : "6."
                                    } Audience Targeting</div>
                                    <div className="description">
                                        Include or exclude audiences from third party data vendors.
                                        <div className="full-list left">
                                            <div className="title">Included</div>
                                            <DropdownTreeSelect 
                                                disabled={isDuplicateCampaign()}
                                                // data={optionBluekaiIncluded} 
                                                data={optionDmpIncluded} 
                                                onChange={handleTreeSelectOnChangeInclude} 
                                                onAction={handleTreeSelectOnAction} 
                                                onNodeToggle={handleTreeSelectOnNodecurrentNode} />
                                        </div>
        
                                        <div className="full-list left">
                                            <div className="title">Excluded</div>
                                            <DropdownTreeSelect 
                                                disabled={isDuplicateCampaign()}
                                                // data={optionBluekaiExcluded} 
                                                data={optionDmpExcluded} 
                                                onChange={handleTreeSelectOnChangeExclude} 
                                                onAction={handleTreeSelectOnAction} 
                                                onNodeToggle={handleTreeSelectOnNodecurrentNode} />
                                        </div>
        
                                    </div>
                                </div>
                            </section>

                            {/* RETARGETING */}
                            <section className={`${isSupernovaAdgroup() ? "disabled" : ""}`} ref={(el) => refs.current.RETARGETING = el}>
                                <div className={'form-group'}>
                                    <div className="title">{
                                        isDuplicateCampaign() ? "6." : 
                                        isSupernovaAdgroup() ? "8" : "7."
                                    } Re Targeting</div>
                                    <div className="description">
                                        Include or exclude segments you've created to retarget already converted audiences
                                        <div className="half-list left">
                                            <div className="title">Included</div>
                                                <Select
                                                    isDisabled={isDuplicateCampaign()}
                                                    value={getIncludedCustomAudiences()}
                                                    className="select-primary multi"
                                                    onChange={(e) => handleChangeTargetingHasIncExc("customAudiences", "included", e)}
                                                    isMulti
                                                    // options={
                                                    //     adgroups.items.filter(
                                                    //         item => 
                                                    //         adgroup && 
                                                    //         !adgroup.targeting?.customAudiences.included.includes(item.id) &&
                                                    //         !adgroup.targeting?.retargetingAdGroups.included.includes(parseInt(item.id))
                                                    //     ).map((item) => {
                                                    //         return({
                                                    //             label:item.name,
                                                    //             value:item.id,
                                                    //             isAdgroup:true
                                                    //         })
                                                    //     }).concat(getOptionCustomAudiences().filter(item => 
                                                    //         !adgroup.targeting?.customAudiences.included.includes(item.value) &&
                                                    //         !adgroup.targeting?.retargetingAdGroups.included.includes(parseInt(item.value))
                                                    //     ))
                                                    // }
                                                    options={
                                                        //LABEL OF CAMPAIGN
                                                        // [{
                                                        //     label:"Campaigns:",
                                                        //     value:"",
                                                        //     isDisabled: true,
                                                        //     type:"LABEL",
                                                        //     sortIndex:1.0
                                                        // }]
                                                        //Note when enabling back the campaign. uncomment the label campaign and adgroups in the concat and then remove this :
                                                        //LABEL ADGROUP
                                                        [{
                                                            label:"Adgroups:",
                                                            value:"",
                                                            isDisabled: true,
                                                            type:"LABEL",
                                                            sortIndex:2.0
                                                        }]
                                                        .concat(
                                                            //LIST OF CAMPAIGNS
                                                            // campaigns.items.map((item) => {
                                                            //     return({
                                                            //         label:item.name,
                                                            //         value:item.id,
                                                            //         type:"CAMPAIGN",
                                                            //         sortIndex:1.1
                                                            //     })
                                                            // }),
                                                            //LABEL ADGROUP
                                                            // [{
                                                            //     label:"Adgroups:",
                                                            //     value:"",
                                                            //     isDisabled: true,
                                                            //     type:"LABEL",
                                                            //     sortIndex:2.0
                                                            // }],
                                                            //LIST OF ADGROUPS
                                                            allAdgroups.map((item) => {
                                                                return({
                                                                    label:item.name,
                                                                    value:item.id,
                                                                    type:"ADGROUP",
                                                                    sortIndex:2.1
                                                                })
                                                            }),
                                                            //LABEL OF AUDIENCE
                                                            [{
                                                                label:"Custom Audiences:",
                                                                value:"",
                                                                isDisabled: true,
                                                                type:"LABEL",
                                                                sortIndex:3.0
                                                            }],
                                                            //LIST OF AUDIENCE
                                                            getOptionCustomAudiences()
                                                        ).sortBy("sortIndex")
                                                    }
                                                    styles={styleSelectMultiple} />
                                        </div>
                                        <div className="half-list right">
                                            <div className="title">Excluded</div>
                                            <Select
                                                    isDisabled={isDuplicateCampaign()}
                                                    value={getExcludedCustomAudiences()}
                                                    className="select-primary multi"
                                                    onChange={(e) => handleChangeTargetingHasIncExc("customAudiences", "excluded", e)}
                                                    isMulti
                                                    // options={
                                                    //     adgroups.items.filter(
                                                    //         item => 
                                                    //         adgroup && 
                                                    //         !adgroup.targeting?.customAudiences.excluded.includes(item.id) &&
                                                    //         !adgroup.targeting?.retargetingAdGroups.excluded.includes(parseInt(item.id))
                                                    //     ).map((item) => {
                                                    //         return({
                                                    //             label:item.name,
                                                    //             value:item.id,
                                                    //             isAdgroup:true
                                                    //         })
                                                    //     }).concat(getOptionCustomAudiences().filter(item => 
                                                    //         !adgroup.targeting?.customAudiences.excluded.includes(item.value) &&
                                                    //         !adgroup.targeting?.retargetingAdGroups.excluded.includes(parseInt(item.value))
                                                    //     ))
                                                    // }
                                                    options={
                                                        //LABEL OF CAMPAIGN
                                                        // [{
                                                        //     label:"Campaigns:",
                                                        //     value:"",
                                                        //     isDisabled: true,
                                                        //     type:"LABEL",
                                                        //     sortIndex:1.0
                                                        // }]
                                                        //Note when enabling back the campaign. uncomment the label campaign and adgroups in the concat and then remove this :
                                                        //LABEL ADGROUP
                                                        [{
                                                            label:"Adgroups:",
                                                            value:"",
                                                            isDisabled: true,
                                                            type:"LABEL",
                                                            sortIndex:2.0
                                                        }]
                                                        .concat(
                                                            //LIST OF CAMPAIGNS
                                                            // campaigns.items.map((item) => {
                                                            //     return({
                                                            //         label:item.name,
                                                            //         value:item.id,
                                                            //         type:"CAMPAIGN",
                                                            //         sortIndex:1.1
                                                            //     })
                                                            // }),
                                                            //LABEL ADGROUP
                                                            // [{
                                                            //     label:"Adgroups:",
                                                            //     value:"",
                                                            //     isDisabled: true,
                                                            //     type:"LABEL",
                                                            //     sortIndex:2.0
                                                            // }],
                                                            //LIST OF ADGROUPS
                                                            allAdgroups.map((item) => {
                                                                return({
                                                                    label:item.name,
                                                                    value:item.id,
                                                                    type:"ADGROUP",
                                                                    sortIndex:2.1
                                                                })
                                                            }),
                                                            //LABEL OF AUDIENCE
                                                            [{
                                                                label:"Custom Audiences:",
                                                                value:"",
                                                                isDisabled: true,
                                                                type:"LABEL",
                                                                sortIndex:3.0
                                                            }],
                                                            //LIST OF AUDIENCE
                                                            getOptionCustomAudiences()
                                                        ).sortBy("sortIndex")
                                                    }
                                                    styles={styleSelectMultiple} />
                                        </div>
        
                                    </div>
                                </div>
                            </section>
        
                            {/* INTEREST */}
                            {/* <section ref={(ref) => { adgroupRef[7] = ref }}>
                                <div className={'form-group'}>
                                    <div className="title">7. Interest Targeting</div>
                                    <div className="description">
                                    Interest targeting is based on publisher classification and user's reading habits.
                                        <div className="half-list left">
                                            <div className="title">Included Interests</div>
                                            <Select
                                                isDisabled={isDuplicateCampaign()}
                                                value={getIncludedInterest()}
                                                className="select-primary multi"
                                                onChange={(e) => handleChangeTargetingHasIncExc("interest", "included", e)}
                                                isMulti
                                                options={getOptionInterest()}
                                                styles={styleSelectMultiple} />
                                        </div>
                                        <div className="half-list right">
                                            <div className="title">Excluded Interests</div>
                                            <Select
                                                isDisabled={isDuplicateCampaign()}
                                                value={getExcludedInterest()}
                                                className="select-primary multi"
                                                onChange={(e) => handleChangeTargetingHasIncExc("interest", "excluded", e)}
                                                isMulti
                                                options={getOptionInterest()}
                                                styles={styleSelectMultiple} />
                                        </div>
                                    </div>
                                </div>
                            </section> */}
        
                            {/* GEO TARGETING */}
                            <section className={`${isSupernovaAdgroup() ? "disabled" : ""}`} ref={(el) => refs.current.LOCATION = el}>
                                <div className={'form-group'}>
                                    <div className="title">{
                                        isDuplicateCampaign() ? "7." : 
                                        isSupernovaAdgroup() ? "9" : "8."
                                    } Location</div>
                                    <div className="description">
                                    The countries, states and DMA regions where your content ads will be shown.

                                    <div style={{ height: '300px', width: '100%' }} id="mapbox">
                                        {/* <GoogleMapReact
                                            bootstrapURLKeys={{ key: "AIzaSyDfuGvx27JlyH4JCQxVMlpD9ZwMlqeT4WQ" }}
                                            defaultCenter={googleMapProps.center}
                                            defaultZoom={googleMapProps.zoom}
                                            center={googleMapProps.center}
                                            zoom={googleMapProps.zoom}
                                            options={{
                                                fullscreenControl   : false,
                                                draggable           : false,
                                                gestureHandling     : "none",
                                                zoomControl         : false,
                                            }}
                                        >
                                        <AnyReactComponent
                                            lat={googleMapProps.center.lat}
                                            lng={googleMapProps.center.lng}
                                            text="My Marker"
                                            />

                                        </GoogleMapReact> */}
                                    </div>

                                    <Tabs id="controlled-tab" activeKey={locationTabKey} onSelect={(k) => setLocationTabKey(k)} className="mb-3 mt-3">
                                            <Tab eventKey="included" title="Included">
                                                <div className="full-list">
                                                    <div className="title">Included Locations</div>
                                                    {
                                                        !isSupernovaAdgroup() &&
                                                        <AsyncSelect
                                                            isDisabled={isDuplicateCampaign()}
                                                            noOptionsMessage={() => 'Results:'}
                                                            loadingMessage={() => 'Loading...'}
                                                            menuPlacement="top"
                                                            cacheOptions
                                                            isMulti
                                                            placeholder="Select..."
                                                            value={getIncludedGeoTargeting()}
                                                            className="select-primary multi"
                                                            loadOptions={loadLocationOptions}
                                                            defaultOptions
                                                            onChange={(e) => handleSearchLocation("geo", "included", e)}
                                                            styles={styleSelectMultiple}
                                                            />
                                                    }
                                                    <div className="title mt-4">Zipcodes</div>
                                                    <div className="hint mt-0">Enter multiple Zip Codes as comma separated</div>
                                                    {
                                                        adgroup && adgroup.targeting?.geo?.included ?
                                                        <>
                                                            <TagsInput
                                                                disabled={isDuplicateCampaign()}
                                                                value={
                                                                    adgroup &&
                                                                    adgroup.targeting && 
                                                                    adgroup.targeting?.geo && 
                                                                    adgroup.targeting?.geo?.included && 
                                                                    adgroup.targeting?.geo?.included?.postalCodes && 
                                                                    adgroup.targeting?.geo?.included?.postalCodes.map(item => item.split(":")[1])
                                                                }
                                                                // onChange={setSelected}
                                                                // onChange={(value) => console.log(e)}z
                                                                onChange={(val) => handleChangeZip(val)}
                                                                // onChange={
                                                                //     (value) => 
                                                                //     setAdgroup({
                                                                //         ...adgroup,
                                                                //         targeting: {
                                                                //             ...adgroup.targeting,
                                                                //             ["geo"]: {
                                                                //                 ...adgroup.targeting["geo"],
                                                                //                 ["included"]: {
                                                                //                     ...adgroup.targeting["geo"]["included"],
                                                                //                     ["postalCodes"] : value
                                                                //                 }
                                                                //             }
                                                                //         }
                                                                //     })
                                                                // }
                                                                name="zipcodesStr"
                                                            />
                                                            <div className="mt-3">
                                                                <b>Total Zipcodes:</b> {
                                                                    adgroup &&
                                                                    adgroup.targeting && 
                                                                    adgroup.targeting?.geo && 
                                                                    adgroup.targeting?.geo?.included && 
                                                                    adgroup.targeting?.geo?.included?.postalCodes && 
                                                                    Array.isArray(adgroup.targeting?.geo?.included?.postalCodes)
                                                                    ? adgroup.targeting?.geo?.included?.postalCodes.length
                                                                    : 0
                                                                }
                                                            </div>
                                                        </>
                                                        :
                                                        isSupernovaAdgroup() ? "" : "Loading"
                                                    }
                                                </div>
                                            </Tab>
                                            <Tab eventKey="excluded" title="Excluded">
                                                <div className="full-list">
                                                    <div className="title">Excluded Locations</div>
                                                    {
                                                        !isSupernovaAdgroup() &&
                                                        <AsyncSelect
                                                            isDisabled={isDuplicateCampaign()}
                                                            noOptionsMessage={() => 'Results:'}
                                                            loadingMessage={() => 'Loading...'}
                                                            menuPlacement="top"
                                                            cacheOptions
                                                            placeholder="Select..."
                                                            isMulti
                                                            value={getExcludedGeoTargeting()}
                                                            className="select-primary multi"
                                                            loadOptions={loadLocationOptions}
                                                            defaultOptions
                                                            onChange={(e) => handleSearchLocation("geo", "excluded", e)}
                                                            styles={styleSelectMultiple}
                                                        />
                                                    }
                                                </div>
                                            </Tab>
                                    </Tabs>
                                        
                                        
                                    </div>
                                </div>
                            </section>
                        </div>

                        {/* AUDIENCE TARGETING (PROMPT) */}
                        <div className={`multipleAdgroupForm2 p-3 ${multipleAdgroupFormPage != 2 ? "d-none" : ""}`}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Adgroup Name</th>
                                            <th>Targeting</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        adgroup &&
                                        adgroup?.targeting &&
                                        adgroup?.targeting?.audience &&
                                        adgroup?.targeting?.audience["AND"] &&
                                        adgroup?.targeting?.audience["AND"][0] &&
                                        adgroup?.targeting?.audience["AND"][0]["OR"]?.map((audience, index) => 
                                        // adgroup.targeting?.audience["AND"].map((audience, index) => 
                                            <tr className='main-row' key={index}>
                                                <td><input type="text" name="name" value={multipleAdgroupObj[index]?.name} onChange={(e) => handleMultipleAdgroupNameChange(e, index)}/></td>
                                                <td>
                                                    <table className='targeting'>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <OverlayTrigger placement="left" content="" overlay={<Tooltip>{flattenArr(dmp)?.find(item => item.id == audience.category.split(":")[1])?.name}</Tooltip>}>
                                                                        <span>Audience: {flattenArr(dmp)?.find(item => item.id == audience.category.split(":")[1])?.name}</span>
                                                                    </OverlayTrigger>                                                                
                                                                </td>
                                                            </tr>            
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                        </div>


                        {/* IPC */}
                        <section ref={(el) => refs.current.IPC = el}>
                                <div className="title">{
                                        isDuplicateCampaign() ? "8." : 
                                        isSupernovaAdgroup() ? "10." : "9."
                                    } IPC</div>
                                <div className={`description ipc ${ipc && ipc.loading == false && ipc.items && ipc.items.length > 0 && ipc.items[0].pricing_status == true ? "ipc-enabled" : ""}`}>
                                    <div className="half-list left ipc">
                                        {/* first enable IPC */}
                                        <span>Enable IPC</span>
                                        {
                                            ipc && ipc.loading == false && ipc.items && ipc.items.length > 0 && ipc.items[0].pricing_status == true ?
                                            <></>
                                            :
                                            <div className='label-contact-admin'>
                                                Contact admin to enable this feature
                                            </div> 
                                        }
                                    </div>
                                    <div className="half-list right ipc">
                                        <Toggle className={`${(ipc && ipc.loading == false && ipc.items && ipc.items.length > 0 && ipc.items[0].pricing_status == true && !isDuplicateCampaign() && !hasDuplicateCampaign() && campaigns.isDone != false) ? "" : "disabled"}`}  checked={ipc_status == undefined ? (ipc && ipc.loading == false && ipc.items && ipc.items.length > 0) ? ipc.items[0].ipc_status : false : ipc_status  } onChange={(e) => toggleIPCAdgroup(e)}/> 
                                        <button className={`${(ipc && ipc.loading == false && ipc.items && ipc.items.length > 0 && ipc.items[0].pricing_status == true && !isDuplicateCampaign() && !hasDuplicateCampaign() && campaigns.isDone != false) ? "" : "disabled"} btn btn-primary`}  type="button" onClick={(e) => handleDuplication(e)}><i className="fa fa-files-o" aria-hidden="true"></i> Create IPC Campaign</button>
                                    </div>
                                </div>
                            </section> 
                        
                        {/* DEAL ID */}
                        {
                            isOnlyForInternalUsers(user.username) && 
                            <section ref={(el) => refs.current.DEAL_ID = el}>
                                    <div className="title">{
                                        isDuplicateCampaign() ? "9." : 
                                        isSupernovaAdgroup() ? "11." : "10."
                                    } Deal ID</div>
                                    <div className={`description`}>
                                        <label className="mr-3" htmlFor="dealID">
                                            Deal ID
                                        </label>
                                        <div className="content">
                                            <input 
                                                disabled={isDuplicateCampaign()}
                                                type="text" 
                                                className={`form-control`} 
                                                name="dealID" 
                                                min={0} 
                                                value={adgroup && adgroup?.supernova?.dealID && adgroup?.supernova?.dealID != null ? adgroup?.supernova?.dealID : ""} 
                                                onChange={(e) => handleChangeInputText(e)} />
                                        </div>
                                    </div>
                                </section> 
                        }

                        {/* SUPPLY TARGETING */}
                        {/* DISABLE TEMPORARILY UNTIL FUTHER NOTICE*/}
                        {/* <section ref={(ref) => { adgroupRef[11] = ref }}>
                                <div className="title">11. Supply Targeting</div>
                                <div className="radio full-width flex" onChange={(e) => handleChangeTargetingCheckbox(e)}>
                                    <div className={'form-group supply-type'}>
                                        <label>Supply Type:</label>
                                        <label>
                                            <input type="checkbox" value={"main"} checked={adgroup?.supernova?.supply_targeting?.includes("main")} name="supply_targeting" defaultChecked={true} />
                                            <span className="tracker-description">Main</span>
                                        </label>
                                        <label>
                                            <input type="checkbox" value={"arbigo"}  checked={adgroup?.supernova?.supply_targeting?.includes("arbigo")} name="supply_targeting" defaultChecked={false} />
                                            <span className="tracker-description">Arbigo</span>
                                        </label>
                                    </div>
                                </div>
                            </section> */}
                        
    
                        {/* SUBMIT OR CANCEL */}
                        <div className="footer">
                            {
                                sideDrawer.purpose == "CREATE_MULTIPLE" ?
                                <>
                                    <div className="btn btn-primary" type="button" onClick={(e) => setMultipleAdgroupFormPage(1)}><i className="fa fa-chevron-left" aria-hidden="true"></i> Prev</div>
                                    {
                                        multipleAdgroupFormPage == 1 ?
                                            <div className="btn btn-primary" type="button" onClick={(e) => setMultipleAdgroupFormPage(2)}>Next <i className="fa fa-chevron-right" aria-hidden="true"></i></div>
                                        :
                                            <button className="btn btn-primary" type="submit" onClick={(e) => handleSubmit(e)}><i className="fa fa-check" aria-hidden="true"></i> {(sideDrawer.purpose == "CREATE_SINGLE") ? 'Create' : 'Save'}</button>

                                    }
                                    <button className="btn btn-secondary" onClick={() => {handleCloseDrawer(); setMultipleAdgroupFormPage(1)}}>Cancel</button>
                                </>
                                :
                                <>
                                    <button className="btn btn-primary" type="submit" onClick={(e) => handleSubmit(e)}><i className="fa fa-check" aria-hidden="true"></i> {(sideDrawer.purpose == "CREATE_SINGLE") ? 'Create' : 'Save'}</button>
                                    <div className="btn btn-secondary" onClick={handleCloseDrawer}>Cancel</div>
                                </>
                            }
                        </div>
    
                    </form>
                </div>
            </>
        );
    }
    catch(e){
        console.log(e)
        
        //Catch handler (Redirect if env is production)
        // if(config.redirectOnError){
        //     window.location.reload(false);
        // }
    }
    
    
}

const mapState = state => ({
    user                : state.users.current,
    campaigns           : state.campaigns,
    sideDrawer          : state.sideDrawer,
    adgroups            : state.adgroups,
    geolocations        : state.geolocations,
    credits             : state.users.current.credits,
    bluekai             : state.bluekai.items,
    dmp                 : state.dmp.items,
    accountsSupernova   : state.accountsSupernova.items,
    measurement         : state.measurement,
    marketingFunnel     : state.marketingFunnel,
    ipc                 : state.ipc,
    duplicates          : state.duplicates,
    query               : state.query,
})

const actionCreators = {
    getASMinBidById         : accountSupernovaActions.getASMinBidById,
    createAdgroup           : adgroupActions.createAdgroup,
    createMultipleAdgroups  : adgroupActions.createMultipleAdgroups,
    getIncludedKeys         : geolocationActions.getIncludedKeys,
    getExcludedKeys         : geolocationActions.getExcludedKeys,
    getCities               : geolocationActions.getCities,
    getGoogleCoordindates   : geolocationActions.getGoogleCoordindates,
    updateAdgroup           : adgroupActions.updateAdgroup,
    updateSupernovaAdgroup  : adgroupActions.updateSupernovaAdgroup,
    closeDrawer             : sideDrawerActions.close,
    getBluekaiList          : bluekaiActions.getBluekaiList,
    getDMP                  : dmpActions.getDMP,
    getAudiencesByAccountId : measurementActions.getAudiencesByAccountId,
    getMarketingFunnel      : marketingFunnelActions.getMarketingFunnel,
    createMarketingFunnel   : marketingFunnelActions.createMarketingFunnel,
    updateMarketingFunnel   : marketingFunnelActions.updateMarketingFunnel,
    getIPCByAdgroupId       : ipcActions.getIPCByAdgroupId,
    addIPC                  : ipcActions.addIPC,
    createCampaign          : campaignActions.createCampaign,
    getDuplicatedCampaignsByAccountId   : duplicatesActions.getDuplicatedCampaignsByAccountId,
    getQueriesByAdgroupID   : queryActions.getQueriesByAdgroupID,
}


const connectedAdgroupForm = connect(mapState, actionCreators)(AdgroupForm);
export { connectedAdgroupForm as AdgroupForm };


