import config from 'config';
import { authHeader } from '../_helpers';

export const audienceService = {
    getAudiencesByPixelId,
    updateAudience,
    createAudience
};

function getAudiencesByPixelId(pixelIds) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({"pixelIds": pixelIds}),
        headers: { 
            'Content-Type': 'application/json'
        },
    };
    return fetch(`${config.apiURL}/audiences`, requestOptions).then(handleResponse);
}

function updateAudience(data) {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({"name": data.name}),
    };

    return fetch(`${config.apiURL}/audiences/${data.accountId}/${data.audienceId}`, requestOptions).then(handleResponse);
}

function createAudience(data, accountId) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/audiences/${accountId}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
