import '../../_helpers/sort-by.js'
export const listPushAdsRegions = [
    {
        "id": 364647,
        "value": "2",
        "label": "Alaska, USA"
    },
    {
        "id": 363912,
        "value": "1",
        "label": "Alabama, USA"
    },
    {
        "id": 367286,
        "value": "8931",
        "label": "Aol, USA"
    },
    {
        "id": 367039,
        "value": "4",
        "label": "Arkansas, USA"
    },
    {
        "id": 366609,
        "value": "3",
        "label": "Arizona, USA"
    },
    {
        "id": 367108,
        "value": "5",
        "label": "California, USA"
    },
    {
        "id": 367188,
        "value": "6",
        "label": "Colorado, USA"
    },
    {
        "id": 367222,
        "value": "7",
        "label": "Connecticut, USA"
    },
    {
        "id": 367299,
        "value": "9",
        "label": "District Of Columbia, USA"
    },
    {
        "id": 367257,
        "value": "8",
        "label": "Delaware, USA"
    },
    {
        "id": 363914,
        "value": "10",
        "label": "Florida, USA"
    },
    {
        "id": 364210,
        "value": "12",
        "label": "Hawaii, USA"
    },
    {
        "id": 364515,
        "value": "16",
        "label": "Iowa, USA"
    },
    {
        "id": 364403,
        "value": "13",
        "label": "Idaho, USA"
    },
    {
        "id": 364425,
        "value": "14",
        "label": "Illinois, USA"
    },
    {
        "id": 364457,
        "value": "15",
        "label": "Indiana, USA"
    },
    {
        "id": 364534,
        "value": "17",
        "label": "Kansas, USA"
    },
    {
        "id": 364550,
        "value": "18",
        "label": "Kentucky, USA"
    },
    {
        "id": 364580,
        "value": "19",
        "label": "Louisiana, USA"
    },
    {
        "id": 364723,
        "value": "22",
        "label": "Massachusetts, USA"
    },
    {
        "id": 364694,
        "value": "21",
        "label": "Maryland, USA"
    },
    {
        "id": 364649,
        "value": "20",
        "label": "Maine, USA"
    },
    {
        "id": 364745,
        "value": "23",
        "label": "Michigan, USA"
    },
    {
        "id": 364835,
        "value": "24",
        "label": "Minnesota, USA"
    },
    {
        "id": 365469,
        "value": "26",
        "label": "Missouri, USA"
    },
    {
        "id": 365037,
        "value": "25",
        "label": "Mississippi, USA"
    },
    {
        "id": 365638,
        "value": "27",
        "label": "Montana, USA"
    },
    {
        "id": 366933,
        "value": "34",
        "label": "North Carolina, USA"
    },
    {
        "id": 366937,
        "value": "35",
        "label": "North Dakota, USA"
    },
    {
        "id": 365655,
        "value": "28",
        "label": "Nebraska, USA"
    },
    {
        "id": 366611,
        "value": "30",
        "label": "New Hampshire, USA"
    },
    {
        "id": 366912,
        "value": "31",
        "label": "New Jersey, USA"
    },
    {
        "id": 366918,
        "value": "32",
        "label": "New Mexico, USA"
    },
    {
        "id": 365736,
        "value": "29",
        "label": "Nevada, USA"
    },
    {
        "id": 366921,
        "value": "33",
        "label": "New York, USA"
    },
    {
        "id": 366944,
        "value": "36",
        "label": "Ohio, USA"
    },
    {
        "id": 366960,
        "value": "37",
        "label": "Oklahoma, USA"
    },
    {
        "id": 366970,
        "value": "38",
        "label": "Oregon, USA"
    },
    {
        "id": 366978,
        "value": "39",
        "label": "Pennsylvania, USA"
    },
    {
        "id": 367041,
        "value": "40",
        "label": "Rhode Island, USA"
    },
    {
        "id": 367044,
        "value": "41",
        "label": "South Carolina, USA"
    },
    {
        "id": 367048,
        "value": "42",
        "label": "South Dakota, USA"
    },
    {
        "id": 367053,
        "value": "43",
        "label": "Tennessee, USA"
    },
    {
        "id": 367056,
        "value": "44",
        "label": "Texas, USA"
    },
    {
        "id": 367060,
        "value": "45",
        "label": "Utah, USA"
    },
    {
        "id": 367068,
        "value": "47",
        "label": "Virginia, USA"
    },
    {
        "id": 367064,
        "value": "46",
        "label": "Vermont, USA"
    },
    {
        "id": 367070,
        "value": "48",
        "label": "Washington, USA"
    },
    {
        "id": 367307,
        "value": "9203",
        "label": "Webtv, USA"
    },
    {
        "id": 367110,
        "value": "50",
        "label": "Wisconsin, USA"
    },
    {
        "id": 367076,
        "value": "49",
        "label": "West Virginia, USA"
    },
    {
        "id": 367123,
        "value": "51",
        "label": "Wyoming, USA"
    },
    {
        "id": 364054,
        "value": "11",
        "label": "Georgia, USA"
    }
].sortBy('label')