import { adgroupCreativeConstants } from '../_constants';

export function adgroupCreatives(state = {}, action) {
  switch (action.type) {
    //=====================================
    // GET
    //=====================================
    case adgroupCreativeConstants.GET_REQUEST:
      return {
        loading: true,
        items:[]
      };
    case adgroupCreativeConstants.GET_SUCCESS:
      return {
        loading: false,
        items: action.adgroupCreatives
      };
    case adgroupCreativeConstants.GET_FAILURE:
      return { 
        loading: false,
        items:[],
        error: action.error
      };
    //=====================================
    // ADD
    //=====================================
    case adgroupCreativeConstants.ADD_REQUEST:
      return {
        loading: true,
        items:[]
      };
    case adgroupCreativeConstants.ADD_SUCCESS:
      return {
        loading: false,
        items: action.adgroupCreatives
      };
    case adgroupCreativeConstants.ADD_FAILURE:
      return { 
        loading: false,
        items:[],
        error: action.error
      };
      //=====================================
      // DEFAULT
      //=====================================
    default:
      return state
  }
}