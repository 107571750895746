import config from 'config';
import { authHeader } from '../_helpers';
import axios from 'axios';

export const creativeService = {
    getCreativesInAccount,
    getCreativesInAdgroup,
    updateCreative,     // Update Creative
    addCreative,        // Add to local DB
    uploadFile,         // Upload for preview
    
    //Video
    createNewVideoAsset,    //1. Create a new video asset
    getStatusOfVideoUpload, //2. Get status of video upload

    //Push
    addCreativePush,        // Create propeller campaign
    addMoreCreativePush,    // Add more creatives to an existing propeller campaign
    editCreativePush        // Edit propeller creative
};

function getStatusOfVideoUpload(videoAssetId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiURL}/creatives/videoUpload/${videoAssetId}`, requestOptions).then(handleResponse);
}

function getCreativesInAdgroup(adGroupId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiURL}/creatives/adGroup/${adGroupId}`, requestOptions).then(handleResponse);
}

function getCreativesInAccount(accountId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiURL}/creatives/account/${accountId}`, requestOptions).then(handleResponse);
}



function updateCreative(data) {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };
    return fetch(`${config.apiURL}/creatives/${data.id}`, requestOptions).then(handleResponse);
}

function addCreative(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };
    return fetch(`${config.apiURL}/creatives/`, requestOptions).then(handleResponse);
}

function addCreativePush(data, accountId, selectedAdgroup) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };
    return fetch(`${config.apiURL}/creatives/push/${accountId}/${selectedAdgroup}`, requestOptions).then(handleResponse);
}

function editCreativePush(data) {
    const requestOptions = {
        method: 'PATCH',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };
    return fetch(`${config.apiURL}/creatives/push/${data.id}`, requestOptions).then(handleResponse);
}

function addMoreCreativePush(data, accountId, selectedAdgroup) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };
    return fetch(`${config.apiURL}/creatives/push/additional/${accountId}/${selectedAdgroup}`, requestOptions).then(handleResponse);
}

function createNewVideoAsset(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };
    return fetch(`${config.apiURL}/creatives/videoassets/`, requestOptions).then(handleResponse);
}

function uploadFile(file, field){
    const data = new FormData()
    data.append('file', file)
    // const requestOptions = {
    //     method: 'POST',
    //     headers: authHeader(),
    //     body: data
    // };
    // return fetch(`${config.apiURL}/creatives/preview/`, requestOptions).then(handleResponse);

    return axios.request({
        method: "post", 
        url: `${config.apiURL}/creatives/preview/${field}`, 
        headers: authHeader(),
        data: data, 
        onUploadProgress: (p) => {
        //   console.log(p.loaded / p.total); 
            return({
                fileprogress: p.loaded / p.total
            })
        }
    }).then (data => {
        return data.data
        this.setState({
        fileprogress: 1.0,
        })
    })
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}