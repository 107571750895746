import React, { useState, useEffect, Component, useDebugValue } from 'react'
import {BrowserRouter as Router, Link, useHistory } from "react-router-dom";

//Redux
import { connect } from 'react-redux';
import { 
    userActions,
    campaignActions,
    statisticActions
} from '../_actions';

//React Select
import { listCreativeType } from '../_constants/data'
import { styleSelectBasic, styleSelectMultiple } from '../_constants/settings'
import Select from 'react-select'

//Components
import {Footer} from '../_components/Footer';
import {LeftNavigation} from '../_components/LeftNavigation'

//Helpers
import '../_helpers/sort-by.js'

//Moments JS
import Moment from 'react-moment';
import moment from 'moment';

//Toggle
import Toggle from 'react-toggle'
import '../_stylesheets/css/react-toggle.css'

//List Data
import { 
    listIABCategory, 
    listLanguage, 
    listAdgroupType,
    listCampaignType,
    listAdType,

} from '../_constants/data'


const CampaignList = (props) => {
    const useStateWithLabel = (initialValue, label) => {
        const [value, setValue] = useState(initialValue);
        useDebugValue(`${label}: ${value}`);
        return [value, setValue];
    };

    const { user, users, campaigns, statistics } = props;

    //Used as the actual Date Range parameter
    const [startDate, setStartDate] = useStateWithLabel(moment().subtract(60, 'days'), "startDate");
    // const [startDate, setStartDate] = useStateWithLabel(moment("2021/01/01"), "startDate");
    const [endDate, setEndDate] = useStateWithLabel(moment(), "endDate");


    //1. WHEN user data is ready, GET all campaigns
    useEffect(() => {
        props.getCampaigns(user.accountId);
    },[user]);

    //2. WHEN campaign is data is ready, GET its statistics
    useEffect(() => {
        if(campaigns.isDone){
            props.getStatsPerCampaign(user.accountId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
        }
    },[
        campaigns.isDone,
    ]);

    //Navigate onClick
    const history = useHistory();
    const routeChange = (path) =>{ 
    history.push(path);
    }
  

  

    //Search input fields
    const [campaignSearch, setCampaignSearch] = useStateWithLabel("", "campaignSearch");

    //Returns the current selected campaign
    const [selectedCampaign, setSelectedCampaign] = useStateWithLabel(null, "selectedCampaign");

    return (
        <div className="creative-library">

            <LeftNavigation />
            <div className="canvas">
                <div className={`custom-panel creativelib table`}>
                    <div className="header">
                        Campaigns
                    </div>
                    {
                        campaigns.loading ?
                        <div className="loading">
                            Initializing...
                        </div>
                        :
                        <table className="home primary fixed-header has-hover"> 
                            <thead>
                                <tr>
                                        <th className="id">Campaign ID</th>
                                        <th className="name">Campaign Name</th>
                                        <th className="category">Category</th>
                                        <th className="options">Type</th>
                                        <th className="options">Language</th>
                                        <th className="options">Archived</th>
                                        <th className="options">Autopilot</th>
                                        <th className="stats text-right">Impression</th>
                                        <th className="stats text-right">Clicks</th>
                                        <th className="stats text-right">Media Spend</th>   
                                        <th className="stats text-right">Data Spend</th>   
                                        <th className="stats text-right">Total Spend</th>   
                                        {/* <th className="stats text-right">Daily Budget</th>    */}
                                        {/* <th className="stats text-right">Yesterday</th>    */}
                                        <th className="stats text-right">ECPC</th>
                                </tr>
                            </thead>
                            <tbody>
                                    {statistics.campaign && campaigns.items && campaigns.items.filter(campaign => 
                                        campaign.name.toUpperCase().indexOf(campaignSearch.toUpperCase()) >= 0 ||
                                        campaign.id.indexOf(campaignSearch) >= 0
                                        ).map((campaign, index) =>
                                        <tr className={`clickable ${campaign.archived ? 'd-none' : ''} ${campaign.id == selectedCampaign ? 'selected' : ''}`} onClick={() => routeChange(`/adgroup/${campaign.id}`)} key={campaign.id}>
                                            <td className="id"><span>{campaign.id}</span></td>
                                            <td className="name"><span className="blue-link plain">{campaign.name}</span></td>
                                            <td className="category">
                                                <span>
                                                    {
                                                        listIABCategory.filter(iabCategory => iabCategory.value == campaign.iabCategory).map(iabCategory => {
                                                            return iabCategory.label
                                                        }).toString()
                                                    }
                                                </span>
                                            </td>
                                            <td className="options">
                                                <span>
                                                    {
                                                        listCampaignType.filter(type => type.value == campaign.type).map(type => {
                                                            return type.label
                                                        }).toString()
                                                    }
                                                </span>
                                            </td>
                                            <td className="options">
                                                <span>
                                                    {
                                                        listLanguage.filter(language => language.value == campaign.language).map(language => {
                                                            return language.label
                                                        }).toString()
                                                    }
                                                </span>
                                            </td>
                                            <td className="options"><span>{campaign.archived ? 'true' : 'false'}</span></td>
                                            <td className="options"><span>{campaign.autopilot ? 'true' : 'false'}</span></td>
                                            <td className="stats text-right">
                                                <span>
                                                    {
                                                        statistics.campaign.filter(item => item.campaignId == campaign.id).length == 0 ? "-" : statistics.campaign.filter(item => item.campaignId == campaign.id)[0].impressions.toLocaleString()
                                                    }
                                                </span>
                                            </td>

                                            {/* CAMPAIGN CLICKS */}
                                            <td className="stats text-right">
                                                <span>
                                                    {
                                                        statistics.campaign.filter(item => item.campaignId == campaign.id).length == 0 ? "-" : statistics.campaign.filter(item => item.campaignId == campaign.id)[0].clicks.toLocaleString()
                                                    }
                                                </span>
                                            </td>
                                            <td className="stats text-right">
                                                <span>
                                                    {
                                                        statistics.campaign.filter(item => item.campaignId == campaign.id).length == 0
                                                        ? "-"
                                                        :
                                                            `$${parseFloat(
                                                                    statistics.campaign.filter(item => item.campaignId == campaign.id)[0].mediaSpend + statistics.campaign.filter(item => item.campaignId == campaign.id)[0].dataspend_with_margin
                                                                ).toLocaleString(undefined, {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })}`
                                                    }
                                                </span>
                                            </td>
                                            {/* <td className="stats text-right">
                                                <span>
                                                    {
                                                        statistics.campaign.filter(item => item.campaignId == campaign.id).length == 0
                                                        ? "-"
                                                        :
                                                            `$${parseFloat(statistics.campaign.filter(item => item.campaignId == campaign.id)[0].dailyBudget).toLocaleString(undefined, {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })}`
                                                    }
                                                </span>
                                            </td> */}
                                            {/* <td className="stats text-right">
                                                <span>
                                                    {
                                                        statistics.campaign.filter(item => item.campaignId == campaign.id).length == 0
                                                        ? "-"
                                                        :
                                                            `$${parseFloat(statistics.campaign.filter(item => item.campaignId == campaign.id)[0].yesterday_spend).toLocaleString(undefined, {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })}`
                                                    }
                                                </span>
                                            </td> */}
                                            <td className="stats text-right">
                                                <span>
                                                    {
                                                        statistics.campaign.filter(item => item.campaignId == campaign.id).length == 0 
                                                        ? "-"
                                                        : 
                                                            //Total Spend Divided by Clicks
                                                            `$${parseFloat(
                                                                statistics.campaign.filter(item => item.campaignId == campaign.id)[0].mediaSpend).toLocaleString(undefined, {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                            })}`
                                                    } 
                                                </span>
                                            </td>
                                            <td className="stats text-right">
                                                <span>
                                                    {
                                                        statistics.campaign.filter(item => item.campaignId == campaign.id).length == 0 
                                                        ? "-"
                                                        : 
                                                            //Total Spend Divided by Clicks
                                                            `$${parseFloat(
                                                                statistics.campaign.filter(item => item.campaignId == campaign.id)[0].dataspend_with_margin).toLocaleString(undefined, {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                            })}`
                                                    } 
                                                </span>
                                            </td>
                                            <td className="stats text-right">
                                                <span>
                                                    {
                                                        statistics.campaign.filter(item => item.campaignId == campaign.id).length == 0 
                                                        ? "-"
                                                        : 
                                                            //Total Spend Divided by Clicks
                                                            `$${parseFloat(
                                                                (statistics.campaign.filter(item => item.campaignId == campaign.id)[0].mediaSpend + statistics.campaign.filter(item => item.campaignId == campaign.id)[0].dataspend_with_margin) / 
                                                                statistics.campaign.filter(item => item.campaignId == campaign.id)[0].clicks).toLocaleString(undefined, {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                            })}`
                                                    } 
                                                </span>
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        
            <Footer />
        </div>
    )
}

const mapState = state => ({
    user        : state.users.current,
    users       : state.users,
    statistics  : state.statistics,
    campaigns   : state.campaigns,
})

const actionCreators = {
    getCampaigns        : campaignActions.getCampaigns,
    getStatsPerCampaign : statisticActions.getStatsPerCampaign,
}

const connectedCampaignList = connect(mapState, actionCreators)(CampaignList);

export { connectedCampaignList as CampaignList };