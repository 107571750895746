export const geolocationConstants = {
    GET_INCLUDED_CITIES_REQUEST     : 'GET_INCLUDED_CITIES_REQUEST',
    GET_INCLUDED_CITIES_SUCCESS     : 'GET_INCLUDED_CITIES_SUCCESS',
    GET_INCLUDED_CITIES_FAILURE     : 'GET_INCLUDED_CITIES_FAILURE',
    
    GET_EXCLUDED_CITIES_REQUEST     : 'GET_EXCLUDED_CITIES_REQUEST',
    GET_EXCLUDED_CITIES_SUCCESS     : 'GET_EXCLUDED_CITIES_SUCCESS',
    GET_EXCLUDED_CITIES_FAILURE     : 'GET_EXCLUDED_CITIES_FAILURE',
    
    GET_CITIES_REQUEST             : 'GET_CITIES_REQUEST',
    GET_CITIES_SUCCESS             : 'GET_CITIES_SUCCESS',
    GET_CITIES_FAILURE             : 'GET_CITIES_FAILURE',
    
    GET_GOOGLE_COORDINATES_REQUEST             : 'GET_GOOGLE_COORDINATES_REQUEST',
    GET_GOOGLE_COORDINATES_SUCCESS             : 'GET_GOOGLE_COORDINATES_SUCCESS',
    GET_GOOGLE_COORDINATES_FAILURE             : 'GET_GOOGLE_COORDINATES_FAILURE',
 
    GET_INCLUDED_PUSH_COUNTRIES_REQUEST             : 'GET_INCLUDED_PUSH_COUNTRIES_REQUEST',
    GET_INCLUDED_PUSH_COUNTRIES_SUCCESS             : 'GET_INCLUDED_PUSH_COUNTRIES_SUCCESS',
    GET_INCLUDED_PUSH_COUNTRIES_FAILURE             : 'GET_INCLUDED_PUSH_COUNTRIES_FAILURE',
    
    GET_INCLUDED_PUSH_CITIES_REQUEST             : 'GET_INCLUDED_PUSH_CITIES_REQUEST',
    GET_INCLUDED_PUSH_CITIES_SUCCESS             : 'GET_INCLUDED_PUSH_CITIES_SUCCESS',
    GET_INCLUDED_PUSH_CITIES_FAILURE             : 'GET_INCLUDED_PUSH_CITIES_FAILURE',
    
    GET_INCLUDED_PUSH_REGIONS_REQUEST             : 'GET_INCLUDED_PUSH_REGIONS_REQUEST',
    GET_INCLUDED_PUSH_REGIONS_SUCCESS             : 'GET_INCLUDED_PUSH_REGIONS_SUCCESS',
    GET_INCLUDED_PUSH_REGIONS_FAILURE             : 'GET_INCLUDED_PUSH_REGIONS_FAILURE',
};
