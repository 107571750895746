import { bluekaiConstants } from '../_constants';
import { bluekaiService } from '../_services';
import { alertActions, progressActions } from './';
import { history } from '../_helpers';

export const bluekaiActions = {
    getBluekaiList,
};
function getBluekaiList() {
    return dispatch => {
        dispatch(request());
        bluekaiService.getBluekaiList()
            .then(
                bluekai => {
                    dispatch(success(bluekai));
                },
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: bluekaiConstants.GET_REQUEST } }
    function success(bluekai) { return { type: bluekaiConstants.GET_SUCCESS, bluekai } }
    function failure(error) { return { type: bluekaiConstants.GET_FAILURE, error } }
}

