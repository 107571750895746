import { adgroupConstants } from '../_constants';

export function adgroups(state = {}, action) {
  switch (action.type) {
    //=====================================
    // GET
    //=====================================
    case adgroupConstants.GET_REQUEST:
      return {
        isDone: false
      };
    case adgroupConstants.GET_SUCCESS:
      return {
        items: action.adgroups,
        isDone: true
      };
    case adgroupConstants.GET_FAILURE:
      return { 
        error: action.error,
        isDone: true
      };
    //=====================================
    // GET LOCK LIST
    //=====================================
    case adgroupConstants.GETLOCK_REQUEST:
      return {
        ...state,
      };
    case adgroupConstants.GETLOCK_SUCCESS:
      return {
        ...state,
        lock:action.adgroups,
      };
    case adgroupConstants.GETLOCK_FAILURE:
      return { 
        error: action.error,
      };
      //=====================================
      // GET DETAIL
      //=====================================
      case adgroupConstants.GET_DETAIL_REQUEST:
        return {
          loading: true
        };
      case adgroupConstants.GET_DETAIL_SUCCESS:
        return {
          items: action.adgroup
        };
      case adgroupConstants.GET_DETAIL_FAILURE:
        return { 
          error: action.error
        };
      //=====================================
      // UPDATE
      //=====================================
      case adgroupConstants.UPDATE_REQUEST:
        return {
          ...state,
          loading: true
        };
      case adgroupConstants.UPDATE_SUCCESS:
        return {
          ...state,
          isDone: true,
          loading: false,
          items: action.adgroups,
        };
      case adgroupConstants.UPDATE_FAILURE:
        return {
          ...state,
          isDone: true,
          loading: false,
          error:action.error
        };
      //=====================================
      // CREATE
      //=====================================
      case adgroupConstants.CREATE_REQUEST:
        return { 
          ...state,
          loading: true 
        };
      case adgroupConstants.CREATE_SUCCESS:
        return {
          items: action.adgroups,
          isDone: true,
          loading:false
        };
      case adgroupConstants.CREATE_FAILURE:
        return {
          ...state,
          loading:false,
          isDone: true,
          error: action.error
        };
      //=====================================
      // SELECT
      // Note:
      // Sets the selected adgroupID
      // So the sideDrawer knows which 
      // adgroup to load when editing.
      //=====================================
      case adgroupConstants.SELECT_ADGROUP:
        return {
          ...state,
          selected: action.adgroupID
        };
      //=====================================
      // DEFAULT
      //=====================================
    default:
      return state
  }
}