import { brandRefSupernovaConstants } from '../_constants';
import { brandRefSupernovaService } from '../_services';
import { alertActions, progressActions } from './';
import { history } from '../_helpers';

export const brandRefSupernova = {
    getByAccountId,
    addBrandRefSupernova,
    updateBrandRefSupernova
};



function getByAccountId(accountId) {
    return dispatch => {
        dispatch(request());

        brandRefSupernovaService.getByAccountId(accountId)
            .then(
                brands => dispatch(success(brands)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: brandRefSupernovaConstants.GET_REQUEST } }
    function success(brands) { return { type: brandRefSupernovaConstants.GET_SUCCESS, brands } }
    function failure(error) { return { type: brandRefSupernovaConstants.GET_FAILURE, error } }
}

function addBrandRefSupernova(data) {
    return dispatch => {
        dispatch(request());

        brandRefSupernovaService.addBrandRefSupernova(data)
            .then(
                brands => dispatch(success(brands)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: brandRefSupernovaConstants.CREATE_REQUEST } }
    function success(brands) { return { type: brandRefSupernovaConstants.CREATE_SUCCESS, brands } }
    function failure(error) { return { type: brandRefSupernovaConstants.CREATE_FAILURE, error } }
}

function updateBrandRefSupernova(data) {
    return dispatch => {
        dispatch(request());

        brandRefSupernovaService.updateBrandRefSupernova(data)
            .then(
                brands => dispatch(success(brands)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: brandRefSupernovaConstants.UPDATE_REQUEST } }
    function success(brands) { return { type: brandRefSupernovaConstants.UPDATE_SUCCESS, brands } }
    function failure(error) { return { type: brandRefSupernovaConstants.UPDATE_FAILURE, error } }
}