import config from 'config';
import { authHeader } from '../_helpers';

export const marketResearchService = {
    getMarketResearch,
    createMarketStudy,
    createClient,
    createBrand,
    updateMarketStudy,
    removeClient,
    removeBrand,
    removeStudy,
};
function getMarketResearch(accountId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/market_research/${accountId}`, requestOptions).then(handleResponse);
}
function createMarketStudy(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/market_research`, requestOptions).then(handleResponse);
}
function createClient(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/market_research`, requestOptions).then(handleResponse);
}
function createBrand(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/market_research`, requestOptions).then(handleResponse);
}
function updateMarketStudy(data, id) {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/market_research/${id}`, requestOptions).then(handleResponse);
}

function removeClient(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/market_research/client`, requestOptions).then(handleResponse);
}
function removeBrand(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/market_research/brand`, requestOptions).then(handleResponse);
}
function removeStudy(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/market_research/study`, requestOptions).then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}