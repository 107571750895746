import { geolocationConstants } from '../_constants';
import { geolocationService } from '../_services';
import { alertActions, progressActions } from './';
import { history } from '../_helpers';

export const geolocationActions = {
    getIncludedKeys,
    getExcludedKeys,
    getCities,
    getGoogleCoordindates,

    //Push Campaign
    getIncludedPushCountries,
    getIncludedPushCities,
    getIncludedRegions

};

function getIncludedPushCountries(keys) {
    return dispatch => {
        dispatch(request());

        geolocationService.getIncludedPushCountries(keys)
            .then(
                geolocations => dispatch(success(geolocations)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: geolocationConstants.GET_INCLUDED_PUSH_COUNTRIES_REQUEST } }
    function success(geolocations) { return { type: geolocationConstants.GET_INCLUDED_PUSH_COUNTRIES_SUCCESS, geolocations } }
    function failure(error) { return { type: geolocationConstants.GET_INCLUDED_PUSH_COUNTRIES_FAILURE, error } }
}

function getIncludedPushCities(keys) {
    return dispatch => {
        dispatch(request());

        geolocationService.getIncludedPushCities(keys)
            .then(
                geolocations => dispatch(success(geolocations)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: geolocationConstants.GET_INCLUDED_PUSH_CITIES_REQUEST } }
    function success(geolocations) { return { type: geolocationConstants.GET_INCLUDED_PUSH_CITIES_SUCCESS, geolocations } }
    function failure(error) { return { type: geolocationConstants.GET_INCLUDED_PUSH_CITIES_FAILURE, error } }
}

function getIncludedRegions(keys) {
    return dispatch => {
        dispatch(request());

        geolocationService.getIncludedRegions(keys)
            .then(
                geolocations => dispatch(success(geolocations)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: geolocationConstants.GET_INCLUDED_PUSH_REGIONS_REQUEST } }
    function success(geolocations) { return { type: geolocationConstants.GET_INCLUDED_PUSH_REGIONS_SUCCESS, geolocations } }
    function failure(error) { return { type: geolocationConstants.GET_INCLUDED_PUSH_REGIONS_FAILURE, error } }
}

function getIncludedKeys(keys) {
    return dispatch => {
        dispatch(request());

        geolocationService.getGeolocationsByKeys(keys)
            .then(
                geolocations => dispatch(success(geolocations)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: geolocationConstants.GET_INCLUDED_CITIES_REQUEST } }
    function success(geolocations) { return { type: geolocationConstants.GET_INCLUDED_CITIES_SUCCESS, geolocations } }
    function failure(error) { return { type: geolocationConstants.GET_INCLUDED_CITIES_FAILURE, error } }
}

function getExcludedKeys(keys) {
    return dispatch => {
        dispatch(request());

        geolocationService.getGeolocationsByKeys(keys)
            .then(
                geolocations => dispatch(success(geolocations)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: geolocationConstants.GET_EXCLUDED_CITIES_REQUEST } }
    function success(geolocations) { return { type: geolocationConstants.GET_EXCLUDED_CITIES_SUCCESS, geolocations } }
    function failure(error) { return { type: geolocationConstants.GET_EXCLUDED_CITIES_FAILURE, error } }
}

function getCities(nameContains) {
    return dispatch => {
        dispatch(request());

        geolocationService.getGeolocationsByTypes("CITY", nameContains)
            .then(
                geolocations => dispatch(success(geolocations)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: geolocationConstants.GET_CITIES_REQUEST } }
    function success(geolocations) { return { type: geolocationConstants.GET_CITIES_SUCCESS, geolocations } }
    function failure(error) { return { type: geolocationConstants.GET_CITIES__FAILURE, error } }
}

function getGoogleCoordindates(searchText) {
    return dispatch => {
        dispatch(request());

        geolocationService.getGoogleCoordindates(searchText)
            .then(
                googlemap => dispatch(success(googlemap)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: geolocationConstants.GET_GOOGLE_COORDINATES_REQUEST } }
    function success(googlemap) { return { type: geolocationConstants.GET_GOOGLE_COORDINATES_SUCCESS, googlemap } }
    function failure(error) { return { type: geolocationConstants.GET_GOOGLE_COORDINATES_FAILURE, error } }
}

