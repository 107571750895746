export const brandsConstants = {
    GET_REQUEST: 'BRANDS_GET_REQUEST',
    GET_SUCCESS: 'BRANDS_GET_SUCCESS',
    GET_FAILURE: 'BRANDS_GET_FAILURE',

    CREATE_REQUEST: 'BRANDS_CREATE_REQUEST',
    CREATE_SUCCESS: 'BRANDS_CREATE_SUCCESS',
    CREATE_FAILURE: 'BRANDS_CREATE_FAILURE',

    UPDATE_REQUEST: 'BRANDS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'BRANDS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'BRANDS_UPDATE_FAILURE',
};
