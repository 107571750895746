import '../../_helpers/sort-by.js'
export const listPushAdsOSVersion = [
    {
        "id": 208713,
        "value": "android2",
        "label": "Android 2"
    },
    {
        "id": 208714,
        "value": "android3",
        "label": "Android 3"
    },
    {
        "id": 208715,
        "value": "android4",
        "label": "Android 4"
    },
    {
        "id": 208716,
        "value": "android5",
        "label": "Android 5"
    },
    {
        "id": 409716,
        "value": "android6",
        "label": "Android 6"
    },
    {
        "id": 410279,
        "value": "android7",
        "label": "Android 7"
    },
    {
        "id": 409749,
        "value": "ios10",
        "label": "iOS 10"
    },
    {
        "id": 409751,
        "value": "ios4",
        "label": "iOS 4"
    },
    {
        "id": 409752,
        "value": "ios5",
        "label": "iOS 5"
    },
    {
        "id": 208744,
        "value": "ios6",
        "label": "iOS 6"
    },
    {
        "id": 208745,
        "value": "ios7",
        "label": "iOS 7"
    },
    {
        "id": 208746,
        "value": "ios8",
        "label": "iOS 8"
    },
    {
        "id": 408539,
        "value": "ios9",
        "label": "iOS 9"
    },
    {
        "id": 208749,
        "value": "mac10.10",
        "label": "Mac OS X 10.10"
    },
    {
        "id": 408540,
        "value": "mac10.11",
        "label": "Mac OS X 10.11"
    },
    {
        "id": 409750,
        "value": "mac10.12",
        "label": "Mac OS X 10.12"
    },
    {
        "id": 409753,
        "value": "mac10.5",
        "label": "Mac OS X 10.5"
    },
    {
        "id": 409754,
        "value": "mac10.6",
        "label": "Mac OS X 10.6"
    },
    {
        "id": 409755,
        "value": "mac10.7",
        "label": "Mac OS X 10.7"
    },
    {
        "id": 208750,
        "value": "mac10.8",
        "label": "Mac OS X 10.8"
    },
    {
        "id": 208751,
        "value": "mac10.9",
        "label": "Mac OS X 10.9"
    },
    {
        "id": 208777,
        "value": "other",
        "label": "Other"
    },
    {
        "id": 208877,
        "value": "vista",
        "label": "Windows Vista"
    },
    {
        "id": 408532,
        "value": "win10",
        "label": "Windows 10"
    },
    {
        "id": 208902,
        "value": "win7",
        "label": "Windows 7"
    },
    {
        "id": 208903,
        "value": "win8",
        "label": "Windows 8"
    },
    {
        "id": 409756,
        "value": "windows_phone7",
        "label": "Windows Phone 7"
    },
    {
        "id": 409757,
        "value": "windows_phone8",
        "label": "Windows Phone 8"
    },
    {
        "id": 208906,
        "value": "xp",
        "label": "Windows XP"
    },
    {
        "id": 465382,
        "value": "ios11",
        "label": "iOS 11"
    },
    {
        "id": 465467,
        "value": "android8",
        "label": "Android 8"
    },
    {
        "id": 465475,
        "value": "mac10.13",
        "label": "Mac OS X 10.13"
    },
    {
        "id": 465528,
        "value": "android9",
        "label": "Android 9"
    },
    {
        "id": 465529,
        "value": "ios12",
        "label": "iOS 12"
    },
    {
        "id": 465530,
        "value": "mac10.14",
        "label": "Mac OS X 10.14"
    },
    {
        "id": 465636,
        "value": "android10",
        "label": "Android 10"
    },
    {
        "id": 465637,
        "value": "ios13",
        "label": "iOS 13"
    },
    {
        "id": 465642,
        "value": "mac10.15",
        "label": "Mac OS X 10.15"
    },
    {
        "id": 465651,
        "value": "kai_os2.5",
        "label": "KaiOS 2.5"
    },
    {
        "id": 465652,
        "value": "kai_os1.0",
        "label": "KaiOS 1.0"
    },
    {
        "id": 465653,
        "value": "kai_os2.0",
        "label": "KaiOS 2.0"
    },
    {
        "id": 465654,
        "value": "kai_osother",
        "label": "KaiOS other"
    },
    {
        "id": 513761,
        "value": "ios14",
        "label": "iOS 14"
    },
    {
        "id": 513772,
        "value": "android11",
        "label": "Android 11"
    },
    {
        "id": 513777,
        "value": "mac11.0",
        "label": "Mac OS X 11.0"
    },
    {
        "id": 527248,
        "value": "ios15",
        "label": "iOS 15"
    },
    {
        "id": 527257,
        "value": "android12",
        "label": "Android 12"
    },
    {
        "id": 527559,
        "value": "ios16",
        "label": "iOS 16"
    },
    {
        "id": 527603,
        "value": "mac12.0",
        "label": "Mac OS Monterey"
    },
    {
        "id": 527646,
        "value": "android13",
        "label": "Android 13"
    }
].sortBy('label')