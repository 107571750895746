import '../../_helpers/sort-by.js'
export const listPushAdsBrowser = [
    {
        "id": 409745,
        "value": "android_webview",
        "label": "Android WebView"
    },
    {
        "id": 403986,
        "value": "chrome",
        "label": "Google Chrome"
    },
    {
        "id": 408533,
        "value": "edge",
        "label": "Microsoft Edge"
    },
    {
        "id": 408534,
        "value": "fb",
        "label": "Facebook Browser"
    },
    {
        "id": 404037,
        "value": "firefox",
        "label": "Mozilla Firefox"
    },
    {
        "id": 404091,
        "value": "ie",
        "label": "Internet Explorer"
    },
    {
        "id": 404200,
        "value": "native",
        "label": "Android Browser"
    },
    {
        "id": 404228,
        "value": "opera",
        "label": "Opera"
    },
    {
        "id": 404229,
        "value": "opera_mini",
        "label": "Opera Mini"
    },
    {
        "id": 409748,
        "value": "other",
        "label": "Other"
    },
    {
        "id": 404251,
        "value": "puffin",
        "label": "Puffin"
    },
    {
        "id": 404268,
        "value": "safari",
        "label": "Apple Safari"
    },
    {
        "id": 419983,
        "value": "samsung",
        "label": "Samsung Browser"
    },
    {
        "id": 404340,
        "value": "uc",
        "label": "UC Browser"
    },
    {
        "id": 407449,
        "value": "yandex",
        "label": "Yandex Browser"
    },
    {
        "id": 465532,
        "value": "miui",
        "label": "Miui Browser"
    },
    {
        "id": 465533,
        "value": "netfront",
        "label": "NetFront Internet Browser"
    },
    {
        "id": 465675,
        "value": "coc_coc",
        "label": "Coc Coc"
    }
].sortBy('label')