import { tagSupernovaConstants } from '../_constants';

export function tagSupernova(state = {}, action) {
  switch (action.type) {
    //=====================================
    // GET
    //=====================================
    case tagSupernovaConstants.GET_REQUEST:
      return {
        loading: true
      };
    case tagSupernovaConstants.GET_SUCCESS:
      return {
        items: action.tagSupernova
      };
    case tagSupernovaConstants.GET_FAILURE:
      return { 
        error: action.error
      };
      //=====================================
      // DEFAULT
      //=====================================
    default:
      return state
  }
}