import '../../_helpers/sort-by.js'
export const listDeviceMake = [
    { "value": "APPLE", "label": "Apple" },
    { "value": "SAMSUNG", "label": "Samsung" },
    { "value": "GOOGLE", "label": "Google" },
    { "value": "MOTOROLA", "label": "Motorola" },
    { "value": "SONY", "label": "Sony" },
    { "value": "LG", "label": "LG" },
    { "value": "HTC", "label": "HTC" },
    { "value": "NOKIA", "label": "Nokia" },
    { "value": "XIAOMI", "label": "Xiaomi" },
    { "value": "ONEPLUS", "label": "OnePlus" },
    { "value": "HUAWEI", "label": "Huawei" },
    { "value": "OPPO", "label": "Oppo" },
    { "value": "VIVO", "label": "Vivo" },
    { "value": "LENOVO", "label": "Lenovo" },
    { "value": "ASUS", "label": "Asus" }
].sortBy('label')