import config from 'config';
import { authHeader } from '../_helpers';

export const geolocationService = {
    getGeolocationsByKeys,
    getGeolocationsByTypes,
    getGoogleCoordindates,

    //Push campaign
    getIncludedPushCountries,
    getIncludedPushCities,
    getIncludedRegions
};

function getIncludedPushCountries(ids) {

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({"type":"COUNTRY","ids":ids})
    };

    return fetch(`${config.apiURL}/geolocations/l2`, requestOptions).then(handleResponse);
}
function getIncludedPushCities(ids) {

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({"type":"CITY","ids":ids})
    };

    return fetch(`${config.apiURL}/geolocations/l2`, requestOptions).then(handleResponse);
}
function getIncludedRegions(ids) {

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({"type":"REGION","ids":ids})
    };

    return fetch(`${config.apiURL}/geolocations/l2`, requestOptions).then(handleResponse);
}

function getGeolocationsByKeys(keys) {

    const val = keys == "" ? 0 : keys

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/geolocations/?keys=${val}`, requestOptions).then(handleResponse);
}

function getGeolocationsByTypes(types, nameContains) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/geolocations/?types=${types}&nameContains=${nameContains}`, requestOptions).then(handleResponse);
}

function getGoogleCoordindates(searchText) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({searchText: searchText})
    };

    return fetch(`${config.apiURL}/geolocations/googlemap`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}