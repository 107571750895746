import { accountsSupernovaConstants } from '../_constants';

export function accountsSupernova(state = {}, action) {
  switch (action.type) {
    //=====================================
    // GET
    //=====================================
    case accountsSupernovaConstants.GET_REQUEST:
      return {
        loading: true,
      };
    case accountsSupernovaConstants.GET_SUCCESS:
      return {
        items: action.accountsSupernova,
        loading:false
      };
    case accountsSupernovaConstants.GET_FAILURE:
      return { 
        error: action.error,
        loading:false
      };
    //=====================================
    // GET MIN BID
    //=====================================
    case accountsSupernovaConstants.GET_MIN_BID_REQUEST:
      return {
        loading: true,
      };
    case accountsSupernovaConstants.GET_MIN_BID_SUCCESS:
      return {
        items: action.accountsSupernova,
        loading:false
      };
    case accountsSupernovaConstants.GET_MIN_BID_FAILURE:
      return { 
        error: action.error,
        loading:false
      };
    //=====================================
    // UPDATE
    //=====================================
    case accountsSupernovaConstants.UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        updating: true,
      };
    case accountsSupernovaConstants.UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        loading:false,
        items: action.result,
      };
    case accountsSupernovaConstants.UPDATE_FAILURE:
      return { 
        ...state,
        error: action.error,
        updating: false,
        loading:false
      };
    //=====================================
    // DEFAULT
    //=====================================
    default:
      return state
  }
}