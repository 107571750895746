import React from 'react';
import config from 'config';
import { connect } from 'react-redux';

import { Route, Redirect } from 'react-router-dom';
import { userActions } from '../_actions';
class PrivateRoute extends React.Component {

  componentDidMount(){
        const token = localStorage.getItem('token')
        const {authenticateJWT} = this.props;
        if(token){
            authenticateJWT(token)
        }
    }

  render() {
    const {user, role, component: Component, ...rest} = this.props;
    const token = localStorage.getItem('token')
    let isLoggedIn = false
    let isVerificationComplete = (user !== undefined) || (token == null)
    
    if(isVerificationComplete){
        isLoggedIn = token == null 
            ? false 
            : user.id 
                ? true 
                : false
    }
    return (
        isVerificationComplete &&
        <Route {...rest} render={props => (
            isLoggedIn && role == user.role
             ? <Component {...props} />
             : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )} />
    );
    }

}

function mapState(state) {
  const { users } = state;
  const user  = users.current;
  return { user };
}

const actionCreators = {
    authenticateJWT: userActions.authenticateJWT
};


const connectedPrivateRoute = connect(mapState, actionCreators)(PrivateRoute);
export { connectedPrivateRoute as PrivateRoute };

