import '../../_helpers/sort-by.js'
export const listPushAdsCountries = [
    {type:"country", label:"United Arab Emirates", value:"ae"},
    {type:"country", label:"Afghanistan", value:"af"},
    {type:"country", label:"Antigua and Barbuda", value:"ag"},
    {type:"country", label:"Anguilla", value:"ai"},
    {type:"country", label:"Albania", value:"al"},
    {type:"country", label:"Armenia", value:"am"},
    {type:"country", label:"Angola", value:"ao"},
    {type:"country", label:"Antarctica", value:"aq"},
    {type:"country", label:"Argentina", value:"ar"},
    {type:"country", label:"American Samoa", value:"as"},
    {type:"country", label:"Austria", value:"at"},
    {type:"country", label:"Australia", value:"au"},
    {type:"country", label:"Aruba", value:"aw"},
    {type:"country", label:"Åland Islands", value:"ax"},
    {type:"country", label:"Azerbaijan", value:"az"},
    {type:"country", label:"Bosnia and Herzegovina", value:"ba"},
    {type:"country", label:"Barbados", value:"bb"},
    {type:"country", label:"Bangladesh", value:"bd"},
    {type:"country", label:"Belgium", value:"be"},
    {type:"country", label:"Burkina Faso", value:"bf"},
    {type:"country", label:"Bulgaria", value:"bg"},
    {type:"country", label:"Bahrain", value:"bh"},
    {type:"country", label:"Burundi", value:"bi"},
    {type:"country", label:"Benin", value:"bj"},
    {type:"country", label:"Saint Barthélemy", value:"bl"},
    {type:"country", label:"Bermuda", value:"bm"},
    {type:"country", label:"Brunei Darussalam", value:"bn"},
    {type:"country", label:"Bolivia (Plurinational State of)", value:"bo"},
    {type:"country", label:"Bonaire, Sint Eustatius and Saba", value:"bq"},
    {type:"country", label:"Brazil", value:"br"},
    {type:"country", label:"Bahamas", value:"bs"},
    {type:"country", label:"Bhutan", value:"bt"},
    {type:"country", label:"Bouvet Island", value:"bv"},
    {type:"country", label:"Botswana", value:"bw"},
    {type:"country", label:"Belarus", value:"by"},
    {type:"country", label:"Belize", value:"bz"},
    {type:"country", label:"Canada", value:"ca"},
    {type:"country", label:"Cocos (Keeling) Islands", value:"cc"},
    {type:"country", label:"Congo, Democratic Republic of the", value:"cd"},
    {type:"country", label:"Central African Republic", value:"cf"},
    {type:"country", label:"Congo", value:"cg"},
    {type:"country", label:"Switzerland", value:"ch"},
    {type:"country", label:"Côte d'Ivoire", value:"ci"},
    {type:"country", label:"Cook Islands", value:"ck"},
    {type:"country", label:"Chile", value:"cl"},
    {type:"country", label:"Cameroon", value:"cm"},
    {type:"country", label:"China", value:"cn"},
    {type:"country", label:"Colombia", value:"co"},
    {type:"country", label:"Costa Rica", value:"cr"},
    {type:"country", label:"Cuba", value:"cu"},
    {type:"country", label:"Cabo Verde", value:"cv"},
    {type:"country", label:"Curaçao", value:"cw"},
    {type:"country", label:"Christmas Island", value:"cx"},
    {type:"country", label:"Cyprus", value:"cy"},
    {type:"country", label:"Czechia", value:"cz"},
    {type:"country", label:"Germany", value:"de"},
    {type:"country", label:"Djibouti", value:"dj"},
    {type:"country", label:"Denmark", value:"dk"},
    {type:"country", label:"Dominica", value:"dm"},
    {type:"country", label:"Dominican Republic", value:"do"},
    {type:"country", label:"Algeria", value:"dz"},
    {type:"country", label:"Ecuador", value:"ec"},
    {type:"country", label:"Estonia", value:"ee"},
    {type:"country", label:"Egypt", value:"eg"},
    {type:"country", label:"Western Sahara", value:"eh"},
    {type:"country", label:"Eritrea", value:"er"},
    {type:"country", label:"Spain", value:"es"},
    {type:"country", label:"Ethiopia", value:"et"},
    {type:"country", label:"Finland", value:"fi"},
    {type:"country", label:"Fiji", value:"fj"},
    {type:"country", label:"Falkland Islands (Malvinas)", value:"fk"},
    {type:"country", label:"Micronesia (Federated States of)", value:"fm"},
    {type:"country", label:"Faroe Islands", value:"fo"},
    {type:"country", label:"France", value:"fr"},
    {type:"country", label:"Gabon", value:"ga"},
    {type:"country", label:"United Kingdom of Great Britain and Northern Ireland", value:"gb"},
    {type:"country", label:"Grenada", value:"gd"},
    {type:"country", label:"Georgia", value:"ge"},
    {type:"country", label:"French Guiana", value:"gf"},
    {type:"country", label:"Guernsey", value:"gg"},
    {type:"country", label:"Ghana", value:"gh"},
    {type:"country", label:"Gibraltar", value:"gi"},
    {type:"country", label:"Greenland", value:"gl"},
    {type:"country", label:"Gambia", value:"gm"},
    {type:"country", label:"Guinea", value:"gn"},
    {type:"country", label:"Guadeloupe", value:"gp"},
    {type:"country", label:"Equatorial Guinea", value:"gq"},
    {type:"country", label:"Greece", value:"gr"},
    {type:"country", label:"South Georgia and the South Sandwich Islands", value:"gs"},
    {type:"country", label:"Guatemala", value:"gt"},
    {type:"country", label:"Guam", value:"gu"},
    {type:"country", label:"Guinea-Bissau", value:"gw"},
    {type:"country", label:"Guyana", value:"gy"},
    {type:"country", label:"Hong Kong", value:"hk"},
    {type:"country", label:"Heard Island and McDonald Islands", value:"hm"},
    {type:"country", label:"Honduras", value:"hn"},
    {type:"country", label:"Croatia", value:"hr"},
    {type:"country", label:"Haiti", value:"ht"},
    {type:"country", label:"Hungary", value:"hu"},
    {type:"country", label:"Indonesia", value:"id"},
    {type:"country", label:"Ireland", value:"ie"},
    {type:"country", label:"Israel", value:"il"},
    {type:"country", label:"Isle of Man", value:"im"},
    {type:"country", label:"India", value:"in"},
    {type:"country", label:"British Indian Ocean Territory", value:"io"},
    {type:"country", label:"Iraq", value:"iq"},
    {type:"country", label:"Iran (Islamic Republic of)", value:"ir"},
    {type:"country", label:"Iceland", value:"is"},
    {type:"country", label:"Italy", value:"it"},
    {type:"country", label:"Jersey", value:"je"},
    {type:"country", label:"Jamaica", value:"jm"},
    {type:"country", label:"Jordan", value:"jo"},
    {type:"country", label:"Japan", value:"jp"},
    {type:"country", label:"Kenya", value:"ke"},
    {type:"country", label:"Kyrgyzstan", value:"kg"},
    {type:"country", label:"Cambodia", value:"kh"},
    {type:"country", label:"Kiribati", value:"ki"},
    {type:"country", label:"Comoros", value:"km"},
    {type:"country", label:"Saint Kitts and Nevis", value:"kn"},
    {type:"country", label:"Korea (Democratic People's Republic of)", value:"kp"},
    {type:"country", label:"Korea, Republic of", value:"kr"},
    {type:"country", label:"Kuwait", value:"kw"},
    {type:"country", label:"Cayman Islands", value:"ky"},
    {type:"country", label:"Kazakhstan", value:"kz"},
    {type:"country", label:"Lao People's Democratic Republic", value:"la"},
    {type:"country", label:"Lebanon", value:"lb"},
    {type:"country", label:"Saint Lucia", value:"lc"},
    {type:"country", label:"Liechtenstein", value:"li"},
    {type:"country", label:"Sri Lanka", value:"lk"},
    {type:"country", label:"Liberia", value:"lr"},
    {type:"country", label:"Lesotho", value:"ls"},
    {type:"country", label:"Lithuania", value:"lt"},
    {type:"country", label:"Luxembourg", value:"lu"},
    {type:"country", label:"Latvia", value:"lv"},
    {type:"country", label:"Libya", value:"ly"},
    {type:"country", label:"Morocco", value:"ma"},
    {type:"country", label:"Monaco", value:"mc"},
    {type:"country", label:"Moldova, Republic of", value:"md"},
    {type:"country", label:"Montenegro", value:"me"},
    {type:"country", label:"Saint Martin (French part)", value:"mf"},
    {type:"country", label:"Madagascar", value:"mg"},
    {type:"country", label:"Marshall Islands", value:"mh"},
    {type:"country", label:"North Macedonia", value:"mk"},
    {type:"country", label:"Mali", value:"ml"},
    {type:"country", label:"Myanmar", value:"mm"},
    {type:"country", label:"Mongolia", value:"mn"},
    {type:"country", label:"Macao", value:"mo"},
    {type:"country", label:"Northern Mariana Islands", value:"mp"},
    {type:"country", label:"Martinique", value:"mq"},
    {type:"country", label:"Mauritania", value:"mr"},
    {type:"country", label:"Montserrat", value:"ms"},
    {type:"country", label:"Malta", value:"mt"},
    {type:"country", label:"Mauritius", value:"mu"},
    {type:"country", label:"Maldives", value:"mv"},
    {type:"country", label:"Malawi", value:"mw"},
    {type:"country", label:"Mexico", value:"mx"},
    {type:"country", label:"Malaysia", value:"my"},
    {type:"country", label:"Mozambique", value:"mz"},
    {type:"country", label:"Namibia", value:"na"},
    {type:"country", label:"New Caledonia", value:"nc"},
    {type:"country", label:"Niger", value:"ne"},
    {type:"country", label:"Norfolk Island", value:"nf"},
    {type:"country", label:"Nigeria", value:"ng"},
    {type:"country", label:"Nicaragua", value:"ni"},
    {type:"country", label:"Netherlands", value:"nl"},
    {type:"country", label:"Norway", value:"no"},
    {type:"country", label:"Nepal", value:"np"},
    {type:"country", label:"Nauru", value:"nr"},
    {type:"country", label:"Niue", value:"nu"},
    {type:"country", label:"New Zealand", value:"nz"},
    {type:"country", label:"Oman", value:"om"},
    {type:"country", label:"Panama", value:"pa"},
    {type:"country", label:"Peru", value:"pe"},
    {type:"country", label:"French Polynesia", value:"pf"},
    {type:"country", label:"Papua New Guinea", value:"pg"},
    {type:"country", label:"Philippines", value:"ph"},
    {type:"country", label:"Pakistan", value:"pk"},
    {type:"country", label:"Poland", value:"pl"},
    {type:"country", label:"Saint Pierre and Miquelon", value:"pm"},
    {type:"country", label:"Pitcairn", value:"pn"},
    {type:"country", label:"Puerto Rico", value:"pr"},
    {type:"country", label:"Palestine, State of", value:"ps"},
    {type:"country", label:"Portugal", value:"pt"},
    {type:"country", label:"Palau", value:"pw"},
    {type:"country", label:"Paraguay", value:"py"},
    {type:"country", label:"Qatar", value:"qa"},
    {type:"country", label:"Réunion", value:"re"},
    {type:"country", label:"Romania", value:"ro"},
    {type:"country", label:"Serbia", value:"rs"},
    {type:"country", label:"Russian Federation", value:"ru"},
    {type:"country", label:"Rwanda", value:"rw"},
    {type:"country", label:"Saudi Arabia", value:"sa"},
    {type:"country", label:"Solomon Islands", value:"sb"},
    {type:"country", label:"Seychelles", value:"sc"},
    {type:"country", label:"Sudan", value:"sd"},
    {type:"country", label:"Sweden", value:"se"},
    {type:"country", label:"Singapore", value:"sg"},
    {type:"country", label:"Saint Helena, Ascension and Tristan da Cunha", value:"sh"},
    {type:"country", label:"Slovenia", value:"si"},
    {type:"country", label:"Svalbard and Jan Mayen", value:"sj"},
    {type:"country", label:"Slovakia", value:"sk"},
    {type:"country", label:"Sierra Leone", value:"sl"},
    {type:"country", label:"San Marino", value:"sm"},
    {type:"country", label:"Senegal", value:"sn"},
    {type:"country", label:"Somalia", value:"so"},
    {type:"country", label:"Suriname", value:"sr"},
    {type:"country", label:"South Sudan", value:"ss"},
    {type:"country", label:"Sao Tome and Principe", value:"st"},
    {type:"country", label:"El Salvador", value:"sv"},
    {type:"country", label:"Sint Maarten (Dutch part)", value:"sx"},
    {type:"country", label:"Syrian Arab Republic", value:"sy"},
    {type:"country", label:"Eswatini", value:"sz"},
    {type:"country", label:"Turks and Caicos Islands", value:"tc"},
    {type:"country", label:"Chad", value:"td"},
    {type:"country", label:"French Southern Territories", value:"tf"},
    {type:"country", label:"Togo", value:"tg"},
    {type:"country", label:"Thailand", value:"th"},
    {type:"country", label:"Tajikistan", value:"tj"},
    {type:"country", label:"Tokelau", value:"tk"},
    {type:"country", label:"Timor-Leste", value:"tl"},
    {type:"country", label:"Turkmenistan", value:"tm"},
    {type:"country", label:"Tunisia", value:"tn"},
    {type:"country", label:"Tonga", value:"to"},
    {type:"country", label:"Türkiye", value:"tr"},
    {type:"country", label:"Trinidad and Tobago", value:"tt"},
    {type:"country", label:"Tuvalu", value:"tv"},
    {type:"country", label:"Taiwan, Province of China", value:"tw"},
    {type:"country", label:"Tanzania, United Republic of", value:"tz"},
    {type:"country", label:"Ukraine", value:"ua"},
    {type:"country", label:"Uganda", value:"ug"},
    {type:"country", label:"United States Minor Outlying Islands", value:"um"},
    {type:"country", label:"United States of America", value:"us"},
    {type:"country", label:"Uruguay", value:"uy"},
    {type:"country", label:"Uzbekistan", value:"uz"},
    {type:"country", label:"Holy See", value:"va"},
    {type:"country", label:"Saint Vincent and the Grenadines", value:"vc"},
    {type:"country", label:"Venezuela (Bolivarian Republic of)", value:"ve"},
    {type:"country", label:"Virgin Islands (British)", value:"vg"},
    {type:"country", label:"Virgin Islands (U.S.)", value:"vi"},
    {type:"country", label:"Viet Nam", value:"vn"},
    {type:"country", label:"Vanuatu", value:"vu"},
    {type:"country", label:"Wallis and Futuna", value:"wf"},
    {type:"country", label:"Samoa", value:"ws"},
    {type:"country", label:"Yemen", value:"ye"},
    {type:"country", label:"Mayotte", value:"yt"},
    {type:"country", label:"South Africa", value:"za"},
    {type:"country", label:"Zambia", value:"zm"},
    {type:"country", label:"Zimbabwe", value:"zw"},
].sortBy('label')