export const styleSelectCreativeForm = {
    control: (base, state) => ({
        ...base,
        background: "#fff",
        borderRadius: state.isFocused ? "3px 3px 3px 3px" : 3,
        borderColor: state.isFocused ? "#ddd" : "#ddd",
        border:"0px",
        boxShadow: state.isFocused ? null : null,
        padding:"0px",
        fontSize:"13px",
        // height:"23px",
        height:"auto",
        minHeight:"23px",
        "&:hover": {
            borderColor: state.isFocused ? "#ddd" : "#ddd",
        }
    }),
    valueContainer: (base) => ({
        ...base,
        display: "contents",
        width:"100%"
    }),
    multiValue: (base) => ({
        ...base,
        margin:"0px",
        width:"100%",
        "div:first-of-type":{
            width:"100%",
        },
        "&:first-of-type":{
            // marginTop:"18px"
        }
    }),
    menu: (base) => ({
        ...base,
        borderRadius: "0px 3px 3px 0px",
        border:"1px solid #ccc",
        width:"300px",
        maxWidth:"300px",
        background: "#fff",
        marginTop: 0,
    }),
    input: (base) => ({
        ...base,
        marginTop:"-5px",
        width:"100%",
        // position:"absolute", 
        top:"0px",
        "div":{
            width:"100%",
            paddingRight:"15px",
            paddingTop:"3px",
        },
        "div input":{
            width:"100% !important"
        }
    }),
    menuList: (base) => ({
        ...base,
        padding: 0,
        color:"#000"
    }),
    singleValue: (base) => ({
        ...base,
        paddingLeft:"8px"
    }),
    indicatorsContainer: (base) => ({
        ...base,
        display:"none"
    }),
    option: (provided, state) => ({
        ...provided,
        padding: "5px 10px",
        background: "#fff",
        fontSize: "13px",
        display: state.isSelected ? "none" : "block",
        "&:hover": {
            background: "#eef3e7"
        }
    }),
};