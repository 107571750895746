import config from 'config';
import { authHeader } from '../_helpers';

export const publisherService = {
    getPublishersInAccount,
    getPublishersInCampaign,
    getPublishersInAdgroup,

    getTotalPublishersPerCampaign,
    getTotalPublishersPerAdgroup,
    getTotalPublishersPerCreative

};


function getTotalPublishersPerCampaign(campaignId, startDate, endDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/publishers/total/campaign/${campaignId}/${startDate}/${endDate}`, requestOptions).then(handleResponse);
}

function getTotalPublishersPerAdgroup(adgroupId, startDate, endDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/publishers/total/adgroup/${adgroupId}/${startDate}/${endDate}`, requestOptions).then(handleResponse);
}

function getTotalPublishersPerCreative(startDate, endDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/publishers/total/creative/${startDate}/${endDate}`, requestOptions).then(handleResponse);
}

function getPublishersInAccount(accountId, startDate, endDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/publishers/account/${accountId}/${startDate}/${endDate}`, requestOptions).then(handleResponse);
}

function getPublishersInCampaign(accountId, campaignId, startDate, endDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/publishers/campaign/${campaignId}/${accountId}/${startDate}/${endDate}`, requestOptions).then(handleResponse);
}

function getPublishersInAdgroup(accountId, adgroupId, startDate, endDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/publishers/adgroup/${adgroupId}/${accountId}/${startDate}/${endDate}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}