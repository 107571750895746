import { userConstants } from '../_constants';

export function users(state = {}, action) {
  switch (action.type) {
    //================================================================================================================
    case userConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading:false,
        items: action.users
      };
    case userConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    //================================================================================================================
    case userConstants.GETBYACCOUNTID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETBYACCOUNTID_SUCCESS:
      return {
        ...state,
        loading:false,
        items: action.users
      };
    case userConstants.GETBYACCOUNTID_FAILURE:
      return {
        error: action.error
      };
      //================================================================================================================
    case userConstants.GET_REQUEST:
      return {
        loading: true
      };
    case userConstants.GET_SUCCESS:
      return {
        ...state,
        loading:false,
        current: action.user
      };
    case userConstants.GET_FAILURE:
      return { 
        loading:false,
        current:{ 
          error: action.error
        }
      };
    //================================================================================================================
    case userConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map(user =>
          user.id === action.id
            ? { ...user, deleting: true }
            : user
        )
      };
    case userConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        ...state,
        items: state.items.filter(user => user.id !== action.id)
      };
    case userConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
      return {
        ...state,
        items: state.items.map(user => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        })
      };
    //================================================================================================================
    case userConstants.SHOW_UPDATE_FORM:
      return {
        ...state,
        items: state.items.map(user =>
          user.id === action.id
            ? { ...user, showUpdateForm: true }
            : user
        )
      };
    case userConstants.HIDE_UPDATE_FORM:
      return {
        ...state,
        items: state.items.map(user =>
          user.id === action.id
            ? { ...user, showUpdateForm: false }
            : user
        )
      };
    //================================================================================================================
    case userConstants.UPDATE_MYINFO_REQUEST:
      return {    
        loading: true
      };
    case userConstants.UPDATE_MYINFO_SUCCESS:
      return {
        loading: false
      };
    case userConstants.UPDATE_MYINFO_FAILURE:
      return {
        loading: false
      };
    //================================================================================================================
    case userConstants.MYINFO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.MYINFO_SUCCESS:
      return {
        ...state,
        loading: false,
        current:action.user
      };
    case userConstants.MYINFO_FAILURE:
      return {
        ...state,
        loading: false
      };
    //================================================================================================================
    case userConstants.UPDATE_REQUEST:
      return {
        ...state,
        items: state.items.map(user => {
          return (
            user.id === action.user.id
              ? {
                ...user,
                updating: true,
              }
              : user
          )
        })
      };
    case userConstants.UPDATE_SUCCESS:
      return {
        ...state,
        items: state.items.map(user => {
          return (
            user.id === action.user.id
              ? {
                ...user,
                updating        : false,
                accountId       : action.user.accountId       !== undefined ? action.user.accountId       : user.accountId,
                firstName       : action.user.firstName       !== undefined ? action.user.firstName       : user.firstName,
                lastName        : action.user.lastName        !== undefined ? action.user.lastName        : user.lastName,
                companyName     : action.user.companyName     !== undefined ? action.user.companyName     : user.companyName,
                companyWebsite  : action.user.companyWebsite  !== undefined ? action.user.companyWebsite  : user.companyWebsite,
                position        : action.user.position        !== undefined ? action.user.position        : user.position,
                credits         : action.user.credits         !== undefined ? action.user.credits         : user.credits,
                showBidControl  : action.user.showBidControl  !== undefined ? action.user.showBidControl  : user.showBidControl
              }
              : user
          )
        }

        )
      };
    case userConstants.UPDATE_FAILURE:
      return {
        ...state,
        items: state.items.map(user => {
          if (user.id === action.id) {
            const { updating, ...userCopy } = user;
            return { ...userCopy, updateError: action.error };
          }

          return user;
        })
      };
    //================================================================================================================
    case userConstants.APPROVE_REQUEST:
      return {
        ...state,
        items: state.items.map(user =>
          user.id === action.userId
            ? { ...user, approving: true }
            : user
        )
      };
    case userConstants.APPROVE_SUCCESS:
      return {
        ...state,
        items: state.items.map(user =>
          user.id === action.userId
            ? {
              ...user,
              approving: false,
              status: 'approved'
            }
            : user
        )
      };
    case userConstants.APPROVE_FAILURE:
      return {
        ...state,
        items: state.items.map(user => {
          if (user.id === action.id) {
            const { approving, ...userCopy } = user;
            return { ...userCopy, updateError: action.error };
          }
          return user;
        })
      };
    //================================================================================================================
    case userConstants.ADD_REQUEST:
      return {
        ...state,
      };
    case userConstants.ADD_SUCCESS:
      return {
        ...state,
        items: action.users
      };
    case userConstants.ADD_FAILURE:
      return {
        ...state,
      };
    //================================================================================================================
    default:
      return state
  }
}