import { supernovaCreditsConstants } from '../_constants';
import { supernovaCreditsService } from '../_services';
import { alertActions} from './';

export const supernovaCreditsActions = {
    getSupernovaCreditById,
    getSupernovaCreditByMultipleId,
    updateAccountCredit,
};



function getSupernovaCreditById(accountId) {
    return dispatch => {
        dispatch(request());

        supernovaCreditsService.getSupernovaCreditById(accountId)
            .then(
                supernovaCredits => dispatch(success(supernovaCredits)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: supernovaCreditsConstants.GET_REQUEST } }
    function success(supernovaCredits) { return { type: supernovaCreditsConstants.GET_SUCCESS, supernovaCredits } }
    function failure(error) { return { type: supernovaCreditsConstants.GET_FAILURE, error } }
}

function getSupernovaCreditByMultipleId(accountList) {
    return dispatch => {
        dispatch(request());

        supernovaCreditsService.getSupernovaCreditByMultipleId(accountList)
            .then(
                supernovaCredits => dispatch(success(supernovaCredits)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: supernovaCreditsConstants.GET_REQUEST } }
    function success(supernovaCredits) { return { type: supernovaCreditsConstants.GET_SUCCESS, supernovaCredits } }
    function failure(error) { return { type: supernovaCreditsConstants.GET_FAILURE, error } }
}

function updateAccountCredit(accountId, credit, accountList) {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.loading("Saving changes..."));
        console.log("Updating Supernova Credit: " + accountId);

        supernovaCreditsService.updateAccountCredit(accountId, credit)
            .then(
                () => {
                    supernovaCreditsService.getSupernovaCreditByMultipleId(accountList)
                    .then(
                        supernovaCreditsService => {
                            console.log(supernovaCreditsService)
                            dispatch(success(supernovaCreditsService))
                            dispatch(alertActions.success('Changes Saved'));
                            console.log("DONE: Updating Supernova Credit");
                        },
                        error => {
                            dispatch(failure(error.toString()))
                        }
                    );
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: supernovaCreditsConstants.UPDATE_REQUEST} }
    function success(result) { return { type: supernovaCreditsConstants.UPDATE_SUCCESS, result } }
    function failure(id, error) { return { type: supernovaCreditsConstants.UPDATE_FAILURE, id, error } }
}

