export const creativesPendingConstants = {
    SUBMIT_REQUEST: 'CREATIVE_SUBMIT_REQUEST',
    SUBMIT_SUCCESS: 'CREATIVE_SUBMIT_SUCCESS',
    SUBMIT_FAILURE: 'CREATIVE_SUBMIT_FAILURE',

    GET_REQUEST: 'CREATIVES_PENDING_GET_REQUEST',
    GET_SUCCESS: 'CREATIVES_PENDING_GET_SUCCESS',
    GET_FAILURE: 'CREATIVES_PENDING_GET_FAILURE',
    
    ADD_REQUEST: 'CREATIVES_PENDING_ADD_REQUEST',
    ADD_SUCCESS: 'CREATIVES_PENDING_ADD_SUCCESS',
    ADD_FAILURE: 'CREATIVES_PENDING_ADD_FAILURE',
    
    CHECK_UPLOAD_STATUS_REQUEST: 'CHECK_UPLOAD_STATUS_REQUEST',
    CHECK_UPLOAD_STATUS_SUCCESS: 'CHECK_UPLOAD_STATUS_SUCCESS',
    CHECK_UPLOAD_STATUS_FAILURE: 'CHECK_UPLOAD_STATUS_FAILURE',
};
