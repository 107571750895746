import { aiConstants } from '../_constants';

export function ai(state = {}, action) {
  switch (action.type) {
    //=====================================
    // GET
    //=====================================
    case aiConstants.GET_REQUEST:
      return {
        ...state,
        loading: true,
        ask_to_proceed: false,
        response:""
      };
      //=====================================
      // CANCEL
      //=====================================
    case aiConstants.CANCEL_AI_REQUEST:
      return {
        ...state,
      };
    case aiConstants.GET_SUCCESS:
      return {
        ...state,
        loading         : false,
        response        : action.aiResponse.response,
        response_type   : action.aiResponse.response_type,
        adgroup_name    : action.aiResponse.adgroup_name,
        thread_id       : action.aiResponse.thread_id,
        query_id        : action.aiResponse.query_id,
        ask_to_proceed  : true,
        clusters        : action.aiResponse.clusters,
        gatheredZip     : action.aiResponse.gatheredZip,
        totalReach      : action.aiResponse.totalReach,
      };
    case aiConstants.GET_FAILURE:
      return { 
        ...state,
        loading: false,
        response: 'Please try again...',
        ask_to_proceed:false,
        error: action.error
      };
      //=====================================
      // DEFAULT
      //=====================================
    default:
      return {
        ...state,
        loading: false,
        // ask_to_proceed: false,
        // response: ""
      }
  }
}