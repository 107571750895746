import '../../_helpers/sort-by.js'
export const listPushAdsOSTypes = [
    {
        "id": 208718,
        "value": "desktop",
        "label": "Desktop"
    },
    {
        "id": 208752,
        "value": "mobile",
        "label": "Mobile"
    },
    {
        "id": 208778,
        "value": "other",
        "label": "Other"
    }
].sortBy('label')