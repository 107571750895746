export const audienceConstants = {
    GET_REQUEST: 'AUDIENCE_GET_REQUEST',
    GET_SUCCESS: 'AUDIENCE_GET_SUCCESS',
    GET_FAILURE: 'AUDIENCE_GET_FAILURE',

    UPDATE_REQUEST: 'AUDIENCE_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'AUDIENCE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'AUDIENCE_UPDATE_FAILURE',
    
    CREATE_REQUEST: 'AUDIENCE_CREATE_REQUEST',
    CREATE_SUCCESS: 'AUDIENCE_CREATE_SUCCESS',
    CREATE_FAILURE: 'AUDIENCE_CREATE_FAILURE',
};
