import { progressConstants } from '../_constants';

export function progress(state = {}, action) {
  switch (action.type) {
    case progressConstants.UPDATE:
      return {
        status: action.status
      };
    default:
      return state
  }
}