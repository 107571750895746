export const adgroupConstants = {
    CREATE_REQUEST: 'ADGROUP_CREATE_REQUEST',
    CREATE_SUCCESS: 'ADGROUP_CREATE_SUCCESS',
    CREATE_FAILURE: 'ADGROUP_CREATE_FAILURE',

    GET_REQUEST: 'ADGROUP_GET_REQUEST',
    GET_SUCCESS: 'ADGROUP_GET_SUCCESS',
    GET_FAILURE: 'ADGROUP_GET_FAILURE',

    GETLOCK_REQUEST: 'ADGROUP_GETLOCK_REQUEST',
    GETLOCK_SUCCESS: 'ADGROUP_GETLOCK_SUCCESS',
    GETLOCK_FAILURE: 'ADGROUP_GETLOCK_FAILURE',

    UPDATE_REQUEST: 'ADGROUP_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'ADGROUP_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'ADGROUP_UPDATE_FAILURE',

    GET_DETAIL_REQUEST: 'ADGROUP_GET_DETAIL_REQUEST',
    GET_DETAIL_SUCCESS: 'ADGROUP_GET_DETAIL_SUCCESS',
    GET_DETAIL_FAILURE: 'ADGROUP_GET_DETAIL_FAILURE',

    SELECT_ADGROUP: 'ADGROUP_SELECTED'
};
