import '../../_helpers/sort-by.js'
export const listDeviceCarrier = [
    { "value": "AT&T", "label": "AT&T" },
    { "value": "VERIZON", "label": "Verizon" },
    { "value": "TMOBILE", "label": "T-Mobile" },
    { "value": "SPRINT", "label": "Sprint" },
    { "value": "USCELLULAR", "label": "US Cellular" },
    { "value": "XFINITY", "label": "Xfinity" },
    { "value": "SPECTRUM", "label": "Spectrum" },
    { "value": "COX", "label": "Cox Communications" },
    { "value": "CENTURYLINK", "label": "CenturyLink" },
    { "value": "FRONTIER", "label": "Frontier Communications" },
    { "value": "OPTIMUM", "label": "Optimum" },
    { "value": "RCN", "label": "RCN" },
    { "value": "WINDSTREAM", "label": "Windstream" },
    { "value": "MEDIACOM", "label": "Mediacom" },
    { "value": "HUGHESNET", "label": "HughesNet" },
    { "value": "VIASAT", "label": "Viasat" },
    { "value": "GOOGLEFIBER", "label": "Google Fiber" }
].sortBy('label')