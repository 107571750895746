export const marketResearchConstants = {
    GET_REQUEST: 'MARKETRESEARCH_GET_REQUEST',
    GET_SUCCESS: 'MARKETRESEARCH_GET_SUCCESS',
    GET_FAILURE: 'MARKETRESEARCH_GET_FAILURE',
    
    CREATE_REQUEST: 'MARKETRESEARCH_CREATE_REQUEST',
    CREATE_SUCCESS: 'MARKETRESEARCH_CREATE_SUCCESS',
    CREATE_FAILURE: 'MARKETRESEARCH_CREATE_FAILURE',
    
    CREATE_CLIENT_REQUEST: 'CREATE_CLIENT_REQUEST',
    CREATE_CLIENT_SUCCESS: 'CREATE_CLIENT_SUCCESS',
    CREATE_CLIENT_FAILURE: 'CREATE_CLIENT_FAILURE',
    
    CREATE_BRAND_REQUEST: 'CREATE_BRAND_REQUEST',
    CREATE_BRAND_SUCCESS: 'CREATE_BRAND_SUCCESS',
    CREATE_BRAND_FAILURE: 'CREATE_BRAND_FAILURE',
    
    UPDATE_REQUEST: 'MARKETRESEARCH_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'MARKETRESEARCH_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'MARKETRESEARCH_UPDATE_FAILURE',
};
