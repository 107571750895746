import React, { useState, useEffect, Component, useDebugValue } from 'react'
import {BrowserRouter as Router, Link } from "react-router-dom";

//Redux
import { connect } from 'react-redux';
import { userActions, supernovaCreditsActions } from '../_actions';

//React Bootstrap
import { Dropdown } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

//Components
import {LeftNavigation} from '../_components/LeftNavigation'
import {Footer} from '../_components/Footer';

//Tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

//Select
import { styleSelectMultiple } from '../_constants/settings';

//React Select
import Select from 'react-select';

const AdminBilling = (props) => {

    const { user, users, supernovaCredits} = props;

    const useStateWithLabel = (initialValue, label) => {
        const [value, setValue] = useState(initialValue);
        useDebugValue(`${label}: ${value}`);
        return [value, setValue];
    };

    //Selected Credit
    const [selectedAccountCredit, setSelectedAccountCredit] = useStateWithLabel(null, "selectedAccountCredit");
    const [selectedUserCredit, setSelectedUserCredit] = useStateWithLabel(null, "selectedUserCredit");
    
    
    //Selected Account
    const [selectedAccountId, setSelectedAccountId] = useStateWithLabel(null, "selectedAccountId");
    const [isUserListVisible, setIsUserListVisible] = useStateWithLabel(false, "isUserListVisible");

    //Selected User
    const [selectedUserId, setSelectedUserId] = useStateWithLabel(null, "selectedUserId");
    
    //For editing credit
    const [isEditFormVisible, setIsEditFormVisible] = useStateWithLabel(false, "isEditFormVisible");


    const [accountsList, setAccountsList] = useStateWithLabel([], "accountsList");


    useEffect(() => {
        if(user.role == "admin"){
            props.getByAccountId(user.accountId); //For Client Admin
        }
        else{
            props.getAll(); //For Platform admin
        }
    },[]);

    useEffect(() => {
        if(accountsList.length == 0){
            //Distinct accountIds as AccountsList
            let list = (users && users.items) ? [...new Set(users.items.map(item => item.accountId))] : []
            setAccountsList(list.filter(item => item != "0000").reverse())
            
            //Get list of credits for available accounts
            props.getSupernovaCreditByMultipleId(list)
        }
    },[users]);

    const handleChangeInputText = (event, purpose) => {
        let { name, value } = event.target;
        if(event.target.type == "number" || event.target.getAttribute('data-type') == "number"){
            //Allow only numeric and dot
            let t = event.target.value.replace('-', '').replace(/[^\d.-]/g, '');

            // Allow only 2 decimal place
            value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t; //Old 
            // value = parseFloat(t).toFixed(2)
        }

        if(purpose == "EDIT_ACCOUNT_CREDIT"){
            setSelectedAccountCredit({
                ...selectedAccountCredit,
                [name]: value
            })
        }
        
        if(purpose == "EDIT_USER_CREDIT"){
            setSelectedUserCredit(value)
        }
    }

    const handleUpdateAccountCredit = () => {
        let list = (users && users.items) ? [...new Set(users.items.map(item => item.accountId))] : []
        props.updateAccountCredit(
            selectedAccountCredit.accountId, 
            {
                credit: selectedAccountCredit.credit
            },
            list
        );
        setSelectedAccountCredit(null);
        setIsEditFormVisible(false);
    }

    const handleUpdateUserCredit = () => {
        let list = (users && users.items) ? [...new Set(users.items.map(item => item.accountId))] : []
        let max = (selectedAccountCredit.credit - getTotalCreditsAllocatedToUsers())
        if(isUserCreditExceeded()){
            return;
        }
        props.updateUser({
            id:selectedUserId,
            credits:selectedUserCredit
        });
        setSelectedUserCredit(null);
        setIsEditFormVisible(false);
    }

    const getCreditOfSelectedUser = (userId) => {
        return users.items.filter(item => item.id == userId )[0].credits !== undefined 
        ? users.items.filter(item => item.id == userId )[0].credits 
        : "0.00"
    }

    const getTotalCreditsAllocatedToUsers = () => {
        return users.items && 
        supernovaCredits.items && 
        supernovaCredits.items.length > 0 && users.items.filter(item => item.accountId == selectedAccountId && item.credits !== undefined).reduce(function (acc, obj) { return acc + parseFloat(obj.credits); }, 0)
    }

    const isUserCreditExceeded = () => {
        return selectedUserCredit > ((selectedAccountCredit.credit - getTotalCreditsAllocatedToUsers()) + parseFloat(getCreditOfSelectedUser(selectedUserId)))
    }

    // const isAccountCreditExceeded = () => {
    //     console.log(selectedUserCredit > ((selectedAccountCredit.credit - getTotalCreditsAllocatedToUsers()) + parseFloat(getCreditOfSelectedUser(selectedUserId))))
    // }

    try{
        return (
            <div className='admin'>

                {/* SUB MENU */}
                <LeftNavigation role={user.role}/>

                <div className='canvas'>
                <div className="admin custom-panel table">
                        <div className="header">
                            {
                                selectedAccountId != null && isUserListVisible && users.items.filter(item => item.accountId == selectedAccountId).length > 0 ?
                                <>
                                    <OverlayTrigger placement="top" overlay={<Tooltip><b>Show Accounts</b></Tooltip>}>
                                        <button className="btn btn-primary" onClick={() => {
                                            setSelectedAccountCredit(null);
                                            setSelectedUserCredit(null);
                                            setSelectedAccountId(null); 
                                            setIsEditFormVisible(false);
                                            setIsUserListVisible(false);

                                        }}>
                                            Billing Section 
                                        </button>
                                    </OverlayTrigger>
                                    &nbsp;/&nbsp;
                                    {users.items.filter(item => item.accountId == selectedAccountId)[0].companyName} Users
                                </>
                                :
                                <>
                                    <span>Billing Section</span>
                                </>
                            }
                        </div>
                        {
                            
                            !isUserListVisible ?

                                // LIST OF ACCOUNTS
                                <table className="billing-section primary fixed-header has-hover">
                                    <thead>
                                        <tr>
                                            <th className='text-left'>Account Id</th>
                                            <th className='text-left'>Type</th>
                                            <th className='text-left'>Company</th>
                                            <th className='text-left'>Website</th>
                                            <th className='text-right'>Credits</th>
                                            <th className="text-center"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.items && supernovaCredits.items && supernovaCredits.items.length > 0 && accountsList.map((accountId) =>
                                            <tr key={accountId} className={`${user.accountId != accountId ? "" : "main-account"}`}>
                                                <td>
                                                    {
                                                        <span>{Object.hasOwn(user, "accountId") ? accountId : "-"}</span>
                                                    }
                                                </td>
                                                <td>
                                                    <span>
                                                        {
                                                            user.accountId != accountId ? `Sub-account` : "Main"
                                                        }
                                                    </span>
                                                </td>
                                                <td>
                                                    {
                                                            <span>
                                                                {users.items.filter(item => item.accountId == accountId)[0].companyName}
                                                            </span>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                            <span>{users.items.filter(item => item.accountId == accountId)[0].companyWebsite}</span>
                                                    }
                                                </td>
                                                <td className="text-right">
                                                        {
                                                            (isEditFormVisible && selectedAccountCredit != null && selectedAccountCredit.accountId == accountId) ? 
                                                            <>
                                                                $&nbsp;
                                                                <input 
                                                                    data-type="number"
                                                                    type="text" 
                                                                    className="text-right" 
                                                                    autoFocus={true} 
                                                                    value={
                                                                        selectedAccountCredit.credit
                                                                    } 
                                                                    name="credit" 
                                                                    onChange={(e) => handleChangeInputText(e, "EDIT_ACCOUNT_CREDIT")}>
                                                                </input>
                                                            </>
                                                            :
                                                            <span>
                                                                {
                                                                    `$${parseFloat(supernovaCredits.items.find(item => item.accountId == accountId)?.credit).toLocaleString(undefined, {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2
                                                                    })}`    
                                                                }
                                                            </span>
                                                        }
                                                </td>
                                                <td className="text-right">
                                                    <span>
                                                        {
                                                            (isEditFormVisible && selectedAccountId == accountId) ?
                                                                <span>
                                                                    <button className={`btn btn-primary ml-2 ${(supernovaCredits.updating || selectedUserCredit == "") ? "disabled" : ""}`} onClick={(e) => handleUpdateAccountCredit()}>Save</button>
                                                                    <button className={`btn btn-secondary ml-2 mr-2 btn-sm ${supernovaCredits.updating ? "disabled" : ""}`} onClick={(e) => {
                                                                        setSelectedAccountCredit(null); 
                                                                        setIsEditFormVisible(false); 
                                                                        setSelectedAccountId(null);
                                                                        }}>Discard</button>
                                                                </span>
                                                            :
                                                            <>
                                                                {
                                                                    user.accountId != accountId &&
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                                            <button type="button" 
                                                                                className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${isEditFormVisible ? "disabled": ""}`} 
                                                                                onClick={() => {
                                                                                    setSelectedAccountId(accountId); 
                                                                                    setIsEditFormVisible(true); 
                                                                                    setSelectedAccountCredit(supernovaCredits.items.filter(item => item.accountId == accountId)[0])
                                                                                }}>
                                                                                <i className="fa fa-pencil" aria-hidden="true"></i>
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                }
                                                                <OverlayTrigger placement="top" overlay={<Tooltip><b>Show Users</b></Tooltip>}>
                                                                    <button type="button" 
                                                                        className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${isEditFormVisible ? "disabled": ""}`} 
                                                                        onClick={(e) => {
                                                                            setSelectedAccountId(accountId); 
                                                                            setIsUserListVisible(true);
                                                                            setSelectedAccountCredit(supernovaCredits.items.filter(item => item.accountId == accountId)[0])
                                                                        }}>
                                                                        <i className="fa fa-users" aria-hidden="true"></i>
                                                                    </button>
                                                                </OverlayTrigger>
                                                            </> 
                                                        }
                                                    </span>
                                                </td>
                                            </tr>
                                            )}
                                    </tbody>
                                </table>

                            :
                            <>
                                {/* USERS INSIDE AN ACCOUNT */}
                                <div className='summary-credit'>
                                    <div className='row'>
                                        <div>Account's Credit</div>
                                        <div>:</div>
                                        <div>
                                            {
                                                `$ ${parseFloat(selectedAccountCredit.credit).toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })}`   
                                            }
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div>Allocated</div>
                                        <div>:</div>
                                        <div>
                                            {
                                                `$ ${parseFloat(getTotalCreditsAllocatedToUsers()).toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })}`   
                                            }
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div>Unallocated</div>
                                        <div>:</div>
                                        <div>
                                            {
                                                `$ ${parseFloat(selectedAccountCredit.credit - getTotalCreditsAllocatedToUsers()).toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })}`   
                                            }
                                        </div>
                                    </div>
                                </div>
                                <table className="billing-section primary fixed-header has-hover">
                                    <thead>
                                        <tr>
                                            <th className='text-left'>First Name</th>
                                            <th className='text-left'>Last Name</th>
                                            <th className='text-left'>Username</th>
                                            <th className='text-right'>Credits</th>
                                            <th className="text-center"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.items && supernovaCredits.items && supernovaCredits.items.length > 0 && users.items.filter(item => item.accountId == selectedAccountId).map((user) =>
                                            <tr key={user.id}>
                                                <td>
                                                    {
                                                        <span>{user.firstName}</span>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        <span>{user.lastName}</span>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        <span>{user.username}</span>
                                                    }
                                                </td>
                                                <td className="text-right">
                                                        {
                                                            (
                                                                isEditFormVisible && 
                                                                selectedUserId == user.id
                                                            ) ? 
                                                            <>
                                                                {
                                                                    isUserCreditExceeded() && <span className='error'>Exceeds Unallocated Credit</span>
                                                                }
                                                                $&nbsp;
                                                                <input 
                                                                    data-type="number"
                                                                    type="text" 
                                                                    className="text-right" 
                                                                    autoFocus={true} 
                                                                    value={selectedUserCredit} 
                                                                    name="credit" 
                                                                    onChange={(e) => handleChangeInputText(e, "EDIT_USER_CREDIT")}>
                                                                </input>
                                                            </>
                                                            :
                                                            <span>
                                                                {
                                                                    `$ ${parseFloat(getCreditOfSelectedUser(user.id)).toLocaleString(undefined, {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2
                                                                    })}`   
                                                                }
                                                            </span>
                                                        }


                                                    
                                                </td>
                                                <td className="text-right">
                                                    {
                                                        (isEditFormVisible && selectedUserId == user.id) ?
                                                        <span>
                                                            <button className={`btn btn-primary ml-2 ${(supernovaCredits.updating || selectedUserCredit == "" || isUserCreditExceeded()) ? "disabled" : ""}`} onClick={(e) => handleUpdateUserCredit()}>Save</button>
                                                            <button className={`btn btn-secondary ml-2 mr-2 btn-sm ${supernovaCredits.updating ? "disabled" : ""}`} onClick={(e) => {
                                                                setSelectedUserId(null);
                                                                setSelectedUserCredit(null)
                                                                setIsEditFormVisible(false); 
                                                                }}>Discard</button>
                                                        </span>
                                                        :
                                                        <OverlayTrigger placement="top" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                            <button type="button" 
                                                                className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${isEditFormVisible ? "disabled": ""}`} 
                                                                onClick={() => {
                                                                    setIsEditFormVisible(true); 
                                                                    setSelectedUserId(user.id);
                                                                    setSelectedUserCredit(getCreditOfSelectedUser(user.id))
                                                                }}>
                                                                <i className="fa fa-pencil" aria-hidden="true"></i>
                                                            </button>
                                                        </OverlayTrigger>
                                                    }
                                                </td>
                                            </tr>
                                            )}
                                    </tbody>
                                </table>
                            </>
                        }

                    </div>
                </div>
                <Footer />
            </div>
        );
    }
    catch(e){
        console.log(e)
        // window.location.reload(false);
    }
}

const mapState = state => ({
    user    : state.users.current,
    users   : state.users,
    supernovaCredits: state.supernovaCredits
})

const actionCreators = {
    getAll                          : userActions.getAll,
    getByAccountId                  : userActions.getByAccountId,
    getSupernovaCreditByMultipleId  : supernovaCreditsActions.getSupernovaCreditByMultipleId,
    updateAccountCredit             : supernovaCreditsActions.updateAccountCredit,
    updateUser                      : userActions.update,
}

const connectedAdminBilling = connect(mapState, actionCreators)(AdminBilling);
export { connectedAdminBilling as AdminBilling };