import { sideDrawerConstants } from '../_constants';

export const sideDrawerActions = {
    open,
    close
};

function open(form, purpose) {
    return { 
        type: sideDrawerConstants.OPEN,
        form,
        purpose,
    };
}
function close(form, purpose) {
    return { 
        type: sideDrawerConstants.CLOSE,
        form,
        purpose,
    };
}

