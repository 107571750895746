export const brandRefSupernovaConstants = {
    GET_REQUEST: 'BRANDREFSUPERNOVA_GET_REQUEST',
    GET_SUCCESS: 'BRANDREFSUPERNOVA_GET_SUCCESS',
    GET_FAILURE: 'BRANDREFSUPERNOVA_GET_FAILURE',

    CREATE_REQUEST: 'BRANDREFSUPERNOVA_CREATE_REQUEST',
    CREATE_SUCCESS: 'BRANDREFSUPERNOVA_CREATE_SUCCESS',
    CREATE_FAILURE: 'BRANDREFSUPERNOVA_CREATE_FAILURE',

    UPDATE_REQUEST: 'BRANDREFSUPERNOVA_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'BRANDREFSUPERNOVA_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'BRANDREFSUPERNOVA_UPDATE_FAILURE',
};
