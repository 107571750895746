import { tagSupernovaConstants } from '../_constants';
import { tagSupernovaService } from '../_services';
import { alertActions, progressActions } from './';
import { history } from '../_helpers';

export const tagSupernovaActions = {
    getTagSupernovaByAccountId,
};



function getTagSupernovaByAccountId(tagSupernovaId) {
    return dispatch => {
        dispatch(request());

        tagSupernovaService.getTagSupernovaByAccountId(tagSupernovaId)
            .then(
                tagSupernova => dispatch(success(tagSupernova)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: tagSupernovaConstants.GET_REQUEST } }
    function success(tagSupernova) { return { type: tagSupernovaConstants.GET_SUCCESS, tagSupernova } }
    function failure(error) { return { type: tagSupernovaConstants.GET_FAILURE, error } }
}

