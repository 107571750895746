import config from 'config';
import { authHeader } from '../_helpers';

export const accountSupernovaService = {
    getASById,
    getASByMultipleId,
    updateASById,
    getASMinBidById,
};

function getASById(accountId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/as/${accountId}`, requestOptions).then(handleResponse);
}

function getASMinBidById(accountId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/as/minimum_bid/${accountId}`, requestOptions).then(handleResponse);
}

function getASByMultipleId(accountList) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(accountList)
    };

    return fetch(`${config.apiURL}/as`, requestOptions).then(handleResponse);
}

function updateASById(accountId, countryCode, settings) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(settings)
    };

    return fetch(`${config.apiURL}/as/${accountId}/${countryCode}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
