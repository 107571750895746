import React, { useState, useEffect, Component, useDebugValue } from 'react'
import {BrowserRouter as Router, Link } from "react-router-dom";

//Redux
import { connect } from 'react-redux';
import { 
    userActions,
    brandsActions, 
} from '../_actions';

//React Select
import Select from 'react-select';

import { styleSelectBasic, styleSelectFit, styleSelectGoal, styleSelectMultiple } from '../_constants/settings'

//Components
import {LeftNavigation} from '../_components/LeftNavigation'
import {Footer} from '../_components/Footer';

//Toggle
import Toggle from 'react-toggle'
import '../_stylesheets/css/react-toggle.css'

const BrandPage = (props) => {

    const { user, users, brands} = props;

    const useStateWithLabel = (initialValue, label) => {
        const [value, setValue] = useState(initialValue);
        useDebugValue(`${label}: ${value}`);
        return [value, setValue];
    };


    
    //ON LOAD: Get List of brands
    useEffect(() => {
        if(user.role == "admin"){
            //For Client Admin
        }
        else{
            //For Platform admin
            props.getBrands();
            props.getAll();
        }
    },[]);



    const toggleBrandStatus = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const brandName = e.target.getAttribute('data-bname')
        if(e.target.checked){

            //1. Approve a brand (Make it available in the list found in campaign settings)
            props.updateBrand({
                name  : brandName,
                status: "approved"
            })
        }
        else{
            //1. Disable a brand (Hide it from the list found in the campaign settings)
            props.updateBrand({
                name  : brandName,
                status: "disabled"
            })
        }
    }


    try{
        return (
            <div className='admin'>

                {/* SUB MENU */}
                <LeftNavigation role={user.role}/>

                <div className='canvas'>
                    <div className="admin custom-panel table brand">
                        <div className="header">
                            Brands
                            <div className="subtitle">
                                {/*  */}
                            </div>
                        </div>
                        {
                            (brands && brands.items && brands.isDone != false && brands.items != null) ?
                                <table className="primary fixed-header has-hover has-hover brand">
                                    <thead>
                                            <tr>
                                                <th>Brand</th>
                                                <th>
                                                    Status
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                brands.items.sortBy("name").map(brand => 
                                                    <React.Fragment key={brand.name}>
                                                        <tr className='fields'>
                                                            <td>{brand.name}</td>
                                                            <td>
                                                                {<Toggle className={`${brands?.loading == true ? "disabled" : ""}`} data-bname={brand.name} defaultChecked={brand.status == "approved" ? true : false} onChange={(e) => toggleBrandStatus(e)}/> }
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            }
                                        </tbody>
                                </table>
                            :
                            <>
                                {
                                    brands && brands.loading == true ?
                                    <>
                                        <i className="fa fa-spinner fa-spin fast-spin"></i> Loading...
                                    </>
                                    :
                                    <>
                                        
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>


                <Footer />
            </div>
        );
    }
    catch(e){
        // window.location.reload(false);
        console.log(e)
    }
}

const mapState = state => ({
    user                : state.users.current,
    users               : state.users,
    brands           : state.brands,
}) 

const actionCreators = {
    getAll      : userActions.getAll,
    getBrands   : brandsActions.getBrands,
    updateBrand : brandsActions.updateBrand,
}

const connectedBrandPage = connect(mapState, actionCreators)(BrandPage);
export { connectedBrandPage as BrandPage };