import { publisherGroupConstants } from '../_constants';

export function publishergroups(state = {}, action) {
  switch (action.type) {
    //================================================================================================================
    case publisherGroupConstants.GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case publisherGroupConstants.GET_SUCCESS:
      return {
        ...state,
        items: action.publisherGroups
      };
    case publisherGroupConstants.GET_FAILURE:
      return { 
        ...state,
        error: action.error
      };
    //================================================================================================================
    case publisherGroupConstants.DELETE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case publisherGroupConstants.DELETE_SUCCESS:
      return {
        ...state,
        items: action.publisherGroups
      };
    case publisherGroupConstants.DELETE_FAILURE:
      return { 
        ...state,
        error: action.error
      };
    //================================================================================================================
    case publisherGroupConstants.UPDATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case publisherGroupConstants.UPDATE_SUCCESS:
      return {
        ...state,
        items: action.publisherGroups
      };
    case publisherGroupConstants.UPDATE_FAILURE:
      return { 
        ...state,
        error: action.error
      };
    //================================================================================================================
    case publisherGroupConstants.CREATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case publisherGroupConstants.CREATE_SUCCESS:
      return {
        ...state,
        items: action.publisherGroups
        // items: [...state.items, action.publisherGroups], //Add the newly added publisherGroupConstants
      };
    case publisherGroupConstants.CREATE_FAILURE:
      return { 
        ...state,
        error: action.error
      };
      //================================================================================================================
    default:
      return state
  }
}