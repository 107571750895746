import { brandsConstants } from '../_constants';
import { brandsService } from '../_services';
import { alertActions, progressActions } from './';
import { history } from '../_helpers';

export const brandsActions = {
    getBrands,
    addBrand,
    updateBrand
};



function getBrands() {
    return dispatch => {
        dispatch(request());

        brandsService.getBrands()
            .then(
                brands => dispatch(success(brands)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: brandsConstants.GET_REQUEST } }
    function success(brands) { return { type: brandsConstants.GET_SUCCESS, brands } }
    function failure(error) { return { type: brandsConstants.GET_FAILURE, error } }
}

function addBrand(brand) {
    return dispatch => {
        dispatch(request());

        brandsService.addBrand(brand)
            .then(
                brands => dispatch(success(brands)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: brandsConstants.CREATE_REQUEST } }
    function success(brands) { return { type: brandsConstants.CREATE_SUCCESS, brands } }
    function failure(error) { return { type: brandsConstants.CREATE_FAILURE, error } }
}

function updateBrand(data) {
    return dispatch => {
        dispatch(request());

        brandsService.updateBrand(data)
            .then(
                brands => dispatch(success(brands)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: brandsConstants.UPDATE_REQUEST } }
    function success(brands) { return { type: brandsConstants.UPDATE_SUCCESS, brands } }
    function failure(error) { return { type: brandsConstants.UPDATE_FAILURE, error } }
}