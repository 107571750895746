import { overlayConstants } from '../_constants';

export const overlayActions = {
    open,
    close
};

function open(form, purpose, parentId, id = null) {
    return { 
        type: overlayConstants.OPEN,
        form,
        purpose,
        parentId: parentId,
        id: id
    };
}
function close(form, purpose) {
    return { 
        type: overlayConstants.CLOSE,
        form,
        purpose,
    };
}

