import React from 'react'
import { connect } from 'react-redux';
import {CampaignForm}  from './forms/CampaignForm'
import {AdgroupFormPush}  from './forms/AdgroupFormPush'
import {AdgroupForm}  from './forms/AdgroupForm'
import {UpdateProfileForm}  from './forms/UpdateProfileForm'

class SideDrawer extends React.Component {
    constructor(props) {
        super(props);
    }
    render(){
        const { isAutoPilot, sideDrawer, supernovaCampaignId, campaignId, campaignType, allAdgroups, campaignSource} = this.props;
        const Content = () => {
            switch(sideDrawer.form){
                case 'campaign':    
                    return <CampaignForm newCampaignType={campaignType}/>;
                case 'adgroup':    
                    return campaignType == "PUSH_NOTIFICATION" ? 
                        <AdgroupFormPush 
                            campaignId={campaignId} 
                            supernovaCampaignId={supernovaCampaignId}
                            campaignSource={campaignSource} 
                            newCampaignType={campaignType}
                            /> : 
                        <AdgroupForm 
                            campaignId={campaignId} 
                            supernovaCampaignId={supernovaCampaignId} 
                            campaignSource={campaignSource} 
                            campaignType={campaignType} 
                            isAutoPilot={isAutoPilot} 
                            allAdgroups={allAdgroups}/>;
                case 'profile':     
                    return <UpdateProfileForm/>;
                default: return '';
            }
        }

        return (
                <>
                   <div className={`side-drawer ${sideDrawer.isOpen ? 'open' : ''}`}>
                        <div className="backdrop"></div>
                        <div className={`panel ${sideDrawer.form}`}>
                            <Content/>
                        </div>
                   </div>
                </>
            );
    }
}

const mapState = state => ({
    sideDrawer:state.sideDrawer,
})

const connectedSideDrawer = connect(mapState)(SideDrawer);
export { connectedSideDrawer as SideDrawer };
