import React, { useState, useEffect, Component, useDebugValue } from 'react'
import {BrowserRouter as Router, Link } from "react-router-dom";

//Redux
import { connect } from 'react-redux';
import { userActions, accountSupernovaActions } from '../_actions';


//Components
import {LeftNavigation} from '../_components/LeftNavigation'
import {Footer} from '../_components/Footer';

//Tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const MarginControl = (props) => {

    const { user, users, accountsSupernova} = props;

    const useStateWithLabel = (initialValue, label) => {
        const [value, setValue] = useState(initialValue);
        useDebugValue(`${label}: ${value}`);
        return [value, setValue];
    };

    //Selected Entity
    const [selectedUserId,      setSelectedUserId] = useStateWithLabel(null, "selectedUserId");
    const [selectedUser,        setSelectedUser] = useStateWithLabel(null, "selectedUser");
    const [accountsList,        setAccountsList] = useStateWithLabel([], "accountsList");
    
    
    const [newSettings,        setNewSettings] = useStateWithLabel(null, "newSettings");
    const [selectedCountryCode,  setSelectedCountryCode] = useStateWithLabel(null, "selectedCountryCode");
    const [selectedAccountId,  setSelectedAccountId] = useStateWithLabel(null, "selectedAccountId");
    const [selectedSetting,  setSelectedSetting] = useStateWithLabel(null, "selectedSetting");

    const selectUser = (userId) => {
        setSelectedUserId(userId)
        setSelectedUser(users.items && users.items.filter(item => item.id == userId))
    }

    const getCompanyByAccountId = (accountId) => {
        return users.items.filter(item => item.accountId == accountId)[0].companyName
    }

    const hasSelectedRow = () => {
        return (selectedCountryCode != null && selectedAccountId != null && selectedSetting != null);
    }
    


    //Get List of client users under this account (including sub clients)
    useEffect(() => {
        if(user.role == "admin"){
            props.getByAccountId(user.accountId);   //For Client Admin
        }
        else{
            props.getAll();                         //For Platform admin
        }
    },[]);
    

    useEffect(() => {
        getUserType()
        if(accountsList.length == 0){
            //Distinct accountIds as AccountsList
            let list = (users && users.items) ? [...new Set(users.items.map(item => item.accountId))] : []
            setAccountsList(list)

            //Get control margin for each of the account in AccountsList
            props.getASByMultipleId(list); 
        }
    },[users]);

    const handleChangeInputText = (event) => {
        let { name, value } = event.target;
        if(event.target.type == "number"){
            // Allow only 2 decimal place
            let t = value;
            value = (t.indexOf(".") >= 0) ? parseFloat(t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : parseFloat(t);
        }
        setNewSettings({
            ...newSettings,
            [name]: value
        })
    }

    const handleChangeInputTextMultipleCampaignType = (event) => {
        let { name, value } = event.target;
        if(event.target.type == "number"){
            // Allow only 2 decimal place
            let t = value;
            value = (t.indexOf(".") >= 0) ? parseFloat(t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : parseFloat(t);
        }


        getUserType() == "direct-client" 
        ? 
            setNewSettings({
                ...newSettings,
                "license_fee_client_cpm_display": value,
                "license_fee_client_cpm_push": value,
            })
        : 
            setNewSettings({
                ...newSettings,
                "license_fee_platform_cpm_display": value,
                "license_fee_platform_cpm_push": value
            })
    }

    const handleSaveChanges = (e) => {
        e.preventDefault();
        props.updateASById(selectedAccountId, selectedCountryCode, newSettings, accountsList)

        //IF    current user is a #platform-admin 
        //THEN  update all marginControl of subclients of the account you just updated.
        console.log(getUserType())
        if(getUserType() == "supernova"){
            let accountsUnderRecentlyChangedMargin = users && users.items && users.items.filter(item => item.parentAccountId == selectedAccountId).map(item => {return item.accountId})
            let uniqueAccountsUnderRecentlyChangedMargin = [...new Set(accountsUnderRecentlyChangedMargin)];
            
            uniqueAccountsUnderRecentlyChangedMargin.map(accountId => {
                props.updateASById(accountId, selectedCountryCode, newSettings, accountsList)
            })

        }


        discardChanges();
    }

    const discardChanges = () => {
        setSelectedCountryCode(null);
        setSelectedAccountId(null);
        setSelectedSetting(null);
        setNewSettings(null);
    }

    const getUserType = () => {
        if(user.accountId){
            if(user.parentAccountId === undefined){             //IF there is no parentAccountId THEN it means the account is Supernova
                if(window.location.hostname == "local.supernova.inc"){
                    document.title = `Platform Admin`
                }
                return "supernova";
            }
            else if(user.parentAccountId === "0000" || user.parentAccountId === 0){           //IF the parentAccountId is 0000 (Which is Supernova's Account ID) THEN it means it is a direct client of supernova
                if(window.location.hostname == "local.supernova.inc"){
                    document.title = `Client | ${user.companyName}`
                }
                return "direct-client";
            }
            else{                                               //ELSE THEN it means it's a sub-client of supernova
                if(window.location.hostname == "local.supernova.inc"){
                    document.title = `Sub-client | ${user.companyName}`
                }
                return "sub-client"
            }
        }
        else{
            return null;
        }
    }


    try{
        return (
            <div className='admin'>

                {/* SUB MENU */}
                <LeftNavigation role={user.role}/>

                <div className='canvas'>
                    <div className="admin custom-panel table">
                    <div className="header">
                            Margin Control
                            <div className="subtitle">
                                {/*  */}
                            </div>
                            <div className="controls">
                                {/* <button type="button" className="btn btn-primary btn-xs btn-pagination"><i className="fa fa-plus-circle" aria-hidden="true"></i> Add Pixel</button> */}
                            </div>
                        </div>
                        {
                            (accountsSupernova && accountsSupernova.loading == false) ?
                                <table className="primary fixed-header has-hover margin-control">
                                        <thead>
                                            <tr>
                                                <th>Ad Group Bid Type</th>
                                                <th>Value</th>
                                                <th className='text-center'>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            accountsList.filter(accountId => accountId != user.accountId).map(accountId => 
                                                <React.Fragment key={accountId}>
                                                    <tr className="company">
                                                        <td colSpan={8}>
                                                            <label>
                                                                {/* {<img className="company-icon" src={`http://${users.items.filter(item => item.accountId == accountId)[0].companyWebsite}/favicon.ico`} alt=""/>} */}
                                                                {getCompanyByAccountId(accountId)}
                                                                <span className='badge mr-2'>{accountId}</span>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                    {
                                                        accountsSupernova && accountsSupernova.items && accountsSupernova.items.filter(item => item.accountId == accountId).map(item => 
                                                            <React.Fragment key={`${accountId}${item.countryCode}`}>
                                                                    <tr className="country d-none">
                                                                        <td colSpan={7}>{item.countryCode == "default" ? "All Countries" : item.country}</td>
                                                                    </tr>
                                                                    <tr className='fields no-border-top d-none'>
                                                                        <td>Bid Adjuster</td>
                                                                        {
                                                                            selectedCountryCode == item.countryCode &&
                                                                            selectedAccountId == accountId &&
                                                                            selectedSetting == "bid_adjustment" ?
                                                                            <>
                                                                                <td><input type="number" defaultValue={getUserType() == "direct-client" ? item.bid_adjustment_client_cpm_display : item.bid_adjustment_platform_cpm_display} value={getUserType() == "direct-client" ? newSettings.bid_adjustment_client_cpm_display : newSettings.bid_adjustment_platform_cpm_display} name={getUserType() == "direct-client" ? "bid_adjustment_client_cpm_display" : "bid_adjustment_platform_cpm_display"} onChange={(e) => handleChangeInputText(e)}></input></td>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <td>{parseFloat(getUserType() == "direct-client" ? item.bid_adjustment_client_cpm_display : item.bid_adjustment_platform_cpm_display).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}%</td>
                                                                            </>
                                                                        }
                                                                        <td className="text-center">
                                                                            {
                                                                                accountsSupernova.updating ? 
                                                                                    <em> - Saving...</em>
                                                                                    :   selectedCountryCode == item.countryCode &&
                                                                                        selectedAccountId == accountId &&
                                                                                        selectedSetting == "bid_adjustment" ?  
                                                                                            <span>
                                                                                                <button className="btn btn-primary ml-2" onClick={(e) => handleSaveChanges(e)}>Save</button>
                                                                                                <button className="btn btn-secondary ml-2 mr-2 btn-sm" onClick={(e) => discardChanges()}>Discard</button>
                                                                                            </span>
                                                                                        :
                                                                                        <span>
                                                                                            <OverlayTrigger placement="left" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                                                                <button type="button" className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${hasSelectedRow() ? "disabled" : ""}`} onClick={() => {
                                                                                                    setSelectedCountryCode(item.countryCode);
                                                                                                    setSelectedAccountId(accountId);
                                                                                                    setSelectedSetting("bid_adjustment");
                                                                                                    getUserType() == "direct-client" ?
                                                                                                        setNewSettings({
                                                                                                            bid_adjustment_client_cpm_display     : item.bid_adjustment_client_cpm_display,
                                                                                                            bid_adjustment_client_cpc_display     : item.bid_adjustment_client_cpc_display,
                                                                                                        })
                                                                                                    :
                                                                                                        setNewSettings({
                                                                                                            bid_adjustment_platform_cpm_display     : item.bid_adjustment_platform_cpm_display,
                                                                                                            bid_adjustment_platform_cpc_display     : item.bid_adjustment_platform_cpc_display,
                                                                                                        })
                                                                                                }}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                                                            </OverlayTrigger>
                                                                                        </span>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    {
                                                                        //DELTA MARGIN APPLIES ONLY FOR DIRECT CLIENTS (CONTROLLED BY PLATFORM ADMIN)
                                                                        user.role == "platform_admin" &&
                                                                        <tr className='fields'>
                                                                            <td>Delta</td>
                                                                            {
                                                                                selectedCountryCode == item.countryCode &&
                                                                                selectedAccountId == accountId &&
                                                                                selectedSetting == "delta_margin" ?
                                                                                <>
                                                                                    <td><input type="number" defaultValue={item.delta_margin_cpm_display} value={newSettings.delta_margin_cpm_display} name="delta_margin_cpm_display" onChange={(e) => handleChangeInputText(e)}></input></td>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <td>{parseFloat(item.delta_margin_cpm_display).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}%</td>
                                                                                </>
                                                                            }
                                                                            <td className="text-center">
                                                                                {
                                                                                    accountsSupernova.updating ? 
                                                                                    <em> - Saving...</em>
                                                                                    :   selectedCountryCode == item.countryCode &&
                                                                                        selectedAccountId == accountId &&
                                                                                        selectedSetting == "delta_margin" ?  
                                                                                            <span>
                                                                                                <button className="btn btn-primary ml-2" onClick={(e) => handleSaveChanges(e)}>Save</button>
                                                                                                <button className="btn btn-secondary ml-2 mr-2 btn-sm" onClick={(e) => discardChanges()}>Discard</button>
                                                                                            </span>
                                                                                        :
                                                                                        <span>
                                                                                            <OverlayTrigger placement="left" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                                                                <button type="button" className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${hasSelectedRow() ? "disabled" : ""}`} onClick={() => {
                                                                                                    setSelectedCountryCode(item.countryCode);
                                                                                                    setSelectedAccountId(accountId);
                                                                                                    setSelectedSetting("delta_margin");
                                                                                                    setNewSettings({
                                                                                                        delta_margin_cpm_display     : item.delta_margin_cpm_display,
                                                                                                        delta_margin_cpc_display     : item.delta_margin_cpc_display,
                                                                                                    })
                                                                                                }}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                                                            </OverlayTrigger>
                                                                                        </span>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                    <tr className='fields'>
                                                                        <td>License Fee</td>
                                                                        {
                                                                            selectedCountryCode == item.countryCode &&
                                                                            selectedAccountId == accountId &&
                                                                            selectedSetting == "license_fee" ?
                                                                            <>
                                                                                <td><input type="number" defaultValue={getUserType() == "direct-client" ? item.license_fee_client_cpm_display : item.license_fee_platform_cpm_display} value={getUserType() == "direct-client" ? newSettings.license_fee_client_cpm_display : newSettings.license_fee_platform_cpm_display} name={getUserType() == "direct-client" ? "license_fee_client_cpm_display" : "license_fee_platform_cpm_display"} onChange={(e) => handleChangeInputTextMultipleCampaignType(e)}></input></td>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <td>{parseFloat(getUserType() == "direct-client" ? item.license_fee_client_cpm_display : item.license_fee_platform_cpm_display).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}%</td>
                                                                            </>
                                                                        }
                                                                        <td className="text-center">
                                                                            {
                                                                                accountsSupernova.updating ? 
                                                                                <em> - Saving...</em>
                                                                                :   selectedCountryCode == item.countryCode &&
                                                                                    selectedAccountId == accountId &&
                                                                                    selectedSetting == "license_fee" ?  
                                                                                        <span>
                                                                                            <button className="btn btn-primary ml-2" onClick={(e) => handleSaveChanges(e)}>Save</button>
                                                                                            <button className="btn btn-secondary ml-2 mr-2 btn-sm" onClick={(e) => discardChanges()}>Discard</button>
                                                                                        </span>
                                                                                    :
                                                                                    <span>
                                                                                        <OverlayTrigger placement="left" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                                                            <button type="button" className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${hasSelectedRow() ? "disabled" : ""}`} onClick={() => {
                                                                                                setSelectedCountryCode(item.countryCode);
                                                                                                setSelectedAccountId(accountId);
                                                                                                setSelectedSetting("license_fee");
                                                                                                getUserType() == "direct-client" ?
                                                                                                    //IF the user logged in is a {direct-client} THEN it means they are updating {sub-client} 
                                                                                                    //THEREFORE we need to update both: client and sub-client license fee
                                                                                                    //NOTE: Notice that license fee variable has "display" at the end (for example: item.license_fee_client_cpm_display). 
                                                                                                    //      Because in the future we may have different license fee for each campaign type. 
                                                                                                    //      But for now we only use one (1) license fee for all campaign types.
                                                                                                    setNewSettings({
                                                                                                        license_fee_client_cpm_display     : item.license_fee_client_cpm_display,
                                                                                                        license_fee_client_cpc_display     : item.license_fee_client_cpc_display,
                                                                                                        license_fee_platform_cpm_display    : accountsSupernova && accountsSupernova.items && accountsSupernova.items.find(item => item.accountId == user.accountId).license_fee_platform_cpm_display,
                                                                                                        license_fee_platform_cpc_display    : accountsSupernova && accountsSupernova.items && accountsSupernova.items.find(item => item.accountId == user.accountId).license_fee_platform_cpc_display,
                                                                                                        
                                                                                                        license_fee_client_cpm_push     : item.license_fee_client_cpm_push,
                                                                                                        license_fee_client_cpc_push     : item.license_fee_client_cpc_push,
                                                                                                        license_fee_platform_cpm_push    : accountsSupernova && accountsSupernova.items && accountsSupernova.items.find(item => item.accountId == user.accountId).license_fee_platform_cpm_push,
                                                                                                        license_fee_platform_cpc_push    : accountsSupernova && accountsSupernova.items && accountsSupernova.items.find(item => item.accountId == user.accountId).license_fee_platform_cpc_push,
                                                                                                    })
                                                                                                :
                                                                                                    //ELSE the user logged in must of been a platform atmin because (only platform-admin and direct-client can have an admin)
                                                                                                    //THEREFORE we dont need to touch the sub_client license fee
                                                                                                    setNewSettings({
                                                                                                        license_fee_platform_cpm_display    : item.license_fee_platform_cpm_display,
                                                                                                        license_fee_platform_cpc_display    : item.license_fee_platform_cpc_display,
                                                                                                    })
                                                                                                
                                                                                            }}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                                                        </OverlayTrigger>
                                                                                    </span>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    <tr className='fields'>
                                                                        <td>Serving Fee</td>
                                                                        {
                                                                            selectedCountryCode == item.countryCode &&
                                                                            selectedAccountId == accountId &&
                                                                            selectedSetting == "serving_fee" ?
                                                                            <>
                                                                                <td><input type="number" defaultValue={getUserType() == "direct-client" ? item.serving_fee_client_cpm_display : item.serving_fee_platform_cpm_display} value={getUserType() == "direct-client" ? newSettings.serving_fee_client_cpm_display : newSettings.serving_fee_platform_cpm_display} name={getUserType() == "direct-client" ? "serving_fee_client_cpm_display" : "serving_fee_platform_cpm_display"} onChange={(e) => handleChangeInputText(e)}></input></td>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <td>${parseFloat(getUserType() == "direct-client" ? item.serving_fee_client_cpm_display : item.serving_fee_platform_cpm_display).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                                                            </>
                                                                        }
                                                                        <td className="text-center">
                                                                            {
                                                                                accountsSupernova.updating ? 
                                                                                <em> - Saving...</em>
                                                                                :   selectedCountryCode == item.countryCode &&
                                                                                    selectedAccountId == accountId &&
                                                                                    selectedSetting == "serving_fee" ?  
                                                                                        <span>
                                                                                            <button className="btn btn-primary ml-2" onClick={(e) => handleSaveChanges(e)}>Save</button>
                                                                                            <button className="btn btn-secondary ml-2 mr-2 btn-sm" onClick={(e) => discardChanges()}>Discard</button>
                                                                                        </span>
                                                                                    :
                                                                                    <span>
                                                                                        <OverlayTrigger placement="left" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                                                            <button type="button" className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${hasSelectedRow() ? "disabled" : ""}`} onClick={() => {
                                                                                                setSelectedCountryCode(item.countryCode);
                                                                                                setSelectedAccountId(accountId);
                                                                                                setSelectedSetting("serving_fee");
                                                                                                getUserType() == "direct-client" ?
                                                                                                    setNewSettings({
                                                                                                        serving_fee_client_cpm_display     : item.serving_fee_client_cpm_display,
                                                                                                        serving_fee_client_cpc_display     : item.serving_fee_client_cpc_display,
                                                                                                    })
                                                                                                :
                                                                                                    setNewSettings({
                                                                                                        serving_fee_platform_cpm_display     : item.serving_fee_platform_cpm_display,
                                                                                                        serving_fee_platform_cpc_display     : item.serving_fee_platform_cpc_display,
                                                                                                    })
                                                                                            }}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                                                        </OverlayTrigger>
                                                                                    </span>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    <tr className={`fields ${getUserType() != "supernova" ? "d-none" : ""}`}>
                                                                        <td>Data Spend Margin</td>
                                                                        {
                                                                            // DATA SPEND IS CONTROLLED BY BOTH PLATFORM ADMIN AND RESELLER ADMIN. SAME VALUE IS APPLIED REGARDLESS WETHER IT IS DIRECT OS SUBCLIENT
                                                                            selectedCountryCode == item.countryCode &&
                                                                            selectedAccountId == accountId &&
                                                                            selectedSetting == "data_spend_margin" ?
                                                                            <>
                                                                                <td><input type="number" defaultValue={item.data_spend_margin_cpm_display} value={newSettings.data_spend_margin_cpm_display} name="data_spend_margin_cpm_display" onChange={(e) => handleChangeInputText(e)}></input></td>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <td>{parseFloat(item.data_spend_margin_cpm_display).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}%</td>
                                                                            </>
                                                                        }
                                                                        <td className="text-center">
                                                                            {
                                                                                accountsSupernova.updating ? 
                                                                                <em> - Saving...</em>
                                                                                :   selectedCountryCode == item.countryCode &&
                                                                                    selectedAccountId == accountId &&
                                                                                    selectedSetting == "data_spend_margin" ?  
                                                                                        <span>
                                                                                            <button className="btn btn-primary ml-2" onClick={(e) => handleSaveChanges(e)}>Save</button>
                                                                                            <button className="btn btn-secondary ml-2 mr-2 btn-sm" onClick={(e) => discardChanges()}>Discard</button>
                                                                                        </span>
                                                                                    :
                                                                                    <span>
                                                                                        <OverlayTrigger placement="left" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                                                            <button type="button" className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${hasSelectedRow() ? "disabled" : ""}`} onClick={() => {
                                                                                                setSelectedCountryCode(item.countryCode);
                                                                                                setSelectedAccountId(accountId);
                                                                                                setSelectedSetting("data_spend_margin");
                                                                                                setNewSettings({
                                                                                                    data_spend_margin_cpm_display    : item.data_spend_margin_cpm_display,
                                                                                                    data_spend_margin_cpc_display    : item.data_spend_margin_cpc_display
                                                                                                })
                                                                                            }}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                                                        </OverlayTrigger>
                                                                                    </span>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    {/*********************************************************************************
                                                                     * 1. DISPLAY MINIMUM BID CPM
                                                                     *********************************************************************************/}
                                                                    <tr className={`fields ${user.showBidControl ? "" : "d-none"}`}>
                                                                        <td>Display Minimum Bid CPM</td>
                                                                        {
                                                                            selectedCountryCode == item.countryCode &&
                                                                            selectedAccountId == accountId &&
                                                                            selectedSetting == "minimum_bid_display_cpm" ?
                                                                            <>
                                                                                <td><input type="number" defaultValue={getUserType() == "direct-client" ?  item.minimum_bid_client_display_cpm : item.minimum_bid_platform_display_cpm} value={getUserType() == "direct-client" ? newSettings.minimum_bid_client_display_cpm === undefined ? 0 : newSettings.minimum_bid_client_display_cpm : newSettings.minimum_bid_platform_display_cpm === undefined ? 0 : newSettings.minimum_bid_platform_display_cpm} name={getUserType() == "direct-client" ? "minimum_bid_client_display_cpm" : "minimum_bid_platform_display_cpm"}  onChange={(e) => handleChangeInputText(e)}></input></td>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <td>${parseFloat(getUserType() == "direct-client" ? item.minimum_bid_client_display_cpm : item.minimum_bid_platform_display_cpm === undefined ? 0.00 : item.minimum_bid_platform_display_cpm).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                                                            </>
                                                                        }
                                                                        <td className="text-center">
                                                                            {
                                                                                accountsSupernova.updating ? 
                                                                                <em> - Saving...</em>
                                                                                :   selectedCountryCode == item.countryCode &&
                                                                                    selectedAccountId == accountId &&
                                                                                    selectedSetting == "minimum_bid_display_cpm" ?  
                                                                                        <span>
                                                                                            <button className="btn btn-primary ml-2" onClick={(e) => handleSaveChanges(e)}>Save</button>
                                                                                            <button className="btn btn-secondary ml-2 mr-2 btn-sm" onClick={(e) => discardChanges()}>Discard</button>
                                                                                        </span>
                                                                                    :
                                                                                    <span>
                                                                                        <OverlayTrigger placement="left" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                                                            <button type="button" className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${hasSelectedRow() ? "disabled" : ""}`} onClick={() => {
                                                                                                setSelectedCountryCode(item.countryCode);
                                                                                                setSelectedAccountId(accountId);
                                                                                                setSelectedSetting("minimum_bid_display_cpm");
                                                                                                getUserType() == "direct-client" ?
                                                                                                    setNewSettings({
                                                                                                        minimum_bid_client_display_cpm     : item.minimum_bid_client_display_cpm,
                                                                                                    })
                                                                                                :
                                                                                                    setNewSettings({
                                                                                                        minimum_bid_platform_display_cpm     : item.minimum_bid_platform_display_cpm,
                                                                                                    })
                                                                                            }}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                                                        </OverlayTrigger>
                                                                                    </span>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    {/*********************************************************************************
                                                                     * 2. DISPLAY MINIMUM BID CPC
                                                                     *********************************************************************************/}
                                                                    <tr className={`fields ${user.showBidControl ? "" : "d-none"}`}>
                                                                        <td>Display Minimum Bid CPC</td>
                                                                        {
                                                                            selectedCountryCode == item.countryCode &&
                                                                            selectedAccountId == accountId &&
                                                                            selectedSetting == "minimum_bid_display_cpc" ?
                                                                            <>
                                                                                <td><input type="number" defaultValue={getUserType() == "direct-client" ?  item.minimum_bid_client_display_cpc : item.minimum_bid_platform_display_cpc} value={getUserType() == "direct-client" ? newSettings.minimum_bid_client_display_cpc === undefined ? 0 : newSettings.minimum_bid_client_display_cpc : newSettings.minimum_bid_platform_display_cpc === undefined ? 0 : newSettings.minimum_bid_platform_display_cpc} name={getUserType() == "direct-client" ? "minimum_bid_client_display_cpc" : "minimum_bid_platform_display_cpc"}  onChange={(e) => handleChangeInputText(e)}></input></td>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <td>${parseFloat(getUserType() == "direct-client" ? item.minimum_bid_client_display_cpc : item.minimum_bid_platform_display_cpc === undefined ? 0.00 : item.minimum_bid_platform_display_cpc).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                                                            </>
                                                                        }
                                                                        <td className="text-center">
                                                                            {
                                                                                accountsSupernova.updating ? 
                                                                                <em> - Saving...</em>
                                                                                :   selectedCountryCode == item.countryCode &&
                                                                                    selectedAccountId == accountId &&
                                                                                    selectedSetting == "minimum_bid_display_cpc" ?  
                                                                                        <span>
                                                                                            <button className="btn btn-primary ml-2" onClick={(e) => handleSaveChanges(e)}>Save</button>
                                                                                            <button className="btn btn-secondary ml-2 mr-2 btn-sm" onClick={(e) => discardChanges()}>Discard</button>
                                                                                        </span>
                                                                                    :
                                                                                    <span>
                                                                                        <OverlayTrigger placement="left" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                                                            <button type="button" className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${hasSelectedRow() ? "disabled" : ""}`} onClick={() => {
                                                                                                setSelectedCountryCode(item.countryCode);
                                                                                                setSelectedAccountId(accountId);
                                                                                                setSelectedSetting("minimum_bid_display_cpc");
                                                                                                getUserType() == "direct-client" ?
                                                                                                    setNewSettings({
                                                                                                        minimum_bid_client_display_cpc     : item.minimum_bid_client_display_cpc,
                                                                                                    })
                                                                                                :
                                                                                                    setNewSettings({
                                                                                                        minimum_bid_platform_display_cpc     : item.minimum_bid_platform_display_cpc,
                                                                                                    })
                                                                                            }}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                                                        </OverlayTrigger>
                                                                                    </span>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    {/*********************************************************************************
                                                                     * 3. NATIVE MINIMUM BID CPM
                                                                     *********************************************************************************/}
                                                                    <tr className={`fields ${user.showBidControl ? "" : "d-none"}`}>
                                                                        <td>Native Minimum Bid CPM</td>
                                                                        {
                                                                            selectedCountryCode == item.countryCode &&
                                                                            selectedAccountId == accountId &&
                                                                            selectedSetting == "minimum_bid_native_cpm" ?
                                                                            <>
                                                                                <td><input type="number" defaultValue={getUserType() == "direct-client" ?  item.minimum_bid_client_native_cpm : item.minimum_bid_platform_native_cpm} value={getUserType() == "direct-client" ? newSettings.minimum_bid_client_native_cpm === undefined ? 0 : newSettings.minimum_bid_client_native_cpm : newSettings.minimum_bid_platform_native_cpm === undefined ? 0 : newSettings.minimum_bid_platform_native_cpm} name={getUserType() == "direct-client" ? "minimum_bid_client_native_cpm" : "minimum_bid_platform_native_cpm"}  onChange={(e) => handleChangeInputText(e)}></input></td>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <td>${parseFloat(getUserType() == "direct-client" ? item.minimum_bid_client_native_cpm : item.minimum_bid_platform_native_cpm === undefined ? 0.00 : item.minimum_bid_platform_native_cpm).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                                                            </>
                                                                        }
                                                                        <td className="text-center">
                                                                            {
                                                                                accountsSupernova.updating ? 
                                                                                <em> - Saving...</em>
                                                                                :   selectedCountryCode == item.countryCode &&
                                                                                    selectedAccountId == accountId &&
                                                                                    selectedSetting == "minimum_bid_native_cpm" ?  
                                                                                        <span>
                                                                                            <button className="btn btn-primary ml-2" onClick={(e) => handleSaveChanges(e)}>Save</button>
                                                                                            <button className="btn btn-secondary ml-2 mr-2 btn-sm" onClick={(e) => discardChanges()}>Discard</button>
                                                                                        </span>
                                                                                    :
                                                                                    <span>
                                                                                        <OverlayTrigger placement="left" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                                                            <button type="button" className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${hasSelectedRow() ? "disabled" : ""}`} onClick={() => {
                                                                                                setSelectedCountryCode(item.countryCode);
                                                                                                setSelectedAccountId(accountId);
                                                                                                setSelectedSetting("minimum_bid_native_cpm");
                                                                                                getUserType() == "direct-client" ?
                                                                                                    setNewSettings({
                                                                                                        minimum_bid_client_native_cpm     : item.minimum_bid_client_native_cpm,
                                                                                                    })
                                                                                                :
                                                                                                    setNewSettings({
                                                                                                        minimum_bid_platform_native_cpm     : item.minimum_bid_platform_native_cpm,
                                                                                                    })
                                                                                            }}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                                                        </OverlayTrigger>
                                                                                    </span>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    {/*********************************************************************************
                                                                     * 4. NATIVE MINIMUM BID CPC
                                                                     *********************************************************************************/}
                                                                    <tr className={`fields ${user.showBidControl ? "" : "d-none"}`}>
                                                                        <td>Native Minimum Bid CPC</td>
                                                                        {
                                                                            selectedCountryCode == item.countryCode &&
                                                                            selectedAccountId == accountId &&
                                                                            selectedSetting == "minimum_bid_native_cpc" ?
                                                                            <>
                                                                                <td><input type="number" defaultValue={getUserType() == "direct-client" ?  item.minimum_bid_client_native_cpc : item.minimum_bid_platform_native_cpc} value={getUserType() == "direct-client" ? newSettings.minimum_bid_client_native_cpc === undefined ? 0 : newSettings.minimum_bid_client_native_cpc : newSettings.minimum_bid_platform_native_cpc === undefined ? 0 : newSettings.minimum_bid_platform_native_cpc} name={getUserType() == "direct-client" ? "minimum_bid_client_native_cpc" : "minimum_bid_platform_native_cpc"}  onChange={(e) => handleChangeInputText(e)}></input></td>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <td>${parseFloat(getUserType() == "direct-client" ? item.minimum_bid_client_native_cpc : item.minimum_bid_platform_native_cpc === undefined ? 0.00 : item.minimum_bid_platform_native_cpc).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                                                            </>
                                                                        }
                                                                        <td className="text-center">
                                                                            {
                                                                                accountsSupernova.updating ? 
                                                                                <em> - Saving...</em>
                                                                                :   selectedCountryCode == item.countryCode &&
                                                                                    selectedAccountId == accountId &&
                                                                                    selectedSetting == "minimum_bid_native_cpc" ?  
                                                                                        <span>
                                                                                            <button className="btn btn-primary ml-2" onClick={(e) => handleSaveChanges(e)}>Save</button>
                                                                                            <button className="btn btn-secondary ml-2 mr-2 btn-sm" onClick={(e) => discardChanges()}>Discard</button>
                                                                                        </span>
                                                                                    :
                                                                                    <span>
                                                                                        <OverlayTrigger placement="left" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                                                            <button type="button" className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${hasSelectedRow() ? "disabled" : ""}`} onClick={() => {
                                                                                                setSelectedCountryCode(item.countryCode);
                                                                                                setSelectedAccountId(accountId);
                                                                                                setSelectedSetting("minimum_bid_native_cpc");
                                                                                                getUserType() == "direct-client" ?
                                                                                                    setNewSettings({
                                                                                                        minimum_bid_client_native_cpc     : item.minimum_bid_client_native_cpc,
                                                                                                    })
                                                                                                :
                                                                                                    setNewSettings({
                                                                                                        minimum_bid_platform_native_cpc     : item.minimum_bid_platform_native_cpc,
                                                                                                    })
                                                                                            }}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                                                        </OverlayTrigger>
                                                                                    </span>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    {/*********************************************************************************
                                                                     * 5. VIDEO MINIMUM BID CPM
                                                                     *********************************************************************************/}
                                                                    <tr className={`fields ${user.showBidControl ? "" : "d-none"}`}>
                                                                        <td>Video MinimumBid CPM</td>
                                                                        {
                                                                            selectedCountryCode == item.countryCode &&
                                                                            selectedAccountId == accountId &&
                                                                            selectedSetting == "minimum_bid_video_cpm" ?
                                                                            <>
                                                                                <td><input type="number" defaultValue={getUserType() == "direct-client" ?  item.minimum_bid_client_video_cpm : item.minimum_bid_platform_video_cpm} value={getUserType() == "direct-client" ? newSettings.minimum_bid_client_video_cpm === undefined ? 0 : newSettings.minimum_bid_client_video_cpm : newSettings.minimum_bid_platform_video_cpm === undefined ? 0 : newSettings.minimum_bid_platform_video_cpm} name={getUserType() == "direct-client" ? "minimum_bid_client_video_cpm" : "minimum_bid_platform_video_cpm"}  onChange={(e) => handleChangeInputText(e)}></input></td>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <td>${parseFloat(getUserType() == "direct-client" ? item.minimum_bid_client_video_cpm : item.minimum_bid_platform_video_cpm === undefined ? 0.00 : item.minimum_bid_platform_video_cpm).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                                                            </>
                                                                        }
                                                                        <td className="text-center">
                                                                            {
                                                                                accountsSupernova.updating ? 
                                                                                <em> - Saving...</em>
                                                                                :   selectedCountryCode == item.countryCode &&
                                                                                    selectedAccountId == accountId &&
                                                                                    selectedSetting == "minimum_bid_video_cpm" ?  
                                                                                        <span>
                                                                                            <button className="btn btn-primary ml-2" onClick={(e) => handleSaveChanges(e)}>Save</button>
                                                                                            <button className="btn btn-secondary ml-2 mr-2 btn-sm" onClick={(e) => discardChanges()}>Discard</button>
                                                                                        </span>
                                                                                    :
                                                                                    <span>
                                                                                        <OverlayTrigger placement="left" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                                                            <button type="button" className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${hasSelectedRow() ? "disabled" : ""}`} onClick={() => {
                                                                                                setSelectedCountryCode(item.countryCode);
                                                                                                setSelectedAccountId(accountId);
                                                                                                setSelectedSetting("minimum_bid_video_cpm");
                                                                                                getUserType() == "direct-client" ?
                                                                                                    setNewSettings({
                                                                                                        minimum_bid_client_video_cpm     : item.minimum_bid_client_video_cpm,
                                                                                                    })
                                                                                                :
                                                                                                    setNewSettings({
                                                                                                        minimum_bid_platform_video_cpm     : item.minimum_bid_platform_video_cpm,
                                                                                                    })
                                                                                            }}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                                                        </OverlayTrigger>
                                                                                    </span>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    {/*********************************************************************************
                                                                     * 6. VIDEO MINIMUM BID CPC
                                                                     *********************************************************************************/}
                                                                    <tr className={`fields ${user.showBidControl ? "" : "d-none"}`}>
                                                                        <td>Video MinimumBid CPC</td>
                                                                        {
                                                                            selectedCountryCode == item.countryCode &&
                                                                            selectedAccountId == accountId &&
                                                                            selectedSetting == "minimum_bid_video_cpc" ?
                                                                            <>
                                                                                <td><input type="number" defaultValue={getUserType() == "direct-client" ?  item.minimum_bid_client_video_cpc : item.minimum_bid_platform_video_cpc} value={getUserType() == "direct-client" ? newSettings.minimum_bid_client_video_cpc === undefined ? 0 : newSettings.minimum_bid_client_video_cpc : newSettings.minimum_bid_platform_video_cpc === undefined ? 0 : newSettings.minimum_bid_platform_video_cpc} name={getUserType() == "direct-client" ? "minimum_bid_client_video_cpc" : "minimum_bid_platform_video_cpc"}  onChange={(e) => handleChangeInputText(e)}></input></td>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <td>${parseFloat(getUserType() == "direct-client" ? item.minimum_bid_client_video_cpc : item.minimum_bid_platform_video_cpc === undefined ? 0.00 : item.minimum_bid_platform_video_cpc).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                                                            </>
                                                                        }
                                                                        <td className="text-center">
                                                                            {
                                                                                accountsSupernova.updating ? 
                                                                                <em> - Saving...</em>
                                                                                :   selectedCountryCode == item.countryCode &&
                                                                                    selectedAccountId == accountId &&
                                                                                    selectedSetting == "minimum_bid_video_cpc" ?  
                                                                                        <span>
                                                                                            <button className="btn btn-primary ml-2" onClick={(e) => handleSaveChanges(e)}>Save</button>
                                                                                            <button className="btn btn-secondary ml-2 mr-2 btn-sm" onClick={(e) => discardChanges()}>Discard</button>
                                                                                        </span>
                                                                                    :
                                                                                    <span>
                                                                                        <OverlayTrigger placement="left" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                                                            <button type="button" className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${hasSelectedRow() ? "disabled" : ""}`} onClick={() => {
                                                                                                setSelectedCountryCode(item.countryCode);
                                                                                                setSelectedAccountId(accountId);
                                                                                                setSelectedSetting("minimum_bid_video_cpc");
                                                                                                getUserType() == "direct-client" ?
                                                                                                    setNewSettings({
                                                                                                        minimum_bid_client_video_cpc     : item.minimum_bid_client_video_cpc,
                                                                                                    })
                                                                                                :
                                                                                                    setNewSettings({
                                                                                                        minimum_bid_platform_video_cpc     : item.minimum_bid_platform_video_cpc,
                                                                                                    })
                                                                                            }}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                                                        </OverlayTrigger>
                                                                                    </span>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    {/*********************************************************************************
                                                                     * 9. CONNECTED TV MINIMUM BID CPM
                                                                     *********************************************************************************/}
                                                                    <tr className={`fields ${user.showBidControl ? "" : "d-none"}`}>
                                                                        <td>Connected TV Minimum Bid CPM</td>
                                                                        {
                                                                            selectedCountryCode == item.countryCode &&
                                                                            selectedAccountId == accountId &&
                                                                            selectedSetting == "minimum_bid_connected_tv_cpm" ?
                                                                            <>
                                                                                <td><input type="number" defaultValue={getUserType() == "direct-client" ?  item.minimum_bid_client_connected_tv_cpm : item.minimum_bid_platform_connected_tv_cpm} value={getUserType() == "direct-client" ? newSettings.minimum_bid_client_connected_tv_cpm === undefined ? 0 : newSettings.minimum_bid_client_connected_tv_cpm : newSettings.minimum_bid_platform_connected_tv_cpm === undefined ? 0 : newSettings.minimum_bid_platform_connected_tv_cpm} name={getUserType() == "direct-client" ? "minimum_bid_client_connected_tv_cpm" : "minimum_bid_platform_connected_tv_cpm"}  onChange={(e) => handleChangeInputText(e)}></input></td>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <td>${parseFloat(getUserType() == "direct-client" ? item.minimum_bid_client_connected_tv_cpm : item.minimum_bid_platform_connected_tv_cpm === undefined ? 0.00 : item.minimum_bid_platform_connected_tv_cpm).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                                                            </>
                                                                        }
                                                                        <td className="text-center">
                                                                            {
                                                                                accountsSupernova.updating ? 
                                                                                <em> - Saving...</em>
                                                                                :   selectedCountryCode == item.countryCode &&
                                                                                    selectedAccountId == accountId &&
                                                                                    selectedSetting == "minimum_bid_connected_tv_cpm" ?  
                                                                                        <span>
                                                                                            <button className="btn btn-primary ml-2" onClick={(e) => handleSaveChanges(e)}>Save</button>
                                                                                            <button className="btn btn-secondary ml-2 mr-2 btn-sm" onClick={(e) => discardChanges()}>Discard</button>
                                                                                        </span>
                                                                                    :
                                                                                    <span>
                                                                                        <OverlayTrigger placement="left" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                                                            <button type="button" className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${hasSelectedRow() ? "disabled" : ""}`} onClick={() => {
                                                                                                setSelectedCountryCode(item.countryCode);
                                                                                                setSelectedAccountId(accountId);
                                                                                                setSelectedSetting("minimum_bid_connected_tv_cpm");
                                                                                                getUserType() == "direct-client" ?
                                                                                                    setNewSettings({
                                                                                                        minimum_bid_client_connected_tv_cpm     : item.minimum_bid_client_connected_tv_cpm,
                                                                                                    })
                                                                                                :
                                                                                                    setNewSettings({
                                                                                                        minimum_bid_platform_connected_tv_cpm     : item.minimum_bid_platform_connected_tv_cpm,
                                                                                                    })
                                                                                            }}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                                                        </OverlayTrigger>
                                                                                    </span>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    {/*********************************************************************************
                                                                     * 10. CONNECTED TV MINIMUM BID CPC
                                                                     *********************************************************************************/}
                                                                    <tr className={`fields ${user.showBidControl ? "" : "d-none"}`}>
                                                                        <td>Connected TV Minimum Bid CPC</td>
                                                                        {
                                                                            selectedCountryCode == item.countryCode &&
                                                                            selectedAccountId == accountId &&
                                                                            selectedSetting == "minimum_bid_connected_tv_cpc" ?
                                                                            <>
                                                                                <td><input type="number" defaultValue={getUserType() == "direct-client" ?  item.minimum_bid_client_connected_tv_cpc : item.minimum_bid_platform_connected_tv_cpc} value={getUserType() == "direct-client" ? newSettings.minimum_bid_client_connected_tv_cpc === undefined ? 0 : newSettings.minimum_bid_client_connected_tv_cpc : newSettings.minimum_bid_platform_connected_tv_cpc === undefined ? 0 : newSettings.minimum_bid_platform_connected_tv_cpc} name={getUserType() == "direct-client" ? "minimum_bid_client_connected_tv_cpc" : "minimum_bid_platform_connected_tv_cpc"}  onChange={(e) => handleChangeInputText(e)}></input></td>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <td>${parseFloat(getUserType() == "direct-client" ? item.minimum_bid_client_connected_tv_cpc : item.minimum_bid_platform_connected_tv_cpc === undefined ? 0.00 : item.minimum_bid_platform_connected_tv_cpc).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                                                            </>
                                                                        }
                                                                        <td className="text-center">
                                                                            {
                                                                                accountsSupernova.updating ? 
                                                                                <em> - Saving...</em>
                                                                                :   selectedCountryCode == item.countryCode &&
                                                                                    selectedAccountId == accountId &&
                                                                                    selectedSetting == "minimum_bid_connected_tv_cpc" ?  
                                                                                        <span>
                                                                                            <button className="btn btn-primary ml-2" onClick={(e) => handleSaveChanges(e)}>Save</button>
                                                                                            <button className="btn btn-secondary ml-2 mr-2 btn-sm" onClick={(e) => discardChanges()}>Discard</button>
                                                                                        </span>
                                                                                    :
                                                                                    <span>
                                                                                        <OverlayTrigger placement="left" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                                                            <button type="button" className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${hasSelectedRow() ? "disabled" : ""}`} onClick={() => {
                                                                                                setSelectedCountryCode(item.countryCode);
                                                                                                setSelectedAccountId(accountId);
                                                                                                setSelectedSetting("minimum_bid_connected_tv_cpc");
                                                                                                getUserType() == "direct-client" ?
                                                                                                    setNewSettings({
                                                                                                        minimum_bid_client_connected_tv_cpc     : item.minimum_bid_client_connected_tv_cpc,
                                                                                                    })
                                                                                                :
                                                                                                    setNewSettings({
                                                                                                        minimum_bid_platform_connected_tv_cpc     : item.minimum_bid_platform_connected_tv_cpc,
                                                                                                    })
                                                                                            }}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                                                        </OverlayTrigger>
                                                                                    </span>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    

                                                                    {
                                                                        ///////////////////////////////
                                                                        // ROWS BELOW ARE ALL FOR PUSH
                                                                        ///////////////////////////////
                                                                    }
                                                                    {
                                                                        //PUSH DELTA MARGIN APPLIES ONLY FOR DIRECT CLIENTS (CONTROLLED BY PLATFORM ADMIN)
                                                                        user.role == "platform_admin" &&
                                                                        <>
                                                                            <tr className='fields push-section'>
                                                                                <td>Push Delta</td>
                                                                                {
                                                                                    selectedCountryCode == item.countryCode &&
                                                                                    selectedAccountId == accountId &&
                                                                                    selectedSetting == "push_delta_margin" ?
                                                                                    <>
                                                                                        <td><input type="number" defaultValue={item.delta_margin_cpm_push} value={newSettings.delta_margin_cpm_push} name="delta_margin_cpm_push" onChange={(e) => handleChangeInputText(e)}></input></td>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <td>{parseFloat(item.delta_margin_cpm_push).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}%</td>
                                                                                    </>
                                                                                }
                                                                                <td className="text-center">
                                                                                    {
                                                                                        accountsSupernova.updating ? 
                                                                                        <em> - Saving...</em>
                                                                                        :   selectedCountryCode == item.countryCode &&
                                                                                            selectedAccountId == accountId &&
                                                                                            selectedSetting == "push_delta_margin" ?  
                                                                                                <span>
                                                                                                    <button className="btn btn-primary ml-2" onClick={(e) => handleSaveChanges(e)}>Save</button>
                                                                                                    <button className="btn btn-secondary ml-2 mr-2 btn-sm" onClick={(e) => discardChanges()}>Discard</button>
                                                                                                </span>
                                                                                            :
                                                                                            <span>
                                                                                                <OverlayTrigger placement="left" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                                                                    <button type="button" className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${hasSelectedRow() ? "disabled" : ""}`} onClick={() => {
                                                                                                        setSelectedCountryCode(item.countryCode);
                                                                                                        setSelectedAccountId(accountId);
                                                                                                        setSelectedSetting("push_delta_margin");
                                                                                                        setNewSettings({
                                                                                                            delta_margin_cpm_push     : item.delta_margin_cpm_push,
                                                                                                            delta_margin_cpc_push     : item.delta_margin_cpc_push,
                                                                                                        })
                                                                                                    }}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                                                                </OverlayTrigger>
                                                                                            </span>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            <tr className='fields'>
                                                                                <td>Push License Fee</td>
                                                                                {
                                                                                    selectedCountryCode == item.countryCode &&
                                                                                    selectedAccountId == accountId &&
                                                                                    selectedSetting == "push_license_fee" ?
                                                                                    <>
                                                                                        <td><input type="number" defaultValue={getUserType() == "direct-client" ? item.license_fee_client_cpm_push : item.license_fee_platform_cpm_push} value={getUserType() == "direct-client" ? newSettings.license_fee_client_cpm_push : newSettings.license_fee_platform_cpm_push} name={getUserType() == "direct-client" ? "license_fee_client_cpm_push" : "license_fee_platform_cpm_push"} onChange={(e) => handleChangeInputText(e)}></input></td>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <td>{parseFloat(getUserType() == "direct-client" ? item.license_fee_client_cpm_push : item.license_fee_platform_cpm_push).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}%</td>
                                                                                    </>
                                                                                }
                                                                                <td className="text-center">
                                                                                    {
                                                                                        accountsSupernova.updating ? 
                                                                                        <em> - Saving...</em>
                                                                                        :   selectedCountryCode == item.countryCode &&
                                                                                            selectedAccountId == accountId &&
                                                                                            selectedSetting == "push_license_fee" ?  
                                                                                                <span>
                                                                                                    <button className="btn btn-primary ml-2" onClick={(e) => handleSaveChanges(e)}>Save</button>
                                                                                                    <button className="btn btn-secondary ml-2 mr-2 btn-sm" onClick={(e) => discardChanges()}>Discard</button>
                                                                                                </span>
                                                                                            :
                                                                                            <span>
                                                                                                <OverlayTrigger placement="left" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                                                                    <button type="button" className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${hasSelectedRow() ? "disabled" : ""}`} onClick={() => {
                                                                                                        setSelectedCountryCode(item.countryCode);
                                                                                                        setSelectedAccountId(accountId);
                                                                                                        setSelectedSetting("push_license_fee");
                                                                                                        getUserType() == "direct-client" ?
                                                                                                            setNewSettings({
                                                                                                                license_fee_client_cpm_push     : item.license_fee_client_cpm_push,
                                                                                                                license_fee_client_cpc_push     : item.license_fee_client_cpc_push,
                                                                                                            })
                                                                                                        :
                                                                                                            setNewSettings({
                                                                                                                license_fee_platform_cpm_push     : item.license_fee_platform_cpm_push,
                                                                                                                license_fee_platform_cpc_push     : item.license_fee_platform_cpc_push,
                                                                                                            })
                                                                                                        
                                                                                                    }}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                                                                </OverlayTrigger>
                                                                                            </span>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            <tr className='fields'>
                                                                                <td>Push Serving Fee</td>
                                                                                {
                                                                                    selectedCountryCode == item.countryCode &&
                                                                                    selectedAccountId == accountId &&
                                                                                    selectedSetting == "push_serving_fee" ?
                                                                                    <>
                                                                                        <td><input type="number" defaultValue={getUserType() == "direct-client" ? item.serving_fee_client_cpm_push : item.serving_fee_platform_cpm_push} value={getUserType() == "direct-client" ? newSettings.serving_fee_client_cpm_push : newSettings.serving_fee_platform_cpm_push} name={getUserType() == "direct-client" ? "serving_fee_client_cpm_push" : "serving_fee_platform_cpm_push"} onChange={(e) => handleChangeInputText(e)}></input></td>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <td>${parseFloat(getUserType() == "direct-client" ? item.serving_fee_client_cpm_push : item.serving_fee_platform_cpm_push).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                                                                    </>
                                                                                }
                                                                                <td className="text-center">
                                                                                    {
                                                                                        accountsSupernova.updating ? 
                                                                                        <em> - Saving...</em>
                                                                                        :   selectedCountryCode == item.countryCode &&
                                                                                            selectedAccountId == accountId &&
                                                                                            selectedSetting == "push_serving_fee" ?  
                                                                                                <span>
                                                                                                    <button className="btn btn-primary ml-2" onClick={(e) => handleSaveChanges(e)}>Save</button>
                                                                                                    <button className="btn btn-secondary ml-2 mr-2 btn-sm" onClick={(e) => discardChanges()}>Discard</button>
                                                                                                </span>
                                                                                            :
                                                                                            <span>
                                                                                                <OverlayTrigger placement="left" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                                                                    <button type="button" className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${hasSelectedRow() ? "disabled" : ""}`} onClick={() => {
                                                                                                        setSelectedCountryCode(item.countryCode);
                                                                                                        setSelectedAccountId(accountId);
                                                                                                        setSelectedSetting("push_serving_fee");
                                                                                                        getUserType() == "direct-client" ?
                                                                                                            setNewSettings({
                                                                                                                serving_fee_client_cpm_push     : item.serving_fee_client_cpm_push,
                                                                                                                serving_fee_client_cpc_push     : item.serving_fee_client_cpc_push,
                                                                                                            })
                                                                                                        :
                                                                                                            setNewSettings({
                                                                                                                serving_fee_platform_cpm_push     : item.serving_fee_platform_cpm_push,
                                                                                                                serving_fee_platform_cpc_push     : item.serving_fee_platform_cpc_push,
                                                                                                            })
                                                                                                    }}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                                                                </OverlayTrigger>
                                                                                            </span>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    }
                                                                    <tr className={`fields ${getUserType() != "supernova" ? "d-none" : ""}`}>
                                                                        <td>Push Data Spend Margin</td>
                                                                        {
                                                                            // DATA SPEND IS CONTROLLED BY BOTH PLATFORM ADMIN AND RESELLER ADMIN. SAME VALUE IS APPLIED REGARDLESS WETHER IT IS DIRECT OS SUBCLIENT
                                                                            selectedCountryCode == item.countryCode &&
                                                                            selectedAccountId == accountId &&
                                                                            selectedSetting == "push_data_spend_margin" ?
                                                                            <>
                                                                                <td><input type="number" defaultValue={item.data_spend_margin_cpm_push} value={newSettings.data_spend_margin_cpm_push} name="data_spend_margin_cpm_push" onChange={(e) => handleChangeInputText(e)}></input></td>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <td>{parseFloat(item.data_spend_margin_cpm_push).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}%</td>
                                                                            </>
                                                                        }
                                                                        <td className="text-center">
                                                                            {
                                                                                accountsSupernova.updating ? 
                                                                                <em> - Saving...</em>
                                                                                :   selectedCountryCode == item.countryCode &&
                                                                                    selectedAccountId == accountId &&
                                                                                    selectedSetting == "push_data_spend_margin" ?  
                                                                                        <span>
                                                                                            <button className="btn btn-primary ml-2" onClick={(e) => handleSaveChanges(e)}>Save</button>
                                                                                            <button className="btn btn-secondary ml-2 mr-2 btn-sm" onClick={(e) => discardChanges()}>Discard</button>
                                                                                        </span>
                                                                                    :
                                                                                    <span>
                                                                                        <OverlayTrigger placement="left" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                                                            <button type="button" className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${hasSelectedRow() ? "disabled" : ""}`} onClick={() => {
                                                                                                setSelectedCountryCode(item.countryCode);
                                                                                                setSelectedAccountId(accountId);
                                                                                                setSelectedSetting("push_data_spend_margin");
                                                                                                setNewSettings({
                                                                                                    data_spend_margin_cpm_push    : item.data_spend_margin_cpm_push,
                                                                                                    data_spend_margin_cpc_push    : item.data_spend_margin_cpc_push
                                                                                                })
                                                                                            }}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                                                        </OverlayTrigger>
                                                                                    </span>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    {/*********************************************************************************
                                                                     * 7. PUSH AD MINIMUM BID CPM
                                                                     *********************************************************************************/}
                                                                    <tr className={`fields ${user.showBidControl ? "" : "d-none"}`}>
                                                                        <td>Push Ad Minimum Bid CPM</td>
                                                                        {
                                                                            selectedCountryCode == item.countryCode &&
                                                                            selectedAccountId == accountId &&
                                                                            selectedSetting == "minimum_bid_push_ad_cpm" ?
                                                                            <>
                                                                                <td><input type="number" defaultValue={getUserType() == "direct-client" ?  item.minimum_bid_client_push_ad_cpm : item.minimum_bid_platform_push_ad_cpm} value={getUserType() == "direct-client" ? newSettings.minimum_bid_client_push_ad_cpm === undefined ? 0 : newSettings.minimum_bid_client_push_ad_cpm : newSettings.minimum_bid_platform_push_ad_cpm === undefined ? 0 : newSettings.minimum_bid_platform_push_ad_cpm} name={getUserType() == "direct-client" ? "minimum_bid_client_push_ad_cpm" : "minimum_bid_platform_push_ad_cpm"}  onChange={(e) => handleChangeInputText(e)}></input></td>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <td>${parseFloat(getUserType() == "direct-client" ? item.minimum_bid_client_push_ad_cpm : item.minimum_bid_platform_push_ad_cpm === undefined ? 0.00 : item.minimum_bid_platform_push_ad_cpm).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                                                            </>
                                                                        }
                                                                        <td className="text-center">
                                                                            {
                                                                                accountsSupernova.updating ? 
                                                                                <em> - Saving...</em>
                                                                                :   selectedCountryCode == item.countryCode &&
                                                                                    selectedAccountId == accountId &&
                                                                                    selectedSetting == "minimum_bid_push_ad_cpm" ?  
                                                                                        <span>
                                                                                            <button className="btn btn-primary ml-2" onClick={(e) => handleSaveChanges(e)}>Save</button>
                                                                                            <button className="btn btn-secondary ml-2 mr-2 btn-sm" onClick={(e) => discardChanges()}>Discard</button>
                                                                                        </span>
                                                                                    :
                                                                                    <span>
                                                                                        <OverlayTrigger placement="left" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                                                            <button type="button" className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${hasSelectedRow() ? "disabled" : ""}`} onClick={() => {
                                                                                                setSelectedCountryCode(item.countryCode);
                                                                                                setSelectedAccountId(accountId);
                                                                                                setSelectedSetting("minimum_bid_push_ad_cpm");
                                                                                                getUserType() == "direct-client" ?
                                                                                                    setNewSettings({
                                                                                                        minimum_bid_client_push_ad_cpm     : item.minimum_bid_client_push_ad_cpm,
                                                                                                    })
                                                                                                :
                                                                                                    setNewSettings({
                                                                                                        minimum_bid_platform_push_ad_cpm     : item.minimum_bid_platform_push_ad_cpm,
                                                                                                    })
                                                                                            }}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                                                        </OverlayTrigger>
                                                                                    </span>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    {/*********************************************************************************
                                                                     * 8. PUSH AD MINIMUM BID CPC
                                                                     *********************************************************************************/}
                                                                    <tr className={`fields ${user.showBidControl ? "" : "d-none"}`}>
                                                                        <td>Push Ad Minimum Bid CPC</td>
                                                                        {
                                                                            selectedCountryCode == item.countryCode &&
                                                                            selectedAccountId == accountId &&
                                                                            selectedSetting == "minimum_bid_push_ad_cpc" ?
                                                                            <>
                                                                                <td><input type="number" defaultValue={getUserType() == "direct-client" ?  item.minimum_bid_client_push_ad_cpc : item.minimum_bid_platform_push_ad_cpc} value={getUserType() == "direct-client" ? newSettings.minimum_bid_client_push_ad_cpc === undefined ? 0 : newSettings.minimum_bid_client_push_ad_cpc : newSettings.minimum_bid_platform_push_ad_cpc === undefined ? 0 : newSettings.minimum_bid_platform_push_ad_cpc} name={getUserType() == "direct-client" ? "minimum_bid_client_push_ad_cpc" : "minimum_bid_platform_push_ad_cpc"}  onChange={(e) => handleChangeInputText(e)}></input></td>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <td>${parseFloat(getUserType() == "direct-client" ? item.minimum_bid_client_push_ad_cpc : item.minimum_bid_platform_push_ad_cpc === undefined ? 0.00 : item.minimum_bid_platform_push_ad_cpc).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                                                            </>
                                                                        }
                                                                        <td className="text-center">
                                                                            {
                                                                                accountsSupernova.updating ? 
                                                                                <em> - Saving...</em>
                                                                                :   selectedCountryCode == item.countryCode &&
                                                                                    selectedAccountId == accountId &&
                                                                                    selectedSetting == "minimum_bid_push_ad_cpc" ?  
                                                                                        <span>
                                                                                            <button className="btn btn-primary ml-2" onClick={(e) => handleSaveChanges(e)}>Save</button>
                                                                                            <button className="btn btn-secondary ml-2 mr-2 btn-sm" onClick={(e) => discardChanges()}>Discard</button>
                                                                                        </span>
                                                                                    :
                                                                                    <span>
                                                                                        <OverlayTrigger placement="left" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                                                            <button type="button" className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${hasSelectedRow() ? "disabled" : ""}`} onClick={() => {
                                                                                                setSelectedCountryCode(item.countryCode);
                                                                                                setSelectedAccountId(accountId);
                                                                                                setSelectedSetting("minimum_bid_push_ad_cpc");
                                                                                                getUserType() == "direct-client" ?
                                                                                                    setNewSettings({
                                                                                                        minimum_bid_client_push_ad_cpc     : item.minimum_bid_client_push_ad_cpc,
                                                                                                    })
                                                                                                :
                                                                                                    setNewSettings({
                                                                                                        minimum_bid_platform_push_ad_cpc     : item.minimum_bid_platform_push_ad_cpc,
                                                                                                    })
                                                                                            }}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                                                        </OverlayTrigger>
                                                                                    </span>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </React.Fragment>
                                            )
                                        }
                                        </tbody>
                                    </table>
                            :
                            <>
                                <i className="fa fa-spinner fa-spin fast-spin"></i> Loading...
                            </>
                        }
                    </div>
                </div>


                <Footer />
            </div>
        );
    }
    catch(e){
        // window.location.reload(false);
        console.log(e)
    }
}

const mapState = state => ({
    user                : state.users.current,
    users               : state.users,
    accountsSupernova    : state.accountsSupernova
    

    // const { users, authentication } = state;
    // const { user } = authentication;
    // return { user, users };
})

const actionCreators = {
    getASByMultipleId       : accountSupernovaActions.getASByMultipleId,
    updateASById            : accountSupernovaActions.updateASById,
    getAll                  : userActions.getAll,
    getByAccountId          : userActions.getByAccountId,
}

const connectedMarginControl = connect(mapState, actionCreators)(MarginControl);
export { connectedMarginControl as MarginControl };