import { accountConstants } from '../_constants';

export function accounts(state = {}, action) {
  switch (action.type) {
    //=====================================
    // GET
    //=====================================
    case accountConstants.GET_REQUEST:
      return {
        loading: true
      };
    case accountConstants.GET_SUCCESS:
      return {
        items: action.accounts
      };
    case accountConstants.GET_FAILURE:
      return { 
        error: action.error
      };
      //=====================================
      // DEFAULT
      //=====================================
    default:
      return state
  }
}