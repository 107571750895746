import { combineReducers }      from 'redux';

import { authentication }       from './authentication.reducer';
import { registration }         from './registration.reducer';
import { users }                from './users.reducer';
import { alert }                from './alert.reducer';
import { sideDrawer }           from './sideDrawer.reducer';
import { overlay }              from './overlay.reducer';
import { progress }             from './progress.reducer';
import { campaigns }            from './campaigns.reducer';
import { adgroups }             from './adgroups.reducer';
import { accounts }             from './accounts.reducer';
import { adgroupCreatives }     from './adgroupCreatives.reducer';
import { statistics }           from './statistics.reducer';
import { creatives }            from './creatives.reducer';
import { publishers }           from './publishers.reducer';
import { publishergroups }      from './publishergroups.reducer';
import { geolocations }         from './geolocations.reducer';
import { pixels }               from './pixels.reducer';
import { audiences }            from './audiences.reducer';
import { marketResearch }       from './marketResearch.reducer';
import { bluekai }              from './bluekai.reducer';
import { dmp }                  from './dmp.reducer';
import { creativesPending }     from './creativesPending.reducer';
import { accountsSupernova }    from './accountsSupernova.reducer';
import { supernovaCredits }     from './supernovaCredits.reducer';
import { basisDSP }             from './basisDSP.reducer';
import { deactivatedAdgroups }  from './deactivatedAdgroups.reducer';
import { conversions }          from './conversion.reducer';
import { measurement }          from './measurement.reducer';
import { ads }                  from './ads.reducer';
import { tagSupernova }         from './tagSupernova.reducer';
import { logSupernova }         from './logSupernova.reducer';
import { marketingFunnel }      from './marketingFunnel.reducer';
import { brands }               from './brands.reducer';
import { brandRefSupernova }    from './brandRefSupernova.reducer';
import { ipc }                  from './ipc.reducer';
import { duplicates }           from './duplicates.reducer';
import { ai }                   from './ai.reducer';
import { query }                from './query.reducer';

const rootReducer = combineReducers({
  authentication,
  registration,
  users,
  alert,
  sideDrawer,
  overlay,
  progress,
  campaigns,
  adgroups,
  accounts,
  adgroupCreatives,
  statistics,
  creatives,
  publishers,
  publishergroups,
  geolocations,
  pixels,
  audiences,
  marketResearch,
  bluekai,
  dmp,
  creativesPending,
  accountsSupernova,
  supernovaCredits,
  basisDSP,
  deactivatedAdgroups,
  measurement,
  ads,
  tagSupernova,
  logSupernova,
  marketingFunnel,
  brands,
  brandRefSupernova,
  ipc,
  duplicates,
  ai,
  query
});

export default rootReducer;