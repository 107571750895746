import '../../_helpers/sort-by.js'
export const listCampaignGoal = [
    
    // {value:'TIME_ON_SITE',          label:'Time on Site - Seconds',         symbol:'s',     usedFor:["VIDEO", "NATIVE", "DISPLAY"]},
    // {value:'MAX_BOUNCE_RATE',       label:'Max Bounce Rate',                symbol:'%',     usedFor:["VIDEO", "NATIVE", "DISPLAY"]},
    // {value:'PAGES_PER_SESSION',     label:'Pageviews per Visit',            symbol:'',      usedFor:["VIDEO", "NATIVE", "DISPLAY"]},
    // {value:'NEW_UNIQUE_VISITORS',   label:'New Users',                      symbol:'%',     usedFor:["VIDEO", "NATIVE", "DISPLAY"]},
    // {value:'CPV',                   label:'Cost per Visit',                 symbol:'$',     usedFor:["VIDEO", "NATIVE", "DISPLAY"]},
    // {value:'CP_NEW_VISITOR',        label:'Cost per New Visitor',           symbol:'$',     usedFor:["VIDEO", "NATIVE", "DISPLAY"]},
    // {value:'CPUU',                  label:'Cost per Unique Users',          symbol:'$',     usedFor:["VIDEO", "NATIVE", "DISPLAY"]},
    {value:'CPA',                   label:'CPA',        symbol:'$',     usedFor:["VIDEO", "NATIVE", "DISPLAY"]}, //Exclude temporarily because it requieres converstionGoal
    {value:'CPC',                   label:'CPC',        symbol:'$',     usedFor:["VIDEO", "NATIVE", "DISPLAY"]},
    {value:'CTR',                   label:'CTR',        symbol:'%',     usedFor:["VIDEO", "NATIVE", "DISPLAY"]},
    
    //For Videos Only
    // {value:'CP_NON_BOUNCED_VISIT',  label:'Cost per Non-Bounced Visit',     symbol:'$',     usedFor:["VIDEO"]},
    // {value:'CP_PAGE_VIEW',          label:'Cost per PageView',              symbol:'$',     usedFor:["VIDEO"]},
    {value:'CPCV',                  label:'Cost per Completed Video View',  symbol:'$',     usedFor:["VIDEO"]},

].sortBy('label')