import { supernovaCreditsConstants } from '../_constants';

export function supernovaCredits(state = {}, action) {
  switch (action.type) {
    //=====================================
    // GET
    //=====================================
    case supernovaCreditsConstants.GET_REQUEST:
      return {
        loading: true,
      };
    case supernovaCreditsConstants.GET_SUCCESS:
      return {
        items: action.supernovaCredits,
        loading:false
      };
    case supernovaCreditsConstants.GET_FAILURE:
      return { 
        error: action.error,
        loading:false
      };
    //=====================================
    // UPDATE
    //=====================================
    case supernovaCreditsConstants.UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      };
    case supernovaCreditsConstants.UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        items: action.result,
      };
    case supernovaCreditsConstants.UPDATE_FAILURE:
      return { 
        ...state,
        error: action.error,
        updating: false,
      };
    //=====================================
    // DEFAULT
    //=====================================
    default:
      return state
  }
}