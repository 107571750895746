import { progressConstants } from '../_constants';
import { history } from '../_helpers';

export const progressActions = {
    update,
    clear
};

function update(completed, total) {
    return { type: progressConstants.UPDATE, status:((completed / total) * 100) };
}
function clear() {
    return { type: progressConstants.UPDATE, status:0 };
}