import React, { useState, useEffect, Component, useDebugValue } from 'react'
import {BrowserRouter as Router, Link } from "react-router-dom";

//Redux
import { connect } from 'react-redux';
import { userActions } from '../_actions';

//React Bootstrap
import { Dropdown } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

//Components
import {LeftNavigation} from '../_components/LeftNavigation'
import {Footer} from '../_components/Footer';

//Tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

//Select
import { styleSelectMultiple } from '../_constants/settings';

//React Select
import Select from 'react-select';

//Toggle
import Toggle from 'react-toggle'
import '../_stylesheets/css/react-toggle.css'

const AdminUserManagement = (props) => {

    const { user, users} = props;

    const useStateWithLabel = (initialValue, label) => {
        const [value, setValue] = useState(initialValue);
        useDebugValue(`${label}: ${value}`);
        return [value, setValue];
    };

    //Selected Entity
    const [selectedUserId, setSelectedUserId] = useStateWithLabel(null, "selectedUserId");
    const [selectedUser, setSelectedUser] = useStateWithLabel(null, "selectedUser");
    const [isChangePasswordVisible, setIsChangePasswordVisible] = useStateWithLabel(false, "isChangePasswordVisible");

    //For adding new user
    const [isAddFormVisible, setIsAddFormVisible] = useStateWithLabel(false, "isAddFormVisible");
    const [newUser, setNewUser] = useStateWithLabel({
        username:"",
        password:"",
        role:"client",
        companyName:"",
        parentAccountId:user.accountId,
        companyWebsite:"",
        accountId:"",
        firstName:"",
        lastName:"",
        position:"",
        hasIF:true,
        status:"approved",
        showBidControl:false,
    }, "newUser");

    const selectUser = (userId) => {
        setSelectedUserId(userId)
        setSelectedUser(users.items && users.items.filter(item => item.id == userId))
    }

    const acceptRegistration = (userId) => {
        let isExecuted = confirm(`Are you sure you want to approve this user?`);
        if(isExecuted){
            props.approveUser(userId)
        }
    }
    

    useEffect(() => {
        if(user.role == "admin"){
            props.getByAccountId(user.accountId); //For Client Admin
        }
        else{
            props.getAll(); //For Platform admin
        }
    },[]);

    const handleUserRoleChange = (dropdown, purpose) => {
        if(purpose == "edit"){
            setSelectedUser({
                ...selectedUser,
                ["role"]: dropdown.value,
                ["showBidControl"]: dropdown.value == "client" ? false : newUser.showBidControl
            })
        }

        if(purpose == "add"){
            setNewUser({
                ...newUser,
                ["role"]: dropdown.value,
                ["showBidControl"]: dropdown.value == "client" ? false : newUser.showBidControl
            })
        }

    }

    const handleDeleteUser = (user) => {
        if(confirm(`You are about to delete ${user.firstName} ${user.lastName}. Are you sure?`) == true){
            props.deleteUser(user);
        }
    }

    const handleChangeInputText = (event, purpose) => {
        let { name, value } = event.target;
        if(event.target.type == "number"){
            // Allow only 2 decimal place
            let t = value;
            value = (t.indexOf(".") >= 0) ? parseFloat(t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : parseFloat(t);
        }

        if(purpose == "edit"){
            setSelectedUser({
                ...selectedUser,
                [name]: value
            })
        }

        if(purpose == "add"){
            setNewUser({
                ...newUser,
                [name]: value
            })
        }

    }

    const discardPasswordChange = (user) => {
        delete selectedUser.password
        setIsChangePasswordVisible(false)
    }

    const handleApproveUser = (user) => {
        return (e) => {
            props.approveUser(user)
        };
    }

    const resetAddNewUserForm = () => {
        setNewUser({
            username:"",
            password:"",
            role:"client",
            companyName:"",
            parentAccountId:user.accountId,
            companyWebsite:"",
            accountId:"",
            firstName:"",
            lastName:"",
            position:"",
            hasIF:true,
            status:"approved",
        })
        setIsAddFormVisible(false);
    }

    const handleAddNewUser = () => {
        let isValid = true;
        if(newUser.username == ""){
            alert("Username is required");
            isValid = false;
            return;
        }
        if(newUser.password == ""){
            alert("Password is required");
            isValid = false;
            return;
        }
        if(newUser.firstName == ""){
            alert("Firstname is required");
            isValid = false;
            return;
        }
        if(isValid){
            props.addUser(newUser, user);
            resetAddNewUserForm()
        }
    }

    const handleSaveChanges = (user) => {
        if(selectedUser.accountId !== undefined){user.accountId= selectedUser.accountId;}
        if(selectedUser.firstName !== undefined){user.firstName= selectedUser.firstName;}
        if(selectedUser.lastName !== undefined){user.lastName= selectedUser.lastName;}
        if(selectedUser.companyName !== undefined){user.companyName= selectedUser.companyName;}
        if(selectedUser.companyWebsite !== undefined){user.companyWebsite= selectedUser.companyWebsite;}
        if(selectedUser.position !== undefined){user.position= selectedUser.position;}
        if(selectedUser.password !== undefined){user.password= selectedUser.password;}
        if(selectedUser.role !== undefined){user.role= selectedUser.role;}

        props.updateUser(user);
        setSelectedUserId(null);
        setSelectedUser(null);
    }

    const toggleShowBidControl = (e) => {
        const selectedUserId    = e.target.getAttribute('data-uid')
        const selectedUsername  = e.target.getAttribute('data-username')
        const value             = e.target.checked
        const purpose           = e.target.getAttribute('data-purpose')
        if(purpose == "add"){
            setNewUser({
                ...newUser,
                ["showBidControl"]: e.target.checked
            })
        }
        if(purpose == "edit"){
            props.updateUser({
                id:selectedUserId,
                showBidControl:value,
                username:selectedUsername
            });
        }
        e.preventDefault();
    }


    try{
        return (
            <div className='admin'>

                {/* SUB MENU */}
                <LeftNavigation role={user.role}/>

                <div className='canvas'>
                <div className="admin custom-panel table">
                        <div className="header">
                            User List Management
                            <div className="subtitle">
                                {/*  */}
                            </div>
                            <div className="controls pr-3">
                                <button type="button" className={`${(selectedUser && selectedUser.approving == true) || selectedUser || isAddFormVisible ? "disabled" : ""} btn btn-primary btn-xs btn-pagination`} onClick={() => setIsAddFormVisible(true)}><i className="fa fa-plus-circle" aria-hidden="true"></i> Add New User</button>
                            </div>
                        </div>
                        <table className="user-list-management primary fixed-header has-hover">
                            <thead>
                                <tr>
                                    <th className='text-left'>Username</th>
                                    <th className='text-left'>Password</th>
                                    <th className='text-left'>Company</th>
                                    <th className='text-left'></th>
                                    <th className='text-left'>Website</th>
                                    <th className='text-left'>Role</th>
                                    <th className='text-left'>Account Id</th>
                                    <th className='text-left'>First Name</th>
                                    <th className='text-left'>Last Name</th>
                                    <th className='text-left'>Position</th>
                                    <th className="text-center">Status</th>
                                    {
                                        props.user.role == "platform_admin" && 
                                        <th className="text-center">Bid Control</th>
                                    }
                                    <th className="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.items && users.items.filter(item => item.username !== user.username).map((user, index) =>
                                    <tr key={user.id}>
                                        <td>
                                            <OverlayTrigger placement="top" overlay={<Tooltip><b>{user.username}</b></Tooltip>}>
                                                <span>{user.username}</span>
                                            </OverlayTrigger>
                                        </td>
                                        <td>
                                            {
                                                user.id == selectedUserId ? 
                                                    isChangePasswordVisible ?
                                                        <>
                                                            <input type="password" placeholder="Set new password" defaultValue={user.password} value={selectedUser.password} name="password" onChange={(e) => handleChangeInputText(e, "edit")}></input>
                                                            <OverlayTrigger placement="top" overlay={<Tooltip><b>Undo changes</b></Tooltip>}>
                                                                <button type="button" className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${isAddFormVisible ? "disabled": ""}`} onClick={() => discardPasswordChange()}><i className="fa fa-undo" aria-hidden="true"></i></button>
                                                            </OverlayTrigger>
                                                        </>
                                                    :
                                                    <>
                                                        <OverlayTrigger placement="top" overlay={<Tooltip><b>Set new password</b></Tooltip>}>
                                                            <button type="button" className={`btn btn-primary btn-xs btn-approve btn-pagination mr-3 mt-0`} onClick={() => setIsChangePasswordVisible(true)}>Change Password</button>
                                                        </OverlayTrigger>
                                                    </>

                                                :
                                                <span>
                                                    *************
                                                </span>
                                            }
                                        </td>
                                        <td>
                                            {
                                                user.id == selectedUserId ? 
                                                <input type="text" defaultValue={user.companyName} value={selectedUser.companyName} name="companyName" onChange={(e) => handleChangeInputText(e, "edit")}></input>
                                                :
                                                <OverlayTrigger placement="top" overlay={<Tooltip><b>{user.companyName}</b></Tooltip>}>
                                                    <span>
                                                        {user.companyName}
                                                    </span>
                                                </OverlayTrigger>
                                            }
                                        </td>
                                        <td>
                                            {<img className="company-icon" src={`http://${user.companyWebsite}/favicon.ico`} alt=""/>}
                                        </td>
                                        <td>
                                            {
                                                user.id == selectedUserId ? 
                                                <input type="text" defaultValue={user.companyWebsite} value={selectedUser.companyWebsite} name="companyWebsite" onChange={(e) => handleChangeInputText(e, "edit")}></input>
                                                :
                                                <OverlayTrigger placement="top" overlay={<Tooltip><b>{user.companyWebsite}</b></Tooltip>}>
                                                    <span>{user.companyWebsite}</span>
                                                </OverlayTrigger>
                                            }
                                        </td>
                                        <td className={`text-capitalize`}>
                                                {
                                                    user.id == selectedUserId ? 
                                                        <Select
                                                            defaultValue={{
                                                                label:user.role,
                                                                value:user.role
                                                            }}
                                                            className={`select-primary`}
                                                            onChange={(e) => handleUserRoleChange(e, "edit")}
                                                            menuPlacement="top"
                                                            searchable={false}
                                                            options={[
                                                                {
                                                                    label:"client",
                                                                    value:"client",
                                                                },
                                                                {
                                                                    label:"admin",
                                                                    value:"admin",
                                                                }
                                                            ]}
                                                            styles={styleSelectMultiple} />
                                                    :
                                                    <div className={`badge ${user.role}`}>{user.role}</div>
                                                }
                                        </td>
                                        <td>
                                            {
                                                (user.id == selectedUserId && users.current.role == "platform_admin") ? 
                                                <input type="text" autoFocus={true} defaultValue={user.accountId} value={selectedUser.accountId} name="accountId" onChange={(e) => handleChangeInputText(e, "edit")}></input>
                                                :
                                                <span>{Object.hasOwn(user, "accountId") ? user.accountId : "-"}</span>
                                            }
                                        </td>
                                        <td>
                                            {
                                                user.id == selectedUserId ? 
                                                <input type="text" autoFocus={true} defaultValue={user.firstName} value={selectedUser.firstName} name="firstName" onChange={(e) => handleChangeInputText(e, "edit")}></input>
                                                :
                                                <OverlayTrigger placement="top" overlay={<Tooltip><b>{user.firstName}</b></Tooltip>}>
                                                    <span>{user.firstName}</span>
                                                </OverlayTrigger>
                                            }
                                        </td>
                                        <td>
                                            {
                                                user.id == selectedUserId ? 
                                                <input type="text" defaultValue={user.lastName} value={selectedUser.lastName} name="lastName" onChange={(e) => handleChangeInputText(e, "edit")}></input>
                                                :
                                                <OverlayTrigger placement="top" overlay={<Tooltip><b>{user.lastName}</b></Tooltip>}>
                                                    <span>{user.lastName}</span>
                                                </OverlayTrigger>
                                            }
                                        </td>
                                        <td>
                                            {
                                                user.id == selectedUserId ? 
                                                <input type="text" defaultValue={user.position} value={selectedUser.position} name="position" onChange={(e) => handleChangeInputText(e, "edit")}></input>
                                                :
                                                <OverlayTrigger placement="top" overlay={<Tooltip><b>{user.position}</b></Tooltip>}>
                                                    <span>{user.position}</span>
                                                </OverlayTrigger>
                                            }
                                        </td>
                                        <td className="text-center text-capitalize">
                                            {
                                                user.status == "approved" ?
                                                <div className={`badge ${user.status}`}>{user.status}</div>
                                                :
                                                <OverlayTrigger placement="top" overlay={<Tooltip><b>Accept Registration</b></Tooltip>}>
                                                    <button type="button" className={`${(selectedUser && selectedUser.approving == true) || selectedUser || isAddFormVisible ? "disabled" : ""} btn btn-primary btn-xs btn-approve btn-pagination mr-3`} onClick={() => acceptRegistration(user.id)}>Accept</button>
                                                </OverlayTrigger>
                                            }
                                        </td>
                                        {
                                            props.user.role == "platform_admin" && 
                                            <td className="text-center">
                                            {
                                                <Toggle className={user.role == "client" && "disabled"}  data-purpose="edit" data-username={user.username} data-uid={user.id} checked={user.showBidControl} defaultChecked={user.showBidControl} onChange={(e) => toggleShowBidControl(e)}/> 
                                            }
                                            </td>
                                        }
                                        <td className="text-center">
                                        {
                                            user.deleting ? <em> - Deleting...</em>
                                            : user.updating ? <em> - Saving...</em>
                                            // : user.approving ? <em> - Approving...</em>
                                            : user.deleteError ? <span className="text-danger"> - ERROR: {user.deleteError}</span>
                                            : user.id == selectedUserId ?  
                                            <span>
                                                <button className="btn btn-primary ml-2" onClick={(e) => handleSaveChanges(user)}>Save</button>
                                                <button className="btn btn-secondary ml-2 mr-2 btn-sm" onClick={(e) => {setSelectedUserId(null); setSelectedUser(null); setIsChangePasswordVisible(false);}}>Discard</button>
                                            </span>
                                            :
                                            <span>
                                                <OverlayTrigger placement="top" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                    <button type="button" className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${isAddFormVisible || selectedUser ? "disabled": ""}`} onClick={() => selectUser(user.id)}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                </OverlayTrigger>
                                                <OverlayTrigger placement="top" overlay={<Tooltip><b>Delete</b></Tooltip>}>
                                                    <button type="button" className={`btn btn-primary btn-xs btn-actions btn-pagination mr-3 ${isAddFormVisible || selectedUser ? "disabled": ""}`} onClick={() => handleDeleteUser(user)}><i className="fa fa-times" aria-hidden="true"></i></button>
                                                </OverlayTrigger>
                                            </span>
                                        }
                                        </td>
                                    </tr>
                                    )}
                                    {
                                        isAddFormVisible &&
                                        <tr>
                                            <td><input type="text" placeholder='Username' value={newUser.username} name="username" onChange={(e) => handleChangeInputText(e, "add")}></input></td>
                                            <td><input type="password" placeholder='Password' value={newUser.password} name="password" onChange={(e) => handleChangeInputText(e, "add")}></input></td>
                                            <td><input type="text" placeholder='Company Name' value={newUser.companyName} name="companyName" onChange={(e) => handleChangeInputText(e, "add")}></input></td>
                                            <td></td>
                                            <td><input type="text" placeholder='Company Website' value={newUser.companyWebsite} name="companyWebsite" onChange={(e) => handleChangeInputText(e, "add")}></input></td>
                                            <td>
                                            <Select
                                                defaultValue={{
                                                    label:"client",
                                                    value:"client"
                                                }}
                                                className={`select-primary`}
                                                onChange={(e) => handleUserRoleChange(e, "add")}
                                                menuPlacement="top"
                                                searchable={false}
                                                options={[
                                                    {
                                                        label:"client",
                                                        value:"client",
                                                    },
                                                    {
                                                        label:"admin",
                                                        value:"admin",
                                                    }
                                                ]}
                                                styles={styleSelectMultiple} />
                                            </td>
                                            <td><input type="text" placeholder='Account ID' value={newUser.accountId} name="accountId" onChange={(e) => handleChangeInputText(e, "add")}></input></td>
                                            <td><input type="text" placeholder='First Name' value={newUser.firstName} name="firstName" onChange={(e) => handleChangeInputText(e, "add")}></input></td>
                                            <td><input type="text" placeholder='Last Name' value={newUser.lastName} name="lastName" onChange={(e) => handleChangeInputText(e, "add")}></input></td>
                                            <td><input type="text" placeholder='Company Position' value={newUser.position} name="position" onChange={(e) => handleChangeInputText(e, "add")}></input></td>
                                            <td></td>
                                            {
                                                props.user.role == "platform_admin" && 
                                                <td className='text-center'>
                                                    <Toggle className={newUser.role == "client" && "disabled"} data-purpose="add" data-uid={user.id} checked={newUser.showBidControl} defaultChecked={newUser.role == "client" ? false : newUser.showBidControl} onChange={(e) => toggleShowBidControl(e)}/> 
                                                </td>
                                            }
                                            <td>
                                                <span>
                                                    <button className="btn btn-primary ml-2" onClick={(e) => handleAddNewUser()}>Save</button>
                                                    <button className="btn btn-secondary ml-2 mr-2 btn-sm" onClick={(e) => {resetAddNewUserForm()}}>Discard</button>
                                                </span>
                                            </td>
                                        </tr>
                                    }
                            </tbody>
                        </table>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
    catch(e){
        console.log(e)
        // window.location.reload(false);
    }
}

const mapState = state => ({
    user    : state.users.current,
    users   : state.users
    

    // const { users, authentication } = state;
    // const { user } = authentication;
    // return { user, users };
})

const actionCreators = {
    getAll          : userActions.getAll,
    getByAccountId  : userActions.getByAccountId,
    deleteUser      : userActions.delete,
    showUpdateForm  : userActions.showUpdateForm,
    hideUpdateForm  : userActions.hideUpdateForm,
    updateUser      : userActions.update,
    approveUser     : userActions.approve,
    addUser         : userActions.addUser
}

const connectedAdminUserManagement = connect(mapState, actionCreators)(AdminUserManagement);
export { connectedAdminUserManagement as AdminUserManagement };