import config from 'config';
import { authHeader } from '../_helpers';

export const publisherGroupService = {
    getPublisherGroups,
    deletePublisherGroup,
    updatePublisherGroup,
    createPublisherGroup,
};


function getPublisherGroups(accountId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/publishergroups/${accountId}`, requestOptions).then(handleResponse);
}

function deletePublisherGroup(publisherGroupId, accountId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/publishergroups/${publisherGroupId}/${accountId}`, requestOptions).then(handleResponse);
}

function updatePublisherGroup(data) {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/publishergroups/${data.id}/${data.accountId}`, requestOptions).then(handleResponse);
}

function createPublisherGroup(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/publishergroups/${data.accountId}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}