import { dmpConstants } from '../_constants';
import { dmpService } from '../_services';
import { alertActions, progressActions } from './';
import { history } from '../_helpers';

export const dmpActions = {
    getDMP,
};
function getDMP() {
    return dispatch => {
        dispatch(request());
        dmpService.getDMP()
            .then(
                dmp => {
                    dispatch(success(dmp));
                },
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: dmpConstants.GET_REQUEST } }
    function success(dmp) { return { type: dmpConstants.GET_SUCCESS, dmp } }
    function failure(error) { return { type: dmpConstants.GET_FAILURE, error } }
}

