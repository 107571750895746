import { alertConstants } from '../_constants';

export function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: 'alert-success',
        title: 'Success',
        fa_icon: 'fa-check',
        message: action.message
      };
    case alertConstants.ERROR:
      return {
        type: 'alert-danger',
        title: 'Error',
        fa_icon: 'fa-exclamation-circle',
        message: action.message
      };
    case alertConstants.LOADING:
      return {
        type: 'alert-loading',
        title: '',
        fa_icon: 'fa-cog fa-spin',
        message: action.message
      };
    case alertConstants.CLEAR:
      return {};
    default:
      return state
  }
}