import { marketingFunnelConstants } from '../_constants';
import { 
    marketingFunnelService,
 } from '../_services';
import { alertActions, progressActions } from './';
import { history } from '../_helpers';

export const marketingFunnelActions = {
    getMarketingFunnel,
    createMarketingFunnel,
    updateMarketingFunnel,
};



function getMarketingFunnel(adgroupId) {
    return dispatch => {
        dispatch(request());

        marketingFunnelService.getMarketingFunnel(adgroupId)
            .then(
                marketingFunnel => {
                    dispatch(success(marketingFunnel))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: marketingFunnelConstants.GET_REQUEST } }
    function success(marketingFunnel) { return { type: marketingFunnelConstants.GET_SUCCESS, marketingFunnel } }
    function failure(error) { return { type: marketingFunnelConstants.GET_FAILURE, error } }
}


function createMarketingFunnel(marketingFunnel, adGroupId) {
    return dispatch => {
        const data = {
            adgroup_id: adGroupId,
            marketing_funnel: marketingFunnel
        }
        console.log("xxxxxxxxxxxxxxxxxxxxxxxxx")
        console.log(data)
        console.log("xxxxxxxxxxxxxxxxxxxxxxxxx")
        dispatch(request(marketingFunnel));
        console.log("Adding marketingFunnel");
        marketingFunnelService.createMarketingFunnel(data)
            .then(
                marketingFunnel => {
                    dispatch(success(marketingFunnel))
                    console.log("DONE: Adding marketingFunnel");
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request(marketingFunnel) { return { type: marketingFunnelConstants.CREATE_REQUEST, marketingFunnel } }
    function success(marketingFunnel) { return { type: marketingFunnelConstants.CREATE_SUCCESS, marketingFunnel } }
    function failure(error) { return { type: campaignConstants.CREATE_FAILURE, error } }
}



function updateMarketingFunnel(marketingFunnel, adGroupId) {
    return dispatch => {
        dispatch(request(marketingFunnel));
        console.log("Updating marketingFunnel: ");

        // 1. Update MarketingFunnel Details
        marketingFunnelService.updateMarketingFunnel({"marketing_funnel":marketingFunnel}, adGroupId)
            .then(
                marketingFunnel => {
                    dispatch(success(marketingFunnel))
                    console.log("DONE: Updating marketingFunnel");
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request(marketingFunnel) { return { type: marketingFunnelConstants.UPDATE_REQUEST, marketingFunnel } }
    function success(marketingFunnel) { return { type: marketingFunnelConstants.UPDATE_SUCCESS, marketingFunnel } }
    function failure(id, error) { return { type: marketingFunnelConstants.UPDATE_FAILURE, id, error } }
}

