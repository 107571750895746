import { deactivatedAdgroupConstants } from '../_constants';

export function deactivatedAdgroups(state = {}, action) {
  switch (action.type) {
    //=====================================
    // GET
    //=====================================
    case deactivatedAdgroupConstants.GET_DEACTIVATED_ADGROUPS_REQUEST:
      return {
        ...state,
        isDone: false
      };
    case deactivatedAdgroupConstants.GET_DEACTIVATED_ADGROUPS_SUCCESS:
      // console.log("services")
      // console.log(action.deactivatedAdgroups)
      return {
        ...state,
        items: action.deactivatedAdgroups,
        isDone: true
      };
    case deactivatedAdgroupConstants.GET_DEACTIVATED_ADGROUPS_FAILURE:
      return { 
        ...state,
        error: action.error,
        isDone: true
      };
      //=====================================
      // DEFAULT
      //=====================================
    default:
      return state
  }
}