export const marketingFunnelConstants = {
    CREATE_REQUEST: 'MARKETING_FUNNEL_CREATE_REQUEST',
    CREATE_SUCCESS: 'MARKETING_FUNNEL_CREATE_SUCCESS',
    CREATE_FAILURE: 'MARKETING_FUNNEL_CREATE_FAILURE',

    GET_REQUEST: 'MARKETING_FUNNEL_GET_REQUEST',
    GET_SUCCESS: 'MARKETING_FUNNEL_GET_SUCCESS',
    GET_FAILURE: 'MARKETING_FUNNEL_GET_FAILURE',

    UPDATE_REQUEST: 'MARKETING_FUNNEL_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'MARKETING_FUNNEL_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'MARKETING_FUNNEL_UPDATE_FAILURE',
};
