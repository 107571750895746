import { publisherConstants } from '../_constants';
import { publisherService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const publisherActions = {
    getPublishersInAccount,
    getPublishersInCampaign,
    getPublishersInAdgroup,

    getTotalPublishersPerCampaign,
    getTotalPublishersPerAdgroup,
    getTotalPublishersPerCreative
};


function getTotalPublishersPerCampaign(campaignId, startDate, endDate) {
    return dispatch => {
        dispatch(request());

        publisherService.getTotalPublishersPerCampaign(campaignId, startDate, endDate)
            .then(
                publishers => dispatch(success(publishers)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: publisherConstants.GET_TOTAL_PER_CAMPAIGN_REQUEST } }
    function success(publishers) { return { type: publisherConstants.GET_TOTAL_PER_CAMPAIGN_SUCCESS, publishers } }
    function failure(error) { return { type: publisherConstants.GET_TOTAL_PER_CAMPAIGN_FAILURE, error } }
}

function getTotalPublishersPerAdgroup(adgroupId, startDate, endDate) {
    return dispatch => {
        dispatch(request());

        publisherService.getTotalPublishersPerAdgroup(adgroupId, startDate, endDate)
            .then(
                publishers => dispatch(success(publishers)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: publisherConstants.GET_TOTAL_PER_ADGROUP_REQUEST } }
    function success(publishers) { return { type: publisherConstants.GET_TOTAL_PER_ADGROUP_SUCCESS, publishers } }
    function failure(error) { return { type: publisherConstants.GET_TOTAL_PER_ADGROUP_FAILURE, error } }
}

function getTotalPublishersPerCreative(startDate, endDate) {
    return dispatch => {
        dispatch(request());

        publisherService.getTotalPublishersPerCreative(startDate, endDate)
            .then(
                publishers => dispatch(success(publishers)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: publisherConstants.GET_TOTAL_PER_CREATIVE_REQUEST } }
    function success(publishers) { return { type: publisherConstants.GET_TOTAL_PER_CREATIVE_SUCCESS, publishers } }
    function failure(error) { return { type: publisherConstants.GET_TOTAL_PER_CREATIVE_FAILURE, error } }
}

function getPublishersInAccount(accountId, startDate, endDate) {
    return dispatch => {
        dispatch(request());

        publisherService.getPublishersInAccount(accountId, startDate, endDate)
            .then(
                publishers => dispatch(success(publishers)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: publisherConstants.GET_ACCOUNT_REQUEST } }
    function success(publishers) { return { type: publisherConstants.GET_ACCOUNT_SUCCESS, publishers } }
    function failure(error) { return { type: publisherConstants.GET_ACCOUNT_FAILURE, error } }
}

function getPublishersInCampaign(accountId, campaignId, startDate, endDate) {
    return dispatch => {
        dispatch(request());

        publisherService.getPublishersInCampaign(accountId, campaignId, startDate, endDate)
            .then(
                publishers => dispatch(success(publishers)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: publisherConstants.GET_CAMPAIGN_REQUEST } }
    function success(publishers) { return { type: publisherConstants.GET_CAMPAIGN_SUCCESS, publishers } }
    function failure(error) { return { type: publisherConstants.GET_CAMPAIGN_FAILURE, error } }
}

function getPublishersInAdgroup(accountId, adgroupId, startDate, endDate) {
    return dispatch => {
        dispatch(request());

        publisherService.getPublishersInAdgroup(accountId, adgroupId, startDate, endDate)
            .then(
                publishers => dispatch(success(publishers)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: publisherConstants.GET_ADGROUP_REQUEST } }
    function success(publishers) { return { type: publisherConstants.GET_ADGROUP_SUCCESS, publishers } }
    function failure(error) { return { type: publisherConstants.GET_ADGROUP_FAILURE, error } }
}