import React from 'react'
import { connect } from 'react-redux';
import {CreativeForm}  from './forms/CreativeForm'
import {CreativeFormPush}  from './forms/CreativeFormPush'

class Overlay extends React.Component {
    constructor(props) {
        super(props);
    }
    render(){
        const { overlay, campaignType, adgroups } = this.props;
        const Content = () => {
            switch(overlay.form){
                case 'creative':    
                    return campaignType == "PUSH_NOTIFICATION" 
                    ? <CreativeFormPush adgroups={adgroups} campaignType={campaignType} selectedAdgroup={overlay.parentId.supernovaAdgroupId} selectedCreative={overlay.id}/> 
                    // : <CreativeForm campaignType={campaignType} selectedAdgroup={overlay.parentId.supernovaAdgroupId} selectedZemantaAdgroupId={overlay.parentId.zemantaId} selectedCreative={overlay.id}/>;
                    : <CreativeForm campaignType={campaignType} selectedAdgroup={overlay.parentId} selectedZemantaAdgroupId={overlay.parentId} selectedCreative={overlay.id}/>;
                default: return '';
            }
        }
        return (
                <>
                    <div className={`overlay ${overlay.isOpen ? 'open' : ''}`}>
                            <Content/>
                   </div>
                </>
            );
    }
}

const mapState = state => ({
    overlay:state.overlay,
    adgroups: state.adgroups,
})

const connectedOverlay = connect(mapState)(Overlay);
export { connectedOverlay as Overlay };
