import config from 'config';
import { authHeader } from '../_helpers';

export const adgroupCreativeService = {
    getAdgroupCreatives,
    addAdgroupCreatives,
};

function getAdgroupCreatives(accountId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/adgroupcreatives/${accountId}`, requestOptions).then(handleResponse);
}
function addAdgroupCreatives(adGroupId, creativeId, accountId) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            adGroupId   : adGroupId, 
            creativeId  : creativeId, 
            accountId   : accountId
        })
    };

    return fetch(`${config.apiURL}/adgroupcreatives/`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
