import { publisherConstants } from '../_constants';

export function publishers(state = {}, action) {
  switch (action.type) {
    // GET PUBLISHER IN ACCOUNT =========================================================================================
    case publisherConstants.GET_ACCOUNT_REQUEST:
      return {
        ...state,
        loading_account: true
      };
    case publisherConstants.GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: action.publishers,
        loading_account:false
      };
    case publisherConstants.GET_ACCOUNT_FAILURE:
      return { 
        ...state,
        error: action.error,
        loading_account:false
      };
    // GET PUBLISHER IN CAMPAIGN =========================================================================================
    case publisherConstants.GET_CAMPAIGN_REQUEST:
      return {
        ...state,
        loading_campaign:true
      };
    case publisherConstants.GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaign: action.publishers,
        loading_campaign:false
      };
    case publisherConstants.GET_CAMPAIGN_FAILURE:
      return { 
        ...state,
        error: action.error,
        loading_campaign:false
      };
    // GET PUBLISHER IN ADGROUP =========================================================================================
    case publisherConstants.GET_ADGROUP_REQUEST:
      return {
        ...state,
        loading_adgroup:true
      };
    case publisherConstants.GET_ADGROUP_SUCCESS:
      return {
        ...state,
        adgroup: action.publishers,
        loading_adgroup:false
      };
    case publisherConstants.GET_ADGROUP_FAILURE:
      return { 
        ...state,
        error: action.error,
        loading_adgroup:false
      };
    // GET TOTAL PUBLISHERS PER CAMPAIGN =========================================================================================
    case publisherConstants.GET_TOTAL_PER_CAMPAIGN_REQUEST:
      return {
        ...state,
      };
    case publisherConstants.GET_TOTAL_PER_CAMPAIGN_SUCCESS:
      if(action.publishers.length == 0){return{...state};}  //Dont include if there is no result
      return {
        ...state,
        total_by_campaign:  state.total_by_campaign ? [...state.total_by_campaign.filter(item => item._id != action.publishers[0]._id), action.publishers[0]] : [action.publishers[0]], //The filter prevents having duplicate
      };
    case publisherConstants.GET_TOTAL_PER_CAMPAIGN_FAILURE:
      return { 
        ...state,
        error: action.error,
      };
    // GET TOTAL PUBLISHERS PER ADGROUP =========================================================================================
    case publisherConstants.GET_TOTAL_PER_ADGROUP_REQUEST:
      return {
        ...state,
        isPublisherCountPerAdgroupDone:false
      };
    case publisherConstants.GET_TOTAL_PER_ADGROUP_SUCCESS:
      if(action.publishers.length == 0){return{...state};}  //Dont include if there is no result
      return {
        ...state,
        total_by_adgroup:  state.total_by_adgroup ? [...state.total_by_adgroup.filter(item => item._id != action.publishers[0]._id), action.publishers[0]] : [action.publishers[0]], //The filter prevents having duplicate
      };
    case publisherConstants.GET_TOTAL_PER_ADGROUP_FAILURE:
      return { 
        ...state,
        error: action.error,
        isPublisherCountPerAdgroupDone:true
      };
    // GET TOTAL PUBLISHERS PER CREATIVE =========================================================================================
    case publisherConstants.GET_TOTAL_PER_CREATIVE_REQUEST:
      return {
        ...state,
        isPublisherCountPerCreativeDone:false
      };
    case publisherConstants.GET_TOTAL_PER_CREATIVE_SUCCESS:
      return {
        ...state,
        total_by_creative: action.publishers,
        isPublisherCountPerCreativeDone:true
      };
    case publisherConstants.GET_TOTAL_PER_CREATIVE_FAILURE:
      return { 
        ...state,
        error: action.error,
        isPublisherCountPerCreativeDone:true
      };
      //================================================================================================================
    default:
      return state
  }
}