import { basisDSPConstants } from '../_constants';
import { basisDSPService } from '../_services';
import { alertActions, progressActions } from './';
import { history } from '../_helpers';

export const basisDSPActions = {
    getAll,
};



function getAll() {
    return dispatch => {
        dispatch(request());

        basisDSPService.getAll()
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: basisDSPConstants.GET_REQUEST } }
    function success(data) { return { type: basisDSPConstants.GET_SUCCESS, data } }
    function failure(error) { return { type: basisDSPConstants.GET_FAILURE, error } }
}

