import { pixelConstants } from '../_constants';

export function pixels(state = {}, action) {
  switch (action.type) {
    //=====================================
    // GET
    //=====================================
    case pixelConstants.GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case pixelConstants.GET_SUCCESS:
      return {
        loading: false,
        items: action.pixels
      };
    case pixelConstants.GET_FAILURE:
      return { 
        ...state,
        loading: false,
        error: action.error
      };
    //=====================================
    // UPDATE
    //=====================================
    case pixelConstants.UPDATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case pixelConstants.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.pixels
      };
    case pixelConstants.UPDATE_FAILURE:
      return { 
        ...state,
        loading: false,
        error: action.error
      };
    //=====================================
    // CREATE
    //=====================================
    case pixelConstants.CREATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case pixelConstants.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.pixels
      };
    case pixelConstants.CREATE_FAILURE:
      return { 
        ...state,
        loading: false,
        error: action.error
      };
      //=====================================
      // DEFAULT
      //=====================================
    default:
      return state
  }
}