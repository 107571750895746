import { marketingFunnelConstants } from '../_constants';

export function marketingFunnel(state = {}, action) {
  switch (action.type) {
    //=====================================
    // GET
    //=====================================
    case marketingFunnelConstants.GET_REQUEST:
      return {
        isDone: false
      };
    case marketingFunnelConstants.GET_SUCCESS:
      return {
        items: action.marketingFunnel,
        isDone: true
      };
    case marketingFunnelConstants.GET_FAILURE:
      return { 
        error: action.error,
        isDone: true
      };
      //=====================================
      // UPDATE
      //=====================================
      case marketingFunnelConstants.UPDATE_REQUEST:
        return {
          ...state,
          loading: true
        };
      case marketingFunnelConstants.UPDATE_SUCCESS:
        return {
          ...state,
          isDone: true,
          loading: false,
          items: action.marketingFunnel,
        };
      case marketingFunnelConstants.UPDATE_FAILURE:
        return {
          ...state,
          isDone: true,
          loading: false,
          error:action.error
        };
      //=====================================
      // CREATE
      //=====================================
      case marketingFunnelConstants.CREATE_REQUEST:
        return { 
          ...state,
          loading: true 
        };
      case marketingFunnelConstants.CREATE_SUCCESS:
        return {
          items: action.marketingFunnel,
          isDone: true,
          loading:false
        };
      case marketingFunnelConstants.CREATE_FAILURE:
        return {
          ...state,
          loading:false,
          isDone: true,
          error: action.error
        };
      //=====================================
      // DEFAULT
      //=====================================
    default:
      return state
  }
}