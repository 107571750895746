export const publisherGroupConstants = {
    GET_REQUEST: 'PUBLISHERGROUP_GET_REQUEST',
    GET_SUCCESS: 'PUBLISHERGROUP_GET_SUCCESS',
    GET_FAILURE: 'PUBLISHERGROUP_GET_FAILURE',

    DELETE_REQUEST: 'PUBLISHERGROUP_DELETE_REQUEST',
    DELETE_SUCCESS: 'PUBLISHERGROUP_DELETE_SUCCESS',
    DELETE_FAILURE: 'PUBLISHERGROUP_DELETE_FAILURE',

    UPDATE_REQUEST: 'PUBLISHERGROUP_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'PUBLISHERGROUP_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'PUBLISHERGROUP_UPDATE_FAILURE',
    
    CREATE_REQUEST: 'PUBLISHERGROUP_CREATE_REQUEST',
    CREATE_SUCCESS: 'PUBLISHERGROUP_CREATE_SUCCESS',
    CREATE_FAILURE: 'PUBLISHERGROUP_CREATE_FAILURE',

};
