export const creativeConstants = {
    CREATE_REQUEST: 'CREATIVE_CREATE_REQUEST',
    CREATE_SUCCESS: 'CREATIVE_CREATE_SUCCESS',
    CREATE_FAILURE: 'CREATIVE_CREATE_FAILURE',

    CREATE_PUSH_REQUEST: 'CREATIVE_CREATE_PUSH_REQUEST',
    CREATE_PUSH_SUCCESS: 'CREATIVE_CREATE_PUSH_SUCCESS',
    CREATE_PUSH_FAILURE: 'CREATIVE_CREATE_PUSH_FAILURE',

    EDIT_PUSH_REQUEST: 'CREATIVE_EDIT_PUSH_REQUEST',
    EDIT_PUSH_SUCCESS: 'CREATIVE_EDIT_PUSH_SUCCESS',
    EDIT_PUSH_FAILURE: 'CREATIVE_EDIT_PUSH_FAILURE',
    
    UPDATE_REQUEST: 'CREATIVE_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'CREATIVE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CREATIVE_UPDATE_FAILURE',

    GET_REQUEST: 'CREATIVE_GET_REQUEST',
    GET_SUCCESS: 'CREATIVE_GET_SUCCESS',
    GET_FAILURE: 'CREATIVE_GET_FAILURE',

    UPDATE_REQUEST: 'CREATIVE_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'CREATIVE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CREATIVE_UPDATE_FAILURE',
    
    UPLOAD_REQUEST: 'CREATIVE_UPLOAD_REQUEST',
    UPLOAD_SUCCESS: 'CREATIVE_UPLOAD_SUCCESS',
    UPLOAD_PROGRESS: 'CREATIVE_UPLOAD_PROGRESS',
    UPLOAD_FAILURE: 'CREATIVE_UPLOAD_FAILURE',
    UPLOAD_VIDEO_SUCCESS: 'CREATIVE_UPLOAD_VIDEO_SUCCESS',  //Video shares the same REQUEST, PROGRESS AND FAILURE AS CREATIVE. It only differs ffor SUCCESS
    
    CREATE_VIDEO_ASSET_REQUEST: 'CREATE_VIDEO_ASSET_REQUEST',
    CREATE_VIDEO_ASSET_SUCCESS: 'CREATE_VIDEO_ASSET_SUCCESS',
    CREATE_VIDEO_ASSET_FAILURE: 'CREATE_VIDEO_ASSET_FAILURE',
    
    CLEAR_UPLOADS: "CLEAR_UPLOADS",

    SELECT_CREATIVE: 'CREATIVE_SELECTED'
};
