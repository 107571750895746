import React from 'react';

import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
// import { Router, Route, Switch, Redirect } from 'react-router-dom';

//Redux
import { connect }                          from 'react-redux';
import { history }                          from '../_helpers';
import { alertActions, progressActions,  }  from '../_actions';
import { PrivateRoute }                     from '../_components';

//Client Pages
import { CreativeLibrary }                  from '../CreativeLibrary';
import { CampaignList }                     from '../CampaignList';
import { AdgroupList }                      from '../AdgroupList';
import { CreativeList }                     from '../CreativeList';
import { PublisherManagement }              from '../PublisherManagement';
import { ReportPage }                       from '../ReportPage';
import { DashboardPage }                    from '../DashboardPage';
import { LoginPage }                        from '../LoginPage';
import { RegisterPage }                     from '../RegisterPage';
import { PixelsPage }                       from '../PixelsPage';
import { ConversionPage }                   from '../ConversionPage';
import { MarketResearchPage }               from '../MarketResearchPage';
import { MarketResearchPage2 }              from '../MarketResearchPage2';
import { GlobePrototype }                   from '../GlobePage';

//Admin Pages
import { AdminUserManagement }              from '../AdminUserManagement';
import { AdminBilling }                     from '../AdminBilling';
import { MarginControl }                    from '../MarginControl';
import { IPCPage }                          from '../IPCPage';
import { BrandPage }                        from '../BrandPage';

//Styles
import { Animated }                         from "react-animated-css";
import '../_stylesheets/css/style.css'

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alertIsVisible: true
        }

        history.listen((location, action) => {
            // clear alert on location change
            this.props.clearAlerts();
        });
    }

    
    render() {
        const { user, users, alert, progress } = this.props;
        const role = localStorage.getItem('r');

        // Sequence in showing alert: (inner setTimeout is triggered first)
        alert.message && setTimeout(() => {

            // Step 2: Then after 2 seconds alert is hidden
            if(alert.type != "alert-loading"){
                this.setState({alertIsVisible : true})
                this.state.alertIsVisible && this.props.clearAlerts();
            }
          }, 1000);

        return (
            <div>
                {
                    alert.message && alert.message != "Unauthorized Login" && 
                    <Animated className="animated-alert" animationIn="bounceInTop" isVisible={this.state.alertIsVisible}>
                        <div className={`alert ${alert.type}`}>
                            <i className={`fa ${alert.fa_icon}`} aria-hidden="true"></i>
                            <span className="title">{`${alert.type == "success" ? alert.title + ":" : ""}`}</span>
                            <span className="message">{alert.message}</span>
                        </div>
                    </Animated>
                }
                {
                    // progress.status > 0 &&
                    // progress.status <= 100 &&
                    // <div style={{width:`${progress.status}%`}} className="progress-bar">{progress.status}</div>
                }
                {
                    users.loading && 
                    <Animated className="animated-alert" animationIn="bounceInTop" isVisible={true}>
                        <div className={`alert alert-loading`}>
                            <i className={`fa fa-cog fa-spin`} aria-hidden="true"></i>
                            <span className="title"></span>
                            <span className="message">Loading....</span>
                        </div>
                    </Animated>
                }
                {
                    <Router history={history}>
                        <Switch>
                            {/** PUBLIC PAGES **/}
                            <Route path="/login" component={LoginPage} />
                            <Route path="/register" component={RegisterPage} />

                            {/** DSP PAGES **/}
                            <PrivateRoute role="DSP" exact path="/dsp" component={ReportPage}/>


                            {/** USER PAGES **/}
                            <PrivateRoute role="client" exact path="/dashboard" component={DashboardPage}/>
                            <PrivateRoute role="client" exact path="/creative-library" component={CreativeLibrary}/>
                            <PrivateRoute role="client" exact path="/campaign" component={CampaignList}/>
                            <PrivateRoute role="client" exact path={["/adgroup/:campaignId", "/adgroup/"]} component={AdgroupList}/>
                            <PrivateRoute role="client" exact path={["/creative/:adgroupId", "/creative/"]} component={CreativeList}/>
                            <PrivateRoute role="client" exact path="/publisher-management" component={PublisherManagement}/>
                            <PrivateRoute role="client" exact path="/pixels" component={PixelsPage}/>
                            <PrivateRoute role="client" exact path="/conversion" component={ConversionPage}/>
                            <PrivateRoute role="client" exact path="/market-research" component={MarketResearchPage}/>
                            <PrivateRoute role="client" exact path="/market-research2" component={MarketResearchPage2}/>
                            <PrivateRoute role="client" exact path="/globe-prototype" component={GlobePrototype} />

                            {/** PLATFORM ADMIN PAGES **/}
                            <PrivateRoute role="platform_admin" exact path="/platform-admin/user-management" component={AdminUserManagement}/>
                            <PrivateRoute role="platform_admin" exact path="/platform-admin/margin-control" component={MarginControl}/>
                            <PrivateRoute role="platform_admin" exact path="/platform-admin/billing" component={AdminBilling}/>
                            <PrivateRoute role="platform_admin" exact path="/platform-admin/ipc" component={IPCPage}/>
                            <PrivateRoute role="platform_admin" exact path="/brand-management" component={BrandPage}/>
                            
                            {/** CLIENT ADMIN PAGES **/}
                            <PrivateRoute role="admin" exact path="/admin/margin-control" component={MarginControl}/>
                            <PrivateRoute role="admin" exact path="/admin/user-management" component={AdminUserManagement}/>
                            <PrivateRoute role="admin" exact path="/admin/billing" component={AdminBilling}/>
                            
                            
                            {
                                role == "platform_admin"    ?   <Redirect from="*" to="/platform-admin/user-management" /> :

                                role == "admin"             ?   <Redirect from="*" to="/admin/user-management" /> :
                                
                                role == "client"            ?   <Redirect from="*" to="/dashboard" /> :

                                role == "DSP"               ?   <Redirect from="*" to="/dsp" /> :
                                <Redirect from="*" to="/login" />
                            }
                        </Switch>
                    </Router>
                }
            </div>
        );
    }
}

function mapState(state) {
    const { alert, users, progress } = state;
    return { alert, users, progress };
}

const actionCreators = {
    clearAlerts: alertActions.clear,
    clearProgress: progressActions.clear,
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };
