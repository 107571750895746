import config from 'config';
import { authHeader } from '../_helpers';

export const brandsService = {
    getBrands,
    addBrand,
    updateBrand
};

function getBrands() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/brands/`, requestOptions).then(handleResponse);
}

function addBrand(brand) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({"brand":brand})
    };

    return fetch(`${config.apiURL}/brands`, requestOptions).then(handleResponse);
}

function updateBrand(data) {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/brands`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
