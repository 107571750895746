import { adsConstants } from '../_constants';
import { 
    adsService,
 } from '../_services';
import { alertActions, progressActions } from './';
import { history } from '../_helpers';

export const adsActions = {
    getByAdGroupId,
    updateAds,
};

function getByAdGroupId(adGroupId) {
    return dispatch => {
        dispatch(request());

        adsService.getByAdGroupId(adGroupId)
            .then(
                ads => {
                    dispatch(success(ads))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: adsConstants.GET_REQUEST } }
    function success(ads) { return { type: adsConstants.GET_SUCCESS, ads } }
    function failure(error) { return { type: adsConstants.GET_FAILURE, error } }
}

function updateAds(supernovaAdgroupId, data, adGroupId) {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.loading("Saving changes..."));
        console.log("Updating status of ads with supernovaAdgroupId: " + supernovaAdgroupId);

        // 1. Update Ads Details
        adsService.update(supernovaAdgroupId, data)
            .then(
                ads => {
                    adsService.getByAdGroupId(adGroupId)
                    .then(
                        ads => {
                            dispatch(success(ads))
                            dispatch(alertActions.success('Changes Saved'));
                            console.log("DONE: Updating Ads");
                        },
                        error => {
                            dispatch(failure(error.toString()))
                        }
                    );
                },
                error => {
                    console.log("ERROR: Updating ads");
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: adsConstants.UPDATE_REQUEST } }
    function success(ads) { return { type: adsConstants.UPDATE_SUCCESS, ads } }
    function failure(id, error) { return { type: adsConstants.UPDATE_FAILURE, id, error } }
}
