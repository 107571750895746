export const campaignConstants = {
    CREATE_REQUEST: 'CAMPAIGN_CREATE_REQUEST',
    CREATE_SUCCESS: 'CAMPAIGN_CREATE_SUCCESS',
    CREATE_FAILURE: 'CAMPAIGN_CREATE_FAILURE',

    GET_REQUEST: 'CAMPAIGN_GET_REQUEST',
    GET_SUCCESS: 'CAMPAIGN_GET_SUCCESS',
    GET_FAILURE: 'CAMPAIGN_GET_FAILURE',
    
    GET_INACTIVE_REQUEST: 'CAMPAIGN_GET_INACTIVE_REQUEST',
    GET_INACTIVE_SUCCESS: 'CAMPAIGN_GET_INACTIVE_SUCCESS',
    GET_INACTIVE_FAILURE: 'CAMPAIGN_GET_INACTIVE_FAILURE',
    
    DISABLE_REQUEST: 'CAMPAIGN_DISABLE_REQUEST',
    DISABLE_SUCCESS: 'CAMPAIGN_DISABLE_SUCCESS',
    DISABLE_FAILURE: 'CAMPAIGN_DISABLE_FAILURE',
    
    ENABLE_REQUEST: 'CAMPAIGN_ENABLE_REQUEST',
    ENABLE_SUCCESS: 'CAMPAIGN_ENABLE_SUCCESS',
    ENABLE_FAILURE: 'CAMPAIGN_ENABLE_FAILURE',

    GET_DETAIL_REQUEST: 'CAMPAIGN_GET_DETAIL_REQUEST',
    GET_DETAIL_SUCCESS: 'CAMPAIGN_GET_DETAIL_SUCCESS',
    GET_DETAIL_FAILURE: 'CAMPAIGN_GET_DETAIL_FAILURE',

    UPDATE_REQUEST: 'CAMPAIGN_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'CAMPAIGN_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CAMPAIGN_UPDATE_FAILURE',

    CREATE_PUSH_REQUEST: 'CAMPAIGN_CREATE_PUSH_REQUEST',
    CREATE_PUSH_SUCCESS: 'CAMPAIGN_CREATE_PUSH_SUCCESS',
    CREATE_PUSH_FAILURE: 'CAMPAIGN_CREATE_PUSH_FAILURE',
    
    UPDATE_PUSH_REQUEST: 'CAMPAIGN_UPDATE_PUSH_REQUEST',
    UPDATE_PUSH_SUCCESS: 'CAMPAIGN_UPDATE_PUSH_SUCCESS',
    UPDATE_PUSH_FAILURE: 'CAMPAIGN_UPDATE_PUSH_FAILURE',
    
    SELECT_CAMPAIGN: 'CAMPAIGN_SELECTED'
};
