  import { campaignConstants } from '../_constants';

export function campaigns(state = {}, action) {
  switch (action.type) {
    //=====================================
    // CREATE
    //=====================================
    case campaignConstants.CREATE_REQUEST:
      return { 
        ...state,
        loading: true 
      };
    case campaignConstants.CREATE_SUCCESS:
      return {
        ...state,
        items: action.campaigns,
        isDone:true
      };
    case campaignConstants.CREATE_FAILURE:
      return {
        ...state,
        loading:false,
        error: action.error
      };
    //=====================================
    // GET
    //=====================================
    case campaignConstants.GET_REQUEST:
      return {
        ...state,
        isDone: false
      };
    case campaignConstants.GET_SUCCESS:
      return {
        ...state,
        items: action.campaigns,
        isDone:true
      };
    case campaignConstants.GET_FAILURE:
      return { 
        error: action.error,
        isDone:true
      };
    //=====================================
    // GET INACTIVE CAMPAIGNS
    //=====================================
    case campaignConstants.GET_INACTIVE_REQUEST:
      return {
        ...state,
        isDone: false
      };
    case campaignConstants.GET_INACTIVE_SUCCESS:
      return {
        ...state,
        inactive: action.campaigns,
        isDone:true
      };
    case campaignConstants.GET_INACTIVE_FAILURE:
      return { 
        ...state,
        error: action.error,
        isDone:true
      };
    //=====================================
    // ENABLE CAMPAIGNS
    //=====================================
    case campaignConstants.ENABLE_REQUEST:
      return {
        ...state,
        isDone: false
      };
    case campaignConstants.ENABLE_SUCCESS:
      return {
        ...state,
        inactive: state.inactive.filter(item => item.campaignId != action.campaignId),  //Remove the item from campaigns.inactive
        isDone:true
      };
    case campaignConstants.ENABLE_FAILURE:
      return { 
        ...state,
        error: action.error,
        isDone:true
      };
    //=====================================
    // DISABLE CAMPAIGNS
    //=====================================
    case campaignConstants.DISABLE_REQUEST:
      return {
        ...state,
        isDone: false
      };
    case campaignConstants.DISABLE_SUCCESS:
      return {
        ...state,
        inactive: [...state.inactive, action.data], //Add the newly added campaign to the campaigns.items
        isDone:true
      };
    case campaignConstants.DISABLE_FAILURE:
      return { 
        ...state,
        error: action.error,
        isDone:true
      };
      //=====================================
      // UPDATE
      //=====================================
      case campaignConstants.UPDATE_REQUEST:
        return {
          ...state,
          loading: true
        };
      case campaignConstants.UPDATE_SUCCESS:
        return {
          ...state,
          loading: false,
          isDone:true,
          items: action.campaigns,
        };
      case campaignConstants.UPDATE_FAILURE:
        return {
          ...state,
          loading: false,
          error:action.error
        };
      //=====================================
      // SELECT
      // Note:
      // Sets the selected campaignID
      // So the sideDrawer knows which 
      // campaign to load when editing.
      //=====================================
      case campaignConstants.SELECT_CAMPAIGN:
        return {
          ...state,
          selected: action.campaignID
        };
      //=====================================
      // GET DETAIL
      //=====================================
      case campaignConstants.GET_DETAIL_REQUEST:
        return {
          loading: true
        };
      case campaignConstants.GET_DETAIL_SUCCESS:
        return {
          items: action.campaign
        };
      case campaignConstants.GET_DETAIL_FAILURE:
        return { 
          error: action.error
        };
    //=====================================
    // CREATE PUSH CAMPAIGN
    //=====================================
    case campaignConstants.CREATE_PUSH_REQUEST:
      return { 
        ...state,
        loading: true 
      };
    case campaignConstants.CREATE_PUSH_SUCCESS:
      console.log("xxxxxxxxxxxxxxxxxxxxxxxxxx")
      console.log(action.pushCampaigns)
      console.log("xxxxxxxxxxxxxxxxxxxxxxxxxx")
      return {
        ...state,
        items: [...state.items, action.pushCampaigns], //Add the newly added campaign to the campaign.items
        loading:false,
        isDone:true
      };
    case campaignConstants.CREATE_PUSH_FAILURE:
      return {
        ...state,
        loading:false,
        error: action.error
      };
    //=====================================
    // UPDATE PUSH CAMPAIGN
    //=====================================
    case campaignConstants.UPDATE_PUSH_REQUEST:
      return { 
        ...state,
        loading: true 
      };
    case campaignConstants.UPDATE_PUSH_SUCCESS:
      const index = state.items.findIndex(item => item.id == action.data.campaignId);
      let updated_items = state.items;
      updated_items[index].name = action.data.name
      return {
        ...state,
        items: updated_items, // 
        loading:false,
        isDone:true
      };
    case campaignConstants.UPDATE_PUSH_FAILURE:
      return {
        ...state,
        loading:false,
        error: action.error
      };
      //=====================================
      // DEFAULT
      //=====================================
    default:
      return state
  }
}