import { publisherGroupConstants } from '../_constants';
import { publisherGroupService } from '../_services';
import { alertActions, progressActions } from './';
import { history } from '../_helpers';

export const publisherGroupActions = {
    getPublisherGroups,
    deletePublisherGroup,
    updatePublisherGroup,
    createPublisherGroup
};


function getPublisherGroups(accountId) {
    return dispatch => {
        dispatch(request());

        publisherGroupService.getPublisherGroups(accountId)
            .then(
                publisherGroups => dispatch(success(publisherGroups)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: publisherGroupConstants.GET_REQUEST } }
    function success(publisherGroups) { return { type: publisherGroupConstants.GET_SUCCESS, publisherGroups } }
    function failure(error) { return { type: publisherGroupConstants.GET_FAILURE, error } }
}

function deletePublisherGroup(publisherGroupId, accountId, publisherGroupName) {
    return dispatch => {
        const totalProcess = 2; //Note: 2 because we need to first we need to delete. Then second we need to retrieve again from local db for latest copy
        let completedProcess = 0
        dispatch(request());
        dispatch(alertActions.loading("Deleting..."));

        completedProcess += 1;
        dispatch(progressActions.update(completedProcess, totalProcess));
        publisherGroupService.deletePublisherGroup(publisherGroupId, accountId)
            .then(
                publisherGroups => {
                    publisherGroupService.getPublisherGroups()
                    .then(
                        publisherGroups => {
                            dispatch(alertActions.success(`${publisherGroupName} is removed`));
                            publisherGroupService.getPublisherGroups(accountId)
                            .then(
                                publisherGroups => dispatch(success(publisherGroups)),
                                error => dispatch(failure(error.toString()))
                            );
                        },
                        error => dispatch(failure(error.toString()))
                    );
                },
                error => {
                    completedProcess = totalProcess;
                    dispatch(progressActions.update(completedProcess, totalProcess));
                    dispatch(alertActions.error(error));
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: publisherGroupConstants.DELETE_REQUEST } }
    function success(publisherGroups) { return { type: publisherGroupConstants.DELETE_SUCCESS, publisherGroups } }
    function failure(error) { return { type: publisherGroupConstants.DELETE_FAILURE, error } }
}

function updatePublisherGroup(data) {
    return dispatch => {
        const totalProcess = 2; //Note: 2 because we need to first we need to update. Then second we need to retrieve again from local db for latest copy
        let completedProcess = 0
        dispatch(request());
        dispatch(alertActions.loading("Saving..."));

        completedProcess += 1;
        dispatch(progressActions.update(completedProcess, totalProcess));

        publisherGroupService.updatePublisherGroup(data)
            .then(
                publisherGroups => {
                    publisherGroupService.getPublisherGroups()
                    .then(
                        publisherGroups => {
                            dispatch(alertActions.success(`${data.name} is updated`));
                            publisherGroupService.getPublisherGroups(data.accountId)
                            .then(
                                publisherGroups => dispatch(success(publisherGroups)),
                                error => dispatch(failure(error.toString()))
                            );
                        },
                        error => dispatch(failure(error.toString()))
                    );
                },
                error => {
                    completedProcess = totalProcess;
                    dispatch(progressActions.update(completedProcess, totalProcess));
                    dispatch(alertActions.error(error));
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: publisherGroupConstants.UPDATE_REQUEST } }
    function success(publisherGroups) { return { type: publisherGroupConstants.UPDATE_SUCCESS, publisherGroups } }
    function failure(error) { return { type: publisherGroupConstants.UPDATE_FAILURE, error } }
}

function createPublisherGroup(data, entries) {
    return dispatch => {
        const totalProcess = 2; //Note: 2 because we need to first we need to create. Then second we need to retrieve again from local db for latest copy
        let completedProcess = 0
        dispatch(request());
        dispatch(alertActions.loading("Creating Publisher Group..."));

        completedProcess += 1;
        dispatch(progressActions.update(completedProcess, totalProcess));

        publisherGroupService.createPublisherGroup(data, entries)
            .then(
                publisherGroups => {
                    publisherGroupService.getPublisherGroups()
                    .then(
                        publisherGroups => {
                            dispatch(alertActions.success(`${data.name} is created`));
                            publisherGroupService.getPublisherGroups(data.accountId)
                            .then(
                                publisherGroups => dispatch(success(publisherGroups)),
                                error => dispatch(failure(error.toString()))
                            );
                        },
                        error => dispatch(failure(error.toString()))
                    );
                },
                error => {
                    completedProcess = totalProcess;
                    dispatch(progressActions.update(completedProcess, totalProcess));
                    dispatch(alertActions.error(error));
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: publisherGroupConstants.CREATE_REQUEST } }
    function success(publisherGroups) { return { type: publisherGroupConstants.CREATE_SUCCESS, publisherGroups } }
    function failure(error) { return { type: publisherGroupConstants.CREATE_FAILURE, error } }
}