import '../../_helpers/sort-by.js'
export const listCreativeAttributes = [
    {value:'1', label:'Audio Ad (Auto-Play)'},
    {value:'2', label:'Audio Ad (User Initiated)'},
    {value:'3', label:'Expandable (Automatic)'},
    {value:'4', label:'Expandable (User Initiated - Click)'},
    {value:'5', label:'Expandable (User Initiated - Rollover)'},
    {value:'6', label:'In-Banner Video Ad (Auto-Play)'},
    {value:'7', label:'In-Banner Video Ad (User Initiated)'},
    {value:'8', label:'Pop (e.g., Over, Under, or Upon Exit)'},
    {value:'9', label:'Provocative or Suggestive Imagery'},
    {value:'10', label:'Shaky, Flashing, Flickering, Extreme Animation, Smileys'},
    {value:'11', label:'Surveys'},
    {value:'12', label:'Text Only'},
    {value:'13', label:'User Interactive (e.g., Embedded Games)'},
    {value:'14', label:'Windows Dialog or Alert Style'},
    {value:'15', label:'Has Audio On/Off Button'},
    {value:'16', label:'Ad Provides Skip Button'},
    {value:'17', label:'Adobe Flash'},
].sortBy('label')