import { ipcConstants } from '../_constants';

export function ipc(state = {}, action) {
  switch (action.type) {
    //=====================================
    // GET
    //=====================================
    case ipcConstants.GET_REQUEST:
      return {
        loading: true
      };
    case ipcConstants.GET_SUCCESS:
      return {
        loading: false,
        items: action.ipc
      };
    case ipcConstants.GET_FAILURE:
      return { 
        loading: false,
        error: action.error
      };
      //=====================================
      // DEFAULT
      //=====================================
    default:
      return state
  }
}