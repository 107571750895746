import { accountConstants } from '../_constants';
import { accountService } from '../_services';
import { alertActions, progressActions } from './';
import { history } from '../_helpers';

export const accountActions = {
    getAccountById,
};



function getAccountById(accountId) {
    return dispatch => {
        dispatch(request());

        accountService.getAccountById(accountId)
            .then(
                accounts => dispatch(success(accounts)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: accountConstants.GET_REQUEST } }
    function success(accounts) { return { type: accountConstants.GET_SUCCESS, accounts } }
    function failure(error) { return { type: accountConstants.GET_FAILURE, error } }
}

