import config from 'config';
import { authHeader } from '../_helpers';

export const aiService = {
    askAI,
    askAIToProceedProcess,
    cancelAIRequest
};

let controller; 

function cancelAIRequest() {
    if (controller) {
        controller.abort();  // This cancels the request
    }
}

function askAI(message, thread_id, accountId, campaignId) {
    controller = new AbortController();  // Create a new controller for each request
    const signal = controller.signal;    // Get the signal

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            message: message,
            accountId: accountId,
            campaignId: campaignId
        }),
        signal // Attach the signal to the fetch request
    };

    if(thread_id == null){
        // New Query
        return fetch(`${config.apiURL}/zip/null`, requestOptions)
            .then(handleResponse)
            .catch((error) => {
                if (error.name === 'AbortError') {
                    console.log('Request canceled');
                } else {
                    throw error;
                }
            });
    } else {
        // Additional Query
        return fetch(`${config.apiURL}/zip/${thread_id}`, requestOptions)
            .then(handleResponse)
            .catch((error) => {
                if (error.name === 'AbortError') {
                    console.log('Request canceled');
                } else {
                    throw error;
                }
            });
    }
}
function askAIToProceedProcess(thread_id, campaignId, targetLocation) {
    controller = new AbortController();  // Create a new controller for each request
    const signal = controller.signal;    // Get the signal

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            targetLocation: targetLocation,
        }),
        signal // Attach the signal to the fetch request
    };

    return fetch(`${config.apiURL}/zip/resume/${thread_id}/${campaignId}`, requestOptions)
        .then(handleResponse)
        .catch((error) => {
            if (error.name === 'AbortError') {
                console.log('Request canceled');
            } else {
                throw error;
            }
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}