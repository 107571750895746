import { creativeConstants } from '../_constants';

export function creatives(state = {}, action) {
  switch (action.type) {
    //=====================================
    // CREATE
    //=====================================
    case creativeConstants.CREATE_REQUEST:
      return { 
        ...state,
        creating: true 
      };
    case creativeConstants.CREATE_SUCCESS:
      return {
        ...state,
        items: state.items.concat(action.creatives),
        creating:false
      };
    case creativeConstants.CREATE_FAILURE:
      return {
        ...state,
        creating:false,
        error: action.error
      };
    //=====================================
    // CREATE PUSH CREATIVE
    //=====================================
    case creativeConstants.CREATE_PUSH_REQUEST:
      return { 
        ...state,
        creating: true 
      };
    case creativeConstants.CREATE_PUSH_SUCCESS:
      return {
        ...state,
        items: state.items.concat(action.creatives),
        creating:false
      };
    case creativeConstants.CREATE_PUSH_FAILURE:
      return {
        ...state,
        creating:false,
        error: action.error
      };
    //=====================================
    // EDIT PUSH CREATIVES
    //=====================================
    case creativeConstants.EDIT_PUSH_REQUEST:
      return { 
        ...state,
        updating: true 
      };
    case creativeConstants.EDIT_PUSH_SUCCESS:
      //Update creative by id
      const index_push_edit = state.items.findIndex(item => item.id == action.creative.id)
      state.items[index_push_edit] = action.creative

      return {
        ...state,
        updating: false,
        items: state.items,
      };
    case creativeConstants.EDIT_PUSH_FAILURE:
      return {
        ...state,
        updating:false,
        error: action.error
      };
    //=====================================
    // UPDATE
    //=====================================
    case creativeConstants.UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      };
    case creativeConstants.UPDATE_SUCCESS:
      
      //Update creative by id
      const i = state.items.findIndex(item => item.id == action.creative.id)
      state.items[i] = action.creative

      return {
        ...state,
        updating: false,
        items: state.items,
      };
    case creativeConstants.UPLOAD_PROGRESS:

      // Add the progress to state.file[]
      // If it doesn't exist then create one
      // If it already exist then find if it already has an existing based on $index. If it finds that there is already an existing state.find[$index] then update it Else $push 
      if(state.file){
        let i = state.file.findIndex(item => item.index == action.progress.index)
        if(i >= 0){
          state.file[i] = {
            ...state.file[i],
            [action.progress.field]:action.progress.value,
            index:action.progress.index
          }
        }
        else{
          state.file.push({
            ...state.file[i],
            [action.progress.field]:action.progress.value,
            index:action.progress.index
          })
        }
      }
      else{
        state.file = []
        state.file[0] = {
          ...state.file[0],
          [action.progress.field]:action.progress.value,
          index:0
        }
      }

      return {
        ...state,
        [action.progress.field]:action.progress.value,
      };

    case creativeConstants.UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        error:action.error
      };
    //=====================================
    // UPLOAD
    //=====================================
    case creativeConstants.UPLOAD_REQUEST:
      return {
        ...state,
        isVideoProcessing:true,
      };
    case creativeConstants.UPLOAD_SUCCESS:
      const index_image = state.file.findIndex(item => item.index == action.image.index)
      state.file[index_image] = {
        ...state.file[index_image],
        [action.image.field]: action.image.url,
        field: action.image.field,
        index:action.image.index
      }
      return {
        ...state,
        isVideoProcessing:false,
      };
    case creativeConstants.UPLOAD_VIDEO_SUCCESS:
      const index_video = state.file.findIndex(item => item.index == action.video.index)
      state.file[index_video] = {
        ...state.file[index_video],
        [action.video.field]: action.video.field,
        videoAssetId: action.video.videoAssetId,
        index:action.video.index
      }
      return {
        ...state,
        isVideoProcessing:false,
      };
    case creativeConstants.UPLOAD_FAILURE:
      return {
        ...state,
        isVideoProcessing:false,
        error:true
      };
    //=====================================
    // VIDEO: CREATE NEW VIDEO ASSET
    //=====================================
    case creativeConstants.CREATE_VIDEO_ASSET_REQUEST:
      return {
        ...state,
      };
    case creativeConstants.CREATE_VIDEO_ASSET_SUCCESS:
      return {
        ...state,
      };
    case creativeConstants.CREATE_VIDEO_ASSET_FAILURE:
      return {
        ...state,
      };
    //=====================================
    // GET
    //=====================================
    case creativeConstants.GET_REQUEST:
      return {
        isDone: false
      };
    case creativeConstants.GET_SUCCESS:
      return {
        isDone: true,
        items: action.creatives
      };
    case creativeConstants.GET_FAILURE:
      return { 
        isDone: true,
        error: action.error
      };
      //=====================================
      // SELECT
      // Note:
      // Sets the selected creativeID
      // So the sideDrawer knows which 
      // creative to load when editing.
      //=====================================
      case creativeConstants.SELECT_ADGROUP:
        return {
          ...state,
          selected: action.creativeID
        };
      //=====================================
      // CLEAR UPLOADS
      //=====================================
      case creativeConstants.CLEAR_UPLOADS:
        return {
          ...state,
          file:[],
        };
      //=====================================
      // DEFAULT
      //=====================================
    default:
      return state
  }
}