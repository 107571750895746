import { audienceConstants } from '../_constants';

export function audiences(state = {}, action) {
  switch (action.type) {
    //=====================================
    // GET
    //=====================================
    case audienceConstants.GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case audienceConstants.GET_SUCCESS:
      return {
        ...state,
        items: action.audiences,
        loading: false
      };
    case audienceConstants.GET_FAILURE:
      return { 
        ...state,
        error: action.error,
        loading: false
      };
    //=====================================
    // UPDATE
    //=====================================
    case audienceConstants.UPDATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case audienceConstants.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.audiences
      };
    case audienceConstants.UPDATE_FAILURE:
      return { 
        ...state,
        loading: false,
        error: action.error
      };
    //=====================================
    // CREATE
    //=====================================
    case audienceConstants.CREATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case audienceConstants.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: [...state.items, action.audiences]
      };
    case audienceConstants.CREATE_FAILURE:
      return { 
        ...state,
        loading: false,
        error: action.error
      };
      //=====================================
      // DEFAULT
      //=====================================
    default:
      return state
  }
}