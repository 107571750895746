import React from 'react'
import {BrowserRouter as Router, Link } from "react-router-dom";
import { Dropdown } from 'react-bootstrap'

//Redux
import { connect } from 'react-redux';
import { 
    campaignActions, 
    adgroupActions, 
    sideDrawerActions 
} from '../_actions';

class TopNavigation extends React.Component {
        
    constructor(props) {
        super(props);
        this.handleOpenSideDrawer = this.handleOpenSideDrawer.bind(this)
    }
    componentDidMount() {
        const { page, campaignId, adgroupId } = this.props;
        page == "campaign" && this.props.getCampaignDetail(this.props.campaignId)
        page == "adgroup" && this.props.getAdgroupDetail(this.props.adgroupId)
    }
    handleOpenSideDrawer(form, purpose, campaignID) {
        this.props.openSideDrawer(form, purpose);
    }
    render(){
        const { user, page, campaigns, adgroups, handleOpenSideDrawer, campaignId, adgroupId } = this.props;
        const campaign = campaigns.items !== undefined 
            ? campaigns.items[0]
            : ""
        const adgroup = adgroups.items !== undefined 
            ? adgroups.items[0]
            : ""
        
        return (
            <>
                <div className="top-navigation">
                    <a className="navbar-brand" href="#">
                        <img className="logo" src="/public/images/logo.png" />
                    </a>
                    <Dropdown className="profile-menu">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <i className="fa fa-bars" aria-hidden="true"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <div>
                                <div>Logged In as</div>
                                <div>{user.username}</div>
                            </div>
                            <Dropdown.Item onClick={() => this.showModal('Update Profile', 'FORM_UPDATE_PROFILE', 'update-profile', null)}>User Profile</Dropdown.Item>
                            <Dropdown.Item href="/login">Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </>
            );
    }
}

const mapState = state => ({
    campaigns:state.campaigns,
    adgroups:state.adgroups
})

const actionCreators = {
    getCampaignDetail: campaignActions.getCampaignDetail,
    getAdgroupDetail: adgroupActions.getAdgroupDetail,
    openSideDrawer: sideDrawerActions.open
}

const connectedTopNavigation = connect(mapState, actionCreators)(TopNavigation);

export { connectedTopNavigation as TopNavigation };