import '../../_helpers/sort-by.js'
export const listPushAdsCities = [
    {
        "id": 329037,
        "value": "1441",
        "label": "Miranda, California, USA"
    },
    {
        "id": 332622,
        "value": "5542",
        "label": "Mission Hills, California, USA"
    },
    {
        "id": 331415,
        "value": "3136",
        "label": "Mission Viejo, California, USA"
    },
    {
        "id": 331327,
        "value": "2720",
        "label": "Moccasin, California, USA"
    },
    {
        "id": 333418,
        "value": "818",
        "label": "Modesto, California, USA"
    },
    {
        "id": 330163,
        "value": "1870",
        "label": "Mojave, California, USA"
    },
    {
        "id": 332404,
        "value": "5365",
        "label": "Mokelumne Hill, California, USA"
    },
    {
        "id": 330227,
        "value": "2011",
        "label": "Monrovia, California, USA"
    },
    {
        "id": 331292,
        "value": "2661",
        "label": "Montague, California, USA"
    },
    {
        "id": 328933,
        "value": "11251",
        "label": "Montara, California, USA"
    },
    {
        "id": 333427,
        "value": "8251",
        "label": "Montclair, California, USA"
    },
    {
        "id": 331646,
        "value": "38478",
        "label": "Monte Rio, California, USA"
    },
    {
        "id": 330161,
        "value": "1869",
        "label": "Montebello, California, USA"
    },
    {
        "id": 333455,
        "value": "863",
        "label": "Monterey, California, USA"
    },
    {
        "id": 331534,
        "value": "3509",
        "label": "Monterey Park, California, USA"
    },
    {
        "id": 333097,
        "value": "60670",
        "label": "Montgomery Creek, California, USA"
    },
    {
        "id": 330213,
        "value": "1992",
        "label": "Montrose, California, USA"
    },
    {
        "id": 329053,
        "value": "1486",
        "label": "Moorpark, California, USA"
    },
    {
        "id": 328956,
        "value": "1225",
        "label": "Moraga, California, USA"
    },
    {
        "id": 332623,
        "value": "5547",
        "label": "Moreno Valley, California, USA"
    },
    {
        "id": 331313,
        "value": "2692",
        "label": "Morgan Hill, California, USA"
    },
    {
        "id": 332013,
        "value": "47450",
        "label": "Morongo Valley, California, USA"
    },
    {
        "id": 331517,
        "value": "33805",
        "label": "Morro Bay, California, USA"
    },
    {
        "id": 330149,
        "value": "18544",
        "label": "Moss Beach, California, USA"
    },
    {
        "id": 331457,
        "value": "3368",
        "label": "Moss Landing, California, USA"
    },
    {
        "id": 330195,
        "value": "19548",
        "label": "Mount Aukum, California, USA"
    },
    {
        "id": 331410,
        "value": "30110",
        "label": "Mount Baldy, California, USA"
    },
    {
        "id": 330261,
        "value": "20937",
        "label": "Mount Hamilton, California, USA"
    },
    {
        "id": 332355,
        "value": "49468",
        "label": "Mount Hermon, California, USA"
    },
    {
        "id": 328948,
        "value": "11798",
        "label": "Mount Laguna, California, USA"
    },
    {
        "id": 330282,
        "value": "2179",
        "label": "Mount Shasta, California, USA"
    },
    {
        "id": 328945,
        "value": "11725",
        "label": "Mount Wilson, California, USA"
    },
    {
        "id": 331954,
        "value": "46027",
        "label": "Mountain Center, California, USA"
    },
    {
        "id": 332356,
        "value": "49469",
        "label": "Mountain Pass, California, USA"
    },
    {
        "id": 332190,
        "value": "48743",
        "label": "Mountain Ranch, California, USA"
    },
    {
        "id": 329089,
        "value": "156",
        "label": "Mountain View, California, USA"
    },
    {
        "id": 332357,
        "value": "49470",
        "label": "Murphys, California, USA"
    },
    {
        "id": 329147,
        "value": "16776",
        "label": "Murrieta, California, USA"
    },
    {
        "id": 333058,
        "value": "60617",
        "label": "Myers Flat, California, USA"
    },
    {
        "id": 330152,
        "value": "1860",
        "label": "Napa, California, USA"
    },
    {
        "id": 332617,
        "value": "5484",
        "label": "National City, California, USA"
    },
    {
        "id": 331411,
        "value": "30111",
        "label": "Navarro, California, USA"
    },
    {
        "id": 331518,
        "value": "33806",
        "label": "Needles, California, USA"
    },
    {
        "id": 333079,
        "value": "60647",
        "label": "Nelson, California, USA"
    },
    {
        "id": 331293,
        "value": "2664",
        "label": "Nevada City, California, USA"
    },
    {
        "id": 332358,
        "value": "49471",
        "label": "New Almaden, California, USA"
    },
    {
        "id": 331630,
        "value": "37929",
        "label": "New Cuyama, California, USA"
    },
    {
        "id": 332154,
        "value": "4809",
        "label": "Newark, California, USA"
    },
    {
        "id": 330225,
        "value": "2007",
        "label": "Newberry Springs, California, USA"
    },
    {
        "id": 333863,
        "value": "935",
        "label": "Newbury Park, California, USA"
    },
    {
        "id": 329172,
        "value": "17374",
        "label": "Newcastle, California, USA"
    },
    {
        "id": 333457,
        "value": "8661",
        "label": "Newhall, California, USA"
    },
    {
        "id": 329822,
        "value": "17610",
        "label": "Newman, California, USA"
    },
    {
        "id": 330209,
        "value": "1987",
        "label": "Newport Beach, California, USA"
    },
    {
        "id": 331412,
        "value": "30112",
        "label": "Newport Coast, California, USA"
    },
    {
        "id": 332407,
        "value": "5402",
        "label": "Nicasio, California, USA"
    },
    {
        "id": 332001,
        "value": "47127",
        "label": "Nice, California, USA"
    },
    {
        "id": 331627,
        "value": "37920",
        "label": "Nicolaus, California, USA"
    },
    {
        "id": 331998,
        "value": "47120",
        "label": "Niland, California, USA"
    },
    {
        "id": 329059,
        "value": "1492",
        "label": "Nipomo, California, USA"
    },
    {
        "id": 336025,
        "value": "3894",
        "label": "Norco, California, USA"
    },
    {
        "id": 335224,
        "value": "30113",
        "label": "Norden, California, USA"
    },
    {
        "id": 335877,
        "value": "36863",
        "label": "North Fork, California, USA"
    },
    {
        "id": 336042,
        "value": "3931",
        "label": "North Highlands, California, USA"
    },
    {
        "id": 333928,
        "value": "10112",
        "label": "North Hills, California, USA"
    },
    {
        "id": 334027,
        "value": "1194",
        "label": "North Hollywood, California, USA"
    },
    {
        "id": 335225,
        "value": "30114",
        "label": "North Palm Springs, California, USA"
    },
    {
        "id": 335664,
        "value": "36180",
        "label": "North San Juan, California, USA"
    },
    {
        "id": 334761,
        "value": "2010",
        "label": "Northridge, California, USA"
    },
    {
        "id": 334058,
        "value": "1238",
        "label": "Norwalk, California, USA"
    },
    {
        "id": 333969,
        "value": "1073",
        "label": "Novato, California, USA"
    },
    {
        "id": 334353,
        "value": "15887",
        "label": "Nuevo, California, USA"
    },
    {
        "id": 336052,
        "value": "39461",
        "label": "O Neals, California, USA"
    },
    {
        "id": 338855,
        "value": "9484",
        "label": "Oak Park, California, USA"
    },
    {
        "id": 338233,
        "value": "60672",
        "label": "Oak Run, California, USA"
    },
    {
        "id": 334379,
        "value": "16020",
        "label": "Oak View, California, USA"
    },
    {
        "id": 335161,
        "value": "2727",
        "label": "Oakdale, California, USA"
    },
    {
        "id": 338768,
        "value": "8854",
        "label": "Oakhurst, California, USA"
    },
    {
        "id": 334350,
        "value": "158",
        "label": "Oakland, California, USA"
    },
    {
        "id": 338801,
        "value": "9068",
        "label": "Oakley, California, USA"
    },
    {
        "id": 338838,
        "value": "9286",
        "label": "Oakville, California, USA"
    },
    {
        "id": 334183,
        "value": "1432",
        "label": "Occidental, California, USA"
    },
    {
        "id": 338863,
        "value": "9580",
        "label": "Oceano, California, USA"
    },
    {
        "id": 334197,
        "value": "1482",
        "label": "Oceanside, California, USA"
    },
    {
        "id": 338184,
        "value": "60523",
        "label": "Ocotillo, California, USA"
    },
    {
        "id": 338396,
        "value": "6499",
        "label": "Ojai, California, USA"
    },
    {
        "id": 335091,
        "value": "25569",
        "label": "Olancha, California, USA"
    },
    {
        "id": 335118,
        "value": "261254",
        "label": "Old Station, California, USA"
    },
    {
        "id": 338203,
        "value": "60579",
        "label": "Olema, California, USA"
    },
    {
        "id": 336464,
        "value": "49472",
        "label": "Olivehurst, California, USA"
    },
    {
        "id": 334993,
        "value": "23510",
        "label": "Olympic Valley, California, USA"
    },
    {
        "id": 334506,
        "value": "170",
        "label": "Ontario, California, USA"
    },
    {
        "id": 338193,
        "value": "60545",
        "label": "Onyx, California, USA"
    },
    {
        "id": 334744,
        "value": "1984",
        "label": "Orange, California, USA"
    },
    {
        "id": 336465,
        "value": "49473",
        "label": "Orange Cove, California, USA"
    },
    {
        "id": 338844,
        "value": "9328",
        "label": "Orangevale, California, USA"
    },
    {
        "id": 336313,
        "value": "47116",
        "label": "Oregon House, California, USA"
    },
    {
        "id": 336466,
        "value": "49474",
        "label": "Orick, California, USA"
    },
    {
        "id": 335071,
        "value": "2472",
        "label": "Orinda, California, USA"
    },
    {
        "id": 334851,
        "value": "2177",
        "label": "Orland, California, USA"
    },
    {
        "id": 334847,
        "value": "2166",
        "label": "Orleans, California, USA"
    },
    {
        "id": 335931,
        "value": "38461",
        "label": "Oro Grande, California, USA"
    },
    {
        "id": 334398,
        "value": "16100",
        "label": "Orosi, California, USA"
    },
    {
        "id": 334843,
        "value": "2156",
        "label": "Oroville, California, USA"
    },
    {
        "id": 338851,
        "value": "939",
        "label": "Oxnard, California, USA"
    },
    {
        "id": 338839,
        "value": "9287",
        "label": "Pacific Grove, California, USA"
    },
    {
        "id": 337124,
        "value": "5579",
        "label": "Pacific Palisades, California, USA"
    },
    {
        "id": 334750,
        "value": "1998",
        "label": "Pacifica, California, USA"
    },
    {
        "id": 334048,
        "value": "12086",
        "label": "Pacoima, California, USA"
    },
    {
        "id": 334873,
        "value": "22295",
        "label": "Paicines, California, USA"
    },
    {
        "id": 335226,
        "value": "30115",
        "label": "Pala, California, USA"
    },
    {
        "id": 338227,
        "value": "60648",
        "label": "Palermo, California, USA"
    },
    {
        "id": 334196,
        "value": "1481",
        "label": "Palm Desert, California, USA"
    },
    {
        "id": 338856,
        "value": "949",
        "label": "Palm Springs, California, USA"
    },
    {
        "id": 334199,
        "value": "1483",
        "label": "Palmdale, California, USA"
    },
    {
        "id": 333978,
        "value": "109",
        "label": "Palo Alto, California, USA"
    },
    {
        "id": 334726,
        "value": "19674",
        "label": "Palo Cedro, California, USA"
    },
    {
        "id": 336731,
        "value": "51088",
        "label": "Palo Verde, California, USA"
    },
    {
        "id": 334994,
        "value": "23511",
        "label": "Palomar Mountain, California, USA"
    },
    {
        "id": 337105,
        "value": "5535",
        "label": "Palos Verdes Peninsula, California, USA"
    },
    {
        "id": 337106,
        "value": "5536",
        "label": "Panorama City, California, USA"
    },
    {
        "id": 335153,
        "value": "2704",
        "label": "Paradise, California, USA"
    },
    {
        "id": 338495,
        "value": "6789",
        "label": "Paramount, California, USA"
    },
    {
        "id": 335092,
        "value": "25570",
        "label": "Parker Dam, California, USA"
    },
    {
        "id": 338836,
        "value": "927",
        "label": "Pasadena, California, USA"
    },
    {
        "id": 335156,
        "value": "2711",
        "label": "Paskenta, California, USA"
    },
    {
        "id": 334205,
        "value": "1493",
        "label": "Paso Robles, California, USA"
    },
    {
        "id": 338526,
        "value": "6957",
        "label": "Patterson, California, USA"
    },
    {
        "id": 336467,
        "value": "49475",
        "label": "Patton, California, USA"
    },
    {
        "id": 336182,
        "value": "45291",
        "label": "Pauma Valley, California, USA"
    },
    {
        "id": 338234,
        "value": "60673",
        "label": "Paynes Creek, California, USA"
    },
    {
        "id": 334520,
        "value": "17071",
        "label": "Pearblossom, California, USA"
    },
    {
        "id": 337070,
        "value": "5433",
        "label": "Pebble Beach, California, USA"
    },
    {
        "id": 334081,
        "value": "13094",
        "label": "Penn Valley, California, USA"
    },
    {
        "id": 338840,
        "value": "9292",
        "label": "Penngrove, California, USA"
    },
    {
        "id": 335933,
        "value": "38475",
        "label": "Penryn, California, USA"
    },
    {
        "id": 338859,
        "value": "953",
        "label": "Perris, California, USA"
    },
    {
        "id": 334056,
        "value": "1229",
        "label": "Pescadero, California, USA"
    },
    {
        "id": 334184,
        "value": "1435",
        "label": "Petaluma, California, USA"
    },
    {
        "id": 335093,
        "value": "25571",
        "label": "Petrolia, California, USA"
    },
    {
        "id": 333950,
        "value": "10537",
        "label": "Phelan, California, USA"
    },
    {
        "id": 334420,
        "value": "16243",
        "label": "Phillipsville, California, USA"
    },
    {
        "id": 336295,
        "value": "46784",
        "label": "Philo, California, USA"
    },
    {
        "id": 334747,
        "value": "1989",
        "label": "Pico Rivera, California, USA"
    },
    {
        "id": 338744,
        "value": "8700",
        "label": "Piedmont, California, USA"
    },
    {
        "id": 338199,
        "value": "60569",
        "label": "Piedra, California, USA"
    },
    {
        "id": 336183,
        "value": "45395",
        "label": "Pilot Hill, California, USA"
    },
    {
        "id": 338715,
        "value": "8484",
        "label": "Pine Grove, California, USA"
    },
    {
        "id": 335104,
        "value": "260765",
        "label": "Pine Mountain Club, California, USA"
    },
    {
        "id": 334654,
        "value": "1876",
        "label": "Pine Valley, California, USA"
    },
    {
        "id": 335158,
        "value": "2724",
        "label": "Pinecrest, California, USA"
    },
    {
        "id": 338864,
        "value": "9593",
        "label": "Pinole, California, USA"
    },
    {
        "id": 336398,
        "value": "48494",
        "label": "Pinon Hills, California, USA"
    },
    {
        "id": 338274,
        "value": "61097",
        "label": "Pioneer, California, USA"
    },
    {
        "id": 338185,
        "value": "60524",
        "label": "Pioneertown, California, USA"
    },
    {
        "id": 336245,
        "value": "45979",
        "label": "Piru, California, USA"
    },
    {
        "id": 334660,
        "value": "1878",
        "label": "Pismo Beach, California, USA"
    },
    {
        "id": 334055,
        "value": "1227",
        "label": "Pittsburg, California, USA"
    },
    {
        "id": 334401,
        "value": "16106",
        "label": "Pixley, California, USA"
    },
    {
        "id": 338714,
        "value": "848",
        "label": "Placentia, California, USA"
    },
    {
        "id": 334852,
        "value": "2182",
        "label": "Placerville, California, USA"
    },
    {
        "id": 335612,
        "value": "35147",
        "label": "Planada, California, USA"
    },
    {
        "id": 336311,
        "value": "47114",
        "label": "Platina, California, USA"
    },
    {
        "id": 338848,
        "value": "9353",
        "label": "Playa Del Rey, California, USA"
    },
    {
        "id": 335227,
        "value": "30116",
        "label": "Pleasant Grove, California, USA"
    },
    {
        "id": 335395,
        "value": "3374",
        "label": "Pleasant Hill, California, USA"
    },
    {
        "id": 334190,
        "value": "1444",
        "label": "Pleasanton, California, USA"
    },
    {
        "id": 334647,
        "value": "18703",
        "label": "Plymouth, California, USA"
    },
    {
        "id": 335661,
        "value": "36172",
        "label": "Point Arena, California, USA"
    },
    {
        "id": 334995,
        "value": "23512",
        "label": "Point Mugu Nawc, California, USA"
    },
    {
        "id": 334996,
        "value": "23513",
        "label": "Point Reyes Station, California, USA"
    },
    {
        "id": 338872,
        "value": "9769",
        "label": "Pollock Pines, California, USA"
    },
    {
        "id": 334760,
        "value": "2008",
        "label": "Pomona, California, USA"
    },
    {
        "id": 336468,
        "value": "49476",
        "label": "Pope Valley, California, USA"
    },
    {
        "id": 335228,
        "value": "30117",
        "label": "Port Costa, California, USA"
    },
    {
        "id": 335998,
        "value": "3885",
        "label": "Port Hueneme, California, USA"
    },
    {
        "id": 335229,
        "value": "30118",
        "label": "Port Hueneme Cbc Base, California, USA"
    },
    {
        "id": 336448,
        "value": "49259",
        "label": "Porter Ranch, California, USA"
    },
    {
        "id": 334846,
        "value": "2164",
        "label": "Porterville, California, USA"
    },
    {
        "id": 334856,
        "value": "2196",
        "label": "Portola, California, USA"
    },
    {
        "id": 334997,
        "value": "23514",
        "label": "Portola Valley, California, USA"
    },
    {
        "id": 338194,
        "value": "60546",
        "label": "Posey, California, USA"
    },
    {
        "id": 336443,
        "value": "48933",
        "label": "Potrero, California, USA"
    },
    {
        "id": 336362,
        "value": "47808",
        "label": "Potter Valley, California, USA"
    },
    {
        "id": 338630,
        "value": "794",
        "label": "Poway, California, USA"
    },
    {
        "id": 338200,
        "value": "60570",
        "label": "Prather, California, USA"
    },
    {
        "id": 336282,
        "value": "46495",
        "label": "Princeton, California, USA"
    },
    {
        "id": 334844,
        "value": "2157",
        "label": "Quincy, California, USA"
    },
    {
        "id": 338228,
        "value": "60649",
        "label": "Rackerby, California, USA"
    },
    {
        "id": 338205,
        "value": "60589",
        "label": "Rail Road Flat, California, USA"
    },
    {
        "id": 334402,
        "value": "16110",
        "label": "Raisin City, California, USA"
    },
    {
        "id": 334213,
        "value": "1499",
        "label": "Ramona, California, USA"
    },
    {
        "id": 338183,
        "value": "60522",
        "label": "Ranchita, California, USA"
    },
    {
        "id": 334023,
        "value": "1190",
        "label": "Rancho Cordova, California, USA"
    },
    {
        "id": 338481,
        "value": "673",
        "label": "Rancho Cucamonga, California, USA"
    },
    {
        "id": 336440,
        "value": "4885",
        "label": "Rancho Mirage, California, USA"
    },
    {
        "id": 337128,
        "value": "5582",
        "label": "Rancho Palos Verdes, California, USA"
    },
    {
        "id": 338854,
        "value": "9410",
        "label": "Rancho Santa Fe, California, USA"
    },
    {
        "id": 336770,
        "value": "5375",
        "label": "Rancho Santa Margarita, California, USA"
    },
    {
        "id": 335230,
        "value": "30119",
        "label": "Randsburg, California, USA"
    },
    {
        "id": 335231,
        "value": "30120",
        "label": "Raymond, California, USA"
    },
    {
        "id": 338862,
        "value": "9559",
        "label": "Red Bluff, California, USA"
    },
    {
        "id": 338735,
        "value": "864",
        "label": "Redding, California, USA"
    },
    {
        "id": 333946,
        "value": "1049",
        "label": "Redlands, California, USA"
    },
    {
        "id": 335353,
        "value": "308",
        "label": "Redondo Beach, California, USA"
    },
    {
        "id": 336469,
        "value": "49477",
        "label": "Redway, California, USA"
    },
    {
        "id": 338820,
        "value": "916",
        "label": "Redwood City, California, USA"
    },
    {
        "id": 333994,
        "value": "11355",
        "label": "Redwood Estates, California, USA"
    },
    {
        "id": 335908,
        "value": "37933",
        "label": "Redwood Valley, California, USA"
    },
    {
        "id": 338527,
        "value": "6958",
        "label": "Reedley, California, USA"
    },
    {
        "id": 336072,
        "value": "40512",
        "label": "Represa, California, USA"
    },
    {
        "id": 334005,
        "value": "11569",
        "label": "Rescue, California, USA"
    },
    {
        "id": 335347,
        "value": "3071",
        "label": "Reseda, California, USA"
    },
    {
        "id": 338717,
        "value": "849",
        "label": "Rialto, California, USA"
    },
    {
        "id": 335943,
        "value": "38552",
        "label": "Richgrove, California, USA"
    },
    {
        "id": 334054,
        "value": "1226",
        "label": "Richmond, California, USA"
    },
    {
        "id": 335903,
        "value": "37921",
        "label": "Richvale, California, USA"
    },
    {
        "id": 338646,
        "value": "804",
        "label": "Ridgecrest, California, USA"
    },
    {
        "id": 338189,
        "value": "60535",
        "label": "Rimforest, California, USA"
    },
    {
        "id": 335072,
        "value": "2473",
        "label": "Rio Dell, California, USA"
    },
    {
        "id": 334845,
        "value": "2163",
        "label": "Rio Linda, California, USA"
    },
    {
        "id": 338210,
        "value": "60602",
        "label": "Rio Nido, California, USA"
    },
    {
        "id": 338873,
        "value": "9770",
        "label": "Rio Oso, California, USA"
    },
    {
        "id": 334169,
        "value": "13768",
        "label": "Rio Vista, California, USA"
    },
    {
        "id": 334853,
        "value": "2187",
        "label": "Ripon, California, USA"
    },
    {
        "id": 338220,
        "value": "60627",
        "label": "River Pines, California, USA"
    },
    {
        "id": 335154,
        "value": "2706",
        "label": "Riverbank, California, USA"
    },
    {
        "id": 334404,
        "value": "16115",
        "label": "Riverdale, California, USA"
    },
    {
        "id": 334057,
        "value": "1236",
        "label": "Riverside, California, USA"
    },
    {
        "id": 336037,
        "value": "39266",
        "label": "Robbins, California, USA"
    },
    {
        "id": 334796,
        "value": "2057",
        "label": "Rocklin, California, USA"
    },
    {
        "id": 338867,
        "value": "9648",
        "label": "Rodeo, California, USA"
    },
    {
        "id": 335049,
        "value": "2370",
        "label": "Rohnert Park, California, USA"
    },
    {
        "id": 334207,
        "value": "1497",
        "label": "Rosamond, California, USA"
    },
    {
        "id": 336307,
        "value": "4694",
        "label": "Rosemead, California, USA"
    },
    {
        "id": 335990,
        "value": "3878",
        "label": "Roseville, California, USA"
    },
    {
        "id": 338847,
        "value": "9349",
        "label": "Ross, California, USA"
    },
    {
        "id": 335396,
        "value": "33807",
        "label": "Rough And Ready, California, USA"
    },
    {
        "id": 338236,
        "value": "60675",
        "label": "Round Mountain, California, USA"
    },
    {
        "id": 337101,
        "value": "5510",
        "label": "Rowland Heights, California, USA"
    },
    {
        "id": 338221,
        "value": "60628",
        "label": "Rumsey, California, USA"
    },
    {
        "id": 336438,
        "value": "48839",
        "label": "Running Springs, California, USA"
    },
    {
        "id": 336276,
        "value": "46465",
        "label": "Rutherford, California, USA"
    },
    {
        "id": 338222,
        "value": "60629",
        "label": "Ryde, California, USA"
    },
    {
        "id": 334007,
        "value": "116",
        "label": "Sacramento, California, USA"
    },
    {
        "id": 334012,
        "value": "11690",
        "label": "Salida, California, USA"
    },
    {
        "id": 338692,
        "value": "829",
        "label": "Salinas, California, USA"
    },
    {
        "id": 338187,
        "value": "60526",
        "label": "Salton City, California, USA"
    },
    {
        "id": 338216,
        "value": "60618",
        "label": "Salyer, California, USA"
    },
    {
        "id": 336470,
        "value": "49478",
        "label": "Samoa, California, USA"
    },
    {
        "id": 334841,
        "value": "2151",
        "label": "San Andreas, California, USA"
    },
    {
        "id": 338843,
        "value": "9316",
        "label": "San Anselmo, California, USA"
    },
    {
        "id": 335909,
        "value": "37941",
        "label": "San Ardo, California, USA"
    },
    {
        "id": 338315,
        "value": "624",
        "label": "San Bernardino, California, USA"
    },
    {
        "id": 336712,
        "value": "5054",
        "label": "San Bruno, California, USA"
    },
    {
        "id": 334749,
        "value": "1994",
        "label": "San Carlos, California, USA"
    },
    {
        "id": 336096,
        "value": "4354",
        "label": "San Clemente, California, USA"
    },
    {
        "id": 334031,
        "value": "120",
        "label": "San Diego, California, USA"
    },
    {
        "id": 337449,
        "value": "5643",
        "label": "San Dimas, California, USA"
    },
    {
        "id": 335097,
        "value": "256",
        "label": "San Fernando, California, USA"
    },
    {
        "id": 334327,
        "value": "157",
        "label": "San Francisco, California, USA"
    },
    {
        "id": 338655,
        "value": "8068",
        "label": "San Gabriel, California, USA"
    },
    {
        "id": 338204,
        "value": "60580",
        "label": "San Geronimo, California, USA"
    },
    {
        "id": 334129,
        "value": "13644",
        "label": "San Gregorio, California, USA"
    },
    {
        "id": 334203,
        "value": "1489",
        "label": "San Jacinto, California, USA"
    },
    {
        "id": 334403,
        "value": "16111",
        "label": "San Joaquin, California, USA"
    },
    {
        "id": 333979,
        "value": "110",
        "label": "San Jose, California, USA"
    },
    {
        "id": 338861,
        "value": "9540",
        "label": "San Juan Bautista, California, USA"
    },
    {
        "id": 338260,
        "value": "6080",
        "label": "San Juan Capistrano, California, USA"
    },
    {
        "id": 335869,
        "value": "3646",
        "label": "San Leandro, California, USA"
    },
    {
        "id": 338169,
        "value": "6018",
        "label": "San Lorenzo, California, USA"
    },
    {
        "id": 336038,
        "value": "39267",
        "label": "San Lucas, California, USA"
    },
    {
        "id": 336248,
        "value": "46",
        "label": "San Luis Obispo, California, USA"
    },
    {
        "id": 334998,
        "value": "23515",
        "label": "San Luis Rey, California, USA"
    },
    {
        "id": 335164,
        "value": "2737",
        "label": "San Marcos, California, USA"
    },
    {
        "id": 338494,
        "value": "6787",
        "label": "San Marino, California, USA"
    },
    {
        "id": 334801,
        "value": "2068",
        "label": "San Martin, California, USA"
    },
    {
        "id": 335923,
        "value": "381",
        "label": "San Mateo, California, USA"
    },
    {
        "id": 336462,
        "value": "4929",
        "label": "San Miguel, California, USA"
    },
    {
        "id": 338392,
        "value": "6494",
        "label": "San Pablo, California, USA"
    },
    {
        "id": 335732,
        "value": "363",
        "label": "San Pedro, California, USA"
    },
    {
        "id": 335935,
        "value": "38479",
        "label": "San Quentin, California, USA"
    },
    {
        "id": 338666,
        "value": "813",
        "label": "San Rafael, California, USA"
    },
    {
        "id": 338663,
        "value": "812",
        "label": "San Ramon, California, USA"
    },
    {
        "id": 336073,
        "value": "40513",
        "label": "San Simeon, California, USA"
    },
    {
        "id": 338650,
        "value": "8044",
        "label": "San Ysidro, California, USA"
    },
    {
        "id": 338435,
        "value": "6550",
        "label": "Sanger, California, USA"
    },
    {
        "id": 334200,
        "value": "1485",
        "label": "Santa Ana, California, USA"
    },
    {
        "id": 336749,
        "value": "521",
        "label": "Santa Barbara, California, USA"
    },
    {
        "id": 338882,
        "value": "99",
        "label": "Santa Clara, California, USA"
    },
    {
        "id": 334758,
        "value": "2005",
        "label": "Santa Clarita, California, USA"
    },
    {
        "id": 338682,
        "value": "820",
        "label": "Santa Cruz, California, USA"
    },
    {
        "id": 337104,
        "value": "5534",
        "label": "Santa Fe Springs, California, USA"
    },
    {
        "id": 334693,
        "value": "19342",
        "label": "Santa Margarita, California, USA"
    },
    {
        "id": 337941,
        "value": "597",
        "label": "Santa Maria, California, USA"
    },
    {
        "id": 335138,
        "value": "265",
        "label": "Santa Monica, California, USA"
    },
    {
        "id": 337077,
        "value": "548",
        "label": "Santa Paula, California, USA"
    },
    {
        "id": 338672,
        "value": "814",
        "label": "Santa Rosa, California, USA"
    },
    {
        "id": 338758,
        "value": "881",
        "label": "Santa Ynez, California, USA"
    },
    {
        "id": 336400,
        "value": "48512",
        "label": "Santa Ysabel, California, USA"
    },
    {
        "id": 338852,
        "value": "9407",
        "label": "Santee, California, USA"
    },
    {
        "id": 334764,
        "value": "2016",
        "label": "Saratoga, California, USA"
    },
    {
        "id": 338906,
        "value": "998",
        "label": "Sausalito, California, USA"
    },
    {
        "id": 336471,
        "value": "49479",
        "label": "Scotia, California, USA"
    },
    {
        "id": 335930,
        "value": "3845",
        "label": "Scotts Valley, California, USA"
    },
    {
        "id": 335659,
        "value": "3598",
        "label": "Seal Beach, California, USA"
    },
    {
        "id": 335196,
        "value": "2953",
        "label": "Seaside, California, USA"
    },
    {
        "id": 338703,
        "value": "8382",
        "label": "Sebastopol, California, USA"
    },
    {
        "id": 338186,
        "value": "60525",
        "label": "Seeley, California, USA"
    },
    {
        "id": 338238,
        "value": "60677",
        "label": "Seiad Valley, California, USA"
    },
    {
        "id": 334033,
        "value": "12007",
        "label": "Selma, California, USA"
    },
    {
        "id": 335907,
        "value": "37927",
        "label": "Sequoia National Park, California, USA"
    },
    {
        "id": 334232,
        "value": "15207",
        "label": "Shafter, California, USA"
    },
    {
        "id": 334720,
        "value": "19578",
        "label": "Shandon, California, USA"
    },
    {
        "id": 335157,
        "value": "2718",
        "label": "Shasta, California, USA"
    },
    {
        "id": 334999,
        "value": "23516",
        "label": "Shasta Lake, California, USA"
    },
    {
        "id": 338198,
        "value": "60567",
        "label": "Shaver Lake, California, USA"
    },
    {
        "id": 336275,
        "value": "46464",
        "label": "Sheridan, California, USA"
    },
    {
        "id": 338846,
        "value": "934",
        "label": "Sherman Oaks, California, USA"
    },
    {
        "id": 334894,
        "value": "2290",
        "label": "Shingle Springs, California, USA"
    },
    {
        "id": 336310,
        "value": "47113",
        "label": "Shingletown, California, USA"
    },
    {
        "id": 335094,
        "value": "25572",
        "label": "Shoshone, California, USA"
    },
    {
        "id": 336472,
        "value": "49480",
        "label": "Sierra City, California, USA"
    },
    {
        "id": 334762,
        "value": "2013",
        "label": "Sierra Madre, California, USA"
    },
    {
        "id": 335904,
        "value": "37922",
        "label": "Sierraville, California, USA"
    },
    {
        "id": 336473,
        "value": "49481",
        "label": "Signal Hill, California, USA"
    },
    {
        "id": 335614,
        "value": "35153",
        "label": "Silverado, California, USA"
    },
    {
        "id": 335348,
        "value": "3072",
        "label": "Simi Valley, California, USA"
    },
    {
        "id": 338190,
        "value": "60536",
        "label": "Skyforest, California, USA"
    },
    {
        "id": 334522,
        "value": "17104",
        "label": "Sloughhouse, California, USA"
    },
    {
        "id": 335905,
        "value": "37923",
        "label": "Smartsville, California, USA"
    },
    {
        "id": 336402,
        "value": "48559",
        "label": "Smith River, California, USA"
    },
    {
        "id": 335900,
        "value": "37908",
        "label": "Snelling, California, USA"
    },
    {
        "id": 335906,
        "value": "37924",
        "label": "Soda Springs, California, USA"
    },
    {
        "id": 336710,
        "value": "5050",
        "label": "Solana Beach, California, USA"
    },
    {
        "id": 335148,
        "value": "2694",
        "label": "Soledad, California, USA"
    },
    {
        "id": 338757,
        "value": "880",
        "label": "Solvang, California, USA"
    },
    {
        "id": 336216,
        "value": "45841",
        "label": "Somerset, California, USA"
    },
    {
        "id": 338217,
        "value": "60619",
        "label": "Somes Bar, California, USA"
    },
    {
        "id": 335233,
        "value": "30122",
        "label": "Somis, California, USA"
    },
    {
        "id": 335149,
        "value": "2697",
        "label": "Sonoma, California, USA"
    },
    {
        "id": 334850,
        "value": "2174",
        "label": "Sonora, California, USA"
    },
    {
        "id": 338845,
        "value": "9336",
        "label": "Soquel, California, USA"
    },
    {
        "id": 336474,
        "value": "49482",
        "label": "Soulsbyville, California, USA"
    },
    {
        "id": 338201,
        "value": "60572",
        "label": "South Dos Palos, California, USA"
    },
    {
        "id": 338259,
        "value": "6077",
        "label": "South El Monte, California, USA"
    },
    {
        "id": 337107,
        "value": "5539",
        "label": "South Gate, California, USA"
    },
    {
        "id": 335160,
        "value": "2726",
        "label": "South Lake Tahoe, California, USA"
    },
    {
        "id": 337071,
        "value": "5436",
        "label": "South Pasadena, California, USA"
    },
    {
        "id": 335636,
        "value": "3555",
        "label": "South San Francisco, California, USA"
    },
    {
        "id": 335000,
        "value": "23517",
        "label": "Spreckels, California, USA"
    },
    {
        "id": 338850,
        "value": "9379",
        "label": "Spring Valley, California, USA"
    },
    {
        "id": 335397,
        "value": "33808",
        "label": "Springville, California, USA"
    },
    {
        "id": 335142,
        "value": "2663",
        "label": "Squaw Valley, California, USA"
    },
    {
        "id": 335147,
        "value": "2689",
        "label": "St Helena, California, USA"
    },
    {
        "id": 336475,
        "value": "49483",
        "label": "Standard, California, USA"
    },
    {
        "id": 338243,
        "value": "60691",
        "label": "Standish, California, USA"
    },
    {
        "id": 334831,
        "value": "21123",
        "label": "Stanford, California, USA"
    },
    {
        "id": 338684,
        "value": "8235",
        "label": "Stanton, California, USA"
    },
    {
        "id": 335001,
        "value": "23518",
        "label": "Stevenson Ranch, California, USA"
    },
    {
        "id": 335234,
        "value": "30123",
        "label": "Stevinson, California, USA"
    },
    {
        "id": 338211,
        "value": "60603",
        "label": "Stewarts Point, California, USA"
    },
    {
        "id": 335610,
        "value": "35129",
        "label": "Stinson Beach, California, USA"
    },
    {
        "id": 336436,
        "value": "48815",
        "label": "Stirling City, California, USA"
    },
    {
        "id": 334091,
        "value": "132",
        "label": "Stockton, California, USA"
    },
    {
        "id": 334905,
        "value": "23288",
        "label": "Stonyford, California, USA"
    },
    {
        "id": 336262,
        "value": "46249",
        "label": "Stratford, California, USA"
    },
    {
        "id": 338866,
        "value": "9618",
        "label": "Strathmore, California, USA"
    },
    {
        "id": 336476,
        "value": "49484",
        "label": "Strawberry, California, USA"
    },
    {
        "id": 338261,
        "value": "6089",
        "label": "Studio City, California, USA"
    },
    {
        "id": 338191,
        "value": "60537",
        "label": "Sugarloaf, California, USA"
    },
    {
        "id": 338752,
        "value": "8773",
        "label": "Suisun City, California, USA"
    },
    {
        "id": 334400,
        "value": "16104",
        "label": "Sultana, California, USA"
    },
    {
        "id": 336477,
        "value": "49485",
        "label": "Summerland, California, USA"
    },
    {
        "id": 334538,
        "value": "17308",
        "label": "Sun City, California, USA"
    },
    {
        "id": 338627,
        "value": "7920",
        "label": "Sun Valley, California, USA"
    },
    {
        "id": 334128,
        "value": "13599",
        "label": "Sunland, California, USA"
    },
    {
        "id": 338818,
        "value": "915",
        "label": "Sunnyvale, California, USA"
    },
    {
        "id": 334053,
        "value": "1224",
        "label": "Sunol, California, USA"
    },
    {
        "id": 334819,
        "value": "20944",
        "label": "Sunset Beach, California, USA"
    },
    {
        "id": 334877,
        "value": "22587",
        "label": "Surfside, California, USA"
    },
    {
        "id": 335398,
        "value": "33809",
        "label": "Susanville, California, USA"
    },
    {
        "id": 335934,
        "value": "38476",
        "label": "Sutter, California, USA"
    },
    {
        "id": 334588,
        "value": "17633",
        "label": "Sutter Creek, California, USA"
    },
    {
        "id": 336249,
        "value": "4600",
        "label": "Sylmar, California, USA"
    },
    {
        "id": 336748,
        "value": "520",
        "label": "Taft, California, USA"
    },
    {
        "id": 338865,
        "value": "9602",
        "label": "Tahoe City, California, USA"
    },
    {
        "id": 335159,
        "value": "2725",
        "label": "Tahoe Vista, California, USA"
    },
    {
        "id": 336478,
        "value": "49486",
        "label": "Tahoma, California, USA"
    },
    {
        "id": 338212,
        "value": "60604",
        "label": "Talmage, California, USA"
    },
    {
        "id": 337079,
        "value": "5481",
        "label": "Tarzana, California, USA"
    },
    {
        "id": 338230,
        "value": "60651",
        "label": "Taylorsville, California, USA"
    },
    {
        "id": 336361,
        "value": "47807",
        "label": "Tecate, California, USA"
    },
    {
        "id": 336339,
        "value": "47285",
        "label": "Tecopa, California, USA"
    },
    {
        "id": 334193,
        "value": "1477",
        "label": "Tehachapi, California, USA"
    },
    {
        "id": 338239,
        "value": "60678",
        "label": "Tehama, California, USA"
    },
    {
        "id": 334204,
        "value": "1490",
        "label": "Temecula, California, USA"
    },
    {
        "id": 337076,
        "value": "5478",
        "label": "Temple City, California, USA"
    },
    {
        "id": 335665,
        "value": "36181",
        "label": "Templeton, California, USA"
    },
    {
        "id": 336479,
        "value": "49487",
        "label": "Terra Bella, California, USA"
    },
    {
        "id": 338281,
        "value": "61156",
        "label": "The Sea Ranch, California, USA"
    },
    {
        "id": 335662,
        "value": "36177",
        "label": "Thermal, California, USA"
    },
    {
        "id": 335155,
        "value": "2710",
        "label": "Thornton, California, USA"
    },
    {
        "id": 338356,
        "value": "645",
        "label": "Thousand Oaks, California, USA"
    },
    {
        "id": 335089,
        "value": "2555",
        "label": "Thousand Palms, California, USA"
    },
    {
        "id": 335152,
        "value": "2703",
        "label": "Three Rivers, California, USA"
    },
    {
        "id": 334396,
        "value": "16096",
        "label": "Tipton, California, USA"
    },
    {
        "id": 335235,
        "value": "30124",
        "label": "Tollhouse, California, USA"
    },
    {
        "id": 336480,
        "value": "49488",
        "label": "Toluca Lake, California, USA"
    },
    {
        "id": 334906,
        "value": "23289",
        "label": "Tomales, California, USA"
    },
    {
        "id": 334602,
        "value": "18015",
        "label": "Topanga, California, USA"
    },
    {
        "id": 338419,
        "value": "65134",
        "label": "Topaz, California, USA"
    },
    {
        "id": 334751,
        "value": "2002",
        "label": "Torrance, California, USA"
    },
    {
        "id": 334773,
        "value": "20298",
        "label": "Trabuco Canyon, California, USA"
    },
    {
        "id": 334854,
        "value": "2192",
        "label": "Tracy, California, USA"
    },
    {
        "id": 335942,
        "value": "38547",
        "label": "Tranquillity, California, USA"
    },
    {
        "id": 335944,
        "value": "38553",
        "label": "Traver, California, USA"
    },
    {
        "id": 335002,
        "value": "23519",
        "label": "Travis Afb, California, USA"
    },
    {
        "id": 336481,
        "value": "49489",
        "label": "Tres Pinos, California, USA"
    },
    {
        "id": 338219,
        "value": "60621",
        "label": "Trinidad, California, USA"
    },
    {
        "id": 336408,
        "value": "48628",
        "label": "Trinity Center, California, USA"
    },
    {
        "id": 338890,
        "value": "992",
        "label": "Trona, California, USA"
    },
    {
        "id": 334849,
        "value": "2172",
        "label": "Truckee, California, USA"
    },
    {
        "id": 337126,
        "value": "5581",
        "label": "Tujunga, California, USA"
    },
    {
        "id": 334643,
        "value": "1861",
        "label": "Tulare, California, USA"
    },
    {
        "id": 334099,
        "value": "13361",
        "label": "Tulelake, California, USA"
    },
    {
        "id": 336393,
        "value": "48219",
        "label": "Tuolumne, California, USA"
    },
    {
        "id": 334093,
        "value": "13207",
        "label": "Tupman, California, USA"
    },
    {
        "id": 335047,
        "value": "2360",
        "label": "Turlock, California, USA"
    },
    {
        "id": 336751,
        "value": "5246",
        "label": "Tustin, California, USA"
    },
    {
        "id": 338231,
        "value": "60652",
        "label": "Twain, California, USA"
    },
    {
        "id": 333989,
        "value": "11184",
        "label": "Twain Harte, California, USA"
    },
    {
        "id": 334004,
        "value": "11515",
        "label": "Twentynine Palms, California, USA"
    },
    {
        "id": 336482,
        "value": "49490",
        "label": "Twin Bridges, California, USA"
    },
    {
        "id": 335399,
        "value": "33810",
        "label": "Twin Peaks, California, USA"
    },
    {
        "id": 334189,
        "value": "1440",
        "label": "Ukiah, California, USA"
    },
    {
        "id": 335616,
        "value": "3520",
        "label": "Union City, California, USA"
    },
    {
        "id": 336727,
        "value": "5095",
        "label": "Universal City, California, USA"
    },
    {
        "id": 338874,
        "value": "983",
        "label": "Upland, California, USA"
    },
    {
        "id": 334185,
        "value": "1436",
        "label": "Upper Lake, California, USA"
    },
    {
        "id": 338708,
        "value": "841",
        "label": "Vacaville, California, USA"
    },
    {
        "id": 338181,
        "value": "6047",
        "label": "Valencia, California, USA"
    },
    {
        "id": 336256,
        "value": "46054",
        "label": "Vallecito, California, USA"
    },
    {
        "id": 334052,
        "value": "1222",
        "label": "Vallejo, California, USA"
    },
    {
        "id": 334907,
        "value": "23290",
        "label": "Valley Center, California, USA"
    },
    {
        "id": 336417,
        "value": "48698",
        "label": "Valley Ford, California, USA"
    },
    {
        "id": 335901,
        "value": "37909",
        "label": "Valley Springs, California, USA"
    },
    {
        "id": 338742,
        "value": "8691",
        "label": "Valley Village, California, USA"
    },
    {
        "id": 336366,
        "value": "47947",
        "label": "Valyermo, California, USA"
    },
    {
        "id": 334059,
        "value": "1241",
        "label": "Van Nuys, California, USA"
    },
    {
        "id": 337108,
        "value": "5540",
        "label": "Venice, California, USA"
    },
    {
        "id": 334746,
        "value": "1988",
        "label": "Ventura, California, USA"
    },
    {
        "id": 336390,
        "value": "48134",
        "label": "Verdugo City, California, USA"
    },
    {
        "id": 336314,
        "value": "47122",
        "label": "Vernalis, California, USA"
    },
    {
        "id": 338207,
        "value": "60590",
        "label": "Victor, California, USA"
    },
    {
        "id": 338896,
        "value": "994",
        "label": "Victorville, California, USA"
    },
    {
        "id": 336483,
        "value": "49491",
        "label": "Villa Grande, California, USA"
    },
    {
        "id": 338870,
        "value": "9724",
        "label": "Villa Park, California, USA"
    },
    {
        "id": 336312,
        "value": "47115",
        "label": "Vina, California, USA"
    },
    {
        "id": 338831,
        "value": "923",
        "label": "Visalia, California, USA"
    },
    {
        "id": 334890,
        "value": "2289",
        "label": "Vista, California, USA"
    },
    {
        "id": 338224,
        "value": "60630",
        "label": "Volcano, California, USA"
    },
    {
        "id": 335902,
        "value": "37910",
        "label": "Wallace, California, USA"
    },
    {
        "id": 335609,
        "value": "3508",
        "label": "Walnut, California, USA"
    },
    {
        "id": 338828,
        "value": "919",
        "label": "Walnut Creek, California, USA"
    },
    {
        "id": 338225,
        "value": "60631",
        "label": "Walnut Grove, California, USA"
    },
    {
        "id": 334908,
        "value": "23291",
        "label": "Warner Springs, California, USA"
    },
    {
        "id": 334191,
        "value": "1476",
        "label": "Wasco, California, USA"
    },
    {
        "id": 333962,
        "value": "10701",
        "label": "Washington, California, USA"
    },
    {
        "id": 334517,
        "value": "17054",
        "label": "Waterford, California, USA"
    },
    {
        "id": 334884,
        "value": "2287",
        "label": "Watsonville, California, USA"
    },
    {
        "id": 334399,
        "value": "16103",
        "label": "Waukena, California, USA"
    },
    {
        "id": 338613,
        "value": "7842",
        "label": "Weaverville, California, USA"
    },
    {
        "id": 334024,
        "value": "11906",
        "label": "Weed, California, USA"
    },
    {
        "id": 336484,
        "value": "49492",
        "label": "Weimar, California, USA"
    },
    {
        "id": 338893,
        "value": "993",
        "label": "Weldon, California, USA"
    },
    {
        "id": 338246,
        "value": "60695",
        "label": "Wendel, California, USA"
    },
    {
        "id": 335150,
        "value": "2699",
        "label": "Weott, California, USA"
    },
    {
        "id": 335896,
        "value": "3766",
        "label": "West Covina, California, USA"
    },
    {
        "id": 335004,
        "value": "23520",
        "label": "West Hills, California, USA"
    },
    {
        "id": 337078,
        "value": "5480",
        "label": "West Hollywood, California, USA"
    },
    {
        "id": 338208,
        "value": "60591",
        "label": "West Point, California, USA"
    },
    {
        "id": 338389,
        "value": "6491",
        "label": "West Sacramento, California, USA"
    },
    {
        "id": 336214,
        "value": "4580",
        "label": "Westlake Village, California, USA"
    },
    {
        "id": 338849,
        "value": "936",
        "label": "Westminster, California, USA"
    },
    {
        "id": 335932,
        "value": "38474",
        "label": "Westmorland, California, USA"
    },
    {
        "id": 338214,
        "value": "60606",
        "label": "Westport, California, USA"
    },
    {
        "id": 338593,
        "value": "7321",
        "label": "Westwood, California, USA"
    },
    {
        "id": 335236,
        "value": "30125",
        "label": "Wheatland, California, USA"
    },
    {
        "id": 338240,
        "value": "60679",
        "label": "Whiskeytown, California, USA"
    },
    {
        "id": 336485,
        "value": "49493",
        "label": "White Water, California, USA"
    },
    {
        "id": 334909,
        "value": "23292",
        "label": "Whitethorn, California, USA"
    },
    {
        "id": 338241,
        "value": "60680",
        "label": "Whitmore, California, USA"
    },
    {
        "id": 334752,
        "value": "2003",
        "label": "Whittier, California, USA"
    },
    {
        "id": 336077,
        "value": "41249",
        "label": "Wildomar, California, USA"
    },
    {
        "id": 338594,
        "value": "7325",
        "label": "Williams, California, USA"
    },
    {
        "id": 334186,
        "value": "1437",
        "label": "Willits, California, USA"
    },
    {
        "id": 335140,
        "value": "2660",
        "label": "Willow Creek, California, USA"
    },
    {
        "id": 335141,
        "value": "2662",
        "label": "Willows, California, USA"
    },
    {
        "id": 335833,
        "value": "364",
        "label": "Wilmington, California, USA"
    },
    {
        "id": 338209,
        "value": "60592",
        "label": "Wilseyville, California, USA"
    },
    {
        "id": 334550,
        "value": "17372",
        "label": "Wilton, California, USA"
    },
    {
        "id": 337110,
        "value": "5545",
        "label": "Winchester, California, USA"
    },
    {
        "id": 338785,
        "value": "8954",
        "label": "Windsor, California, USA"
    },
    {
        "id": 335005,
        "value": "23521",
        "label": "Winnetka, California, USA"
    },
    {
        "id": 336027,
        "value": "39010",
        "label": "Winterhaven, California, USA"
    },
    {
        "id": 334855,
        "value": "2194",
        "label": "Winters, California, USA"
    },
    {
        "id": 336486,
        "value": "49494",
        "label": "Winton, California, USA"
    },
    {
        "id": 338195,
        "value": "60547",
        "label": "Wofford Heights, California, USA"
    },
    {
        "id": 334428,
        "value": "16337",
        "label": "Woodacre, California, USA"
    },
    {
        "id": 338842,
        "value": "9311",
        "label": "Woodbridge, California, USA"
    },
    {
        "id": 334397,
        "value": "16099",
        "label": "Woodlake, California, USA"
    },
    {
        "id": 334842,
        "value": "2152",
        "label": "Woodland, California, USA"
    },
    {
        "id": 335633,
        "value": "3547",
        "label": "Woodland Hills, California, USA"
    },
    {
        "id": 338196,
        "value": "60548",
        "label": "Woody, California, USA"
    },
    {
        "id": 334745,
        "value": "1985",
        "label": "Wrightwood, California, USA"
    },
    {
        "id": 335663,
        "value": "36179",
        "label": "Yermo, California, USA"
    },
    {
        "id": 335607,
        "value": "34902",
        "label": "Yolo, California, USA"
    },
    {
        "id": 336099,
        "value": "4371",
        "label": "Yorba Linda, California, USA"
    },
    {
        "id": 336487,
        "value": "49495",
        "label": "Yorkville, California, USA"
    },
    {
        "id": 334910,
        "value": "23293",
        "label": "Yosemite National Park, California, USA"
    },
    {
        "id": 334188,
        "value": "1439",
        "label": "Yountville, California, USA"
    },
    {
        "id": 334848,
        "value": "2171",
        "label": "Yreka, California, USA"
    },
    {
        "id": 335893,
        "value": "3752",
        "label": "Yuba City, California, USA"
    },
    {
        "id": 334429,
        "value": "16338",
        "label": "Yucaipa, California, USA"
    },
    {
        "id": 338687,
        "value": "825",
        "label": "Yucca Valley, California, USA"
    },
    {
        "id": 338226,
        "value": "60632",
        "label": "Zamora, California, USA"
    },
    {
        "id": 336433,
        "value": "48804",
        "label": "Zenia, California, USA"
    },
    {
        "id": 338170,
        "value": "6020",
        "label": "Parlier, California, USA"
    },
    {
        "id": 335619,
        "value": "35250",
        "label": "Westley, California, USA"
    },
    {
        "id": 328959,
        "value": "123",
        "label": "?, California, USA"
    },
    {
        "id": 331955,
        "value": "46055",
        "label": "Acampo, California, USA"
    },
    {
        "id": 329017,
        "value": "13896",
        "label": "Acton, California, USA"
    },
    {
        "id": 333293,
        "value": "634",
        "label": "Adelanto, California, USA"
    },
    {
        "id": 333080,
        "value": "60653",
        "label": "Adin, California, USA"
    },
    {
        "id": 332487,
        "value": "5438",
        "label": "Agoura Hills, California, USA"
    },
    {
        "id": 332320,
        "value": "49433",
        "label": "Aguanga, California, USA"
    },
    {
        "id": 333023,
        "value": "60561",
        "label": "Ahwahnee, California, USA"
    },
    {
        "id": 330296,
        "value": "221",
        "label": "Alameda, California, USA"
    },
    {
        "id": 333420,
        "value": "8201",
        "label": "Alamo, California, USA"
    },
    {
        "id": 331765,
        "value": "4282",
        "label": "Albany, California, USA"
    },
    {
        "id": 331379,
        "value": "30078",
        "label": "Albion, California, USA"
    },
    {
        "id": 333050,
        "value": "60608",
        "label": "Alderpoint, California, USA"
    },
    {
        "id": 329054,
        "value": "1488",
        "label": "Alhambra, California, USA"
    },
    {
        "id": 332616,
        "value": "5482",
        "label": "Aliso Viejo, California, USA"
    },
    {
        "id": 331620,
        "value": "37912",
        "label": "Alleghany, California, USA"
    },
    {
        "id": 329102,
        "value": "16105",
        "label": "Alpaugh, California, USA"
    },
    {
        "id": 330223,
        "value": "20044",
        "label": "Alpine, California, USA"
    },
    {
        "id": 331644,
        "value": "38473",
        "label": "Alta, California, USA"
    },
    {
        "id": 333462,
        "value": "8743",
        "label": "Altadena, California, USA"
    },
    {
        "id": 331380,
        "value": "30079",
        "label": "Altaville, California, USA"
    },
    {
        "id": 330215,
        "value": "1996",
        "label": "Alturas, California, USA"
    },
    {
        "id": 333380,
        "value": "7191",
        "label": "Alviso, California, USA"
    },
    {
        "id": 333059,
        "value": "60622",
        "label": "Amador City, California, USA"
    },
    {
        "id": 328910,
        "value": "10170",
        "label": "American Canyon, California, USA"
    },
    {
        "id": 333850,
        "value": "924",
        "label": "Anaheim, California, USA"
    },
    {
        "id": 331324,
        "value": "2716",
        "label": "Anderson, California, USA"
    },
    {
        "id": 330272,
        "value": "2161",
        "label": "Angels Camp, California, USA"
    },
    {
        "id": 333003,
        "value": "60529",
        "label": "Angelus Oaks, California, USA"
    },
    {
        "id": 333291,
        "value": "6288",
        "label": "Angwin, California, USA"
    },
    {
        "id": 331511,
        "value": "33799",
        "label": "Annapolis, California, USA"
    },
    {
        "id": 333434,
        "value": "8328",
        "label": "Antelope, California, USA"
    },
    {
        "id": 328957,
        "value": "1228",
        "label": "Antioch, California, USA"
    },
    {
        "id": 331916,
        "value": "45520",
        "label": "Anza, California, USA"
    },
    {
        "id": 332621,
        "value": "5541",
        "label": "Apple Valley, California, USA"
    },
    {
        "id": 331960,
        "value": "46220",
        "label": "Applegate, California, USA"
    },
    {
        "id": 333866,
        "value": "9382",
        "label": "Aptos, California, USA"
    },
    {
        "id": 331580,
        "value": "36171",
        "label": "Arbuckle, California, USA"
    },
    {
        "id": 329051,
        "value": "1484",
        "label": "Arcadia, California, USA"
    },
    {
        "id": 331422,
        "value": "3224",
        "label": "Arcata, California, USA"
    },
    {
        "id": 332321,
        "value": "49434",
        "label": "Armona, California, USA"
    },
    {
        "id": 333877,
        "value": "9538",
        "label": "Arnold, California, USA"
    },
    {
        "id": 333878,
        "value": "9541",
        "label": "Aromas, California, USA"
    },
    {
        "id": 330166,
        "value": "1874",
        "label": "Arroyo Grande, California, USA"
    },
    {
        "id": 333437,
        "value": "8371",
        "label": "Artesia, California, USA"
    },
    {
        "id": 333067,
        "value": "60636",
        "label": "Artois, California, USA"
    },
    {
        "id": 333287,
        "value": "6165",
        "label": "Arvin, California, USA"
    },
    {
        "id": 333884,
        "value": "9577",
        "label": "Atascadero, California, USA"
    },
    {
        "id": 331827,
        "value": "4381",
        "label": "Atherton, California, USA"
    },
    {
        "id": 331609,
        "value": "3753",
        "label": "Atwater, California, USA"
    },
    {
        "id": 331910,
        "value": "45293",
        "label": "Atwood, California, USA"
    },
    {
        "id": 329104,
        "value": "16108",
        "label": "Auberry, California, USA"
    },
    {
        "id": 330288,
        "value": "2186",
        "label": "Auburn, California, USA"
    },
    {
        "id": 329123,
        "value": "16459",
        "label": "Avalon, California, USA"
    },
    {
        "id": 330267,
        "value": "2155",
        "label": "Avenal, California, USA"
    },
    {
        "id": 332204,
        "value": "48816",
        "label": "Avery, California, USA"
    },
    {
        "id": 331841,
        "value": "44178",
        "label": "Avila Beach, California, USA"
    },
    {
        "id": 328973,
        "value": "1237",
        "label": "Azusa, California, USA"
    },
    {
        "id": 331323,
        "value": "2715",
        "label": "Badger, California, USA"
    },
    {
        "id": 333872,
        "value": "9454",
        "label": "Baker, California, USA"
    },
    {
        "id": 333388,
        "value": "755",
        "label": "Bakersfield, California, USA"
    },
    {
        "id": 331537,
        "value": "352",
        "label": "Baldwin Park, California, USA"
    },
    {
        "id": 331979,
        "value": "46530",
        "label": "Ballico, California, USA"
    },
    {
        "id": 331621,
        "value": "37913",
        "label": "Bangor, California, USA"
    },
    {
        "id": 328919,
        "value": "1053",
        "label": "Banning, California, USA"
    },
    {
        "id": 332322,
        "value": "49435",
        "label": "Bard, California, USA"
    },
    {
        "id": 333288,
        "value": "622",
        "label": "Barstow, California, USA"
    },
    {
        "id": 333024,
        "value": "60562",
        "label": "Bass Lake, California, USA"
    },
    {
        "id": 332323,
        "value": "49436",
        "label": "Bayside, California, USA"
    },
    {
        "id": 331381,
        "value": "30080",
        "label": "Beale Afb, California, USA"
    },
    {
        "id": 332628,
        "value": "5654",
        "label": "Beaumont, California, USA"
    },
    {
        "id": 333107,
        "value": "60692",
        "label": "Beckwourth, California, USA"
    },
    {
        "id": 332201,
        "value": "48783",
        "label": "Bell, California, USA"
    },
    {
        "id": 331382,
        "value": "30081",
        "label": "Bell Gardens, California, USA"
    },
    {
        "id": 333428,
        "value": "8282",
        "label": "Bella Vista, California, USA"
    },
    {
        "id": 332618,
        "value": "5508",
        "label": "Bellflower, California, USA"
    },
    {
        "id": 333424,
        "value": "8223",
        "label": "Belmont, California, USA"
    },
    {
        "id": 330485,
        "value": "2470",
        "label": "Belvedere Tiburon, California, USA"
    },
    {
        "id": 329007,
        "value": "13387",
        "label": "Ben Lomond, California, USA"
    },
    {
        "id": 329022,
        "value": "1417",
        "label": "Benicia, California, USA"
    },
    {
        "id": 333016,
        "value": "60551",
        "label": "Benton, California, USA"
    },
    {
        "id": 333849,
        "value": "921",
        "label": "Berkeley, California, USA"
    },
    {
        "id": 333069,
        "value": "60638",
        "label": "Berry Creek, California, USA"
    },
    {
        "id": 331383,
        "value": "30082",
        "label": "Bethel Island, California, USA"
    },
    {
        "id": 329039,
        "value": "1474",
        "label": "Beverly Hills, California, USA"
    },
    {
        "id": 332152,
        "value": "48054",
        "label": "Bieber, California, USA"
    },
    {
        "id": 333081,
        "value": "60654",
        "label": "Big Bar, California, USA"
    },
    {
        "id": 331453,
        "value": "3346",
        "label": "Big Bear City, California, USA"
    },
    {
        "id": 330316,
        "value": "22876",
        "label": "Big Bear Lake, California, USA"
    },
    {
        "id": 331546,
        "value": "35703",
        "label": "Big Bend, California, USA"
    },
    {
        "id": 331384,
        "value": "30083",
        "label": "Big Oak Flat, California, USA"
    },
    {
        "id": 332397,
        "value": "5167",
        "label": "Big Pine, California, USA"
    },
    {
        "id": 331635,
        "value": "37939",
        "label": "Big Sur, California, USA"
    },
    {
        "id": 330266,
        "value": "2154",
        "label": "Biggs, California, USA"
    },
    {
        "id": 333025,
        "value": "60563",
        "label": "Biola, California, USA"
    },
    {
        "id": 332324,
        "value": "49437",
        "label": "Birds Landing, California, USA"
    },
    {
        "id": 328927,
        "value": "1085",
        "label": "Bishop, California, USA"
    },
    {
        "id": 331513,
        "value": "33801",
        "label": "Blairsden-graeagle, California, USA"
    },
    {
        "id": 333051,
        "value": "60609",
        "label": "Blocksburg, California, USA"
    },
    {
        "id": 333458,
        "value": "8682",
        "label": "Bloomington, California, USA"
    },
    {
        "id": 329057,
        "value": "14908",
        "label": "Blue Jay, California, USA"
    },
    {
        "id": 329028,
        "value": "1426",
        "label": "Blue Lake, California, USA"
    },
    {
        "id": 333894,
        "value": "990",
        "label": "Blythe, California, USA"
    },
    {
        "id": 333031,
        "value": "60577",
        "label": "Bodega, California, USA"
    },
    {
        "id": 329036,
        "value": "1434",
        "label": "Bodega Bay, California, USA"
    },
    {
        "id": 333010,
        "value": "60541",
        "label": "Bodfish, California, USA"
    },
    {
        "id": 330111,
        "value": "17641",
        "label": "Bolinas, California, USA"
    },
    {
        "id": 329162,
        "value": "17249",
        "label": "Bonita, California, USA"
    },
    {
        "id": 333383,
        "value": "7247",
        "label": "Bonsall, California, USA"
    },
    {
        "id": 329029,
        "value": "1427",
        "label": "Boonville, California, USA"
    },
    {
        "id": 330214,
        "value": "1993",
        "label": "Boron, California, USA"
    },
    {
        "id": 330218,
        "value": "1997",
        "label": "Borrego Springs, California, USA"
    },
    {
        "id": 333868,
        "value": "9385",
        "label": "Boulder Creek, California, USA"
    },
    {
        "id": 332325,
        "value": "49438",
        "label": "Boulevard, California, USA"
    },
    {
        "id": 331385,
        "value": "30084",
        "label": "Boyes Hot Springs, California, USA"
    },
    {
        "id": 330211,
        "value": "1991",
        "label": "Bradley, California, USA"
    },
    {
        "id": 333315,
        "value": "64870",
        "label": "Brandeis, California, USA"
    },
    {
        "id": 333042,
        "value": "60595",
        "label": "Branscomb, California, USA"
    },
    {
        "id": 330250,
        "value": "2062",
        "label": "Brawley, California, USA"
    },
    {
        "id": 331647,
        "value": "3851",
        "label": "Brea, California, USA"
    },
    {
        "id": 333857,
        "value": "9298",
        "label": "Brentwood, California, USA"
    },
    {
        "id": 330298,
        "value": "22230",
        "label": "Bridgeport, California, USA"
    },
    {
        "id": 329025,
        "value": "1423",
        "label": "Bridgeville, California, USA"
    },
    {
        "id": 331826,
        "value": "4373",
        "label": "Brisbane, California, USA"
    },
    {
        "id": 331386,
        "value": "30085",
        "label": "Brookdale, California, USA"
    },
    {
        "id": 331387,
        "value": "30086",
        "label": "Brooks, California, USA"
    },
    {
        "id": 332326,
        "value": "49439",
        "label": "Browns Valley, California, USA"
    },
    {
        "id": 333070,
        "value": "60639",
        "label": "Brownsville, California, USA"
    },
    {
        "id": 330246,
        "value": "20564",
        "label": "Buellton, California, USA"
    },
    {
        "id": 330208,
        "value": "1986",
        "label": "Buena Park, California, USA"
    },
    {
        "id": 328975,
        "value": "1239",
        "label": "Burbank, California, USA"
    },
    {
        "id": 331711,
        "value": "3902",
        "label": "Burlingame, California, USA"
    },
    {
        "id": 329067,
        "value": "15121",
        "label": "Burney, California, USA"
    },
    {
        "id": 333052,
        "value": "60610",
        "label": "Burnt Ranch, California, USA"
    },
    {
        "id": 329106,
        "value": "16112",
        "label": "Burrel, California, USA"
    },
    {
        "id": 333035,
        "value": "60584",
        "label": "Burson, California, USA"
    },
    {
        "id": 330280,
        "value": "2176",
        "label": "Butte City, California, USA"
    },
    {
        "id": 328924,
        "value": "1082",
        "label": "Buttonwillow, California, USA"
    },
    {
        "id": 333867,
        "value": "9383",
        "label": "Byron, California, USA"
    },
    {
        "id": 331694,
        "value": "38847",
        "label": "Cabazon, California, USA"
    },
    {
        "id": 332620,
        "value": "5538",
        "label": "Calabasas, California, USA"
    },
    {
        "id": 332162,
        "value": "4834",
        "label": "Calexico, California, USA"
    },
    {
        "id": 333017,
        "value": "60552",
        "label": "Caliente, California, USA"
    },
    {
        "id": 330210,
        "value": "1990",
        "label": "California City, California, USA"
    },
    {
        "id": 329101,
        "value": "16102",
        "label": "California Hot Springs, California, USA"
    },
    {
        "id": 331512,
        "value": "33800",
        "label": "Calimesa, California, USA"
    },
    {
        "id": 329062,
        "value": "14954",
        "label": "Calipatria, California, USA"
    },
    {
        "id": 333892,
        "value": "9792",
        "label": "Calistoga, California, USA"
    },
    {
        "id": 333082,
        "value": "60655",
        "label": "Callahan, California, USA"
    },
    {
        "id": 331388,
        "value": "30087",
        "label": "Calpella, California, USA"
    },
    {
        "id": 333106,
        "value": "60690",
        "label": "Calpine, California, USA"
    },
    {
        "id": 330248,
        "value": "2058",
        "label": "Camarillo, California, USA"
    },
    {
        "id": 329060,
        "value": "1494",
        "label": "Cambria, California, USA"
    },
    {
        "id": 330333,
        "value": "23499",
        "label": "Camino, California, USA"
    },
    {
        "id": 333043,
        "value": "60596",
        "label": "Camp Meeker, California, USA"
    },
    {
        "id": 331389,
        "value": "30088",
        "label": "Camp Pendleton, California, USA"
    },
    {
        "id": 330143,
        "value": "1852",
        "label": "Campbell, California, USA"
    },
    {
        "id": 330251,
        "value": "2063",
        "label": "Campo, California, USA"
    },
    {
        "id": 331460,
        "value": "33706",
        "label": "Camptonville, California, USA"
    },
    {
        "id": 333083,
        "value": "60656",
        "label": "Canby, California, USA"
    },
    {
        "id": 333444,
        "value": "850",
        "label": "Canoga Park, California, USA"
    },
    {
        "id": 333018,
        "value": "60553",
        "label": "Cantil, California, USA"
    },
    {
        "id": 333873,
        "value": "9475",
        "label": "Canyon, California, USA"
    },
    {
        "id": 330334,
        "value": "23500",
        "label": "Canyon Country, California, USA"
    },
    {
        "id": 332217,
        "value": "49255",
        "label": "Canyon Dam, California, USA"
    },
    {
        "id": 332210,
        "value": "48935",
        "label": "Capay, California, USA"
    },
    {
        "id": 329150,
        "value": "16885",
        "label": "Capistrano Beach, California, USA"
    },
    {
        "id": 328950,
        "value": "1192",
        "label": "Capitola, California, USA"
    },
    {
        "id": 333891,
        "value": "9783",
        "label": "Cardiff By The Sea, California, USA"
    },
    {
        "id": 333053,
        "value": "60611",
        "label": "Carlotta, California, USA"
    },
    {
        "id": 331334,
        "value": "290",
        "label": "Carlsbad, California, USA"
    },
    {
        "id": 331417,
        "value": "3157",
        "label": "Carmel, California, USA"
    },
    {
        "id": 332218,
        "value": "49256",
        "label": "Carmel By The Sea, California, USA"
    },
    {
        "id": 333470,
        "value": "8934",
        "label": "Carmel Valley, California, USA"
    },
    {
        "id": 329097,
        "value": "1608",
        "label": "Carmichael, California, USA"
    },
    {
        "id": 332004,
        "value": "47303",
        "label": "Carnelian Bay, California, USA"
    },
    {
        "id": 332368,
        "value": "5084",
        "label": "Carpinteria, California, USA"
    },
    {
        "id": 330947,
        "value": "2619",
        "label": "Carson, California, USA"
    },
    {
        "id": 329103,
        "value": "16107",
        "label": "Caruthers, California, USA"
    },
    {
        "id": 333014,
        "value": "60549",
        "label": "Casmalia, California, USA"
    },
    {
        "id": 333044,
        "value": "60597",
        "label": "Caspar, California, USA"
    },
    {
        "id": 333084,
        "value": "60657",
        "label": "Cassel, California, USA"
    },
    {
        "id": 333885,
        "value": "9596",
        "label": "Castaic, California, USA"
    },
    {
        "id": 333085,
        "value": "60658",
        "label": "Castella, California, USA"
    },
    {
        "id": 333472,
        "value": "9000",
        "label": "Castro Valley, California, USA"
    },
    {
        "id": 333347,
        "value": "6549",
        "label": "Castroville, California, USA"
    },
    {
        "id": 332629,
        "value": "5655",
        "label": "Cathedral City, California, USA"
    },
    {
        "id": 332327,
        "value": "49440",
        "label": "Catheys Valley, California, USA"
    },
    {
        "id": 331634,
        "value": "37938",
        "label": "Cayucos, California, USA"
    },
    {
        "id": 331310,
        "value": "2688",
        "label": "Cazadero, California, USA"
    },
    {
        "id": 331390,
        "value": "30089",
        "label": "Cedar Glen, California, USA"
    },
    {
        "id": 333072,
        "value": "60640",
        "label": "Cedar Ridge, California, USA"
    },
    {
        "id": 333281,
        "value": "61266",
        "label": "Cedarpines Park, California, USA"
    },
    {
        "id": 332015,
        "value": "47467",
        "label": "Cedarville, California, USA"
    },
    {
        "id": 333876,
        "value": "9534",
        "label": "Ceres, California, USA"
    },
    {
        "id": 331333,
        "value": "2854",
        "label": "Cerritos, California, USA"
    },
    {
        "id": 330276,
        "value": "2168",
        "label": "Challenge, California, USA"
    },
    {
        "id": 331825,
        "value": "4369",
        "label": "Chatsworth, California, USA"
    },
    {
        "id": 331391,
        "value": "30090",
        "label": "Chester, California, USA"
    },
    {
        "id": 331735,
        "value": "40522",
        "label": "Chicago Park, California, USA"
    },
    {
        "id": 333373,
        "value": "715",
        "label": "Chico, California, USA"
    },
    {
        "id": 333098,
        "value": "60681",
        "label": "Chilcoot, California, USA"
    },
    {
        "id": 330284,
        "value": "2181",
        "label": "Chinese Camp, California, USA"
    },
    {
        "id": 332625,
        "value": "5578",
        "label": "Chino, California, USA"
    },
    {
        "id": 332624,
        "value": "5548",
        "label": "Chino Hills, California, USA"
    },
    {
        "id": 330274,
        "value": "2165",
        "label": "Chowchilla, California, USA"
    },
    {
        "id": 330125,
        "value": "18039",
        "label": "Chualar, California, USA"
    },
    {
        "id": 328930,
        "value": "1111",
        "label": "Chula Vista, California, USA"
    },
    {
        "id": 332365,
        "value": "5075",
        "label": "Citrus Heights, California, USA"
    },
    {
        "id": 332619,
        "value": "5509",
        "label": "City Of Industry, California, USA"
    },
    {
        "id": 331421,
        "value": "3206",
        "label": "Claremont, California, USA"
    },
    {
        "id": 331997,
        "value": "47117",
        "label": "Clarksburg, California, USA"
    },
    {
        "id": 330230,
        "value": "2017",
        "label": "Clayton, California, USA"
    },
    {
        "id": 329110,
        "value": "16118",
        "label": "Clearlake, California, USA"
    },
    {
        "id": 333386,
        "value": "7323",
        "label": "Clearlake Oaks, California, USA"
    },
    {
        "id": 332328,
        "value": "49441",
        "label": "Clearlake Park, California, USA"
    },
    {
        "id": 329124,
        "value": "16460",
        "label": "Clements, California, USA"
    },
    {
        "id": 333279,
        "value": "61251",
        "label": "Clio, California, USA"
    },
    {
        "id": 333073,
        "value": "60641",
        "label": "Clipper Mills, California, USA"
    },
    {
        "id": 329024,
        "value": "1421",
        "label": "Cloverdale, California, USA"
    },
    {
        "id": 333419,
        "value": "819",
        "label": "Clovis, California, USA"
    },
    {
        "id": 333402,
        "value": "7868",
        "label": "Coachella, California, USA"
    },
    {
        "id": 330279,
        "value": "2175",
        "label": "Coalinga, California, USA"
    },
    {
        "id": 332000,
        "value": "47126",
        "label": "Coarsegold, California, USA"
    },
    {
        "id": 330641,
        "value": "25561",
        "label": "Cobb, California, USA"
    },
    {
        "id": 331392,
        "value": "30091",
        "label": "Coleville, California, USA"
    },
    {
        "id": 331328,
        "value": "2721",
        "label": "Colfax, California, USA"
    },
    {
        "id": 331709,
        "value": "38985",
        "label": "Coloma, California, USA"
    },
    {
        "id": 333887,
        "value": "968",
        "label": "Colton, California, USA"
    },
    {
        "id": 331645,
        "value": "38477",
        "label": "Columbia, California, USA"
    },
    {
        "id": 333318,
        "value": "6489",
        "label": "Colusa, California, USA"
    },
    {
        "id": 333045,
        "value": "60598",
        "label": "Comptche, California, USA"
    },
    {
        "id": 333853,
        "value": "926",
        "label": "Compton, California, USA"
    },
    {
        "id": 333416,
        "value": "816",
        "label": "Concord, California, USA"
    },
    {
        "id": 333264,
        "value": "61114",
        "label": "Cool, California, USA"
    },
    {
        "id": 333367,
        "value": "7069",
        "label": "Copperopolis, California, USA"
    },
    {
        "id": 329109,
        "value": "16117",
        "label": "Corcoran, California, USA"
    },
    {
        "id": 331312,
        "value": "2691",
        "label": "Corning, California, USA"
    },
    {
        "id": 329058,
        "value": "1491",
        "label": "Corona, California, USA"
    },
    {
        "id": 330335,
        "value": "23501",
        "label": "Corona Del Mar, California, USA"
    },
    {
        "id": 333436,
        "value": "8370",
        "label": "Coronado, California, USA"
    },
    {
        "id": 331315,
        "value": "2695",
        "label": "Corte Madera, California, USA"
    },
    {
        "id": 331428,
        "value": "3298",
        "label": "Costa Mesa, California, USA"
    },
    {
        "id": 331974,
        "value": "46435",
        "label": "Cotati, California, USA"
    },
    {
        "id": 331320,
        "value": "2709",
        "label": "Cottonwood, California, USA"
    },
    {
        "id": 331616,
        "value": "37904",
        "label": "Coulterville, California, USA"
    },
    {
        "id": 333260,
        "value": "61103",
        "label": "Courtland, California, USA"
    },
    {
        "id": 330429,
        "value": "2454",
        "label": "Covelo, California, USA"
    },
    {
        "id": 331535,
        "value": "351",
        "label": "Covina, California, USA"
    },
    {
        "id": 333033,
        "value": "60581",
        "label": "Coyote, California, USA"
    },
    {
        "id": 333463,
        "value": "878",
        "label": "Crescent City, California, USA"
    },
    {
        "id": 333040,
        "value": "60593",
        "label": "Cressey, California, USA"
    },
    {
        "id": 330942,
        "value": "260827",
        "label": "Crest Park, California, USA"
    },
    {
        "id": 332359,
        "value": "5066",
        "label": "Crestline, California, USA"
    },
    {
        "id": 332145,
        "value": "47892",
        "label": "Creston, California, USA"
    },
    {
        "id": 329126,
        "value": "16516",
        "label": "Crockett, California, USA"
    },
    {
        "id": 330265,
        "value": "2153",
        "label": "Crows Landing, California, USA"
    },
    {
        "id": 330205,
        "value": "1981",
        "label": "Culver City, California, USA"
    },
    {
        "id": 331533,
        "value": "3505",
        "label": "Cupertino, California, USA"
    },
    {
        "id": 331963,
        "value": "46277",
        "label": "Cutler, California, USA"
    },
    {
        "id": 333049,
        "value": "60607",
        "label": "Cutten, California, USA"
    },
    {
        "id": 330207,
        "value": "1983",
        "label": "Cypress, California, USA"
    },
    {
        "id": 331633,
        "value": "37936",
        "label": "Daggett, California, USA"
    },
    {
        "id": 333454,
        "value": "8612",
        "label": "Daly City, California, USA"
    },
    {
        "id": 333365,
        "value": "6900",
        "label": "Dana Point, California, USA"
    },
    {
        "id": 329032,
        "value": "1429",
        "label": "Danville, California, USA"
    },
    {
        "id": 332184,
        "value": "48691",
        "label": "Davenport, California, USA"
    },
    {
        "id": 330285,
        "value": "2183",
        "label": "Davis, California, USA"
    },
    {
        "id": 330642,
        "value": "25563",
        "label": "Death Valley, California, USA"
    },
    {
        "id": 329020,
        "value": "13935",
        "label": "Deer Park, California, USA"
    },
    {
        "id": 330317,
        "value": "2288",
        "label": "Del Mar, California, USA"
    },
    {
        "id": 331904,
        "value": "45262",
        "label": "Del Rey, California, USA"
    },
    {
        "id": 333874,
        "value": "9480",
        "label": "Delano, California, USA"
    },
    {
        "id": 331601,
        "value": "36864",
        "label": "Delhi, California, USA"
    },
    {
        "id": 331575,
        "value": "36049",
        "label": "Denair, California, USA"
    },
    {
        "id": 331961,
        "value": "46257",
        "label": "Descanso, California, USA"
    },
    {
        "id": 330234,
        "value": "2018",
        "label": "Desert Center, California, USA"
    },
    {
        "id": 330212,
        "value": "19913",
        "label": "Desert Hot Springs, California, USA"
    },
    {
        "id": 333417,
        "value": "8171",
        "label": "Diablo, California, USA"
    },
    {
        "id": 330226,
        "value": "2009",
        "label": "Diamond Bar, California, USA"
    },
    {
        "id": 331760,
        "value": "41485",
        "label": "Diamond Springs, California, USA"
    },
    {
        "id": 332330,
        "value": "49443",
        "label": "Dillon Beach, California, USA"
    },
    {
        "id": 330293,
        "value": "2191",
        "label": "Dinuba, California, USA"
    },
    {
        "id": 332331,
        "value": "49444",
        "label": "Discovery Bay, California, USA"
    },
    {
        "id": 328918,
        "value": "10516",
        "label": "Dixon, California, USA"
    },
    {
        "id": 332014,
        "value": "47453",
        "label": "Dobbins, California, USA"
    },
    {
        "id": 333848,
        "value": "9204",
        "label": "Dorris, California, USA"
    },
    {
        "id": 333467,
        "value": "8849",
        "label": "Dos Palos, California, USA"
    },
    {
        "id": 333046,
        "value": "60599",
        "label": "Dos Rios, California, USA"
    },
    {
        "id": 333086,
        "value": "60659",
        "label": "Douglas City, California, USA"
    },
    {
        "id": 333037,
        "value": "60586",
        "label": "Douglas Flat, California, USA"
    },
    {
        "id": 330228,
        "value": "2014",
        "label": "Downey, California, USA"
    },
    {
        "id": 331317,
        "value": "2702",
        "label": "Downieville, California, USA"
    },
    {
        "id": 333100,
        "value": "60683",
        "label": "Doyle, California, USA"
    },
    {
        "id": 333064,
        "value": "60633",
        "label": "Drytown, California, USA"
    },
    {
        "id": 331957,
        "value": "4615",
        "label": "Duarte, California, USA"
    },
    {
        "id": 333847,
        "value": "920",
        "label": "Dublin, California, USA"
    },
    {
        "id": 331648,
        "value": "38548",
        "label": "Ducor, California, USA"
    },
    {
        "id": 332998,
        "value": "60519",
        "label": "Dulzura, California, USA"
    },
    {
        "id": 333047,
        "value": "60600",
        "label": "Duncans Mills, California, USA"
    },
    {
        "id": 333027,
        "value": "60565",
        "label": "Dunlap, California, USA"
    },
    {
        "id": 331325,
        "value": "2717",
        "label": "Dunnigan, California, USA"
    },
    {
        "id": 331318,
        "value": "2705",
        "label": "Dunsmuir, California, USA"
    },
    {
        "id": 331393,
        "value": "30092",
        "label": "Durham, California, USA"
    },
    {
        "id": 330254,
        "value": "20699",
        "label": "Dutch Flat, California, USA"
    },
    {
        "id": 329061,
        "value": "1495",
        "label": "Earlimart, California, USA"
    },
    {
        "id": 330643,
        "value": "25564",
        "label": "Earp, California, USA"
    },
    {
        "id": 331394,
        "value": "30093",
        "label": "East Irvine, California, USA"
    },
    {
        "id": 331706,
        "value": "38920",
        "label": "Edison, California, USA"
    },
    {
        "id": 329048,
        "value": "1479",
        "label": "Edwards, California, USA"
    },
    {
        "id": 328931,
        "value": "1113",
        "label": "El Cajon, California, USA"
    },
    {
        "id": 329063,
        "value": "1496",
        "label": "El Centro, California, USA"
    },
    {
        "id": 330229,
        "value": "2015",
        "label": "El Cerrito, California, USA"
    },
    {
        "id": 330402,
        "value": "2448",
        "label": "El Dorado, California, USA"
    },
    {
        "id": 332939,
        "value": "6023",
        "label": "El Dorado Hills, California, USA"
    },
    {
        "id": 329174,
        "value": "17539",
        "label": "El Granada, California, USA"
    },
    {
        "id": 333852,
        "value": "925",
        "label": "El Monte, California, USA"
    },
    {
        "id": 332332,
        "value": "49445",
        "label": "El Nido, California, USA"
    },
    {
        "id": 331945,
        "value": "45845",
        "label": "El Portal, California, USA"
    },
    {
        "id": 330237,
        "value": "2021",
        "label": "El Segundo, California, USA"
    },
    {
        "id": 333859,
        "value": "9317",
        "label": "El Sobrante, California, USA"
    },
    {
        "id": 331734,
        "value": "40511",
        "label": "Eldridge, California, USA"
    },
    {
        "id": 329021,
        "value": "1416",
        "label": "Elk, California, USA"
    },
    {
        "id": 331461,
        "value": "33709",
        "label": "Elk Creek, California, USA"
    },
    {
        "id": 331637,
        "value": "3802",
        "label": "Elk Grove, California, USA"
    },
    {
        "id": 331542,
        "value": "35249",
        "label": "Elmira, California, USA"
    },
    {
        "id": 331395,
        "value": "30094",
        "label": "Elverta, California, USA"
    },
    {
        "id": 331590,
        "value": "3674",
        "label": "Emeryville, California, USA"
    },
    {
        "id": 332333,
        "value": "49446",
        "label": "Emigrant Gap, California, USA"
    },
    {
        "id": 331396,
        "value": "30095",
        "label": "Empire, California, USA"
    },
    {
        "id": 333110,
        "value": "6079",
        "label": "Encinitas, California, USA"
    },
    {
        "id": 332476,
        "value": "5437",
        "label": "Encino, California, USA"
    },
    {
        "id": 331326,
        "value": "2719",
        "label": "Escalon, California, USA"
    },
    {
        "id": 330220,
        "value": "1999",
        "label": "Escondido, California, USA"
    },
    {
        "id": 331329,
        "value": "2722",
        "label": "Esparto, California, USA"
    },
    {
        "id": 331761,
        "value": "41528",
        "label": "Etna, California, USA"
    },
    {
        "id": 330314,
        "value": "2286",
        "label": "Eureka, California, USA"
    },
    {
        "id": 329098,
        "value": "16097",
        "label": "Exeter, California, USA"
    },
    {
        "id": 330271,
        "value": "2160",
        "label": "Fair Oaks, California, USA"
    },
    {
        "id": 333425,
        "value": "8232",
        "label": "Fairfax, California, USA"
    },
    {
        "id": 328955,
        "value": "1223",
        "label": "Fairfield, California, USA"
    },
    {
        "id": 331719,
        "value": "39259",
        "label": "Fall River Mills, California, USA"
    },
    {
        "id": 333862,
        "value": "9333",
        "label": "Fallbrook, California, USA"
    },
    {
        "id": 329099,
        "value": "16098",
        "label": "Farmersville, California, USA"
    },
    {
        "id": 331618,
        "value": "37906",
        "label": "Farmington, California, USA"
    },
    {
        "id": 333006,
        "value": "60532",
        "label": "Fawnskin, California, USA"
    },
    {
        "id": 332334,
        "value": "49447",
        "label": "Fellows, California, USA"
    },
    {
        "id": 329019,
        "value": "13927",
        "label": "Felton, California, USA"
    },
    {
        "id": 331581,
        "value": "36173",
        "label": "Ferndale, California, USA"
    },
    {
        "id": 331397,
        "value": "30096",
        "label": "Fiddletown, California, USA"
    },
    {
        "id": 333054,
        "value": "60613",
        "label": "Fields Landing, California, USA"
    },
    {
        "id": 331398,
        "value": "30097",
        "label": "Fillmore, California, USA"
    },
    {
        "id": 332335,
        "value": "49448",
        "label": "Finley, California, USA"
    },
    {
        "id": 330292,
        "value": "2190",
        "label": "Firebaugh, California, USA"
    },
    {
        "id": 332336,
        "value": "49449",
        "label": "Fish Camp, California, USA"
    },
    {
        "id": 330336,
        "value": "23502",
        "label": "Five Points, California, USA"
    },
    {
        "id": 330273,
        "value": "2162",
        "label": "Folsom, California, USA"
    },
    {
        "id": 333431,
        "value": "8302",
        "label": "Fontana, California, USA"
    },
    {
        "id": 330337,
        "value": "23503",
        "label": "Foothill Ranch, California, USA"
    },
    {
        "id": 331399,
        "value": "30098",
        "label": "Forbestown, California, USA"
    },
    {
        "id": 331959,
        "value": "46202",
        "label": "Forest Falls, California, USA"
    },
    {
        "id": 329009,
        "value": "13600",
        "label": "Forest Knolls, California, USA"
    },
    {
        "id": 333429,
        "value": "8283",
        "label": "Forest Ranch, California, USA"
    },
    {
        "id": 333412,
        "value": "8042",
        "label": "Foresthill, California, USA"
    },
    {
        "id": 330151,
        "value": "18576",
        "label": "Forestville, California, USA"
    },
    {
        "id": 333335,
        "value": "65080",
        "label": "Forks Of Salmon, California, USA"
    },
    {
        "id": 330533,
        "value": "248376",
        "label": "Fort Dick, California, USA"
    },
    {
        "id": 331400,
        "value": "30099",
        "label": "Fortuna, California, USA"
    },
    {
        "id": 330394,
        "value": "244714",
        "label": "Foster City, California, USA"
    },
    {
        "id": 329113,
        "value": "1623",
        "label": "Fountain Valley, California, USA"
    },
    {
        "id": 330338,
        "value": "23504",
        "label": "Fowler, California, USA"
    },
    {
        "id": 329159,
        "value": "17176",
        "label": "Frazier Park, California, USA"
    },
    {
        "id": 333860,
        "value": "9321",
        "label": "Freedom, California, USA"
    },
    {
        "id": 333421,
        "value": "821",
        "label": "Fremont, California, USA"
    },
    {
        "id": 331972,
        "value": "46396",
        "label": "French Camp, California, USA"
    },
    {
        "id": 331622,
        "value": "37915",
        "label": "French Gulch, California, USA"
    },
    {
        "id": 330179,
        "value": "19",
        "label": "Fresno, California, USA"
    },
    {
        "id": 329810,
        "value": "17609",
        "label": "Friant, California, USA"
    },
    {
        "id": 330476,
        "value": "2467",
        "label": "Ft Bragg, California, USA"
    },
    {
        "id": 328914,
        "value": "1039",
        "label": "Ft Irwin, California, USA"
    },
    {
        "id": 332337,
        "value": "49450",
        "label": "Ft Jones, California, USA"
    },
    {
        "id": 333423,
        "value": "822",
        "label": "Fullerton, California, USA"
    },
    {
        "id": 333865,
        "value": "9372",
        "label": "Fulton, California, USA"
    },
    {
        "id": 329077,
        "value": "15346",
        "label": "Galt, California, USA"
    },
    {
        "id": 330422,
        "value": "2452",
        "label": "Garberville, California, USA"
    },
    {
        "id": 330206,
        "value": "1982",
        "label": "Garden Grove, California, USA"
    },
    {
        "id": 332149,
        "value": "47949",
        "label": "Garden Valley, California, USA"
    },
    {
        "id": 328951,
        "value": "1193",
        "label": "Gardena, California, USA"
    },
    {
        "id": 333055,
        "value": "60614",
        "label": "Gasquet, California, USA"
    },
    {
        "id": 331623,
        "value": "37916",
        "label": "Gazelle, California, USA"
    },
    {
        "id": 331624,
        "value": "37917",
        "label": "Georgetown, California, USA"
    },
    {
        "id": 332205,
        "value": "48823",
        "label": "Gerber, California, USA"
    },
    {
        "id": 330134,
        "value": "18250",
        "label": "Geyserville, California, USA"
    },
    {
        "id": 333411,
        "value": "802",
        "label": "Gilroy, California, USA"
    },
    {
        "id": 333890,
        "value": "9743",
        "label": "Glen Ellen, California, USA"
    },
    {
        "id": 333038,
        "value": "60587",
        "label": "Glencoe, California, USA"
    },
    {
        "id": 333855,
        "value": "928",
        "label": "Glendale, California, USA"
    },
    {
        "id": 333453,
        "value": "8602",
        "label": "Glendora, California, USA"
    },
    {
        "id": 332384,
        "value": "51132",
        "label": "Glenhaven, California, USA"
    },
    {
        "id": 332003,
        "value": "47271",
        "label": "Glenn, California, USA"
    },
    {
        "id": 331631,
        "value": "37930",
        "label": "Glennville, California, USA"
    },
    {
        "id": 333065,
        "value": "60634",
        "label": "Gold Run, California, USA"
    },
    {
        "id": 333108,
        "value": "6070",
        "label": "Goleta, California, USA"
    },
    {
        "id": 331636,
        "value": "37940",
        "label": "Gonzales, California, USA"
    },
    {
        "id": 332020,
        "value": "47561",
        "label": "Goshen, California, USA"
    },
    {
        "id": 333409,
        "value": "8002",
        "label": "Granada Hills, California, USA"
    },
    {
        "id": 330172,
        "value": "18794",
        "label": "Grand Terrace, California, USA"
    },
    {
        "id": 332338,
        "value": "49451",
        "label": "Granite Bay, California, USA"
    },
    {
        "id": 330281,
        "value": "2178",
        "label": "Grass Valley, California, USA"
    },
    {
        "id": 332339,
        "value": "49452",
        "label": "Graton, California, USA"
    },
    {
        "id": 332340,
        "value": "49453",
        "label": "Green Valley Lake, California, USA"
    },
    {
        "id": 330339,
        "value": "23506",
        "label": "Greenbrae, California, USA"
    },
    {
        "id": 331674,
        "value": "38776",
        "label": "Greenfield, California, USA"
    },
    {
        "id": 333881,
        "value": "9560",
        "label": "Greenville, California, USA"
    },
    {
        "id": 330255,
        "value": "20700",
        "label": "Greenwood, California, USA"
    },
    {
        "id": 330340,
        "value": "23507",
        "label": "Grenada, California, USA"
    },
    {
        "id": 333469,
        "value": "8906",
        "label": "Gridley, California, USA"
    },
    {
        "id": 332341,
        "value": "49454",
        "label": "Grimes, California, USA"
    },
    {
        "id": 331401,
        "value": "30100",
        "label": "Grizzly Flats, California, USA"
    },
    {
        "id": 333385,
        "value": "7319",
        "label": "Groveland, California, USA"
    },
    {
        "id": 333858,
        "value": "9304",
        "label": "Grover Beach, California, USA"
    },
    {
        "id": 329160,
        "value": "17178",
        "label": "Guadalupe, California, USA"
    },
    {
        "id": 329023,
        "value": "1418",
        "label": "Gualala, California, USA"
    },
    {
        "id": 332997,
        "value": "60518",
        "label": "Guasti, California, USA"
    },
    {
        "id": 332999,
        "value": "60520",
        "label": "Guatay, California, USA"
    },
    {
        "id": 329038,
        "value": "1466",
        "label": "Guerneville, California, USA"
    },
    {
        "id": 331720,
        "value": "39260",
        "label": "Guinda, California, USA"
    },
    {
        "id": 331602,
        "value": "36865",
        "label": "Gustine, California, USA"
    },
    {
        "id": 331822,
        "value": "4353",
        "label": "Hacienda Heights, California, USA"
    },
    {
        "id": 329092,
        "value": "1575",
        "label": "Half Moon Bay, California, USA"
    },
    {
        "id": 333077,
        "value": "60645",
        "label": "Hamilton City, California, USA"
    },
    {
        "id": 333879,
        "value": "9556",
        "label": "Hanford, California, USA"
    },
    {
        "id": 331514,
        "value": "33802",
        "label": "Happy Camp, California, USA"
    },
    {
        "id": 333448,
        "value": "8556",
        "label": "Harbor City, California, USA"
    },
    {
        "id": 333015,
        "value": "60550",
        "label": "Harmony, California, USA"
    },
    {
        "id": 331860,
        "value": "44583",
        "label": "Hat Creek, California, USA"
    },
    {
        "id": 333039,
        "value": "60588",
        "label": "Hathaway Pines, California, USA"
    },
    {
        "id": 331956,
        "value": "46077",
        "label": "Hawaiian Gardens, California, USA"
    },
    {
        "id": 330236,
        "value": "2019",
        "label": "Hawthorne, California, USA"
    },
    {
        "id": 330289,
        "value": "2188",
        "label": "Hayfork, California, USA"
    },
    {
        "id": 330154,
        "value": "1863",
        "label": "Hayward, California, USA"
    },
    {
        "id": 333471,
        "value": "8981",
        "label": "Healdsburg, California, USA"
    },
    {
        "id": 331582,
        "value": "36174",
        "label": "Heber, California, USA"
    },
    {
        "id": 331675,
        "value": "38777",
        "label": "Helendale, California, USA"
    },
    {
        "id": 332181,
        "value": "48663",
        "label": "Helm, California, USA"
    },
    {
        "id": 328915,
        "value": "1045",
        "label": "Hemet, California, USA"
    },
    {
        "id": 331619,
        "value": "37907",
        "label": "Herald, California, USA"
    },
    {
        "id": 332399,
        "value": "5189",
        "label": "Hercules, California, USA"
    },
    {
        "id": 328949,
        "value": "11825",
        "label": "Herlong, California, USA"
    },
    {
        "id": 328909,
        "value": "1000",
        "label": "Hermosa Beach, California, USA"
    },
    {
        "id": 332627,
        "value": "5641",
        "label": "Hesperia, California, USA"
    },
    {
        "id": 333854,
        "value": "9269",
        "label": "Hickman, California, USA"
    },
    {
        "id": 332342,
        "value": "49455",
        "label": "Hidden Valley Lake, California, USA"
    },
    {
        "id": 333888,
        "value": "9693",
        "label": "Highland, California, USA"
    },
    {
        "id": 330182,
        "value": "19144",
        "label": "Hilmar, California, USA"
    },
    {
        "id": 333007,
        "value": "60533",
        "label": "Hinkley, California, USA"
    },
    {
        "id": 331316,
        "value": "2701",
        "label": "Hollister, California, USA"
    },
    {
        "id": 333258,
        "value": "61088",
        "label": "Holt, California, USA"
    },
    {
        "id": 333882,
        "value": "9563",
        "label": "Holtville, California, USA"
    },
    {
        "id": 330203,
        "value": "19779",
        "label": "Homeland, California, USA"
    },
    {
        "id": 331330,
        "value": "2723",
        "label": "Homewood, California, USA"
    },
    {
        "id": 330287,
        "value": "2185",
        "label": "Hoopa, California, USA"
    },
    {
        "id": 331958,
        "value": "46201",
        "label": "Hopland, California, USA"
    },
    {
        "id": 331625,
        "value": "37918",
        "label": "Hornbrook, California, USA"
    },
    {
        "id": 332165,
        "value": "48342",
        "label": "Hornitos, California, USA"
    },
    {
        "id": 331322,
        "value": "2714",
        "label": "Hughson, California, USA"
    },
    {
        "id": 332005,
        "value": "47306",
        "label": "Hume, California, USA"
    },
    {
        "id": 332630,
        "value": "570",
        "label": "Huntington Beach, California, USA"
    },
    {
        "id": 333439,
        "value": "8418",
        "label": "Huntington Park, California, USA"
    },
    {
        "id": 331319,
        "value": "2707",
        "label": "Huron, California, USA"
    },
    {
        "id": 332182,
        "value": "48665",
        "label": "Hyampom, California, USA"
    },
    {
        "id": 329030,
        "value": "1428",
        "label": "Hydesville, California, USA"
    },
    {
        "id": 330169,
        "value": "1877",
        "label": "Idyllwild, California, USA"
    },
    {
        "id": 331587,
        "value": "36193",
        "label": "Igo, California, USA"
    },
    {
        "id": 333334,
        "value": "6508",
        "label": "Imperial, California, USA"
    },
    {
        "id": 333883,
        "value": "9565",
        "label": "Imperial Beach, California, USA"
    },
    {
        "id": 331577,
        "value": "36167",
        "label": "Independence, California, USA"
    },
    {
        "id": 329055,
        "value": "14893",
        "label": "Indian Wells, California, USA"
    },
    {
        "id": 331419,
        "value": "318",
        "label": "Indio, California, USA"
    },
    {
        "id": 333445,
        "value": "851",
        "label": "Inglewood, California, USA"
    },
    {
        "id": 328954,
        "value": "1221",
        "label": "Inverness, California, USA"
    },
    {
        "id": 331857,
        "value": "44535",
        "label": "Inyokern, California, USA"
    },
    {
        "id": 329789,
        "value": "17607",
        "label": "Ione, California, USA"
    },
    {
        "id": 328978,
        "value": "124",
        "label": "Irvine, California, USA"
    },
    {
        "id": 331462,
        "value": "33716",
        "label": "Isleton, California, USA"
    },
    {
        "id": 331515,
        "value": "33803",
        "label": "Ivanhoe, California, USA"
    },
    {
        "id": 330275,
        "value": "2167",
        "label": "Jackson, California, USA"
    },
    {
        "id": 330170,
        "value": "1879",
        "label": "Jacumba, California, USA"
    },
    {
        "id": 331617,
        "value": "37905",
        "label": "Jamestown, California, USA"
    },
    {
        "id": 330257,
        "value": "20728",
        "label": "Jamul, California, USA"
    },
    {
        "id": 333267,
        "value": "61136",
        "label": "Janesville, California, USA"
    },
    {
        "id": 331402,
        "value": "30101",
        "label": "Jenner, California, USA"
    },
    {
        "id": 333020,
        "value": "60555",
        "label": "Johannesburg, California, USA"
    },
    {
        "id": 332343,
        "value": "49456",
        "label": "Jolon, California, USA"
    },
    {
        "id": 333426,
        "value": "824",
        "label": "Joshua Tree, California, USA"
    },
    {
        "id": 329047,
        "value": "1478",
        "label": "Julian, California, USA"
    },
    {
        "id": 333090,
        "value": "60662",
        "label": "Junction City, California, USA"
    },
    {
        "id": 330644,
        "value": "25565",
        "label": "June Lake, California, USA"
    },
    {
        "id": 333021,
        "value": "60556",
        "label": "Keeler, California, USA"
    },
    {
        "id": 332344,
        "value": "49457",
        "label": "Keene, California, USA"
    },
    {
        "id": 329033,
        "value": "1430",
        "label": "Kelseyville, California, USA"
    },
    {
        "id": 331516,
        "value": "33804",
        "label": "Kenwood, California, USA"
    },
    {
        "id": 329108,
        "value": "16116",
        "label": "Kerman, California, USA"
    },
    {
        "id": 330645,
        "value": "25566",
        "label": "Kernville, California, USA"
    },
    {
        "id": 332345,
        "value": "49458",
        "label": "Kettleman City, California, USA"
    },
    {
        "id": 332346,
        "value": "49459",
        "label": "Keyes, California, USA"
    },
    {
        "id": 331309,
        "value": "2687",
        "label": "King City, California, USA"
    },
    {
        "id": 331463,
        "value": "33719",
        "label": "Kings Beach, California, USA"
    },
    {
        "id": 331628,
        "value": "37925",
        "label": "Kings Canyon National Pk, California, USA"
    },
    {
        "id": 333109,
        "value": "6073",
        "label": "Kingsburg, California, USA"
    },
    {
        "id": 333062,
        "value": "60625",
        "label": "Kirkwood, California, USA"
    },
    {
        "id": 330259,
        "value": "20929",
        "label": "Klamath, California, USA"
    },
    {
        "id": 332347,
        "value": "49460",
        "label": "Klamath River, California, USA"
    },
    {
        "id": 329125,
        "value": "16515",
        "label": "Kneeland, California, USA"
    },
    {
        "id": 331626,
        "value": "37919",
        "label": "Knights Landing, California, USA"
    },
    {
        "id": 331579,
        "value": "36169",
        "label": "Knightsen, California, USA"
    },
    {
        "id": 333056,
        "value": "60615",
        "label": "Korbel, California, USA"
    },
    {
        "id": 333869,
        "value": "9389",
        "label": "La Canada Flintridge, California, USA"
    },
    {
        "id": 331413,
        "value": "3070",
        "label": "La Crescenta, California, USA"
    },
    {
        "id": 331917,
        "value": "45523",
        "label": "La Grange, California, USA"
    },
    {
        "id": 333432,
        "value": "8303",
        "label": "La Habra, California, USA"
    },
    {
        "id": 328947,
        "value": "11797",
        "label": "La Honda, California, USA"
    },
    {
        "id": 330164,
        "value": "1873",
        "label": "La Jolla, California, USA"
    },
    {
        "id": 330256,
        "value": "2072",
        "label": "La Mesa, California, USA"
    },
    {
        "id": 331288,
        "value": "2642",
        "label": "La Mirada, California, USA"
    },
    {
        "id": 333430,
        "value": "8301",
        "label": "La Palma, California, USA"
    },
    {
        "id": 330222,
        "value": "2004",
        "label": "La Puente, California, USA"
    },
    {
        "id": 331823,
        "value": "4359",
        "label": "La Quinta, California, USA"
    },
    {
        "id": 328917,
        "value": "10493",
        "label": "La Verne, California, USA"
    },
    {
        "id": 331403,
        "value": "30102",
        "label": "Ladera Ranch, California, USA"
    },
    {
        "id": 330480,
        "value": "2469",
        "label": "Lafayette, California, USA"
    },
    {
        "id": 331416,
        "value": "3149",
        "label": "Laguna Beach, California, USA"
    },
    {
        "id": 331733,
        "value": "4012",
        "label": "Laguna Hills, California, USA"
    },
    {
        "id": 331842,
        "value": "44202",
        "label": "Laguna Niguel, California, USA"
    },
    {
        "id": 332219,
        "value": "49257",
        "label": "Laguna Woods, California, USA"
    },
    {
        "id": 333032,
        "value": "60578",
        "label": "Lagunitas, California, USA"
    },
    {
        "id": 331404,
        "value": "30103",
        "label": "Lake Arrowhead, California, USA"
    },
    {
        "id": 333102,
        "value": "60685",
        "label": "Lake City, California, USA"
    },
    {
        "id": 329134,
        "value": "16594",
        "label": "Lake Elsinore, California, USA"
    },
    {
        "id": 330245,
        "value": "2048",
        "label": "Lake Forest, California, USA"
    },
    {
        "id": 330646,
        "value": "25567",
        "label": "Lake Hughes, California, USA"
    },
    {
        "id": 331632,
        "value": "37934",
        "label": "Lake Isabella, California, USA"
    },
    {
        "id": 332348,
        "value": "49461",
        "label": "Lakehead, California, USA"
    },
    {
        "id": 331314,
        "value": "2693",
        "label": "Lakeport, California, USA"
    },
    {
        "id": 333875,
        "value": "9483",
        "label": "Lakeside, California, USA"
    },
    {
        "id": 330221,
        "value": "2001",
        "label": "Lakewood, California, USA"
    },
    {
        "id": 333013,
        "value": "60544",
        "label": "Lamont, California, USA"
    },
    {
        "id": 333361,
        "value": "678",
        "label": "Lancaster, California, USA"
    },
    {
        "id": 333001,
        "value": "60527",
        "label": "Landers, California, USA"
    },
    {
        "id": 330148,
        "value": "1854",
        "label": "Larkspur, California, USA"
    },
    {
        "id": 328934,
        "value": "11284",
        "label": "Lathrop, California, USA"
    },
    {
        "id": 330283,
        "value": "2180",
        "label": "Laton, California, USA"
    },
    {
        "id": 332403,
        "value": "5333",
        "label": "Lawndale, California, USA"
    },
    {
        "id": 329026,
        "value": "1424",
        "label": "Laytonville, California, USA"
    },
    {
        "id": 331578,
        "value": "36168",
        "label": "Le Grand, California, USA"
    },
    {
        "id": 329052,
        "value": "14852",
        "label": "Lebec, California, USA"
    },
    {
        "id": 330647,
        "value": "25568",
        "label": "Lee Vining, California, USA"
    },
    {
        "id": 329027,
        "value": "1425",
        "label": "Leggett, California, USA"
    },
    {
        "id": 331405,
        "value": "30104",
        "label": "Lemon Cove, California, USA"
    },
    {
        "id": 333871,
        "value": "9409",
        "label": "Lemon Grove, California, USA"
    },
    {
        "id": 333861,
        "value": "9323",
        "label": "Lemoore, California, USA"
    },
    {
        "id": 330290,
        "value": "2189",
        "label": "Lewiston, California, USA"
    },
    {
        "id": 333889,
        "value": "9697",
        "label": "Lincoln, California, USA"
    },
    {
        "id": 330253,
        "value": "20698",
        "label": "Linden, California, USA"
    },
    {
        "id": 329100,
        "value": "16101",
        "label": "Lindsay, California, USA"
    },
    {
        "id": 332349,
        "value": "49462",
        "label": "Litchfield, California, USA"
    },
    {
        "id": 332220,
        "value": "49258",
        "label": "Little River, California, USA"
    },
    {
        "id": 333404,
        "value": "7902",
        "label": "Littlerock, California, USA"
    },
    {
        "id": 331406,
        "value": "30105",
        "label": "Live Oak, California, USA"
    },
    {
        "id": 331588,
        "value": "362",
        "label": "Livermore, California, USA"
    },
    {
        "id": 332408,
        "value": "5423",
        "label": "Livingston, California, USA"
    },
    {
        "id": 333022,
        "value": "60557",
        "label": "Llano, California, USA"
    },
    {
        "id": 331321,
        "value": "2713",
        "label": "Lockeford, California, USA"
    },
    {
        "id": 333029,
        "value": "60575",
        "label": "Lockwood, California, USA"
    },
    {
        "id": 330286,
        "value": "2184",
        "label": "Lodi, California, USA"
    },
    {
        "id": 333057,
        "value": "60616",
        "label": "Loleta, California, USA"
    },
    {
        "id": 330344,
        "value": "2424",
        "label": "Loma Linda, California, USA"
    },
    {
        "id": 333465,
        "value": "8810",
        "label": "Lomita, California, USA"
    },
    {
        "id": 328916,
        "value": "1046",
        "label": "Lompoc, California, USA"
    },
    {
        "id": 330224,
        "value": "2006",
        "label": "Lone Pine, California, USA"
    },
    {
        "id": 331908,
        "value": "45280",
        "label": "Long Barn, California, USA"
    },
    {
        "id": 329004,
        "value": "133",
        "label": "Long Beach, California, USA"
    },
    {
        "id": 333091,
        "value": "60663",
        "label": "Lookout, California, USA"
    },
    {
        "id": 333316,
        "value": "6488",
        "label": "Loomis, California, USA"
    },
    {
        "id": 330343,
        "value": "2369",
        "label": "Los Alamitos, California, USA"
    },
    {
        "id": 332350,
        "value": "49463",
        "label": "Los Alamos, California, USA"
    },
    {
        "id": 331980,
        "value": "4672",
        "label": "Los Altos, California, USA"
    },
    {
        "id": 328935,
        "value": "113",
        "label": "Los Angeles, California, USA"
    },
    {
        "id": 330277,
        "value": "2169",
        "label": "Los Banos, California, USA"
    },
    {
        "id": 333870,
        "value": "940",
        "label": "Los Gatos, California, USA"
    },
    {
        "id": 330268,
        "value": "2158",
        "label": "Los Molinos, California, USA"
    },
    {
        "id": 329166,
        "value": "17307",
        "label": "Los Olivos, California, USA"
    },
    {
        "id": 330167,
        "value": "18753",
        "label": "Los Osos, California, USA"
    },
    {
        "id": 333111,
        "value": "6085",
        "label": "Lost Hills, California, USA"
    },
    {
        "id": 332351,
        "value": "49464",
        "label": "Lotus, California, USA"
    },
    {
        "id": 331311,
        "value": "2690",
        "label": "Lower Lake, California, USA"
    },
    {
        "id": 333880,
        "value": "9558",
        "label": "Loyalton, California, USA"
    },
    {
        "id": 331638,
        "value": "38460",
        "label": "Lucerne, California, USA"
    },
    {
        "id": 331585,
        "value": "36178",
        "label": "Lucerne Valley, California, USA"
    },
    {
        "id": 333363,
        "value": "6790",
        "label": "Lynwood, California, USA"
    },
    {
        "id": 333008,
        "value": "60534",
        "label": "Lytle Creek, California, USA"
    },
    {
        "id": 333093,
        "value": "60665",
        "label": "Macdoel, California, USA"
    },
    {
        "id": 329034,
        "value": "1431",
        "label": "Mad River, California, USA"
    },
    {
        "id": 330278,
        "value": "2173",
        "label": "Madera, California, USA"
    },
    {
        "id": 330260,
        "value": "20936",
        "label": "Magalia, California, USA"
    },
    {
        "id": 333893,
        "value": "989",
        "label": "Malibu, California, USA"
    },
    {
        "id": 328928,
        "value": "1088",
        "label": "Mammoth Lakes, California, USA"
    },
    {
        "id": 332353,
        "value": "49466",
        "label": "Manchester, California, USA"
    },
    {
        "id": 333408,
        "value": "7978",
        "label": "Manhattan Beach, California, USA"
    },
    {
        "id": 330295,
        "value": "2195",
        "label": "Manteca, California, USA"
    },
    {
        "id": 333094,
        "value": "60666",
        "label": "Manton, California, USA"
    },
    {
        "id": 331407,
        "value": "30107",
        "label": "March Air Reserve Base, California, USA"
    },
    {
        "id": 332354,
        "value": "49467",
        "label": "Maricopa, California, USA"
    },
    {
        "id": 333450,
        "value": "8592",
        "label": "Marina, California, USA"
    },
    {
        "id": 330160,
        "value": "1867",
        "label": "Marina Del Rey, California, USA"
    },
    {
        "id": 330130,
        "value": "18232",
        "label": "Mariposa, California, USA"
    },
    {
        "id": 331589,
        "value": "36410",
        "label": "Markleeville, California, USA"
    },
    {
        "id": 331536,
        "value": "35142",
        "label": "Marshall, California, USA"
    },
    {
        "id": 331983,
        "value": "46876",
        "label": "Martell, California, USA"
    },
    {
        "id": 330252,
        "value": "2065",
        "label": "Martinez, California, USA"
    },
    {
        "id": 330269,
        "value": "2159",
        "label": "Marysville, California, USA"
    },
    {
        "id": 332402,
        "value": "5268",
        "label": "Mather, California, USA"
    },
    {
        "id": 331656,
        "value": "38620",
        "label": "Maxwell, California, USA"
    },
    {
        "id": 331408,
        "value": "30108",
        "label": "Maywood, California, USA"
    },
    {
        "id": 329064,
        "value": "1498",
        "label": "Mc Kittrick, California, USA"
    },
    {
        "id": 333092,
        "value": "60664",
        "label": "Mcarthur, California, USA"
    },
    {
        "id": 331918,
        "value": "45526",
        "label": "Mcclellan, California, USA"
    },
    {
        "id": 331901,
        "value": "45213",
        "label": "Mccloud, California, USA"
    },
    {
        "id": 328922,
        "value": "1081",
        "label": "Mcfarland, California, USA"
    },
    {
        "id": 333886,
        "value": "9609",
        "label": "Mckinleyville, California, USA"
    },
    {
        "id": 333078,
        "value": "60646",
        "label": "Meadow Valley, California, USA"
    },
    {
        "id": 331584,
        "value": "36176",
        "label": "Meadow Vista, California, USA"
    },
    {
        "id": 330247,
        "value": "20576",
        "label": "Mecca, California, USA"
    },
    {
        "id": 329035,
        "value": "1433",
        "label": "Mendocino, California, USA"
    },
    {
        "id": 329107,
        "value": "16114",
        "label": "Mendota, California, USA"
    },
    {
        "id": 331583,
        "value": "36175",
        "label": "Menifee, California, USA"
    },
    {
        "id": 330312,
        "value": "2274",
        "label": "Menlo Park, California, USA"
    },
    {
        "id": 329161,
        "value": "17248",
        "label": "Mentone, California, USA"
    },
    {
        "id": 330294,
        "value": "2193",
        "label": "Merced, California, USA"
    },
    {
        "id": 331676,
        "value": "38778",
        "label": "Meridian, California, USA"
    },
    {
        "id": 330341,
        "value": "23508",
        "label": "Mi Wuk Village, California, USA"
    },
    {
        "id": 329117,
        "value": "16256",
        "label": "Middletown, California, USA"
    },
    {
        "id": 333041,
        "value": "60594",
        "label": "Midpines, California, USA"
    },
    {
        "id": 331409,
        "value": "30109",
        "label": "Midway City, California, USA"
    },
    {
        "id": 333105,
        "value": "60688",
        "label": "Milford, California, USA"
    },
    {
        "id": 333095,
        "value": "60667",
        "label": "Mill Creek, California, USA"
    },
    {
        "id": 333290,
        "value": "6286",
        "label": "Mill Valley, California, USA"
    },
    {
        "id": 333477,
        "value": "914",
        "label": "Millbrae, California, USA"
    },
    {
        "id": 330342,
        "value": "23509",
        "label": "Millville, California, USA"
    },
    {
        "id": 331766,
        "value": "4299",
        "label": "Milpitas, California, USA"
    },
    {
        "id": 333096,
        "value": "60668",
        "label": "Mineral, California, USA"
    },
    {
        "id": 331420,
        "value": "3194",
        "label": "Mira Loma, California, USA"
    },
    {
        "id": 331629,
        "value": "37926",
        "label": "Miramonte, California, USA"
    },
    {
        "id": 511915,
        "value": "616903",
        "label": "Playa Vista, California, USA"
    },
    {
        "id": 329105,
        "value": "16109",
        "label": "Big Creek, California, USA"
    },
    {
        "id": 330196,
        "value": "19579",
        "label": "Honeydew, California, USA"
    },
    {
        "id": 330488,
        "value": "247072",
        "label": "Fort Bidwell, California, USA"
    },
    {
        "id": 330514,
        "value": "248021",
        "label": "Blairsden, California, USA"
    },
    {
        "id": 330545,
        "value": "248582",
        "label": "Mono Hot Springs, California, USA"
    },
    {
        "id": 331942,
        "value": "45734",
        "label": "Floriston, California, USA"
    },
    {
        "id": 332200,
        "value": "48777",
        "label": "Nipton, California, USA"
    },
    {
        "id": 332329,
        "value": "49442",
        "label": "Dardanelle, California, USA"
    },
    {
        "id": 332352,
        "value": "49465",
        "label": "Ludlow, California, USA"
    },
    {
        "id": 333000,
        "value": "60521",
        "label": "Lincoln Acres, California, USA"
    },
    {
        "id": 333002,
        "value": "60528",
        "label": "Amboy, California, USA"
    },
    {
        "id": 333004,
        "value": "60530",
        "label": "Cima, California, USA"
    },
    {
        "id": 333005,
        "value": "60531",
        "label": "Essex, California, USA"
    },
    {
        "id": 333009,
        "value": "60538",
        "label": "El Toro, California, USA"
    },
    {
        "id": 333011,
        "value": "60542",
        "label": "Camp Nelson, California, USA"
    },
    {
        "id": 333012,
        "value": "60543",
        "label": "Kaweah, California, USA"
    },
    {
        "id": 333019,
        "value": "60554",
        "label": "Darwin, California, USA"
    },
    {
        "id": 333026,
        "value": "60564",
        "label": "Cantua Creek, California, USA"
    },
    {
        "id": 333028,
        "value": "60566",
        "label": "Lakeshore, California, USA"
    },
    {
        "id": 333030,
        "value": "60576",
        "label": "Loma Mar, California, USA"
    },
    {
        "id": 333034,
        "value": "60582",
        "label": "Holy City, California, USA"
    },
    {
        "id": 333036,
        "value": "60585",
        "label": "Campo Seco, California, USA"
    },
    {
        "id": 333048,
        "value": "60601",
        "label": "El Verano, California, USA"
    },
    {
        "id": 333060,
        "value": "60623",
        "label": "Hood, California, USA"
    },
    {
        "id": 333061,
        "value": "60624",
        "label": "Kit Carson, California, USA"
    },
    {
        "id": 333063,
        "value": "60626",
        "label": "Madison, California, USA"
    },
    {
        "id": 333066,
        "value": "60635",
        "label": "Kyburz, California, USA"
    },
    {
        "id": 333068,
        "value": "60637",
        "label": "Belden, California, USA"
    },
    {
        "id": 333074,
        "value": "60642",
        "label": "Crescent Mills, California, USA"
    },
    {
        "id": 333075,
        "value": "60643",
        "label": "Feather Falls, California, USA"
    },
    {
        "id": 333076,
        "value": "60644",
        "label": "Goodyears Bar, California, USA"
    },
    {
        "id": 333088,
        "value": "60660",
        "label": "Flournoy, California, USA"
    },
    {
        "id": 333089,
        "value": "60661",
        "label": "Greenview, California, USA"
    },
    {
        "id": 333099,
        "value": "60682",
        "label": "Davis Creek, California, USA"
    },
    {
        "id": 333101,
        "value": "60684",
        "label": "Eagleville, California, USA"
    },
    {
        "id": 333103,
        "value": "60686",
        "label": "Likely, California, USA"
    },
    {
        "id": 333104,
        "value": "60687",
        "label": "Madeline, California, USA"
    },
    {
        "id": 333314,
        "value": "64844",
        "label": "Bryn Mawr, California, USA"
    },
    {
        "id": 333864,
        "value": "9356",
        "label": "Kentfield, California, USA"
    },
    {
        "id": 334187,
        "value": "1438",
        "label": "Piercy, California, USA"
    },
    {
        "id": 334989,
        "value": "23480",
        "label": "Vidal, California, USA"
    },
    {
        "id": 335084,
        "value": "248542",
        "label": "Spring Garden, California, USA"
    },
    {
        "id": 335232,
        "value": "30121",
        "label": "Sheep Ranch, California, USA"
    },
    {
        "id": 338197,
        "value": "60560",
        "label": "Red Mountain, California, USA"
    },
    {
        "id": 338202,
        "value": "60574",
        "label": "Yettem, California, USA"
    },
    {
        "id": 338213,
        "value": "60605",
        "label": "Vineburg, California, USA"
    },
    {
        "id": 338218,
        "value": "60620",
        "label": "Redcrest, California, USA"
    },
    {
        "id": 338229,
        "value": "60650",
        "label": "Strawberry Valley, California, USA"
    },
    {
        "id": 338232,
        "value": "60671",
        "label": "Nubieber, California, USA"
    },
    {
        "id": 338235,
        "value": "60674",
        "label": "Proberta, California, USA"
    },
    {
        "id": 338237,
        "value": "60676",
        "label": "Scott Bar, California, USA"
    },
    {
        "id": 338242,
        "value": "60689",
        "label": "Ravendale, California, USA"
    },
    {
        "id": 338244,
        "value": "60693",
        "label": "Termo, California, USA"
    },
    {
        "id": 338245,
        "value": "60694",
        "label": "Vinton, California, USA"
    },
    {
        "id": 338351,
        "value": "64254",
        "label": "Storrie, California, USA"
    },
    {
        "id": 338390,
        "value": "64927",
        "label": "Witter Springs, California, USA"
    },
    {
        "id": 338393,
        "value": "64968",
        "label": "Wishon, California, USA"
    },
    {
        "id": 338420,
        "value": "65135",
        "label": "Santa Rita Park, California, USA"
    }
].sortBy('label')