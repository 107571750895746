import React, { useState, useEffect, Component, useDebugValue } from 'react';
import {BrowserRouter as Router, Link } from "react-router-dom";

//Redux
import { connect } from 'react-redux';
import { 
    campaignActions,
    pixelActions,
    audienceActions,
    measurementActions,
    tagSupernovaActions,
} from '../_actions';

//React Select
import { listConversionType } from '../_constants/data'
import { styleSelectGoal } from '../_constants/settings'
import { styleSelectBasic, styleSelectMultiple } from '../_constants/settings'
import Select from 'react-select'

//Components
import {TopNavigation} from '../_components/TopNavigation'
import {Submenu} from '../_components/Submenu'
import {Footer} from '../_components/Footer';
import {LeftNavigation} from '../_components/LeftNavigation'

//Moment
import Moment from 'react-moment';
import moment from 'moment';

//Tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

//Helpers
import '../_helpers/sort-by.js'
import { measurement } from '../_reducers/measurement.reducer';

//Highlight JS
import Highlight from 'react-highlight'

const PixelsPage = (props) => {

    const useStateWithLabel = (initialValue, label) => {
        const [value, setValue] = useState(initialValue);
        useDebugValue(`${label}: ${value}`);
        return [value, setValue];
    };


    const { user, users, pixels, audiences, measurement, campaigns, tagSupernova } = props;
    const accountId = user.accountId


    useEffect(() => {
        // props.getPixelsByAccountId(accountId)
        props.getCampaigns(accountId);
        props.getConversionDefinitionByAccountId(accountId)
        props.getMeasurementServicesByAccountId(accountId)
        props.getAudiencesByAccountId(accountId)
        props.getTagSupernovaByAccountId(accountId)
    },[]);
    

    useEffect(() => {
        if(pixels.items){
            let pixelIds = pixels.items.map(pixel => {return pixel.id})
            props.getAudiencesByPixelId(pixelIds)
        }
    },[pixels.items]);

    const [isEditingForm, setIsEditingForm] = useStateWithLabel(false, "isEditingForm");
    

    const [selectedEntityKey, setSelectedEntityKey] = useStateWithLabel(null, "selectedEntityKey");
    const [isEditPixelWindowVisible, setIsEditPixelWindowVisible] = useStateWithLabel(false, "isEditPixelWindowVisible");
    const [pixelId, setPixelId] = useStateWithLabel("", "pixelId");
    const [pixelName, setPixelName] = useStateWithLabel("", "pixelName");
    const [pixelNotes, setPixelNotes] = useStateWithLabel("", "pixelNotes");
    const [pixelConversionType, setPixelConversionType] = useStateWithLabel("", "pixelConversionType")
    const [pixelEventName, setPixelEventName] = useStateWithLabel("", "pixelEventName")
    const [marketingFunnel, setMarketingFunnel] = useStateWithLabel("", "marketingFunnel")

    
    const [isEditAudienceWindowVisible, setIsEditAudienceWindowVisible] = useStateWithLabel(false, "isEditAudienceWindowVisible");
    const [audienceId, setAudienceId] = useStateWithLabel("", "audienceId");
    const [audienceName, setAudienceName] = useStateWithLabel("", "audienceName");
    const [audienceNotes, setAudienceNotes] = useStateWithLabel("", "audienceNotes");
    const [audiencePixel, setAudiencePixel] = useStateWithLabel("", "audiencePixel");
    const [audienceTraffic, setAudienceTraffic] = useStateWithLabel("", "audienceTraffic");
    const [audienceTrafficInTheLast, setAudienceTrafficInTheLast] = useStateWithLabel("", "audienceTrafficInTheLast");
    const [audienceRules, setAudienceRules] = useStateWithLabel([], "audienceRules");

    const [isCopyPixelWindowVisible, setIsCopyPixelWindowVisible] = useStateWithLabel(false, "isCopyPixelWindowVisible");
    
    const [conversionTrigger, setConversionTrigger] = useStateWithLabel("RULE", "conversionTrigger");
    const [attributionModelCount, setAttributionModelCount] = useStateWithLabel("ONE", "attributionModelCount");
    const [attributionModelType, setAttributionModelType] = useStateWithLabel("FIRST", "attributionModelType");
    const [measurementMatchingRules_actionType, setMeasurementMatchingRules_actionType] = useStateWithLabel("CONTAINS", "measurementMatchingRules_actionType");
    const [measurementMatchingRules_value, setMeasurementMatchingRules_value] = useStateWithLabel("", "measurementMatchingRules_value");
    const [clickConversionWindow, setClickConversionWindow] = useStateWithLabel(1, "clickConversionWindow");
    const [viewConversionWindow, setViewConversionWindow] = useStateWithLabel(1, "viewConversionWindow");
    const [isTagCopied, setIsTagCopied] = useStateWithLabel(false, "isTagCopied");

    const [selectedCampaign, setSelectedCampaign] = useStateWithLabel(null, "selectedCampaign");
    const [selectedConversion, setSelectedConversion] = useStateWithLabel(null, "selectedConversion");
    const [selectedAudience, setSelectedAudience] = useStateWithLabel(null, "selectedAudience");
    
    const showPixelForm = (data, purpose) => {
        setIsEditPixelWindowVisible(true); 
        setIsEditingForm(purpose == "edit" ? true : false); 
        setPixelId(data == null ? "" : data.id)
        setPixelName(data == null ? "" : data.name)
        setPixelNotes(data == null ? "" : data.notes)
        setPixelConversionType(data == null ? "" : data.conversionType)
        setConversionTrigger(data == null ? "" : data.conversionTriggerType)
        if(data != null && data.measurementMatchingRules && data.measurementMatchingRules.length > 0 && data.measurementMatchingRules[0].value){
            setMeasurementMatchingRules_value(data.measurementMatchingRules[0].value)
            setMeasurementMatchingRules_actionType(data.measurementMatchingRules[0].actionType)
        }
    }

    const showAudienceForm = (data, purpose) => {
        setIsEditAudienceWindowVisible(true)
        setIsEditingForm(purpose == "edit" ? true : false);
        setAudienceName(data == null ? "" : data.name)
        setAudienceNotes(data == null ? "" : data.notes)
        setAudiencePixel(data == null ? "" : data.pixelId)
        setAudienceId(data == null ? "" : data.id)
        setAudienceTrafficInTheLast(data == null ? 90 : data.ttl)
        setAudienceTraffic(data == null ? "" : data.rules[0].type == "VISIT" ? "Anyone who visited your website" : "People who visited specific web pages")
        setAudienceRules(data == null ? [] : data.rules[0].type == "STARTS_WITH" ? [{type:"URL Contains", value:data.rules[0].value}] : [{type:"URL equals", value:data.rules[0].value}] )
    }

    const showCopyTag = (data) => {
        setConversionTrigger(data.conversionTriggerType)
        setIsEditingForm(false)
        setIsCopyPixelWindowVisible(true);
        setPixelEventName(data.eventName);
        setPixelId(data.id)
        // setPixelUrl(data.url.replace("p1.zemanta.com", "pixel.supernova.inc"))
    }

    const updatePixel = (pixelId) => {
        let data = {
            id:pixelId,
            notes:pixelNotes,
            name:pixelName,
        }
        props.updateConversionDefinition(data, accountId)
        setIsEditPixelWindowVisible(false);
        setIsEditAudienceWindowVisible(false);
        setIsEditingForm(false)
    }

    const createPixel = () => {
        let data = {
            name                    : pixelName,
            measurementServiceId    : accountId,
            accountId               : accountId,
            conversionType          : pixelConversionType,
            archived                : false,
            notes                   : pixelNotes,
            eventName               : pixelEventName,
            isPureS2s               : "false",
            attributionModelType    : attributionModelType,
            attributionModelCount   : attributionModelCount,
            clickConversionWindow   : clickConversionWindow,
            viewConversionWindow    : viewConversionWindow,
            conversionTriggerType   : conversionTrigger,
            measurementMatchingRules: conversionTrigger == "RULE" ? [{
                targetType  : "URL",
                actionType  : measurementMatchingRules_actionType,
                value       : measurementMatchingRules_value
            }] : []
        }

        props.createConversionDefinition(data, accountId)
        setIsEditPixelWindowVisible(false);
        setIsEditAudienceWindowVisible(false);
        setIsEditingForm(false)
    }

    const createAudience = () => {
        let data = {
            pixelId: audiencePixel,
            name: audienceName,
            archived: false,
            ttl: audienceTrafficInTheLast,
            rules: audienceRules
        }
        props.createAudience(data, accountId)
        setIsEditPixelWindowVisible(false);
        setIsEditAudienceWindowVisible(false);
        setIsEditingForm(false)
    }

    const updateAudience = () => {
        // let pixelIds = pixels.items.map(pixel => {return pixel.id})
        let measurementServiceId = measurement.services[0].id;
        let data = {
            id:audienceId,
            measurementServiceId:measurementServiceId,
            name:audienceName,
            notes:audienceNotes,
        }

        return;
        props.updateAudience(data)
        setIsEditAudienceWindowVisible(false);
        setIsEditingForm(false)
    }

    const handleDropdownChange = (dropdown) => {
        const value = Array.isArray(dropdown)
            ? dropdown.map((item) => {
                return item.value
            })
            : dropdown === null ? "" : dropdown.value
        return (value)
    }

    const listPixels = pixels.items && pixels.items.map((item) => {
        return {
            label: item.name,
            value: item.id
        }
    })

    const getTotalPixels = () => {
        return pixels.items && pixels.items.length
    }

    const copyTag = async () => {
        try {
            let tag = conversionTrigger == "S2S" ?
                `https://s2s.supernova.inc/${accountId}/${pixelEventName}/?postbackid={postbackid}`
            :
                `<script type="text/javascript" src="https://pixel.supernova.inc/tag.js">
${
    conversionTrigger == "CODE" 
    ? `supernova('track', '${accountId}', '${pixelEventName}');`
    : `supernova('track', '${accountId}');`
}
</script>
<noscript>
    <img src="//pixel.supernova.inc/${accountId}/${pixelEventName}" referrerpolicy="no-referrer-when-downgrade" height="1" width="1" border="0" alt="" />
</noscript>`
            await navigator.clipboard.writeText(tag);
            setIsTagCopied(true)
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className="pixels-audience">

            {
                /* COPY TAG FORM */
                isCopyPixelWindowVisible && 
                <div className="backdrop">
                    <div className="window copy-tag">
                        <div className="title">
                            {isEditingForm ? "Edit Conversion Definition" : "Copy Conversion Tag"}
                        </div>
                        <div className="body">
                            <section>
                                {/* <b>Option 1:</b> Using an iframe tag */}
                                <div className="form-group">
                                        <div className="form-control readonly code-snippet">
                                            {
                                                    conversionTrigger == "S2S"
                                                    ?<Highlight className='html'>{`https://s2s.supernova.inc/${accountId}/${pixelEventName}/?postbackid={postbackid}`}</Highlight>
                                                    :
                                                    <Highlight className='html'>
                                                        {
                                                            `<!-- Supernova Pixel -->
<script type="text/javascript" src="https://pixel.supernova.inc/tag.js">
    ${
        `supernova('${selectedAudience == null ? "c" : "a"}', '${accountId}', '${pixelId}');`
    }
</script>
<noscript>
    <img src="//pixel.supernova.inc/${accountId}/${pixelEventName}" referrerpolicy="no-referrer-when-downgrade" height="1" width="1" border="0" alt="" />
</noscript>`
                                                        }
                                                    </Highlight>
                                                }
                                        </div>
                                </div>
                                {/* <b>Option 2:</b> Using a javascript tag
                                <div className="form-group">
                                        <div className="form-control readonly">{pixelUrl}</div>
                                </div> */}
                                <div className="description-block">
                                    <div className="title">Where do I place my conversion tag on my site?</div>
                                    <div className="content">Pixels need to be properly placed in order to measure goals effectively. We recommend placing your pixel on the confirmation page which is shown to a person after the desired action is performed.</div>
                                </div>
                            </section>
                        </div>
                        <div className="footer">
                            <button className="btn btn-xs btn-secondary mr-2" onClick={() => copyTag(false)}>Copy Tag</button>
                            <button className="btn btn-xs btn-secondary" onClick={() => {setIsCopyPixelWindowVisible(false); setIsTagCopied(false)}}>Cancel</button>
                            {
                                isTagCopied &&
                                <div className='tag-copied-text'>
                                    Tag successfully copied
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }

            {
                /* CONVERSION DEFINITION FORM (FORMERLY PIXEL) */
                isEditPixelWindowVisible && 
                <div className="backdrop">
                    <div className="window">
                        <div className="title">
                            {isEditingForm ? "Edit" : "Create"} Conversion Definition
                        </div>
                        <div className="body">
                            <section>
                                <div className="section-title">General Info</div>
                                <div className="form-group">
                                    <label>Name</label>
                                    <div className="content">
                                            <input type="text" className="form-control" value = {pixelName} name="name" onChange={event => setPixelName(event.target.value)}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Notes</label>
                                    <div className="content">
                                        <textarea maxLength="250" type="text" className="form-control" value={pixelNotes} onChange={event => setPixelNotes(event.target.value)}>{pixelNotes}</textarea>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Marketing Funnel</label>
                                    <div className="content">
                                    <Select
                                        onChange={(event) => {setMarketingFunnel(handleDropdownChange(event))}}
                                        className={`select-primary pixe-page-dropdown mt-0 mb-0 ${isEditingForm ? "disabled" : ""}`}
                                        placeholder={marketingFunnel}
                                        value={marketingFunnel}
                                        options={[
                                            {label:"Awareness",     value:"Awareness"},
                                            {label:"Consideration", value:"Consideration"},
                                            {label:"Purchase",      value:"Purchase"},
                                            {label:"Loyalty",       value:"Loyalty"},
                                        ]}
                                        isClearable={false}
                                        styles={styleSelectGoal} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Conversion Type</label>
                                    <div className="content">
                                        {
                                            measurement && measurement.conversion_definitions && 
                                            <>
                                                <Select
                                                    onChange={event => setPixelConversionType(() => handleDropdownChange(event))}
                                                    className={`select-primary pixe-page-dropdown mt-0 mb-0 ${isEditingForm ? "disabled" : ""}`}
                                                    options={listConversionType}
                                                    styles={styleSelectGoal} />
                                            </>
                                        }
                                    </div>
                                </div>
                            </section>
                            <section className="conversion-triggers">
                                <div className="section-title">Triggers</div>
                                <div className="form-group">
                                    <label>Trigger Type</label>
                                    <div className="content">
                                        <Select
                                            onChange={event => setConversionTrigger((e) => handleDropdownChange(event))}
                                            className={`select-primary pixe-page-dropdown mt-0 mb-0 ${isEditingForm ? "disabled" : ""}`}
                                            defaultValue={
                                                [
                                                    {value:'RULE',      label:'Rule'},
                                                    {value:'CODE',      label:'Code'},
                                                    {value:'S2S',       label:'Server to Server'}
                                                ].filter(item => item.value == conversionTrigger)
                                            }
                                            options={[
                                                {value:'RULE',      label:'Rule'},
                                                {value:'CODE',      label:'Code'},
                                                {value:'S2S',       label:'Server to Server'}
                                            ]}
                                            styles={styleSelectGoal} />
                                    </div>
                                </div>
                                {
                                    conversionTrigger == "RULE" &&
                                    <div className="form-group">
                                        <label>Action Type</label>
                                        <div className='content'>
                                            <div className={`radio side-by-side full-width ${isEditingForm ? "disabled" : ""}`} onChange={(e) => setMeasurementMatchingRules_actionType(e.target.value)}>
                                                <label>
                                                    <input className={`${isEditingForm ? "disabled" : ""}`} type="radio" value={"CONTAINS"} name="measurementMatchingRules_actionType" defaultChecked={measurementMatchingRules_actionType == "CONTAINS"}/>
                                                    <span className="radio-description campaign-auto-pilot d-flex">Contains</span>
                                                </label>
                                                <label>
                                                    <input className={`${isEditingForm ? "disabled" : ""}`} type="radio" value={"STARTS_WITH"} name="measurementMatchingRules_actionType" defaultChecked={measurementMatchingRules_actionType == "STARTS_WITH"} />
                                                    <span className="radio-description campaign-auto-pilot d-flex">Starts with</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>    
                                }
                                {
                                    conversionTrigger == "RULE" &&
                                    <div className="form-group">
                                        <label>URL Keyword</label>
                                        <div className='content'>
                                            <input type="text" value={measurementMatchingRules_value} className={`form-control ${isEditingForm ? "disabled" : ""}`} disabled={isEditingForm} onChange={(e) => setMeasurementMatchingRules_value(e.target.value)} name="name" placeholder=''/>
                                        </div>
                                    </div>    
                                }
                                {
                                    (conversionTrigger == "CODE" || conversionTrigger == "S2S") &&
                                    <div className="form-group">
                                        <label>Event Name</label>
                                        <div className='content'>
                                            <input type="text" value={pixelEventName} className={`form-control ${isEditingForm ? "disabled" : ""}`} disabled={isEditingForm} name="name" onChange={(e) => setPixelEventName(e.target.value)} placeholder=''/>
                                        </div>
                                    </div>    
                                }
                            </section>
                            <section className='attribution-rules'>
                                <div className="section-title">Attribution Rules</div>
                                <div className="form-group">
                                    <label>Count</label>
                                    <div className="content">
                                        <div className={`radio attributionModelCount full-width ${isEditingForm ? "disabled" : ""}`} onChange={(e) => setAttributionModelCount(e.target.value)}>
                                            <label>
                                                <input className={`${isEditingForm ? "disabled" : ""}`} type="radio" value={"EVERY"} name="attributionModelCount" defaultChecked={attributionModelCount == "EVERY"}/>
                                                <span className="radio-description campaign-auto-pilot d-flex"><b>All</b> All conversion will be counted. Use it for purchases and other similar events where each action brings value.</span>
                                            </label>
                                            <label>
                                                <input className={`${isEditingForm ? "disabled" : ""}`} type="radio" value={"ONE"} name="attributionModelCount" defaultChecked={attributionModelCount == "ONE"} />
                                                <span className="radio-description campaign-auto-pilot d-flex"><b>One</b> Only the first conversion will be counted. Use it for sign up or leads where only the first user interaction brings value.</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Attribution model</label>
                                    <div className="content">
                                        {
                                            measurement && measurement.conversion_definitions && 
                                            <div className={`radio side-by-side full-width ${isEditingForm ? "disabled" : ""}`} onChange={(e) => setAttributionModelType(e.target.value)}>
                                                <label>
                                                    <input className={`${isEditingForm ? "disabled" : ""}`} type="radio" value={"FIRST"} name="attributionModelType" defaultChecked={attributionModelType == "FIRST"}/>
                                                    <span className="radio-description campaign-auto-pilot d-flex">First click</span>
                                                </label>
                                                <label>
                                                    <input className={`${isEditingForm ? "disabled" : ""}`} type="radio" value={"LAST"} name="attributionModelType" defaultChecked={attributionModelType == "LAST"} />
                                                    <span className="radio-description campaign-auto-pilot d-flex">Last click</span>
                                                </label>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Click-through</label>
                                    <div className="content">
                                        {
                                            measurement && measurement.conversion_definitions && 
                                            <>
                                                <Select
                                                    onChange={event => setClickConversionWindow((e) => handleDropdownChange(event))}
                                                    className={`select-primary pixe-page-dropdown mt-0 mb-0 ${isEditingForm ? "disabled" : ""}`}
                                                    defaultValue={[{value:'1',   label:'1 day'}]}
                                                    menuPlacement="top"
                                                    options={[
                                                        {value:'1',   label:'1 day'},
                                                        {value:'2',   label:'2 day'},
                                                        {value:'7',   label:'7 day'},
                                                        {value:'28',   label:'28 day'},
                                                        {value:'30',   label:'30 day'},
                                                    ]}
                                                    styles={styleSelectGoal} />
                                            </>
                                        }
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="footer">
                            {
                                isEditingForm 
                                ? <button className="btn btn-xs btn-primary" type="button" onClick={() => updatePixel(pixelId)}><i className="fa fa-check" aria-hidden="true"></i> Save</button>
                                : <button className="btn btn-xs btn-primary" type="button" onClick={() => createPixel()}><i className="fa fa-check" aria-hidden="true"></i> Create</button>
                                    
                            }
                            <button className="btn btn-xs btn-secondary" onClick={() => setIsEditPixelWindowVisible(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            }

            {
                /* AUDIENCE FORM */
                isEditAudienceWindowVisible && 
                <div className="backdrop">
                    <div className="window">
                        <div className="title">
                            {isEditingForm ? "Edit" : "Create"} Custom Audience
                        </div>
                        <div className="body">
                            <section>
                                <div className="form-group">
                                    <label>Audience Name</label>
                                    <div className="content">
                                        <input type="text" className="form-control" value = {audienceName} name="audienceName" onChange={event => setAudienceName(event.target.value)}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Notes</label>
                                    <div className="content">
                                        <textarea maxLength="250" type="text" className="form-control" value={audienceNotes} onChange={event => setAudienceNotes(event.target.value)}>{audienceNotes}</textarea>
                                    </div>
                                </div>
                                <div className="form-group d-none">
                                    <label>Pixel</label>
                                    <div className="content">
                                        {
                                            isEditingForm 
                                            ?
                                                <input type="text" className="form-control" disabled value = {audiencePixel} name="audiencePixel" onChange={event => setAudiencePixel(event.target.value)}/>
                                            : 
                                                <Select
                                                onChange={event => setAudiencePixel(() => handleDropdownChange(event))}
                                                className={`select-primary ${isEditingForm ? "disabled" : ""}`}
                                                placeholder="Select"
                                                options={listPixels}
                                                styles={styleSelectBasic} />

                                        }
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Website traffic</label>
                                    <div className="content">
                                        {
                                            isEditingForm
                                            ?
                                                <div>
                                                    <input type="text" className="form-control" disabled value={audienceTraffic} name="audienceTraffic"/>
                                                    {
                                                        audienceRules.length > 0 && (audienceRules[0].type == "" || audienceRules[0].type == "URL Contains" || audienceRules[0].type == "URL equals") &&
                                                        <>
                                                            <div className="audience-rules-subtext">Include traffic that meets the following conditions:</div>
                                                            <input type="text" className="form-control" disabled value={audienceRules[0].type}/>
                                                            <input type="text" className="form-control" disabled value={audienceRules[0].value}/>
                                                        </>
                                                    }
                                                </div>
                                            :
                                                <div>
                                                    <Select
                                                    onChange={event => setAudienceRules([{type:handleDropdownChange(event), value:""}])}
                                                    className={`select-primary ${isEditingForm ? "disabled" : ""}`}
                                                    placeholder="Select"
                                                    options={
                                                        [
                                                            {label:"Anyone who visited your website", value:"VISIT"},
                                                            // {label:"People who visited specific web pages", value:""}
                                                        ]
                                                    }
                                                    styles={styleSelectBasic} />
                                                    {
                                                        audienceRules.length > 0 && (audienceRules[0].type == "" || audienceRules[0].type == "STARTS_WITH" || audienceRules[0].type == "CONTAINS") &&
                                                        <>
                                                            <div className="audience-rules-subtext">Include traffic that meets the following conditions:</div>
                                                            <Select
                                                            onChange={event => setAudienceRules([{type:handleDropdownChange(event), value:""}])}
                                                            className={`select-primary${isEditingForm ? "disabled" : ""}`}
                                                            placeholder="Select"
                                                            options={
                                                                [
                                                                    {label:"URL Contains", value:"STARTS_WITH"},
                                                                    {label:"URL equals", value:"CONTAINS"}
                                                                ]
                                                            }
                                                            styles={styleSelectBasic} />
                                                            {
                                                                audienceRules.length > 0 && (audienceRules[0].type == "STARTS_WITH" || audienceRules[0].type == "CONTAINS")  &&
                                                                    <input type="text" className="form-control" value={audienceRules[0].value}  onChange={event => setAudienceRules([{type:audienceRules[0].type, value:event.target.value}])}/>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>In the last</label>
                                    <div className="content">
                                        {
                                            isEditingForm 
                                            ?
                                                <input type="text" className="form-control" disabled value={`${audienceTrafficInTheLast} days`} name="audienceTrafficInTheLast" onChange={event => setAudienceTrafficInTheLast(event.target.value)}/>
                                            :
                                                <div className="ttl">
                                                    <input type="text" className="form-control" value={audienceTrafficInTheLast} name="audienceTrafficInTheLast" onChange={event => setAudienceTrafficInTheLast(event.target.value)}/> <span>days</span>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="footer">
                            {
                                isEditingForm 
                                ? <button className="btn btn-xs btn-primary" type="submit" onClick={() => updateAudience()}><i className="fa fa-check" aria-hidden="true"></i> Save</button>
                                : <button className="btn btn-xs btn-primary" type="submit" onClick={() => createAudience()}><i className="fa fa-check" aria-hidden="true"></i> Create</button>
                            }
                            <button className="btn btn-xs btn-secondary" onClick={() => setIsEditAudienceWindowVisible(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            }



            <LeftNavigation />

            <div className="dashboard">


                {/* TABLES */}
                <div className="details-column pixels-audiences">
                    <div className='fixed-table-wrapper'>
                        <div className="title">Statistics</div>
                        <div className="controls">
                            {
                                // selectedEntityKey == "pixels" && 
                                // <button type="button" className={`btn btn-primary btn-xs btn-pagination`} onClick={() => {showPixelForm(null, "create")}}>
                                //     <i className="fa fa-plus-circle" aria-hidden="true"></i> Add Conversion Funnel
                                // </button>
                            }
                            {
                                // selectedEntityKey == "audiences" && 
                                // <button type="button" className={`btn btn-primary btn-xs btn-pagination`} onClick={() => {showAudienceForm(null, "create")}}>
                                //     <i className="fa fa-plus-circle" aria-hidden="true"></i> Add Custom Audience
                                // </button>
                            }
                        </div>
                        {/* PIXELS */}
                        {
                            selectedEntityKey == "pixels" &&
                            <table id="primary-report-table" className={`primary has-hover`}>  
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className="text-left">Name </th>
                                        <th className="text-left">Last Triggered </th>
                                        <th className="text-left">Recent Traffic (Last 24 hrs)</th>
                                        <th className="text-left">Last Conversion </th>
                                        <th className="text-left">Conversions (Last 24 hrs) </th>
                                        <th className="text-left">Event Name </th>
                                        <th className="text-left">Trigger Type</th>
                                        <th className="text-center">Click Conversion Window </th>
                                        <th className="text-center">View Conversion Window</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        measurement.conversion_definitions && measurement.conversion_definitions.map((conversion_definition, index) =>
                                        <tr key={index}>
                                            <td>
                                                <OverlayTrigger placement="top" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                    <button type="button" className="btn btn-primary btn-xs btn-actions btn-pagination mr-3" onClick={() => {showPixelForm(conversion_definition, "edit")}}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                </OverlayTrigger>
                                                <OverlayTrigger placement="top" overlay={<Tooltip><b>Pixel</b></Tooltip>}>
                                                    <button type="button" className="btn btn-primary btn-xs btn-actions btn-pagination mr-3" onClick={() => showCopyTag(conversion_definition)}><i className="fa fa-files-o" aria-hidden="true"></i></button>
                                                </OverlayTrigger>
                                            </td>
                                            <td className="text-left">
                                                <span className="blue-link plain" onClick={() => showCopyTag(conversion_definition)}>{conversion_definition.name}</span>
                                            </td>
                                            <td className="text-left">
                                                {/* {conversion_definition.lastTriggered == undefined ? "N/A" : moment(conversion_definition.lastTriggered).format('MMM D, YYYY hh:mm:ss a')} */}
                                                {
                                                    tagSupernova.items && 
                                                    tagSupernova.items.find(item => item.tagId == conversion_definition.id /**&& item.type == "a"**/) && 
                                                    moment(tagSupernova.items.filter(item => item.tagId == conversion_definition.id /**&& item.type == "a"**/).sortBy("timestamp").reverse()[0]?.timestamp).format('LLL')
                                                }
                                            </td>
                                            <td className="text-left">
                                                {/* {conversion_definition.recentTraffic == undefined ? "N/A" : conversion_definition.recentTraffic} */}
                                                {
                                                    tagSupernova.items && 
                                                    tagSupernova.items.filter(item => item.tagId == conversion_definition.id /**&& item.type == "a"**/).length
                                                }
                                            </td>
                                            <td className="text-left">
                                                {/* {conversion_definition.lastConversion == undefined ? "N/A" : conversion_definition.lastConversion} */}
                                                {
                                                    tagSupernova.items && 
                                                    tagSupernova.items.find(item => item.tagId == conversion_definition.id /**&& item.type == "c"**/ && item.isConversion == true) && 
                                                    moment(tagSupernova.items.filter(item => item.tagId == conversion_definition.id /**&& item.type == "c"**/ && item.isConversion == true).sortBy("timestamp").reverse()[0]?.timestamp).format('LLL')
                                                }
                                            </td>
                                            <td className="text-left">
                                                {/* {conversion_definition.recentConversions == undefined ? "N/A" : conversion_definition.recentConversions} */}
                                                {
                                                    tagSupernova.items && 
                                                    tagSupernova.items.filter(item => item.tagId == conversion_definition.id /**&& item.type == "c"**/ && item.isConversion == true).length
                                                }
                                            </td>
                                            <td className="text-left">{conversion_definition.eventName}</td>
                                            <td className="text-left">{conversion_definition.conversionTriggerType}</td>
                                            <td className="text-center">{conversion_definition.clickConversionWindow}</td>
                                            <td className="text-center">{conversion_definition.viewConversionWindow}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        }
                        {/* AUDIENCES */}
                        {
                            selectedEntityKey == "audiences" &&
                            <table id="primary-report-table" className={`primary has-hover`}>  
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className="text-left">Audience </th>
                                        <th className="text-center">Audience ID</th>
                                        <th className="text-center">Activities</th>
                                        <th className="text-left">Created </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        measurement.audiences && measurement.audiences.map((audience, index) =>
                                        <tr key={index}>
                                            <td>
                                                <OverlayTrigger placement="top" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                    <button type="button" className="btn btn-primary btn-xs btn-actions btn-pagination mr-3" onClick={() => {showAudienceForm(audience, "edit")}}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                </OverlayTrigger>
                                                <OverlayTrigger placement="top" overlay={<Tooltip><b>Pixel</b></Tooltip>}>
                                                    <button type="button" className="btn btn-primary btn-xs btn-actions btn-pagination mr-3" onClick={() => showCopyTag(audience)}><i className="fa fa-files-o" aria-hidden="true"></i></button>
                                                </OverlayTrigger>
                                            </td>
                                            <td className="text-left"><span className="blue-link plain" onClick={() => showCopyTag(audience)}>{audience.name}</span></td>
                                            <td className="text-center">{audience.id}</td>
                                            <td className="text-center">
                                                {
                                                    tagSupernova.items && 
                                                    tagSupernova.items.filter(item => item.tagId == audience.id && item.type == "a").length
                                                }
                                            </td>
                                            <td className="text-left">{moment(audience.createdDt).format('LLL')}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        }
                    </div>
                </div>

                {/* 1ST COLUMN (CAMPAIGNS) */}
                <div className={`entity-column campaign`}>
                    <div className="title-wrapper">
                        <div className="title">Campaigns</div>
                    </div>
                    <ul>
                        {
                            campaigns.items && campaigns.items.sortBy("name").map((campaign, index) =>
                                <li className={`${campaign.archived ? 'd-none' : ''} ${campaign.id == selectedCampaign ? 'selected' : ''} ${campaigns && campaigns.inactive && campaigns.items ? 'active' : 'inactive'}`} 
                                    key={`${index}-${campaign.id}`}
                                    onClick={() => {setSelectedCampaign(campaign.id)}}>

                                    <div className="menu">
                                        {campaign.name}
                                    </div>
                                </li>
                            )
                        }
                    </ul>
                </div>

                {/* 2ND COLUMN (CONVERSION) */}
                <div className={`entity-column pixels-audiences`}>
                    <div className="title-wrapper">
                        <div className="title">Conversions</div>
                    </div>
                    <div className="controls">
                            <OverlayTrigger placement="top" content="" overlay={<Tooltip>New Conversion</Tooltip>}>
                                <button className="add-entity" onClick={() => showPixelForm(null, "create")}><i className="fa fa-plus" aria-hidden="true"></i></button>
                            </OverlayTrigger>
                    </div>
                    <ul>
                        {
                            measurement.conversion_definitions && measurement.conversion_definitions.map((conversion_definition, index) =>
                                <li key={index} className= {selectedConversion == conversion_definition.id ? "selected" : ""} onClick={() => {setSelectedConversion(conversion_definition.id); setSelectedAudience(null); setSelectedEntityKey("pixels")}}>
                                    <div className="menu">
                                        {conversion_definition.name}
                                    </div>
                                    <span className={`badge clickable ${getTotalPixels() > 0 ? "clickable" : "empty"}`}>
                                        {
                                            getTotalPixels()
                                        }
                                    </span>
                                </li>
                            )
                        }
                    </ul>
                </div>

                {/* 3RD COLUMN (AUDIENCE) */}
                <div className={`entity-column pixels-audiences`}>
                    <div className="title-wrapper">
                        <div className="title">Audiences</div>
                    </div>
                    <div className="controls">
                            <OverlayTrigger placement="top" content="" overlay={<Tooltip>New Custom Audience</Tooltip>}>
                                <button className="add-entity" onClick={() => showAudienceForm(null, "create")}><i className="fa fa-plus" aria-hidden="true"></i></button>
                            </OverlayTrigger>
                    </div>
                    <ul>
                        {
                            measurement.audiences && measurement.audiences.map((audience, index) =>
                                <li key={index} className= {selectedAudience == audience.id ? "selected" : ""} onClick={() => {setSelectedAudience(audience.id); setSelectedConversion(null); setSelectedEntityKey("audiences")}}>
                                    <div className="menu">
                                        {audience.name}
                                    </div>
                                    <span className={`badge clickable ${getTotalPixels() > 0 ? "clickable" : "empty"}`}>
                                        {
                                            getTotalPixels()
                                        }
                                    </span>
                                </li>
                            )
                        }
                    </ul>
                </div>
                
                {/* 2ND COLUMN */}
                {/* <div className={`entity-column`}>
                    <div className="title-wrapper">
                        <div className="title">
                            {selectedEntityKey == "pixels" ? "Conversion Definition" : "Audience"}
                        </div>
                    </div>
                    <div className="controls">
                        <OverlayTrigger placement="top" content="" overlay={<Tooltip>Add {selectedEntityKey == "pixels" ? "Conversion Definition" : "Audience"}</Tooltip>}>
                            <button onClick={() => {
                                selectedEntityKey == "pixels" ? showPixelForm(null, "create") : showAudienceForm(null, "create")
                            }} className="add-entity"><i className="fa fa-plus" aria-hidden="true"></i></button>
                        </OverlayTrigger>
                    </div>
                    <ul>
                        {
                            selectedEntityKey == "pixels" &&
                            pixels.items && pixels.items.map((pixel, index) =>
                                <li key={index}>
                                    <div className="gear-wrapper" onClick={(e) => {e.stopPropagation(); showPixelForm(pixel, "edit")}}>
                                        <i className="fa fa-cog" aria-hidden="true"></i>
                                    </div>
                                    <div className="menu">
                                        {pixel.name}
                                    </div>
                                </li>
                            )
                        }

                        {
                            selectedEntityKey == "audiences" &&
                            audiences.items && audiences.items.map((audience, index) =>
                                <li key={index}>
                                    <div className="gear-wrapper" onClick={(e) => {e.stopPropagation(); showAudienceForm(audience, "edit")}}>
                                        <i className="fa fa-cog" aria-hidden="true"></i>
                                    </div>
                                    <div className="menu">
                                        {audience.name}
                                    </div>
                                </li>
                            )
                        }
                    </ul>
                </div> */}
            </div>


            <Footer />
        </div>
    )
}

const mapState = state => ({
    user        : state.users.current,
    users       : state.users,
    pixels      : state.pixels,
    audiences   : state.audiences,
    measurement : state.measurement,
    campaigns   : state.campaigns,
    tagSupernova: state.tagSupernova
})

const actionCreators = {

    //Campaign
    getCampaigns                        : campaignActions.getCampaigns,

    //Pixel
    getPixelsByAccountId                : pixelActions.getPixelsByAccountId,
 
    //Audience
    updateAudience                      : audienceActions.updateAudience,
    createAudience                      : audienceActions.createAudience,
    getAudiencesByPixelId               : audienceActions.getAudiencesByPixelId,
    

    //Measurement
    getMeasurementServicesByAccountId   : measurementActions.getMeasurementServicesByAccountId,
    getConversionDefinitionByAccountId  : measurementActions.getConversionDefinitionByAccountId,
    getAudiencesByAccountId             : measurementActions.getAudiencesByAccountId,
    updateConversionDefinition          : measurementActions.updateConversionDefinition,
    createConversionDefinition          : measurementActions.createConversionDefinition,
    
    //Tag Supernova
    getTagSupernovaByAccountId          : tagSupernovaActions.getTagSupernovaByAccountId,
    
}

const connectedPixelsPage = connect(mapState, actionCreators)(PixelsPage);

export { connectedPixelsPage as PixelsPage };