import '../../_helpers/sort-by.js'
export const listConversionType = [
    {value:'OTHER',                 label:'Other'},
    {value:'LEGACY',                label:'Legacy'},
    {value:'ADD_TO_CART',           label:'Add to cart'},
    {value:'ADD_TO_WISHLIST',       label:'Add to whishlist'},
    {value:'COMPLETE_REGISTRATION', label:'Complete registration'},
    {value:'CONTACT',               label:'Contact'},
    {value:'LEAD',                  label:'Lead'},
    {value:'PAGE_VIEW',             label:'Page view'},
    {value:'PURCHASE',              label:'Purchase'},
    {value:'START_TRIAL',           label:'Start trial'},
    {value:'SUBSCRIBE',             label:'Subscribe'},
].sortBy('label')