import { aiConstants } from '../_constants';
import { aiService } from '../_services';
import { alertActions, progressActions } from './';
import { history } from '../_helpers';

export const aiActions = {
    askAI,
    askAIToProceedProcess,
    cancelAIRequest,
};

function cancelAIRequest() {
    return dispatch => {
        aiService.cancelAIRequest();  // Trigger the service's cancel request
        dispatch({ type: aiConstants.CANCEL_AI_REQUEST });  // Dispatch an action object
    };
}




function askAI(message, thread_id, accountId, campaignId) {
    console.log(thread_id)
    return dispatch => {
        dispatch(request());

        aiService.askAI(message, thread_id, accountId, campaignId)
            .then(
                aiResponse => dispatch(success(aiResponse)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: aiConstants.GET_REQUEST } }
    function success(aiResponse) { return { type: aiConstants.GET_SUCCESS, aiResponse } }
    function failure(error) { return { type: aiConstants.GET_FAILURE, error } }
}

function askAIToProceedProcess(thread_id, campaignId, targetLocation) {
    console.log(thread_id)
    return dispatch => {
        dispatch(request());

        aiService.askAIToProceedProcess(thread_id, campaignId, targetLocation)
            .then(
                aiResponse => dispatch(success(aiResponse)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: aiConstants.GET_REQUEST } }
    function success(aiResponse) { return { type: aiConstants.GET_SUCCESS, aiResponse } }
    function failure(error) { return { type: aiConstants.GET_FAILURE, error } }
}

