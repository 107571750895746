import '../../_helpers/sort-by.js'
export const listInterest = [
    {value:'COMMUNICATION', label:'Communication Tools'},
    {value:'MEN', label:'Men’s Lifestyle'},
    {value:'DATING', label:'Dating & Relationships'},
    {value:'WEATHER', label:'Weather & Environment'},
    {value:'FASHION', label:'Beauty & Fashion'},
    {value:'TRAVEL', label:'Travel and Leisure'},
    {value:'FUN_QUIZZES', label:'Viral, lists & Quizzes'},
    {value:'HEALTH', label:'Health & Fitness'},
    {value:'SCIENCE', label:'Science'},
    {value:'TECHNOLOGY', label:'Technology'},
    {value:'CARS', label:'Automotive'},
    {value:'MEDIA', label:'News'},
    {value:'HOME', label:'Home & Garden'},
    {value:'FAMILY', label:'Family & Parenting'},
    {value:'SHOPPING_COUPONS', label:'Shopping'},
    {value:'ENTERTAINMENT', label:'Arts & Entertainment'},
    {value:'HOBBIES', label:'Hobbies & Interests'},
    {value:'RELIGION', label:'Religion & Spirituality'},
    {value:'MUSIC', label:'Music'},
    {value:'FOOD', label:'Food & Drink'},
    {value:'SPANISH', label:'Spanish Sites'},
    {value:'PETS', label:'Pets'},
    {value:'WOMEN', label:'Women’s Lifestyle'},
    {value:'SPORTS', label:'Sports'},
    {value:'FRENCH', label:'French Sites'},
    {value:'POLITICS_LAW', label:'Law, Gov’t & Politics'},
    {value:'GAMES', label:'Games & Gaming'},
    {value:'FINANCE', label:'Business & Finance'},
    {value:'EDUCATION', label:'Education'},
    {value:'UTILITY', label:'Software & Services'},
    {value:'OTHER', label:'Other'},
    {value:'FOREIGN', label:'International Sites'},
    {value:'PREMIUM', label:'Premium'},
].sortBy('label')