import '../../_helpers/sort-by.js'
export const listPushAdsDevice = [
    {
        "id": 403930,
        "value": "apple_tv",
        "label": "Apple TV"
    },
    {
        "id": 404009,
        "value": "desktop",
        "label": "Desktop"
    },
    {
        "id": 404101,
        "value": "ipad",
        "label": "iPad"
    },
    {
        "id": 404102,
        "value": "iphone",
        "label": "iPhone"
    },
    {
        "id": 404103,
        "value": "ipod",
        "label": "iPod"
    },
    {
        "id": 404124,
        "value": "kindle",
        "label": "Amazon Kindle"
    },
    {
        "id": 404232,
        "value": "other",
        "label": "Other"
    },
    {
        "id": 404243,
        "value": "playstation",
        "label": "Sony Playstation"
    },
    {
        "id": 407271,
        "value": "xbox",
        "label": "Microsoft Xbox"
    }
].sortBy('label')