import { marketResearchConstants } from '../_constants';

export function marketResearch(state = {}, action) {
  switch (action.type) {
    //=====================================
    // GET
    //=====================================
    case marketResearchConstants.GET_REQUEST:
      return {
        ...state,
        isDone: false
      };
    case marketResearchConstants.GET_SUCCESS:
      return {
        items: action.marketresearch,
        isDone: true
      };
    case marketResearchConstants.GET_FAILURE:
      return { 
        error: action.error,
        isDone: true
      };
    //=====================================
    // CREATE MARKET STUDY
    //=====================================
    case marketResearchConstants.CREATE_REQUEST:
      return {
        ...state,
        isDone: false
      };
    case marketResearchConstants.CREATE_SUCCESS:
      return {
        items: action.marketresearch,
        isDone: true
      };
    case marketResearchConstants.CREATE_FAILURE:
      return { 
        error: action.error,
        isDone: true
      };
    //=====================================
    // CREATE CLIENT
    //=====================================
    case marketResearchConstants.CREATE_CLIENT_REQUEST:
      return {
        ...state,
        isDone: false
      };
    case marketResearchConstants.CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        items: action.marketresearch,
        isDone: true
      };
    case marketResearchConstants.CREATE_CLIENT_FAILURE:
      return { 
        error: action.error,
        isDone: true
      };
    //=====================================
    // CREATE BRAND
    //=====================================
    case marketResearchConstants.CREATE_BRAND_REQUEST:
      return {
        ...state,
        isDone: false
      };
    case marketResearchConstants.CREATE_BRAND_SUCCESS:
      return {
        ...state,
        items: action.marketresearch,
        isDone: true
      };
    case marketResearchConstants.CREATE_BRAND_FAILURE:
      return { 
        error: action.error,
        isDone: true
      };
    //=====================================
    // UPDATE
    //=====================================
    case marketResearchConstants.UPDATE_REQUEST:
      return {
        ...state,
        isDone: false
      };
    case marketResearchConstants.UPDATE_SUCCESS:
      return {
        items: action.marketresearch,
        isDone: true
      };
    case marketResearchConstants.UPDATE_FAILURE:
      return { 
        error: action.error,
        isDone: true
      };
    default:
      return state
  }
}