import { brandsConstants } from '../_constants';

export function brands(state = {}, action) {
  switch (action.type) {
    //=====================================
    // GET
    //=====================================
    case brandsConstants.GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case brandsConstants.GET_SUCCESS:
      return {
        items: action.brands
      };
    case brandsConstants.GET_FAILURE:
      return { 
        ...state,
        error: action.error
      };
    //=====================================
    // CREATE
    //=====================================
    case brandsConstants.CREATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case brandsConstants.CREATE_SUCCESS:
      return {
        ...state,
      };
    case brandsConstants.CREATE_FAILURE:
      return { 
        ...state,
        error: action.error
      };
    //=====================================
    // UPDATE
    //=====================================
    case brandsConstants.UPDATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case brandsConstants.UPDATE_SUCCESS:
      return {
        items: action.brands
      };
    case brandsConstants.UPDATE_FAILURE:
      return { 
        ...state,
        error: action.error
      };
      //=====================================
      // DEFAULT
      //=====================================
    default:
      return state
  }
}