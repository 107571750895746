import { duplicatesConstants } from '../_constants';

export function duplicates(state = {}, action) {
  switch (action.type) {
    //=====================================
    // GET
    //=====================================
    case duplicatesConstants.GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case duplicatesConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.duplicates
      };
    case duplicatesConstants.GET_FAILURE:
      return { 
        ...state,
        loading: false,
        error: action.error
      };
    //=====================================
    // CREATE
    //=====================================
    case duplicatesConstants.CREATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case duplicatesConstants.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.duplicates
      };
    case duplicatesConstants.CREATE_FAILURE:
      return { 
        ...state,
        loading: false,
        error: action.error
      };
      //=====================================
      // DEFAULT
      //=====================================
    default:
      return state
  }
}