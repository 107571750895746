export const accountsSupernovaConstants = {
    GET_REQUEST: 'AS_GET_REQUEST',
    GET_SUCCESS: 'AS_GET_SUCCESS',
    GET_FAILURE: 'AS_GET_FAILURE',
    
    GET_MIN_BID_REQUEST: 'AS_GET_MIN_BID_REQUEST',
    GET_MIN_BID_SUCCESS: 'AS_GET_MIN_BID_SUCCESS',
    GET_MIN_BID_FAILURE: 'AS_GET_MIN_BID_FAILURE',
    
    UPDATE_REQUEST: 'AS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'AS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'AS_UPDATE_FAILURE',
};
