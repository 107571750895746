import config from 'config';
import React, { useState, useEffect, Component, useDebugValue, useRef } from 'react'
import {BrowserRouter as Router, Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { CSVLink, CSVDownload } from "react-csv";


//Redux
import { connect } from 'react-redux';
import { 
    aiActions,
    userActions,
    accountSupernovaActions,
    campaignActions,
    sideDrawerActions,
    overlayActions,
    adgroupActions,
    creativeActions,
    statisticActions,
    publisherActions,
    progressActions,
    adgroupCreativeActions,
    creativesPendingActions,
    deactivatedAdgroupActions,
    measurementActions,
    adsActions,
    logSupernovaActions,
    duplicatesActions
} from '../_actions';

import { 
    listIABCategory, 
    listLanguage, 
    listAdgroupType,
    listCampaignType,
    listAdType,

} from '../_constants/data'

//Components
import {SideDrawer} from '../_components/SideDrawer'
import {Overlay} from '../_components/Overlay'
import {LeftNavigation} from '../_components/LeftNavigation'
import {Footer} from '../_components/Footer';
import {LifetimeValue} from '../_components/charts/LifetimeValue';


//Helpers
import '../_helpers/sort-by.js';
import '../_helpers/distinct.js';

//Recharts
import { BarChart, Bar, Brush, ReferenceLine, LineChart, Line, Cell, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer } from 'recharts';


//Moments JS
import Moment from 'react-moment';
import moment from 'moment';


//Toggle
import Toggle from 'react-toggle'
import '../_stylesheets/css/react-toggle.css'

//React bootstrap
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'


//Daterange Picker
// import { DateRangePicker } from 'react-dates'
// import 'react-dates/initialize';
// import 'react-dates/lib/css/_datepicker.css';

//New Date range picker
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { Calendar } from 'react-date-range';
import { DefinedRange } from 'react-date-range';

//React Select
import { listConversionType } from '../_constants/data'
import { styleSelectGoal } from '../_constants/settings'
import { styleSelectBasic, styleSelectMultiple } from '../_constants/settings'
import Select from 'react-select'

//Tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip  from "react-bootstrap/Tooltip";

//Cookies
import Cookies from 'js-cookie';

//Highlight JS
import Highlight from 'react-highlight'

// Globe
import {GlobePrototype} from '../GlobePage';

const DashboardPage = (props) => {

    const useStateWithLabel = (initialValue, label) => {
        const [value, setValue] = useState(initialValue);
        useDebugValue(`${label}: ${value}`);
        return [value, setValue];
    };

    const ref = useRef(null);

    //All Adgroup
    const [isRealtimeLoaded, setIsRealtimeLoaded] = useStateWithLabel(false, "isRealtimeLoaded");
    
    //Show Adgroup Floating Menu
    const [isAdgroupFloatMenuVisible, setIsAdgroupFloatMenuVisible] = useStateWithLabel(false, "isAdgroupFloatMenuVisible");

    //All Adgroup
    const [allAdgroups, setAllAdgroups] = useStateWithLabel(null, "allAdgroups");

    //Sort Entity By
    const [sortCampaignBy, setSortCampaignBy] = useStateWithLabel("name", "sortCampaignBy");


    //Selected Entity
    const [selectedEntityKey, setSelectedEntityKey] = useStateWithLabel(null, "selectedEntityKey");
    const [selectedEntityId, setSelectedEntityId] = useStateWithLabel(null, "selectedEntityId");
    const [focusedCreativeId, setFocusedCreativeId] = useStateWithLabel(null, "focusedCreativeId");

    
    //Group By
    const [groupBy, setGroupBy] = useStateWithLabel("daily", "groupBy");
    
    //Entity Totals
    const [campaignMiniTotal, setCampaignMiniTotal] = useStateWithLabel("impressions", "campaignMiniTotal");
    const [adgroupMiniTotal, setAdgroupMiniTotal] = useStateWithLabel("impressions", "adgroupMiniTotal");
    const [creativeMiniTotal, setCreativeMiniTotal] = useStateWithLabel("impressions", "creativeMiniTotal");


    //Search input fields
    const [campaignSearch, setCampaignSearch] = useStateWithLabel("", "campaignSearch");
    const [adgroupSearch, setAdGroupSearch] = useStateWithLabel("", "adgroupSearch");
    const [creativeSearch, setCreativeSearch] = useStateWithLabel("", "creativeSearch");
    const [publisherSearch, setPublisherSearch] = useStateWithLabel("", "publisherSearch");
    const [publisherPanel, setPublisherPanel] = useStateWithLabel(null, "publisherPanel");
    const [insightPanel, setInsightPanel] = useStateWithLabel(null, "insightPanel");


    //Bid input fields (Helps us determine which Bid is being edited in the dashboard table)
    const [editBidFieldId, setEditBidFieldId] = useStateWithLabel(null, "editBidFieldId");
    const [editBidFieldValue, setEditBidFieldValue] = useStateWithLabel(null, "editBidFieldValue");

    
    //Daily Budget input fields (Helps us determine which Bid is being edited in the dashboard table)
    const [editBudgetFieldId, setEditBudgetFieldId] = useStateWithLabel(null, "editBudgetFieldId");
    const [editBudgetFieldValue, setEditBudgetFieldValue] = useStateWithLabel(null, "editBudgetFieldValue");


    //Returns the current selected campaign and adgroup
    const [selectedSupernovaCampaignId, setSelectedSupernovaCampaignId] = useStateWithLabel(null, "selectedSupernovaCampaignId");
    const [selectedCampaignId, setSelectedCampaignId] = useStateWithLabel(null, "selectedCampaignId");

    const [selectedAdgroup, setSelectedAdgroup] = useStateWithLabel(null, "selectedAdgroup");
    const [visibleStats, setVisibleStats] = useStateWithLabel(null, "visibleStats");
    
    //Sort Table
    const [tableSortBy, setTableSortBy] = useStateWithLabel("date_number", "tableSortBy");
    const [isTableSortDescending, setIsTableSortDescending] = useStateWithLabel(true, "tableSortBy");
    
    //Pagination
    const [paginationOffset, setpaginationOffset] = useStateWithLabel(0, "paginationOffset");


    //Used as the actual Date Range parameter
    const [startDate, setStartDate] = useStateWithLabel(moment().startOf('month'), "startDate");   // Start date is 1st day of last month
    const [endDate, setEndDate] = useStateWithLabel(moment(), "endDate");


    //Returns which input field in the date-range is in focus (either startDate or endDate)
    const [focusedInput, setFocusedInput] = useStateWithLabel(null, "focusedInput");


    //Preloader
    const [totalProcess, setTotalProcess] = useStateWithLabel(3, "totalProcess");
    const [completedProcess, setCompletedProcess] = useStateWithLabel(0, "completedProcess");
    
    //Toggles daterange
    const [isDateRangeActive, setIsDateRangeActive] = useStateWithLabel(false, "isDateRangeActive");
    
    
    //Visible Columns
    const [visibleColumns, setVisibleColumns] = useStateWithLabel([], "visibleColumns");
    const [isShowColumnFormVisible, setIsShowColumnFormVisible] = useStateWithLabel(Cookies.get("isShowColumnFormVisible") === "true" ? true : false, "isShowColumnFormVisible");
    const [isColumnDataSpendVisible, setIsColumnDataSpendVisible] = useStateWithLabel(Cookies.get("isColumnDataSpendVisible") === "true" ? true : false, "isColumnDataSpendVisible");
    const [isColumnMediaSpendVisible, setIsColumnMediaSpendVisible] = useStateWithLabel(Cookies.get("isColumnMediaSpendVisible") === "true" ? true : false, "isColumnMediaSpendVisible");
    const [isColumnMediaECPCVisible, setIsColumnMediaECPCVisible] = useStateWithLabel(Cookies.get("isColumnMediaECPCVisible") === "true" ? true : false, "isColumnMediaECPCVisible");
    const [isColumnMediaECPMVisible, setIsColumnMediaECPMVisible] = useStateWithLabel(Cookies.get("isColumnMediaECPMVisible") === "true" ? true : false, "isColumnMediaECPMVisible");
    
    //Overlay choose campaign type
    const [isCampaignOptionVisible, setIsCampaignOptionVisible] = useStateWithLabel(false, "isCampaignOptionVisible");
    const [selectedNewCampaignType, setSelectedNewCampaignType] = useStateWithLabel(null, "selectedNewCampaignType");
    
    //New Campaign Name for Push Ads
    const [newPushAdCampaignName, setNewPushAdCampaignName] = useStateWithLabel("", "newPushAdCampaignName");
    const [isPushAdNewCampaignFormVisible, setIsPushAdNewCampaignFormVisible] = useStateWithLabel(false, "isPushAdNewCampaignFormVisible");
    const [isPushAdEditCampaignFormVisible, setIsPushAdEditCampaignFormVisible] = useStateWithLabel(false, "isPushAdEditCampaignFormVisible");
    
   
    
    const [associatedConverstionDefinition, setAssociatedConverstionDefinition] = useStateWithLabel(null, "associatedConverstionDefinition");        // False means the 3rd column shows creative True means 3rd column either shows conversion or audience
    const [isAltContent, setIsAltContent] = useStateWithLabel(false, "isAltContent");        // False means the 3rd column shows creative True means 3rd column either shows conversion or audience


    //////////////////////////////////////////////////////////////////////////
    // CONVERSION DEFINITION AND AUDIENCES STATE (SAME AS PIXELSPAGE.JSX)
    //////////////////////////////////////////////////////////////////////////
    const [isEditingForm, setIsEditingForm] = useStateWithLabel(false, "isEditingForm");
    const [isEditPixelWindowVisible, setIsEditPixelWindowVisible] = useStateWithLabel(false, "isEditPixelWindowVisible");
    const [pixelId, setPixelId] = useStateWithLabel("", "pixelId");
    const [pixelName, setPixelName] = useStateWithLabel("", "pixelName");
    const [pixelNotes, setPixelNotes] = useStateWithLabel("", "pixelNotes");
    const [pixelConversionType, setPixelConversionType] = useStateWithLabel("", "pixelConversionType")
    const [pixelEventName, setPixelEventName] = useStateWithLabel("", "pixelEventName")

    
    const [isEditAudienceWindowVisible, setIsEditAudienceWindowVisible] = useStateWithLabel(false, "isEditAudienceWindowVisible");
    const [audienceId, setAudienceId] = useStateWithLabel("", "audienceId");
    const [audienceName, setAudienceName] = useStateWithLabel("", "audienceName");
    const [audienceNotes, setAudienceNotes] = useStateWithLabel("", "audienceNotes");
    const [audiencePixel, setAudiencePixel] = useStateWithLabel("", "audiencePixel");
    const [audienceTraffic, setAudienceTraffic] = useStateWithLabel("", "audienceTraffic");
    const [audienceTrafficInTheLast, setAudienceTrafficInTheLast] = useStateWithLabel("", "audienceTrafficInTheLast");
    const [audienceRules, setAudienceRules] = useStateWithLabel([], "audienceRules");

    const [isCopyPixelWindowVisible, setIsCopyPixelWindowVisible] = useStateWithLabel(false, "isCopyPixelWindowVisible");
    
    const [conversionTrigger, setConversionTrigger] = useStateWithLabel("RULE", "conversionTrigger");
    const [attributionModelCount, setAttributionModelCount] = useStateWithLabel("ONE", "attributionModelCount");
    const [attributionModelType, setAttributionModelType] = useStateWithLabel("FIRST", "attributionModelType");
    const [measurementMatchingRules_actionType, setMeasurementMatchingRules_actionType] = useStateWithLabel("CONTAINS", "measurementMatchingRules_actionType");
    const [measurementMatchingRules_value, setMeasurementMatchingRules_value] = useStateWithLabel("", "measurementMatchingRules_value");
    const [clickConversionWindow, setClickConversionWindow] = useStateWithLabel(1, "clickConversionWindow");
    const [viewConversionWindow, setViewConversionWindow] = useStateWithLabel(1, "viewConversionWindow");
    const [isTagCopied, setIsTagCopied] = useStateWithLabel(false, "isTagCopied");

    const [selectedConversion, setSelectedConversion] = useStateWithLabel(null, "selectedConversion");
    const [selectedAudience, setSelectedAudience] = useStateWithLabel(null, "selectedAudience");

    const [tabKey, setTabKey] = useStateWithLabel("reports", "tabKey");
    
    const [filteredCreatives, setFilteredCreatives] = useStateWithLabel([], "filteredCreatives");

    

    ////////////////////////////////////////////////
    //Insights
    ////////////////////////////////////////////////
    const [insights, setInsights] = useStateWithLabel({
        headings:["DMA", "Impressions", "Clicks", "CTR", "Total Spend", "Yesterday Spend", "eCPC"],
        rows:[ [], [], [], [], [], [], [] ],
        selected:"TOP5_DMA_BY_CTR",
    }, "insights")


    ////////////////////////////////////////////////
    // SUPERNOVA AI STATES
    ////////////////////////////////////////////////
    const [showAITab, setShowAITab]                     = useStateWithLabel(false, "showAITab");
    const [showAIWindow, setShowAIWindow]               = useStateWithLabel(false, "showAIWindow");
    const [userMessage, setUserMessage]                 = useStateWithLabel("", "userMessage");
    const [currentStep, setCurrentStep]                 = useStateWithLabel("NEW_QUERY","currentStep")
    const [adgroupName, setAdgroupName]                 = useStateWithLabel("Adgroup 1","adgroupName")
    const [outputMsg, setOutputMsg]                     = useStateWithLabel("", "outputMsg")
    const [lastMessageFromAI, setLastMessageFromAI]     = useStateWithLabel("", "lastMessageFromAI")
    const [audienceDescription, setAudienceDescription] = useStateWithLabel("", "audienceDescription")
    const [selectedState, setSelectedState]             = useStateWithLabel([], "selectedState")
    // const [globeData, setGlobeData]                  = useStateWithLabel([], "globeData")
    // const [globeData, setGlobeData]                     = useStateWithLabel([["00601", 21000, 18.180555, -66.749961]], "globeData") //For testing ["zipcode", population, latitude, longitude]
    const [globeData, setGlobeData]                     = useStateWithLabel([], "globeData")
    const [totalReach, setTotalReach]                   = useStateWithLabel(0, "totalReach")
    const [listLongLat, setListLongLat]                 = useStateWithLabel([], "listLongLat")
    const [aiMessageLog, setAIMessageLog]               = useStateWithLabel([], "aiMessageLog")




    const { user, users, ai, campaign, campaigns, adgroup, adgroups, creative, creatives, statistics, publishers, adgroupCreatives, sideDrawer, creativesPending, measurement, ads, logSupernova, onClickOutside, duplicates } = props;

    //State of daterange
    const [state, setState] = useState([
        {
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            key: 'selection'
        }
    ]);
    
    
    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        props.getAllAdgroups(user.accountId);
        props.getCampaigns(user.accountId);
        props.getCreativesInAccount(user.accountId)
        props.getAdgroupCreatives(user.accountId)
        props.getCreativesPending()
        props.getUserById(user.id);
        props.getInactiveCampaigns(user.accountId);
        props.getDeactivatedAdgroups(user.accountId)
        
        //Get Measurement (Conversion Definitions and Audiences)
        props.getConversionDefinitionByAccountId(user.accountId)
        props.getMeasurementServicesByAccountId(user.accountId)
        props.getAudiencesByAccountId(user.accountId)
        
        props.getDuplicatedCampaignsByAccountId(user.accountId)
        props.getDailyLogByAccountIdAndCreativeIds(user.accountId, filteredCreatives)


        // props.getDailyLogByAccountIdAndCreativeIds(user.accountId, filteredCreatives)
        
        // console.log("xxxxxxxxxxxxxx USER TYPE xxxxxxxxxxxxxx ")
        // console.log(getUserType())
        // console.log("xxxxxxxxxxxxxxXXXXXXXXXXXxxxxxxxxxxxxxx ")


        /////////////////////////////////////
        // LOAD GLOBE FOR SUPERNOVA AI
        /////////////////////////////////////
        const fetchData = async () => {
            try {
                const response = await fetch(`https://omni.supernova.inc/public/uploads/data/lnglat.json?v=1`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log("Longitude and Latitude Loaded")
                setListLongLat(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
        addToAIMessageLog(`Hi ${user.firstName}. I am Supernova AI. I can help you create a custom audience and ad group. Let’s begin with a description of what your product / service is, and the type of audience you want to reach.`, "AI")
    },[]);
    
    //During the first load, get all adgroups and store it in state
    useEffect(() => {
        // console.log("adgroup changed")
        if(allAdgroups == null){
            setAllAdgroups(adgroups.items)
        }

        //Set Filtered Creatives for Realtime when campaign is chosen
        //This will define all creatives based on given list of adgroups under the selected campaign.
        let adgroupsInCurrentCampaign = []
        if(adgroups && adgroups.items && selectedEntityKey == "supernovaCampaignId"){
            adgroupsInCurrentCampaign = adgroups.items.filter(adgroup => adgroup.supernovaCampaignId == selectedEntityId).map(item => item.id)
            // console.log(adgroupsInCurrentCampaign)
            // TODO (Status: IN PROGRESS): Get list of creatives from ads table instead of adgroup_creatives
            setFilteredCreatives(adgroupCreatives.items.filter(adgroupCreative => adgroupsInCurrentCampaign.indexOf(adgroupCreative.supernovaAdgroupId) !== -1).map(item => item.supernovaCreativeId).sort())
        }

        if(adgroups.isDone && !logSupernova.loading){
            setIsRealtimeLoaded(true)
        }
    },[adgroups]);

    //Detects clicking outside the adgroup float menu
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsAdgroupFloatMenuVisible(false)
                onClickOutside && onClickOutside();
          }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
        };
      }, [ onClickOutside ]);
    


    //////////////////////////////////////////////////////////// 
    // Timer Interval to consistently check the following: 
    // - Pending Creatives
    // - Locked Adgroups (list of those that are processing)
    //////////////////////////////////////////////////////////// 
    const [seconds, setSeconds] = useStateWithLabel(0, "seconds");
    useEffect(() => {
        setTimeout(() => {
                // if(seconds < 1){
                    setSeconds(seconds => seconds + 1);
                // }
        }, 50000); //Checks every x milliseconds (Increase if it is linking creatives twice) NOTE: In the future if it still creating duplicated creative consider logging batchId in the DB that is already done so it doesn't link more than once.
        
        // console.log("check batch status")
        // Check pending creatives from ZMT
        if(creativesPending && 
            creativesPending.items && 
            creativesPending.isCheckingUploadStatus != true && 
            creativesPending.isDone == true && 
            creativesPending.items.filter(item => item.status == "pending").length > 0)
            {
                creativesPending.items.filter(item => item.status == "pending").map(item => {
                let adGroupId = item.adGroupId
                let zemantaAdgroupId = adgroups?.items.find(adgroup => adgroup?.supernovaAdgroupId == adGroupId && adgroup.source == "ZMT")?.id || ""
                let supernovaAdgroupId = adgroups?.items.find(adgroup => adgroup?.supernovaAdgroupId == adGroupId && adgroup.source == "ZMT")?.supernovaAdgroupId || ""
                console.log(`zemantaAdgroupId: ${zemantaAdgroupId}`)
                console.log(`supernovaAdgroupId: ${supernovaAdgroupId}`)
                props.checkUploadStatus(item.batchId, user.accountId, supernovaAdgroupId, zemantaAdgroupId)
            })
        }

        // Check locked adgroups
        props.getLockList(selectedSupernovaCampaignId)

    }, [seconds]);
    /////////////////////////////////////////////////////////////////////////////////////////////////
    // Timer Interval to consistently check for Creative Stats when the table for it is being shown
    /////////////////////////////////////////////////////////////////////////////////////////////////
    const [secondsCreativeStats, setSecondsCreativeStats] = useStateWithLabel(0, "secondsCreativeStats");
    useEffect(() => {
        setTimeout(() => {
                // if(seconds < 1){
                    setSecondsCreativeStats(secondsCreativeStats => secondsCreativeStats + 1);
                // }
        }, 5000); //Checks every x milliseconds

        if(logSupernova.loading == false && tabKey == "realtime"){
            props.getDailyLogByAccountIdAndCreativeIds(user.accountId, filteredCreatives)
        }
    }, [secondsCreativeStats]);

    
    useEffect(() => {
       if(creativesPending.isReloadRequired == true){
            props.getCreativesInAccount(user.accountId)
            props.getAdgroupCreatives(user.accountId)
            props.getCreativesPending()
       }
    }, [creativesPending.isReloadRequired]);

    
    useEffect(() => {
        if(campaigns.isDone){
            console.log("getStatsPerCampaign()")
            props.getStatsPerCampaign(user.accountId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
            setpaginationOffset(0);
        }
    },[
        campaigns.isDone,
    ]);

    useEffect(() => {
        const chatContainer = document.getElementById('chat-container');
        chatContainer.scrollTop = chatContainer.scrollHeight;
    },[aiMessageLog]); 

    useEffect(() => {
        setShowAITab(campaigns?.items?.find(item => item.id == selectedCampaignId)?.budgets && new Date() < new Date(getMaxEndDate(selectedCampaignId)) ? false : true)
    },[selectedCampaignId]); 

    //Commented out temporarily because its too heavy. (This is the publisher badge)
    // useEffect(() => {
    //     if(statistics.isCampaignDone){
    //         campaigns.items && campaigns.items.map(campaign => {
    //             props.getTotalPublishersPerCampaign(campaign.supernovaCampaignId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
    //         })
    //     }
    // },[
    //     statistics.isCampaignDone,
    // ]);


    useEffect(() => {
        if(adgroups.isDone){
            adgroups.items && adgroups.items.map(adgroup => {
                // props.getTotalPublishersPerAdgroup(adgroup.supernovaAdgroupId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));  
            })
            // console.log("getStatsPerAdgroup()")
            props.getStatsPerAdgroup(user.accountId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
            setpaginationOffset(0);
        }
    },[
        adgroups.isDone,
    ]);
    
    useEffect(() => {
        if(creatives.isDone){
            creatives.items && creatives.items.map(adgroup => {
                // props.getTotalPublishersPerAdgroup(adgroup.supernovaAdgroupId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));  
            })
            // console.log("getStatsPerCreative()")
            props.getStatsPerCreative(user.accountId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
        }
    },[
        creatives.isDone,
    ]);

    /**********************************
     * Insights: Top DMA By CTR
     **********************************/
    useEffect(() => {
        if(statistics.isTopDMAByCtrDone){
            setInsights({
                ...insights, 
                rows: statistics.topDMAByCtr && statistics.topDMAByCtr.map((item, index) => {
                        return([
                            item.dmaName == "" ? "N/A" : item.dmaName,                                  // DMA
                            item.impressions.toLocaleString(),                                          // Impressions
                            item.clicks.toLocaleString(),                                               // Clicks
                            `${parseFloat((item.clicks / item.impressions) * 100).toFixed(2)} %`,                               // CTR
                            `${item.mediaSpend.toLocaleString(undefined, {                                   // Total Spend
                                minimumFractionDigits: 2, 
                                maximumFractionDigits: 2
                            })}`,
                            `${item.yesterdaySpend.toLocaleString(undefined, {                          // Yesterday Spend
                                minimumFractionDigits: 2, 
                                maximumFractionDigits: 2
                            })}`,   
                            `$${parseFloat(item.clicks == 0 ? 0 : item.mediaSpend / item.clicks).toLocaleString(undefined, {        // eCPC
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                            })}`, 
                        ])
                }), 
            });
        }
    },[statistics.isTopDMAByCtrDone]);
    
    /**********************************
     * Insights: Top Countries By CTR
     **********************************/
    useEffect(() => {
        if(statistics.isTopCountriesByCtrDone){
            setInsights({
                ...insights, 
                rows: statistics.topCountriesByCtr && statistics.topCountriesByCtr.map((item, index) => {
                    return([
                        item.countryName == "" ? "N/A" : item.countryName,                              // Country
                        item.impressions.toLocaleString(),                                              // Impressions
                            item.clicks.toLocaleString(),                                               // Clicks
                            `${parseFloat((item.clicks / item.impressions) * 100).toFixed(2)} %`,                               // CTR
                            `${item.mediaSpend.toLocaleString(undefined, {                                   // Total Spend
                                minimumFractionDigits: 2, 
                                maximumFractionDigits: 2
                            })}`,
                            `${item.yesterdaySpend.toLocaleString(undefined, {                          // Yesterday Spend
                                minimumFractionDigits: 2, 
                                maximumFractionDigits: 2
                            })}`,   
                            `$${parseFloat(item.clicks == 0 ? 0 : item.mediaSpend / item.clicks).toLocaleString(undefined, {        // eCPC
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                            })}`, 
                    ])
                }), 
            });
        }
    },[statistics.isTopCountriesByCtrDone]);
    
    /**********************************
     * Insights: Top State By CTR
     **********************************/
    useEffect(() => {
        if(statistics.isTopStatesByCtrDone){
            setInsights({
                ...insights, 
                rows: statistics.topStatesByCtr && statistics.topStatesByCtr.map((item, index) => {
                    return([
                        item.stateName == "" ? "N/A" : item.stateName.replace(", United States", ""),   // State
                        item.impressions.toLocaleString(),                                              // Impressions
                            item.clicks.toLocaleString(),                                               // Clicks
                            `${parseFloat((item.clicks / item.impressions) * 100).toFixed(2)} %`,                               // CTR
                            `${item.mediaSpend.toLocaleString(undefined, {                                   // Total Spend
                                minimumFractionDigits: 2, 
                                maximumFractionDigits: 2
                            })}`,
                            `${item.yesterdaySpend.toLocaleString(undefined, {                          // Yesterday Spend
                                minimumFractionDigits: 2, 
                                maximumFractionDigits: 2
                            })}`,   
                            `$${parseFloat(item.clicks == 0 ? 0 : item.mediaSpend / item.clicks).toLocaleString(undefined, {        // eCPC
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                            })}`, 
                    ])
                }), 
            });
        }
    },[statistics.isTopStatesByCtrDone]);
    
    
    /**********************************
     * Watch status of logSupernova.isLoaded
     **********************************/
    useEffect(() => {
        if(logSupernova.loading == false){
            setIsRealtimeLoaded(true)
        }
    },[logSupernova]);
    

    /**
    *
    * UPDATE PRELOADER
    *
    * On startup we only show preloader on the following:
    * 1. Campaign Stats
    * 2. Chart 1 (Daily)
    * 3. Chart 2 (Monthly)
    * 4. Count Total Publishers per campaignID
    * Note thats why we have 4 as default value for totalProcess
    *
    *
    * But when the user changes the date-range and we need to show preloader this time we include the following:
    * 1. Campaign Stats
    * 2. Adgroup Stats
    * 3. Creative Stats
    * 4. Chart 1 (Daily)
    * 5. Chart 2 (Monthly)
    * 6. Count Total Publishers per campaignID
    * 7. Count Total Publishers per adgroupdID
    * 8. Count Total Publishers per creativeID
    * Note thats why we setTotalProcess(8) when date range is updated
    */
    useEffect(() => {statistics.isDailyDone && setCompletedProcess(completedProcess + 1); statistics.isDailyDone },[statistics.isDailyDone]);
    useEffect(() => {statistics.isMonthlyDone && setCompletedProcess(completedProcess + 1); statistics.isMonthlyDone },[statistics.isMonthlyDone]);
    useEffect(() => {statistics.isCampaignDone && setCompletedProcess(completedProcess + 1); statistics.isCampaignDone },[statistics.isCampaignDone]);
    useEffect(() => {statistics.isAdgroupDone && setCompletedProcess(completedProcess + 1); statistics.isAdgroupDone },[statistics.isAdgroupDone]);
    useEffect(() => {statistics.isCreativeDone && setCompletedProcess(completedProcess + 1); statistics.isCreativeDone },[statistics.isCreativeDone]);
    
    useEffect(() => {
        props.updatePreloader((completedProcess + 1), totalProcess);
    },[
        statistics.isCampaignDone,
        statistics.isAdgroupDone, 
        statistics.isCreativeDone,
        statistics.isDailyDone,
        statistics.isMonthlyDone,
        publishers.isPublisherCountPerAdgroupDone,
        publishers.isPublisherCountPerCreativeDone,
    ]);

    // Hide important campaigns for testers to avoid changing any settings
    const isHiddenForTesting = (supernovaCampaignId) => {
        const testers = ["bernard@arbigo.com", "sergey@arbigo.com"]
        const campaignsToHide = [
                "41710139",     // Excellence MScroller 300x600
                "3929181",      // Excellence Resorts Display 2021
                "40258708",     // Duplicate of Excellence Resorts Display 2021
                "5011686",      // Excellence Resorts Native 2021
                "12588609",     // Tinsley FL Keys 2021-2022 IO#108 and IO#110
                "1678311533223",     // Susana's Push
        ]
        if( testers.includes(users.current.username) && campaignsToHide.includes(supernovaCampaignId) ){
            return true;
        }
        else{
            return false;
        }
    }

    //Show all campaigns in Account
    const showCampaignsPanel = () => {

        // console.log("showCampaignsPanel()")

        //1. Set Selected Keys
        setSelectedEntityKey(null);
        setSelectedEntityId(null);
        
        setSelectedSupernovaCampaignId(null)
        setSelectedCampaignId(null)

        setSelectedAdgroup(null);
        
        //2. Get Campaign data
        // props.getCampaigns(user.accountId);
        
        //3. Set Publisher Data
        setPublisherPanel("account")
        setpaginationOffset(0)

        //4. Set Sort By
        setTableSortBy("id");

        //5. Update the dashboard table
        changeStatisticsTable(null, groupBy, user.accountId);

        // 6. If the user is currently in AI prompt tab set the default tab back to Reports
        if(tabKey == "ai"){
            setTabKey("realtime")
        }

    }

    //Show all adgroups in a selected Campaign
    const showAdgroupPanel = (supernovaCampaignId) => {

        // console.log("showAdgroupPanel()")

        setSelectedNewCampaignType(campaigns.items.find(item => item.supernovaCampaignId == supernovaCampaignId)?.type);

        //1. Set Selected Keys
        setSelectedEntityKey("supernovaCampaignId");
        setSelectedEntityId(supernovaCampaignId);
        setSelectedSupernovaCampaignId(supernovaCampaignId)
        setSelectedCampaignId(campaigns.items.find(item => item.supernovaCampaignId == supernovaCampaignId)?.id);
        setSelectedAdgroup(null);

        //2. Get Ad group data and those that are locked (in progress)
        props.getAdgroups(supernovaCampaignId);
        props.getLockList(selectedSupernovaCampaignId);

        //3. Set Publisher Data
        setPublisherPanel("campaign")
        setpaginationOffset(0)

        //4. Set Sort By
        setTableSortBy("id");

        //5. Update the dashboard table
        changeStatisticsTable("supernovaCampaignId", groupBy, supernovaCampaignId)

        if(supernovaCampaignId !== null){
            let goals = props.campaigns.items.find(items => items.id == supernovaCampaignId)?.goals
            let cpa = (goals?.length > 0 && goals[0] != null) ? goals.filter(item => item.type == "CPA") : []
            let conversionDefinitionId = cpa.length > 0 ? cpa[0].conversionGoal.conversionDefinitionId : null
            setAssociatedConverstionDefinition(conversionDefinitionId);
        }

        //6. Update real time data
        // props.getLogSupernovaByAccountId(user.accountId, focusedCreativeId)
        props.getDailyLogByAccountIdAndCreativeIds(user.accountId, filteredCreatives)

    }
    
    //Show all creatives in a selected Adgroup
    const showCreativePanel = (supernovaAdgroupId) => {

        console.log("showCreativePanel()")

        //1. Set Selected Keys
        setSelectedEntityKey("supernovaAdgroupId");
        setSelectedEntityId(supernovaAdgroupId);
        setSelectedAdgroup(supernovaAdgroupId)

        //2. Get Ad group data
        // props.getCreativesInAdgroup(supernovaAdgroupId);

        //3. Set Publisher Data
        setPublisherPanel("adgroup")
        setpaginationOffset(0)
        
        //4. Set Sort By
        setTableSortBy("id");

        //5. Update the dashboard table
        changeStatisticsTable("supernovaAdgroupId", groupBy, supernovaAdgroupId)

        //6. Load Ads for the selected supernovaAdgroupId
        props.getByAdGroupId(supernovaAdgroupId);

        //Set Filtered Creatives for Realtime when adgroup is chosen
        //This will define all creatives under the selected adgroupid

        // TODO (Status: IN PROGRESS): Get list of creatives from ads table instead of adgroup_creatives
        setFilteredCreatives(adgroupCreatives.items.filter(adgroupCreative => adgroupCreative.supernovaAdgroupId == supernovaAdgroupId).map(item => item.supernovaCreativeId))
        console.log(adgroupCreatives.items.filter(adgroupCreative => adgroupCreative.supernovaAdgroupId == supernovaAdgroupId).map(item => item.supernovaCreativeId))
    }

    //Show stats in a selected Creative
    const showCreativeStats = (supernovaCreativeId) => {

        console.log("showCreativePanel()")

        //1. Set Selected Keys
        setSelectedEntityKey("supernovaCreativeId");
        setSelectedEntityId(supernovaCreativeId);
        setFocusedCreativeId(supernovaCreativeId)

        //2. Get Ad group data
        // props.getCreativesInAdgroup(supernovaAdgroupId);

        //Update real time data
        props.getLogSupernovaByAccountId(user.accountId, focusedCreativeId)


        //3. Set Publisher Data
        // setPublisherPanel("adgroup")
        // setpaginationOffset(0)
        
        //4. Set Sort By
        // setTableSortBy("id");

        //5. Update the dashboard table
        // changeStatisticsTable("supernovaCreativeId", groupBy, supernovaCreativeId)

        //6. Load Ads for the selected supernovaAdgroupId
        // props.getByAdGroupId(supernovaCreativeId);

    }

    /*************************************
     Stats group by publishers
    *************************************/
    const showPublishersByAccountId = (accountId) =>{
        props.getPublishersInAccount(accountId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'));
        setPublisherPanel("account")
        setInsightPanel(null)
    }
    const showPublishersByCampaignId = (accountId, supernovaCampaignId) =>{
        props.getPublishersInCampaign(accountId, supernovaCampaignId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'));
        // setSelectedSupernovaCampaignId(supernovaCampaignId)
        setPublisherPanel("campaign")
        setInsightPanel(null)
    }
    const showPublishersByAdgroupId = (accountId, supernovaAdgroupId) =>{
        props.getPublishersInAdgroup(accountId, supernovaAdgroupId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'));
        // setSelectedAdgroup(supernovaAdgroupId)
        setPublisherPanel("adgroup")
        setInsightPanel(null)
    }

    /*************************************
     Stats group by country
    *************************************/
     const showCountriesByAccountId = (accountId) =>{
        props.getStatsByCountry("accountId", accountId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'), user.accountId);
        setPublisherPanel("account")
        setInsightPanel(null)
    }
    const showCountriesByCampaignId = (supernovaCampaignId) =>{
        props.getStatsByCountry("supernovaCampaignId", supernovaCampaignId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'), user.accountId);
        setSelectedSupernovaCampaignId(supernovaCampaignId)
        setPublisherPanel("campaign")
        setInsightPanel(null)
    }
    const showCountriesByAdgroupId = (supernovaAdgroupId) =>{
        props.getStatsByCountry("supernovaAdgroupId", supernovaAdgroupId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'), user.accountId);
        setSelectedAdgroup(supernovaAdgroupId)
        setPublisherPanel("adgroup")
        setInsightPanel(null)
    }

    /*************************************
     Stats group by zone
    *************************************/
     const showZoneByAccountId = (accountId) =>{
        props.getStatsByZone("accountId", accountId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'), user.accountId);
        setPublisherPanel("account")
        setInsightPanel(null)
    }
    const showZoneByCampaignId = (supernovaCampaignId) =>{
        props.getStatsByZone("supernovaCampaignId", supernovaCampaignId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'), user.accountId);
        setSelectedSupernovaCampaignId(supernovaCampaignId)
        setPublisherPanel("campaign")
        setInsightPanel(null)
    }
    const showZoneByAdgroupId = (supernovaAdgroupId) =>{
        props.getStatsByZone("supernovaAdgroupId", supernovaAdgroupId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'), user.accountId);
        setSelectedAdgroup(supernovaAdgroupId)
        setPublisherPanel("adgroup")
        setInsightPanel(null)
    }

    /*************************************
     Stats group by device
    *************************************/
     const showDevicesByAccountId = (accountId) =>{
        props.getStatsByDevice("accountId", accountId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'), user.accountId);
        setPublisherPanel("account")
        setInsightPanel(null)
    }
    const showDevicesByCampaignId = (supernovaCampaignId) =>{
        props.getStatsByDevice("supernovaCampaignId", supernovaCampaignId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'), user.accountId);
        setSelectedSupernovaCampaignId(supernovaCampaignId)
        setPublisherPanel("campaign")
        setInsightPanel(null)
    }

    const showDevicesByAdgroupId = (supernovaAdgroupId) =>{
        props.getStatsByDevice("supernovaAdgroupId", supernovaAdgroupId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'), user.accountId);
        setSelectedAdgroup(supernovaAdgroupId)
        setPublisherPanel("adgroup")
        setInsightPanel(null)
    }

    /*************************************
     Stats group by Environment
    *************************************/
    const showEnvironmentsByAccountId = (accountId) =>{
        props.getStatsByEnvironment("accountId", accountId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'), user.accountId);
        setPublisherPanel("account")
        setInsightPanel(null)
    }
    const showEnvironmentsByCampaignId = (supernovaCampaignId) =>{
        props.getStatsByEnvironment("supernovaCampaignId", supernovaCampaignId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'), user.accountId);
        setSelectedSupernovaCampaignId(supernovaCampaignId)
        setPublisherPanel("campaign")
        setInsightPanel(null)
    }
    const showEnvironmentsByAdgroupId = (supernovaAdgroupId) =>{
        props.getStatsByEnvironment("supernovaAdgroupId", supernovaAdgroupId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'), user.accountId);
        setSelectedAdgroup(supernovaAdgroupId)
        setPublisherPanel("adgroup")
        setInsightPanel(null)
    }
    
    /*************************************
     Stats group by OS
    *************************************/
    const showOSByAccountId = (accountId) =>{
        props.getStatsByOS("accountId", accountId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'), user.accountId);
        setPublisherPanel("account")
        setInsightPanel(null)
    }
    const showOSByCampaignId = (supernovaCampaignId) =>{
        props.getStatsByOS("supernovaCampaignId", supernovaCampaignId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'), user.accountId);
        setSelectedSupernovaCampaignId(supernovaCampaignId)
        setPublisherPanel("campaign")
        setInsightPanel(null)
    }
    const showOSByAdgroupId = (supernovaAdgroupId) =>{
        props.getStatsByOS("supernovaAdgroupId", supernovaAdgroupId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'), user.accountId);
        setSelectedAdgroup(supernovaAdgroupId)
        setPublisherPanel("adgroup")
        setInsightPanel(null)
    }

    /*************************************
     Stats group by State
    *************************************/
     const showStatesByAccountId = (accountId) =>{
        props.getStatsByState("accountId", accountId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'), user.accountId);
        setPublisherPanel("account")
        setInsightPanel(null)
    }
    const showStatesByCampaignId = (supernovaCampaignId) =>{
        props.getStatsByState("supernovaCampaignId", supernovaCampaignId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'), user.accountId);
        setSelectedSupernovaCampaignId(supernovaCampaignId)
        setPublisherPanel("campaign")
        setInsightPanel(null)
    }
    const showStatesByAdgroupId = (supernovaAdgroupId) =>{
        props.getStatsByState("supernovaAdgroupId", supernovaAdgroupId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'), user.accountId);
        setSelectedAdgroup(supernovaAdgroupId)
        setPublisherPanel("adgroup")
        setInsightPanel(null)
    }

    /*************************************
     Stats group by DMA
    *************************************/
     const showDMAByAccountId = (accountId) =>{
        props.getStatsByDMA("accountId", accountId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'), user.accountId);
        setPublisherPanel("account")
        setInsightPanel(null)
    }
    const showDMAByCampaignId = (supernovaCampaignId) =>{
        props.getStatsByDMA("supernovaCampaignId", supernovaCampaignId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'), user.accountId);
        setSelectedSupernovaCampaignId(supernovaCampaignId)
        setPublisherPanel("campaign")
        setInsightPanel(null)
    }

    const showDMAByAdgroupId = (supernovaAdgroupId) =>{
        props.getStatsByDMA("supernovaAdgroupId", supernovaAdgroupId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'), user.accountId);
        setSelectedAdgroup(supernovaAdgroupId)
        setPublisherPanel("adgroup")
        setInsightPanel(null)
    }

    /*************************************
     Stats group by Media Sources
    *************************************/
     const showMediaSourcesByAccountId = (accountId) =>{
        props.getStatsByMediaSources("accountId", accountId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'), user.accountId);
        setPublisherPanel("account")
        setInsightPanel(null)
    }
    const showMediaSourcesByCampaignId = (supernovaCampaignId) =>{
        props.getStatsByMediaSources("supernovaCampaignId", supernovaCampaignId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'), user.accountId);
        setSelectedSupernovaCampaignId(supernovaCampaignId)
        setPublisherPanel("campaign")
        setInsightPanel(null)
    }

    const showMediaSourcesByAdgroupId = (supernovaAdgroupId) =>{
        props.getStatsByMediaSources("supernovaAdgroupId", supernovaAdgroupId, moment(state[0].startDate).format('YYYY-MM-DD'), moment(state[0].endDate).format('YYYY-MM-DD'), user.accountId);
        setSelectedAdgroup(supernovaAdgroupId)
        setPublisherPanel("adgroup")
        setInsightPanel(null)
    }

    /*************************************
     Stats by Insights
    *************************************/
    const editCampaign = (form, purpose, campaignID, campaignType)  => {
        if(campaignType == "PUSH_NOTIFICATION"){
            let index   = campaigns.items.findIndex(item => item.id == campaignID);
            let name    = index < 0 ? "" : campaigns.items[index].name;
            setNewPushAdCampaignName(name)
            setIsPushAdEditCampaignFormVisible(true)
        }
        else{
            props.openSideDrawer(form, purpose);
        }
        setSelectedNewCampaignType(campaignType)
        props.selectCampaign(campaignID)
    }
    
    const editAdgroup = (form, purpose, adgroupID)  => {
        props.openSideDrawer(form, purpose);
        props.selectAdgroup(adgroupID)
    }

    const createCampaign = (form, purpose)  => {
        setNewPushAdCampaignName("")
        props.openSideDrawer(form, purpose);
    }

    const createAdgroup = (form, purpose)  => {
        setSelectedNewCampaignType(null)
        props.openSideDrawer(form, purpose);
    }

    const createCreative = (form, purpose, supernovaAdgroupId)  => {
        props.openOverlay(form, purpose, supernovaAdgroupId);
    }

    const editCreative = (form, purpose, supernovaAdgroupId,  supernovaCreativeId)  => {
        props.openOverlay(form, purpose, supernovaAdgroupId, supernovaCreativeId);
    }

    const showStats = (panel) => {
        setVisibleStats(panel)
    }

    const exportTable = (tableId) => {
        
    }

    const getCampaignNameByID = (supernovaCampaignId) => {
        // console.log(campaigns.items);
        return campaigns.items && [campaigns.items.filter(campaign => campaign.supernovaCampaignId == supernovaCampaignId).reduce.call(campaigns.items, (acc, cur) => cur)].name
    }

    const getAdgroupNameBydId = (supernovaAdgroupId) => {
        return adgroups.items.filter(adgroup => adgroup.supernovaAdgroupId == supernovaAdgroupId)[0]?.name
    }

    const getEntityLabel = () => {
        switch(selectedEntityKey){
            case null: return "Campaign"; break;
            case "supernovaCampaignId": return "Ad Group"; break;
            case "supernovaAdgroupId": return "Creative"; break;
        }
    }

    const getMiniInsightValue = (id, entity, property) => {
        
        //If it is spend:
        if(property == "spend"){
            if(getUserType() == "supernova"){
                property = "dataspend_with_margin"
            }
            if(getUserType() == "direct-client"){
                property = "total_spend_client"
            }
            if(getUserType() == "sub-client"){
                property = "total_spend_subclient"
            }
        }


        if(statistics[entity]){
            const arr = statistics[entity].filter(item => item._id == id);
            return arr.length > 0 ? arr.map(item => {return item[property]}) : 0;
        }
    }

    /** FOR PAGINATION **/
    const tableRowsPerPage = 20;
    const publisherNextPage = () => {
        setpaginationOffset(paginationOffset + tableRowsPerPage);
    }

    const publisherPrevPage = () => {
        setpaginationOffset(paginationOffset - tableRowsPerPage);
    }

    let countTableRows = 
            (groupBy == "entity" && selectedEntityKey == "supernovaAdgroupId") ? 
                    creatives.items.filter(creative => 
                        // TODO (Status: IN PROGRESS): Get list of creatives from ads table instead of adgroup_creatives
                        adgroupCreatives.items.filter(adgroupCreative => adgroupCreative.supernovaAdgroupId == selectedAdgroup).map(item => {return item.supernovaCreativeId}).includes(creative.id) //Filters the creatives under the selected adgroup
                    ).length

            :(groupBy == "daily") ? 
                statistics["daily"] && endDate.diff(startDate, 'days')+1
                
            : (groupBy == "devices"       || 
            groupBy == "os"             || 
            groupBy == "countries"      || 
            groupBy == "environments"   || 
            groupBy == "dma"            || 
            groupBy == "state"          || 
            groupBy == "media_sources"  ) ? 
                    statistics[groupBy] && statistics[groupBy].length

            : (groupBy == "publisher" && publisherPanel == "account") ? 
                props.publishers.account && props.publishers.account.filter(account => 
                        account.publisher.toString().toUpperCase().indexOf(publisherSearch.toUpperCase()) >= 0 ||
                        account.mediaSource.toString().toUpperCase().indexOf(publisherSearch.toUpperCase()) >= 0
                        ).length

            : (groupBy == "publisher" && publisherPanel == "campaign") ? 
                props.publishers.campaign && props.publishers.campaign.filter(campaign => 
                        campaign.publisher.toString().toUpperCase().indexOf(publisherSearch.toUpperCase()) >= 0 ||
                        campaign.mediaSource.toString().toUpperCase().indexOf(publisherSearch.toUpperCase()) >= 0
                        ).length

            : (groupBy == "publisher" && publisherPanel == "adgroup") ? 
                props.publishers.adgroup && props.publishers.adgroup.filter(adgroup => 
                    // adgroup.supernovaCampaignId == selectedSupernovaCampaignId &&
                    (adgroup.publisher.toString().toUpperCase().indexOf(publisherSearch.toUpperCase()) >= 0 ||
                    adgroup.mediaSource.toString().toUpperCase().indexOf(publisherSearch.toUpperCase()) >= 0)
                    ).length

            : (groupBy == "publisher" && publisherPanel == "creative") ?                                                    // Deprecated
                props.publishers.creative && props.publishers.creative.filter(creative => 
                    creative.supernovaAdgroupId == selectedAdgroup &&
                    (creative.publisher.toString().toUpperCase().indexOf(publisherSearch.toUpperCase()) >= 0 ||
                    creative.mediaSource.toString().toUpperCase().indexOf(publisherSearch.toUpperCase()) >= 0)
                    ).length
            : 0

    //paginationOffset, paginationOffset + tableRowsPerPage
    let tableCurrentPage = (paginationOffset / tableRowsPerPage) + 1
    let tableTotalPage = parseInt(countTableRows / tableRowsPerPage) + 1

    /** PREPARE DATA FOR EXPORTING TABLE IN CSV **/
    let csvData = []
    var table = document.getElementById("primary-report-table");
    var myArr = []
    if(table){
        for (var i = 0, row; row = table.rows[i]; i++) {
            //iterate through rows
            //rows would be accessed using the "row" variable assigned in the for loop
            let cell = []
            for (var j = 0, col; col = row.cells[j]; j++) {
                cell.push(row.cells[j].innerText)
            }  
            myArr[i] = cell
        }
        csvData = myArr;
    }

////////////////////////////
    const cancelEditField = () => {
        
        setEditBidFieldId(null) 
        setEditBidFieldValue(null)

        setEditBudgetFieldId(null) 
        setEditBudgetFieldValue(null)
    }

    const saveNewBid = (newBidValue, supernovaAdgroupId) => {
        //If new value is the same as old then cancel saving
        if(newBidValue == null){
            cancelEditField();
        }
        else{
            let adgroup = adgroups.items.filter(item => item.supernovaCampaignId == supernovaAdgroupId)[0]
            props.updateAdgroup({
                id          : supernovaAdgroupId,
                bid         : newBidValue,
                supernovaCampaignId  : adgroup.supernovaCampaignId,
                autopilot   : adgroup.autopilot
            })
            cancelEditField(); //Close the editable field 
        }
    }

    const saveNewBudget = (newBudgetValue, supernovaAdgroupId) => {
        //If new value is the same as old then cancel saving
        if(newBudgetValue == null){
            cancelEditField();
        }
        else{
            let adgroup = adgroups.items.filter(item => item.supernovaCampaignId == supernovaAdgroupId)[0]
            props.updateAdgroup({
                id          : supernovaAdgroupId,
                dailyBudget : newBudgetValue,
                supernovaCampaignId  : adgroup.supernovaCampaignId,
                autopilot   : adgroup.autopilot
            })
            cancelEditField(); //Close the editable field 
        }
    }

    const updateDateRange = () => {
        setIsDateRangeActive(false);
        //Set the {startDate} and {endDate} so stats can be updated
        setStartDate(moment(state[0].startDate));
        setEndDate(moment(state[0].endDate));

        props.getCampaigns(user.accountId);
        props.getCreativesInAccount(user.accountId)
        props.getAdgroupCreatives(user.accountId)
        
        //May be temporary:
        // showAdgroupPanel(selectedSupernovaCampaignId)

    }

    const changeStatisticsTable = (selectedKey, groupBy, selectedId) => {
        switch(groupBy){
            case "daily":
                setTableSortBy("date_number");
                break;

            case "entity":
                //No need to fetch from API again because the data is already fetched by default.
                setTableSortBy("id");
                break;

            case "publisher":
                switch(selectedKey)
                {
                    case null           : showPublishersByAccountId(user.accountId);    break;
                    case "supernovaCampaignId"   : showPublishersByCampaignId(user.accountId, selectedId);       break;
                    case "supernovaAdgroupId"    : showPublishersByAdgroupId(user.accountId, selectedId);        break;
                }
                break;

            case "environments":
                setTableSortBy("id");
                switch(selectedKey)
                {
                    case null           : showEnvironmentsByAccountId(user.accountId);  break;
                    case "supernovaCampaignId"   : showEnvironmentsByCampaignId(selectedId);     break;
                    case "supernovaAdgroupId"    : showEnvironmentsByAdgroupId(selectedId);      break;
                }
                break;
                
            case "devices":
                setTableSortBy("id");
                switch(selectedKey)
                {
                    case null           : showDevicesByAccountId(user.accountId);       break;
                    case "supernovaCampaignId"   : showDevicesByCampaignId(selectedId);          break;
                    case "supernovaAdgroupId"    : showDevicesByAdgroupId(selectedId);           break;
                }
                break;
                    
            case "os":
                setTableSortBy("id");
                switch(selectedKey)
                {
                    case null           : showOSByAccountId(user.accountId);            break;
                    case "supernovaCampaignId"   : showOSByCampaignId(selectedId);               break;
                    case "supernovaAdgroupId"    : showOSByAdgroupId(selectedId);                break;
                }
                break;

            case "countries":
                setTableSortBy("id");
                switch(selectedKey)
                {
                    case null           : showCountriesByAccountId(user.accountId);     break;
                    case "supernovaCampaignId"   : showCountriesByCampaignId(selectedId);        break;
                    case "supernovaAdgroupId"    : showCountriesByAdgroupId(selectedId);         break;
                }
                break;

            case "zone":
                setTableSortBy("id");
                switch(selectedKey)
                {
                    case null           : showZoneByAccountId(user.accountId);     break;
                    case "supernovaCampaignId"   : showZoneByCampaignId(selectedId);        break;
                    case "supernovaAdgroupId"    : showZoneByAdgroupId(selectedId);         break;
                }
                break;
                
            case "state":
                setTableSortBy("id");
                switch(selectedKey)
                {
                    case null           : showStatesByAccountId(user.accountId);        break;
                    case "supernovaCampaignId"   : showStatesByCampaignId(selectedId);           break;
                    case "supernovaAdgroupId"    : showStatesByAdgroupId(selectedId);            break;
                }
                break;
                
            case "dma":
                setTableSortBy("id");
                switch(selectedKey)
                {
                    case null           : showDMAByAccountId(user.accountId);           break;
                    case "supernovaCampaignId"   : showDMAByCampaignId(selectedId);              break;
                    case "supernovaAdgroupId"    : showDMAByAdgroupId(selectedId);               break;
                }
                break;
                
            case "media_sources":
                setTableSortBy("id");
                switch(selectedKey)
                {
                    case null           : showMediaSourcesByAccountId(user.accountId);  break;
                    case "supernovaCampaignId"   : showMediaSourcesByCampaignId(selectedId);     break;
                    case "supernovaAdgroupId"    : showMediaSourcesByAdgroupId(selectedId);      break;
                }
                break;
            case "TOP5_DMA_BY_CTR":
                setTableSortBy("id");
                setInsights({
                    ...insights, 
                    headings:["DMA", "Impressions", "Clicks", "CTR", "Total Spend", "Yesterday Spend", "eCPC"],
                    selected:groupBy
                });
                switch(selectedKey)
                {
                    case null           : props.getTopPerformingDMAByCTR("accountId",   user.accountId, startDate.format('YYYY-MM-DD'),     endDate.format('YYYY-MM-DD'), user.accountId, 5); break;
                    case "supernovaCampaignId"   : props.getTopPerformingDMAByCTR("supernovaCampaignId",  selectedId,     startDate.format('YYYY-MM-DD'),         endDate.format('YYYY-MM-DD'), user.accountId, 5); break;
                    case "supernovaAdgroupId"    : props.getTopPerformingDMAByCTR("supernovaAdgroupId",   selectedId,     startDate.format('YYYY-MM-DD'),         endDate.format('YYYY-MM-DD'), user.accountId, 5); break;
                }
                break;
            case "TOP5_COUNTRIES_BY_CTR":
                setTableSortBy("id");
                setInsights({
                    ...insights, 
                    headings:["Country", "Impressions", "Clicks", "CTR", "Total Spend", "Yesterday Spend", "eCPC"],
                    selected:groupBy
                });
                switch(selectedKey)
                {
                    case null           : props.getTopPerformingCountriesByCTR("accountId",     user.accountId,     startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), user.accountId, 5); break;
                    case "supernovaCampaignId"   : props.getTopPerformingCountriesByCTR("supernovaCampaignId",    selectedId,         startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), user.accountId, 5); break;
                    case "supernovaAdgroupId"    : props.getTopPerformingCountriesByCTR("supernovaAdgroupId",     selectedId,         startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), user.accountId, 5); break;
                }
                break;
            case "TOP5_STATE_BY_CTR":
                setTableSortBy("id");
                setInsights({
                    ...insights, 
                    headings:["State", "Impressions", "Clicks", "CTR", "Total Spend", "Yesterday Spend", "eCPC"],
                    selected:groupBy
                });
                switch(selectedKey)
                {
                    case null           : props.getTopPerformingStatesByCTR("accountId",    user.accountId,     startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), user.accountId, 5); break;
                    case "supernovaCampaignId"   : props.getTopPerformingStatesByCTR("supernovaCampaignId",   selectedId,         startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), user.accountId, 5); break;
                    case "supernovaAdgroupId"    : props.getTopPerformingStatesByCTR("supernovaAdgroupId",    selectedId,         startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), user.accountId, 5); break;
                }
                break;
            case "TOP10_DMA_BY_CTR":
                setTableSortBy("id");
                setInsights({
                    ...insights, 
                    headings:["DMA", "Impressions", "Clicks", "CTR", "Total Spend", "Yesterday Spend", "eCPC"],
                    selected:groupBy
                });
                switch(selectedKey)
                {
                    case null           : props.getTopPerformingDMAByCTR("accountId",   user.accountId, startDate.format('YYYY-MM-DD'),     endDate.format('YYYY-MM-DD'), user.accountId, 10); break;
                    case "supernovaCampaignId"   : props.getTopPerformingDMAByCTR("supernovaCampaignId",  selectedId,     startDate.format('YYYY-MM-DD'),     endDate.format('YYYY-MM-DD'), user.accountId, 10); break;
                    case "supernovaAdgroupId"    : props.getTopPerformingDMAByCTR("supernovaAdgroupId",   selectedId,     startDate.format('YYYY-MM-DD'),     endDate.format('YYYY-MM-DD'), user.accountId, 10); break;
                }
                break;
            case "TOP10_COUNTRIES_BY_CTR":
                setTableSortBy("id");
                setInsights({
                    ...insights, 
                    headings:["Country", "Impressions", "Clicks", "CTR", "Total Spend", "Yesterday Spend", "eCPC"],
                    selected:groupBy
                });
                switch(selectedKey)
                {
                    case null           : props.getTopPerformingCountriesByCTR("accountId",     user.accountId,     startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), user.accountId, 10); break;
                    case "supernovaCampaignId"   : props.getTopPerformingCountriesByCTR("supernovaCampaignId",    selectedId,         startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), user.accountId, 10); break;
                    case "supernovaAdgroupId"    : props.getTopPerformingCountriesByCTR("supernovaAdgroupId",     selectedId,         startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), user.accountId, 10); break;
                }
                break;
            case "TOP10_STATE_BY_CTR":
                setTableSortBy("id");
                setInsights({
                    ...insights, 
                    headings:["State", "Impressions", "Clicks", "CTR", "Total Spend", "Yesterday Spend", "eCPC"],
                    selected:groupBy
                });
                switch(selectedKey)
                {
                    case null           : props.getTopPerformingStatesByCTR("accountId",    user.accountId,     startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), user.accountId, 10); break;
                    case "supernovaCampaignId"   : props.getTopPerformingStatesByCTR("supernovaCampaignId",   selectedId,         startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), user.accountId, 10); break;
                    case "supernovaAdgroupId"    : props.getTopPerformingStatesByCTR("supernovaAdgroupId",    selectedId,         startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), user.accountId, 10); break;
                }
                break;
        }
    }

    const getKeyColumn = (groupBy) => {
        switch(groupBy){
            case "daily":                   return {label:"Daily",                      value:""};              break;
            case "entity":                  return {label:"Entity",                     value:""};              break;
            case "publisher":               return {label:"Publisher",                  value:""};              break;
            case "environments":            return {label:"Environment",                value:"environment"};   break;
            case "devices":                 return {label:"Device",                     value:"device"};        break;
            case "os":                      return {label:"Operating System",           value:"os"};            break;
            case "countries":               return {label:"Country",                    value:"countryName"};   break;
            case "zone":                    return {label:"Zone",                       value:"zone_id"};       break;
            case "state":                   return {label:"State",                      value:"stateName"};     break;
            case "dma":                     return {label:"DMA",                        value:"dmaName"};       break;
            case "media_sources":           return {label:"Media Sources",              value:"mediasource"};   break;
            case "TOP5_DMA_BY_CTR":         return {label:"Top 5 DMA by CTR",           value:""};              break;
            case "TOP5_COUNTRIES_BY_CTR":   return {label:"Top 5 Countries by CTR",     value:""};              break;
            case "TOP5_STATE_BY_CTR":       return {label:"Top 5 State by CTR",         value:""};              break;
            case "TOP10_DMA_BY_CTR":        return {label:"Top 10 DMA by CTR",          value:""};              break;
            case "TOP10_COUNTRIES_BY_CTR":  return {label:"Top 10 Countries by CTR",    value:""};              break;
            case "TOP10_STATE_BY_CTR":      return {label:"Top 10 State by CTR",        value:""};              break;
        }
    }

    const getUserType = () => {
        if(user.accountId){
            if(user.parentAccountId === undefined){             //IF there is no parentAccountId THEN it means the account is Supernova
                if(window.location.hostname == "local.supernova.inc"){
                    document.title = `Supernova | ${user.companyName}`
                }
                return "supernova";
            }
            else if(user.parentAccountId === "0000" || user.parentAccountId === 0 || user.parentAccountId === "4762"){           //IF the parentAccountId is 0000 (Which is Supernova's Account ID) THEN it means it is a direct client of supernova
                if(window.location.hostname == "local.supernova.inc"){
                    document.title = `Client | ${user.companyName}`
                }
                return "direct-client";
            }
            else{                                               //ELSE THEN it means it's a sub-client of supernova
                if(window.location.hostname == "local.supernova.inc"){
                    document.title = `Sub-client | ${user.companyName}`
                }
                return "sub-client"
            }
        }
        else{
            return null;
        }
    }

    const getDailyStats = () => {
        // 1. Get all dates between the date range start and end date
        // For example: Date range is Jun 01, 2022 to Jul 31, 2022
        // Then return: 
        //    Jun 01, 2022
        //    Jun 02, 2022
        //    Jun 03, 2022
        //    and so on...
        
        let now                 = startDate.clone()
        let dates_in_daterange  = [];
        let empty_dates         = [];
        let daily_stats         = [];

        while (now.isSameOrBefore(endDate)) {
            dates_in_daterange.push(now.format('YYYY-MM-DD'));
            now.add(1, 'days');
        }


        //2. Get dates that are not in the API result
        empty_dates = dates_in_daterange.filter(item => !statistics["daily"].map(item => item._id).includes(item)).map(item => {
            return ({
                date_number     : moment(item).format("YYYYMMDD"),
                date            : moment(item).format("ll"),
                impressions     : 0,
                clicks          : 0,
                ctr             : 0,
                media_spend     : 0,
                data_spend      : 0,
                total_spend     : 0,
                yesterday_spend : 0,
                media_ecpc      : 0,
                media_ecpm      : 0,
                total_ecpc      : 0,
                total_ecpm      : 0,
            })
        })

        
        //3. Merge Dates from API with those that are not in API so users can still see a date even if it does not have any stats 
        daily_stats = empty_dates.concat(statistics["daily"].filter(item => dates_in_daterange.includes(item._id)).map((item, index) => {
            switch(getUserType()){
                case "supernova":
                    return ({
                        date_number     : moment(item._id).format("YYYYMMDD"),
                        date            : moment(item._id).format("ll"),
                        impressions     : item.impressions,
                        clicks          : item.clicks,
                        ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                        yesterday_spend : item.yesterdaySpend,
                        data_spend      : parseFloat(item.dataspend_with_margin),
                        media_spend     : parseFloat(item.mediaSpend),
                        total_spend     : parseFloat(item.mediaSpend) + parseFloat(item.dataspend_with_margin),
                        media_ecpc      : parseFloat(item.ecpc),
                        media_ecpm      : parseFloat(item.ecpm),
                        total_ecpc      : parseFloat(item.ecpc),
                        total_ecpm      : parseFloat(item.ecpm),
                    })
                    break;
                case "direct-client":
                    return ({
                        date_number     : moment(item._id).format("YYYYMMDD"),
                        date            : moment(item._id).format("ll"),
                        impressions     : item.impressions,
                        clicks          : item.clicks,
                        ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                        yesterday_spend : item.yesterdaySpend,
                        data_spend      : parseFloat(item.dataspend_with_margin),
                        media_spend     : parseFloat(item.media_spend_client),
                        total_spend     : parseFloat(item.total_spend_client),
                        media_ecpc      : parseFloat(item.media_ecpc_client),
                        media_ecpm      : parseFloat(item.media_ecpm_spend_mod_delta_after_serving_fee),
                        total_ecpc      : parseFloat(item.total_ecpc_client),
                        total_ecpm      : parseFloat(item.total_ecpm_client),
                    })
                    break;
                case "sub-client":
                    return ({
                        date_number     : moment(item._id).format("YYYYMMDD"),
                        date            : moment(item._id).format("ll"),
                        impressions     : item.impressions,
                        clicks          : item.clicks,
                        ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                        data_spend      : parseFloat(item.dataspend_with_margin),
                        media_spend     : parseFloat(item.media_spend_subclient),
                        yesterday_spend : item.yesterdaySpend,
                        total_spend     : parseFloat(item.total_spend_subclient),
                        media_ecpc      : parseFloat(item.media_ecpc_subclient),
                        media_ecpm      : parseFloat(item.media_ecpm_subclient_spend_mod_delta_after_serving_fee),
                        total_ecpc      : parseFloat(item.total_ecpc_subclient),
                        total_ecpm      : parseFloat(item.total_ecpm_subclient),
                    })
                    break;
            }
        }))
        return daily_stats;

    }

    //////////////////////////////////////////////////////////
    //Gets the additional log of CLICKS today for real time
    //////////////////////////////////////////////////////////
    const getAdditionalClicksToday = (date) => {
        let result = 0
        if(selectedEntityKey == null){
            result = logSupernova?.items?.filter(logSup => logSup._id == moment(date).format('YYYY-MM-DD'))?.map(item => item.clicks ).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        }
        else{
            result = logSupernova?.items?.filter(logSup => filteredCreatives.indexOf(logSup.supernovaCreativeId) !== -1 && logSup._id == moment(date).format('YYYY-MM-DD'))?.map(item => item.clicks ).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        }
        return result
    }

    //////////////////////////////////////////////////////////
    //Gets the additional log of IMPRESSIONS today for real time
    //////////////////////////////////////////////////////////
    const getAdditionalImpressionsToday = (date) => {
        let result = 0
        if(selectedEntityKey == null){
            result = logSupernova?.items?.filter(logSup => logSup._id == moment(date).format('YYYY-MM-DD'))?.map(item => item.impressions ).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        }
        else{
            result = logSupernova?.items?.filter(logSup => filteredCreatives.indexOf(logSup.supernovaCreativeId) !== -1 && logSup._id == moment(date).format('YYYY-MM-DD'))?.map(item => item.impressions ).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        }
        return result
    }

    const getDailyStatsCreative = () => {
        // 1. Get all dates between the date range start and end date
        // For example: Date range is Jun 01, 2022 to Jul 31, 2022
        // Then return: 
        //    Jun 01, 2022
        //    Jun 02, 2022
        //    Jun 03, 2022
        //    and so on...
        
        let now                 = startDate.clone()
        let dates_in_daterange  = [];
        let empty_dates         = [];
        let daily_stats         = [];

        while (now.isSameOrBefore(endDate)) {
            dates_in_daterange.push(now.format('YYYY-MM-DD'));
            now.add(1, 'days');
        }


        //2. Get dates that are not in the API result
        empty_dates = dates_in_daterange.filter(item => !logSupernova.items.map(item => item._id).includes(item)).map(item => {
            return ({
                date_number     : moment(item).format("YYYYMMDD"),
                date            : moment(item).format("ll"),
                impressions     : 0,
                clicks          : 0,
                ctr             : 0,
            })
        })

        
        //3. Merge Dates from API with those that are not in API so users can still see a date even if it does not have any stats 
        daily_stats = empty_dates.concat(logSupernova.items && logSupernova.items.filter(item => dates_in_daterange.includes(item._id)).map((item, index) => {
            switch(getUserType()){
                case "supernova":
                    return ({
                        date_number     : moment(item._id).format("YYYYMMDD"),
                        date            : moment(item._id).format("ll"),
                        impressions     : item.impressions,
                        clicks          : item.clicks,
                        ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                    })
                    break;
                case "direct-client":
                    return ({
                        date_number     : moment(item._id).format("YYYYMMDD"),
                        date            : moment(item._id).format("ll"),
                        impressions     : item.impressions,
                        clicks          : item.clicks,
                        ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                    })
                    break;
                case "sub-client":
                    return ({
                        date_number     : moment(item._id).format("YYYYMMDD"),
                        date            : moment(item._id).format("ll"),
                        impressions     : item.impressions,
                        clicks          : item.clicks,
                        ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                    })
                    break;
            }
        }))
        return daily_stats;

    }

    const tableArray = (groupBy) => {
        return (
                
                /******************************************
                 * Daily (General)
                 *****************************************/
                groupBy == "daily" ? 
                    statistics["daily"] ? getDailyStats()
                    : []

                /******************************************
                 * Daily (Creatives)
                 *****************************************/
                : groupBy == "daily_creatives" ? 
                    (logSupernova && logSupernova.items) ? getDailyStatsCreative()
                    : []

                /******************************************
                 * Campaign
                 *****************************************/
                : groupBy == "entity" && selectedEntityKey == null ? 
                    (statistics.campaign && campaigns.items) ? campaigns.items.map((campaign, index) => {
                        if(statistics.campaign.filter(item => item.supernovaCampaignId == campaign.supernovaCampaignId).length == 0){
                            return ({
                                id              : campaign.supernovaCampaignId,
                                name            : campaign.name,
                                impressions     : 0,
                                clicks          : 0,
                                ctr             : 0,
                                yesterday_spend : 0,
                                data_spend      : 0,
                                media_spend     : 0,
                                total_spend     : 0,
                                media_ecpc      : 0,
                                media_ecpm      : 0,
                                total_ecpc      : 0,
                                total_ecpm      : 0,
                                daily_budget    : 0
                            })

                        }
                        else{
                            let item = statistics.campaign.filter(item => item.supernovaCampaignId == campaign.supernovaCampaignId)[0]
                            switch(getUserType()){
                                case "supernova":
                                    return ({
                                        id              : campaign.supernovaCampaignId,
                                        name            : campaign.name,
                                        impressions     : item.impressions,
                                        clicks          : item.clicks,
                                        ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                        yesterday_spend : item.yesterdaySpend,

                                        data_spend      : parseFloat(item.dataspend_with_margin),
                                        media_spend     : parseFloat(item.mediaSpend),
                                        total_spend     : parseFloat(item.dataspend_with_margin) + parseFloat(item.mediaSpend),
                                        media_ecpc      : parseFloat(item.ecpc),
                                        media_ecpm      : parseFloat(item.ecpm),
                                        total_ecpc      : parseFloat(item.ecpc),
                                        total_ecpm      : parseFloat(item.ecpm),
                                        daily_budget    : 0
    
                                    })
                                    break;
                                case "direct-client":
                                    return ({
                                        id              : campaign.supernovaCampaignId,
                                        name            : campaign.name,
                                        impressions     : item.impressions,
                                        clicks          : item.clicks,
                                        ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                        yesterday_spend : item.yesterdaySpend,
                                        data_spend      : parseFloat(item.dataspend_with_margin),
                                        media_spend     : parseFloat(item.media_spend_client),
                                        total_spend     : parseFloat(item.total_spend_client),
                                        media_ecpc      : parseFloat(item.media_ecpc_client),
                                        media_ecpm      : parseFloat(item.media_ecpm_spend_mod_delta_after_serving_fee),
                                        total_ecpc      : parseFloat(item.total_ecpc_client),
                                        total_ecpm      : parseFloat(item.total_ecpm_client),
                                        daily_budget    : 0
                                    })
                                    break;
                                case "sub-client":
                                    return ({
                                        id              : campaign.supernovaCampaignId,
                                        name            : campaign.name,
                                        impressions     : item.impressions,
                                        clicks          : item.clicks,
                                        ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                        yesterday_spend : item.yesterdaySpend,
                                        data_spend      : parseFloat(item.dataspend_with_margin),
                                        media_spend     : parseFloat(item.media_spend_subclient),
                                        total_spend     : parseFloat(item.total_spend_subclient),
                                        media_ecpc      : parseFloat(item.media_ecpc_subclient),
                                        media_ecpm      : parseFloat(item.media_ecpm_subclient_spend_mod_delta_after_serving_fee),
                                        total_ecpc      : parseFloat(item.total_ecpc_subclient),
                                        total_ecpm      : parseFloat(item.total_ecpm_subclient),
                                        daily_budget    : 0
                                    })
                                    break;
                            }
                        }
                    })
                    : []
                /******************************************
                 * Ad Group
                 *****************************************/
                : groupBy == "entity" && selectedEntityKey == "supernovaCampaignId" ? 
                    (statistics.adgroup && adgroups.items) ? adgroups.items.map((adgroup, index) => {
                        if(statistics.adgroup.filter(item => item.supernovaAdgroupId == adgroup.supernovaAdgroupId).length == 0){
                            return ({
                                id              : adgroup.supernovaAdgroupId,
                                name            : adgroup.name,
                                impressions     : 0,
                                clicks          : 0,
                                ctr             : 0,
                                yesterday_spend : 0,
                                data_spend      : 0,
                                media_spend     : 0,
                                total_spend     : 0,
                                media_ecpc      : 0,
                                media_ecpm      : 0,
                                total_ecpc      : 0,
                                total_ecpm      : 0,
                                daily_budget    : 0
                            })
                        }
                        else{
                            let item = statistics.adgroup.filter(item => item.supernovaAdgroupId == adgroup.supernovaAdgroupId)[0]
                            switch(getUserType()){
                                case "supernova":
                                    return ({
                                        id              : adgroup.supernovaAdgroupId,
                                        name            : adgroup.name,
                                        impressions     : item.impressions,
                                        clicks          : item.clicks,
                                        ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                        yesterday_spend : item.yesterdaySpend,
                                        data_spend      : parseFloat(item.dataspend_with_margin),
                                        media_spend     : parseFloat(item.mediaSpend),
                                        total_spend     : parseFloat(item.dataspend_with_margin) + parseFloat(item.mediaSpend),
                                        media_ecpc      : parseFloat(item.ecpc),
                                        media_ecpm      : parseFloat(item.ecpm),
                                        total_ecpc      : parseFloat(item.ecpc),
                                        total_ecpm      : parseFloat(item.ecpm),
                                        daily_budget    : 0
    
                                    })
                                    break;
                                case "direct-client":
                                    return ({
                                        id              : adgroup.supernovaAdgroupId,
                                        name            : adgroup.name,
                                        impressions     : item.impressions,
                                        clicks          : item.clicks,
                                        ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                        yesterday_spend : item.yesterdaySpend,
                                        data_spend      : parseFloat(item.dataspend_with_margin),
                                        media_spend     : parseFloat(item.media_spend_client),
                                        total_spend     : parseFloat(item.total_spend_client),
                                        media_ecpc      : parseFloat(item.media_ecpc_client),
                                        media_ecpm      : parseFloat(item.media_ecpm_spend_mod_delta_after_serving_fee),
                                        total_ecpc      : parseFloat(item.total_ecpc_client),
                                        total_ecpm      : parseFloat(item.total_ecpm_client),
                                        daily_budget    : 0
                                    })
                                    break;
                                case "sub-client":
                                    return ({
                                        id              : adgroup.supernovaAdgroupId,
                                        name            : adgroup.name,
                                        impressions     : item.impressions,
                                        clicks          : item.clicks,
                                        ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                        yesterday_spend : item.yesterdaySpend,
                                        data_spend      : parseFloat(item.dataspend_with_margin),
                                        media_spend     : parseFloat(item.media_spend_subclient),
                                        total_spend     : parseFloat(item.total_spend_subclient),
                                        media_ecpc      : parseFloat(item.media_ecpc_subclient),
                                        media_ecpm      : parseFloat(item.media_ecpm_subclient_spend_mod_delta_after_serving_fee),
                                        total_ecpc      : parseFloat(item.total_ecpc_subclient),
                                        total_ecpm      : parseFloat(item.total_ecpm_subclient),
                                        daily_budget    : 0
                                    })
                                    break;
                            }
                        }
                    })
                    : []
                /******************************************
                 * Creatives`
                 *****************************************/
                : groupBy == "entity" && selectedEntityKey == "supernovaAdgroupId" ? 
                    // TODO (Status: DONE): Get list of creatives from ads table instead of adgroup_creatives
                    // (selectedEntityKey =="supernovaAdgroupId" && adgroupCreatives && !adgroupCreatives.loading && adgroupCreatives.items && statistics.creative && creatives.items) ? creatives.items.filter(creative => 
                    //     adgroupCreatives.items.filter(adgroupCreative => adgroupCreative.supernovaAdgroupId == selectedAdgroup).map(item => {return item.supernovaCreativeId}).includes(creative.id) //Filters the creatives under the selected adgroup
                    // ).map((creative, index) => {
                    (
                        selectedEntityKey =="supernovaAdgroupId" && 
                        ads && 
                        ads.isDone && 
                        ads.items && 
                        statistics.creative && 
                        creatives.items) ? 
                        creatives.items.filter(creative => 
                        ads.items.filter(ad => ad.supernovaAdgroupId == selectedAdgroup).map(item => {return item.creative.id}).includes(creative.id) //Filters the creatives under the selected adgroup
                    ).map((creative, index) => {
                        console.log(creative)
                        if(statistics.creative.filter(item => item.supernovaCreativeId == creative.supernovaCreativeId).length == 0){
                            return ({
                                id              : creative.supernovaCreativeId,
                                title           : creative.title,
                                type            : creative.type,
                                size            : `${creative.imageWidth}x${creative.imageHeight}`,
                                impressions     : 0,
                                clicks          : 0,
                                ctr             : 0,
                                data_spend      : 0,
                                media_spend     : 0,
                                total_spend     : 0,
                                yesterday_spend : 0,
                                media_ecpc      : 0,
                                media_ecpm      : 0,
                                total_ecpc      : 0,
                                total_ecpm      : 0,
                                daily_budget    : 0,
                                archived        : creative.archived
                            })
                        }
                        else{
                            let item = statistics.creative.filter(item => item.supernovaCreativeId == creative.supernovaCreativeId)[0]
                            switch(getUserType()){
                                case "supernova":
                                    return ({
                                        id              : creative.supernovaCreativeId,
                                        title           : creative.title,
                                        type            : creative.type,
                                        size            : `${creative.imageWidth}x${creative.imageHeight}`,
                                        impressions     : item.impressions,
                                        clicks          : item.clicks,
                                        ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                        yesterday_spend : item.yesterdaySpend,
                                        data_spend      : parseFloat(item.dataspend_with_margin),
                                        media_spend     : parseFloat(item.mediaSpend),
                                        total_spend     : parseFloat(item.dataspend_with_margin) + parseFloat(item.mediaSpend),
                                        media_ecpc      : parseFloat(item.ecpc),
                                        media_ecpm      : parseFloat(item.ecpm),
                                        total_ecpc      : parseFloat(item.ecpc),
                                        total_ecpm      : parseFloat(item.ecpm),
                                        daily_budget    : 0,
                                        archived        : creative.archived
                                    })
                                    break;
                                case "direct-client":
                                    return ({
                                        id              : creative.supernovaCreativeId,
                                        title           : creative.title,
                                        type            : creative.type,
                                        size            : `${creative.imageWidth}x${creative.imageHeight}`,
                                        impressions     : item.impressions,
                                        clicks          : item.clicks,
                                        ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                        yesterday_spend : item.yesterdaySpend,
                                        data_spend      : parseFloat(item.dataspend_with_margin),
                                        media_spend     : parseFloat(item.media_spend_client),
                                        total_spend     : parseFloat(item.total_spend_client),
                                        media_ecpc      : parseFloat(item.media_ecpc_client),
                                        media_ecpm      : parseFloat(item.media_ecpm_spend_mod_delta_after_serving_fee),
                                        total_ecpc      : parseFloat(item.total_ecpc_client),
                                        total_ecpm      : parseFloat(item.total_ecpm_client),
                                        daily_budget    : 0,
                                        archived        : creative.archived
                                    })
                                    break;
                                case "sub-client":
                                    return ({
                                        id              : creative.supernovaCreativeId,
                                        title           : creative.title,
                                        type            : creative.type,
                                        size            : `${creative.imageWidth}x${creative.imageHeight}`,
                                        impressions     : item.impressions,
                                        clicks          : item.clicks,
                                        ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                        yesterday_spend : item.yesterdaySpend,
                                        data_spend      : parseFloat(item.dataspend_with_margin),
                                        media_spend     : parseFloat(item.media_spend_subclient),
                                        total_spend     : parseFloat(item.total_spend_subclient),
                                        media_ecpc      : parseFloat(item.media_ecpc_subclient),
                                        media_ecpm      : parseFloat(item.media_ecpm_subclient_spend_mod_delta_after_serving_fee),
                                        total_ecpc      : parseFloat(item.total_ecpc_subclient),
                                        total_ecpm      : parseFloat(item.total_ecpm_subclient),
                                        daily_budget    : 0,
                                        archived        : creative.archived
                                    })
                                    break;
                            }
                        }
                    })
                    : []
                /******************************************
                 * Devices
                 * Os
                 * Countries
                 * Zone
                 * Environments
                 * DMA
                 * State
                 * Mddia Sources
                 *****************************************/
                : groupBy == "devices"          || 
                  groupBy == "os"               || 
                  groupBy == "countries"        || 
                  groupBy == "zone"             || 
                  groupBy == "environments"     || 
                  groupBy == "dma"              || 
                  groupBy == "state"            || 
                  groupBy == "media_sources"  ? 
                    statistics[groupBy] ? statistics[groupBy].map((item, index) => {

                        switch(getUserType()){
                            case "supernova":
                                return ({
                                    id              : item[getKeyColumn(groupBy).value],
                                    impressions     : item.impressions,
                                    clicks          : item.clicks,
                                    ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                    yesterday_spend : item.yesterdaySpend,
                                    data_spend      : parseFloat(item.dataspend_with_margin),
                                    media_spend     : parseFloat(item.mediaSpend),
                                    total_spend     : parseFloat(item.dataspend_with_margin) + parseFloat(item.mediaSpend),
                                    media_ecpc      : parseFloat(item.ecpc),
                                    media_ecpm      : parseFloat(item.ecpm),
                                    total_ecpc      : parseFloat(item.ecpc),
                                    total_ecpm      : parseFloat(item.ecpm),
                                    daily_budget    : 0
                                })
                                break;
                            case "direct-client":
                                return ({
                                    id              : item[getKeyColumn(groupBy).value],
                                    impressions     : item.impressions,
                                    clicks          : item.clicks,
                                    ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                    yesterday_spend : item.yesterdaySpend,
                                    data_spend      : parseFloat(item.dataspend_with_margin),
                                    media_spend     : parseFloat(item.media_spend_client),
                                    total_spend     : parseFloat(item.total_spend_client),
                                    media_ecpc      : parseFloat(item.media_ecpc_client),
                                    media_ecpm      : parseFloat(item.media_ecpm_spend_mod_delta_after_serving_fee),
                                    total_ecpc      : parseFloat(item.total_ecpc_client),
                                    total_ecpm      : parseFloat(item.total_ecpm_client),
                                    daily_budget    : 0
                                })
                                break;
                            case "sub-client":
                                return ({
                                    id              : item[getKeyColumn(groupBy).value],
                                    impressions     : item.impressions,
                                    clicks          : item.clicks,
                                    ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                    yesterday_spend : item.yesterdaySpend,
                                    data_spend      : parseFloat(item.dataspend_with_margin),
                                    media_spend     : parseFloat(item.media_spend_subclient),
                                    total_spend     : parseFloat(item.total_spend_subclient),
                                    media_ecpc      : parseFloat(item.media_ecpc_subclient),
                                    media_ecpm      : parseFloat(item.media_ecpm_subclient_spend_mod_delta_after_serving_fee),
                                    total_ecpc      : parseFloat(item.total_ecpc_subclient),
                                    total_ecpm      : parseFloat(item.total_ecpm_subclient),
                                    daily_budget    : 0
                                })
                                break;
                        }
                    })
                    : []
                /******************************************
                 * Top State By CTR
                 *****************************************/
                : groupBy == "TOP5_STATE_BY_CTR"        ||
                  groupBy == "TOP10_STATE_BY_CTR"       ?
                    (statistics.isTopStatesByCtrDone && statistics.topStatesByCtr) ? statistics.topStatesByCtr.map((item, index) => {
                        switch(getUserType()){
                            case "supernova":
                                return ({
                                    id              : item.stateName == "" ? "N/A" : item.stateName.replace(", United States", ""),     // State
                                    impressions     : item.impressions,
                                    clicks          : item.clicks,
                                    ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                    yesterday_spend : item.yesterdaySpend,
                                    data_spend      : parseFloat(item.dataspend_with_margin),
                                    media_spend     : parseFloat(item.mediaSpend),
                                    total_spend     : parseFloat(item.dataspend_with_margin) + parseFloat(item.mediaSpend),
                                    media_ecpc      : parseFloat(item.ecpc),
                                    media_ecpm      : parseFloat(item.ecpm),
                                    total_ecpc      : parseFloat(item.ecpc),
                                    total_ecpm      : parseFloat(item.ecpm),
                                    daily_budget    : 0
                                })
                                break;
                            case "direct-client":
                                return ({
                                    id              : item.stateName == "" ? "N/A" : item.stateName.replace(", United States", ""),     // State
                                    impressions     : item.impressions,
                                    clicks          : item.clicks,
                                    ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                    yesterday_spend : item.yesterdaySpend,
                                    data_spend      : parseFloat(item.dataspend_with_margin),
                                    media_spend     : parseFloat(item.media_spend_client),
                                    total_spend     : parseFloat(item.total_spend_client),
                                    media_ecpc      : parseFloat(item.media_ecpc_client),
                                    media_ecpm      : parseFloat(item.media_ecpm_spend_mod_delta_after_serving_fee),
                                    total_ecpc      : parseFloat(item.total_ecpc_client),
                                    total_ecpm      : parseFloat(item.total_ecpm_client),
                                    daily_budget    : 0
                                })
                                break;
                            case "sub-client":
                                return ({
                                    id              : item.stateName == "" ? "N/A" : item.stateName.replace(", United States", ""),     // State
                                    impressions     : item.impressions,
                                    clicks          : item.clicks,
                                    ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                    yesterday_spend : item.yesterdaySpend,
                                    data_spend      : parseFloat(item.dataspend_with_margin),
                                    media_spend     : parseFloat(item.media_spend_subclient),
                                    total_spend     : parseFloat(item.total_spend_subclient),
                                    media_ecpc      : parseFloat(item.media_ecpc_subclient),
                                    media_ecpm      : parseFloat(item.media_ecpm_subclient_spend_mod_delta_after_serving_fee),
                                    total_ecpc      : parseFloat(item.total_ecpc_subclient),
                                    total_ecpm      : parseFloat(item.total_ecpm_subclient),
                                    daily_budget    : 0
                                })
                                break;
                        }
                        ////////////////
                        // Old Version:
                        ////////////////
                        // return ({
                        //     id              : item.stateName == "" ? "N/A" : item.stateName.replace(", United States", ""),     // State
                        //     impressions     : item.impressions,                                                                 // Impressions
                        //     clicks          : item.clicks,                                                                      // Clicks
                        //     ctr             : parseFloat(item.clicks / item.impressions),                                       // CTR
                        //     total_spend     : item.mediaSpend,                                                                       // Total Spend
                        //     yesterday_spend : item.yesterdaySpend,                                                              // Yesterday Spend
                        //     ecpc            : item.clicks == 0 ? 0 : parseFloat(item.mediaSpend / item.clicks),                      // eCPC
                        //     ecpm            : item.impressions == 0 ? 0 : parseFloat((item.mediaSpend * 1000) / item.impressions),   // eCPM
                        // })
                    })
                    : []
                /******************************************
                 * Top DMA By CTR
                 *****************************************/
                : groupBy == "TOP5_DMA_BY_CTR"        ||
                  groupBy == "TOP10_DMA_BY_CTR"       ?
                    (statistics.isTopDMAByCtrDone && statistics.topDMAByCtr) ? statistics.topDMAByCtr.map((item, index) => {
                        switch(getUserType()){
                            case "supernova":
                                return ({
                                    id              : item.dmaName == "" ? "N/A" : item.dmaName,                                            // DMA
                                    impressions     : item.impressions,
                                    clicks          : item.clicks,
                                    ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                    yesterday_spend : item.yesterdaySpend,
                                    data_spend      : parseFloat(item.dataspend_with_margin),
                                    media_spend     : parseFloat(item.mediaSpend),
                                    total_spend     : parseFloat(item.dataspend_with_margin) + parseFloat(item.mediaSpend),
                                    media_ecpc      : parseFloat(item.ecpc),
                                    media_ecpm      : parseFloat(item.ecpm),
                                    total_ecpc      : parseFloat(item.ecpc),
                                    total_ecpm      : parseFloat(item.ecpm),
                                    daily_budget    : 0
                                })
                                break;
                            case "direct-client":
                                return ({
                                    id              : item.dmaName == "" ? "N/A" : item.dmaName,                                            // DMA
                                    impressions     : item.impressions,
                                    clicks          : item.clicks,
                                    ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                    yesterday_spend : item.yesterdaySpend,
                                    data_spend      : parseFloat(item.dataspend_with_margin),
                                    media_spend     : parseFloat(item.media_spend_client),
                                    total_spend     : parseFloat(item.total_spend_client),
                                    media_ecpc      : parseFloat(item.media_ecpc_client),
                                    media_ecpm      : parseFloat(item.media_ecpm_spend_mod_delta_after_serving_fee),
                                    total_ecpc      : parseFloat(item.total_ecpc_client),
                                    total_ecpm      : parseFloat(item.total_ecpm_client),
                                    daily_budget    : 0
                                })
                                break;
                            case "sub-client":
                                return ({
                                    id              : item.dmaName == "" ? "N/A" : item.dmaName,                                            // DMA
                                    impressions     : item.impressions,
                                    clicks          : item.clicks,
                                    ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                    yesterday_spend : item.yesterdaySpend,
                                    data_spend      : parseFloat(item.dataspend_with_margin),
                                    media_spend     : parseFloat(item.media_spend_subclient),
                                    total_spend     : parseFloat(item.total_spend_subclient),
                                    media_ecpc      : parseFloat(item.media_ecpc_subclient),
                                    media_ecpm      : parseFloat(item.media_ecpm_subclient_spend_mod_delta_after_serving_fee),
                                    total_ecpc      : parseFloat(item.total_ecpc_subclient),
                                    total_ecpm      : parseFloat(item.total_ecpm_subclient),
                                    daily_budget    : 0
                                })
                                break;
                        }
                        ////////////////
                        // Old Version:
                        ////////////////
                        // return ({
                        //     id              : item.dmaName == "" ? "N/A" : item.dmaName,                                            // DMA
                        //     impressions     : item.impressions,                                                                     // Impressions
                        //     clicks          : item.clicks,                                                                          // Clicks
                        //     ctr             : parseFloat(item.clicks / item.impressions),                                           // CTR
                        //     total_spend     : item.mediaSpend,                                                                           // Total Spend
                        //     yesterday_spend : item.yesterdaySpend,                                                                  // Yesterday Spend
                        //     ecpc            : item.clicks == 0 ? 0 : parseFloat(item.mediaSpend / item.clicks),                          // eCPC
                        //     ecpm            : item.impressions == 0 ? 0 : parseFloat((item.mediaSpend * 1000) / item.impressions),       // eCPM
                        // })
                    })
                    : []
                /******************************************
                 * Top Countries By CTR
                 *****************************************/
                : groupBy == "TOP5_COUNTRIES_BY_CTR"        ||
                  groupBy == "TOP10_COUNTRIES_BY_CTR"       ?
                    (statistics.isTopCountriesByCtrDone && statistics.topCountriesByCtr) ? statistics.topCountriesByCtr.map((item, index) => {
                        switch(getUserType()){
                            case "supernova":
                                return ({
                                    id              : item.countryName == "" ? "N/A" : item.countryName,                                    // Country
                                    impressions     : item.impressions,
                                    clicks          : item.clicks,
                                    ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                    yesterday_spend : item.yesterdaySpend,
                                    data_spend      : parseFloat(item.dataspend_with_margin),
                                    media_spend     : parseFloat(item.mediaSpend),
                                    total_spend     : parseFloat(item.dataspend_with_margin) + parseFloat(item.mediaSpend),
                                    media_ecpc      : parseFloat(item.ecpc),
                                    media_ecpm      : parseFloat(item.ecpm),
                                    total_ecpc      : parseFloat(item.ecpc),
                                    total_ecpm      : parseFloat(item.ecpm),
                                    daily_budget    : 0
                                })
                                break;
                            case "direct-client":
                                return ({
                                    id              : item.countryName == "" ? "N/A" : item.countryName,                                    // Country
                                    impressions     : item.impressions,
                                    clicks          : item.clicks,
                                    ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                    yesterday_spend : item.yesterdaySpend,
                                    data_spend      : parseFloat(item.dataspend_with_margin),
                                    media_spend     : parseFloat(item.media_spend_client),
                                    total_spend     : parseFloat(item.total_spend_client),
                                    media_ecpc      : parseFloat(item.media_ecpc_client),
                                    media_ecpm      : parseFloat(item.media_ecpm_spend_mod_delta_after_serving_fee),
                                    total_ecpc      : parseFloat(item.total_ecpc_client),
                                    total_ecpm      : parseFloat(item.total_ecpm_client),
                                    daily_budget    : 0
                                })
                                break;
                            case "sub-client":
                                return ({
                                    id              : item.countryName == "" ? "N/A" : item.countryName,                                    // Country
                                    impressions     : item.impressions,
                                    clicks          : item.clicks,
                                    ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                    yesterday_spend : item.yesterdaySpend,
                                    data_spend      : parseFloat(item.dataspend_with_margin),
                                    media_spend     : parseFloat(item.media_spend_subclient),
                                    total_spend     : parseFloat(item.total_spend_subclient),
                                    media_ecpc      : parseFloat(item.media_ecpc_subclient),
                                    media_ecpm      : parseFloat(item.media_ecpm_subclient_spend_mod_delta_after_serving_fee),
                                    total_ecpc      : parseFloat(item.total_ecpc_subclient),
                                    total_ecpm      : parseFloat(item.total_ecpm_subclient),
                                    daily_budget    : 0
                                })
                                break;
                        }
                        ////////////////
                        // Old Version:
                        ////////////////
                        // return ({
                        //     id              : item.countryName == "" ? "N/A" : item.countryName,                                    // Country
                        //     impressions     : item.impressions,                                                                     // Impressions
                        //     clicks          : item.clicks,                                                                          // Clicks
                        //     ctr             : parseFloat(item.clicks / item.impressions),                                           // CTR
                        //     total_spend     : item.mediaSpend,                                                                           // Total Spend
                        //     yesterday_spend : item.yesterdaySpend,                                                                  // Yesterday Spend
                        //     ecpc            : item.clicks == 0 ? 0 : parseFloat(item.mediaSpend / item.clicks),                          // eCPC
                        //     ecpm            : item.impressions == 0 ? 0 : parseFloat((item.mediaSpend * 1000) / item.impressions),       // eCPM
                        // })
                    })
                    : []
                /******************************************
                 * Publishers in Account
                 *****************************************/
                : (groupBy == "publisher" && publisherPanel == "account") ? 
                    publishers.account ? publishers.account.map((item, index) => {
                        switch(getUserType()){
                            case "supernova":
                                return ({
                                    id              : item.publisher,
                                    media_source    : item.mediaSource,
                                    impressions     : item.impressions,
                                    clicks          : item.clicks,
                                    ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                    yesterday_spend : item.yesterdaySpend,
                                    data_spend      : parseFloat(item.dataspend_with_margin),
                                    media_spend     : parseFloat(item.mediaSpend),
                                    total_spend     : convertStringToNumber(item.dataspend_with_margin) + convertStringToNumber(item.mediaSpend),
                                    media_ecpc      : parseFloat(item.ecpc),
                                    media_ecpm      : parseFloat(item.ecpm),
                                    total_ecpc      : parseFloat(item.ecpc),
                                    total_ecpm      : parseFloat(item.ecpm),
                                    daily_budget    : 0
                                })
                                break;
                            case "direct-client":
                                return ({
                                    id              : item.publisher,
                                    media_source    : item.mediaSource,
                                    impressions     : item.impressions,
                                    clicks          : item.clicks,
                                    ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                    yesterday_spend : item.yesterdaySpend,
                                    data_spend      : parseFloat(item.dataspend_with_margin),
                                    media_spend     : parseFloat(item.media_spend_client),
                                    total_spend     : parseFloat(item.total_spend_client),
                                    media_ecpc      : parseFloat(item.media_ecpc_client),
                                    media_ecpm      : parseFloat(item.media_ecpm_spend_mod_delta_after_serving_fee),
                                    total_ecpc      : parseFloat(item.total_ecpc_client),
                                    total_ecpm      : parseFloat(item.total_ecpm_client),
                                    daily_budget    : 0
                                })
                                break;
                            case "sub-client":
                                return ({
                                    id              : item.publisher,
                                    media_source    : item.mediaSource,
                                    impressions     : item.impressions,
                                    clicks          : item.clicks,
                                    ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                    yesterday_spend : item.yesterdaySpend,
                                    data_spend      : parseFloat(item.dataspend_with_margin),
                                    media_spend     : parseFloat(item.media_spend_subclient),
                                    total_spend     : parseFloat(item.total_spend_subclient),
                                    media_ecpc      : parseFloat(item.media_ecpc_subclient),
                                    media_ecpm      : parseFloat(item.media_ecpm_subclient_spend_mod_delta_after_serving_fee),
                                    total_ecpc      : parseFloat(item.total_ecpc_subclient),
                                    total_ecpm      : parseFloat(item.total_ecpm_subclient),
                                    daily_budget    : 0
                                })
                                break;
                        }
                        ////////////////
                        // Old Version:
                        ////////////////
                        // return ({
                        //     id              : item.publisher,
                        //     media_source    : item.mediaSource,
                        //     impressions     : item.impressions,          
                        //     clicks          : item.clicks,
                        //     media_spend     : item.mediaSpend,
                        //     yesterday_spend : item.yesterdaySpend,
                        //     ecpc            : item.clicks == 0 ? 0 : parseFloat(item.mediaSpend / item.clicks),
                        //     ecpm            : item.impressions == 0 ? 0 : parseFloat((item.mediaSpend * 1000) / item.impressions),
                        // })
                    })
                    : []
                /******************************************
                 * Publishers in Campaign
                 *****************************************/
                : (groupBy == "publisher" && publisherPanel == "campaign") ? 
                    publishers.campaign ? publishers.campaign.filter(campaign => 
                    campaign.publisher.toString().toUpperCase().indexOf(publisherSearch.toUpperCase()) >= 0 ||
                    campaign.mediaSource.toString().toUpperCase().indexOf(publisherSearch.toUpperCase()) >= 0).map((item, index) => {
                        switch(getUserType()){
                            case "supernova":
                                return ({
                                    id              : item.publisher,
                                    media_source    : item.mediaSource,
                                    impressions     : item.impressions,
                                    clicks          : item.clicks,
                                    ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                    yesterday_spend : item.yesterdaySpend,
                                    data_spend      : parseFloat(item.dataspend_with_margin),
                                    media_spend     : parseFloat(item.mediaSpend),
                                    total_spend     : parseFloat(item.dataspend_with_margin) + parseFloat(item.mediaSpend),
                                    media_ecpc      : parseFloat(item.ecpc),
                                    media_ecpm      : parseFloat(item.ecpm),
                                    total_ecpc      : parseFloat(item.ecpc),
                                    total_ecpm      : parseFloat(item.ecpm),
                                    daily_budget    : 0
                                })
                                break;
                            case "direct-client":
                                return ({
                                    id              : item.publisher,
                                    media_source    : item.mediaSource,
                                    impressions     : item.impressions,
                                    clicks          : item.clicks,
                                    ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                    yesterday_spend : item.yesterdaySpend,
                                    data_spend      : parseFloat(item.dataspend_with_margin),
                                    media_spend     : parseFloat(item.media_spend_client),
                                    total_spend     : parseFloat(item.total_spend_client),
                                    media_ecpc      : parseFloat(item.media_ecpc_client),
                                    media_ecpm      : parseFloat(item.media_ecpm_spend_mod_delta_after_serving_fee),
                                    total_ecpc      : parseFloat(item.total_ecpc_client),
                                    total_ecpm      : parseFloat(item.total_ecpm_client),
                                    daily_budget    : 0
                                })
                                break;
                            case "sub-client":
                                return ({
                                    id              : item.publisher,
                                    media_source    : item.mediaSource,
                                    impressions     : item.impressions,
                                    clicks          : item.clicks,
                                    ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                    yesterday_spend : item.yesterdaySpend,
                                    data_spend      : parseFloat(item.dataspend_with_margin),
                                    media_spend     : parseFloat(item.media_spend_subclient),
                                    total_spend     : parseFloat(item.total_spend_subclient),
                                    media_ecpc      : parseFloat(item.media_ecpc_subclient),
                                    media_ecpm      : parseFloat(item.media_ecpm_subclient_spend_mod_delta_after_serving_fee),
                                    total_ecpc      : parseFloat(item.total_ecpc_subclient),
                                    total_ecpm      : parseFloat(item.total_ecpm_subclient),
                                    daily_budget    : 0
                                })
                                break;
                        }
                    })
                    : []
                /******************************************
                 * Publishers in Ad Group
                 *****************************************/
                : (groupBy == "publisher" && publisherPanel == "adgroup") ? 
                    publishers.adgroup ? publishers.adgroup.filter(adgroup => 
                        (adgroup.publisher.toString().toUpperCase().indexOf(publisherSearch.toUpperCase()) >= 0 || adgroup.mediaSource.toString().toUpperCase().indexOf(publisherSearch.toUpperCase()) >= 0)
                    ).map((item, index) => {
                        switch(getUserType()){
                            case "supernova":
                                return ({
                                    id              : item.publisher,
                                    media_source    : item.mediaSource,
                                    impressions     : item.impressions,
                                    clicks          : item.clicks,
                                    ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                    yesterday_spend : item.yesterdaySpend,
                                    data_spend      : parseFloat(item.dataspend_with_margin),
                                    media_spend     : parseFloat(item.mediaSpend),
                                    total_spend     : parseFloat(item.dataspend_with_margin) + parseFloat(item.mediaSpend),
                                    media_ecpc      : parseFloat(item.ecpc),
                                    media_ecpm      : parseFloat(item.ecpm),
                                    total_ecpc      : parseFloat(item.ecpc),
                                    total_ecpm      : parseFloat(item.ecpm),
                                    daily_budget    : 0
                                })
                                break;
                            case "direct-client":
                                return ({
                                    id              : item.publisher,
                                    media_source    : item.mediaSource,
                                    impressions     : item.impressions,
                                    clicks          : item.clicks,
                                    ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                    yesterday_spend : item.yesterdaySpend,
                                    data_spend      : parseFloat(item.dataspend_with_margin),
                                    media_spend     : parseFloat(item.media_spend_client),
                                    total_spend     : parseFloat(item.total_spend_client),
                                    media_ecpc      : parseFloat(item.media_ecpc_client),
                                    media_ecpm      : parseFloat(item.media_ecpm_spend_mod_delta_after_serving_fee),
                                    total_ecpc      : parseFloat(item.total_ecpc_client),
                                    total_ecpm      : parseFloat(item.total_ecpm_client),
                                    daily_budget    : 0
                                })
                                break;
                            case "sub-client":
                                return ({
                                    id              : item.publisher,
                                    media_source    : item.mediaSource,
                                    impressions     : item.impressions,
                                    clicks          : item.clicks,
                                    ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                    yesterday_spend : item.yesterdaySpend,
                                    data_spend      : parseFloat(item.dataspend_with_margin),
                                    media_spend     : parseFloat(item.media_spend_subclient),
                                    total_spend     : parseFloat(item.total_spend_subclient),
                                    media_ecpc      : parseFloat(item.media_ecpc_subclient),
                                    media_ecpm      : parseFloat(item.media_ecpm_subclient_spend_mod_delta_after_serving_fee),
                                    total_ecpc      : parseFloat(item.total_ecpc_subclient),
                                    total_ecpm      : parseFloat(item.total_ecpm_subclient),
                                    daily_budget    : 0
                                })
                                break;
                        }
                    })
                    : []
                /******************************************
                 * Publishers in Creatives (Deprecated)
                 *****************************************/
                : (groupBy == "publisher" && publisherPanel == "creative") ? 
                    publishers.creative ? publishers.creative.filter(creative => 
                        creative.supernovaAdgroupId == selectedAdgroup &&
                        (creative.publisher.toString().toUpperCase().indexOf(publisherSearch.toUpperCase()) >= 0 || creative.mediaSource.toString().toUpperCase().indexOf(publisherSearch.toUpperCase()) >= 0)
                    ).map((item, index) => {
                        switch(getUserType()){
                            case "supernova":
                                return ({
                                    id              : item.publisher,
                                    media_source    : item.mediaSource,
                                    impressions     : item.impressions,
                                    clicks          : item.clicks,
                                    ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                    yesterday_spend : item.yesterdaySpend,
                                    data_spend      : parseFloat(item.dataspend_with_margin),
                                    media_spend     : parseFloat(item.mediaSpend),
                                    total_spend     : parseFloat(item.dataspend_with_margin) + parseFloat(item.mediaSpend),
                                    media_ecpc      : parseFloat(item.ecpc),
                                    media_ecpm      : parseFloat(item.ecpm),
                                    total_ecpc      : parseFloat(item.ecpc),
                                    total_ecpm      : parseFloat(item.ecpm),
                                    daily_budget    : 0
                                })
                                break;
                            case "direct-client":
                                return ({
                                    id              : item.publisher,
                                    media_source    : item.mediaSource,
                                    impressions     : item.impressions,
                                    clicks          : item.clicks,
                                    ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                    yesterday_spend : item.yesterdaySpend,
                                    data_spend      : parseFloat(item.dataspend_with_margin),
                                    media_spend     : parseFloat(item.media_spend_client),
                                    total_spend     : parseFloat(item.total_spend_client),
                                    media_ecpc      : parseFloat(item.media_ecpc_client),
                                    media_ecpm      : parseFloat(item.media_ecpm_spend_mod_delta_after_serving_fee),
                                    total_ecpc      : parseFloat(item.total_ecpc_client),
                                    total_ecpm      : parseFloat(item.total_ecpm_client),
                                    daily_budget    : 0
                                })
                                break;
                            case "sub-client":
                                return ({
                                    id              : item.publisher,
                                    media_source    : item.mediaSource,
                                    impressions     : item.impressions,
                                    clicks          : item.clicks,
                                    ctr             : parseFloat(item.impressions == 0 ? 0 : item.clicks / item.impressions),
                                    yesterday_spend : item.yesterdaySpend,
                                    data_spend      : parseFloat(item.dataspend_with_margin),
                                    media_spend     : parseFloat(item.media_spend_subclient),
                                    total_spend     : parseFloat(item.total_spend_subclient),
                                    media_ecpc      : parseFloat(item.media_ecpc_subclient),
                                    media_ecpm      : parseFloat(item.media_ecpm_subclient_spend_mod_delta_after_serving_fee),
                                    total_ecpc      : parseFloat(item.total_ecpc_subclient),
                                    total_ecpm      : parseFloat(item.total_ecpm_subclient),
                                    daily_budget    : 0
                                })
                                break;
                        }
                    })
                    : []
                : []
            )
    }

    /**********************************************************************
     * Get Statistics of All Campaigns
     * Group by Campaign
     **********************************************************************/
    const displayMetricsOfAllCampaigns = () => {
        return (
            <table id="primary-report-table" className={`primary has-hover ${getCampaignType()}`}>  
                <thead>
                    <tr>
                            <th className={`fixed id ${tableSortBy == "id" ? "active" : ""}`} onClick={() => {setTableSortBy("id"); tableSortBy == "id" && setIsTableSortDescending(!isTableSortDescending); }}>
                                <span className="label">Campaign ID {tableSortBy == "id" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                <span className="badge hidden"></span>
                            </th>
                            <th className={`fixed name ${tableSortBy == "name" ? "active" : ""}`} onClick={() => {setTableSortBy("name"); tableSortBy == "name" && setIsTableSortDescending(!isTableSortDescending); }}>
                                <span className="label">Campaign Name {tableSortBy == "name" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                <span className="badge hidden"></span>
                            </th>
                            <th className={`stats text-right ${tableSortBy == "impressions" ? "active" : ""}`} onClick={() => {setTableSortBy("impressions"); tableSortBy == "impressions" && setIsTableSortDescending(!isTableSortDescending); }}>
                                <span className="label">Impression {tableSortBy == "impressions" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                <span className="badge">{tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0).toLocaleString()}</span>
                            </th>
                            <th className={`stats text-right ${tableSortBy == "clicks" ? "active" : ""}`} onClick={() => {setTableSortBy("clicks"); tableSortBy == "clicks" && setIsTableSortDescending(!isTableSortDescending); }}>
                                <span className="label">Clicks {tableSortBy == "clicks" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                <span className="badge">{tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0).toLocaleString()}</span>
                            </th>
                            <th className={`stats text-right ${tableSortBy == "ctr" ? "active" : ""}`} onClick={() => {setTableSortBy("ctr"); tableSortBy == "ctr" && setIsTableSortDescending(!isTableSortDescending); }}>
                                <span className="label">CTR {tableSortBy == "ctr" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                <span className="badge">
                                {
                                    tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0) > 0 
                                    ? `${parseFloat(tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0) /  tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0) * 100).toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}%`
                                    : "0.00%"
                                }
                                </span>
                            </th>
                            <th className={`${isColumnMediaSpendVisible ? "" : "d-none"} stats text-right ${tableSortBy == "media_spend" && "active"}`} onClick={() => {setTableSortBy("media_spend"); tableSortBy == "media_spend" && setIsTableSortDescending(!isTableSortDescending); }}>
                                <span className="label">Media Spend {tableSortBy == "media_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                <span className="badge">${
                                    tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.media_spend) ? "0.00" : accumulator + object.media_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                }</span>
                            </th>   
                            <th className={`${isColumnDataSpendVisible ? "" : "d-none"} data-spend stats text-right ${tableSortBy == "data_spend" && "active"}`} onClick={() => {setTableSortBy("data_spend"); tableSortBy == "data_spend" && setIsTableSortDescending(!isTableSortDescending); }}>
                                <span className="label">Data Spend {tableSortBy == "data_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                <span className="badge">${
                                    tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.data_spend) ? "0.00" : accumulator + object.data_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                }</span>
                            </th>   
                            <th className={`stats text-right ${tableSortBy == "total_spend" && "active"}`} onClick={() => {setTableSortBy("total_spend"); tableSortBy == "total_spend" && setIsTableSortDescending(!isTableSortDescending); }}>
                                <span className="label">Total Spend {tableSortBy == "total_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                <span className="badge">${
                                    tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.total_spend) ? "0.00" : accumulator + object.total_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                }</span>
                            </th>   
                            <th className={`d-none stats text-right ${tableSortBy == "yesterday_spend" && "active"}`} onClick={() => {setTableSortBy("yesterday_spend"); tableSortBy == "yesterday_spend" && setIsTableSortDescending(!isTableSortDescending); }}>
                                <span className="label">Yesterday Spend {tableSortBy == "yesterday_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                <span className="badge hidden"></span>
                            </th>   
                            <th className={`${isColumnMediaECPCVisible ? "" : "d-none"} stats text-right ${tableSortBy == "media_ecpc" && "active"}`} onClick={() => {setTableSortBymedia_("ecpc"); tableSortBy == "media_ecpc" && setIsTableSortDescending(!isTableSortDescending); }}>
                                <span className="label">Media eCPC {tableSortBy == "media_ecpc" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                <span className="badge">${
                                    tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.media_ecpc) ? "0.00" : accumulator + object.media_ecpc}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                }</span>
                            </th>
                            <th className={`${isColumnMediaECPMVisible ? "" : "d-none"} stats text-right ${tableSortBy == "media_ecpm" && "active"}`} onClick={() => {setTableSortBymedia_("ecpm"); tableSortBy == "media_ecpm" && setIsTableSortDescending(!isTableSortDescending); }}>
                                <span className="label">Media eCPM {tableSortBy == "media_ecpm" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                <span className="badge">${
                                    tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.media_ecpm) ? "0.00" : accumulator + object.media_ecpm}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                }</span>
                            </th>
                            <th className={`stats text-right ${tableSortBy == "total_ecpc" && "active"}`} onClick={() => {setTableSortBymedia_("ecpc"); tableSortBy == "total_ecpc" && setIsTableSortDescending(!isTableSortDescending); }}>
                                <span className="label">Total eCPC {tableSortBy == "total_ecpc" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                <span className="badge">${
                                    //TOTAL_ECPC = TOTAL_SPEND /  TOTAL_CLICKS
                                    tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0) == 0
                                    ? "0.00"
                                    : parseFloat(
                                        tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.total_spend) ? 0 : accumulator + object.total_spend}, 0) /
                                        tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0)
                                        ).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                }</span>
                            </th>
                            <th className={`stats text-right ${tableSortBy == "total_ecpm" && "active"}`} onClick={() => {setTableSortBymedia_("ecpm"); tableSortBy == "total_ecpm" && setIsTableSortDescending(!isTableSortDescending); }}>
                                <span className="label">Total eCPM {tableSortBy == "total_ecpm" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                <span className="badge">${
                                    //TOTAL_ECPM = ( TOTAL_SPEND x 1000 ) /  TOTAL_IMPRESSIONS
                                    tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0) == 0
                                    ? "0.00"
                                    : parseFloat(
                                        (tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.total_spend) ? 0 : accumulator + object.total_spend}, 0) * 1000) /
                                        tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0)
                                        ).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                }</span>
                            </th>
                            <th className={`d-none stats text-right ${tableSortBy == "daily_budget" && "active"}`} onClick={() => {setTableSortBy("daily_budget"); tableSortBy == "daily_budget" && setIsTableSortDescending(!isTableSortDescending); }}>
                                <span className="label">Daily Budget {tableSortBy == "daily_budget" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                <span className="badge">${
                                    tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.daily_budget) ? "0.00" : accumulator + object.daily_budget}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                }</span>
                            </th>   
                    </tr>
                </thead>
                <tbody>
                        {
                            tableArray(groupBy).sortBy(tableSortBy, isTableSortDescending).map((item, index) =>
                                <tr key={index}>
                                    <td className="fixed id"><span>{item.id}</span></td>
                                    <td className="fixed name"><span className='blue-link plain'>{item.name}</span></td>
                                    <td className="stats text-right"><span>{item.impressions.toLocaleString()}</span></td>
                                    <td className="stats text-right"><span>{item.clicks.toLocaleString()}</span></td>
                                    <td className="stats text-right"><span>{`${(item.clicks == 0 ? 0 : (item.clicks / item.impressions) * 100).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}%`}</span></td>
                                    <td className={`${isColumnMediaSpendVisible ? "" : "d-none"} stats text-right`}><span>{`$${item.media_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}</span></td>
                                    <td className={`${isColumnDataSpendVisible ? "" : "d-none"} data-spend stats text-right`}><span>{`$${item.data_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}</span></td>
                                    <td className="stats text-right"><span>{`$${item.total_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}</span></td>
                                    <td className="d-none stats text-right"><span>{`$${item.yesterday_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}%`}</span></td>
                                    <td className={`${isColumnMediaECPCVisible ? "" : "d-none"} stats text-right`}><span>{`$${item.media_ecpc.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}</span></td>
                                    <td className={`${isColumnMediaECPMVisible ? "" : "d-none"} stats text-right`}><span>{`$${item.media_ecpm.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}</span></td>
                                    <td className="stats text-right"><span>{`$${
                                            //ECPC = spend / clicks
                                            isNaN((item.total_spend / item.clicks).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                            ? "0.00"
                                            : (item.total_spend / item.clicks).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                        }`}</span></td>
                                    <td className="stats text-right"><span>{`$${
                                            //ECPM = ( spend x 1,000 ) / impressions
                                            isNaN(((item.total_spend * 1000) / item.impressions).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                            ? "0.00"
                                            : ((item.total_spend * 1000) / item.impressions).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                        }`}</span></td>
                                    <td className="d-none stats text-right"><span>{`$${item.daily_budget.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}</span></td>
                                </tr>
                        )}
                </tbody>
            </table>
        )
    }

    /**********************************************************************
     * Get Statistics inside a campaign (CampaignId)
     * Group by AdGroup
     **********************************************************************/
    const displayMetricsOfAdgroupsByCampaignId = () => {
        return (
            <table id="primary-report-table" className={`primary has-hover ${getCampaignType()}`}>  
                <thead>
                    <tr>
                            <th className={`fixed id ${tableSortBy == "id" ? "active" : ""}`} onClick={() => {setTableSortBy("id"); tableSortBy == "id" && setIsTableSortDescending(!isTableSortDescending); }}>
                                Adgroup ID {tableSortBy == "id" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}
                            </th>
                            <th className={`fixed name ${tableSortBy == "name" ? "active" : ""}`} onClick={() => {setTableSortBy("name"); tableSortBy == "name" && setIsTableSortDescending(!isTableSortDescending); }}>
                                Adgroup Name {tableSortBy == "name" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}
                            </th>
                            <th className={`stats text-right ${tableSortBy == "impressions" ? "active" : ""}`} onClick={() => {setTableSortBy("impressions"); tableSortBy == "impressions" && setIsTableSortDescending(!isTableSortDescending); }}>
                                <span className="label">Impression {tableSortBy == "impressions" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                <span className="badge">{tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0).toLocaleString()}</span>
                            </th>
                            <th className={`stats text-right ${tableSortBy == "clicks" ? "active" : ""}`} onClick={() => {setTableSortBy("clicks"); tableSortBy == "clicks" && setIsTableSortDescending(!isTableSortDescending); }}>
                                <span className="label">Clicks {tableSortBy == "clicks" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                <span className="badge">{tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0).toLocaleString()}</span>
                            </th>
                            <th className={`stats text-right ${tableSortBy == "ctr" ? "active" : ""}`} onClick={() => {setTableSortBy("ctr"); tableSortBy == "ctr" && setIsTableSortDescending(!isTableSortDescending); }}>
                                <span className="label">CTR {tableSortBy == "ctr" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                <span className="badge">
                                {
                                    tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0) > 0 
                                    ? `${parseFloat(tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0) /  tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0) * 100).toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}%`
                                    : "0.00%"
                                }
                                </span>
                            </th>
                            <th className={`${isColumnMediaSpendVisible ? "" : "d-none"} stats text-right ${tableSortBy == "media_spend" ? "active" : ""}`} onClick={() => {setTableSortBy("media_spend"); tableSortBy == "media_spend" && setIsTableSortDescending(!isTableSortDescending); }}>
                                <span className="label">Media Spend {tableSortBy == "media_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                <span className="badge">${
                                    tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.media_spend) ? "0.00" : accumulator + object.media_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                }</span>
                            </th>   
                            <th className={`${isColumnDataSpendVisible ? "" : "d-none"} data-spend stats text-right ${tableSortBy == "data_spend" ? "active" : ""}`} onClick={() => {setTableSortBy("data_spend"); tableSortBy == "data_spend" && setIsTableSortDescending(!isTableSortDescending); }}>
                                Data Spend {tableSortBy == "data_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}
                                <span className="badge">${
                                    tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.data_spend) ? "0.00" : accumulator + object.data_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                }</span>
                            </th>   
                            <th className={`stats text-right ${tableSortBy == "total_spend" ? "active" : ""}`} onClick={() => {setTableSortBy("total_spend"); tableSortBy == "total_spend" && setIsTableSortDescending(!isTableSortDescending); }}>
                                <span className="label">Total Spend {tableSortBy == "total_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                <span className="badge">${
                                    tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.total_spend) ? "0.00" : accumulator + object.total_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                }</span>
                            </th>   
                            <th className={`d-none stats text-right ${tableSortBy == "yesterday_spend" ? "active" : ""}`} onClick={() => {setTableSortBy("yesterday_spend"); tableSortBy == "yesterday_spend" && setIsTableSortDescending(!isTableSortDescending); }}>
                                <span className="label">Yesterday Spend {tableSortBy == "yesterday_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                <span className="badge">${
                                    tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.yesterday_spend) ? "0.00" : accumulator + object.yesterday_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                }</span>
                            </th>   
                            <th className={`${isColumnMediaECPCVisible ? "" : "d-none"} stats text-right ${tableSortBy == "media_ecpc" ? "active" : ""}`} onClick={() => {setTableSortBy("media_ecpc"); tableSortBy == "media_ecpc" && setIsTableSortDescending(!isTableSortDescending); }}>
                                <span className="label">Media eCPC {tableSortBy == "media_ecpc" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                <span className="badge">${
                                    tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.media_ecpc) ? "0.00" : accumulator + object.media_ecpc}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                }</span>
                            </th>
                            <th className={`${isColumnMediaECPMVisible ? "" : "d-none"} stats text-right ${tableSortBy == "media_ecpm" ? "active" : ""}`} onClick={() => {setTableSortBy("media_ecpm"); tableSortBy == "media_ecpm" && setIsTableSortDescending(!isTableSortDescending); }}>
                                <span className="label">Media eCPM {tableSortBy == "media_ecpm" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                <span className="badge">${
                                    tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.media_ecpm) ? "0.00" : accumulator + object.media_ecpm}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                }</span>
                            </th>
                            <th className={`stats text-right ${tableSortBy == "total_ecpc" ? "active" : ""}`} onClick={() => {setTableSortBy("total_ecpc"); tableSortBy == "total_ecpc" && setIsTableSortDescending(!isTableSortDescending); }}>
                                <span className="label">Total eCPC {tableSortBy == "total_ecpc" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                <span className="badge">${
                                    //TOTAL_ECPC = TOTAL_SPEND /  TOTAL_CLICKS
                                    tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0) == 0
                                    ? "0.00"
                                    : parseFloat(
                                        tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.total_spend) ? 0 : accumulator + object.total_spend}, 0) /
                                        tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0)
                                        ).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                }</span>
                            </th>
                            <th className={`stats text-right ${tableSortBy == "total_ecpm" ? "active" : ""}`} onClick={() => {setTableSortBy("total_ecpm"); tableSortBy == "total_ecpm" && setIsTableSortDescending(!isTableSortDescending); }}>
                                <span className="label">Total eCPM {tableSortBy == "total_ecpm" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                <span className="badge">${
                                    //TOTAL_ECPM = ( TOTAL_SPEND x 1000 ) /  TOTAL_IMPRESSIONS
                                    tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0) == 0
                                    ? "0.00"
                                    : parseFloat(
                                        (tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.total_spend) ? 0 : accumulator + object.total_spend}, 0) * 1000) /
                                        tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0)
                                        ).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                }</span>
                            </th>
                            <th className={`d-none stats text-right ${tableSortBy == "daily_budget" ? "active" : ""}`} onClick={() => {setTableSortBy("daily_budget"); tableSortBy == "daily_budget" && setIsTableSortDescending(!isTableSortDescending); }}>
                                Daily Budget {tableSortBy == "daily_budget" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}
                            </th>   
                    </tr>
                </thead>
                <tbody>
                    {
                        tableArray(groupBy).sortBy(tableSortBy, isTableSortDescending).map((item, index) =>
                            <tr key={index}>
                                <td className="fixed id"><span>{item.id}</span></td>
                                <td className="fixed name"><span className='blue-link plain'>{item.name}</span></td>
                                <td className="stats text-right"><span>{item.impressions.toLocaleString()}</span></td>
                                <td className="stats text-right"><span>{item.clicks.toLocaleString()}</span></td>
                                <td className="stats text-right"><span>{`${(item.clicks == 0 ? 0 : (item.clicks / item.impressions) * 100).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}%`}</span></td>
                                <td className={`${isColumnMediaSpendVisible ? "" : "d-none"} stats text-right`}><span>{`$${item.media_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}</span></td>
                                <td className={`${isColumnDataSpendVisible ? "" : "d-none"} data-spend stats text-right`}><span>{`$${item.data_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}</span></td>
                                <td className="stats text-right"><span>{`$${item.total_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}</span></td>
                                <td className="d-none stats text-right"><span>{`$${item.yesterday_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}%`}</span></td>
                                <td className={`${isColumnMediaECPCVisible ? "" : "d-none"} stats text-right`}><span>{`$${item.media_ecpc.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}</span></td>
                                <td className={`${isColumnMediaECPMVisible ? "" : "d-none"} stats text-right`}><span>{`$${item.media_ecpm.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}</span></td>
                                <td className="stats text-right"><span>{`$${
                                        //ECPC = spend / clicks
                                        isNaN((item.total_spend / item.clicks).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                        ? "0.00"
                                        : (item.total_spend / item.clicks).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                    }`}</span></td>
                                <td className="stats text-right"><span>{`$${
                                        //ECPM = ( spend x 1,000 ) / impressions
                                        isNaN(((item.total_spend * 1000) / item.impressions).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                        ? "0.00"
                                        : ((item.total_spend * 1000) / item.impressions).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                    }`}</span></td>
                                <td className="d-none stats text-right"><span>{`$${item.daily_budget.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}</span></td>
                            </tr>
                    )}
                </tbody>
            </table>
        )
    }

    /**********************************************************************
     * Get Statistics inside an ad-group (AdGroupId)
     * Group by Creatives
     **********************************************************************/
    const displayMetricsOfCreativesByAdgroupId = () => {
        return (
            <table id="primary-report-table" className={`primary has-hover ${getCampaignType()}`}>  
                <thead>
                    <tr>
                        <th className={`fixed id ${tableSortBy == "id" ? "active" : ""}`} onClick={() => {setTableSortBy("id"); tableSortBy == "id" && setIsTableSortDescending(!isTableSortDescending); }}>
                            Creative ID {tableSortBy == "id" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}
                        </th>
                        <th className={`fixed creative-title ${tableSortBy == "title" ? "active" : ""}`} onClick={() => {setTableSortBy("title"); tableSortBy == "title" && setIsTableSortDescending(!isTableSortDescending); }}>
                            Creative Title {tableSortBy == "title" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}
                        </th>
                        <th className={`creative-size  ${getCampaignType() == "PUSH_NOTIFICATION" ? "d-none" : ""} ${tableSortBy == "size" ? "active" : ""}`} onClick={() => {setTableSortBy("size"); tableSortBy == "size" && setIsTableSortDescending(!isTableSortDescending); }}>
                            <span className="label">Size {tableSortBy == "size" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                            <span className="badge hidden"></span>
                        </th>
                        <th className={`stats  ${getCampaignType() == "PUSH_NOTIFICATION" ? "d-none" : ""} ${tableSortBy == "type" ? "active" : ""}`} onClick={() => {setTableSortBy("type"); tableSortBy == "type" && setIsTableSortDescending(!isTableSortDescending); }}>
                            <span className="label">Type {tableSortBy == "type" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                            <span className="badge hidden"></span>
                        </th>
                        <th className={`stats text-right ${tableSortBy == "impressions" ? "active" : ""}`} onClick={() => {setTableSortBy("impressions"); tableSortBy == "impressions" && setIsTableSortDescending(!isTableSortDescending); }}>
                            <span className="label">Impression {tableSortBy == "impressions" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                            <span className="badge">{tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0).toLocaleString()}</span>
                        </th>
                        <th className={`stats text-right ${tableSortBy == "clicks" ? "active" : ""}`} onClick={() => {setTableSortBy("clicks"); tableSortBy == "clicks" && setIsTableSortDescending(!isTableSortDescending); }}>
                            <span className="label">Clicks {tableSortBy == "clicks" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                            <span className="badge">{tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0).toLocaleString()}</span>
                        </th>
                        <th className={`stats text-right ${tableSortBy == "ctr" ? "active" : ""}`} onClick={() => {setTableSortBy("ctr"); tableSortBy == "ctr" && setIsTableSortDescending(!isTableSortDescending); }}>
                            <span className="label">CTR {tableSortBy == "ctr" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                            <span className="badge">
                            {
                                tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0) > 0 
                                ? `${parseFloat(tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0) /  tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0) * 100).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}%`
                                : "0.00%"
                            }
                            </span>
                        </th>
                        <th className={`${isColumnMediaSpendVisible ? "" : "d-none"} stats text-right ${tableSortBy == "media_spend" ? "active" : ""}`} onClick={() => {setTableSortBy("media_spend"); tableSortBy == "media_spend" && setIsTableSortDescending(!isTableSortDescending); }}>
                            <span className="label">Media Spend {tableSortBy == "media_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                            <span className="badge">${
                                tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.media_spend) ? "0.00" : accumulator + object.media_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                            }</span>
                        </th>   
                        <th className={`${isColumnDataSpendVisible ? "" : "d-none"} data-spend stats text-right ${tableSortBy == "data_spend" ? "active" : ""}`} onClick={() => {setTableSortBy("data_spend"); tableSortBy == "data_spend" && setIsTableSortDescending(!isTableSortDescending); }}>
                            <span className="label">Data Spend {tableSortBy == "data_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                            <span className="badge">${
                                tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.data_spend) ? "0.00" : accumulator + object.data_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                            }</span>
                        </th>   
                        <th className={`stats text-right ${tableSortBy == "total_spend" ? "active" : ""}`} onClick={() => {setTableSortBy("total_spend"); tableSortBy == "total_spend" && setIsTableSortDescending(!isTableSortDescending); }}>
                            <span className="label">Total Spend {tableSortBy == "total_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                            <span className="badge">${
                                    tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.total_spend) ? "0.00" : accumulator + object.total_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                }
                            </span>
                        </th>   
                        <th className={`d-none stats text-right ${tableSortBy == "yesterday_spend" ? "active" : ""}`} onClick={() => {setTableSortBy("yesterday_spend"); tableSortBy == "yesterday_spend" && setIsTableSortDescending(!isTableSortDescending); }}>
                            <span className="label">Yesterday Spend {tableSortBy == "yesterday_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                            <span className="badge">${
                                tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.yesterday_spend) ? "0.00" : accumulator + object.yesterday_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                            }</span>
                        </th>   
                        <th className={`${isColumnMediaECPCVisible ? "" : "d-none"} stats text-right ${tableSortBy == "media_ecpc" ? "active" : ""}`} onClick={() => {setTableSortBy("media_ecpc"); tableSortBy == "media_ecpc" && setIsTableSortDescending(!isTableSortDescending); }}>
                            <span className="label">Media eCPC {tableSortBy == "media_ecpc" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                            <span className="badge">${
                                tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.media_ecpc) ? "0.00" : accumulator + object.media_ecpc}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                            }</span>
                        </th>
                        <th className={`${isColumnMediaECPMVisible ? "" : "d-none"} stats text-right ${tableSortBy == "media_ecpm" ? "active" : ""}`} onClick={() => {setTableSortBy("media_ecpm"); tableSortBy == "media_ecpm" && setIsTableSortDescending(!isTableSortDescending); }}>
                            <span className="label">Media eCPM {tableSortBy == "media_ecpm" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                            <span className="badge">${
                                tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.media_ecpm) ? "0.00" : accumulator + object.media_ecpm}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                            }</span>
                        </th>
                        <th className={`stats text-right ${tableSortBy == "total_ecpc" ? "active" : ""}`} onClick={() => {setTableSortBy("total_ecpc"); tableSortBy == "total_ecpc" && setIsTableSortDescending(!isTableSortDescending); }}>
                            <span className="label">Total eCPC {tableSortBy == "total_ecpc" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                            <span className="badge">${
                                //TOTAL_ECPC = TOTAL_SPEND /  TOTAL_CLICKS
                                tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0) == 0
                                ? "0.00"
                                : parseFloat(
                                    tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.total_spend) ? 0 : accumulator + object.total_spend}, 0) /
                                    tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0).toLocaleString()
                                    ).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                            }</span>
                        </th>
                        <th className={`stats text-right ${tableSortBy == "total_ecpm" ? "active" : ""}`} onClick={() => {setTableSortBy("total_ecpm"); tableSortBy == "total_ecpm" && setIsTableSortDescending(!isTableSortDescending); }}>
                            <span className="label">Total eCPM {tableSortBy == "total_ecpm" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                            <span className="badge">${
                                //TOTAL_ECPM = ( TOTAL_SPEND x 1000 ) /  TOTAL_IMPRESSIONS
                                tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0) == 0
                                ? "0.00"
                                : parseFloat(
                                    (tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.total_spend) ? 0 : accumulator + object.total_spend}, 0) * 1000) /
                                    tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0)
                                    ).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                            }</span>
                        </th>
                        <th className={`d-none stats text-right ${tableSortBy == "daily_budget" ? "active" : ""}`} onClick={() => {setTableSortBy("daily_budget"); tableSortBy == "daily_budget" && setIsTableSortDescending(!isTableSortDescending); }}>
                            <span className="label">Daily Budget {tableSortBy == "daily_budget" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                            <span className="badge">${
                                tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.daily_budget) ? "0.00" : accumulator + object.daily_budget}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                            }</span>
                        </th>   
                    </tr>
                </thead>
                <tbody>
                    {
                        tableArray(groupBy).sortBy(tableSortBy, isTableSortDescending).slice(paginationOffset, paginationOffset + tableRowsPerPage).map((item, index) =>
                            <tr className={`${focusedCreativeId == item.id ? "selected" : ""} clickable ${item.archived ? 'd-none' : ''}`} onMouseEnter={() => setFocusedCreativeId(item.id)} key={index} onClick={() => editCreative("creative", "edit", selectedAdgroup, item.id)}>
                                <td className="fixed id"><span>{item.id}</span></td>
                                <td className="fixed creative-title"><span className='blue-link plain'>{item.title}</span></td>
                                <td className={`creative-size ${getCampaignType() == "PUSH_NOTIFICATION" ? "d-none" : ""}`}><span className='blue-link plain'>{item.size}</span></td>
                                <td className={`creative-type text-right  ${getCampaignType() == "PUSH_NOTIFICATION" ? "d-none" : ""}`}><span>{item.type && item.type.replace(/_/g, ' ').toLowerCase()}</span></td>
                                <td className="stats text-right"><span>{item.impressions.toLocaleString()}</span></td>
                                <td className="stats text-right"><span>{item.clicks.toLocaleString()}</span></td>
                                <td className="stats text-right"><span>{`${(item.clicks == 0 ? 0 : (item.clicks / item.impressions) * 100).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}%`}</span></td>
                                <td className={`${isColumnMediaSpendVisible ? "" : "d-none"} stats text-right`}><span>{`$${item.media_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}</span></td>
                                <td className={`${isColumnDataSpendVisible ? "" : "d-none"} data-spend stats text-right`}><span>{`$${item.data_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}</span></td>
                                <td className="stats text-right"><span>{`$${item.total_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}</span></td>
                                <td className="d-none stats text-right"><span>{`$${item.yesterday_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}%`}</span></td>
                                <td className={`${isColumnMediaECPCVisible ? "" : "d-none"} stats text-right`}><span>{`$${item.media_ecpc.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}</span></td>
                                <td className={`${isColumnMediaECPMVisible ? "" : "d-none"} stats text-right`}><span>{`$${item.media_ecpm.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}</span></td>
                                <td className="stats text-right"><span>{`$${
                                        //ECPC = spend / clicks
                                        isNaN((item.total_spend / item.clicks).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                        ? "0.00"
                                        : (item.total_spend / item.clicks).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                    }`}</span></td>
                                <td className="stats text-right"><span>{`$${
                                        //ECPM = ( spend x 1,000 ) / impressions
                                        isNaN(((item.total_spend * 1000) / item.impressions).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                        ? "0.00"
                                        : ((item.total_spend * 1000) / item.impressions).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                    }`}</span></td>
                                <td className="d-none stats text-right"><span>{`$${item.daily_budget.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}</span></td>
                            </tr>
                    )}
                </tbody>
            </table>
        )
    }

    const getCampaignType = () => {
        let type = selectedNewCampaignType != null 
        ? 
            selectedNewCampaignType 
        : 
            selectedSupernovaCampaignId != null && 
            campaigns && 
            campaigns.items && 
            campaigns.items.filter(item => item.supernovaCampaignId == selectedSupernovaCampaignId).length > 0 &&
            campaigns.items.filter(item => item.supernovaCampaignId == selectedSupernovaCampaignId)[0].type
            
        return type;
    }

    const handleDropdownChange = (dropdown) => {
        const value = Array.isArray(dropdown)
            ? dropdown.map((item) => {
                return item.value
            })
            : dropdown === null ? "" : dropdown.value
        return (value)
    }
    

    //Note this function is deprecated and needs to be updated.
    const isCampaignFlightDateEnded = (cid) => {

        let campaign = campaigns.items.filter(item => item.supernovaCampaignId == cid)[0];
        let start = null
        let end = null

        
        // console.log(campaign.budgets && campaign.budgets.length > 0 && campaign.budgets[0] != null && campaign.budgets.filter(item => item != null))
        // return false;
        
        //Minimum start date in the selected budget
        start = campaign.budgets && campaign.budgets.length > 0 && moment(moment.min(campaign.budgets.filter(item => item != null).map(d => {return moment(d.startDate.toString())} )))
        
        //Maximum end date in the selected budget
        end = campaign.budgets && campaign.budgets.length > 0 && moment(moment.min(campaign.budgets.filter(item => item != null).map(d => {return moment(d.endDate.toString())} )))
        
        return (moment().isBetween(start, end) || start === undefined || end === undefined) ? false : true    //Return false if the flight is still on going
    }

    const checkIfAdgroupIsActive = (adgroup) => {
        
        //Start Date
        let start = moment(adgroup.startDate)
        
        //End Date
        let end = adgroup.endDate != null ?
            moment(adgroup.endDate)
        : campaigns.items && campaigns.items.filter(item => item.supernovaCampaignId == selectedSupernovaCampaignId).map(campaign => {return(
            campaign.budgets && campaign.budgets.length > 0 &&
                moment(moment.min(campaign.budgets.filter(item => item != null).map(d => {return moment(d.endDate.toString())})))
        )})
        return end === undefined ? false : moment().isBetween(start, end[0])
    }

    const convertStringToNumber = (num) =>{
        return typeof String(num) ? parseFloat(num.toString().replaceAll(',', '')) : num
    }

    const escFunction = (event) => {
        if (event.key === "Escape") {
          setIsShowColumnFormVisible(false);
        }
    }

    const handleSubmitEditCampaign = (e) => {
        e.preventDefault();
        const selected_supernovaCampaignId = campaigns.selected
        setNewPushAdCampaignName("")
        setIsPushAdEditCampaignFormVisible(false);
        props.editPushCampaign({name:newPushAdCampaignName}, selected_supernovaCampaignId)
    }

    const handleSubmitNewCampaign = (e) => {
        e.preventDefault();
        setNewPushAdCampaignName("")
        setIsPushAdNewCampaignFormVisible(false);
        props.addPushCampaign({
            name:newPushAdCampaignName,
            accountId:user.accountId,
            type:"PUSH_NOTIFICATION"
        })
    }

    const toggleCampaignState = (e) =>{
        e.preventDefault();
        e.stopPropagation();
        const cid = e.target.getAttribute('data-cid')
        const campaignType = props.campaigns.items.filter(item => item.supernovaCampaignId == cid)[0].type
        if(e.target.checked){

            //1. Remove from campaigns_inactive table
            props.enableCampaign({
                accountId       : user.accountId,
                supernovaCampaignId      : cid,
                campaignType    : campaignType
            })
        }
        else{
            //1. Add to campaigns_inactive table
            props.disableCampaign({
                accountId       : user.accountId,
                supernovaCampaignId      : cid,
                campaignType    : campaignType
            })
        }
    }

    const toggleAdgroupState = (e) =>{
        // e.preventDefault();  //Commented out because it causes glitchy user experience
        if(getCampaignType() == "PUSH_NOTIFICATION"){
            //Propeller
            props.togglePushCampaign(
                e.target.getAttribute('data-aid'),         // 1st param: Push Campaign ID
                e.target.checked ? "play" : "stop",        // 2nd param: Play or Stop a push campaign
                selectedSupernovaCampaignId,                          // 3rd param: Parent CampaignID in supernova
            )
        }
        else{
            //Zemanta
            props.updateAdgroup({
                id          : e.target.getAttribute('data-aid'),
                state      : e.target.checked ? "ACTIVE" : "INACTIVE",
                supernovaCampaignId  : selectedSupernovaCampaignId,
            }, user.accountId)
        }
    }

    const toggleCreativeState = (e) =>{
        //Zemanta
        let supernovaCreativeId  = e.target.getAttribute('data-cid');
        let ad                  = ads.items.find(item => item.creative.id == supernovaCreativeId)
        let supernovaAdgroupId  = ad.supernovaAdgroupId
        if(supernovaAdgroupId !== undefined){
            props.updateAds(
                supernovaAdgroupId,
                {
                    state       : e.target.checked ? "ACTIVE" : "INACTIVE",
                    creative: {
                        id: supernovaCreativeId,
                    }
                },
                selectedEntityId //Note: the selected EntityId during this time is the supernovaAdgroupId
            )
        }
    }

    const getOriginId = (id) => {
        const recordFound = duplicates && duplicates.items ? duplicates.items.filter(item => item.supernovaCampaignId == id) : []
        let originId = null
        if(recordFound.length > 0){
            originId = recordFound[0].originId
        }
        return originId
    }

    const getIdOfDuplicateCampaign = (id) => {
        const recordFound = duplicates && duplicates.items ? duplicates.items.filter(item => item.originId == id) : []
        let originId = null
        if(recordFound.length > 0){
            originId = recordFound[0].supernovaCampaignId
        }
        return originId
    }

    const isDuplicateCampaign = (id) => {
        //If there is an origin then it must be a duplicate campaign
        return getOriginId(id) == null ? false : true
    }

    const hasDuplicateCampaign = (id) => {
        const recordFound = duplicates && duplicates.items ? duplicates.items.filter(item => item.originId == id) : []
        if(recordFound.length > 0){
            return true
        }
        else{
            return false
        }
    }

    const isAdActive = (supernovaCreativeId) =>{
        //Zemanta
        let state        = ads.items.find(item => item?.supernovaCreativeId == supernovaCreativeId)?.state || "INACTIVE"
        
        //Uncomment this do make push creatives active
        let user_status  = creatives.items.find(item => item.id == supernovaCreativeId)?.user_status
        if(state == "ACTIVE" || user_status !== undefined){
        // if(state == "ACTIVE"){
            return true
        }
        else{
            return false
        }
    }

    const isCampaignInactive = (cid) => {
        return campaigns.inactive.findIndex(item => item.supernovaCampaignId == cid) >= 0 ? true : false
    }

    //////////////////////////////////////////////////////////////////////////
    // CONVERSION DEFINITION AND AUDIENCES FUNCTIONS
    //////////////////////////////////////////////////////////////////////////
    const showPixelForm = (data, purpose) => {
        setIsEditPixelWindowVisible(true); 
        setIsEditingForm(purpose == "edit" ? true : false); 
        setPixelId(data == null ? "" : data.id)
        setPixelName(data == null ? "" : data.name)
        setPixelNotes(data == null ? "" : data.notes)
        setPixelConversionType(data == null ? "" : data.conversionType)
        setConversionTrigger(data == null ? "" : data.conversionTriggerType)
        if(data != null && data.measurementMatchingRules && data.measurementMatchingRules.length > 0 && data.measurementMatchingRules[0].value){
            setMeasurementMatchingRules_value(data.measurementMatchingRules[0].value)
            setMeasurementMatchingRules_actionType(data.measurementMatchingRules[0].actionType)
        }
    }

    const showAudienceForm = (data, purpose) => {
        setIsEditAudienceWindowVisible(true)
        setIsEditingForm(purpose == "edit" ? true : false);
        setAudienceName(data == null ? "" : data.name)
        setAudienceNotes(data == null ? "" : data.notes)
        setAudiencePixel(data == null ? "" : data.pixelId)
        setAudienceId(data == null ? "" : data.id)
        setAudienceTrafficInTheLast(data == null ? 90 : data.ttl)
        setAudienceTraffic(data == null ? "" : data.rules[0].type == "VISIT" ? "Anyone who visited your website" : "People who visited specific web pages")
        setAudienceRules(data == null ? [] : data.rules[0].type == "STARTS_WITH" ? [{type:"URL Contains", value:data.rules[0].value}] : [{type:"URL equals", value:data.rules[0].value}] )
    }

    const showCopyTag = (data) => {
        setConversionTrigger(data.conversionTriggerType)
        console.log(data.conversionTriggerType)
        setIsEditingForm(false)
        setIsCopyPixelWindowVisible(true);
        setPixelEventName(data.eventName);
        setPixelId(data.id)
    }

    const updatePixel = (pixelId) => {
        let data = {
            id:pixelId,
            notes:pixelNotes,
            name:pixelName,
        }
        props.updateConversionDefinition(data, user.accountId)
        setIsEditPixelWindowVisible(false);
        setIsEditAudienceWindowVisible(false);
        setIsEditingForm(false)
    }

    const createPixel = () => {
        let data = {
            name                    : pixelName,
            measurementServiceId    : user.accountId,
            accountId               : user.accountId,
            conversionType          : pixelConversionType,
            archived                : false,
            notes                   : pixelNotes,
            eventName               : setPixelEventName,
            isPureS2s               : "false",
            attributionModelType    : attributionModelType,
            attributionModelCount   : attributionModelCount,
            clickConversionWindow   : clickConversionWindow,
            viewConversionWindow    : viewConversionWindow,
            conversionTriggerType   : conversionTrigger,
            measurementMatchingRules: conversionTrigger == "RULE" ? [{
                targetType  : "URL",
                actionType  : measurementMatchingRules_actionType,
                value       : measurementMatchingRules_value
            }] : []
        }

        props.createConversionDefinition(data, user.accountId)
        setIsEditPixelWindowVisible(false);
        setIsEditAudienceWindowVisible(false);
        setIsEditingForm(false)
    }

    const createAudience = () => {
        let data = {
            pixelId: audiencePixel,
            name: audienceName,
            archived: false,
            ttl: audienceTrafficInTheLast,
            rules: audienceRules
        }
        props.createAudience(data, user.accountId)
        setIsEditPixelWindowVisible(false);
        setIsEditAudienceWindowVisible(false);
        setIsEditingForm(false)
    }

    const updateAudience = () => {
        // let pixelIds = pixels.items.map(pixel => {return pixel.id})
        let measurementServiceId = measurement.services[0].id;
        console.log(measurementServiceId)
        let data = {
            id:audienceId,
            measurementServiceId:measurementServiceId,
            name:audienceName,
            notes:audienceNotes,
        }

        return;
        props.updateAudience(data)
        setIsEditAudienceWindowVisible(false);
        setIsEditingForm(false)
    }

    const isAdgroupLock = (adgroup_id) => {
        if(adgroups.lock){
            //Checks if the adgroup is in the lock list (meaning it is processing)
            return adgroups.lock.find(item => item.id == adgroup_id)
        }
        return false;
    }


    ///////////////////////////////////////////
    // SUPERNOVA AI FUNCTIONS
    ///////////////////////////////////////////
    const aiRespondedWithError = () => {
        return ai?.response?.toString()?.includes("Unexpected token") || 
        ai?.response?.toString()?.includes("We've created an audience for you. ERROR") || 
        ai?.response?.toString()?.includes("undefined") || 
        ai?.response?.toString()?.includes("Unable to get valid response") ? true : false
    }
    const addToAIMessageLog = (newMessage, speaker) => {
        const messageElement = <>
            {
                speaker == "AI" ?
                <div className="ai-avatar"><img src="/public/images/logo.png" alt=""/></div>
                :
                <div className="user-avatar">{user.firstName.charAt(0)}</div>
            }
            {/* <div className={`chat-bubble ${speaker.toLowerCase()}`}>{newMessage}</div> */}
            <div className={`chat-bubble ${speaker.toLowerCase()}`} dangerouslySetInnerHTML={{ __html: newMessage }}/>
        </>;
        const updatedLog = [...aiMessageLog, messageElement];
        setAIMessageLog(updatedLog);
    }
    const printStringOneByOne = (str) => {
        if(str == undefined){
            return
        }
        let index = 0;

        //Temporary disabled setInterval to avoid high memory usage in rendering 
        setOutputMsg(str)
        // const intervalId = setInterval(() => {
        //     if (index < str.length) {
        //         setOutputMsg(`${str.slice(0,index+1)}`);
        //         index++;
        //     } else {
        //         clearInterval(intervalId);
        //     }
        // }, 0.2); 
    }

    useEffect(() => {
        if(ai.response && ai.response != ""){
            if(aiRespondedWithError()){
                // Error Handler
                addToAIMessageLog("Oops, something went wrong, our support team has logged the event. Please start over.", "AI")
            }
            else{
                setCurrentStep(ai?.response_type)
                // printStringOneByOne(ai?.response)
                addToAIMessageLog(ai?.response, "AI")
                setLastMessageFromAI(ai?.response)
            }
        }
    }, [ai?.response]);


    useEffect(() => {
        if(ai?.gatheredZip && ai.gatheredZip.length > 0){
            console.log("setting globe data")
            // console.log(listLongLat)
            // setGlobeData(
            //     ai.gatheredZip.map(zip => {
            //         const matchingEntry = listLongLat.find(entry => entry.zip.toString() === zip.toString());
            //         if (matchingEntry) {
            //             // return [entry.zip.toString(), Math.floor(Math.random() * (25000 - 20000 + 1)) + 20000, matchingEntry.lat, matchingEntry.lng];
            //             return [zip.toString(), 50000, matchingEntry.lat, matchingEntry.lng];
            //         } 
            //         //   else {
            //         //     return [0,0,0];
            //         //   }
            //     }).filter(element => element !== undefined)
            // )
            let zipCodes = ai.clusters.flatMap(zipGroup => 
                zipGroup.map(zip => zip[1])
              );
            setGlobeData(
                zipCodes.map(zip => {
                            const matchingEntry = listLongLat.find(entry => entry.zip.toString() === zip.toString());
                            if (matchingEntry) {
                                // return [entry.zip.toString(), Math.floor(Math.random() * (25000 - 20000 + 1)) + 20000, matchingEntry.lat, matchingEntry.lng];
                                return [zip.toString(), 50000, matchingEntry.lat, matchingEntry.lng];
                            } 
                            //   else {
                            //     return [0,0,0];
                            //   }
                        }).filter(element => element !== undefined)
            )
        }
    }, [ai?.gatheredZip]);
    
    useEffect(() => {
        if(ai.loading){
            setCurrentStep("LOADING")
            // printStringOneByOne("Processing...")
            if(ai.response_type == "AUDIENCE_DESCRIPTION"){
                setAudienceDescription(ai.response)
            }
        }
    }, [ai.loading]);

    useEffect(() => {
        if(ai?.totalReach){
            setTotalReach(Math.floor(ai?.totalReach))
        }
    }, [ai?.totalReach]);

    const askAI = () => {
        addToAIMessageLog(userMessage, "USER")
        props.askAI(
            userMessage, 
            ai.thread_id && ai.thread_id !== undefined && currentStep != "NEW_QUERY" ? ai.thread_id : null,
            user.accountId, 
            selectedCampaignId
        )
        setUserMessage("")
    }
    const createAdGroup = () => {
        let budgets                 = campaigns?.items.find(item => item.id == selectedCampaignId)?.budgets || 0
        let total_available_budget  = budgets?.reduce((total, obj) => total + parseInt(obj.available), 0) || 0;
        let last_budget_date        = budgets?.sort((a, b) => new Date(b.endDate) - new Date(a.endDate))[0]?.endDate;

        setUserMessage("")
        setCurrentStep("CREATE_ADGROUP")
        printStringOneByOne(`We are now setting up your adgroup ${userMessage == "" ? ai.adgroup_name :userMessage}... <br/><br/>You may close the window while it is running in the background.`)
        setTabKey("realtime")
        props.createSupernovaAdgroup({
            thread_id   : ai.thread_id,
            name        : ai.adgroup_name,
            query_id    : ai.query_id,
            supernovaCampaignId : campaigns?.items.find(item => item.id == selectedCampaignId)?.supernovaCampaignId,
        },
        user.accountId,
        selectedCampaignId,
        ai.clusters,
        {
            total_available_budget:total_available_budget,
            last_budget_date:last_budget_date,
            budget_type: getBudgetType()
        }
        )
    }

    const closeWindow = () => {
        setShowAIWindow(false)
        setUserMessage("")
        setCurrentStep("NEW_QUERY")
        setAdgroupName("Adgroup 1")
        setOutputMsg("")
    }

    const onClickProceed = () => {
        switch(currentStep){
            case "AUDIENCE_DESCRIPTION"    : 
                addToAIMessageLog(`What is the scope of your Geo Targeting in the US?
                </br></br>
                You can say the entire USA, states, cities, DMA's, towns, neighborhoods, zip codes, and radius.
                </br></br>
                Examples:  
                <br/>I want to include Major US cities on the East Coast.  
                <br/>I want to include Chelsea, Midtown, Bayside, and Jumbo in NYC.
                <br/>I want to include the surrounding zip codes within 10 mile radius of the following addresses.
                `, "AI")
                setCurrentStep("GEO_TARGETING")
                setUserMessage("")
                break;
            case "PROCESS_COMPLETE" : 
                createAdGroup();
                break;
        }
    }

    const submitTargetLocation = () => {
        props.askAIToProceedProcess(ai.thread_id, selectedCampaignId, userMessage); 
        addToAIMessageLog(userMessage,"USER")
        setUserMessage("")
    }

    const getBudgetType = () => {
        return adgroups?.items.some(adgroup => adgroup.state == "ACTIVE") ? "OPTIMIZED" : "EVEN_DISTRIBUTION";
    }

    const getMaxEndDate = (campaign_id) => {
        const budgets = campaigns?.items?.find(item => item.id == campaign_id)?.budgets || []
        const r = budgets?.reduce((latest, current) => {
            return new Date(current.endDate) > new Date(latest.endDate) ? current.endDate : latest.endDate;
        }) || "false"
        // console.log(r?.endDate)
        return r?.endDate
    }

    const startOverChat = () => {

        props.cancelAIRequest()
        addToAIMessageLog("Ok let's start over. Give me a description of what your product / service is, and the type of audience you want to reach.", "AI")
        setUserMessage("")
        setCurrentStep("NEW_QUERY")
        setAdgroupName("Adgroup 1")
        setOutputMsg("")
        setLastMessageFromAI("")
        setAudienceDescription("")
        setSelectedState([])
        setGlobeData([])
        setTotalReach(0)
        setListLongLat([])
    }

    const isAiButtonEnabled = () => {
        return !ai?.loading == true 
        && currentStep != "PROCESS_COMPLETE" && 
        currentStep != "CREATE_ADGROUP" && 
        currentStep != "LOADING" && userMessage != "" ? true : false
    }

    try{
        return (
            <div className="home-page">
    
                {/* DRAWER */}
                <SideDrawer 
                    campaignType={getCampaignType()} 
                    isAutoPilot={
                        selectedSupernovaCampaignId != null && 
                        campaigns && 
                        campaigns.items &&  
                        campaigns.items.filter(item => item.supernovaCampaignId == selectedSupernovaCampaignId).length > 0 &&
                        campaigns.items.filter(item => item.supernovaCampaignId == selectedSupernovaCampaignId)[0].autopilot
                    }
                    campaignId={selectedCampaignId}
                    supernovaCampaignId={selectedSupernovaCampaignId}
                    allAdgroups={allAdgroups}
                    />
                
                {/* OVERLAY */}
                <Overlay campaignType={getCampaignType()}/>
                
                {/* CreativeForm */}
    
                
                {/* TOP NAVIGATION */}
                {/* <TopNavigation user={user} page="home"/> */}
                
    
                {/* SUB MENU */}
                {/* <LeftNavigation /> */}
                <LeftNavigation role="client" />


                {/* CONVERSION DEFINITION FORM (FORMERLY PIXEL) */}
                {
                    isEditPixelWindowVisible && 
                    <div className="backdrop">
                        <div className="window pixels">
                            <div className="title">
                                {isEditingForm ? "Edit" : "Create"} Conversion Definition
                            </div>
                            <div className="body">
                                <section>
                                    <div className="section-title">General Info</div>
                                    <div className="form-group">
                                        <label>Name</label>
                                        <div className="content">
                                                <input type="text" className="form-control" value = {pixelName} name="name" onChange={event => setPixelName(event.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Notes</label>
                                        <div className="content">
                                            <textarea maxLength="250" type="text" className="form-control" value={pixelNotes} onChange={event => setPixelNotes(event.target.value)}>{pixelNotes}</textarea>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Conversion Type</label>
                                        <div className="content">
                                            {
                                                measurement && measurement.conversion_definitions && 
                                                <>
                                                    <Select
                                                        onChange={event => setPixelConversionType(() => handleDropdownChange(event))}
                                                        className={`select-primary pixe-page-dropdown mt-0 mb-0 ${isEditingForm ? "disabled" : ""}`}
                                                        options={listConversionType}
                                                        styles={styleSelectGoal} />
                                                </>
                                            }
                                        </div>
                                    </div>
                                </section>
                                <section className="conversion-triggers">
                                    <div className="section-title">Triggers</div>
                                    <div className="form-group">
                                        <label>Trigger Type</label>
                                        <div className="content">
                                            <Select
                                                onChange={event => setConversionTrigger((e) => handleDropdownChange(event))}
                                                className={`select-primary pixe-page-dropdown mt-0 mb-0 ${isEditingForm ? "disabled" : ""}`}
                                                defaultValue={
                                                    [
                                                        {value:'RULE',      label:'Rule'},
                                                        {value:'CODE',      label:'Code'},
                                                        {value:'S2S',       label:'Server to Server'}
                                                    ].filter(item => item.value == conversionTrigger)
                                                }
                                                options={[
                                                    {value:'RULE',      label:'Rule'},
                                                    {value:'CODE',      label:'Code'},
                                                    {value:'S2S',       label:'Server to Server'}
                                                ]}
                                                styles={styleSelectGoal} />
                                        </div>
                                    </div>
                                    {
                                        conversionTrigger == "RULE" &&
                                        <div className="form-group">
                                            <label>Action Type</label>
                                            <div className='content'>
                                                <div className={`radio side-by-side full-width ${isEditingForm ? "disabled" : ""}`} onChange={(e) => setMeasurementMatchingRules_actionType(e.target.value)}>
                                                    <label>
                                                        <input className={`${isEditingForm ? "disabled" : ""}`} type="radio" value={"CONTAINS"} name="measurementMatchingRules_actionType" defaultChecked={measurementMatchingRules_actionType == "CONTAINS"}/>
                                                        <span className="radio-description campaign-auto-pilot d-flex">Contains</span>
                                                    </label>
                                                    <label>
                                                        <input className={`${isEditingForm ? "disabled" : ""}`} type="radio" value={"STARTS_WITH"} name="measurementMatchingRules_actionType" defaultChecked={measurementMatchingRules_actionType == "STARTS_WITH"} />
                                                        <span className="radio-description campaign-auto-pilot d-flex">Starts with</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>    
                                    }
                                    {
                                        conversionTrigger == "RULE" &&
                                        <div className="form-group">
                                            <label>URL Keyword</label>
                                            <div className='content'>
                                                <input type="text" value={measurementMatchingRules_value} className={`form-control ${isEditingForm ? "disabled" : ""}`} disabled={isEditingForm} onChange={(e) => setMeasurementMatchingRules_value(e.target.value)} name="name" placeholder=''/>
                                            </div>
                                        </div>    
                                    }
                                    {
                                        (conversionTrigger == "CODE" || conversionTrigger == "S2S") &&
                                        <div className="form-group">
                                            <label>Event Name</label>
                                            <div className='content'>
                                                <input type="text" value={pixelEventName} className={`form-control`} disabled={isEditingForm} name="name" onChange={(e) => setPixelEventName(e.target.value)} placeholder=''/>
                                            </div>
                                        </div>    
                                    }
                                </section>
                                <section className='attribution-rules'>
                                    <div className="section-title">Attribution Rules</div>
                                    <div className="form-group">
                                        <label>Count</label>
                                        <div className="content">
                                            <div className={`radio attributionModelCount full-width ${isEditingForm ? "disabled" : ""}`} onChange={(e) => setAttributionModelCount(e.target.value)}>
                                                <label>
                                                    <input className={`${isEditingForm ? "disabled" : ""}`} type="radio" value={"EVERY"} name="attributionModelCount" defaultChecked={attributionModelCount == "EVERY"}/>
                                                    <span className="radio-description campaign-auto-pilot d-flex"><b>All</b> All conversion will be counted. Use it for purchases and other similar events where each action brings value.</span>
                                                </label>
                                                <label>
                                                    <input className={`${isEditingForm ? "disabled" : ""}`} type="radio" value={"ONE"} name="attributionModelCount" defaultChecked={attributionModelCount == "ONE"} />
                                                    <span className="radio-description campaign-auto-pilot d-flex"><b>One</b> Only the first conversion will be counted. Use it for sign up or leads where only the first user interaction brings value.</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Attribution model</label>
                                        <div className="content">
                                            {
                                                measurement && measurement.conversion_definitions && 
                                                <div className={`radio side-by-side full-width ${isEditingForm ? "disabled" : ""}`} onChange={(e) => setAttributionModelType(e.target.value)}>
                                                    <label>
                                                        <input className={`${isEditingForm ? "disabled" : ""}`} type="radio" value={"FIRST"} name="attributionModelType" defaultChecked={attributionModelType == "FIRST"}/>
                                                        <span className="radio-description campaign-auto-pilot d-flex">First click</span>
                                                    </label>
                                                    <label>
                                                        <input className={`${isEditingForm ? "disabled" : ""}`} type="radio" value={"LAST"} name="attributionModelType" defaultChecked={attributionModelType == "LAST"} />
                                                        <span className="radio-description campaign-auto-pilot d-flex">Last click</span>
                                                    </label>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Click-through</label>
                                        <div className="content">
                                            {
                                                measurement && measurement.conversion_definitions && 
                                                <>
                                                    <Select
                                                        onChange={event => setClickConversionWindow((e) => handleDropdownChange(event))}
                                                        className={`select-primary pixe-page-dropdown mt-0 mb-0 ${isEditingForm ? "disabled" : ""}`}
                                                        defaultValue={[{value:'1',   label:'1 day'}]}
                                                        menuPlacement="top"
                                                        options={[
                                                            {value:'1',   label:'1 day'},
                                                            {value:'2',   label:'2 day'},
                                                            {value:'7',   label:'7 day'},
                                                            {value:'28',   label:'28 day'},
                                                            {value:'30',   label:'30 day'},
                                                        ]}
                                                        styles={styleSelectGoal} />
                                                </>
                                            }
                                        </div>
                                    </div>
                                </section>
                                <section className='pb-0'>
                                    <div className="section-title">Supernova Tag</div>
                                    <div className="form-group">
                                    <section>
                                <div className="form-group">
                                        <div className="form-control readonly code-snippet">
                                            {
                                                    conversionTrigger == "S2S"
                                                    ?<Highlight className='html'>{`https://s2s.supernova.inc/${user.accountId}/${pixelEventName}/?postbackid={postbackid}`}</Highlight>
                                                    :
                                                    <Highlight className='html'>
                                                        {
                                                            `<!-- Supernova Pixel -->
<script type="text/javascript" src="https://pixel.supernova.inc/tag.js">
    ${
        conversionTrigger == "CODE" 
        ? `supernova('track', '${user.accountId}', '${pixelEventName}');`
        : `supernova('track', '${user.accountId}');`
    }
</script>
<noscript>
    <img src="//pixel.supernova.inc/${user.accountId}/${pixelEventName}" referrerpolicy="no-referrer-when-downgrade" height="1" width="1" border="0" alt="" />
</noscript>`
                                                        }
                                                    </Highlight>
                                                }
                                        </div>
                                </div>
                                    </section>
                                    </div>
                                </section>
                            </div>
                            <div className="footer">
                                {
                                    isEditingForm 
                                    ? <button className="btn btn-xs btn-primary" type="button" onClick={() => updatePixel(pixelId)}><i className="fa fa-check" aria-hidden="true"></i> Save</button>
                                    : <button className="btn btn-xs btn-primary" type="button" onClick={() => createPixel()}><i className="fa fa-check" aria-hidden="true"></i> Create</button>
                                        
                                }
                                <button className="btn btn-xs btn-secondary" onClick={() => setIsEditPixelWindowVisible(false)}>Cancel</button>
                            </div>
                        </div>
                    </div>
                }


                {/* AUDIENCE FORM */}
                {
                    isEditAudienceWindowVisible && 
                    <div className="backdrop">
                        <div className="window pixels">
                            <div className="title">
                                {isEditingForm ? "Edit" : "Create"} Custom Audience
                            </div>
                            <div className="body">
                                <section>
                                    <div className="form-group">
                                        <label>Audience Name</label>
                                        <div className="content">
                                            <input type="text" className="form-control" value = {audienceName} name="audienceName" onChange={event => setAudienceName(event.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Notes</label>
                                        <div className="content">
                                            <textarea maxLength="250" type="text" className="form-control" value={audienceNotes} onChange={event => setAudienceNotes(event.target.value)}>{audienceNotes}</textarea>
                                        </div>
                                    </div>
                                    <div className="form-group d-none">
                                        <label>Pixel</label>
                                        <div className="content">
                                            {
                                                isEditingForm 
                                                ?
                                                    <input type="text" className="form-control" disabled value = {audiencePixel} name="audiencePixel" onChange={event => setAudiencePixel(event.target.value)}/>
                                                : 
                                                    <Select
                                                    onChange={event => setAudiencePixel(() => handleDropdownChange(event))}
                                                    className={`select-primary ${isEditingForm ? "disabled" : ""}`}
                                                    placeholder="Select"
                                                    options={listPixels}
                                                    styles={styleSelectBasic} />

                                            }
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Website traffic</label>
                                        <div className="content">
                                            {
                                                isEditingForm
                                                ?
                                                    <div>
                                                        <input type="text" className="form-control" disabled value={audienceTraffic} name="audienceTraffic"/>
                                                        {
                                                            audienceRules.length > 0 && (audienceRules[0].type == "" || audienceRules[0].type == "URL Contains" || audienceRules[0].type == "URL equals") &&
                                                            <>
                                                                <div className="audience-rules-subtext">Include traffic that meets the following conditions:</div>
                                                                <input type="text" className="form-control" disabled value={audienceRules[0].type}/>
                                                                <input type="text" className="form-control" disabled value={audienceRules[0].value}/>
                                                            </>
                                                        }
                                                    </div>
                                                :
                                                    <div>
                                                        <Select
                                                        onChange={event => setAudienceRules([{type:handleDropdownChange(event), value:""}])}
                                                        className={`select-primary ${isEditingForm ? "disabled" : ""}`}
                                                        placeholder="Select"
                                                        options={
                                                            [
                                                                {label:"Anyone who visited your website", value:"VISIT"},
                                                                {label:"People who visited specific web pages", value:""}
                                                            ]
                                                        }
                                                        styles={styleSelectBasic} />
                                                        {
                                                            audienceRules.length > 0 && (audienceRules[0].type == "" || audienceRules[0].type == "STARTS_WITH" || audienceRules[0].type == "CONTAINS") &&
                                                            <>
                                                                <div className="audience-rules-subtext">Include traffic that meets the following conditions:</div>
                                                                <Select
                                                                onChange={event => setAudienceRules([{type:handleDropdownChange(event), value:""}])}
                                                                className={`select-primary${isEditingForm ? "disabled" : ""}`}
                                                                placeholder="Select"
                                                                options={
                                                                    [
                                                                        {label:"URL Contains", value:"STARTS_WITH"},
                                                                        {label:"URL equals", value:"CONTAINS"}
                                                                    ]
                                                                }
                                                                styles={styleSelectBasic} />
                                                                {
                                                                    audienceRules.length > 0 && (audienceRules[0].type == "STARTS_WITH" || audienceRules[0].type == "CONTAINS")  &&
                                                                        <input type="text" className="form-control" value={audienceRules[0].value}  onChange={event => setAudienceRules([{type:audienceRules[0].type, value:event.target.value}])}/>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>In the last</label>
                                        <div className="content">
                                            {
                                                isEditingForm 
                                                ?
                                                    <input type="text" className="form-control" disabled value={`${audienceTrafficInTheLast} days`} name="audienceTrafficInTheLast" onChange={event => setAudienceTrafficInTheLast(event.target.value)}/>
                                                :
                                                    <div className="ttl">
                                                        <input type="text" className="form-control" value={audienceTrafficInTheLast} name="audienceTrafficInTheLast" onChange={event => setAudienceTrafficInTheLast(event.target.value)}/> <span>days</span>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </section>
                                <section className='pb-0'>
                                    <div className="section-title">Supernova Tag</div>
                                    <div className="form-group">
                                    <section>
                                <div className="form-group">
                                        <div className="form-control readonly code-snippet">
                                            {
                                                    conversionTrigger == "S2S"
                                                    ?<Highlight className='html'>{`https://s2s.supernova.inc/${user.accountId}/${pixelEventName}/?postbackid={postbackid}`}</Highlight>
                                                    :
                                                    <Highlight className='html'>
                                                        {
                                                            `<!-- Supernova Pixel -->
<script type="text/javascript" src="https://pixel.supernova.inc/tag.js">
    ${
        conversionTrigger == "CODE" 
        ? `supernova('track', '${user.accountId}', '${pixelEventName}');`
        : `supernova('track', '${user.accountId}');`
    }
</script>
<noscript>
    <img src="//pixel.supernova.inc/${user.accountId}/${pixelEventName}" referrerpolicy="no-referrer-when-downgrade" height="1" width="1" border="0" alt="" />
</noscript>`
                                                        }
                                                    </Highlight>
                                                }
                                        </div>
                                </div>
                                    </section>
                                    </div>
                                </section>
                            </div>
                            <div className="footer">
                                {
                                    isEditingForm 
                                    ? <button className="btn btn-xs btn-primary" type="submit" onClick={() => updateAudience()}><i className="fa fa-check" aria-hidden="true"></i> Save</button>
                                    : <button className="btn btn-xs btn-primary" type="submit" onClick={() => createAudience()}><i className="fa fa-check" aria-hidden="true"></i> Create</button>
                                }
                                <button className="btn btn-xs btn-secondary" onClick={() => setIsEditAudienceWindowVisible(false)}>Cancel</button>
                            </div>
                        </div>
                    </div>
                }


                {
                    isCampaignOptionVisible &&
                    <div className="backdrop campaign-option">
                        <div className="window">
                            <div className="title">
                                Choose Campaign Type
                            </div>
                            <div className="body">
                                <div className='option-menu' onClick={() => {createCampaign("campaign", "create"); setSelectedNewCampaignType("NATIVE"); setIsCampaignOptionVisible(false);}}><i className="fa fa-industry" aria-hidden="true"></i> <span>Native</span></div>
                                <div className='option-menu' onClick={() => {createCampaign("campaign", "create"); setSelectedNewCampaignType("DISPLAY"); setIsCampaignOptionVisible(false);}}><i className="fa fa-picture-o" aria-hidden="true"></i> <span>Display</span></div>
                                <div className='option-menu' onClick={() => {createCampaign("campaign", "create"); setSelectedNewCampaignType("VIDEO"); setIsCampaignOptionVisible(false);}}><i className="fa fa-video-camera" aria-hidden="true"></i> <span>Video</span></div>
                                {/* <div className='option-menu' onClick={() => {createCampaign("campaign", "create"); setSelectedNewCampaignType("PUSH_NOTIFICATION"); setIsCampaignOptionVisible(false);}}><i className="fa fa-mobile" aria-hidden="true"></i> <span>Push Notification</span></div> */}
                                <div className='option-menu' onClick={() => {setIsPushAdNewCampaignFormVisible(true); setIsCampaignOptionVisible(false)}}><i className="fa fa-mobile" aria-hidden="true"></i> <span>Push Notification</span></div>
                            </div>
                            <div className="footer">
                                <button className="btn btn-xs btn-secondary" onClick={() => {setIsCampaignOptionVisible(false);}}>Cancel</button>
                            </div>
                        </div>
                    </div>
                }

                {
                    (isPushAdNewCampaignFormVisible || isPushAdEditCampaignFormVisible) &&
                    <div className="backdrop pushAddNewCampaignForm">
                        <div className="window">
                            <form name="form" onSubmit={isPushAdNewCampaignFormVisible ? handleSubmitNewCampaign : handleSubmitEditCampaign}>
                                <div className="title">
                                    {
                                        isPushAdNewCampaignFormVisible ? "Create new Push Ad Campaign" : "Edit Campaign Name"
                                    }
                                </div>
                                <div className="body">
                                    <input type="text" value={newPushAdCampaignName} placeholder="Enter Campaign Name" onChange={(e) => setNewPushAdCampaignName(e.target.value)}/>
                                </div>
                                <div className="footer">
                                    <button className="btn btn-xs btn-primary" type="submit">{isPushAdNewCampaignFormVisible ? "Create" : "Save"}</button>
                                    <button className="btn btn-xs btn-secondary ml-2" type="button" onClick={() => {
                                        setIsPushAdNewCampaignFormVisible(false); 
                                        setIsPushAdEditCampaignFormVisible(false);
                                    }}>Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                }
                
                <div className="dashboard">
    
                    {/* DATERANGE PICKER */}
                    <div onClick={() => setIsDateRangeActive(true)} className={`daterange-wrapper ${isDateRangeActive ? "active" : "inactive"}`} >
                        <span className="label">Date range </span>
    
                        <DateRangePicker
                            editableDateInputs={true}
                            onChange={item => {setState([item.selection]);}}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            maxDate={new Date()}
                            ranges={[{
                                startDate: new Date(state[0].startDate),
                                endDate: new Date(state[0].endDate),
                                key: 'selection'
                            }]}
                            rangeColors={["#01575c"]}
                            direction="vertical"
                            // scroll={{ enabled: true }}
                        />
    
                        <button type="button" className="btn btn-primary btn-xs" onClick={(e) => {updateDateRange(); e.stopPropagation();}}>
                            <i className="fa fa-check"></i> Apply
                        </button>
                        <button type="button" className="btn btn-secondary btn-xs" onClick={(e) => {
                            setState([
                                {
                                    startDate: new Date(startDate),
                                    endDate: new Date(endDate),
                                    key: 'selection'
                                }
                            ])
                            setIsDateRangeActive(false); 
                            e.stopPropagation();
                            }
                        }>
                            Cancel
                        </button>
                    </div> 
    
                    
                    {/* DETAIL COLUMN */}
                    <div className={`details-column ${tabKey == "ai" && selectedCampaignId!= null ? "expanded" : "" }`}>
                    
                    {/* TABS */}
                    <div className="tab-section">
                    {
                        selectedAdgroup != null &&
                        <div className="controls">
                        <OverlayTrigger placement="top" content="" overlay={<Tooltip>New Creative</Tooltip>}>
                        <button className={`add-entity creatives`} onClick={() => createCreative("creative", "create", selectedAdgroup)}><i className="fa fa-plus" aria-hidden="true"></i></button>
                        </OverlayTrigger>
                        </div>
                    }
                    <Tabs id="controlled-tab-example" activeKey={tabKey} onSelect={(k) => setTabKey(k)} className="mb-3">
                        {/* ADS TAB */}
                        <Tab eventKey="creatives" title={selectedAdgroup != null ? <>Ads  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</> : "Ads"} disabled={selectedEntityKey != "supernovaAdgroupId"}>
                                    
                                    {/* CODE BLOCK FOR ENTITY-COLUMN IS ARCHIVED CHECK NOTES IN ENPASS TO RESTORE */}
                                    
                                    {/* ADD CREATIVE BUTTON */}
                                    

                                    {
                                        selectedEntityKey == "supernovaAdgroupId" &&
                                        <div className="fixed-table-wrapper">
                                            <table id="primary-report-table" className={`primary ${getCampaignType()}`}>  
                                                <thead>
                                                    <tr>
                                                        <th className={`fixed id ${tableSortBy == "id" ? "active" : ""}`} onClick={() => {setTableSortBy("id"); tableSortBy == "id" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            Ad ID {tableSortBy == "id" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}
                                                        </th>
                                                        <th className={`fixed status`}>
                                                            Status
                                                        </th>
                                                        <th className={`fixed creative-title ${tableSortBy == "title" && "active"}`} onClick={() => {setTableSortBy("title"); tableSortBy == "title" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            Ad Title {tableSortBy == "title" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}
                                                        </th>
                                                        <th className={`creative-size  ${getCampaignType() == "PUSH_NOTIFICATION" ? "d-none" : ""} ${tableSortBy == "size" && "active"}`} onClick={() => {setTableSortBy("size"); tableSortBy == "size" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            <span className="label">Size {tableSortBy == "size" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge hidden"></span>
                                                        </th>
                                                        <th className={`stats  ${getCampaignType() == "PUSH_NOTIFICATION" ? "d-none" : ""} ${tableSortBy == "type" && "active"}`} onClick={() => {setTableSortBy("type"); tableSortBy == "type" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            <span className="label">Type {tableSortBy == "type" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge hidden"></span>
                                                        </th>
                                                        <th className={`stats text-right ${tableSortBy == "impressions" && "active"}`} onClick={() => {setTableSortBy("impressions"); tableSortBy == "impressions" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            <span className="label">Impression {tableSortBy == "impressions" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge">{tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0).toLocaleString()}</span>
                                                        </th>
                                                        <th className={`stats text-right ${tableSortBy == "clicks" && "active"}`} onClick={() => {setTableSortBy("clicks"); tableSortBy == "clicks" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            <span className="label">Clicks {tableSortBy == "clicks" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge">{tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0).toLocaleString()}</span>
                                                        </th>
                                                        <th className={`stats text-right ${tableSortBy == "ctr" && "active"}`} onClick={() => {setTableSortBy("ctr"); tableSortBy == "ctr" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            <span className="label">CTR {tableSortBy == "ctr" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge">
                                                            {
                                                                tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0) > 0 
                                                                ? `${parseFloat(tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0) /  tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0) * 100).toLocaleString(undefined, {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                })}%`
                                                                : "0.00%"
                                                            }
                                                            </span>
                                                        </th>
                                                        <th className={`${isColumnMediaSpendVisible ? "" : "d-none"} stats text-right ${tableSortBy == "media_spend" && "active"}`} onClick={() => {setTableSortBy("media_spend"); tableSortBy == "media_spend" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            <span className="label">Media Spend {tableSortBy == "media_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge">${
                                                                tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.media_spend) ? "0.00" : accumulator + object.media_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                            }</span>
                                                        </th>   
                                                        <th className={`${isColumnDataSpendVisible ? "" : "d-none"} data-spend stats text-right ${tableSortBy == "data_spend" && "active"}`} onClick={() => {setTableSortBy("data_spend"); tableSortBy == "data_spend" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            <span className="label">Data Spend {tableSortBy == "data_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge">${
                                                                tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.data_spend) ? "0.00" : accumulator + object.data_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                            }</span>
                                                        </th>   
                                                        <th className={`stats text-right ${tableSortBy == "total_spend" && "active"}`} onClick={() => {setTableSortBy("total_spend"); tableSortBy == "total_spend" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            <span className="label">Total Spend {tableSortBy == "total_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge">${
                                                                    tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.total_spend) ? "0.00" : accumulator + object.total_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                }
                                                            </span>
                                                        </th>   
                                                        <th className={`d-none stats text-right ${tableSortBy == "yesterday_spend" && "active"}`} onClick={() => {setTableSortBy("yesterday_spend"); tableSortBy == "yesterday_spend" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            <span className="label">Yesterday Spend {tableSortBy == "yesterday_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge">${
                                                                tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.yesterday_spend) ? "0.00" : accumulator + object.yesterday_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                            }</span>
                                                        </th>   
                                                        <th className={`${isColumnMediaECPCVisible ? "" : "d-none"} stats text-right ${tableSortBy == "media_ecpc" && "active"}`} onClick={() => {setTableSortBy("media_ecpc"); tableSortBy == "media_ecpc" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            <span className="label">Media eCPC {tableSortBy == "media_ecpc" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge">${
                                                                tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.media_ecpc) ? "0.00" : accumulator + object.media_ecpc}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                            }</span>
                                                        </th>
                                                        <th className={`${isColumnMediaECPMVisible ? "" : "d-none"} stats text-right ${tableSortBy == "media_ecpm" && "active"}`} onClick={() => {setTableSortBy("media_ecpm"); tableSortBy == "media_ecpm" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            <span className="label">Media eCPM {tableSortBy == "media_ecpm" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge">${
                                                                tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.media_ecpm) ? "0.00" : accumulator + object.media_ecpm}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                            }</span>
                                                        </th>
                                                        <th className={`stats text-right ${tableSortBy == "total_ecpc" && "active"}`} onClick={() => {setTableSortBy("total_ecpc"); tableSortBy == "total_ecpc" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            <span className="label">Total eCPC {tableSortBy == "total_ecpc" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge">${
                                                                //TOTAL_ECPC = TOTAL_SPEND /  TOTAL_CLICKS
                                                                tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0) == 0
                                                                ? "0.00"
                                                                : parseFloat(
                                                                    tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.total_spend) ? 0 : accumulator + object.total_spend}, 0) /
                                                                    tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0).toLocaleString()
                                                                    ).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                            }</span>
                                                        </th>
                                                        <th className={`stats text-right ${tableSortBy == "total_ecpm" && "active"}`} onClick={() => {setTableSortBy("total_ecpm"); tableSortBy == "total_ecpm" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            <span className="label">Total eCPM {tableSortBy == "total_ecpm" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge">${
                                                                //TOTAL_ECPM = ( TOTAL_SPEND x 1000 ) /  TOTAL_IMPRESSIONS
                                                                tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0) == 0
                                                                ? "0.00"
                                                                : parseFloat(
                                                                    (tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.total_spend) ? 0 : accumulator + object.total_spend}, 0) * 1000) /
                                                                    tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0)
                                                                    ).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                            }</span>
                                                        </th>
                                                        <th className={`d-none stats text-right ${tableSortBy == "daily_budget" && "active"}`} onClick={() => {setTableSortBy("daily_budget"); tableSortBy == "daily_budget" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            <span className="label">Daily Budget {tableSortBy == "daily_budget" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge">${
                                                                tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.daily_budget) ? "0.00" : accumulator + object.daily_budget}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                            }</span>
                                                        </th>   
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        ads && ads.items ?
                                                        tableArray("entity").sortBy(tableSortBy, isTableSortDescending).slice(paginationOffset, paginationOffset + tableRowsPerPage).map((item, index) =>
                                                            <tr className={`${focusedCreativeId == item.id ? "selected" : ""} clickable ${item.archived ? 'd-none' : ''}`} onMouseEnter={() => setFocusedCreativeId(item.id)} key={index}>
                                                                <td onClick={() => editCreative("creative", "edit", selectedAdgroup, item.id)} className={`fixed id`}><span>{item.id}</span></td>
                                                                {/* <td className="fixed status">{ads && ads.items && ads.items.find(ad => ad.creative.id == item.id)?.state}</td> */}
                                                                <td className={`fixed status`}>
                                                                    {/* {ads && ads.items && isAdActive(item.id) ? "" : "inactive"} */}
                                                                    <Toggle className={`${ads?.loading == true ? "disabled" : ""}`} data-cid={item.id} defaultChecked={ads && ads.items && isAdActive(item.id) ? true : false} onChange={(e) => toggleCreativeState(e)}/> 
                                                                </td>
                                                                <td onClick={() => editCreative("creative", "edit", selectedAdgroup, item.id)} className={`fixed creative-title`}><span className='blue-link plain'>{item.title}</span></td>
                                                                <td className={`creative-size ${getCampaignType() == "PUSH_NOTIFICATION" ? "d-none" : ""}  ${ads && ads.items && isAdActive(item.id) ? "" : "disabled"}`}><span className='blue-link plain'>{item.size}</span></td>
                                                                <td className={`creative-type text-right  ${getCampaignType() == "PUSH_NOTIFICATION" ? "d-none" : ""} ${ads && ads.items && isAdActive(item.id) ? "" : "disabled"}`}><span>{item.type && item.type.replace(/_/g, ' ').toLowerCase()}</span></td>
                                                                <td className={`stats text-right ${ads && ads.items && isAdActive(item.id) ? "" : "disabled"}`}><span>{item.impressions.toLocaleString()}</span></td>
                                                                <td className={`stats text-right ${ads && ads.items && isAdActive(item.id) ? "" : "disabled"}`}><span>{item.clicks.toLocaleString()}</span></td>
                                                                <td className={`stats text-right ${ads && ads.items && isAdActive(item.id) ? "" : "disabled"}`}><span>{`${(item.clicks == 0 ? 0 : (item.clicks / item.impressions) * 100).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}%`}</span></td>
                                                                <td className={`${isColumnMediaSpendVisible ? "" : "d-none"} stats text-right  ${ads && ads.items && isAdActive(item.id) ? "" : "disabled"}`}><span>{`$${item.media_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}</span></td>
                                                                <td className={`${isColumnDataSpendVisible ? "" : "d-none"} data-spend stats text-right  ${ads && ads.items && isAdActive(item.id) ? "" : "disabled"}`}><span>{`$${item.data_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}</span></td>
                                                                <td className={`stats text-right ${ads && ads.items && isAdActive(item.id) ? "" : "disabled"}`}><span>{`$${item.total_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}</span></td>
                                                                <td className={`d-none stats text-right ${ads && ads.items && isAdActive(item.id) ? "" : "disabled"}`}><span>{`$${item.yesterday_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}%`}</span></td>
                                                                <td className={`${isColumnMediaECPCVisible ? "" : "d-none"} stats text-right ${ads && ads.items && isAdActive(item.id) ? "" : "disabled"}`}><span>{`$${item.media_ecpc.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}</span></td>
                                                                <td className={`${isColumnMediaECPMVisible ? "" : "d-none"} stats text-right ${ads && ads.items && isAdActive(item.id) ? "" : "disabled"}`}><span>{`$${item.media_ecpm.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}</span></td>
                                                                <td className={`stats text-right ${ads && ads.items && isAdActive(item.id) ? "" : "disabled"}`}><span>{`$${
                                                                        //ECPC = spend / clicks
                                                                        isNaN((item.total_spend / item.clicks).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                        ? "0.00"
                                                                        : (item.total_spend / item.clicks).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }`}</span></td>
                                                                <td className={`stats text-right ${ads && ads.items && isAdActive(item.id) ? "" : "disabled"}`}><span>{`$${
                                                                        //ECPM = ( spend x 1,000 ) / impressions
                                                                        isNaN(((item.total_spend * 1000) / item.impressions).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                        ? "0.00"
                                                                        : ((item.total_spend * 1000) / item.impressions).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }`}</span></td>

                                                                {/* COMMENTED OUT BECAUSE THERE IS AN ERROR IN CONSOLE */}
                                                                {/* <td className="d-none stats text-right"><span>{`$${item.daily_budget.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}</span></td> */}
                                                            </tr>
                                                    )
                                                    :
                                                    <>Loading...</>
                                                }
                                                {
                                                    ////////////////////////
                                                    // PENDING CREATIVES
                                                    ////////////////////////
                                                    (selectedEntityKey =="supernovaAdgroupId" || selectedEntityKey =="supernovaCreativeId") && (creatives.isDone == true || creativesPending.isDone == true) &&
                                                    // creativesPending.state != "INACTIVE" &&
                                                    (selectedEntityKey =="supernovaAdgroupId" || selectedEntityKey =="supernovaCreativeId") && creativesPending.items.length > 0 && 
                                                    // creativesPending.items.filter(item => item.supernovaAdgroupId == selectedAdgroup && item.status == "pending" && item.state != "INACTIVE").sortBy("id", true).map((creative, index) =>
                                                    creativesPending.items.filter(item => item.adGroupId == selectedAdgroup && item.status == "pending").sortBy("id", true).map((creative, index) =>
                                                        <tr className="pending" key={`pending-${index}`}>
                                                            <td className='fixed'><span className="badge empty ">Pending</span></td>
                                                            <td className={`fixed status`}><Toggle className={`disabled`}/> </td>
                                                            <td className='fixed'>{creative.title}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            {/* <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td> */}
                                                        </tr>
                                                        )
                                                }
                                                {
                                                    ////////////////////////
                                                    // APPROVED CREATIVES (do not show since the page will refresh anyway)
                                                    ////////////////////////
                                                    // (selectedEntityKey =="supernovaAdgroupId" || selectedEntityKey =="supernovaCreativeId") && 
                                                    // (creatives.isDone == true || creativesPending.isDone == true) &&
                                                    // creativesPending.items.length > 0 && 
                                                    // // creativesPending.items.filter(item => item.supernovaAdgroupId == selectedAdgroup && item.status == "approved" && item.state != "INACTIVE").sortBy("id", true).map((creative, index) =>
                                                    // // creativesPending.items.filter(item => item.supernovaAdgroupId == selectedAdgroup && item.status == "approved").sortBy("id", true).map((creative, index) =>
                                                    // creativesPending.items
                                                    // .filter(item => item.adGroupId == selectedAdgroup && item.status == "approved")
                                                    // .sortBy("id", true)
                                                    // .map((creative, index) =>
                                                    //     <tr key={`approved-${index}`} className={`${creative.archived ? 'd-none' : ''} ${focusedCreativeId == creative.id ? "hovered" : ""}`}>
                                                    //         <td onClick={() => editCreative("creative", "edit", selectedAdgroup, creative.id)} className={`fixed id`}><span>{creative.id}</span></td>
                                                    //         <td className={`fixed status`}>
                                                    //             <Toggle className={`${ads?.loading == true ? "disabled" : ""}`} data-cid={creative.id} defaultChecked={ads && ads.items && isAdActive(creative.id) ? true : false} onChange={(e) => toggleCreativeState(e)}/> 
                                                    //         </td>
                                                    //         <td onClick={() => editCreative("creative", "edit", selectedAdgroup, creative.id)} className={`fixed creative-title`}><span className='blue-link plain'>{creative.title}</span></td>
                                                    //         <td></td>
                                                    //         <td></td>
                                                    //         <td></td>
                                                    //         <td></td>
                                                    //         <td></td>
                                                    //         <td></td>
                                                    //         <td></td>
                                                    //         <td></td>
                                                    //         {/* <td></td>
                                                    //         <td></td>
                                                    //         <td></td>
                                                    //         <td></td>
                                                    //         <td></td> */}
                                                    //     </tr>
                                                    //     )
                                                }
                                                {
                                                    ////////////////////////
                                                    // REJECTED CREATIVES
                                                    ////////////////////////
                                                    (selectedEntityKey =="supernovaAdgroupId" || selectedEntityKey =="supernovaCreativeId") && (creatives.isDone == true || creativesPending.isDone == true) &&
                                                    (selectedEntityKey =="supernovaAdgroupId" || selectedEntityKey =="supernovaCreativeId") && 
                                                    creativesPending.items.length > 0 && creativesPending.items.filter(item => item.supernovaAdgroupId == selectedAdgroup && item.status == "FAILED" && item.state != "INACTIVE").sortBy("id", true).map((creative, index) =>
                                                        <tr key={`rejected-${index}`} className={`rejected ${creative.archived ? 'd-none' : ''}`}>
                                                            <td onClick={() => editCreative("creative", "edit", selectedAdgroup, creative.id)} className={`fixed id`}><span>{creative.id}</span></td>
                                                            <td className={`fixed status`}><Toggle className={`disabled`}/> </td>
                                                            <td onClick={() => editCreative("creative", "edit", selectedAdgroup, creative.id)} className={`fixed creative-title`}><span className='blue-link plain'>{creative.title}</span></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            {/* <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td> */}
                                                        </tr>
                                                        )
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    }


                                </Tab>


                    {/* REPORTS TAB */}
                        <Tab eventKey="reports" title="Reports">
                        

                    <div className="chart-area">
                        <LifetimeValue 
                            accountId={user.accountId}
                            entityKey={selectedEntityKey} 
                            entityId={selectedEntityId} 
                            startDate={startDate.format('YYYY-MM-DD')} 
                            endDate={endDate.format('YYYY-MM-DD')} 
                            groupBy={groupBy}
                            campaignType={getCampaignType()}
                            />
                    </div>
                        <CSVLink filename={"campaigns.csv"} data={csvData} className="btn btn-primary btn-xs btn-export ml-1"><i className="fa fa-file-excel-o"></i> Export CSV</CSVLink>
                        <div className='table-menus' onClick={(e) => setIsShowColumnFormVisible(true)}>
                            <i className="fa fa-columns" aria-hidden="true"></i>
                            <span>Show Columns</span>
                        </div>
                        {
                            isShowColumnFormVisible &&
                            <div className='available-colum-form'>
                                <div className="outside" onClick={() => setIsShowColumnFormVisible(false)}></div>
                                <div className="inside">
                                    <div>
                                        <input type="checkbox" disabled defaultChecked={true} /> <label>Impressions</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" disabled defaultChecked={true} /> <label>Clicks</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" disabled defaultChecked={true} /> <label>CTR</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" disabled defaultChecked={true} /> <label>Total Spend</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" disabled defaultChecked={true} /> <label>Total eCPC</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" disabled defaultChecked={true} /> <label>Total eCPM</label>
                                    </div>
                                    {
                                        getCampaignType() != "PUSH_NOTIFICATION" &&
                                        <div>
                                            <input type="checkbox" onChange={() => {Cookies.set('isColumnDataSpendVisible',!isColumnDataSpendVisible); setIsColumnDataSpendVisible(!isColumnDataSpendVisible)}} id="isColumnDataSpendVisible" name={isColumnDataSpendVisible} defaultChecked={isColumnDataSpendVisible} /> <label for="isColumnDataSpendVisible">Data Spend</label>
                                        </div>
                                    }
                                    <div>
                                        <input type="checkbox" onChange={() => {Cookies.set('isColumnMediaSpendVisible',!isColumnMediaSpendVisible); setIsColumnMediaSpendVisible(!isColumnMediaSpendVisible)}} id="isColumnMediaSpendVisible" name={isColumnMediaSpendVisible} defaultChecked={isColumnMediaSpendVisible} /> <label for="isColumnMediaSpendVisible">Media Spend</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" onChange={() => {Cookies.set('isColumnMediaECPCVisible',!isColumnMediaECPCVisible); setIsColumnMediaECPCVisible(!isColumnMediaECPCVisible)}} id="isColumnMediaECPCVisible" name={isColumnMediaECPCVisible} defaultChecked={isColumnMediaECPCVisible} /> <label for="isColumnMediaECPCVisible">Media ECPC</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" onChange={() => {Cookies.set('isColumnMediaECPMVisible',!isColumnMediaECPMVisible); setIsColumnMediaECPMVisible(!isColumnMediaECPMVisible)}} id="isColumnMediaECPMVisible" name={isColumnMediaECPMVisible} defaultChecked={isColumnMediaECPMVisible} /> <label for="isColumnMediaECPMVisible">Media ECPM</label>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="fixed-table-wrapper">
                            {
                                //DETAIL COLUMN'S TITLE
                                // Hidden last Aug 1, 2023 when we moved down the dimension inside the tab.
                                // selectedEntityKey == null ?
                                //     <div className="title">Statistics</div>
                                // : selectedEntityKey == "supernovaCampaignId" ?
                                //     <div className="title">Statistics: <span>{getCampaignNameByID(selectedSupernovaCampaignId)}</span></div>
                                // : selectedEntityKey == "supernovaAdgroupId" ?
                                //     <div className="title">Statistics: <span>{getAdgroupNameBydId(selectedAdgroup)}</span></div>
                                // : <></>
                            }
    
                            {/** FILTER DROPDOWN **/}
                            <div className='dropdown-wrapper'>
                                <span className="label">Dimensions</span>
                                <Select
                                    onChange={(event) => {setpaginationOffset(0); changeStatisticsTable(selectedEntityKey, event.value, selectedEntityId); setGroupBy(handleDropdownChange(event))}}
                                    className="select-primary"
                                    value={groupBy}
                                    placeholder={groupBy == "entity" ? getEntityLabel() : getKeyColumn(groupBy).label}
                                    options={
                                        getCampaignType() == "PUSH_NOTIFICATION" ?
                                        [
                                            {label:"Daily",                     value:"daily"},
                                            {isDisabled:false, label:getEntityLabel(),            value:"entity"},
                                            {isDisabled:false, label:"Country",                   value:"countries"},
                                            {isDisabled:false, label:"Zone",                      value:"zone"},
                                            // {label:"Publisher",                 value:"publisher"},
                                            // {label:"Environment",               value:"environments"},
                                            // {label:"Device",                    value:"devices"},
                                            // {label:"OS",                        value:"os"},
                                            // {label:"State",                     value:"state"},
                                            // {label:"DMA",                       value:"dma"},
                                            // {label:"Media Sources",             value:"media_sources"},
                                            // {label:"Top 5 DMA by CTR",          value:"TOP5_DMA_BY_CTR"},
                                            {isDisabled:false, label:"Top 5 Countries by CTR",    value:"TOP5_COUNTRIES_BY_CTR"},
                                            // {label:"Top 5 State by CTR ",       value:"TOP5_STATE_BY_CTR"},
                                            // {label:"Top 10 State by CTR ",      value:"TOP10_STATE_BY_CTR"},
                                            // {label:"Top 10 DMA by CTR",         value:"TOP10_DMA_BY_CTR"},
                                            {isDisabled:false, label:"Top 10 Countries by CTR",   value:"TOP10_COUNTRIES_BY_CTR"},
                                        ]
                                        :
                                        selectedEntityKey == "supernovaAdgroupId" ?
                                        [
                                            {label:"Daily",                     value:"daily"},
                                            {isDisabled:false, label:"Publisher",                 value:"publisher"},
                                            {isDisabled:false, label:"Environment",               value:"environments"},
                                            {isDisabled:false, label:"Device",                    value:"devices"},
                                            {isDisabled:false, label:"OS",                        value:"os"},
                                            {isDisabled:false, label:"Country",                   value:"countries"},
                                            {isDisabled:false, label:"State",                     value:"state"},
                                            {isDisabled:false, label:"DMA",                       value:"dma"},
                                            {isDisabled:false, label:"Media Sources",             value:"media_sources"},
                                            {isDisabled:false, label:"Top 5 DMA by CTR",          value:"TOP5_DMA_BY_CTR"},
                                            {isDisabled:false, label:"Top 5 Countries by CTR",    value:"TOP5_COUNTRIES_BY_CTR"},
                                            {isDisabled:false, label:"Top 5 State by CTR ",       value:"TOP5_STATE_BY_CTR"},
                                            {isDisabled:false, label:"Top 10 State by CTR ",      value:"TOP10_STATE_BY_CTR"},
                                            {isDisabled:false, label:"Top 10 DMA by CTR",         value:"TOP10_DMA_BY_CTR"},
                                            {isDisabled:false, label:"Top 10 Countries by CTR",   value:"TOP10_COUNTRIES_BY_CTR"},
                                        ]
                                        :
                                        [
                                            {label:"Daily",                     value:"daily"},
                                            {isDisabled:false, label:getEntityLabel(),            value:"entity"},
                                            {isDisabled:false, label:"Publisher",                 value:"publisher"},
                                            {isDisabled:false, label:"Environment",               value:"environments"},
                                            {isDisabled:false, label:"Device",                    value:"devices"},
                                            {isDisabled:false, label:"OS",                        value:"os"},
                                            {isDisabled:false, label:"Country",                   value:"countries"},
                                            {isDisabled:false, label:"State",                     value:"state"},
                                            {isDisabled:false, label:"DMA",                       value:"dma"},
                                            {isDisabled:false, label:"Media Sources",             value:"media_sources"},
                                            {isDisabled:false, label:"Top 5 DMA by CTR",          value:"TOP5_DMA_BY_CTR"},
                                            {isDisabled:false, label:"Top 5 Countries by CTR",    value:"TOP5_COUNTRIES_BY_CTR"},
                                            {isDisabled:false, label:"Top 5 State by CTR ",       value:"TOP5_STATE_BY_CTR"},
                                            {isDisabled:false, label:"Top 10 State by CTR ",      value:"TOP10_STATE_BY_CTR"},
                                            {isDisabled:false, label:"Top 10 DMA by CTR",         value:"TOP10_DMA_BY_CTR"},
                                            {isDisabled:false, label:"Top 10 Countries by CTR",   value:"TOP10_COUNTRIES_BY_CTR"},
                                        ]
                                    }
                                    isClearable={false}
                                    styles={styleSelectBasic} />
                            </div>
                            {
                                //DAILY STATS TABLE
                                groupBy == "daily" && selectedEntityKey != "supernovaCreativeId" ? 
                                <>
                                    <table id="primary-report-table" className={`primary has-hover ${getCampaignType()}`}>  
                                        <thead>
                                            <tr>
                                                <th className={tableSortBy == "date_number" && "active"} onClick={() => {setTableSortBy("date_number"); tableSortBy == "date_number" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                    <span className="label">Date {tableSortBy == "date_number" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                    <span className="badge hidden"></span>
                                                </th>
                                                <th className={`${tableSortBy == "impressions" && "active"} text-right`} onClick={() => {setTableSortBy("impressions"); tableSortBy == "impressions" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                    <span className="label">Impressions {tableSortBy == "impressions" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                    <span className="badge">{statistics && statistics.daily && statistics.daily.reduce((accumulator, object) => {return accumulator + object.impressions}, 0).toLocaleString()}</span>
                                                </th>
                                                <th className={`${tableSortBy == "clicks" && "active"} text-right`} onClick={() => {setTableSortBy("clicks"); tableSortBy == "clicks" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                    <span className="label">Clicks {tableSortBy == "clicks" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                    <span className="badge">{statistics && statistics.daily && statistics.daily.reduce((accumulator, object) => {return accumulator + object.clicks}, 0).toLocaleString()}</span>
                                                </th>
                                                <th className={`${tableSortBy == "ctr" && "active"} text-right`} onClick={() => {setTableSortBy("ctr"); tableSortBy == "ctr" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                    <span className="label">CTR {tableSortBy == "ctr" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                    <span className="badge">
                                                        {
                                                            statistics && 
                                                            statistics.daily &&
                                                            Array.isArray(statistics.daily) &&
                                                            statistics.daily.reduce((accumulator, object) => {return accumulator + object.clicks}, 0) > 0 
                                                            ? `${parseFloat(statistics.daily.reduce((accumulator, object) => {return accumulator + object.clicks}, 0) /  statistics.daily.reduce((accumulator, object) => {return accumulator + object.impressions}, 0) * 100).toLocaleString(undefined, {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })}%`
                                                            : "0.00%"
                                                        }
                                                    </span>
                                                </th>
                                                <th className={`${isColumnMediaSpendVisible ? "" : "d-none"} ${tableSortBy == "media_spend" && "active"} text-right`} onClick={() => {setTableSortBy("media_spend"); tableSortBy == "media_spend" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                    <span className="label">Media Spend {tableSortBy == "media_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                    <span className="badge">${
                                                        tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.media_spend) ? "0.00" : accumulator + object.media_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                    }</span>
                                                </th>
                                                <th className={`${isColumnDataSpendVisible ? "" : "d-none"} data-spend ${tableSortBy == "data_spend" && "active"} text-right`} onClick={() => {setTableSortBy("data_spend"); tableSortBy == "data_spend" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                    <span className="label">Data Spend {tableSortBy == "data_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                    <span className="badge">${
                                                        tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.data_spend) ? "0.00" : accumulator + object.data_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                    }</span>
                                                </th>
                                                <th className={`${tableSortBy == "total_spend" && "active"} text-right`} onClick={() => {setTableSortBy("total_spend"); tableSortBy == "total_spend" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                    <span className="label">Total Spend {tableSortBy == "total_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                    <span className="badge">${
                                                            tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.total_spend) ? "0.00" : accumulator + object.total_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                        }
                                                    </span>
                                                </th>
                                                <th className={`d-none ${tableSortBy == "yesterday_spend" && "active"} text-right`} onClick={() => {setTableSortBy("yesterday_spend"); tableSortBy == "yesterday_spend" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                    Yesterday Spend {tableSortBy == "yesterday_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}
                                                </th>
                                                <th className={`${isColumnMediaECPCVisible ? "" : "d-none"} ${tableSortBy == "media_ecpc" && "active"} text-right`} onClick={() => {setTableSortBy("media_ecpc"); tableSortBy == "media_ecpc" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                    <span className="label">Media eCPC {tableSortBy == "media_ecpc" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                    <span className="badge">${
                                                        tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.media_ecpc) ? "0.00" : accumulator + object.media_ecpc}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                    }</span>
                                                </th>
                                                <th className={`${isColumnMediaECPMVisible ? "" : "d-none"} ${tableSortBy == "media_ecpm" && "active"} text-right`} onClick={() => {setTableSortBy("media_ecpm"); tableSortBy == "media_ecpm" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                    <span className="label">Media eCPM {tableSortBy == "media_ecpm" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                    <span className="badge">${
                                                        tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.media_ecpm) ? "0.00" : accumulator + object.media_ecpm}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                    }</span>
                                                </th>
                                                <th className={`${tableSortBy == "total_ecpc" && "active"} text-right`} onClick={() => {setTableSortBy("total_ecpc"); tableSortBy == "total_ecpc" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                    <span className="label">Total eCPC {tableSortBy == "total_ecpc" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                    <span className="badge">${
                                                        //TOTAL_ECPC = TOTAL_SPEND /  TOTAL_CLICKS
                                                        statistics && 
                                                        statistics.daily &&
                                                        Array.isArray(statistics.daily) &&
                                                        (
                                                            statistics.daily.reduce((accumulator, object) => {return accumulator + object.clicks}, 0) == 0
                                                            ? "0.00"
                                                            : parseFloat(
                                                                tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.total_spend) ? 0 : accumulator + object.total_spend}, 0) /
                                                                statistics.daily.reduce((accumulator, object) => {return accumulator + object.clicks}, 0)
                                                            ).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                        )
                                                    }</span>
                                                </th>
                                                <th className={`${tableSortBy == "total_ecpm" && "active"} text-right`} onClick={() => {setTableSortBy("total_ecpm"); tableSortBy == "total_ecpm" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                    <span className="label">Total eCPM {tableSortBy == "total_ecpm" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                    <span className="badge">${
                                                        //TOTAL_ECPM = ( TOTAL_SPEND x 1000 ) /  TOTAL_IMPRESSIONS
                                                        statistics && 
                                                        statistics.daily &&
                                                        Array.isArray(statistics.daily) &&
                                                        (
                                                            statistics.daily.reduce((accumulator, object) => {return accumulator + object.impressions}, 0) == 0
                                                            ? "0.00"
                                                            : parseFloat(
                                                                (tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.total_spend) ? 0 : accumulator + object.total_spend}, 0) * 1000) /
                                                                statistics.daily.reduce((accumulator, object) => {return accumulator + object.impressions}, 0)
                                                            ).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                        )
                                                    }</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            tableArray(groupBy).sortBy(tableSortBy, isTableSortDescending).slice(paginationOffset, paginationOffset + tableRowsPerPage).map((item, index) =>
                                                <tr key={index}>
                                                    <td>{item.date}</td>
                                                    <td className="text-right">{item.impressions.toLocaleString()}</td>
                                                    <td className="text-right">{item.clicks}</td>
                                                    <td className="text-right">{`${(item.clicks == 0 ? 0 : (item.clicks / item.impressions) * 100).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}%`}</td>
                                                    <td className={`${isColumnMediaSpendVisible ? "" : "d-none"} text-right`}>{`$${
                                                        isNaN(item.media_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                        ? "0.00"
                                                        : item.media_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                    }`}</td>
                                                    <td className={`${isColumnDataSpendVisible ? "" : "d-none"} data-spend text-right`}>{`$${
                                                        isNaN(item.data_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                        ? "0.00"
                                                        : item.data_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                    }`}</td>
                                                    <td className="text-right">{`$${
                                                        isNaN(item.total_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                        ? "0.00"
                                                        : item.total_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                    }`}</td>
                                                    <td className="d-none text-right">{`$${
                                                        isNaN(item.yesterday_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                        ? "0.00"
                                                        : item.yesterday_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                    }`}</td>
                                                    <td className={`${isColumnMediaECPCVisible ? "" : "d-none"} text-right`}>{`$${
                                                        isNaN(item.media_ecpc.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                        ? "0.00"
                                                        : item.media_ecpc.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                    }`}</td>
                                                    <td className={`${isColumnMediaECPMVisible ? "" : "d-none"} text-right`}>{`$${
                                                        isNaN(item.media_ecpm.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                        ? "0.00"
                                                        : item.media_ecpm.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                    }`}</td>
                                                    <td className="text-right">{`$${
                                                        //ECPC = spend / clicks
                                                        isNaN((item.total_spend / item.clicks).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                        ? "0.00"
                                                        : (item.total_spend / item.clicks).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                    }`}</td>
                                                    <td className="text-right">{`$${
                                                        //ECPM = ( spend x 1,000 ) / impressions
                                                        isNaN(((item.total_spend * 1000) / item.impressions).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                        ? "0.00"
                                                        : ((item.total_spend * 1000) / item.impressions).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                    }`}</td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </>

                                //DAILY CREATIVES STATS TABLE
                                : groupBy == "daily" && selectedEntityKey == "supernovaCreativeId" ? 
                                <>
                                    <table id="primary-report-table" className={`primary has-hover ${getCampaignType()}`}>  
                                        <thead>
                                            <tr>
                                                <th className={tableSortBy == "date_number" && "active"} onClick={() => {setTableSortBy("date_number"); tableSortBy == "date_number" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                    <span className="label">Date {tableSortBy == "date_number" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                    <span className="badge hidden"></span>
                                                </th>
                                                <th className={`${tableSortBy == "impressions" && "active"} text-right`} onClick={() => {setTableSortBy("impressions"); tableSortBy == "impressions" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                    <span className="label">Impressions {tableSortBy == "impressions" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                    <span className="badge">{logSupernova && logSupernova.items && logSupernova.items.reduce((accumulator, object) => {return accumulator + object.impressions}, 0).toLocaleString()}</span>
                                                </th>
                                                <th className={`${tableSortBy == "clicks" && "active"} text-right`} onClick={() => {setTableSortBy("clicks"); tableSortBy == "clicks" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                    <span className="label">Clicks {tableSortBy == "clicks" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                    <span className="badge">{logSupernova && logSupernova.items && logSupernova.items.reduce((accumulator, object) => {return accumulator + object.clicks}, 0).toLocaleString()}</span>
                                                </th>
                                                <th className={`${tableSortBy == "ctr" && "active"} text-right`} onClick={() => {setTableSortBy("ctr"); tableSortBy == "ctr" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                    <span className="label">CTR {tableSortBy == "ctr" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                    <span className="badge">
                                                    {
                                                        logSupernova && 
                                                        logSupernova.items &&
                                                        Array.isArray(logSupernova.items) &&
                                                        logSupernova.items.reduce((accumulator, object) => {return accumulator + object.clicks}, 0) > 0 
                                                        ? `${parseFloat(logSupernova.items.reduce((accumulator, object) => {return accumulator + object.clicks}, 0) /  logSupernova.items.reduce((accumulator, object) => {return accumulator + object.impressions}, 0) * 100).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                        })}%`
                                                        : "0.00%"
                                                    }
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            tableArray("daily_creatives").sortBy(tableSortBy, isTableSortDescending).slice(paginationOffset, paginationOffset + tableRowsPerPage).map((item, index) =>
                                                <tr key={index}>
                                                    <td>{item.date}</td>
                                                    <td className="text-right">{item.impressions.toLocaleString()}</td>
                                                    <td className="text-right">{item.clicks}</td>
                                                    <td className="text-right">{`${(item.clicks == 0 ? 0 : (item.clicks / item.impressions) * 100).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) * 100}%`}</td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </>

                                
    
                                : groupBy == "entity" ? 
                                <>
                                    {
                                        selectedEntityKey   == null         ?   <>{displayMetricsOfAllCampaigns()}</>
                                        : selectedEntityKey == "supernovaCampaignId" ?   <>{displayMetricsOfAdgroupsByCampaignId()}</>
                                        : selectedEntityKey == "supernovaAdgroupId"  ?   <>{displayMetricsOfCreativesByAdgroupId()}</>
                                        : <></>
                                    }
                                </>
                                    
                                : groupBy == "publisher"  ? 
                                    <>
                                        {
                                            publishers.loading_account  || 
                                            publishers.loading_campaign  || 
                                            publishers.loading_adgroup ?
                                            <div className='loading'><i className="fa fa-spinner fa-spin fast-spin"></i> Loading...</div>
                                            :
                                            <table id="primary-report-table" className={`primary has-hover ${getCampaignType()}`}>  
                                                <thead>
                                                    <tr>
                                                        <th className={`${tableSortBy == "id" ? "active" : ""}`} onClick={() => {setTableSortBy("id"); tableSortBy == "id" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            <span className="label">Publisher {tableSortBy == "id" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge hidden"></span>
                                                        </th>
                                                        <th className={`${tableSortBy == "media_source" && "active"}`} onClick={() => {setTableSortBy("media_source"); tableSortBy == "media_source" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            <span className="label">Media Source {tableSortBy == "media_source" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge hidden"></span>
                                                        </th>
                                                        <th className={`stats text-right ${tableSortBy == "impressions" && "active"}`} onClick={() => {setTableSortBy("impressions"); tableSortBy == "impressions" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            <span className="label">Impression {tableSortBy == "impressions" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge">{tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0)}</span>
                                                        </th>
                                                        <th className={`stats text-right ${tableSortBy == "clicks" && "active"}`} onClick={() => {setTableSortBy("clicks"); tableSortBy == "clicks" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            <span className="label">Clicks {tableSortBy == "clicks" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge">{tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0)}</span>
                                                        </th>
                                                        <th className={`${tableSortBy == "ctr" && "active"} text-right`} onClick={() => {setTableSortBy("ctr"); tableSortBy == "ctr" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                            <span className="label">CTR {tableSortBy == "ctr" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge">
                                                            {
                                                                tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0) > 0 
                                                                ? `${parseFloat(tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0) /  tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0) * 100).toLocaleString(undefined, {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                })}%`
                                                                : "0.00%"
                                                            }
                                                            </span>
                                                        </th>
                                                        <th className={`${isColumnMediaSpendVisible ? "" : "d-none"} ${tableSortBy == "media_spend" && "active"} text-right`} onClick={() => {setTableSortBy("media_spend"); tableSortBy == "media_spend" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                            <span className="label">Media Spend {tableSortBy == "media_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge">${
                                                                tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.media_spend) ? "0.00" : accumulator + object.media_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                            }</span>
                                                        </th>
                                                        <th className={`${isColumnDataSpendVisible ? "" : "d-none"} data-spend ${tableSortBy == "data_spend" && "active"} text-right`} onClick={() => {setTableSortBy("data_spend"); tableSortBy == "data_spend" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                            <span className="label">Data Spend {tableSortBy == "data_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge">${
                                                                tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.data_spend) ? "0.00" : accumulator + object.data_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                            }</span>
                                                        </th>
                                                        <th className={`stats text-right ${tableSortBy == "total_spend" && "active"}`} onClick={() => {setTableSortBy("total_spend"); tableSortBy == "total_spend" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            <span className="label">Total Spend {tableSortBy == "total_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge">${
                                                                tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.total_spend) ? "0.00" : accumulator + object.total_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                            }</span>
                                                        </th>   
                                                        <th className={`d-none stats text-right ${tableSortBy == "yesterday_spend" && "active"}`} onClick={() => {setTableSortBy("yesterday_spend"); tableSortBy == "yesterday_spend" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            <span className="label">Yesterday Spend {tableSortBy == "yesterday_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge">${
                                                                tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.yesterday_spend) ? "0.00" : accumulator + object.yesterday_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                            }</span>
                                                        </th>   
                                                        <th className={`${isColumnMediaECPCVisible ? "" : "d-none"} stats text-right ${tableSortBy == "media_ecpc" && "active"}`} onClick={() => {setTableSortBy("media_ecpc"); tableSortBy == "media_ecpc" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            <span className="label">Media eCPC {tableSortBy == "media_ecpc" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge">${
                                                                tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.media_ecpc) ? "0.00" : accumulator + object.media_ecpc}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                            }</span>
                                                        </th>
                                                        <th className={`${isColumnMediaECPMVisible ? "" : "d-none"} stats text-right ${tableSortBy == "media_ecpm" && "active"}`} onClick={() => {setTableSortBy("media_ecpm"); tableSortBy == "media_ecpm" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            <span className="label">Media eCPM {tableSortBy == "media_ecpm" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge">${
                                                                tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.media_ecpm) ? "0.00" : accumulator + object.media_ecpm}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                            }</span>
                                                        </th>
                                                        <th className={`stats text-right ${tableSortBy == "total_ecpc" && "active"}`} onClick={() => {setTableSortBy("total_ecpc"); tableSortBy == "total_ecpc" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            <span className="label">Total eCPC {tableSortBy == "total_ecpc" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge">${
                                                                //TOTAL_ECPC = TOTAL_SPEND /  TOTAL_CLICKS
                                                                tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0) == 0
                                                                ? "0.00"
                                                                : parseFloat(
                                                                    tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.total_spend) ? 0 : accumulator + object.total_spend}, 0) /
                                                                    tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0)
                                                                    ).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                            }</span>
                                                        </th>
                                                        <th className={`stats text-right ${tableSortBy == "total_ecpm" && "active"}`} onClick={() => {setTableSortBy("total_ecpm"); tableSortBy == "total_ecpm" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                            <span className="label">Total eCPM {tableSortBy == "total_ecpm" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                            <span className="badge">${
                                                                //TOTAL_ECPM = ( TOTAL_SPEND x 1000 ) /  TOTAL_IMPRESSIONS
                                                                tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0) == 0
                                                                ? "0.00"
                                                                : parseFloat(
                                                                    (tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.total_spend) ? 0 : accumulator + object.total_spend}, 0) * 1000) /
                                                                    tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0)
                                                                    ).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                            }</span>
                                                        </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        tableArray(groupBy).sortBy(tableSortBy, isTableSortDescending).slice(paginationOffset, paginationOffset + tableRowsPerPage).map((item, index) =>
                                                            <tr key={index}>
                                                                <td>{item.id}</td>
                                                                <td>{item.media_source}</td>
                                                                <td className="text-right">{item.impressions.toLocaleString()}</td>
                                                                <td className="text-right">{item.clicks}</td>
                                                                <td className="text-right">{`${(item.clicks == 0 ? 0 : (item.clicks / item.impressions) * 100).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}%`}</td>
                                                                <td className={`${isColumnMediaSpendVisible ? "" : "d-none"} text-right`}>{`$${
                                                                    isNaN(item.media_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                    ? "0.00"
                                                                    : item.media_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                }`}</td>
                                                                <td className={`${isColumnDataSpendVisible ? "" : "d-none"} data-spend text-right`}>{`$${
                                                                    isNaN(item.data_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                    ? "0.00"
                                                                    : item.data_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                }`}</td>
                                                                <td className="text-right">{`$${
                                                                    isNaN(item.total_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                    ? "0.00"
                                                                    : item.total_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                }`}</td>
                                                                <td className="d-none text-right">{`$${
                                                                    isNaN(item.yesterday_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                    ? "0.00"
                                                                    : item.yesterday_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                }`}</td>
                                                                <td className={`${isColumnMediaECPCVisible ? "" : "d-none"} text-right`}>{`$${
                                                                    isNaN(item.media_ecpc.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                    ? "0.00"
                                                                    : item.media_ecpc.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                }`}</td>
                                                                <td className={`${isColumnMediaECPMVisible ? "" : "d-none"} text-right`}>{`$${
                                                                    isNaN(item.media_ecpm.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                    ? "0.00"
                                                                    : item.media_ecpm.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                }`}</td>
                                                                <td className="text-right">{`$${
                                                                    //ECPC = spend / clicks
                                                                    isNaN((item.total_spend / item.clicks).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                    ? "0.00"
                                                                    : (item.total_spend / item.clicks).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                }`}</td>
                                                                <td className="text-right">{`$${
                                                                    //ECPM = ( spend x 1,000 ) / impressions
                                                                    isNaN(((item.total_spend * 1000) / item.impressions).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                    ? "0.00"
                                                                    : ((item.total_spend * 1000) / item.impressions).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                }`}</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        }
                                    </>
                                :   groupBy == "devices"        || 
                                    groupBy == "os"             || 
                                    groupBy == "countries"      || 
                                    groupBy == "zone"           || 
                                    groupBy == "environments"   || 
                                    groupBy == "dma"            || 
                                    groupBy == "state"          || 
                                    groupBy == "media_sources"  ? 
                                    <>
                                        {
                                            <>
                                                {
                                                    (statistics.isDevicesDone && !statistics.isDevicesDone)             ||
                                                    (statistics.isOSDone && !statistics.isOSDone)                       ||
                                                    (statistics.isCountriesDone && !statistics.isCountriesDone)         ||
                                                    (statistics.isZoneDone && !statistics.isZoneDone)                   ||
                                                    (statistics.isStateDone && !statistics.isStateDone)                 ||
                                                    (statistics.isDMADone && !statistics.isDMADone)                     ||
                                                    (statistics.isEnvironmentDone && !statistics.isEnvironmentDone)     ||
                                                    (statistics.isMediaSourcesDone && !statistics.isMediaSourcesDone)   ?
                                                    <div className='loading'><i className="fa fa-spinner fa-spin fast-spin"></i> Loading...</div>
                                                    :
                                                    <table id="primary-report-table" className={`primary has-hover ${getCampaignType()}`}>  
                                                        <thead>
                                                            <tr>
                                                                <th className={tableSortBy == "id" ? "active" : ""} onClick={() => {setTableSortBy("id"); tableSortBy == "id" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                                    <span className="label">{getKeyColumn(groupBy).label} {tableSortBy == "id" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                    <span className="badge hidden"></span>
                                                                </th>
                                                                <th className={`${tableSortBy == "impressions" && "active"} text-right`} onClick={() => {setTableSortBy("impressions"); tableSortBy == "impressions" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                                    <span className="label">Impressions {tableSortBy == "impressions" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                    <span className="badge">{tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0)}</span>
                                                                </th>
                                                                <th className={`${tableSortBy == "clicks" && "active"} text-right`} onClick={() => {setTableSortBy("clicks"); tableSortBy == "clicks" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                                    <span className="label">Clicks {tableSortBy == "clicks" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                    <span className="badge">{tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0)}</span>
                                                                </th>
                                                                <th className={`${tableSortBy == "ctr" && "active"} text-right`} onClick={() => {setTableSortBy("ctr"); tableSortBy == "ctr" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                                    <span className="label">CTR {tableSortBy == "ctr" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                    <span className="badge">
                                                                    {
                                                                        tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0) > 0 
                                                                        ? `${parseFloat(tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0) /  tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0) * 100).toLocaleString(undefined, {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        })}%`
                                                                        : "0.00%"
                                                                    }
                                                                    </span>
                                                                </th>
                                                                <th className={`${isColumnMediaSpendVisible ? "" : "d-none"} ${tableSortBy == "media_spend" && "active"} text-right`} onClick={() => {setTableSortBy("media_spend"); tableSortBy == "media_spend" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                                    <span className="label">Media Spend {tableSortBy == "media_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                    <span className="badge">{
                                                                        tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.media_spend) ? "0.00" : accumulator + object.media_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }</span>
                                                                </th>
                                                                <th className={`${isColumnDataSpendVisible ? "" : "d-none"} data-spend ${tableSortBy == "data_spend" && "active"} text-right`} onClick={() => {setTableSortBy("data_spend"); tableSortBy == "data_spend" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                                    <span className="label">Data Spend {tableSortBy == "data_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                    <span className="badge">{
                                                                        tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.data_spend) ? "0.00" : accumulator + object.data_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }</span>
                                                                </th>
                                                                <th className={`${tableSortBy == "total_spend" && "active"} text-right`} onClick={() => {setTableSortBy("total_spend"); tableSortBy == "total_spend" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                                    <span className="label">Total Spend {tableSortBy == "total_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                    <span className="badge">${
                                                                        tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.total_spend) ? "0.00" : accumulator + object.total_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }</span>
                                                                </th>
                                                                <th className={`d-none ${tableSortBy == "yesterday_spend" && "active"} text-right`} onClick={() => {setTableSortBy("yesterday_spend"); tableSortBy == "yesterday_spend" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                                    <span className="label">Yesterday Spend {tableSortBy == "yesterday_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                    <span className="badge">${
                                                                        tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.yesterday_spend) ? "0.00" : accumulator + object.yesterday_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }</span>
                                                                </th>
                                                                <th className={`${isColumnMediaECPCVisible ? "" : "d-none"} ${tableSortBy == "media_ecpc" && "active"} text-right`} onClick={() => {setTableSortBy("media_ecpc"); tableSortBy == "media_ecpc" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                                    <span className="label">Media eCPC {tableSortBy == "media_ecpc" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                    <span className="badge">${
                                                                        tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.media_ecpc) ? "0.00" : accumulator + object.media_ecpc}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }</span>
                                                                </th>
                                                                <th className={`${isColumnMediaECPMVisible ? "" : "d-none"} ${tableSortBy == "media_ecpm" && "active"} text-right`} onClick={() => {setTableSortBy("media_ecpm"); tableSortBy == "media_ecpm" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                                    <span className="label">Media eCPM {tableSortBy == "media_ecpm" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                    <span className="badge">${
                                                                        tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.media_ecpm) ? "0.00" : accumulator + object.media_ecpm}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }</span>
                                                                </th>
                                                                <th className={`${tableSortBy == "total_ecpc" && "active"} text-right`} onClick={() => {setTableSortBy("total_ecpc"); tableSortBy == "total_ecpc" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                                    <span className="label">Total eCPC {tableSortBy == "total_ecpc" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                    <span className="badge">${
                                                                        //TOTAL_ECPC = TOTAL_SPEND /  TOTAL_CLICKS
                                                                        tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0) == 0
                                                                        ? "0.00"
                                                                        : parseFloat(
                                                                            tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.total_spend) ? 0 : accumulator + object.total_spend}, 0) /
                                                                            tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0)
                                                                            ).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }</span>
                                                                </th>
                                                                <th className={`${tableSortBy == "total_ecpm" && "active"} text-right`} onClick={() => {setTableSortBy("total_ecpm"); tableSortBy == "total_ecpm" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                                    <span className="label">Total eCPM {tableSortBy == "total_ecpm" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                    <span className="badge">${
                                                                        //TOTAL_ECPM = ( TOTAL_SPEND x 1000 ) /  TOTAL_IMPRESSIONS
                                                                        tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0) == 0
                                                                        ? "0.00"
                                                                        : parseFloat(
                                                                            (tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.total_spend) ? 0 : accumulator + object.total_spend}, 0) * 1000) /
                                                                            tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0)
                                                                            ).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }</span>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                tableArray(groupBy).sortBy(tableSortBy, isTableSortDescending).slice(paginationOffset, paginationOffset + tableRowsPerPage).map((item, index) =>
                                                                    <tr key={index}>
                                                                        <td><span>{item.id}</span></td>
                                                                        <td className="text-right">{item.impressions.toLocaleString()}</td>
                                                                        <td className="text-right">{item.clicks.toLocaleString()}</td>
                                                                        <td className="text-right">{`${(item.clicks == 0 ? 0 : (item.clicks / item.impressions) * 100).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}%`}</td>
                                                                        <td className={`${isColumnMediaSpendVisible ? "" : "d-none"} text-right`}>{`$${
                                                                            isNaN(item.media_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                            ? "0.00"
                                                                            : item.media_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                        }`}</td>
                                                                        <td className={`${isColumnDataSpendVisible ? "" : "d-none"} data-spend text-right`}>{`$${
                                                                            isNaN(item.data_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                            ? "0.00"
                                                                            : item.data_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                        }`}</td>
                                                                        <td className="text-right">{`$${
                                                                            isNaN(item.total_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                            ? "0.00"
                                                                            : item.total_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                        }`}</td>
                                                                        <td className="d-none text-right">{`$${
                                                                            isNaN(item.yesterday_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                            ? "0.00"
                                                                            : item.yesterday_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                        }`}</td>
                                                                        <td className={`${isColumnMediaECPCVisible ? "" : "d-none"} text-right`}>{`$${
                                                                            isNaN(item.media_ecpc.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                            ? "0.00"
                                                                            : item.media_ecpc.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                        }`}</td>
                                                                        <td className={`${isColumnMediaECPMVisible ? "" : "d-none"} text-right`}>{`$${
                                                                            isNaN(item.media_ecpm.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                            ? "0.00"
                                                                            : item.media_ecpm.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                        }`}</td>
                                                                        <td className="text-right">{`$${
                                                                            //ECPC = spend / clicks
                                                                            isNaN((item.total_spend / item.clicks).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                            ? "0.00"
                                                                            : (item.total_spend / item.clicks).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                        }`}</td>
                                                                        <td className="text-right">{`$${
                                                                            //ECPM = ( spend x 1,000 ) / impressions
                                                                            isNaN(((item.total_spend * 1000) / item.impressions).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                            ? "0.00"
                                                                            : ((item.total_spend * 1000) / item.impressions).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                        }`}</td>
                                                                    </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
    
                                                }
                                            </>
                                        }
                                    </>
                                :   groupBy == "TOP5_DMA_BY_CTR"        || 
                                    groupBy == "TOP5_COUNTRIES_BY_CTR"  || 
                                    groupBy == "TOP5_STATE_BY_CTR"      ||
                                    groupBy == "TOP10_DMA_BY_CTR"       || 
                                    groupBy == "TOP10_COUNTRIES_BY_CTR" || 
                                    groupBy == "TOP10_STATE_BY_CTR"     ?
                                    <>
                                        {
                                            (statistics.isTopDMAByCtrDone  && !statistics.isTopDMAByCtrDone)                ||
                                            (statistics.isTopCountriesByCtrDone  && !statistics.isTopCountriesByCtrDone)    ||
                                            (statistics.isTopStatesByCtrDone  && !statistics.isTopStatesByCtrDone)          ?
                                            <div className='loading'><i className="fa fa-spinner fa-spin fast-spin"></i> Loading...</div>
                                            :
                                                <table id="primary-report-table" className="home primary fixed-header has-hover">
                                                    <thead>
                                                        {
                                                            <tr>
                                                                <th className={tableSortBy == "id" ? "active" : ""} onClick={() => {setTableSortBy("id"); tableSortBy == "id" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                                    <span className="label">{insights.headings[0]} {tableSortBy == "id" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                    <span className="badge hidden"></span>
                                                                </th>
                                                                <th className={`${tableSortBy == "impressions" && "active"} text-right`} onClick={() => {setTableSortBy("impressions"); tableSortBy == "impressions" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                                    <span className="label">Impressions {tableSortBy == "impressions" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                    <span className="badge">{tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0)}</span>
                                                                </th>
                                                                <th className={`${tableSortBy == "clicks" && "active"} text-right`} onClick={() => {setTableSortBy("clicks"); tableSortBy == "clicks" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                                    <span className="label">Clicks {tableSortBy == "clicks" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                    <span className="badge">{tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0)}</span>
                                                                </th>
                                                                <th className={`${tableSortBy == "ctr" && "active"} text-right`} onClick={() => {setTableSortBy("ctr"); tableSortBy == "ctr" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                                    <span className="label">CTR {tableSortBy == "ctr" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                    <span className="badge">
                                                                    {
                                                                        tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0) > 0 
                                                                        ? `${parseFloat(tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0) /  tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0) * 100).toLocaleString(undefined, {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        })}%`
                                                                        : "0.00%"
                                                                    }
                                                                    </span>
                                                                </th>
                                                                <th className={`${isColumnDataSpendVisible ? "" : "d-none"} data-spend ${tableSortBy == "media_spend" && "active"} text-right`} onClick={() => {setTableSortBy("media_spend"); tableSortBy == "media_spend" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                                    <span className="label">Media Spend {tableSortBy == "media_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                    <span className="badge">${
                                                                        tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.media_spend) ? "0.00" : accumulator + object.media_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }</span>
                                                                </th>
                                                                <th className={`${isColumnDataSpendVisible ? "" : "d-none"} data-spend ${tableSortBy == "data_spend" && "active"} text-right`} onClick={() => {setTableSortBy("data_spend"); tableSortBy == "data_spend" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                                    <span className="label">Data Spend {tableSortBy == "data_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                    <span className="badge">${
                                                                        tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.data_spend) ? "0.00" : accumulator + object.data_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }</span>
                                                                </th>
                                                                <th className={`${tableSortBy == "total_spend" && "active"} text-right`} onClick={() => {setTableSortBy("total_spend"); tableSortBy == "total_spend" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                                    <span className="label">Total Spend {tableSortBy == "total_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                    <span className="badge">${
                                                                        tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.total_spend) ? "0.00" : accumulator + object.total_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }</span>
                                                                </th>
                                                                <th className={`d-none ${tableSortBy == "yesterday_spend" && "active"} text-right`} onClick={() => {setTableSortBy("yesterday_spend"); tableSortBy == "yesterday_spend" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                                    <span className="label">Yesterday Spend {tableSortBy == "yesterday_spend" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                    <span className="badge">${
                                                                        tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.yesterday_spend) ? "0.00" : accumulator + object.yesterday_spend}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }</span>
                                                                </th>
                                                                <th className={`${isColumnMediaECPCVisible ? "" : "d-none"} ${tableSortBy == "media_ecpc" && "active"} text-right`} onClick={() => {setTableSortBy("media_ecpc"); tableSortBy == "media_ecpc" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                                    <span className="label">Media eCPC {tableSortBy == "media_ecpc" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                    <span className="badge">${
                                                                        tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.media_ecpc) ? "0.00" : accumulator + object.media_ecpc}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }</span>
                                                                </th>
                                                                <th className={`${isColumnMediaECPMVisible ? "" : "d-none"} d-none ${tableSortBy == "media_ecpm" && "active"} text-right`} onClick={() => {setTableSortBy("media_ecpm"); tableSortBy == "media_ecpm" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                                    <span className="label">Media eCPM {tableSortBy == "media_ecpm" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                    <span className="badge">${
                                                                        tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.media_ecpm) ? "0.00" : accumulator + object.media_ecpm}, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }</span>
                                                                </th>
                                                                <th className={`${tableSortBy == "total_ecpc" && "active"} text-right`} onClick={() => {setTableSortBy("total_ecpc"); tableSortBy == "total_ecpc" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                                    <span className="label">Total eCPC {tableSortBy == "total_ecpc" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                    <span className="badge">${
                                                                        //TOTAL_ECPC = TOTAL_SPEND /  TOTAL_CLICKS
                                                                        tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0) == 0
                                                                        ? "0.00"
                                                                        : parseFloat(
                                                                            tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.total_spend) ? 0 : accumulator + object.total_spend}, 0) /
                                                                            tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.clicks}, 0)
                                                                            ).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }</span>
                                                                </th>
                                                                <th className={`${tableSortBy == "total_ecpm" && "active"} text-right`} onClick={() => {setTableSortBy("total_ecpm"); tableSortBy == "total_ecpm" && setIsTableSortDescending(!isTableSortDescending);}}>
                                                                    <span className="label">Total eCPM {tableSortBy == "total_ecpm" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                    <span className="badge">${
                                                                        //TOTAL_ECPM = ( TOTAL_SPEND x 1000 ) /  TOTAL_IMPRESSIONS
                                                                        tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0) == 0
                                                                        ? "0.00"
                                                                        : parseFloat(
                                                                            (tableArray(groupBy).reduce((accumulator, object) => {return isNaN(accumulator + object.total_spend) ? 0 : accumulator + object.total_spend}, 0) * 1000) /
                                                                            tableArray(groupBy).reduce((accumulator, object) => {return accumulator + object.impressions}, 0)
                                                                            ).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }</span>
                                                                </th>
                                                            </tr>
                                                        }
                                                        {/* <tr>
                                                            {
                                                                insights.headings.map((item, index) => <th className={index != 0 && "text-right" }>{item}</th>)
                                                            }
                                                        </tr> */}
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableArray(groupBy).sortBy(tableSortBy, isTableSortDescending).map((item, index) =>
                                                                <tr key={index}>
                                                                    <td>{item.id}</td>
                                                                    <td className="text-right">{item.impressions.toLocaleString()}</td>
                                                                    <td className="text-right">{item.clicks.toLocaleString()}</td>
                                                                    <td className="text-right">{`${(item.clicks == 0 ? 0 : (item.clicks / item.impressions) * 100).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}%`}</td>
                                                                    <td className={`${isColumnMediaECPCVisible ? "" : "d-none"} text-right`}>{`$${
                                                                        isNaN(item.media_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                        ? "0.00"
                                                                        : item.media_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }`}</td>
                                                                    <td className={`${isColumnDataSpendVisible ? "" : "d-none"} data-spend text-right`}>{`$${
                                                                        isNaN(item.data_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                        ? "0.00"
                                                                        : item.data_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }`}</td>
                                                                    <td className="text-right">{`$${
                                                                        isNaN(item.total_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                        ? "0.00"
                                                                        : item.total_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }`}</td>
                                                                    <td className="d-none text-right">{`$${
                                                                        isNaN(item.yesterday_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                        ? "0.00"
                                                                        : item.yesterday_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }`}</td>
                                                                    <td className={`${isColumnMediaECPCVisible ? "" : "d-none"} text-right`}>{`$${
                                                                        isNaN(item.media_ecpc.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                        ? "0.00"
                                                                        : item.media_ecpc.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }`}</td>
                                                                    <td className={`${isColumnMediaECPMVisible ? "" : "d-none"} text-right`}>{`$${
                                                                        isNaN(item.media_ecpm.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                        ? "0.00"
                                                                        : item.media_ecpm.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }`}</td>
                                                                    <td className="text-right">{`$${
                                                                        //ECPC = spend / clicks
                                                                        isNaN((item.total_spend / item.clicks).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                        ? "0.00"
                                                                        : (item.total_spend / item.clicks).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }`}</td>
                                                                    <td className="text-right">{`$${
                                                                        //ECPM = ( spend x 1,000 ) / impressions
                                                                        isNaN(((item.total_spend * 1000) / item.impressions).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                                                                        ? "0.00"
                                                                        : ((item.total_spend * 1000) / item.impressions).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                    }`}</td>
                                                                </tr>
                                                        )}
                                                        {
                                                            // insights.rows.map(row => 
                                                            //     <tr>
                                                            //         {
                                                            //             Array.isArray(row) && row.map((item, index) => <td className={index != 0 && "text-right" }>{item}</td>)
                                                            //         }
                                                            //     </tr>
                                                            // )
                                                        }
                                                    </tbody>
                                                </table>
                                        }
                                    </>
                                : <></>
                            }
                        </div>
                        {
                            <div className="pagination">
                                {
                                    countTableRows > tableRowsPerPage &&
                                    <>
                                        <button type="button" className={`btn btn-primary btn-xs btn-pagination ${paginationOffset < tableRowsPerPage ? "disabled" : ""}`} onClick={() => publisherPrevPage()}><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                                        <button type="button" className={`btn btn-primary btn-xs btn-pagination mr-2 ${tableCurrentPage >= tableTotalPage ? "disabled" : ""}`} onClick={() => publisherNextPage()}><i className="fa fa-chevron-right" aria-hidden="true"></i></button>
                                        
                                        <div className="pagination-info">
                                            Page {tableCurrentPage} of {tableTotalPage}
                                        </div>
                                    </>
                                }
                            </div>
                        }

                                </Tab>

                                {/* REALTIME */}
                                <Tab eventKey="realtime" title="Real Time">
                                    {
                                        // Commented this out temporarily while trying to finish realtime with new version of getting data from supernova for older dates
                                        isRealtimeLoaded ?
                                            <table id="primary-report-table" className="home primary fixed-header has-hover">
                                                <thead>
                                                    {
                                                        <tr>
                                                            <th className={tableSortBy == "id" ? "active" : ""} onClick={() => {setTableSortBy("id"); tableSortBy == "id" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                                <span className="label">Date {tableSortBy == "id" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                <span className="badge hidden"></span>
                                                            </th>
                                                            <th className={`${tableSortBy == "id" ? "active" : ""} text-right`} onClick={() => {setTableSortBy("id"); tableSortBy == "id" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                                <span className="label">Impressions {tableSortBy == "id" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                <span className="badge hidden"></span>
                                                            </th>
                                                            <th className={`${tableSortBy == "id" ? "active" : ""} text-right`} onClick={() => {setTableSortBy("id"); tableSortBy == "id" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                                <span className="label">Clicks {tableSortBy == "id" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                <span className="badge hidden"></span>
                                                            </th>
                                                            <th className={`${tableSortBy == "id" ? "active" : ""} text-right`} onClick={() => {setTableSortBy("id"); tableSortBy == "id" && setIsTableSortDescending(!isTableSortDescending); }}>
                                                                <span className="label">CTR {tableSortBy == "id" && <i className={`fa ${isTableSortDescending ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden='true'></i>}</span>
                                                                <span className="badge hidden"></span>
                                                            </th>
                                                        </tr>
                                                    }
                                                </thead>
                                                <tbody>
                                                    {
                                                        tableArray(groupBy).sortBy(tableSortBy, isTableSortDescending).reverse().map((item, index) =>
                                                            <tr key={index}>
                                                                <td>{item.date}</td>
                                                                {/* Note We only add the realtime on the latest clicks and impressions*/}
                                                                <td className="text-right">
                                                                    {/* {item.impressions.toLocaleString()} */}
                                                                    {
                                                                        item.impressions + 
                                                                        (
                                                                            (   logSupernova?.items?.length > 0 && 
                                                                                moment().format('YYYY-MM-DD') == moment(item.date).format('YYYY-MM-DD')
                                                                            ) ? 
                                                                            getAdditionalImpressionsToday(item.date) 
                                                                            : 0
                                                                        ) 
                                                                    }
                                                                </td>
                                                                <td className="text-right">
                                                                    {
                                                                        item.clicks + 
                                                                        (
                                                                            (   logSupernova?.items?.length > 0 && 
                                                                                moment().format('YYYY-MM-DD') == moment(item.date).format('YYYY-MM-DD')
                                                                            ) ? 
                                                                            getAdditionalClicksToday(item.date) 
                                                                            : 0
                                                                        ) 
                                                                    }
                                                                </td>

                                                                <td className="text-right">{`${(item.clicks == 0 ? 0 : (item.clicks / item.impressions) * 100).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}%`}</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                            // Commented this out temporarily while trying to finish realtime with new version of getting data from supernova for older dates
                                            :
                                            <>Loading...</>

                                    }
                                </Tab>

                                {/* AI TAB */}
                                <Tab 
                                    eventKey="ai" 
                                    title="Supernova AI" 
                                    disabled={showAITab}
                                    >
                                    <div className="supernova-ai-wrapper">
                                        {/* ESTIMATED REACH */}
                                        <div className="total-reach-wrapper">
                                        {
                                            totalReach > 0 &&
                                            <>
                                            <div className="title">Estimated Reach:</div>
                                            <div className="value">
                                            {totalReach.toLocaleString()}
                                            </div>
                                            </>
                                        }
                                        </div>

                                        {/* GLOBE */}
                                        <GlobePrototype globeData={globeData} />


                                        {/* AI OUTPUT */}
                                        <div className='ai-response' id="chat-container">
                                        {
                                            aiMessageLog.map((message, index) => (
                                                <div key={index} className='chat-container'>{message}</div>
                                            ))
                                        }
                                        {
                                            //////////////////////////////////////////////////////
                                            // PROCEED BUTTON WHEN:
                                            // - Confirming the suggested Audience Description
                                            // - Confirming the suggested AdGroup Name
                                            //////////////////////////////////////////////////////
                                                ai.response &&
                                                ai.ask_to_proceed &&
                                                ai.thread_id != null &&
                                                currentStep !== "CREATE_ADGROUP" &&
                                                currentStep !== "NEW_QUERY" &&
                                                currentStep !== "GEO_TARGETING" &&
                                                !ai.response.toString().includes("Please provide additional details") &&
                                                !ai.response.toString().includes("No audience found") &&
                                                !ai.response.toString().includes("Unexpected token") &&
                                                !ai.response.toString().includes("something went wrong") &&
                                                (lastMessageFromAI.length >= (ai?.response?.length || 0) || lastMessageFromAI.includes("continue.")) && 
                                                <button className='chat-gpt-prompt' disabled={ai?.loading === true} onClick={() => {onClickProceed()}}>
                                                    Proceed <i className="fas fa-caret-right"></i>
                                                </button>
                                        }
                                    </div>
                                    {
                                        //////////////////////////
                                        // AI INPUT 
                                        //////////////////////////
                                        <>
                                            {
                                                <textarea className={`user-message ${currentStep != "CREATE_ADGROUP" ? "" : "disabled"}`} value={userMessage} onChange={(e) => setUserMessage(e.target.value == "" ? " " : e.target.value) } placeholder={currentStep == "LOADING" ? "" : 'Enter your message here...'}/>
                                            }
                                            {
                                                // Hide submit button on the following:
                                                // - When processing
                                                // - When being asked to confirm adgroup name
                                                // - When adgroup is already being processed
                                                !ai?.response?.toString().includes("Cannot read property") &&
                                                <button className={`${isAiButtonEnabled() ? "" : "disabled"} ${currentStep == "LOADING" ? "full-opacity" : ""}`} onClick={() => {
                                                    currentStep == "GEO_TARGETING" 
                                                    ? submitTargetLocation()
                                                    : askAI()
                                                }
                                                }>
                                                    {
                                                        currentStep == "LOADING" && aiRespondedWithError() == false ?
                                                        <>
                                                            Processing <div className="dots"></div>
                                                        </>
                                                        :
                                                        <>
                                                            Send <i className="fas fa-paper-plane"></i>
                                                        </>
                                                    }
                                                    </button>
                                            }
                                            {
                                                //////////////////////////////////////////////////////
                                                // Show start over button when:
                                                // A. Current step is NOT the begining
                                                // B. Current step is NOT yet in the adgroup creation
                                                // C. While process is loading (So users can cancel when it is taking so long)
                                                //////////////////////////////////////////////////////
                                                // (
                                                //     // currentStep != "NEW_QUERY" &&               // A.
                                                //     // currentStep != "PROCESS_COMPLETE"           // B.
                                                // ) 
                                                currentStep != "NEW_QUERY" &&
                                                // || 
                                                // (
                                                //     currentStep == "LOADING"                    // C.
                                                // ) &&
                                                <button className="ai-restart-btn" onClick={() => startOverChat()}>Start Over</button>
                                            }
                                        </>
                                    }
                                    </div>
                                </Tab>
                                
                            </Tabs>
                        </div>
                    </div>
                    
                    {/* CHART */}
                    {/* <div className="chart-area">
                        <img src="/public/images/logo-text.png" height="35"></img>
                        <div className="title">Daily Stats</div>
                        <div className="subtitle">Spot changes or threats as soon as possible and react immediately</div>
                        <LifetimeValue entityKey={selectedEntityKey} entityId={selectedEntityId} startDate={startDate.format('YYYY-MM-DD')} endDate={endDate.format('YYYY-MM-DD')} additionalClass={publisherPanel != null || insightPanel != null ? "wide" : ""}/>
                    </div> */}
    

                            

                    {/* ENTITY COLUMNS */}
                    <div className="entity-column">
                        <div className="title-wrapper">
                            <div className="title">Campaigns</div>
                            <Select
                                onChange={(event) => {setCampaignMiniTotal(handleDropdownChange(event))}}
                                className="select-primary"
                                placeholder="Impressions"
                                options={[
                                    {label:"Impressions",       value:"impressions"},
                                    {label:"Clicks",            value:"clicks"},
                                    {label:"CTR",               value:"ctr"},
                                    {label:"Total Spend",       value:"spend"},
                                    {label:"eCPC",              value:"ecpc"},
                                    // {label:"Publisher",         value:"publisher"},
                                ]}
                                isClearable={false}
                                styles={styleSelectBasic} />
                        </div>
                        <div className="controls">
                            <OverlayTrigger placement="top" content="" overlay={<Tooltip>New Campaign</Tooltip>}>
                                {/* <button className="add-entity" onClick={() => createCampaign("campaign", "create")}><i className="fa fa-plus" aria-hidden="true"></i></button> */}
                                <button className="add-entity" onClick={() => setIsCampaignOptionVisible(true)}><i className="fa fa-plus" aria-hidden="true"></i></button>
                            </OverlayTrigger>
                            <div className="sort-entity-wrapper">
                            <Dropdown>
                                <OverlayTrigger placement="top" content="" overlay={<Tooltip>Sort</Tooltip>}>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                                    </Dropdown.Toggle>
                                </OverlayTrigger>

                                <Dropdown.Menu>
                                    <Dropdown.Item className={`${sortCampaignBy == "name" ? "active" : ""}`} onClick={(e) => setSortCampaignBy("name")}>Name</Dropdown.Item>
                                    <Dropdown.Item className={`${sortCampaignBy == "type" ? "active" : ""}`} onClick={(e) => setSortCampaignBy("type")}>Type</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            </div>
                            <div className="flight-date">
                                {
                                    selectedSupernovaCampaignId != null &&
                                    campaigns?.items?.distinctBy("supernovaCampaignId")?.filter(item => item.id == selectedSupernovaCampaignId).map((campaign, index) => 
                                        campaign.budgets && campaign.budgets.length > 0 &&
                                        <React.Fragment key={`${index}-${campaign.supernovaCampaignId}`}>
                                            <b><i className="fa fa-calendar-check-o" aria-hidden="true"></i></b> &nbsp;
                                            {
                                                //Minimum start date in the selected budget
                                                moment(moment.min(campaign.budgets.filter(item => item != null).map(d => {return moment(d.startDate.toString())} ))).format("MMM D, YYYY")
                                            }
                                             &nbsp;to&nbsp;
                                            {
                                                //Maximum end date in the selected budget
                                                moment(moment.min(campaign.budgets.filter(item => item != null).map(d => {return moment(d.endDate.toString())} ))).format("MMM D, YYYY")
                                            }
                                        </React.Fragment>
                                    )
                                }
                            </div>
                        </div>
                        
                        {/* ACTIVE CAMPAIGNS */}
                        {
                            listCampaignType.map((campaignType, index) => <React.Fragment key={index}>
                                <div className={`li-grouping ${campaigns.items && campaigns.items.filter(item => item.type == campaignType.value).length == 0 ? "d-none" : ""}`}>{campaignType.label}</div>
                                <ul className='campaign-list active'>
                                    {
                                        selectedEntityKey != null && !duplicates.items && campaigns.isDone != true 
                                        ? <li><i className="fa fa-spinner fa-spin fast-spin"></i> Loading...</li>
                                        :
                                        campaigns.items && 
                                        campaigns.inactive && 
                                        campaigns?.items?.distinctBy("supernovaCampaignId")?.filter(item => item.type == campaignType.value).sortBy(sortCampaignBy).map((campaign, index) =>
                                            !isCampaignInactive(campaign.supernovaCampaignId) && 
                                            !isDuplicateCampaign(campaign.supernovaCampaignId) && 
                                            <React.Fragment key={index}>
                                                <OverlayTrigger placement="right" overlay={<Tooltip><b>{campaign.name}</b></Tooltip>} key={index}>
                                                    <li className={`${campaign.supernovaCampaignId == selectedSupernovaCampaignId ? 'selected' : ''} ${isHiddenForTesting(campaign.supernovaCampaignId) ? "d-none" : ""} ${campaigns && campaigns.inactive && campaigns.items && !isCampaignInactive(campaign.supernovaCampaignId) ? 'active' : 'inactive'}`} 
                                                        key={`${index}-${campaign.supernovaCampaignId}`}
                                                        onClick={() => {selectedSupernovaCampaignId == campaign.supernovaCampaignId ? showCampaignsPanel() : showAdgroupPanel(campaign.supernovaCampaignId)}}>
                                                        <div className="gear-wrapper" onClick={(e) => {e.stopPropagation(); editCampaign("campaign", "edit", campaign.supernovaCampaignId, campaign.type)}}>
                                                        <i className="fa fa-cog" aria-hidden="true"></i>
                                                        </div>
                                                        <div className="toggle-status-wrapper">
                                                                {
                                                                    // campaigns && campaigns.inactive && 
                                                                    campaigns && campaigns.inactive && 
                                                                    <Toggle className={`${campaigns?.isDone == false ? "disabled" : ""}`} data-cid={campaign.supernovaCampaignId} defaultChecked={!isCampaignInactive(campaign.supernovaCampaignId)} onChange={(e) => toggleCampaignState(e)}/> 
                                                                }
                                                            </div>
                                                            
                                                                <div className="menu has-toggle-switch">
                                                                    <span className={`title`}><i className={`fa fa-circle ${campaign.type}`} aria-hidden="true"></i>{campaign.name}</span>
                                                                    {
                                                                        campaignMiniTotal == "publisher" ?
                                                                        <React.Fragment key={campaign.supernovaCampaignId}>
                                                                                {
                                                                                    publishers.total_by_campaign && 
                                                                                    publishers.total_by_campaign.filter(publisher => publisher._id == campaign.supernovaCampaignId).length > 0 ? 
                                                                                    <React.Fragment key={campaign.supernovaCampaignId}>
                                                                                    {
                                                                                        publishers.total_by_campaign.filter(publisher => publisher._id == campaign.supernovaCampaignId)[0].total == "0" ? 
                                                                                        <span className="badge empty">
                                                                                            0
                                                                                        </span>
                                                                                        :
                                                                                        <span className="badge clickable">
                                                                                            {publishers.total_by_campaign.filter(publisher => publisher._id == campaign.supernovaCampaignId)[0].total.toLocaleString()}
                                                                                        </span>
                                                                                    }
                                                                                    </React.Fragment>
                                                                                    :
                                                                                    <span className="skeleton-box"></span>
                                                                                }
                                                                            </React.Fragment>
                                                                        :   campaignMiniTotal == "impressions"  || 
                                                                            campaignMiniTotal == "clicks" ?
                                                                            <React.Fragment key={campaign.supernovaCampaignId}>
                                                                                {
                                                                                    <span className={`badge ${getMiniInsightValue(campaign.supernovaCampaignId, "campaign", campaignMiniTotal) == 0 ? "empty" : "clickable"}`}>
                                                                                        {
                                                                                            statistics.isCampaignDone &&
                                                                                            getMiniInsightValue(campaign.supernovaCampaignId, "campaign", campaignMiniTotal) != undefined && getMiniInsightValue(campaign.supernovaCampaignId, "campaign", campaignMiniTotal).toLocaleString()
                                                                                        }
                                                                                    </span>
                                                                                }
                                                                            </React.Fragment>
                                                                        :   campaignMiniTotal == "spend" ?
                                                                            <React.Fragment key={campaign.supernovaCampaignId}>
                                                                                {
                                                                                    getMiniInsightValue(campaign.supernovaCampaignId, "campaign", campaignMiniTotal) == 0 
                                                                                    ?   <span className="badge empty">$0.00</span>
                                                                                    :   <span className="badge clickable">
                                                                                            ${
                                                                                                statistics.isCampaignDone &&
                                                                                                parseFloat(getMiniInsightValue(campaign.supernovaCampaignId, "campaign", campaignMiniTotal)).toLocaleString(undefined, {
                                                                                                    minimumFractionDigits: 2,
                                                                                                    maximumFractionDigits: 2
                                                                                                })
                                                                                            }
                                                                                        </span>
                                                                                }
                                                                            </React.Fragment>
                                                                        :   campaignMiniTotal == "ctr" ?
                                                                            <React.Fragment key={campaign.supernovaCampaignId}>
                                                                                {
                                                                                    getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "clicks") == 0 || getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "clicks") == undefined?
                                                                                    <span className="badge empty">
                                                                                        {
                                                                                            `0.00%`
                                                                                        }
                                                                                    </span>
                                                                                    :
                                                                                    <span className="badge clickable">
                                                                                        {
                                                                                            `
                                                                                            ${
                                                                                                getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "clicks") > getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "impressions")
                                                                                                ? "0.00"
                                                                                                : parseFloat(getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "clicks") / getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "impressions")).toFixed(2) 
                                                                                            }
                                                                                            %`
                                                                                        }
                                                                                    </span>
                                                                                }
                                                                            </React.Fragment>
                                                                        :   campaignMiniTotal == "ecpc" ?
                                                                            <React.Fragment key={campaign.supernovaCampaignId}>
                                                                                {
                                                                                    getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "spend") == 0 || getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "spend") == undefined?
                                                                                    <span className="badge empty">
                                                                                        {
                                                                                            `$0.00`
                                                                                        }
                                                                                    </span>
                                                                                    :
                                                                                    <span className="badge clickable">
                                                                                        {
                                                                                            `$
                                                                                            ${
                                                                                                getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "spend") > getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "clicks") 
                                                                                                ? "0.00"
                                                                                                : parseFloat(getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "spend") / getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "clicks") ).toFixed(2) 
                                                                                            }`
                                                                                        }
                                                                                    </span>
                                                                                }
                                                                            </React.Fragment>
                                                                        : <></>
                                                                    }
                                                                </div>
                                                    </li>
                                                </OverlayTrigger>
                                                {
                                                    //Add the duplicated Campaign on top if there is any 
                                                    hasDuplicateCampaign(campaign.supernovaCampaignId) &&
                                                        campaigns.items.filter(item => item.supernovaCampaignId == getIdOfDuplicateCampaign(campaign.supernovaCampaignId)).map((campaign, index) =>
                                                        <OverlayTrigger placement="right" overlay={<Tooltip><b>{campaign.name}</b></Tooltip>} key={index}>
                                                            <li className={`${campaign.supernovaCampaignId == selectedSupernovaCampaignId ? 'selected' : ''} ${isHiddenForTesting(campaign.supernovaCampaignId) ? "d-none" : ""}  active`} 
                                                                key={`${index}-${campaign.supernovaCampaignId}`}
                                                                onClick={() => {selectedSupernovaCampaignId == campaign.supernovaCampaignId ? showCampaignsPanel() : showAdgroupPanel(campaign.supernovaCampaignId)}}>
                                                                <div className="gear-wrapper" onClick={(e) => {e.stopPropagation(); editCampaign("campaign", "edit", campaign.supernovaCampaignId, campaign.type)}}>
                                                                <i className="fa fa-cog" aria-hidden="true"></i>
                                                                </div>
                                                                <div className="toggle-status-wrapper">
                                                                        {
                                                                            // campaigns && campaigns.inactive && 
                                                                            campaigns && campaigns.inactive && 
                                                                            <Toggle className={`${campaigns?.isDone == false ? "disabled" : ""}`} data-cid={campaign.supernovaCampaignId} defaultChecked={!isCampaignInactive(campaign.supernovaCampaignId)} onChange={(e) => toggleCampaignState(e)}/> 
                                                                        }
                                                                    </div>
                                                                    
                                                                        <div className="menu has-toggle-switch">
                                                                            <span className={`title`}>
                                                                                <img className="arrow-indent" src="/public/images/arrow.svg"/> {campaign.name}
                                                                            </span>
                                                                            {
                                                                                campaignMiniTotal == "publisher" ?
                                                                                <React.Fragment key={campaign.supernovaCampaignId}>
                                                                                        {
                                                                                            publishers.total_by_campaign && 
                                                                                            publishers.total_by_campaign.filter(publisher => publisher._id == campaign.supernovaCampaignId).length > 0 ? 
                                                                                            <React.Fragment key={campaign.supernovaCampaignId}>
                                                                                            {
                                                                                                publishers.total_by_campaign.filter(publisher => publisher._id == campaign.supernovaCampaignId)[0].total == "0" ? 
                                                                                                <span className="badge empty">
                                                                                                    0
                                                                                                </span>
                                                                                                :
                                                                                                <span className="badge clickable">
                                                                                                    {publishers.total_by_campaign.filter(publisher => publisher._id == campaign.supernovaCampaignId)[0].total.toLocaleString()}
                                                                                                </span>
                                                                                            }
                                                                                            </React.Fragment>
                                                                                            :
                                                                                            <span className="skeleton-box"></span>
                                                                                        }
                                                                                    </React.Fragment>
                                                                                :   campaignMiniTotal == "impressions"  || 
                                                                                    campaignMiniTotal == "clicks" ?
                                                                                    <React.Fragment key={campaign.supernovaCampaignId}>
                                                                                        {
                                                                                            <span className={`badge ${getMiniInsightValue(campaign.supernovaCampaignId, "campaign", campaignMiniTotal) == 0 ? "empty" : "clickable"}`}>
                                                                                                {
                                                                                                    statistics.isCampaignDone &&
                                                                                                    getMiniInsightValue(campaign.supernovaCampaignId, "campaign", campaignMiniTotal) != undefined && getMiniInsightValue(campaign.supernovaCampaignId, "campaign", campaignMiniTotal).toLocaleString()
                                                                                                }
                                                                                            </span>
                                                                                        }
                                                                                    </React.Fragment>
                                                                                :   campaignMiniTotal == "spend" ?
                                                                                    <React.Fragment key={campaign.supernovaCampaignId}>
                                                                                        {
                                                                                            getMiniInsightValue(campaign.supernovaCampaignId, "campaign", campaignMiniTotal) == 0 
                                                                                            ?   <span className="badge empty">$0.00</span>
                                                                                            :   <span className="badge clickable">
                                                                                                    ${
                                                                                                        statistics.isCampaignDone &&
                                                                                                        parseFloat(getMiniInsightValue(campaign.supernovaCampaignId, "campaign", campaignMiniTotal)).toLocaleString(undefined, {
                                                                                                            minimumFractionDigits: 2,
                                                                                                            maximumFractionDigits: 2
                                                                                                        })
                                                                                                    }
                                                                                                </span>
                                                                                        }
                                                                                    </React.Fragment>
                                                                                :   campaignMiniTotal == "ctr" ?
                                                                                    <React.Fragment key={campaign.supernovaCampaignId}>
                                                                                        {
                                                                                            getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "clicks") == 0 || getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "clicks") == undefined?
                                                                                            <span className="badge empty">
                                                                                                {
                                                                                                    `0.00%`
                                                                                                }
                                                                                            </span>
                                                                                            :
                                                                                            <span className="badge clickable">
                                                                                                {
                                                                                                    `
                                                                                                    ${
                                                                                                        getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "clicks") > getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "impressions")
                                                                                                        ? "0.00"
                                                                                                        : parseFloat(getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "clicks") / getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "impressions")).toFixed(2) 
                                                                                                    }
                                                                                                    %`
                                                                                                }
                                                                                            </span>
                                                                                        }
                                                                                    </React.Fragment>
                                                                                :   campaignMiniTotal == "ecpc" ?
                                                                                    <React.Fragment key={campaign.supernovaCampaignId}>
                                                                                        {
                                                                                            getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "spend") == 0 || getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "spend") == undefined?
                                                                                            <span className="badge empty">
                                                                                                {
                                                                                                    `$0.00`
                                                                                                }
                                                                                            </span>
                                                                                            :
                                                                                            <span className="badge clickable">
                                                                                                {
                                                                                                    `$
                                                                                                    ${
                                                                                                        getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "spend") > getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "clicks") 
                                                                                                        ? "0.00"
                                                                                                        : parseFloat(getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "spend") / getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "clicks") ).toFixed(2) 
                                                                                                    }`
                                                                                                }
                                                                                            </span>
                                                                                        }
                                                                                    </React.Fragment>
                                                                                : <></>
                                                                            }
                                                                        </div>
                                                            </li>
                                                        </OverlayTrigger>
                                                        )
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                </ul>
                            </React.Fragment>
                            )
                        }
                        {/* INACTIVE CAMPAIGNS */}
                        <ul className='campaign-list inactive'>
                            {
                                selectedEntityKey != null && !duplicates.items && campaigns.isDone != true ? <li><i className="fa fa-spinner fa-spin fast-spin"></i> Loading...</li>
                                :
                                campaigns.items && 
                                campaigns.inactive &&
                                campaigns?.items?.distinctBy("supernovaCampaignId")?.sortBy(sortCampaignBy).map((campaign, index) =>
                                    isCampaignInactive(campaign.supernovaCampaignId) && 
                                    !isDuplicateCampaign(campaign.supernovaCampaignId) && 
                                    <React.Fragment key={`inactive-${index}`}>
                                        <OverlayTrigger placement="right" overlay={<Tooltip><b>{campaign.name}</b></Tooltip>}>
                                        <li className={`${campaign.supernovaCampaignId == selectedSupernovaCampaignId ? 'selected' : ''} ${campaigns && campaigns.inactive && campaigns.items && !isCampaignInactive(campaign.supernovaCampaignId) ? 'active' : 'inactive'}`} 
                                            key={`${index}-${campaign.supernovaCampaignId}`}
                                            onClick={() => {selectedSupernovaCampaignId == campaign.supernovaCampaignId ? showCampaignsPanel() : showAdgroupPanel(campaign.supernovaCampaignId)}}>
                                            <div className="gear-wrapper" onClick={(e) => {e.stopPropagation(); editCampaign("campaign", "edit", campaign.supernovaCampaignId, campaign.type)}}>
                                                <i className="fa fa-cog" aria-hidden="true"></i>
                                            </div>
                                            <div className="toggle-status-wrapper">
                                                    {
                                                        // campaigns && campaigns.inactive && 
                                                        campaigns && campaigns.inactive && 
                                                        <Toggle className={`${campaigns?.isDone == false ? "disabled" : ""}`} data-cid={campaign.supernovaCampaignId} defaultChecked={!isCampaignInactive(campaign.supernovaCampaignId)} onChange={(e) => toggleCampaignState(e)}/> 
                                                    }
                                                </div>
                                                <div className="menu has-toggle-switch">
                                                    <span className={`title`}><i className={`fa fa-circle ${campaign.type}`} aria-hidden="true"></i>{campaign.name}</span>
                                                    {
                                                        campaignMiniTotal == "publisher" ?
                                                        <React.Fragment key={campaign.supernovaCampaignId}>
                                                                {
                                                                    publishers.total_by_campaign && 
                                                                    publishers.total_by_campaign.filter(publisher => publisher._id == campaign.supernovaCampaignId).length > 0 ? 
                                                                    <React.Fragment key={campaign.supernovaCampaignId}>
                                                                    {
                                                                        publishers.total_by_campaign.filter(publisher => publisher._id == campaign.supernovaCampaignId)[0].total == "0" ? 
                                                                        <span className="badge empty">
                                                                            0
                                                                        </span>
                                                                        :
                                                                        <span className="badge clickable">
                                                                            {publishers.total_by_campaign.filter(publisher => publisher._id == campaign.supernovaCampaignId)[0].total.toLocaleString()}
                                                                        </span>
                                                                    }
                                                                    </React.Fragment>
                                                                    :
                                                                    <span className="skeleton-box"></span>
                                                                }
                                                            </React.Fragment>
                                                        :   campaignMiniTotal == "impressions"  || 
                                                            campaignMiniTotal == "clicks" ?
                                                            <React.Fragment key={campaign.supernovaCampaignId}>
                                                                {
                                                                    <span className={`badge ${getMiniInsightValue(campaign.supernovaCampaignId, "campaign", campaignMiniTotal) == 0 ? "empty" : "clickable"}`}>
                                                                        {
                                                                            statistics.isCampaignDone &&
                                                                            getMiniInsightValue(campaign.supernovaCampaignId, "campaign", campaignMiniTotal) !== undefined &&
                                                                            getMiniInsightValue(campaign.supernovaCampaignId, "campaign", campaignMiniTotal).toLocaleString()
                                                                        }
                                                                    </span>
                                                                }
                                                            </React.Fragment>
                                                        :   campaignMiniTotal == "spend" ?
                                                            <React.Fragment key={campaign.supernovaCampaignId}>
                                                                {
                                                                    getMiniInsightValue(campaign.supernovaCampaignId, "campaign", campaignMiniTotal) == 0 
                                                                    ?   <span className="badge empty">$0.00</span>
                                                                    :   <span className="badge clickable">
                                                                            ${
                                                                                statistics.isCampaignDone &&
                                                                                parseFloat(getMiniInsightValue(campaign.supernovaCampaignId, "campaign", campaignMiniTotal)) !== undefined &&
                                                                                parseFloat(getMiniInsightValue(campaign.supernovaCampaignId, "campaign", campaignMiniTotal)).toLocaleString(undefined, {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2
                                                                                })
                                                                            }
                                                                        </span>
                                                                }
                                                            </React.Fragment>
                                                        :   campaignMiniTotal == "ctr" ?
                                                            <React.Fragment key={campaign.supernovaCampaignId}>
                                                                {
                                                                    getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "clicks") == 0 || getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "clicks") == undefined?
                                                                    <span className="badge empty">
                                                                        {
                                                                            `0.00%`
                                                                        }
                                                                    </span>
                                                                    :
                                                                    <span className="badge clickable">
                                                                        {
                                                                            `
                                                                            ${
                                                                                getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "clicks") > getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "impressions")
                                                                                ? "0.00"
                                                                                : parseFloat(getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "clicks") / getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "impressions")).toFixed(2) 
                                                                            }
                                                                            %`
                                                                        }
                                                                    </span>
                                                                }
                                                            </React.Fragment>
                                                        :   campaignMiniTotal == "ecpc" ?
                                                            <React.Fragment key={campaign.supernovaCampaignId}>
                                                                {
                                                                    getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "spend") == 0 || getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "spend") == undefined?
                                                                    <span className="badge empty">
                                                                        {
                                                                            `$0.00`
                                                                        }
                                                                    </span>
                                                                    :
                                                                    <span className="badge clickable">
                                                                        {
                                                                            `$
                                                                            ${
                                                                                getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "spend") > getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "clicks") 
                                                                                ? "0.00"
                                                                                : parseFloat(getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "spend") / getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "clicks") ).toFixed(2) 
                                                                            }`
                                                                        }
                                                                    </span>
                                                                }
                                                            </React.Fragment>
                                                        : <></>
                                                    }
                                                </div>
                                        </li>
                                        </OverlayTrigger>
                                        {
                                            //Add the duplicated Campaign on top if there is any 
                                            hasDuplicateCampaign(campaign.supernovaCampaignId) &&
                                                campaigns.items.filter(item => item.supernovaCampaignId == getIdOfDuplicateCampaign(campaign.supernovaCampaignId)).map((campaign, index) =>
                                                <OverlayTrigger placement="right" overlay={<Tooltip><b>{campaign.name}</b></Tooltip>}>
                                                    <li className={`${campaign.supernovaCampaignId == selectedSupernovaCampaignId ? 'selected' : ''} inactive`} 
                                                        key={`${index}-${campaign.supernovaCampaignId}`}
                                                        onClick={() => {selectedSupernovaCampaignId == campaign.supernovaCampaignId ? showCampaignsPanel() : showAdgroupPanel(campaign.supernovaCampaignId)}}>
                                                        <div className="gear-wrapper" onClick={(e) => {e.stopPropagation(); editCampaign("campaign", "edit", campaign.supernovaCampaignId, campaign.type)}}>
                                                            <i className="fa fa-cog" aria-hidden="true"></i>
                                                        </div>
                                                        <div className="toggle-status-wrapper">
                                                                {
                                                                    // campaigns && campaigns.inactive && 
                                                                    campaigns && campaigns.inactive && 
                                                                    <Toggle className={`${campaigns?.isDone == false ? "disabled" : ""}`} data-cid={campaign.supernovaCampaignId} defaultChecked={!isCampaignInactive(campaign.supernovaCampaignId)} onChange={(e) => toggleCampaignState(e)}/> 
                                                                }
                                                            </div>
                                                            <div className="menu has-toggle-switch">
                                                                <span className={`title`}>
                                                                    <img className="arrow-indent" src="/public/images/arrow.svg"/> {campaign.name}
                                                                </span>
                                                                {
                                                                    campaignMiniTotal == "publisher" ?
                                                                    <React.Fragment key={campaign.supernovaCampaignId}>
                                                                            {
                                                                                publishers.total_by_campaign && 
                                                                                publishers.total_by_campaign.filter(publisher => publisher._id == campaign.supernovaCampaignId).length > 0 ? 
                                                                                <React.Fragment key={campaign.supernovaCampaignId}>
                                                                                {
                                                                                    publishers.total_by_campaign.filter(publisher => publisher._id == campaign.supernovaCampaignId)[0].total == "0" ? 
                                                                                    <span className="badge empty">
                                                                                        0
                                                                                    </span>
                                                                                    :
                                                                                    <span className="badge clickable">
                                                                                        {publishers.total_by_campaign.filter(publisher => publisher._id == campaign.supernovaCampaignId)[0].total.toLocaleString()}
                                                                                    </span>
                                                                                }
                                                                                </React.Fragment>
                                                                                :
                                                                                <span className="skeleton-box"></span>
                                                                            }
                                                                        </React.Fragment>
                                                                    :   campaignMiniTotal == "impressions"  || 
                                                                        campaignMiniTotal == "clicks" ?
                                                                        <React.Fragment key={campaign.supernovaCampaignId}>
                                                                            {
                                                                                <span className={`badge ${getMiniInsightValue(campaign.supernovaCampaignId, "campaign", campaignMiniTotal) == 0 ? "empty" : "clickable"}`}>
                                                                                    {
                                                                                        statistics.isCampaignDone &&
                                                                                        getMiniInsightValue(campaign.supernovaCampaignId, "campaign", campaignMiniTotal) !== undefined &&
                                                                                        getMiniInsightValue(campaign.supernovaCampaignId, "campaign", campaignMiniTotal).toLocaleString()
                                                                                    }
                                                                                </span>
                                                                            }
                                                                        </React.Fragment>
                                                                    :   campaignMiniTotal == "spend" ?
                                                                        <React.Fragment key={campaign.supernovaCampaignId}>
                                                                            {
                                                                                getMiniInsightValue(campaign.supernovaCampaignId, "campaign", campaignMiniTotal) == 0 
                                                                                ?   <span className="badge empty">$0.00</span>
                                                                                :   <span className="badge clickable">
                                                                                        ${
                                                                                            statistics.isCampaignDone &&
                                                                                            parseFloat(getMiniInsightValue(campaign.supernovaCampaignId, "campaign", campaignMiniTotal)) !== undefined &&
                                                                                            parseFloat(getMiniInsightValue(campaign.supernovaCampaignId, "campaign", campaignMiniTotal)).toLocaleString(undefined, {
                                                                                                minimumFractionDigits: 2,
                                                                                                maximumFractionDigits: 2
                                                                                            })
                                                                                        }
                                                                                    </span>
                                                                            }
                                                                        </React.Fragment>
                                                                    :   campaignMiniTotal == "ctr" ?
                                                                        <React.Fragment key={campaign.supernovaCampaignId}>
                                                                            {
                                                                                getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "clicks") == 0 || getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "clicks") == undefined?
                                                                                <span className="badge empty">
                                                                                    {
                                                                                        `0.00%`
                                                                                    }
                                                                                </span>
                                                                                :
                                                                                <span className="badge clickable">
                                                                                    {
                                                                                        `
                                                                                        ${
                                                                                            getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "clicks") > getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "impressions")
                                                                                            ? "0.00"
                                                                                            : parseFloat(getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "clicks") / getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "impressions")).toFixed(2) 
                                                                                        }
                                                                                        %`
                                                                                    }
                                                                                </span>
                                                                            }
                                                                        </React.Fragment>
                                                                    :   campaignMiniTotal == "ecpc" ?
                                                                        <React.Fragment key={campaign.supernovaCampaignId}>
                                                                            {
                                                                                getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "spend") == 0 || getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "spend") == undefined?
                                                                                <span className="badge empty">
                                                                                    {
                                                                                        `$0.00`
                                                                                    }
                                                                                </span>
                                                                                :
                                                                                <span className="badge clickable">
                                                                                    {
                                                                                        `$
                                                                                        ${
                                                                                            getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "spend") > getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "clicks") 
                                                                                            ? "0.00"
                                                                                            : parseFloat(getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "spend") / getMiniInsightValue(campaign.supernovaCampaignId, "campaign", "clicks") ).toFixed(2) 
                                                                                        }`
                                                                                    }
                                                                                </span>
                                                                            }
                                                                        </React.Fragment>
                                                                    : <></>
                                                                }
                                                            </div>
                                                    </li>
                                                </OverlayTrigger>
                                                )
                                        }
                                    </React.Fragment>
                                )
                            }
                        </ul>
                    </div>
                    <div className="entity-column">
                        <div className="title-wrapper">
                            <div className="title">Ad Groups</div>
                            <Select
                                onChange={(event) => {setAdgroupMiniTotal(handleDropdownChange(event))}}
                                className="select-primary"
                                placeholder="Impressions"
                                options={[
                                    {label:"Impressions",       value:"impressions"},
                                    {label:"Clicks",            value:"clicks"},
                                    {label:"CTR",               value:"ctr"},
                                    {label:"Total Spend",       value:"spend"},
                                    {label:"eCPC",              value:"ecpc"},
                                    // {label:"Publisher",         value:"publisher"},
                                ]}
                                isClearable={false}
                                styles={styleSelectBasic} />
                        </div>
                        {
                            <div className="controls">
                                {
                                    isAdgroupFloatMenuVisible &&
                                    <div ref={ref} className="floating-menu">
                                        <div className="item" onClick={() => createAdgroup("adgroup", "CREATE_SINGLE")}>Single</div>
                                        {
                                            selectedNewCampaignType != "PUSH_NOTIFICATION" &&
                                            <div className="item" onClick={() => createAdgroup("adgroup", "CREATE_MULTIPLE")}>Multiple</div>
                                        }
                                    </div>
                                }
                                {
                                    selectedSupernovaCampaignId != null &&
                                    <OverlayTrigger placement="top" content="" overlay={<Tooltip>New Ad Group</Tooltip>}>
                                        <button className={`add-entity ${campaigns?.items?.find(item => item.supernovaCampaignId == selectedSupernovaCampaignId)?.budgets || "disabled"}`} onClick={() => {setIsAdgroupFloatMenuVisible(!isAdgroupFloatMenuVisible);}}><i className="fa fa-plus" aria-hidden="true"></i></button>
                                    </OverlayTrigger>
                                }
                                {
                                    selectedSupernovaCampaignId != null &&
                                    <div className="flight-date">
                                    {
                                        selectedAdgroup != null &&
                                        adgroups?.items?.distinctBy("supernovaAdgroupId")?.filter(item => item.id == selectedAdgroup).map((adgroup, index) => 
                                            <React.Fragment key={`${index}-${adgroup.supernovaAdgroupId}`}>
                                                {
                                                    /*
                                                    * Show date if there is start date.
                                                    * If the end date is null then only show start date.
                                                    */
                                                    adgroup.startDate != null &&
                                                    <>
                                                        <b><i className="fa fa-calendar-check-o" aria-hidden="true"></i></b> &nbsp;
                                                        {moment(adgroup.startDate).format("MMM D, YYYY")}
                                                        {
                                                            adgroup.endDate != null ?
                                                            <>
                                                                &nbsp;to&nbsp;
                                                                {moment(adgroup.endDate).format("MMM D, YYYY")}
                                                            </>
                                                            :
                                                            selectedSupernovaCampaignId != null &&
                                                            campaigns.items && 
                                                            campaigns.items.filter(item => item.supernovaCampaignId == selectedSupernovaCampaignId).map((campaign, index) => 
                                                                campaign.budgets && campaign.budgets.length > 0 &&
                                                                <React.Fragment key={`${index}-${campaign.supernovaCampaignId}`}>
                                                                    &nbsp;to&nbsp;
                                                                    {
                                                                        //Maximum end date in the selected budget
                                                                        moment(moment.min(campaign.budgets.map(d => {return moment(d?.endDate.toString())} ))).format("MMM D, YYYY")
                                                                    }
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </>
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    </div>
                                }
                            </div>
                        }
                        {/* ACTIVE ADGROUPS */}
                        <ul className='adgroup-list active'>
                            {
                                selectedEntityKey != null && adgroups.isDone != true ? <li><i className="fa fa-spinner fa-spin fast-spin"></i> Loading...</li>
                                :
                                selectedEntityKey != null && adgroups.items && adgroups.items.length > 0 ?
                                    adgroups?.items && adgroups?.items?.distinctBy("supernovaAdgroupId")?.map((adgroup, index) =>
                                        (adgroup.state == "ACTIVE" || adgroup.status == 9) &&
                                        <OverlayTrigger placement="right" overlay={<Tooltip><b>{adgroup.name}</b></Tooltip>} key={index}>
                                        <li key={`list-adgroup-${index}`} className={`${adgroup.archived ? 'd-none' : ''} ${adgroup.supernovaAdgroupId == selectedAdgroup ? 'selected' : ''} ${(adgroup.state == "ACTIVE" || adgroup.status == 9) ? "active" : "inactive"}`} >
                                            <div className="gear-wrapper" onClick={(e) => {e.stopPropagation(); editAdgroup("adgroup", "edit", adgroup.supernovaAdgroupId);}}>
                                                <i className="fa fa-cog" aria-hidden="true"></i>
                                            </div>
                                            <div className="toggle-status-wrapper">
                                                {
                                                    campaigns && campaigns.inactive && campaigns.items &&
                                                    // <Toggle className={`${isCampaignInactive(adgroup.supernovaCampaignId) ? "disabled" : ""}`} data-aid={adgroup.supernovaAdgroupId} defaultChecked={adgroup.state == "ACTIVE" || adgroup.status == 9} onChange={(e) => toggleAdgroupState(e)}/> 
                                                    <Toggle className={`${(isCampaignInactive(adgroup.supernovaCampaignId) || adgroups?.loading == true) ? "disabled" : ""}`} data-aid={adgroup.supernovaAdgroupId} defaultChecked={adgroup.state == "ACTIVE" || adgroup.status == 9} onChange={(e) => toggleAdgroupState(e)}/> 
                                                }
                                            </div>
                                                <div className="menu has-toggle-switch" onClick={() => {selectedAdgroup == adgroup.supernovaAdgroupId ? showAdgroupPanel(adgroup.supernovaCampaignId) : showCreativePanel(adgroup.supernovaAdgroupId)}}>
                                                    <span className="title">{adgroup.name} {isAdgroupLock(adgroup.supernovaAdgroupId) ? <i className="fa fa-spinner fa-spin fast-spin"></i>:""}</span>
                                                    {
                                                        isAdgroupLock(adgroup.supernovaAdgroupId) ? 
                                                        <span className="badge empty"><i className="fa fa-spinner fa-spin fast-spin"></i> Processing...</span>
                                                        :
                                                        adgroupMiniTotal == "publisher" ?
                                                        <>
                                                            {
                                                                publishers.total_by_adgroup && 
                                                                publishers.total_by_adgroup.filter(publisher => publisher._id == adgroup.supernovaAdgroupId).length > 0 ? 
                                                                <>
                                                                {
                                                                    publishers.total_by_adgroup.filter(publisher => publisher._id == adgroup.supernovaAdgroupId)[0].total == "0" ? 
                                                                    <span className="badge empty">
                                                                        0
                                                                    </span>
                                                                    :
                                                                    <span className="badge clickable" onClick={() => showPublishersByAdgroupId(adgroup.supernovaAdgroupId)}>
                                                                        {publishers.total_by_adgroup.filter(publisher => publisher._id == adgroup.supernovaAdgroupId)[0].total.toLocaleString()}
                                                                    </span>
                                                                }
                                                                </>
                                                                :
                                                                <span className="skeleton-box"></span>
                                                            }
                                                        </>
                                                        :   adgroupMiniTotal == "impressions"  || 
                                                            adgroupMiniTotal == "clicks"       ?
                                                            <>
                                                                {
                                                                    <span className={`badge ${getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", adgroupMiniTotal) == 0 ? "empty" : "clickable"}`}>
                                                                        {
                                                                            statistics.isAdgroupDone &&
                                                                            getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", adgroupMiniTotal) !== undefined &&
                                                                            getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", adgroupMiniTotal).toLocaleString()
                                                                        }
                                                                    </span>
                                                                }
                                                            </>
                                                        :   adgroupMiniTotal == "spend" ?
                                                        <>
                                                            {
                                                                getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", adgroupMiniTotal) == 0 
                                                                ?   <span className="badge empty">$0.00</span>
                                                                :   <span className="badge clickable">
                                                                        ${
                                                                            statistics.isAdgroupDone &&
                                                                            getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", adgroupMiniTotal) !== undefined && 
                                                                            getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", adgroupMiniTotal).toLocaleString()
                                                                        }
                                                                    </span>
                                                            }
                                                        </>
                                                        :   adgroupMiniTotal == "ctr" ?
                                                        <>
                                                            {
                                                                getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", "clicks") == 0 || getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", "clicks") == undefined?
                                                                <span className="badge empty">
                                                                    {
                                                                        `0.00%`
                                                                    }
                                                                </span>
                                                                :
                                                                <span className="badge clickable">
                                                                    {
                                                                        `
                                                                        ${
                                                                            getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", "clicks") > getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", "impressions") 
                                                                            ? "0.00"
                                                                            : parseFloat(getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", "clicks") / getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", "impressions")).toFixed(2) 
                                                                        }
                                                                        %`
                                                                    }
                                                                </span>
                                                            }
                                                        </>
                                                        :   adgroupMiniTotal == "ecpc" ?
                                                        <>
                                                            {
                                                                getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", "spend") == 0 || getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", "spend") == undefined?
                                                                <span className="badge empty">
                                                                    {
                                                                        `$0.00`
                                                                    }
                                                                </span>
                                                                :
                                                                <span className="badge clickable">
                                                                    {
                                                                        `$
                                                                        ${
                                                                            getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", "spend") > getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", "clicks") 
                                                                            ? "0.00"
                                                                            : parseFloat(getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", "spend") / getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", "clicks")).toFixed(2) 
                                                                        }`
                                                                    }
                                                                </span>
                                                            }
                                                        </>
                                                        : <></>
                                                    }
                                                </div>
                                        </li>
                                        </OverlayTrigger>
                                    )
                                : <li className='no-results'>{selectedEntityKey == "supernovaCampaignId" ? "No Ad Group Found" : ""}</li>
                            }
                        </ul>
                        {/* INACTIVE ADGROUPS */}
                        <ul className='adgroup-list inactive'>
                            {
                                selectedEntityKey != null && adgroups.isDone != true ? <li><i className="fa fa-spinner fa-spin fast-spin"></i> Loading...</li>
                                :
                                selectedEntityKey != null && adgroups.items && adgroups.items.length > 0 &&
                                    adgroups?.items && adgroups?.items?.distinctBy("supernovaAdgroupId")?.map((adgroup, index) =>
                                        (adgroup.state != "ACTIVE" && adgroup.status != 9) &&
                                        <OverlayTrigger placement="right" overlay={<Tooltip><b>{adgroup.name}</b></Tooltip>} key={index}>
                                        <li key={`list-adgroup-${index}`} className={`${adgroup.archived ? 'd-none' : ''} ${isAdgroupLock(adgroup.supernovaAdgroupId) ? "disabled":""} ${adgroup.supernovaAdgroupId == selectedAdgroup ? 'selected' : ''} ${(adgroup.state == "ACTIVE" || adgroup.status == 9) ? "active" : "inactive"}`} >
                                            <div className="gear-wrapper" onClick={(e) => {e.stopPropagation(); editAdgroup("adgroup", "edit", adgroup.supernovaAdgroupId);}}>
                                                <i className="fa fa-cog" aria-hidden="true"></i>
                                            </div>
                                            <div className="toggle-status-wrapper">
                                                {
                                                    campaigns && campaigns.inactive && campaigns.items &&
                                                    // <Toggle className={`${isCampaignInactive(adgroup.supernovaCampaignId) ? "disabled" : ""}`} data-aid={adgroup.supernovaAdgroupId} defaultChecked={adgroup.state == "ACTIVE" || adgroup.status == 9} onChange={(e) => toggleAdgroupState(e)}/> 
                                                    <Toggle className={`${(isCampaignInactive(adgroup.supernovaCampaignId) || adgroups?.loading == true) ? "disabled" : ""}`} data-aid={adgroup.supernovaAdgroupId} defaultChecked={adgroup.state == "ACTIVE" || adgroup.status == 9} onChange={(e) => toggleAdgroupState(e)}/> 
                                                }
                                            </div>
                                                <div className="menu has-toggle-switch" onClick={() => {selectedAdgroup == adgroup.supernovaAdgroupId ? showAdgroupPanel(adgroup.supernovaCampaignId) : showCreativePanel(adgroup.supernovaAdgroupId)}}>
                                                    <span className="title">{adgroup.name}</span>
                                                    {
                                                        isAdgroupLock(adgroup.supernovaAdgroupId) ? 
                                                        <span className="badge empty"><i className="fa fa-spinner fa-spin fast-spin"></i> Processing...</span>
                                                        :
                                                        adgroupMiniTotal == "publisher" ?
                                                        <>
                                                            {
                                                                publishers.total_by_adgroup && 
                                                                publishers.total_by_adgroup.filter(publisher => publisher._id == adgroup.supernovaAdgroupId).length > 0 ? 
                                                                <>
                                                                {
                                                                    publishers.total_by_adgroup.filter(publisher => publisher._id == adgroup.supernovaAdgroupId)[0].total == "0" ? 
                                                                    <span className="badge empty">
                                                                        0
                                                                    </span>
                                                                    :
                                                                    <span className="badge clickable" onClick={() => showPublishersByAdgroupId(adgroup.supernovaAdgroupId)}>
                                                                        {publishers.total_by_adgroup.filter(publisher => publisher._id == adgroup.supernovaAdgroupId)[0].total.toLocaleString()}
                                                                    </span>
                                                                }
                                                                </>
                                                                :
                                                                <span className="skeleton-box"></span>
                                                            }
                                                        </>
                                                        :   adgroupMiniTotal == "impressions"  || 
                                                            adgroupMiniTotal == "clicks"       ?
                                                            <>
                                                                {
                                                                    <span className={`badge ${getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", adgroupMiniTotal) == 0 ? "empty" : "clickable"}`}>
                                                                        {
                                                                            statistics.isAdgroupDone &&
                                                                            getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", adgroupMiniTotal).toLocaleString()
                                                                        }
                                                                    </span>
                                                                }
                                                            </>
                                                        :   adgroupMiniTotal == "spend" ?
                                                        <>
                                                            {
                                                                getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", adgroupMiniTotal) == 0 
                                                                ?   <span className="badge empty">$0.00</span>
                                                                :   <span className="badge clickable">
                                                                        ${
                                                                            statistics.isAdgroupDone &&
                                                                            getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", adgroupMiniTotal).toLocaleString()
                                                                        }
                                                                    </span>
                                                            }
                                                        </>
                                                        :   adgroupMiniTotal == "ctr" ?
                                                        <>
                                                            {
                                                                getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", "clicks") == 0 || getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", "clicks") == undefined?
                                                                <span className="badge empty">
                                                                    {
                                                                        `0.00%`
                                                                    }
                                                                </span>
                                                                :
                                                                <span className="badge clickable">
                                                                    {
                                                                        `
                                                                        ${
                                                                            getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", "clicks") > getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", "impressions") 
                                                                            ? "0.00"
                                                                            : parseFloat(getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", "clicks") / getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", "impressions")).toFixed(2) 
                                                                        }
                                                                        %`
                                                                    }
                                                                </span>
                                                            }
                                                        </>
                                                        :   adgroupMiniTotal == "ecpc" ?
                                                        <>
                                                            {
                                                                getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", "spend") == 0 || getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", "spend") == undefined?
                                                                <span className="badge empty">
                                                                    {
                                                                        `$0.00`
                                                                    }
                                                                </span>
                                                                :
                                                                <span className="badge clickable">
                                                                    {
                                                                        `$
                                                                        ${
                                                                            getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", "spend") > getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", "clicks") 
                                                                            ? "0.00"
                                                                            : parseFloat(getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", "spend") / getMiniInsightValue(adgroup.supernovaAdgroupId, "adgroup", "clicks")).toFixed(2) 
                                                                        }`
                                                                    }
                                                                </span>
                                                            }
                                                        </>
                                                        : <></>
                                                    }
                                                </div>
                                        </li>
                                        </OverlayTrigger>
                                    )
                            }
                        </ul>
                    </div>
                    
                </div>
                {/* <Footer campaign_id={selectedSupernovaCampaignId}/> */}
                <Footer campaign_id={campaigns?.items?.find(item => item.supernovaCampaignId == selectedSupernovaCampaignId)?.id}/>
            </div>
        );
    }
    catch(e){
        console.log(e);

        if(config.redirectOnError){
            // window.location.reload(false);
        }
    }
    
}


const mapState = state => ({
    user                : state.users.current,
    users               : state.users,
    campaigns           : state.campaigns,
    adgroups            : state.adgroups,
    creatives           : state.creatives,
    statistics          : state.statistics,
    publishers          : state.publishers,
    progress            : state.progress,
    adgroupCreatives    : state.adgroupCreatives,
    sideDrawer          : state.sideDrawer,
    creativesPending    : state.creativesPending,
    deactivatedAdgroups : state.deactivatedAdgroups,
    measurement         : state.measurement,
    ads                 : state.ads,
    logSupernova        : state.logSupernova,
    duplicates          : state.duplicates,
    ai                  : state.ai
    // pushCampaigns       : state.pushCampaigns,
})

const actionCreators = {
    getASById                       : accountSupernovaActions.getASById,

    getAdgroups                     : adgroupActions.getAdgroups,
    getAllAdgroups                  : adgroupActions.getAllAdgroups,
    updateAdgroup                   : adgroupActions.updateAdgroup,
    selectAdgroup                   : adgroupActions.select,
    getLockList                     : adgroupActions.getLockList,
    createSupernovaAdgroup          : adgroupActions.createSupernovaAdgroup,

    getCreativesInAdgroup           : creativeActions.getCreativesInAdgroup,
    getCreativesInAccount           : creativeActions.getCreativesInAccount,

    getCampaigns                    : campaignActions.getCampaigns,
    getInactiveCampaigns            : campaignActions.getInactiveCampaigns,
    disableCampaign                 : campaignActions.disableCampaign,
    enableCampaign                  : campaignActions.enableCampaign,
    selectCampaign                  : campaignActions.select,

    getStatsPerCampaign             : statisticActions.getStatsPerCampaign,
    getStatsPerAdgroup              : statisticActions.getStatsPerAdgroup,
    getStatsPerCreative             : statisticActions.getStatsPerCreative,
    getTopPerformingDMAByCTR        : statisticActions.getTopPerformingDMAByCTR,
    getTopPerformingCountriesByCTR  : statisticActions.getTopPerformingCountriesByCTR,
    getTopPerformingStatesByCTR     : statisticActions.getTopPerformingStatesByCTR,

    //Publishers
    getPublishersInAccount          : publisherActions.getPublishersInAccount,
    getPublishersInCampaign         : publisherActions.getPublishersInCampaign,
    getPublishersInAdgroup          : publisherActions.getPublishersInAdgroup,
    
    //Breakdown 
    getStatsByCountry               : statisticActions.getStatsByCountry,
    getStatsByZone                  : statisticActions.getStatsByZone,
    getStatsByDevice                : statisticActions.getStatsByDevice,
    getStatsByOS                    : statisticActions.getStatsByOS,
    getStatsByState                 : statisticActions.getStatsByState,
    getStatsByDMA                   : statisticActions.getStatsByDMA,
    getStatsByEnvironment           : statisticActions.getStatsByEnvironment,
    getStatsByMediaSources          : statisticActions.getStatsByMediaSources,

    
    getTotalPublishersPerCampaign   : publisherActions.getTotalPublishersPerCampaign,
    getTotalPublishersPerAdgroup    : publisherActions.getTotalPublishersPerAdgroup,
    getTotalPublishersPerCreative   : publisherActions.getTotalPublishersPerCreative,
    updatePreloader                 : progressActions.update,

    getAdgroupCreatives             : adgroupCreativeActions.getAdgroupCreatives,
    openSideDrawer                  : sideDrawerActions.open,
    openOverlay                     : overlayActions.open,
    
    getCreativesPending             : creativesPendingActions.getCreativesPending,
    checkUploadStatus               : creativesPendingActions.checkUploadStatus,

    getUserById                     : userActions.getById,
    
    //Push Campaigns
    addPushCampaign                 : campaignActions.addPushCampaign,
    editPushCampaign                : campaignActions.editPushCampaign,
    togglePushCampaign              : adgroupActions.togglePushCampaign,
    getDeactivatedAdgroups          : deactivatedAdgroupActions.getDeactivatedAdgroups,


    //Measurement
    getMeasurementServicesByAccountId   : measurementActions.getMeasurementServicesByAccountId,
    getConversionDefinitionByAccountId  : measurementActions.getConversionDefinitionByAccountId,
    getAudiencesByAccountId             : measurementActions.getAudiencesByAccountId,
    updateConversionDefinition          : measurementActions.updateConversionDefinition,
    createConversionDefinition          : measurementActions.createConversionDefinition,
    
    //Ads
    getByAdGroupId                      : adsActions.getByAdGroupId,
    updateAds                           : adsActions.updateAds,

    //Log Supernova
    getLogSupernovaByAccountId          : logSupernovaActions.getLogSupernovaByAccountId,
    getDailyLogByAccountIdAndCreativeIds    : logSupernovaActions.getDailyLogByAccountIdAndCreativeIds,

    //Duplicate Campaign Reference
    getDuplicatedCampaignsByAccountId   : duplicatesActions.getDuplicatedCampaignsByAccountId,
    addDuplicateReference               : duplicatesActions.addDuplicateReference,

    // Supernova AI
    askAI                               : aiActions.askAI,
    askAIToProceedProcess               : aiActions.askAIToProceedProcess,
    cancelAIRequest                     : aiActions.cancelAIRequest,
    

}

const connectedDashboardPage = connect(mapState, actionCreators)(DashboardPage);

export { connectedDashboardPage as DashboardPage };
