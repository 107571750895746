import React, { useState, useEffect, Component, useDebugValue } from 'react'
import {BrowserRouter as Router, Link } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";


//Redux
import { connect } from 'react-redux';
import { 
    basisDSPActions
} from '../_actions';

//Components
import {SideDrawer} from '../_components/SideDrawer'
import {Overlay} from '../_components/Overlay'
import {LeftNavigation} from '../_components/LeftNavigation'
import {Footer} from '../_components/Footer';
import {LifetimeValue} from '../_components/charts/LifetimeValue';


//Helpers
import '../_helpers/sort-by.js';



//Moments JS
import Moment from 'react-moment';
import moment from 'moment';


//Toggle
import Toggle from 'react-toggle'
import '../_stylesheets/css/react-toggle.css'



//New Date range picker
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { Calendar } from 'react-date-range';
import { DefinedRange } from 'react-date-range';

//React Select
import { styleSelectBasic, styleSelectMultiple } from '../_constants/settings'
import Select from 'react-select'

//Tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip  from "react-bootstrap/Tooltip";

const ReportPage = (props) => {

    const useStateWithLabel = (initialValue, label) => {
        const [value, setValue] = useState(initialValue);
        useDebugValue(`${label}: ${value}`);
        return [value, setValue];
    };

    const { basisDSP } = props;

    useEffect(() => {
        document.title = "Atlantic City Electric Campaign"
        props.getAll();
    },[]);

    const fixPercentage = (num) => {
        return parseFloat(num.toString().replaceAll('%', ''))
    }


    try{
        return (
            <div className="report-page">
                <LeftNavigation role="DSP" />
                <div className='canvas'>
                    <div className='custom-panel table'>
                        <div className="header">
                            Atlantic City Electric Campaign
                        </div>
                        {
                            basisDSP === undefined ?
                            <div className="loading">
                                    Loading...
                            </div>
                            :
                                <table className="primary fixed-header has-hover"> 
                                    <thead>
                                        <tr>
                                                <th className={`fixed`}>Date</th>
                                                <th className={`fixed`}>Campaign ID</th>
                                                <th className={`fixed`}>Campaign Name</th>
                                                <th className={`fixed`}>Start Date</th>
                                                <th className={`fixed`}>End Date</th>
                                                <th className={`fixed`}>Country</th>
                                                <th className={`fixed`}>Region</th>
                                                <th className={`fixed`}>Zip/Postal Code</th>
                                                <th className={`fixed`}>Imps. Won</th>
                                                <th className={`fixed`}>Clicks</th>
                                                <th className={`fixed`}>CTR</th>
                                                <th className={`fixed`}>Completion Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            basisDSP && basisDSP.map((item, index) => 
                                                <tr key={index}>
                                                    <td>{moment(item["date"]).format("MM-DD-YYYY")}</td>
                                                    <td>{item["campaign_id"]}</td>
                                                    <td>{item["campaign_name"]}</td>
                                                    <td>{moment(item["Start date"]).format("MM-DD-YYYY")}</td>
                                                    <td>{moment(item["End date"]).format("MM-DD-YYYY")}</td>
                                                    <td>{item["country"]}</td>
                                                    <td>{item["primary_region"]}</td>
                                                    <td>{item["Zip/Postal Code"]}</td>
                                                    <td>{item["imps_won"]}</td>
                                                    <td>{item["clicks"]}</td>
                                                    <td>{fixPercentage(item["ctr"]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}%</td>
                                                    <td>{fixPercentage(item["completion_rate"]).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}%</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                        }
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
    catch(e){
        // console.log(e);
        window.location.reload(false);
    }
    
}


const mapState = state => ({
    user                : state.users.current,
    users               : state.users,
    basisDSP            : state.basisDSP.items
})

const actionCreators = {
    getAll          : basisDSPActions.getAll
}

const connectedReportPage = connect(mapState, actionCreators)(ReportPage);

export { connectedReportPage as ReportPage };
