import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../_actions';

class RegisterPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                firstName: '',
                lastName: '',
                username: '',
                password: '',
                companyName: '',
                companyWebsite: '',
                position: ''
            },
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);   
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({ submitted: true });
        const { user } = this.state;
        if (user.firstName && user.lastName && user.username && user.password) {
            this.props.register(user);
        }
    }

    render() {
        const { registering  } = this.props;
        const { user, submitted } = this.state;
        return (
            <div className="container">
                <div className="register col-md-6 col-md-offset-3">
                    <h2>Register</h2>
                    <form name="form" onSubmit={this.handleSubmit}>
                        <div className={'form-group' + (submitted && !user.firstName ? ' has-error' : '')}>
                            <label htmlFor="firstName">First Name</label>
                            <input type="text" className="form-control" name="firstName" value={user.firstName} onChange={this.handleChange} />
                            {submitted && !user.firstName &&
                                <div className="help-block">Required</div>
                            }
                        </div>
                        <div className={'form-group' + (submitted && !user.lastName ? ' has-error' : '')}>
                            <label htmlFor="lastName">Last Name</label>
                            <input type="text" className="form-control" name="lastName" value={user.lastName} onChange={this.handleChange} />
                            {submitted && !user.lastName &&
                                <div className="help-block">Required</div>
                            }
                        </div>
                        <div className={'form-group' + (submitted && !user.username ? ' has-error' : '')}>
                            <label htmlFor="username">Email</label>
                            <input type="text" className="form-control" name="username" value={user.username} onChange={this.handleChange} />
                            {submitted && !user.username &&
                                <div className="help-block">Required</div>
                            }
                        </div>
                        <div className={'form-group' + (submitted && !user.password ? ' has-error' : '')}>
                            <label htmlFor="password">Password</label>
                            <input type="password" className="form-control" name="password" value={user.password} onChange={this.handleChange} />
                            {submitted && !user.password &&
                                <div className="help-block">Required</div>
                            }
                        </div>
                        <div className={'form-group' + (submitted && !user.companyName ? ' has-error' : '')}>
                            <label htmlFor="companyName">Company Name</label>
                            <input type="text" className="form-control" name="companyName" value={user.companyName} onChange={this.handleChange} />
                            {submitted && !user.companyName &&
                                <div className="help-block">Required</div>
                            }
                        </div>
                        <div className={'form-group' + (submitted && !user.companyWebsite ? ' has-error' : '')}>
                            <label htmlFor="companyWebsite">Company Website</label>
                            <input type="text" className="form-control" name="companyWebsite" value={user.companyWebsite} onChange={this.handleChange} />
                            {submitted && !user.companyWebsite &&
                                <div className="help-block">Required</div>
                            }
                        </div>
                        <div className={'form-group' + (submitted && !user.position ? ' has-error' : '')}>
                            <label htmlFor="position">Your positition in this company</label>
                            <input type="text" className="form-control" name="position" value={user.position} onChange={this.handleChange} />
                            {submitted && !user.position &&
                                <div className="help-block">Required</div>
                            }
                        </div>
                        <div className="form-group">
                            <button className="btn btn-primary" disabled={registering}>
                            {registering ? 
                                <i>Processing...</i>
                                : 'Submit'
                            }
                            </button>
                            <Link to="/login" className="btn btn-link">Cancel</Link>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const { registering } = state.registration;
    return { registering };
}

const actionCreators = {
    register: userActions.register
}

const connectedRegisterPage = connect(mapState, actionCreators)(RegisterPage);
export { connectedRegisterPage as RegisterPage };